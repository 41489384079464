import { useCallback, useEffect, useState } from 'react';
import { SequenceProgressEvent, StructuredColumns } from '../types/willyTypes';
import { useStoreValue } from '@tw/snipestate';
import { $socket } from '$stores/$socket';
import { AttributionData } from 'types/attribution';

type UseColumnsSocketProps = {
  shopId: string;
};

export function useColumnsSocket({ shopId }: UseColumnsSocketProps) {
  const socket = useStoreValue($socket);
  const [columns, setColumns] = useState<Record<string, AttributionData>>({});

  const onSequenceProgress = useCallback((data: SequenceProgressEvent) => {
    if (data.type !== 'sendToDatabase') {
      return;
    }

    const newColumns: Record<string, AttributionData> = data.data.columns.reduce((acc, column) => {
      acc[column.id] = {
        id: column.id,
        entity: column.entity,
        channel: column.provider_id,
        [column.metric]: {
          reason: column.reason,
          value: column.value,
        },
      };
      return acc;
    }, {});

    setColumns((prev) => ({
      ...prev,
      ...newColumns,
    }));
  }, []);

  useEffect(() => {
    const func = (msg) => {
      if (msg.eventType !== 'workflow-progress') {
        return;
      }
      if (msg.account !== shopId) {
        return;
      }
      onSequenceProgress(msg.data);
    };

    socket.on('message', func);

    return () => {
      socket.off('message', func);
    };
  }, [shopId, socket, onSequenceProgress]);

  return { columns };
}
