import 'firebase/compat/firestore';

import { metrics as ConstantMETRICS } from 'constants/metrics/metrics';
import firebase from 'firebase/compat/app';
import _, { pickBy } from 'lodash';
import moment from '@tw/moment-cached';
import { fetchCurrenciesRate } from 'utils/currencyConvertion';
import { getShopById, toArray } from 'utils/DB';

import { SummaryMetrics } from '@tw/types/module/SummaryMetrics';
import { safeDivide } from '@tw/stats/module/generalUtils';
import { services, ServicesIds, ServicesIdsArray } from '@tw/types/module/services';

import axiosInstance from '../utils/axiosInstance';
import {
  getInfluencerStatsForShop,
  getPixelStats,
  getShopifySegmentsNewStatsForShop,
  getSummaryWillyStatsForShop,
} from './newStats';
import { getTileValue } from './summary';
import { ShopifySegmentType } from '../types/shopify';
import { shopifySegmentSources } from '../utils/allActiveOrderSegmentMetrics';
import { transformGatewayCosts } from './paymentGateways';
import { getProvidersAndIntegrations, getSensoryNewStatsForShop } from './sensory';
import {
  onboardingResponse,
  onboardingTaskInfo,
  TASK_STATUS,
} from '@tw/types/module/services/subscription-manager';
import { COST_SETTINGS_ONBOARDING_TASKS } from 'constants/costSettings';
import { FeatureFlagValueWithMetaDataMap } from '@tw/feature-flag-system/module/types';
import { getComputedFFValues } from 'feature-flag-system/services';
import { initFeatureFlagComputerGeneralData } from 'feature-flag-system';
import { SENSORY_IGNORE_PROVIDERS, SENSORY_SUPPORTED_PROVIDERS } from '@tw/types/module/sensory';
import { ShopIntegrationProperties } from '@tw/types/module/types/ShopProviders';
import { ProvidersIntegrations } from '../types/services';
import { providersFromShopWithSensory } from './shopIntegrations';
import { AttributionStatsRequest } from '../types/attribution';
import { groupStatsByWillyTableType } from './newStatsUtils';

export const enum finalOperator {
  SUM,
  SUM2,
  DIVIDE,
  DIVIDE_PERCENT,
  MINUS,
}

export const FINAL_CALC_METRIC = [
  { id: 'grossProfit', operator: finalOperator.SUM },
  { id: 'totalProductCosts', operator: finalOperator.SUM },
  { id: 'totalNetProfit', operator: finalOperator.SUM },
  { id: 'totalNetMargin', operator: finalOperator.SUM },
  { id: 'cashTurnover', operator: finalOperator.SUM },
  { id: 'totalCustomSpends', operator: finalOperator.SUM },
  { id: 'rcRevenue', operator: finalOperator.SUM },
  { id: 'newCustomerSales', operator: finalOperator.SUM },
  { id: 'totalSales', operator: finalOperator.SUM },
  { id: 'totalNewGrossSales', operator: finalOperator.SUM },
  { id: 'shopifyAov', operator: finalOperator.SUM },
  { id: 'shopifyAovIncludeZero', operator: finalOperator.SUM },
  { id: 'totalOrdersWithAmount', operator: finalOperator.SUM },
  { id: 'getUniqueCustomerCount', operator: finalOperator.SUM },
  { id: 'totalRefunds', operator: finalOperator.SUM },
  { id: 'blendedAds', operator: finalOperator.SUM },
  { id: 'totalAmazonProductItemPrice', operator: finalOperator.SUM },
  { id: 'totalDiscounts', operator: finalOperator.SUM },
  { id: 'totalTaxes', operator: finalOperator.SUM },
  { id: 'totalNetTaxes', operator: finalOperator.SUM },
  { id: 'totalShippingPrice', operator: finalOperator.SUM },
  {
    id: 'blendedSales',
    operator: finalOperator.SUM2,
    numerator: 'totalSales',
    denominator: 'totalAmazonProductItemPrice',
  },
  {
    id: 'totalCpa',
    operator: finalOperator.DIVIDE,
    numerator: 'blendedAds',
    denominator: 'totalOrdersWithAmount',
  },
  {
    id: 'totalRoas',
    operator: finalOperator.DIVIDE,
    numerator: 'totalSales',
    denominator: 'blendedAds',
  },
  {
    id: 'uniqueCustomerLifetimeValue',
    operator: finalOperator.DIVIDE,
    numerator: 'totalSales',
    denominator: 'getUniqueCustomerCount',
  },
  {
    id: 'customerFrequency',
    operator: finalOperator.DIVIDE,
    numerator: 'totalOrdersWithAmount',
    denominator: 'getUniqueCustomerCount',
  },
  {
    id: 'ltvCpa',
    operator: finalOperator.DIVIDE,
    numerator: 'uniqueCustomerLifetimeValue',
    denominator: 'totalCpa',
  },
  {
    id: 'netSales',
    operator: finalOperator.MINUS,
    numerator: 'totalSales',
    denominator: 'totalRefunds',
  },
  {
    id: 'mer',
    operator: finalOperator.DIVIDE_PERCENT,
    numerator: 'blendedAds',
    denominator: 'totalSales',
  },
  {
    id: 'totalPaymentGatewayCosts',
    operator: finalOperator.SUM,
  },
];

const METRICS = Object.values(SummaryMetrics) as any;

const firestore = firebase.firestore;
export const getAllShopsTileValue = (shops, workspaceStats, metric, final) => {
  if (shops.some((id) => !workspaceStats[id]))
    // edge case in UI rendering, but the final res will not 0
    return 0;
  if (final.operator === finalOperator.SUM) {
    return shops.reduce((prev, shop) => {
      const convertedValue =
        getTileValue(workspaceStats[shop], metric) *
        workspaceStats[shop].currencyRateFromPodCurrency;
      return prev + convertedValue;
    }, 0);
  }
  if (final.operator === finalOperator.MINUS) {
    const numerator = getAllShopsTileValue(
      shops,
      workspaceStats,
      METRICS.find((m) => m.metricId === final.numerator),
      FINAL_CALC_METRIC.find((x) => x.id === final.numerator),
    );
    const denominator = getAllShopsTileValue(
      shops,
      workspaceStats,
      METRICS.find((m) => m.metricId === final.denominator),
      FINAL_CALC_METRIC.find((x) => x.id === final.denominator),
    );
    return numerator - denominator;
  }
  if (final.operator === finalOperator.SUM2) {
    const numerator = getAllShopsTileValue(
      shops,
      workspaceStats,
      METRICS.find((m) => m.metricId === final.numerator),
      FINAL_CALC_METRIC.find((x) => x.id === final.numerator),
    );
    const denominator = getAllShopsTileValue(
      shops,
      workspaceStats,
      METRICS.find((m) => m.metricId === final.denominator),
      FINAL_CALC_METRIC.find((x) => x.id === final.denominator),
    );
    return numerator + denominator;
  }
  if (final.operator === finalOperator.DIVIDE) {
    const numerator = getAllShopsTileValue(
      shops,
      workspaceStats,
      METRICS.find((m) => m.metricId === final.numerator),
      FINAL_CALC_METRIC.find((x) => x.id === final.numerator),
    );
    const denominator = getAllShopsTileValue(
      shops,
      workspaceStats,
      METRICS.find((m) => m.metricId === final.denominator),
      FINAL_CALC_METRIC.find((x) => x.id === final.denominator),
    );
    return safeDivide(numerator, denominator);
  }
  if (final.operator === finalOperator.DIVIDE_PERCENT) {
    const numerator = getAllShopsTileValue(
      shops,
      workspaceStats,
      METRICS.find((m) => m.metricId === final.numerator),
      FINAL_CALC_METRIC.find((x) => x.id === final.numerator),
    );
    const denominator = getAllShopsTileValue(
      shops,
      workspaceStats,
      METRICS.find((m) => m.metricId === final.denominator),
      FINAL_CALC_METRIC.find((x) => x.id === final.denominator),
    );
    return safeDivide(numerator * 100, denominator);
  }
};

function getMode(arr) {
  arr.sort();
  return arr
    .sort((a, b) => arr.filter((v) => v === a).length - arr.filter((v) => v === b).length)
    .pop();
}

const sumArray = (arrToSum) => {
  return arrToSum.filter((a) => a).reduce((a, b) => parseInt(a) + parseInt(b), 0);
};
const mergeArrays = (arrArray) => {
  return arrArray.reduce((a, b) => a.concat(b), []).filter((a) => a);
};
const getCommon = (list) => {
  if (!list || !list.length) return null;
  const unique = new Set(list);
  return unique.size > 1 ? getMode(list) : list[0] || '';
};

const getRate = async (sourceCurrency, targetCurrency, date) => {
  if (sourceCurrency === targetCurrency || !sourceCurrency || !sourceCurrency) return 1;
  let conversionRate = 1;
  try {
    conversionRate = await fetchCurrenciesRate(sourceCurrency, targetCurrency, date);
  } catch (error) {
    console.log('error!!', error);
  }
  return conversionRate;
};
const getSeveralShopStats = (
  workspaceStats,
  shops,
  workspaceTimeRange,
  podCurrency,
  podTimezone,
) => {
  const retVal = {
    paymentGatewayCosts: [],
    customSpends: [],
    timezone: '',
    shopTimezone: '',
    currency: '',
    newStats: {},
    isAmazonConnectedNA: true,
  };
  retVal.timezone = retVal.shopTimezone = podTimezone;
  retVal.currency = podCurrency;
  retVal.paymentGatewayCosts = mergeArrays(
    shops.map((shop) => workspaceStats[shop]?.paymentGatewayCosts),
  );
  retVal.customSpends = mergeArrays(shops.map((shop) => workspaceStats[shop]?.customSpends));
  retVal.newStats = getNewStatsMergeSeveralShops(shops.map((shop) => workspaceStats[shop] || {}));
  return { ...retVal, mainDatePickerSelectionRange: workspaceTimeRange };
};

const convertNewStats = (shopNewStats, currencyRateFromPodCurrency) => {
  if (currencyRateFromPodCurrency === 1 || !shopNewStats) return shopNewStats;

  const convertedStats = JSON.parse(JSON.stringify(pickBy(shopNewStats, (v) => v !== undefined)));
  // console.log(`before ${currencyRateFromPodCurrency}`,shopNewStats)
  Object.entries(convertedStats).forEach(([serviceId, serviceStats]) => {
    if (!serviceStats || serviceId === 'shopify-segment') return; //TODO support shopify-segment
    (serviceStats as any[]).forEach(({ hours }, i) => {
      Object.entries(hours).forEach(([hourKey, valObj]) => {
        Object.entries(valObj as any).forEach(([metricId, val]) => {
          if (ConstantMETRICS[metricId]?.format === 'currency') {
            convertedStats[serviceId][i].hours[hourKey][metricId] =
              (val as number) * currencyRateFromPodCurrency;
          }
        });
      });
    });
  });
  return convertedStats;
};

const getNewStatsMergeSeveralShops = (shops) => {
  const newStats = {};

  if (shops.length === 0) return {};

  const shopsToMerge = shops.filter((s) => !_.isEmpty(s));

  const shopsAfterCurrencyConvert = shopsToMerge.map(
    ({ newStats: shopNewStats, currencyRateFromPodCurrency, shopId }) => {
      return {
        newStats: convertNewStats(shopNewStats, currencyRateFromPodCurrency),
        shopId,
      };
    },
  );

  shopsAfterCurrencyConvert.forEach(({ newStats: shopNewStats, shopId }) => {
    Object.entries(shopNewStats).forEach(([serviceId, serviceStats]) => {
      if (!newStats[serviceId]) {
        newStats[serviceId] = [{ hours: {} }];
      }
      if (!serviceStats || serviceId === 'shopify-segment') return; //TODO support shopify-segment
      (serviceStats as any[]).forEach(({ id, hours }) => {
        Object.entries(hours).forEach(([key, valObj]) => {
          newStats[serviceId][0].hours[`${shopId}_${id}_${key}`] = valObj;
        });
      });
    });
  });

  return newStats;
};

export const getShopStatsSelector = (
  { workspaceStats, workspaceTimeRange },
  shopOrShops,
  podCurrency,
) => {
  return typeof shopOrShops === 'string'
    ? workspaceStats[shopOrShops]
    : getSeveralShopStats(
        workspaceStats,
        shopOrShops,
        workspaceTimeRange,
        podCurrency,
        'America/New_York',
      );
};

const getSubCollection = (shopRef, collectionName) => {
  return shopRef
    .collection(collectionName)
    .get()
    .then((data) => toArray(data).filter((item) => item));
};

export const getStatsForShop = async ({ shop, workspaceTimeRange, groupStatsBy, podCurrency }) => {
  const getShopData = async (shop) => {
    const shopData = ((await getShopById(shop)) as any) || {};
    const { timezone = 'America/New_York' } = shopData;

    shopData.shopId = shop; //we need this for channelsAccounts

    let shopTimeRange: any = {};
    shopTimeRange.start = moment(workspaceTimeRange.start)
      .tz(timezone || 'EST', true)
      .startOf('day');

    shopTimeRange.end = moment(workspaceTimeRange.end)
      .tz(timezone || 'EST', true)
      .endOf('day');

    return { shopTimeRange, shopData };
  };

  const getIsFreeShop = async (shop) => {
    const url = `/v2/subscription-manager/subscriptions/shop/${shop}?redis=false`;
    const { data: subscriptionData } = await axiosInstance.get(url);
    const { features } = subscriptionData ?? {};
    const isFreeShop =
      subscriptionData?.items?.some(
        (item) =>
          item.product_type === 'package' &&
          item.plan_preview_start_date == null &&
          item.product_name === 'Founders Dash',
      ) ?? false;

    return { features, isFreeShop, subscriptionData };
  };

  const [{ features, isFreeShop, subscriptionData }, { shopData, shopTimeRange }] =
    await Promise.all([getIsFreeShop(shop), getShopData(shop)]);

  const {
    amazon,
    timezone = 'America/New_York',
    currency = 'USD',
    customExpenseAttributionType = 'daily',
    customExpenseSmartAttribution = {},
    connectedServices,
    sensoryBetaProviders,
    useShippingChargesAsCost = false,
  } = shopData;

  const isAmazonConnectedFE = !!(Object.values(amazon?.sellerAccounts || {})?.filter(
    (x: any) => x.region === 'fe',
  )).length;

  const isAmazonConnectedEU = !!(Object.values(amazon?.sellerAccounts || {})?.filter(
    (x: any) => x.region === 'eu',
  )).length;

  const isAmazonConnectedNA = !!(Object.values(amazon?.sellerAccounts || {})?.filter(
    (x: any) => !x.region || x.region === 'na',
  )).length;

  if (!features || features.length === 0) {
    return {
      shop,
      data: {
        shopWithSensory: {},
        ...shopData,
        isAmazonConnectedFE,
        isAmazonConnectedEU,
        isAmazonConnectedNA,
        newStats: {},
        ltv: { uniqueCustomerCount: 0 },
        shopId: shop,
        shopTimezone: timezone,
        computedFeatures: {},
        customExpenseAttributionType: customExpenseAttributionType || 'daily',
        customExpenseSmartAttribution,
        currencyRateFromPodCurrency: {},
        paymentGatewayCosts: {},
        customSpends: [],
        timezone,
        currency,
        isFreeShop,
        features,
        subscriptionData,
        mainDatePickerSelectionRange: shopTimeRange,
        useShippingChargesAsCost,
      },
    };
  }

  const getShopifyCosts = async () => {
    let paymentGatewayCosts = [];

    try {
      const { data } = await axiosInstance.post('/v2/shopify/mongo/get-segments', {
        shopId: shop,
        limit: 50,
      });

      paymentGatewayCosts = transformGatewayCosts(
        data?.[ShopifySegmentType.PAYMENT_GATEWAY_COSTS] ?? [],
      );
    } catch (e) {
      console.log(`error getting mongo segments for shop: ${shop} `, e);
    }

    return paymentGatewayCosts;
  };

  const getLtvData = async () => {
    let uniqueCustomerCount = 0;
    try {
      let { start, end } = shopTimeRange;
      const format = 'YYYY-MM-DDTHH:mm:ss';
      start = start.format(format);
      end = end.format(format);
      let url = `/v2/shopify/mongo/get-unique-customers-count`;
      let params = { shopId: shop, start, end, granularity: 'total' };
      uniqueCustomerCount = (await axiosInstance.post(url, params)).data?.[0]?.customer_count ?? 0;
    } catch {}
    return uniqueCustomerCount;
  };

  const getSensoryStats = async (sensoryBetaProviders = []) => {
    const newStats: Record<ServicesIds, any> = {} as any;

    const shopProviders: { [key in ServicesIds]: ShopIntegrationProperties[] } =
      ServicesIdsArray.reduce((acc, providerId: ServicesIds) => {
        acc[providerId as ServicesIds] = services[providerId]?.getAccounts(shopWithSensory);
        return acc;
      }, {} as any);

    Object.entries(shopProviders)
      .filter(([providerId, integrations]) => {
        return (
          !SENSORY_IGNORE_PROVIDERS.includes(providerId as ServicesIds) &&
          [...SENSORY_SUPPORTED_PROVIDERS, ...sensoryBetaProviders].includes(
            providerId as ServicesIds,
          ) &&
          (typeof services[providerId].isSensory === 'function'
            ? services[providerId].isSensory(shopData)
            : services[providerId].isSensory) &&
          integrations.length > 0
        );
      })
      .filter(([providerId, integrations]) => !services[providerId].forceOldFetch)
      .map(async ([providerId, integrations]) => {
        newStats[providerId] = await getSensoryNewStatsForShop(
          providerId as ServicesIds,
          integrations,
          true,
          shopTimeRange,
          currency,
          groupStatsBy,
          () => {},
          shop,
          timezone,
        );
      });

    return { newStats };
  };

  const getAllConnectedServicesNewStats = async () => {
    const providersProps = providersFromShopWithSensory(shopWithSensory);

    const newStats: Record<ServicesIds, any> = {} as any;
    const promises: Promise<any>[] = [];

    const shopIntegrations = Object.entries(providersProps).reduce(
      (acc, [serviceId, providerProps]) => {
        acc[serviceId] = providerProps?.integrations;
        return acc;
      },
      {} as ProvidersIntegrations,
    );

    const groupedProviders = groupStatsByWillyTableType(providersProps);
    for (const [willyTableType, providers] of Object.entries(groupedProviders)) {
      promises.push(
        (async () => {
          const groupedStats = await getSummaryWillyStatsForShop(
            providers,
            true,
            shopTimeRange,
            currency,
            shopIntegrations,
            groupStatsBy,
            () => {},
            willyTableType,
            shop,
          );
          Object.entries(groupedStats).forEach(([serviceId, serviceStats]) => {
            newStats[serviceId] = serviceStats;
          });
        })(),
      );
    }

    await Promise.all(promises);
    return newStats;
  };
  const getCustomSpends = async () => {
    const shopRef = firestore().collection('shops').doc(shop);
    const customSpends = (await getSubCollection(shopRef, 'custom_spends')) || [];
    return customSpends.filter((x) => x?.isActive !== false);
  };

  const getRates = async () => {
    return await getRate(currency, podCurrency, shopTimeRange);
  };

  const getCostSettingsTasks = async () => {
    let { data } = await axiosInstance.get<onboardingResponse>(
      `/v2/subscription-manager/onboarding/shop-user/${shop}`,
    );
    const costSettingsTasks: onboardingTaskInfo[] = data?.recomendedTasks?.filter((task) =>
      COST_SETTINGS_ONBOARDING_TASKS.includes(task.id as any),
    );
    return {
      tasks: costSettingsTasks,
      isComplete: costSettingsTasks.every(
        (task) => task?.status === TASK_STATUS.COMPLETE || task?.skipped,
      ),
    };
  };

  const { tasks, isComplete } = await getCostSettingsTasks();

  const { sensoryForShop, integrations } = await getProvidersAndIntegrations(shop);

  const shopWithSensory = { id: shop, ...shopData, sensory: sensoryForShop };

  const activeShippingProvider =
    integrations.filter((x) => !['deleted'].includes(x.status)).find((x) => x.domain === 'shipping')
      ?.provider_id ?? shopData.msp;

  const getAllShopStats = async () => {
    const promises: Promise<any>[] = [];
    promises.push(getAllConnectedServicesNewStats());
    promises.push(
      getShopifySegmentsNewStatsForShop(
        true,
        shopTimeRange,
        groupStatsBy,
        () => {},
        timezone,
        shop,
        shopifySegmentSources.map((x) => ({ id: x, type: ShopifySegmentType.SOURCES })),
      ),
    );
    promises.push(getInfluencerStatsForShop(shop, timezone, groupStatsBy, shopTimeRange));
    promises.push(getShopifyCosts());
    promises.push(getLtvData());
    promises.push(getComputedFFValues(shop));
    promises.push(getRates());
    const params: Partial<AttributionStatsRequest> = {
      startDate: moment.tz(shopTimeRange.start, timezone).format(),
      endDate: moment.tz(shopTimeRange.end, timezone).endOf('day').format(),
      shopDomain: shop,
      timezone: timezone,
      model: 'linearAll',
      showDirect: true,
      breakdown: 'source',
      dateModel: 'eventDate',
      attributionWindow: 'lifetime',
    };
    promises.push(getPixelStats(params));

    const [
      newStatsAllConnectedServices,
      shopifySegmentNewStats,
      influencersNewStats,
      shopifyCostsData,
      uniqueCustomerCount,
      ffData,
      currencyRateFromPodCurrency,
      webAnalyticsData,
    ] = await Promise.all(promises);

    let newStats = { ...newStatsAllConnectedServices };

    newStats['shopify-segment'] = shopifySegmentNewStats;
    newStats['influencers'] = influencersNewStats;
    newStats['pixel'] = webAnalyticsData;
    const paymentGatewayCosts = shopifyCostsData;
    const computedFeatures: FeatureFlagValueWithMetaDataMap = ffData;
    const customSpends = await getCustomSpends();
    return {
      newStats,
      paymentGatewayCosts,
      computedFeatures,
      currencyRateFromPodCurrency,
      customSpends,
      uniqueCustomerCount,
    };
  };

  const {
    newStats,
    paymentGatewayCosts,
    computedFeatures,
    currencyRateFromPodCurrency,
    customSpends,
    uniqueCustomerCount,
  } = await getAllShopStats();

  return {
    shop,
    data: {
      ...shopData,
      isAmazonConnectedFE,
      isAmazonConnectedEU,
      isAmazonConnectedNA,
      shopWithSensory,
      newStats,
      ltv: { uniqueCustomerCount },
      shopId: shop,
      activeShippingProvider,
      shopTimezone: timezone,
      computedFeatures,
      customExpenseAttributionType: customExpenseAttributionType || 'daily',
      currencyRateFromPodCurrency,
      paymentGatewayCosts,
      customSpends,
      timezone,
      currency,
      isFreeShop,
      features,
      subscriptionData,
      mainDatePickerSelectionRange: shopTimeRange,
      costSettingsCompletion: { tasks, isComplete },
      useShippingChargesAsCost,
    },
  };
};
const SHOP_STATS_FETCHED = 'SHOP_STATS_FETCHED';
export const getAllShopsData = ({ shops, workspaceTimeRange, groupStatsBy, podCurrency }) => {
  return async (dispatch) => {
    await initFeatureFlagComputerGeneralData();

    shops.forEach((shop) => {
      getStatsForShop({ shop, workspaceTimeRange, groupStatsBy, podCurrency })
        .then((state) => {
          dispatch({ type: SHOP_STATS_FETCHED, ...state });
        })
        .catch((err) => {
          console.log({ err });
        });
    });
  };
};

const SHOP_STATS_INIT = 'SHOP_STATS_INIT';
export const startLoadingShops = () => (dispatch) => {
  dispatch({ type: SHOP_STATS_INIT });
};

const workspaceStats = (state = {}, action) => {
  switch (action.type) {
    case SHOP_STATS_FETCHED:
      return { ...state, [action.shop]: action.data };
    case SHOP_STATS_INIT:
      return {};
    default:
      return state;
  }
};

export const reducers = {
  workspaceStats,
  // podsAdsStats,
};
