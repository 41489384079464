import { CURRENCIES } from '@tw/constants';
import { getConversionRate } from 'ducks/actions';
import { useAppDispatch } from 'index';
import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';

import { Button, Menu } from '@tw/ui-components';
import { Icon } from '@shopify/polaris';
import { CashDollarMajor, CashEuroMajor, CashPoundMajor, GlobeMinor } from '@shopify/polaris-icons';
import { genericEventLogger, dashboardsActions } from 'utils/dataLayer';
import { WillyDashboardElement } from './Willy/types/willyTypes';
import { useStoreValue, useWritableStore } from '@tw/snipestate';
import { $currency, $shopCurrency } from '../$stores/$shop';

type ActiveCurrencyButtonProps = {
  theme?: 'light' | 'dark' | 'none';
  disabled?: boolean;
  dashboard?: WillyDashboardElement;
  fullWidth?: boolean;
  activator?: (activeCurrency) => ReactNode;
  onCurrencyChange?: (currency: string) => void;
  dropdownPosition?: any;
  dropDownOffset?: number;
  withinPortal?: boolean;
};

const ActiveCurrencyButton: FC<ActiveCurrencyButtonProps> = ({
  disabled,
  dashboard,
  fullWidth,
  activator,
  dropDownOffset,
  dropdownPosition,
  withinPortal,
  onCurrencyChange,
}) => {
  const dispatch = useAppDispatch();
  const [currency, setCurrency] = useWritableStore($currency);
  const shopCurrency = useStoreValue($shopCurrency);
  const options = Object.keys(CURRENCIES)
    .sort()
    .map((x) => ({ value: x, label: x }));
  const dollarCurrencies = ['USD', 'CAD', 'AUD', 'BRL', 'NZD', 'SGD'];

  const handleChange = (newCurrency) => {
    setCurrency(newCurrency);
    dispatch(getConversionRate(shopCurrency, newCurrency));

    if (dashboard) {
      genericEventLogger(dashboardsActions.CHANGE_CURRENCY, {
        currency: newCurrency,
        dashboard_id: dashboard?.id,
        dashboard_name: dashboard?.name,
        template_id: dashboard?.globalDashboardId,
        template_name: dashboard?.globalDashboardId ? dashboard?.name : null,
      });
    }
  };

  return (
    <>
      <Menu
        onClose={() => {}}
        withinPortal={withinPortal}
        position={dropdownPosition}
        mah={100}
        offset={dropDownOffset}
        disabled={disabled}
      >
        <Menu.Target>
          {activator ? (
            activator(currency)
          ) : (
            <div>
              <Button
                id="tr-change-currency"
                disabled={disabled}
                leftSection={
                  <Icon
                    source={
                      dollarCurrencies.includes(currency)
                        ? CashDollarMajor
                        : currency === 'EUR'
                          ? CashEuroMajor
                          : currency === 'GBP'
                            ? CashPoundMajor
                            : GlobeMinor
                    }
                  />
                }
                variant="activator"
                rightSection="caret-down"
                fullWidth={fullWidth}
              >
                {currency}
              </Button>
            </div>
          )}
        </Menu.Target>
        <Menu.Dropdown className="max-h-[250px] overflow-auto z-[999]">
          {options.map((option) => (
            <Menu.Item
              key={option.value}
              onClick={() => {
                handleChange(option.value);
                onCurrencyChange?.(option.value);
              }}
              bg={currency === option.value ? 'named2.4' : undefined}
            >
              {option.label}
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    </>
  );
};

export default ActiveCurrencyButton;
