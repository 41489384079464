import { useState } from 'react';
import { stepActionType, WorkflowStepBase, WorkflowStepRunQuery } from '../types/willyTypes';
import { FlowStepWithText } from './FlowStepWithText';
import { Button } from '@tw/ui-components';
import { FlowStepWithFullEditor } from './FlowStepWithFullEditor';

type FlowRunQueryStepProps = {
  step: WorkflowStepRunQuery & WorkflowStepBase;
  onSave: (query: string, params: Record<string, any>) => void;
  readOnly: boolean;
  toolConfig?: stepActionType;
  depth: number;
  setIsPristine: (isPristine: boolean) => void;
};

export const FlowRunQueryStep: React.FC<FlowRunQueryStepProps> = ({
  step,
  onSave,
  readOnly,
  toolConfig,
  depth,
  setIsPristine,
}) => {
  const [query, setQuery] = useState(step.query);
  const [params, setParams] = useState(step.queryParams || {});
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="flex flex-col gap-2">
      <FlowStepWithText
        text={query}
        readOnly={readOnly}
        textChange={(text) => {
          setIsPristine(false);
          setQuery(text);
          onSave(text, params);
        }}
        toolConfig={toolConfig}
        setIsPristine={setIsPristine}
        variables={step.variables || []}
        depth={depth}
      />
      <div className="flex justify-end gap-2 items-center">
        <Button variant="white" onClick={() => setShowModal(true)} disabled={readOnly}>
          Edit in full editor
        </Button>
      </div>
      <FlowStepWithFullEditor
        opened={showModal}
        onClose={() => setShowModal(false)}
        query={query}
        setQuery={setQuery}
        params={params}
        setParams={setParams}
        onSave={onSave}
      />
    </div>
  );
};
