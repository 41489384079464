import { $tablesWithBeta } from '$stores/willy/$tables';
import { BaseFrom, Column, ColumnRef, Parser, Select } from 'node-sql-parser';
import { Dialect } from '../types/willyTypes';

const parser = new Parser();

export function astifyQuery(query?: string, dialect: Dialect = 'bigquery') {
  try {
    if (!query) {
      return null;
    }
    if (dialect !== 'bigquery') {
      query = removeGroupingSets(query);
    }

    let commentedQuery = query.replace(/{{/g, '--{{');
    // replace lines containing _tvf
    commentedQuery = replaceTvfInstances(commentedQuery);

    let ast = parser.astify(commentedQuery, {
      database: dialect === 'clickhouse' ? 'postgresql' : dialect,
    });

    if (Array.isArray(ast)) {
      ast = ast[0];
    }

    if (!ast) {
      return null;
    }

    if (ast.type !== 'select') {
      return null;
    }

    return ast;
  } catch (e) {
    // console.log(e);
    return null;
  }
}

function replaceTvfInstances(query: string) {
  const regex = /(\w+_tvf)\s*\(.*?\)/gs;
  return query.replace(regex, '$1');
}

function removeGroupingSets(sqlQuery: string): string {
  // This regular expression matches the GROUPING SETS clause,
  // including nested parentheses which are common in SQL for specifying grouping sets.
  const regex = /GROUP BY\s+GROUPING SETS\s*\([\s\S]*?\)\s*\)/gi;
  return sqlQuery.replace(regex, '');
}

export function extractCustomQueryColumns(query: string): string[] {
  try {
    const parsed = astifyQuery(query);
    return (
      parsed?.columns?.map((c: Column) => {
        const expr = c.expr as ColumnRef;
        const val = typeof expr.column === 'string' ? expr.column : expr.column?.expr?.value;
        return c.as || val?.toString();
      }) || []
    );
  } catch (e) {
    console.log(e);
    return [];
  }
}
