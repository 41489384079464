import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './styles/willy.scss';
import { useAppSelector } from 'reducers/RootType';
import _db from 'utils/DB';
import {
  Message,
  PreloadSequenceRunData,
  RunSequenceRequest,
  StepWsStatus,
  WillyMainChatRef,
  WillyPrompt,
  WorkflowWsStatus,
} from './types/willyTypes';
import { computeFeatureFlags } from '../../feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { UpgradePageFallBack } from '../../feature-flag-system/components';
import axiosInstance from 'utils/axiosInstance';
import { CodeAction } from './WillySimpleText';
import { WillyMainChat } from './WillyMainChat';
import { useSequenceMessages } from './sequenceBuilder/hooks/useSequenceMessages';
import {
  convertMessageDataForPrompt,
  getSuggestedPromptsFromPreloadedData,
} from './utils/willyUtils';
import { useParams } from 'react-router-dom';
import { useSequenceFlowSocket } from './sequenceBuilder/useSequenceFlowSocket';
import { useStoreValue } from '@tw/snipestate';
import { $activeAccounts, $currency, $industry } from '$stores/$shop';
import { v4 as uuidV4 } from 'uuid';
import { $dialect } from '$stores/$user';
import { sleep } from 'utils/sleep';
import { runWorkflow } from './utils/sequences';

export const WillyComponent: React.FC<{ asPage?: boolean }> = ({ asPage = true }) => {
  const params = useParams<{ workflowId: string; workflowRunId: string }>();
  const mainChatRef = useRef<WillyMainChatRef>(null);
  const currentShopId = useAppSelector((state) => state.currentShopId);
  const currency = useStoreValue($currency);
  const activeAccounts = useStoreValue($activeAccounts);
  const industry = useStoreValue($industry);
  const timezone = useAppSelector((state) => state.shopTimezone);
  const defaultDialect = useStoreValue($dialect);

  const [conversationId, setConversationId] = useState<string>('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [buildMode, setBuildMode] = useState(false);
  const [workflowPanelOpen, setWorkflowPanelOpen] = useState(false);
  const [workflowIdToRun, setWorkflowIdToRun] = useState<string | undefined>();
  const [loadingGeneratedPrompts, setLoadingGeneratedPrompts] = useState(false);
  const [newWorkflowRunId, setNewWorkflowRunId] = useState<string | undefined>();
  const [wsWorkflows, setWsWorkflows] = useState<Record<string, WorkflowWsStatus>>({});
  const [wsSteps, setWsSteps] = useState<Record<string, StepWsStatus>>({});
  const [mainWorkflowStopped, setMainWorkflowStopped] = useState(false);

  const [generatedPrompts, setGeneratedPrompts] = useState<WillyPrompt[]>([]);
  const activeWorkflowId = useMemo(() => {
    return params.workflowId;
  }, [params.workflowId]);

  const workflowRunning = activeWorkflowId
    ? wsWorkflows[activeWorkflowId]?.status === 'running'
    : false;

  const {
    sequence,
    messages: runMessages,
    lastRun: lastRunData,
    insightStep: lastInsightStep,
    loadingRunMessages,
  } = useSequenceMessages({
    activeWorkflowId: activeWorkflowId,
    activeWorkflowRunId: !workflowRunning && newWorkflowRunId ? newWorkflowRunId : undefined,
  });

  useEffect(() => {
    setNewWorkflowRunId(undefined);
  }, [activeWorkflowId]);

  useSequenceFlowSocket({
    sequenceId: activeWorkflowId ?? '',
    runId: newWorkflowRunId,
    setWsWorkflows,
    setWsSteps,
    setMainWorkflowStopped,
  });

  const runSequence = useCallback(
    async (sequenceId: string) => {
      if (!activeAccounts) {
        return;
      }
      let currentMessageId = uuidV4();
      const newRunId = uuidV4();
      setNewWorkflowRunId(newRunId);
      const requestParams: RunSequenceRequest = {
        source: 'sequence',
        sequenceId,
        shopId: currentShopId,
        conversationId: newRunId,
        additionalShopIds: activeAccounts ?? [],
        messageId: currentMessageId,
        question: '<question will generate from sequence>',
        generateInsights: true,
        stream: false,
        currency,
        timezone: timezone,
        dialect: defaultDialect,
        industry: industry || 'other',
      };
      try {
        await sleep(1000);
        const response = await runWorkflow(requestParams);
        // setErrorRunSequence(undefined);
        // setCount(true);
        // setActiveWorkflowId(response.workflowId);
      } catch (error: any) {
        const errorMessage = error.message || 'Error running sequence';
        console.error(errorMessage);
        // setErrorRunSequence(errorMessage);
        // setLoadingAnswers(false);
      }
    },
    [activeAccounts, currentShopId, currency, defaultDialect, industry, timezone],
  );

  const preloadRunData: PreloadSequenceRunData = useMemo(() => {
    return convertMessageDataForPrompt(runMessages);
  }, [runMessages]);

  const getSamplePromptsForData = useCallback(
    async (data: string) => {
      if (currentShopId) {
        setLoadingGeneratedPrompts(true);
        const suggestedPrompts = await getSuggestedPromptsFromPreloadedData(data, currentShopId);
        setGeneratedPrompts(suggestedPrompts);
        setLoadingGeneratedPrompts(false);
      }
    },
    [currentShopId],
  );

  useEffect(() => {
    if (!!preloadRunData.length && activeWorkflowId) {
      setGeneratedPrompts([]);
      getSamplePromptsForData(JSON.stringify(preloadRunData));
    } else {
      setGeneratedPrompts([]);
    }
  }, [preloadRunData, getSamplePromptsForData, activeWorkflowId]);

  const codeActions = useMemo(() => {
    const actions: CodeAction[] = [
      {
        text: 'Run this query',
        onAction: (code) => {
          const message = `Run this query to get results from the database: ${code}`;
          mainChatRef.current?.handleSubmit?.(message, false, 'Run this query above');
        },
        supportedLanguages: ['sql'],
      },
    ];

    return actions;
  }, []);

  useEffect(() => {
    (async () => {
      if (!conversationId) {
        return;
      }
      const conversation = await _db().collection('conversations').doc(conversationId).get();
      const data = conversation.data();

      if (!data) {
        return;
      }

      if (!data.title) {
        await axiosInstance.post('/v2/willy/suggest-conversation-title', {
          shopId: currentShopId,
          conversationId,
        });
      }
    })();
  }, [conversationId, currentShopId]);

  return (
    <WillyMainChat
      source="chat"
      conversationId={conversationId}
      setConversationId={setConversationId}
      showLogo
      ref={mainChatRef}
      messages={messages}
      setMessages={setMessages}
      codeActions={codeActions}
      buildMode={buildMode}
      setBuildMode={setBuildMode}
      workflowIdToRun={workflowIdToRun}
      setWorkflowIdToRun={setWorkflowIdToRun}
      workflowPanelOpen={workflowPanelOpen}
      setWorkflowPanelOpen={setWorkflowPanelOpen}
      asPage={asPage}
      promptsToUse={activeWorkflowId ? generatedPrompts : undefined}
      sequenceRunData={activeWorkflowId ? preloadRunData : undefined}
      sequenceLastRunAt={activeWorkflowId ? lastRunData?.runAt : undefined}
      chatWithSequenceId={activeWorkflowId}
      sequenceName={sequence?.name}
      loadingGeneratedPrompts={loadingGeneratedPrompts}
      lastInsightStep={lastInsightStep}
      loadingInsightsStep={loadingRunMessages}
      rerunSequnece={runSequence}
      workflowRunning={workflowRunning}
    />
  );
};

export const Willy = computeFeatureFlags(FeatureFlag.CHAT_FF, WillyComponent, () => (
  <UpgradePageFallBack
    featureFlag={FeatureFlag.CHAT_FF}
    InAppContextBannerEnabled={false}
    title="Chat"
    description="Chat with the AI assistant that helps you effortlessly interact with your eCommerce business."
  />
));
