import {
  $customFilters,
  deleteCustomFilter,
  updateCustomFilter,
  createCustomFilter,
} from '$stores/willy/$customFilters';
import {
  FilterProperty,
  InsightsFilter,
  InsightsFilterQuery,
} from '@tw/types/module/services/insights';
import {
  Button,
  Flex,
  Icon,
  Text,
  ActionIcon,
  Switch,
  Anchor,
  Menu,
  Size,
} from '@tw/ui-components';
import { useEffect, useState } from 'react';
import { EditCustomFilterModal } from './EditCustomFilterModal';
import { SetFilterNameModal } from './SetFilterNameModal';
import { useSelector } from 'react-redux';
import { gradualReleaseFeatures } from 'ducks/shop';
import { analyticsEvents, dashboardsActions, genericEventLogger } from 'utils/dataLayer';
import { useStoreValue } from '@tw/snipestate';

export type WillyCustomFiltersProps = {
  availableFilterProps: FilterProperty[];
  onFiltersChanged: (filters: Partial<InsightsFilter>[]) => void;
  appliedFilters: Partial<InsightsFilter>[];
  level: 'dashboard' | 'widget';
  eventLoggerProps?: any;
};
export const WillyCustomFilters: React.FC<WillyCustomFiltersProps> = ({
  onFiltersChanged,
  level,
  availableFilterProps,
  appliedFilters: _appliedFilters = [],
  eventLoggerProps = {},
}) => {
  const customFilters = useStoreValue($customFilters);

  const [opened, setOpened] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState<Partial<InsightsFilter>[]>(_appliedFilters);
  const [editedFilter, setEditedFilter] = useState<Partial<InsightsFilter>>();
  const [namedFilter, setNamedFilter] = useState<Partial<InsightsFilter>>();
  const [unNamedFilter, setUnNamedFilter] = useState<Partial<InsightsFilter>>();

  const isFilterAvailable = (query: InsightsFilterQuery): boolean => {
    const allFilterProps = query.map((filter) => filter.property);
    return allFilterProps.every((property) => availableFilterProps.includes(property));
  };

  const onFiltersReset = () => {
    applyFilters([]);
    setUnNamedFilter(undefined);
  };

  const removeFilter = (filter: InsightsFilter) => {
    const newList = appliedFilters.filter(({ id }) => id !== filter.id);
    applyFilters(newList);
    genericEventLogger(analyticsEvents.DASHBOARDS, {
      action: dashboardsActions.DISABLE_QUICK_FILTER,
      ...getEeventProps(filter),
    });
  };

  const addFilter = (filter: InsightsFilter) => {
    const newList = [...appliedFilters, filter];
    applyFilters(newList);

    setUnNamedFilter(undefined);

    genericEventLogger(analyticsEvents.DASHBOARDS, {
      action: dashboardsActions.DISABLE_QUICK_FILTER,
      ...getEeventProps(filter),
    });
  };

  const deleteFilter = (filterId: string) => {
    deleteCustomFilter(filterId);

    if (appliedFilters.find(({ id }) => id === filterId)) {
      const newList = appliedFilters.filter(({ id }) => id !== filterId);
      applyFilters(newList);
    }
  };

  const renameFilter = (name: InsightsFilter['name']) => {
    if (!namedFilter || !namedFilter.id) return;

    updateCustomFilter(namedFilter.id, { name });
    setNamedFilter(undefined);
  };

  const updateFilter = (props: Partial<InsightsFilter>, apply = false) => {
    if (!editedFilter?.id) return;

    updateCustomFilter(editedFilter.id, props);
    setEditedFilter(undefined);

    if (appliedFilters.find(({ id }) => id === editedFilter.id) || apply) {
      const otherFilters = appliedFilters.filter((f) => f.id !== editedFilter.id);
      applyFilters([...otherFilters, { ...editedFilter, ...props }]);
    }
  };

  const createFilter = async (props: Partial<InsightsFilter>, apply = false) => {
    setEditedFilter(undefined);

    const newId = await createCustomFilter(props as InsightsFilter);
    if (!newId || !apply) return;

    const newList = [...appliedFilters, { ...props, id: newId }];
    applyFilters(newList);
  };

  const applyUnNamedFilter = (query: InsightsFilterQuery) => {
    setAppliedFilters([]);
    setUnNamedFilter({ query });
    setEditedFilter(undefined);
    onFiltersChanged([{ query }]);
  };

  const applyFilters = (filters: Partial<InsightsFilter>[]) => {
    setAppliedFilters(filters);
    onFiltersChanged(filters);
  };

  const getEeventProps = (filter?: Partial<InsightsFilter>) => {
    return {
      ...eventLoggerProps,
      filter_properties: filter?.query?.map((f) => f.property),
    };
  };

  // handle deleted filters
  useEffect(() => {
    const customFiltersIds = customFilters.map((f) => f.id);
    const relevantFilters = appliedFilters.filter((f) => !f.id || customFiltersIds.includes(f.id));
    if (relevantFilters.length === appliedFilters.length) return;

    setAppliedFilters([...relevantFilters]);
    onFiltersChanged([...relevantFilters]);
  }, [appliedFilters, customFilters, onFiltersChanged]);

  return (
    <Flex>
      <Menu opened={opened} zIndex={100} onClose={() => setOpened(false)} closeOnItemClick={false}>
        <Menu.Target>
          <Anchor
            underline="hover"
            size="sm"
            leftIcon={<Icon size={14} name="filter" />}
            onClick={() => {
              setOpened((prev) => !prev);
              genericEventLogger(analyticsEvents.DASHBOARDS, {
                action: dashboardsActions.CLICKED_QUICK_FILTERS,
                ...getEeventProps(),
              });
            }}
          >
            <Flex align="center" gap={5 as Size}>
              <Icon size={14} name="filter" color="named.6" />
              Quick Filters {appliedFilters.length ? `(${appliedFilters.length})` : ''}
            </Flex>
          </Anchor>
        </Menu.Target>

        <Menu.Dropdown w={300}>
          <Menu.Label my="sm">
            <Text color="gray.5" size="sm">
              <Text mb="sm" tt="uppercase">
                Quick Filters
              </Text>
              <Text>You can create new filters for this {level} for easy access.</Text>
            </Text>
          </Menu.Label>
          {customFilters.map((f, index) => (
            <Menu.Item my={0} py={'5px' as Size} key={index}>
              <Flex justify="space-between" gap="md" align="center">
                <Switch
                  checked={!!appliedFilters.find(({ id }) => id === f.id)}
                  disabled={!isFilterAvailable(f.query)}
                  label={<div className="line-clamp-1">{f.name}</div>}
                  size="xs"
                  onChange={(e) => {
                    if (e.target.checked) {
                      addFilter(f);
                    } else {
                      removeFilter(f);
                    }
                  }}
                />
                <Menu withinPortal={false}>
                  <Menu.Target>
                    <ActionIcon icon="menu-vertical" size="xs" />
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item onClick={() => setEditedFilter(f)}>Edit</Menu.Item>
                    <Menu.Item onClick={() => setNamedFilter(f)}>Rename</Menu.Item>
                    <Menu.Item onClick={() => deleteFilter(f.id)}>Delete</Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Flex>
            </Menu.Item>
          ))}
          <Menu.Label mt="xs" p="xs" px="md">
            <Anchor underline="never" fw={500} onClick={() => setEditedFilter(unNamedFilter || {})}>
              + Add Quick Filter
            </Anchor>
          </Menu.Label>
          <Menu.Divider />
          <Menu.Label p="md">
            <Button fullWidth variant="white" size="xs" onClick={onFiltersReset}>
              Clear All
            </Button>
          </Menu.Label>
        </Menu.Dropdown>
      </Menu>

      {namedFilter && (
        <SetFilterNameModal
          filter={namedFilter}
          onClose={() => setNamedFilter(undefined)}
          onSave={(name) =>
            namedFilter.id ? renameFilter(name) : createFilter({ ...editedFilter, name })
          }
        />
      )}
      {editedFilter && (
        <EditCustomFilterModal
          availableProps={availableFilterProps}
          filter={editedFilter}
          onSave={(filter) => {
            filter.id ? updateFilter(filter) : createFilter(filter);
            genericEventLogger(analyticsEvents.DASHBOARDS, {
              action: dashboardsActions.SAVE_QUICK_FILTER,
              ...getEeventProps(filter),
            });
          }}
          onClose={() => setEditedFilter(undefined)}
          onSaveAndApply={(filter) => {
            filter.id ? updateFilter(filter, true) : createFilter(filter, true);
            genericEventLogger(analyticsEvents.DASHBOARDS, {
              action: dashboardsActions.SAVE_AND_APPLY_QUICK_FILTER,
              ...getEeventProps(filter),
            });
          }}
        />
      )}
    </Flex>
  );
};
