import { DropZone } from '@shopify/polaris';
import GoogleSheetsAccountSelector, {
  GoogleSheetsSpreadsheetSelector,
  GoogleSheetsWorksheetSelector,
} from '../../GoogleSheetsAccountSelector';
import WillyDropZone from '../WillyDropZone';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import axiosInstance from 'utils/axiosInstance';
import { useStoreValue } from '@tw/snipestate';
import { $currentShopId } from '$stores/$shop';
import { Text } from '@tw/ui-components';

type FlowPreloadCSVProps = {
  onSave: (args: { url: string; fileName: string; destFileName: string }) => void;
  url: string;
  fileName: string;
  readOnly?: boolean;
  destFileName: string;
};
export const FlowPreloadGoogleCSV = ({
  onSave,
  url,
  fileName,
  readOnly,
  destFileName,
}: FlowPreloadCSVProps) => {
  const currentShopId = useStoreValue($currentShopId);
  const [errorUploadFile, setErrorUploadFile] = useState<string | null>(null);
  const [loadingUploadFile, setLoadingUploadFile] = useState<boolean>(false);

  const handleFileChange = async (file) => {
    if (currentShopId) {
      if (file?.[0] && file?.[0]?.type === 'text/csv') {
        await uploadFile(file[0]);
      } else {
        toast.error('Please select a CSV file.');
      }
    }
  };

  const uploadFile = async (file) => {
    if (currentShopId === null) {
      return;
    }
    setLoadingUploadFile(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('shopId', currentShopId);

    try {
      const { data } = await axiosInstance.post('/v2/sequences/upload-preload-csv', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      onSave({ url: data.url, fileName: data.fileName, destFileName: data.destFileName });
      toast.success('File uploaded successfully');
    } catch (e) {
      console.error(e);
      toast.error('Error uploading file, try again');
      setErrorUploadFile('Error uploading file, try again');
    }
    setLoadingUploadFile(false);
  };

  return (
    <div>
      <DropZone disabled={readOnly} onDrop={(file) => handleFileChange(file)}>
        <div className="flex flex-col items-center w-full justify-center h-full">
          {!!url && !loadingUploadFile ? (
            <div className="flex flex-col gap-4 w-full  justify-center items-center">
              <Text fw={600}>Uploaded File:</Text>
              <Text fs="italic" fw={500}>
                {fileName}
              </Text>
            </div>
          ) : (
            <Text color="one.6" fw={600}>
              Upload CSV
            </Text>
          )}
          {loadingUploadFile && <Text>Loading...</Text>}
          {errorUploadFile && <Text color="red.6">{errorUploadFile}</Text>}
        </div>
      </DropZone>
    </div>
  );
};
