import ClockIconSVG from 'components/Icons/ClockIconSVG';
import { loadOrder } from 'ducks/orders';
import moment from '@tw/moment-cached/module/timezone';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Lottie from 'react-lottie-player';
import { useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { type RootState } from 'reducers/RootType';

import { Card, Icon, Link, SkeletonDisplayText, Spinner, Tooltip } from '@shopify/polaris';

import { CURRENCIES } from '@tw/constants';
import cartAnimationData from '../../lotties/cart_loader.json';
import { CustomerJourney } from '../attribution/components';
import { formatConversionValue, getJourney } from '../attribution/utils';
import allServices from 'constants/services';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { computeFeatureFlags } from 'feature-flag-system';
import { UpgradePageFallBack } from 'feature-flag-system/components';
import { useDarkMode } from 'dark-mode-control';
import { gradualReleaseFeatures } from 'ducks/shop';
import {
  CustomerProfileModal,
  CustomerProfileModalProps,
} from 'components/Insights/CustomerProfile/Activities/CustomerProfileModal';
import { FullShopifyOrder } from '@tw/types';
import { Anchor, Text, Icon as TwIcon } from '@tw/ui-components';
import { cx } from 'utils/cx';
import { useStoreValue } from '@tw/snipestate';
import { $currency, $shopCurrency, $currentShopId } from '../../$stores/$shop';

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export type OrdersWidgetProps = {
  ordersKeyed: Record<any, any>;
  loading: boolean;
  fetchData: () => void;
  moreOrders: any[];
  hasMore: boolean;
};

const overlapsChannels = Object.values(allServices);

const OrdersWidget = ({ ordersKeyed, loading, fetchData, moreOrders, hasMore }) => {
  const currency = useStoreValue($shopCurrency);
  const [rows, setRows] = useState<any[]>([]);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [orderId, setOrderId] = useState<number | null>(0);
  const [order, setOrder] = useState<FullShopifyOrder>();
  const [loadingPixel, setLoadingPixel] = useState(false);
  const [journey, setJourney] = useState([]);
  const [journeyOpen, setJourneyOpen] = useState(false);
  const [campaigns, setCampaigns] = useState<any[]>([]);
  const [animateIcons, setAnimateIcons] = useState(false);
  const [lastItemPosition, setLastItemPosition] = useState(Infinity);
  const { useNewModels } = useSelector((state: RootState) => state.attribution);
  const [customerProfileProps, setCustomerProfileProps] = useState<CustomerProfileModalProps>();

  const { new_activities_timeline: newActivitiesTimeline } = useSelector(gradualReleaseFeatures);

  const firstRow = useCallback(
    () => [
      <div key="placeholder-1" className="placeholder-row">
        <div className="lottie-cart">
          <Lottie
            loop
            play
            animationData={cartAnimationData}
            style={{ height: 20, width: 20 }}
            rendererSettings={{ preserveAspectRatio: 'xMinYMin slice' }}
          />
        </div>
        <div className="table-skeleton">
          <SkeletonDisplayText size="small" />
        </div>
      </div>,
      <div key="placeholder-2" className="table-skeleton">
        <SkeletonDisplayText size="small" />
      </div>,
      // <div key="placeholder-3" className='table-skeleton'><SkeletonDisplayText size='small' /></div>,
      <div key="placeholder-4" className="table-skeleton">
        <SkeletonDisplayText size="small" />
      </div>,
      <div key="placeholder-5" className="table-skeleton dots">
        <SkeletonDisplayText size="small" />
        <SkeletonDisplayText size="small" />
        <SkeletonDisplayText size="small" />
      </div>,
    ],
    [],
  );

  const formatName = useCallback((order) => {
    const { firstName, lastName, orderName, orderId } = order;
    if (!firstName && !lastName) return '';
    if (firstName === 'blocked' && lastName === 'blocked') {
      const isWooOrder = typeof orderName === 'string' && orderName.startsWith('wc');
      const orderNameToShow = isWooOrder ? orderId : orderName;
      return `${orderNameToShow} (name blocked)`;
    }
    return `${firstName ? firstName : ''} ${lastName ? lastName : ''}`;
  }, []);

  const openCustomerJourney = useCallback(
    async (order) => {
      const shopId = order.integration_id || $currentShopId.get();

      if (orderId === order.orderId) {
        setJourneyOpen(false);
        setOrderId(null);
      } else {
        setCustomerProfileProps({
          shopifyCustomerId: order.customerId?.toString(),
          customerName: formatName(order),
          startDate: order.eventDate,
          customerCreatedDate: undefined,
        });
        setLoadingPixel(true);
        setOrderId(order.orderId);
        setJourneyOpen(true);
        const orderPromise = loadOrder(order.orderId, shopId);
        const journeyPromise = getJourney(shopId, order, useNewModels);
        const [fullOrder, journey] = await Promise.all([orderPromise, journeyPromise]);
        setCustomerProfileProps((prev = {}) => ({
          ...prev,
          customerCreatedDate: fullOrder?.customer?.created_at,
        }));
        setJourney(journey);

        setOrder(fullOrder);
        setLoadingPixel(false);

        setCampaigns(
          Object.values<any>(order.pixel_attribution?.lastPlatformClick || {}).map(
            (platform) => platform.name,
          ),
        );
      }
    },
    [formatName, orderId, useNewModels],
  );

  const formatDate = useCallback((date) => {
    const now = moment();
    const dayDiff = moment(now).diff(date, 'days');

    if (dayDiff < 1) {
      return moment(date).format('LT');
    } else {
      return moment(date).format('ll');
    }
  }, []);

  const formatOrderJourney = useCallback((order) => {
    const allPlatforms = order.sources.filter((channelName) =>
      Object.values(overlapsChannels)
        .filter((x) => x.id !== 'tw_referrer')
        .map((c) => c.id)
        .includes(channelName as any),
    );
    return allPlatforms.map((name) => {
      return {
        icon: Object.values(overlapsChannels)
          .find((p) => p.id === name)
          ?.icon?.({ small: false }),
        name: Object.values(overlapsChannels).find((p) => p.id === name)?.title,
        id: Object.values(overlapsChannels).find((p) => p.id === name)?.id,
      };
    });
  }, []);

  const renderRow = useCallback(
    (order, i) => {
      const orderIndex = i;
      if (!order || !Object.keys(order).length) return <></>;
      return [
        <div
          key={`row-${i}-col-1`}
          className="order-cell cell-name"
          id="tr-pixel-order-widget-customer-name"
        >
          <Anchor fz="sm" underline="never" onClick={() => openCustomerJourney(order)}>
            {formatName(order)}
          </Anchor>
        </div>,
        <div key={`row-${i}-col-2`} className="order-cell cell-price">
          {formatConversionValue(order.totalPrice, CURRENCIES[currency])}
        </div>,
        // <div key={`row-${i}-col-3`} className='order-cell'>{formatConversionValue(order.total_price, CURRENCIES[currency])}</div>,
        <div key={`row-${i}-col-4`} className="flex gap-4 items-center flex-nowrap">
          <TwIcon name="clock" size={10} />
          <Text fz="sm">{formatDate(order.eventDate)}</Text>
        </div>,
        <div key={`row-${i}-col-5`} className="order-sources order-cell">
          {formatOrderJourney(order).map((p, i) => {
            return (
              <div className="single-order-platform" key={`order_platform_${i}`}>
                <Tooltip content={p.name}>{p.name === 'Excluded' ? '' : p.icon}</Tooltip>
                {animateIcons && orderIndex === 0 && overlapsChannels[p.id!]?.iconOptions && (
                  <span style={{ position: 'absolute', left: '-15px' }}>
                    <Lottie
                      loop={overlapsChannels[p.id!]?.iconOptions?.loop}
                      play={overlapsChannels[p.id!]?.iconOptions?.autoplay}
                      animationData={overlapsChannels[p.id!]?.iconOptions?.animationData}
                      rendererSettings={overlapsChannels[p.id!]?.iconOptions?.rendererSettings}
                      style={{ height: 60, width: 60 }}
                    />
                  </span>
                )}
                <div className="connectors">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            );
          })}
        </div>,
      ];
    },
    [animateIcons, currency, formatDate, formatName, formatOrderJourney, openCustomerJourney],
  );

  const animateFirstRow = useCallback(async () => {
    const cart: any = document.querySelector('.placeholder-row .lottie-cart');
    if (!cart) return;
    cart.style.left = '100%';
    await delay(500);
    setAnimateIcons(true);
    await delay(1500);
    setAnimateIcons(false);
    cart.style.opacity = '0';
    cart.style.left = '1%';
    cart.style.opacity = '1';
    return;
  }, []);

  useEffect(() => {
    const orders: any[] = Object.values<any>(ordersKeyed || {}).sort((a, b) =>
      a.eventDate < b.eventDate ? 1 : -1,
    );

    if (!orders || !orders.length) {
      return;
    }
    if (isFirstTime) {
      setRows([{}, ...orders]);
      setIsFirstTime(false);
    } else {
      setShowPlaceholder(false);
      setRows(orders);
      animateFirstRow().then(() => {
        setShowPlaceholder(true);
        setRows(() => {
          return [{}, ...orders];
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersKeyed]);

  useEffect(() => {
    setRows((rows) => {
      setLastItemPosition(rows.length);
      return [...rows, ...moreOrders];
    });
  }, [moreOrders]);

  return (
    <Card
      title={
        <Text fw={600} size="xl" id="att-live-orders">
          Live Orders
        </Text>
      }
    >
      <div className="orders-lottie-table">
        {loading && (
          <div className="orders-table-loader-wrap">
            <Spinner size="large" />
          </div>
        )}
        {!loading && (
          <div className="orders-table">
            <InfiniteScroll
              dataLength={rows.length}
              next={fetchData}
              loader={
                <div className="flex-container middle center">
                  <Spinner size="small" />
                </div>
              }
              hasMore={hasMore}
              height={400}
              scrollThreshold={0.95}
              endMessage={
                <p className="flex-container center no-more-orders-msg">
                  No more orders for this date range
                </p>
              }
            >
              <CSSTransition timeout={2000} classNames="first-order" in={showPlaceholder}>
                <div className="row first-row">{firstRow()}</div>
              </CSSTransition>
              <TransitionGroup className="orders-list">
                {rows.map((order, i) => {
                  return (
                    <Fragment key={order.id + '_' + i}>
                      {lastItemPosition === i && <span className="new-orders-divider"></span>}
                      <CSSTransition timeout={1000} classNames="order-item">
                        <div className="row">{renderRow(order, i)}</div>
                      </CSSTransition>
                    </Fragment>
                  );
                })}
              </TransitionGroup>
            </InfiniteScroll>
          </div>
        )}
      </div>
      {!newActivitiesTimeline && (
        <CustomerJourney
          loading={loadingPixel}
          order={order}
          journey={journey}
          isOpen={journeyOpen}
          campaign={{ name: campaigns.join(', ') }}
          onClose={() => setJourneyOpen(false)}
        />
      )}
      {newActivitiesTimeline && journeyOpen && (
        <CustomerProfileModal
          onClose={() => setJourneyOpen(false)}
          {...customerProfileProps}
        ></CustomerProfileModal>
      )}
    </Card>
  );
};

export default computeFeatureFlags<OrdersWidgetProps>(
  [FeatureFlag.PIXEL_LIVE_ORDERS_FF, FeatureFlag.PIXEL_LIMITED_FF],
  OrdersWidget,
  () => {
    const darkMode = useDarkMode();

    return (
      <div
        className={cx(
          '[&>.Polaris-Card]:!h-[452px]',
          darkMode ? '[&>.Polaris-Card]:!bg-transparent' : '[&>.Polaris-Card]:!bg-white',
        )}
      >
        <Card
          title={
            <Text fw={600} size="xl" id="att-live-orders">
              Live Orders
            </Text>
          }
        >
          <UpgradePageFallBack
            InAppContextBannerEnabled={false}
            title="Live Orders"
            description="See all orders in real-time"
            featureFlag={FeatureFlag.PIXEL_LIVE_ORDERS_FF}
          />
        </Card>
      </div>
    );
  },
);
