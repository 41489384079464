import { $derived } from '@tw/snipestate';
import _db, { toArray } from 'utils/DB';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import firestore = firebase.firestore;
import { $userId } from './$user';

type GlobalConfig = {
  id:
    | 'metricsNexus'
    | 'shopifyNexus'
    | 'reportsNexus'
    | 'forceBQDialect'
    | 'adsOnClickhouse'
    | 'willyWay'
    | 'fullyCHPixel'
    | 'force2OnBQ'
    | 'forceCHDialect'
    | 'tw3';
  state?: string | boolean | number;
  planPreviewStartNotifyAfter: number;
  sqlTeamReportCredit?: number;
};

const ssrGlobalConfig: GlobalConfig[] = window.twGlobalConfig || [];

const $privateGlobalConfig = $derived(async (get) => {
  if (ssrGlobalConfig.length || !get($userId)) return ssrGlobalConfig;
  const snippets = toArray(await firestore().collection('globalSettings').get());
  return snippets as GlobalConfig[];
});

export const $globalConfig = $derived((get) => get($privateGlobalConfig).data || []);
