import { MetricsClientDictionary } from 'types/metrics';
import { shopMetrics } from './shop-metrics';
import allServices from '../services';
import { metrics as allMetrics } from '@tw/constants/module/Metrics/allMetrics';

const defaultMetrics = Object.values(allMetrics).reduce((acc, metric) => {
  return {
    ...acc,
    [metric.key]: {
      ...metric,
      Tip: () => {
        return <>{metric.label}</>;
      },
    },
  };
}, {});

export const metrics: MetricsClientDictionary = {
  ...defaultMetrics,
  spend: {
    ...allMetrics.spend!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>Amount Spent on {channelName}</>;
    },
  },
  conversionValue: {
    ...allMetrics.conversionValue!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>{channelName}'s reported Conversion Value</>;
    },
  },
  roas: {
    ...allMetrics.roas!,
    Tip: () => {
      return <>Return On Ad Spend</>;
    },
  },
  purchases: {
    ...allMetrics.purchases!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>{channelName}'s reported Number of Purchases</>;
    },
  },
  clicks: {
    ...allMetrics.clicks!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>{channelName}'s reported Clicks</>;
    },
  },
  impressions: {
    ...allMetrics.impressions!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>{channelName}'s reported Impressions</>;
    },
  },
  cpc: {
    ...allMetrics.cpc!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>{channelName}'s reported Cost per Click</>;
    },
  },
  ctr: {
    ...allMetrics.ctr!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>{channelName}'s reported Click Through Rate</>;
    },
  },
  cpm: {
    ...allMetrics.cpm!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>{channelName}'s reported Cost Per Mille (cost per 1000 impressions)</>;
    },
  },
  cpa: {
    ...allMetrics.cpa!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>{channelName}'s reported Cost Per Acquisition</>;
    },
  },
  aov: {
    ...allMetrics.aov!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>{channelName}'s reported Average Order Value</>;
    },
  },
  productCpa: {
    ...allMetrics.productCpa!,
    Tip: () => {
      return <>Cost Per Acquisition (ad spend / orders with product)</>;
    },
  },
  productAov: {
    ...allMetrics.productAov!,
    Tip: () => {
      return <>Value of Orders with Product / # Orders with Product</>;
    },
  },
  orderValue: {
    ...allMetrics.orderValue!,
    Tip: () => {
      return <>Total Value of Orders with Product</>;
    },
  },
  productNcAov: {
    ...allMetrics.productNcAov!,
    Tip: () => {
      return <>Value of New Customer Orders with Product / # New Customer Orders with Product</>;
    },
  },
  ncOrderValue: {
    ...allMetrics.ncOrderValue!,
    Tip: ({ sourceId }) => {
      return <>Total Value of New Customer Orders with Product</>;
    },
  },
  allConversionValue: {
    ...allMetrics.allConversionValue!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>{channelName}'s reported All Conversion Value</>;
    },
  },
  allConversions: {
    ...allMetrics.allConversions!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>{channelName}'s reported All Conversions</>;
    },
  },
  outboundClicks: {
    ...allMetrics.outboundClicks!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>{channelName}'s reported Outbound Clicks</>;
    },
  },
  outboundCtr: {
    ...allMetrics.outboundCtr!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>{channelName}'s reported Outbound Clicks Through Rate</>;
    },
  },
  oneDayViewConversionValue: {
    ...allMetrics.oneDayViewConversionValue!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>{channelName}'s reported 1 day view Conversion Value</>;
    },
  },
  threeSecondVideoView: {
    ...allMetrics.threeSecondVideoView!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>{channelName}'s reported 3 seconds Video View</>;
    },
  },
  sixSecondVideoView: {
    ...allMetrics.sixSecondVideoView!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>{channelName}'s reported 6 seconds Video View</>;
    },
  },
  totalVideoView: {
    ...allMetrics.totalVideoView!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>{channelName}'s reported Total Video View</>;
    },
  },
  thumbStopRatio: {
    ...allMetrics.thumbStopRatio!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>{channelName}'s reported Thumb Stop Ratio</>;
    },
  },
  pixelRoas: {
    ...allMetrics.pixelRoas!,
    Tip: () => {
      return <>Triple Pixel ROAS</>;
    },
  },
  pixelNcRoas: {
    ...allMetrics.pixelNcRoas!,
    Tip: () => {
      return <>Triple Pixel New Customer Return On Ad Spend</>;
    },
  },
  pixelPurchases: {
    ...allMetrics.pixelPurchases!,
    Tip: () => {
      return <>Triple Pixel Purchases</>;
    },
  },
  pixelNcPurchases: {
    ...allMetrics.pixelNcPurchases!,
    Tip: () => {
      return <>Triple Pixel New Customer Purchases</>;
    },
  },
  pixelConversionValue: {
    ...allMetrics.pixelConversionValue!,
    Tip: () => {
      return <>Triple Pixel Conversion Value</>;
    },
  },
  pixelNcConversionValue: {
    ...allMetrics.pixelNcConversionValue!,
    Tip: () => {
      return <>Triple Pixel New Customer Conversion Value</>;
    },
  },
  pixelVisitors: {
    ...allMetrics.pixelVisitors!,
    Tip: () => {
      return <>Triple Pixel Sessions</>;
    },
    hideTotalDisclaimer: true,
  },
  pixelUniqueVisitors: {
    ...allMetrics.pixelUniqueVisitors!,
    Tip: () => {
      return <>Triple Pixel Unique Visitors</>;
    },
    hideTotalDisclaimer: true,
  },
  pixelCostPerVisitor: {
    ...allMetrics.pixelCostPerVisitor!,
    Tip: () => {
      return <>Triple Pixel Cost Per Visitors</>;
    },
    hideTotalDisclaimer: true,
  },
  pixelNewVisitors: {
    ...allMetrics.pixelNewVisitors!,
    Tip: () => {
      return <>Triple Pixel New Visitors</>;
    },
    hideTotalDisclaimer: true,
  },
  pixelCostPerNewVisitor: {
    ...allMetrics.pixelCostPerNewVisitor!,
    Tip: () => {
      return <>Triple Pixel Cost Per New Visitors</>;
    },
    hideTotalDisclaimer: true,
  },
  pixelUniqueAtc: {
    ...allMetrics.pixelUniqueAtc!,
    Tip: () => {
      return <>Triple Pixel Add to Carts from Unique Customers</>;
    },
  },
  pixelUniqueCheckout: {
    ...allMetrics.pixelUniqueCheckout!,
    Tip: () => {
      return <>Pixel Unique Checkouts Started</>;
    },
  },
  pixelUniqueAddress: {
    ...allMetrics.pixelUniqueAddress!,
    Tip: () => {
      return <>Pixel Unique Address Info Submissions (Checkout)</>;
    },
  },
  pixelUniqueShipping: {
    ...allMetrics.pixelUniqueShipping!,
    Tip: () => {
      return <>Pixel Unique Shipping Info Submissions (Checkout)</>;
    },
  },
  pixelUniqueContact: {
    ...allMetrics.pixelUniqueContact!,
    Tip: () => {
      return <>Pixel Unique Contact Info Submissions (Checkout)</>;
    },
  },
  pixelUniquePayment: {
    ...allMetrics.pixelUniquePayment!,
    Tip: () => {
      return <>Pixel Unique Payment Info Submissions (Checkout)</>;
    },
  },
  pixelCostPerAtc: {
    ...allMetrics.pixelCostPerAtc!,
    Tip: () => {
      return <>Triple Pixel Cost Per Add to Cart</>;
    },
  },
  pixelCpa: {
    ...allMetrics.pixelCpa!,
    Tip: () => {
      return <>Triple Pixel Cost Per Acquisition</>;
    },
  },
  pixelNcCpa: {
    ...allMetrics.pixelNcCpa!,
    Tip: () => {
      return <>Triple Pixel New Customer Cost Per Acquisition</>;
    },
  },
  pixelAov: {
    ...allMetrics.pixelAov!,
    Tip: () => {
      return <>Triple Pixel Average Order Value</>;
    },
  },
  pixelNcAov: {
    ...allMetrics.pixelNcAov!,
    Tip: () => {
      return <>Triple Pixel New Customer Average Order Value</>;
    },
  },
  pixelConversionRate: {
    ...allMetrics.pixelConversionRate!,
    Tip: () => {
      return <>Triple Pixel Conversion Rate (Purchases / Sessions)</>;
    },
  },
  pixelNcConversionRate: {
    ...allMetrics.pixelNcConversionRate!,
    Tip: () => {
      return <>Triple Pixel New Customer Conversion Rate (New Customer Purchases / New Visitors)</>;
    },
  },
  pixelEmailSignup: {
    ...allMetrics.pixelEmailSignup!,
    Tip: () => {
      return <>Triple Pixel Email Sign Up</>;
    },
  },
  pixelCostPerEmailSignup: {
    ...allMetrics.pixelCostPerEmailSignup!,
    Tip: () => {
      return <>Triple Pixel Cost Per Email Sign Up</>;
    },
  },
  pixelEmailSignupRate: {
    ...allMetrics.pixelEmailSignupRate!,
    Tip: () => {
      return <>Triple Pixel Email Sign Up Rate</>;
    },
  },
  pixelCogs: {
    ...allMetrics.pixelCogs!,
    Tip: () => {
      return <>Triple Pixel Cost of Goods</>;
    },
  },
  pixelNcCogs: {
    ...allMetrics.pixelNcCogs!,
    Tip: () => {
      return <>Triple Pixel New Customer Cost of Goods</>;
    },
  },
  pixelProfit: {
    ...allMetrics.pixelProfit!,
    Tip: () => {
      return <>Triple Pixel Profit (Conversion Value - COGS - Ad Spend)</>;
    },
  },
  pixelCvDelta: {
    ...allMetrics.pixelCvDelta!,
    Tip: ({ sourceId }) => {
      const sourceName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>Triple Pixel Conversion Value Delta (Pixel CV - {sourceName} CV)</>;
    },
  },
  pixelNewVisitorPerc: {
    ...allMetrics.pixelNewVisitorPerc!,
    Tip: () => {
      return <>Triple Pixel New Visitor Percent</>;
    },
  },
  pixelNcPurchasesPerc: {
    ...allMetrics.pixelNcPurchasesPerc!,
    Tip: () => {
      return <>Triple Pixel New Customer Purchases Percent</>;
    },
  },
  campaignsConversionValue: {
    ...allMetrics.campaignsConversionValue!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>{channelName}'s Campaigns Conversion Value</>;
    },
  },
  flowsConversionValue: {
    ...allMetrics.flowsConversionValue!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>{channelName}'s Sessions Conversion Value</>;
    },
  },
  unsubscribed: {
    ...allMetrics.unsubscribed!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>{channelName}'s Unsubscribed from Email or SMS</>;
    },
  },
  subscribedToList: {
    ...allMetrics.subscribedToList!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>{channelName}'s Subscribed Email or SMS</>;
    },
  },
  openedEmail: {
    ...allMetrics.openedEmail!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>{channelName}'s Email or SMS Opened</>;
    },
  },
  receivedEmail: {
    ...allMetrics.receivedEmail!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>{channelName}'s Received Email or SMS</>;
    },
  },
  clickedSms: {
    ...allMetrics.clickedSms!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>{channelName}'s SMS Clicks</>;
    },
  },
  clickedEmail: {
    ...allMetrics.clickedEmail!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>{channelName}'s Email Clicks</>;
    },
  },
  blendedConversionValue: {
    ...allMetrics.blendedConversionValue!,
    Tip: () => {
      return <>Triple Whale Blended Conversion value</>;
    },
  },
  blendedSpend: {
    ...allMetrics.blendedSpend!,
    Tip: () => {
      return <>Triple Whale Blended Spend</>;
    },
  },
  blendedRoas: {
    ...allMetrics.blendedRoas!,
    Tip: () => {
      return <>Triple Whale Blended ROAS</>;
    },
  },
  blendedNewCustomerRoas: {
    ...allMetrics.blendedNewCustomerRoas!,
    Tip: () => {
      return <>Triple Whale Modeled Blended New Customer ROAS</>;
    },
  },
  blendedNcCpa: {
    ...allMetrics.blendedNcCpa!,
    Tip: () => {
      return <>Triple Whale Modeled Blended New Customer Cost Per Acquisition</>;
    },
  },
  blendedPoas: {
    ...allMetrics.blendedPoas!,
    Tip: () => {
      return <>Triple Whale Modeled Blended POAS</>;
    },
  },
  blendedCpa: {
    ...allMetrics.blendedCpa!,
    Tip: () => {
      return <>Triple Whale Modeled Blended Cost Per Acquisition</>;
    },
  },
  itemsInInventory: {
    ...allMetrics.itemsInInventory!,
    Tip: () => {
      return <>Quantity of Products in Inventory</>;
    },
  },
  itemsSoldAvg: {
    ...allMetrics.itemsSoldAvg!,
    Tip: () => {
      return <>Average # Sold in selected date range</>;
    },
  },
  avgSold30: {
    ...allMetrics.avgSold30!,
    Tip: () => {
      return <>Average # Sold in previous 30 days</>;
    },
  },
  totalSold30: {
    ...allMetrics.totalSold30!,
    Tip: () => {
      return <>Total # Sold in previous 30 days</>;
    },
  },
  itemsSoldTotal: {
    ...allMetrics.itemsSoldTotal!,
    Tip: () => {
      return <># Products sold in date range</>;
    },
  },
  grossProfit: {
    ...allMetrics.grossProfit!,
    Tip: () => {
      return <>Revenue minus cost</>;
    },
  },
  ncGrossProfit: {
    ...allMetrics.ncGrossProfit!,
    Tip: () => {
      return <>New Customers Revenue minus cost</>;
    },
  },
  returnRate: {
    ...allMetrics.returnRate!,
    Tip: () => {
      return <>Return Rate</>;
    },
  },
  customerCount: {
    ...allMetrics.customerCount!,
    Tip: () => {
      return <># Customers who bought product in date range</>;
    },
  },
  returnsTotal: {
    ...allMetrics.returnsTotal!,
    Tip: () => {
      return <>Total Returns of Product in date range</>;
    },
  },
  conversionRate: {
    ...allMetrics.conversionRate!,
    Tip: () => {
      return <>Triple Whale's reported Conversion Rate (Purchases / Sessions)</>;
    },
  },
  daysOfStock: {
    ...allMetrics.daysOfStock!,
    Tip: () => {
      return <>Days of stock on hand (Inventory / 30 Day Avg Sales)</>;
    },
  },
  contributionMargin: {
    ...allMetrics.contributionMargin!,
    Tip: () => {
      return <>Contribution Margin: Gross Profit - ad spend</>;
    },
  },
  contributionMarginPerUnit: {
    ...allMetrics.contributionMarginPerUnit!,
    Tip: () => {
      return <>Contribution Margin Per Unit: Gross Profit - ad spend / Total Items Sold</>;
    },
  },
  dateStockRunsOut: {
    ...allMetrics.dateStockRunsOut!,
    Tip: () => {
      return <>Predicted Out of Stock date: Today + Days of Stock</>;
    },
  },
  rpr: {
    ...allMetrics.rpr!,
    Tip: () => {
      return <>Repeat Purchase Rate</>;
    },
  },
  ltv60: {
    ...allMetrics.ltv60!,
    Tip: () => {
      return <>60 day LTV (Lifetime Value) = Sales / Unique Customers </>;
    },
  },
  ltv90: {
    ...allMetrics.ltv90!,
    Tip: () => {
      return <>90 day LTV (Lifetime Value) = Sales / Unique Customers </>;
    },
  },
  ltv180: {
    ...allMetrics.ltv180!,
    Tip: () => {
      return <>180 day LTV (Lifetime Value) = Sales / Unique Customers </>;
    },
  },
  ltv365: {
    ...allMetrics.ltv365!,
    Tip: () => {
      return <>365 day LTV (Lifetime Value) = Sales / Unique Customers </>;
    },
  },
  ncRevenue: {
    ...allMetrics.ncRevenue!,
    Tip: () => {
      return <>Gross revenue by product from new customers</>;
    },
  },
  revenue: {
    ...allMetrics.revenue!,
    Tip: () => {
      return <>Gross revenue by product</>;
    },
  },
  ordersWithProduct: {
    ...allMetrics.ordersWithProduct!,
    Tip: () => {
      return <># of Orders which included this product</>;
    },
  },
  ncOrdersWithProduct: {
    ...allMetrics.ncOrdersWithProduct!,
    Tip: () => {
      return <># of Orders which included this product from new customers</>;
    },
  },
  adsInventory: {
    ...allMetrics.adsInventory!,
    Tip: ({ sourceId }) => {
      const channelName = allServices[sourceId]?.title || sourceId || 'Channel';
      return <>Inventory Status of SKU's in this {channelName} Campaign/Ad Set/Ad</>;
    },
  },
  avgPurchasePrice: {
    ...allMetrics.avgPurchasePrice!,
    Tip: () => {
      return <>Average price of the product across all orders with product</>;
    },
  },
  modePurchasePrice: {
    ...allMetrics.modePurchasePrice!,
    Tip: () => {
      return <>Most common product price across all orders with product</>;
    },
  },
  addedToCartEvents: {
    ...allMetrics.addedToCartEvents!,
    Tip: () => {
      return (
        <>
          Add to Carts are only available on the product level (not available in variant report)
          after May, 2023.
        </>
      );
    },
  },
  subscriptionChurnsRate: {
    ...allMetrics.subscriptionChurnsRate!,
    Tip: () => {
      return <>Churns / Sessions</>;
    },
  },
  subscriptionSignUpsRate: {
    ...allMetrics.subscriptionSignUpsRate!,
    Tip: () => {
      return <>Sign Ups / Sessions</>;
    },
  },
  pixelAvgPageViews: {
    ...allMetrics.pixelAvgPageViews!,
    Tip: () => {
      return <>Pixel Average Page Views</>;
    },
    hideTotalDisclaimer: true,
  },
  pixelAvgTimeOnSite: {
    ...allMetrics.pixelAvgTimeOnSite!,
    Tip: () => {
      return <>Pixel Average Session Duration</>;
    },
    hideTotalDisclaimer: true,
  },
  pixelBounceRate: {
    ...allMetrics.pixelBounceRate!,
    Tip: () => {
      return <>Pixel Bounce Rate</>;
    },
    hideTotalDisclaimer: true,
  },
  ...shopMetrics,
};
