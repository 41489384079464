import { useCallback, useState } from 'react';
import {
  DialectWithBoth,
  stepActionType,
  WorkflowStepBase,
  WorkflowStepLoop,
} from '../types/willyTypes';
import { FlowStepWithText } from './FlowStepWithText';
import { Button, Text, TextInput } from '@tw/ui-components';
import { FlowStepWithFullEditor } from './FlowStepWithFullEditor';
import { convertDataToJson, executeCustomQuery } from '../utils/willyUtils';
import { useStoreValue } from '@tw/snipestate';
import { $activeAccounts, $currency, $currentShopId, $timezone } from '$stores/$shop';
import { Prism as ReactSyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

type FlowLoopStepProps = {
  step: WorkflowStepLoop & WorkflowStepBase;
  onSave: (query: string, params: Record<string, any>, maxIterations: number | null) => void;
  readOnly: boolean;
  toolConfig?: stepActionType;
  depth: number;
  dialect: DialectWithBoth;
  setIsPristine: (isPristine: boolean) => void;
};

export const FlowLoopStep: React.FC<FlowLoopStepProps> = ({
  step,
  onSave,
  readOnly,
  toolConfig,
  depth,
  dialect,
  setIsPristine,
}) => {
  const shopId = useStoreValue($currentShopId);
  const activeAccounts = useStoreValue($activeAccounts);
  const currency = useStoreValue($currency);
  const timezone = useStoreValue($timezone);

  const [query, setQuery] = useState(step.query);
  const [params, setParams] = useState(step.queryParams || {});
  const [showModal, setShowModal] = useState(false);
  const [testing, setTesting] = useState(false);
  const [result, setResult] = useState<Record<string, string | number | null>[] | null>(null);
  const [maxIterations, setMaxIterations] = useState(step.maxIterations);

  const handleTest = useCallback(async () => {
    if (!shopId || !activeAccounts || !currency || !timezone || dialect === 'both') {
      return;
    }
    setTesting(true);
    const result = await executeCustomQuery({
      query,
      dialect,
      shopId,
      activeAccounts,
      currency,
      timezone,
      page: 1,
      pageSize: maxIterations || Number.MAX_SAFE_INTEGER,
    });
    setTesting(false);
    const asJson = convertDataToJson(result.data || []);
    setResult(asJson);
  }, [activeAccounts, currency, dialect, maxIterations, query, shopId, timezone]);

  return (
    <div className="flex flex-col gap-2">
      <FlowStepWithText
        text={query}
        readOnly={readOnly}
        textChange={(text) => {
          setIsPristine(false);
          setQuery(text);
          onSave(text, params, maxIterations);
        }}
        toolConfig={toolConfig}
        setIsPristine={setIsPristine}
        variables={step.variables || []}
        depth={depth}
      />
      <TextInput
        type="number"
        label="Max iterations"
        value={maxIterations || ''}
        onChange={(e) => {
          setMaxIterations(Number(e));
          setIsPristine(false);
          onSave(query, params, Number(e));
        }}
      />
      <div className="flex justify-end gap-2 items-center">
        <Button variant="white" onClick={() => setShowModal(true)} disabled={readOnly}>
          Edit in full editor
        </Button>
        <Button disabled={testing} loading={testing} variant="primary" onClick={handleTest}>
          Test
        </Button>
      </div>
      <FlowStepWithFullEditor
        opened={showModal}
        onClose={() => setShowModal(false)}
        query={query}
        setQuery={setQuery}
        params={params}
        setParams={setParams}
        onSave={(query, params) => onSave(query, params, maxIterations)}
      />
      {result && (
        <div className="flex flex-col gap-2">
          <Text>
            This loop will run {result.length} times, each iteration with the following payload:
          </Text>
          <ReactSyntaxHighlighter
            language="json"
            wrapLines
            wrapLongLines
            style={vscDarkPlus}
            showLineNumbers
            customStyle={{
              whiteSpace: 'pre-wrap',
              borderRadius: '8px',
              width: '650px',
              maxHeight: '200px',
              overflow: 'auto',
            }}
          >
            {JSON.stringify(result, null, 2)}
          </ReactSyntaxHighlighter>
        </div>
      )}
    </div>
  );
};
