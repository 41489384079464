export const AccountNotificationPlatorms = ['browser', 'mobile'];
export const ShopNotificationPlatorms = ['email'];
export const NotificationPlatorms = [...AccountNotificationPlatorms, ...ShopNotificationPlatorms];

export const notificationsTypes: any[] = [
  {
    id: 'forecasting',
    name: 'Planner',
    availablePlatforms: ['email'],
    topics: [
      {
        id: 'set_monthly_goals',
        name: 'Set Monthly Goals Reminder',
      },
      {
        id: 'pacing_update',
        name: 'Pacing Update',
      },
      {
        id: 'new_forecast_available',
        name: 'New Forecast Available',
      },
    ],
  },
];
