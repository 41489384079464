import { $derived, $observer, $store } from '@tw/snipestate';
import { isInIframe } from '../config';
import { isMobileApp, isOrcaApp } from './Device';

const $_isIntercomVisible = $store(true);

// only able to use settable capability in this file, so exporting read-only version
export const $isIntercomVisible = $derived((get) => get($_isIntercomVisible));
export const $isIntercomOpen = $observer(false, (_, set) => {
  const interval = setInterval(() => {
    if (!window.Intercom?.booted) return;

    clearInterval(interval);

    window.Intercom('onShow', () => {
      set(true);
    });

    window.Intercom('onHide', () => {
      set(false);
    });
  }, 1000);
});

function trackInitialIntercom() {
  const observer = new MutationObserver((mutationsList) => {
    for (const mutation of mutationsList) {
      if (mutation.type !== 'childList') continue;

      const launcher = document.querySelector(`.intercom-lightweight-app-launcher`);
      const savedVal = $_isIntercomVisible.get();
      const isMatching = savedVal === !!launcher;
      if (!isMatching) {
        toggleLauncherVisibility(savedVal);
      }
    }
  });

  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });
}
trackInitialIntercom();

export const toggleLauncherVisibility = (show: boolean) => {
  if (!window.Intercom) return;

  if (isInIframe || isMobileApp || isOrcaApp) {
    window.Intercom('shutdown');
    $_isIntercomVisible.set(false);
    return;
  }

  $_isIntercomVisible.set(show);
  window.Intercom('update', { hide_default_launcher: !show });
};

export const killIntercom = () => {
  // @ts-ignore
  if (window.Intercom?.booted) {
    // @ts-ignore
    window.OldIntercom = window.Intercom;
  }
  window.Intercom('shutdown');
  window.Intercom = () => {};
  $_isIntercomVisible.set(false);
};

export const reviveIntercom = () => {
  // @ts-ignore
  if (window.Intercom?.booted) {
    window.Intercom('show');
    $_isIntercomVisible.set(true);
    return;
  }

  // @ts-ignore
  if (window.OldIntercom?.booted) {
    // @ts-ignore
    window.Intercom = window.OldIntercom;
    window.Intercom('boot', {
      app_id: 'ogniecxz',
      hide_default_launcher: false,
    });
    window.Intercom('show');
    $_isIntercomVisible.set(true);
  }
};
