import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { uniqBy } from 'lodash';
import _db, { toArray } from 'utils/DB';
import { emptyArray } from 'utils/emptyArray';
import { RootState } from 'reducers/RootType';
import { useDashContext } from '../dashContext';
import { getMainElementDbRef } from '../utils/willyUtils';
import { useStoreValue } from '@tw/snipestate';

type HistoryItem = {
  user?: {
    uid: string;
    email: string;
    firstName: string;
    lastName: string;
  };
  action: string;
  time: number;
  payload?: any;
};

export const useDashHistory = () => {
  const user = useSelector((state: RootState) => state.user);
  const userEmail = useSelector((state: RootState) => state.userEmail);
  const { $dashboard, $dashboardId, $canEdit } = useDashContext();
  const canEdit = useStoreValue($canEdit);
  const dashboard = useStoreValue($dashboard);
  const dashboardId = useStoreValue($dashboardId);
  const [history, setHistory] = useState<any[]>([]);
  const [dashCreator, setDashCreator] = useState<any>();
  const [lastUpdated, setLastUpdated] = useState<any>();
  const [uniqueVisitors, setUniqueVisitors] = useState<any[]>([]);
  const [uniqueUsersEvents, setUniqueUsersEvents] = useState<any[]>([]);
  const [viewed, setViewed] = useState<any[]>([]);

  useEffect(() => {
    if (dashboardId && dashboard && canEdit && !dashboard.isGlobal) {
      getMainElementDbRef(dashboard!)
        .collection('history')
        .get()
        .then((data) => {
          try {
            const historyDocs = toArray(data).sort((a, b) => b.time - a.time);
            const localUniqueVisitors =
              historyDocs.length > 0
                ? uniqBy(historyDocs, (h: HistoryItem) => h.user?.uid)
                : emptyArray();
            const localUniqueUsersEvents = (localUniqueVisitors as HistoryItem[])
              .filter((h) => h.user?.email && dashboard?.users?.includes(h.user.email))
              .map((h) => ({
                ...h,
                admin: (h.user?.email && dashboard?.admins?.includes(h.user.email)) ?? false,
              }));
            const localViewed = historyDocs
              .filter((h) => h.action === 'view_dashboard')
              .sort((a, b) => a.time - b.time);

            setHistory(historyDocs);
            setDashCreator(historyDocs.find((v) => v.action === 'created'));
            setLastUpdated(
              historyDocs.find((v) => v.action !== 'created' && v.action !== 'view_dashboard'),
            );
            setUniqueVisitors([...localUniqueVisitors]);
            setUniqueUsersEvents([...localUniqueUsersEvents]);
            setViewed([...localViewed]);
          } catch (e) {
            console.error('Error updating history', e);
          }
        });
    }
  }, []);

  const updateHistory = useCallback(
    (action, payload?) => {
      if (!dashboardId || !dashboard || !canEdit || dashboard.isGlobal) return;
      if (userEmail && user?.uid) {
        try {
          getMainElementDbRef(dashboard!)
            .collection('history')
            .add({
              action: action ?? 'dashboard_updated',
              payload: payload ?? {},
              time: +new Date(),
              userEmail,
              user: {
                uid: user?.uid,
                email: user.email || userEmail || '',
                firstName: user?.firstName ?? '',
                lastName: user?.lastName ?? '',
              },
            });
        } catch (e) {
          console.error('Error updating history', e);
        }
      }
    },
    [
      canEdit,
      dashboard,
      dashboardId,
      user.email,
      user?.firstName,
      user?.lastName,
      user?.uid,
      userEmail,
    ],
  );

  return {
    history,
    uniqueVisitors,
    uniqueUsersEvents,
    viewed,
    dashCreator,
    lastUpdated,
    updateHistory,
  };
};

export default useDashHistory;
