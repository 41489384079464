import { Providers, ProvidersIntegrations } from '../types/services';
import { ServicesIds, supportedByNexus, WillyTableType } from '@tw/types/module/services';
import axiosInstance from '../utils/axiosInstance';
import { convertDataToStats } from '@tw/stats/module/convertDataToStats';
import { groupStats } from '@tw/stats/module/groupStats';
import { retry } from '@reduxjs/toolkit/query';

export const groupStatsByWillyTableType = (providersProps: Providers) => {
  const filteredProviders = Object.entries(providersProps).reduce(
    (acc, [providerId, provider]) => {
      if (
        provider.isConnected &&
        provider.providerType == 'data' &&
        provider.willyTableType?.length
      ) {
        acc.push({
          providerId: providerId as ServicesIds,
          willyTableType: provider.willyTableType,
        });
      }
      return acc;
    },
    [] as { providerId: ServicesIds; willyTableType: WillyTableType[] }[],
  );

  return filteredProviders.reduce(
    (acc, { providerId, willyTableType }) => {
      willyTableType.forEach((willyTableType) => {
        if (!acc[willyTableType]) {
          acc[willyTableType] = [];
        }
        acc[willyTableType].push(providerId);
      });
      return acc;
    },
    {} as { [key in WillyTableType]: ServicesIds[] },
  );
};

export const getRelevantIntegrations = (integrations: ProvidersIntegrations) => {
  return Object.entries(integrations).reduce((acc, [serviceId, integration]) => {
    if (integration.length) {
      acc[serviceId] = integration;
    }
    return acc;
  }, {} as ProvidersIntegrations);
};

export async function fetchServiceStats<
  B extends boolean,
  R = B extends true
    ? { stats: { [key in ServicesIds]: any[] }; error?: string }
    : { stats: any[]; error?: string },
>(
  params: Record<string, any>,
  url: string,
  method: 'get' | 'post' = 'get',
  isMulti: B,
): Promise<R> {
  const { start, end } = params;
  let data;
  try {
    if (method === 'get') {
      ({ data } = await axiosInstance.get(url, { params }));
    } else {
      ({ data } = await axiosInstance.post(url, params));
    }

    if (isMulti) {
      const stats = data.data.reduce(
        (acc, serviceRes) => {
          const { provider_id } = serviceRes as {
            provider_id: ServicesIds;
            id: string;
            metricsBreakdown: any[];
          };
          const fakeOneServiceResponse = {
            data: [serviceRes],
          };
          acc[provider_id] = convertDataToStats(fakeOneServiceResponse, start, end);
          return acc;
        },
        {} as { [key in ServicesIds]: any[] },
      );

      return { stats } as R;
    } else {
      const stats = convertDataToStats(data, start, end);
      return { stats } as R;
    }
  } catch (e) {
    return {
      error: e.data?.error,
      stats: [],
    } as R;
  }
}
