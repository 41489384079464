import { $shopSequences } from '$stores/willy/$sequences';
import { useStoreValue } from '@tw/snipestate';
import { Button, MultiSelect, Select, Text } from '@tw/ui-components';
import { useState } from 'react';

type FlowSubWorkflowStepProps = {
  parentSequenceId: string;
  sequenceIds?: string[];
  onSave: (sequenceIds: string[]) => void;
  readOnly: boolean;
  limitToOne?: boolean;
  setIsPristine: (isPristine: boolean) => void;
};

export const FlowSubWorkflowStep: React.FC<FlowSubWorkflowStepProps> = ({
  parentSequenceId,
  sequenceIds,
  onSave,
  readOnly,
  limitToOne,
  setIsPristine,
}) => {
  const shopSequences = useStoreValue($shopSequences);
  const [selectedSequences, setSelectedSequences] = useState(sequenceIds);

  const save = () => {
    if (!selectedSequences) {
      return;
    }
    onSave(selectedSequences);
  };

  return (
    <div className="p-5 flex flex-col gap-5">
      <Text fz={14}>Select Agents</Text>

      <div className="flex flex-col gap-5">
        {limitToOne ? (
          <Select
            disabled={readOnly}
            searchable
            value={
              selectedSequences?.filter((subseq) => {
                return !!shopSequences.find((seq) => seq.id === subseq);
              })?.[0]
            }
            data={shopSequences
              .filter((d) => d.name)
              .map((d) => {
                return { label: d.name ?? d.id, value: d.id, disabled: d.id === parentSequenceId };
              })}
            onChange={(v) => {
              setIsPristine(false);
              if (!v) {
                return;
              }
              setSelectedSequences([v]);
            }}
          />
        ) : (
          <MultiSelect
            disabled={readOnly}
            searchable
            value={selectedSequences?.filter((subseq) => {
              return !!shopSequences.find((seq) => seq.id === subseq);
            })}
            data={shopSequences
              .filter((d) => d.name)
              .map((d) => {
                return { label: d.name ?? d.id, value: d.id, disabled: d.id === parentSequenceId };
              })}
            onChange={(v) => {
              setIsPristine(false);
              if (!v) {
                return;
              }
              setSelectedSequences(v);
            }}
          />
        )}
      </div>
      <div>
        <Button onClick={() => save()} disabled={readOnly}>
          Save
        </Button>
      </div>
    </div>
  );
};
