import { MultiSelect } from '@tw/ui-components';
import { RunDoc, WorkflowStepBase, WorkflowStepPreloadRuns } from '../types/willyTypes';
import { useEffect, useState } from 'react';
import _db, { toArray } from 'utils/DB';
import moment from 'moment-timezone';

type FlowLoadDataStepProps = {
  step: WorkflowStepPreloadRuns & WorkflowStepBase;
  stepChange: (newPrompt: WorkflowStepPreloadRuns & WorkflowStepBase) => void;
  readOnly: boolean;
  setIsPristine: (isPristine: boolean) => void;
  sequenceId?: string;
};

export const FlowLoadRunsStep: React.FC<FlowLoadDataStepProps> = ({
  step,
  stepChange,
  readOnly,
  setIsPristine,
  sequenceId,
}) => {
  const [runs, setRuns] = useState<RunDoc[]>([]);

  useEffect(() => {
    if (!sequenceId) return;
    (async () => {
      const runs = await _db()
        .collection('data_sequences')
        .doc(sequenceId)
        .collection('runs')
        .orderBy('runAt', 'desc')
        .limit(10)
        .get();
      const conversations: RunDoc[] = toArray(runs);
      setRuns(conversations);
    })();
  }, [sequenceId]);

  return (
    <div className="flex flex-col gap-4">
      <MultiSelect
        disabled={readOnly}
        data={[
          { label: 'Last Run', value: 'last_run' },
          ...runs.map((r) => {
            return {
              label: moment(r.runAt.toDate()).format('MMMM Do YYYY, h:mm:ss a'),
              value: r.id,
            };
          }),
        ]}
        label="Previous runs"
        value={step.runIds}
        onChange={(val) => {
          stepChange({
            ...step,
            runIds: val,
          });
          setIsPristine(false);
        }}
      />
    </div>
  );
};
