import { ReactComponent as Pin } from './icons/pin.svg';
import { useCallback, useMemo, useState } from 'react';
import {
  WidgetQuery,
  MessageTypes,
  WillyDashboardElement,
  WillyMetric,
  WillyParameter,
  GridOptions,
  GridColumnOptions,
  AxisDomain,
  WillyWidgetElement,
  CodeInterpreterResponse,
  ChatSources,
  NlqResponseDataType,
  Dialect,
  WillyChartLayout,
} from './types/willyTypes';
import _db from 'utils/DB';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/RootType';
import { useAppDispatch } from '../../index';
import { pinWillyToSummary } from '../../ducks/willy';
import { DEFAULT_AXIS_DOMAIN, MAX_WIDGETS_PER_DASHBOARD } from './constants';
import {
  Flex,
  Icon,
  Checkbox,
  IconName,
  Menu,
  TextInput,
  Text,
  Box,
  Button,
  FormattedColor,
} from '@tw/ui-components';
import { useDashHistory } from './hooks/useDashHistory';
import {
  addWidgetToDashboard,
  convertQueryToBuilder,
  deleteWidgetFromDashboard,
  visibleLength,
} from './utils/willyUtils';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { $isTwGlobalDashboardCreatorClaim } from '../../$stores/$user';
import { $combinedDashboard } from '../../$stores/willy/$combinedDashboards';
import {
  analyticsEvents,
  sqwhaleActions,
  dashboardsActions,
  genericEventLogger,
  chatActions,
} from 'utils/dataLayer';
import { openDashSettingModal } from '$stores/willy/$dashSettingsModal';
import { successAlertWithAction } from './AlertWithActions';
import { useStoreValue } from '@tw/snipestate';
import { refreshDashboardWidgets } from './dashContext';

type PinMessageProps = {
  queryId?: string;
  type: MessageTypes;
  title: string;
  metrics: WillyMetric[];
  parameters: WillyParameter[];
  queries?: WidgetQuery[];
  stacked: boolean;
  incrementedStacked: boolean;
  wrapText?: boolean;
  willyMessageType: MessageTypes;
  conversationId?: string;
  disabled?: boolean;
  dashboardId?: string;
  grid?: GridOptions;
  gridColumns?: GridColumnOptions;
  twoColumnMobile?: boolean;
  dimension: string;
  allowDataOverflow?: boolean;
  yAxisDomain: AxisDomain;
  activator?: React.ReactElement;
  breakdownMode?: boolean;
  withoutMainQuery?: boolean;
  codeResult?: CodeInterpreterResponse;
  context: ChatSources;
  onClose?: () => void;
  withinPortal?: boolean;
  hasGlobalConditionalFormatting?: boolean;
  globalConditionalFormattingColor: string;
  dataType?: NlqResponseDataType;
  pinTo?: { dashboardId: string; widgetId: string; onAdd?: () => void };
  dialect: Dialect;
  chartLayout?: WillyChartLayout;
};

export const PinMessage: React.FC<PinMessageProps> = ({
  queryId,
  conversationId,
  type,
  title,
  metrics,
  queries,
  stacked,
  incrementedStacked,
  wrapText,
  willyMessageType,
  disabled = false,
  parameters,
  dashboardId,
  grid,
  gridColumns,
  twoColumnMobile,
  dimension,
  allowDataOverflow,
  yAxisDomain,
  activator,
  breakdownMode,
  withoutMainQuery,
  codeResult,
  context,
  onClose,
  withinPortal = true,
  hasGlobalConditionalFormatting,
  globalConditionalFormattingColor,
  dataType,
  pinTo,
  dialect,
  chartLayout,
}) => {
  const { updateHistory } = useDashHistory();
  const navigate = useNavigate();

  const [freeSearch, setFreeSearch] = useState('');
  const [pinning, setPinning] = useState(false);

  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const dashboards = useStoreValue($combinedDashboard);
  const user = useSelector((state: RootState) => state.user);
  const userEmail = useSelector((state: RootState) => state.userEmail);

  const currentAnalyticsEvent = useMemo(() => {
    return context === 'chat' || context === 'sequence'
      ? analyticsEvents.CHAT
      : context === 'editor'
        ? analyticsEvents.SQWHALE
        : analyticsEvents.DASHBOARDS;
  }, [context]);

  const currentAnalyticsActionSet = useMemo(() => {
    return context === 'chat' || context === 'sequence'
      ? chatActions
      : context === 'editor'
        ? sqwhaleActions
        : dashboardsActions;
  }, [context]);

  const mainQuery = useMemo(() => {
    return queries?.find((q) => q.id === queryId)?.query;
  }, [queries, queryId]);

  const createNewDashboardItem = useMemo(() => {
    return {
      content: 'Copy to New Dashboard',
      onClick: () => {
        if (!queryId) {
          return;
        }
        openDashSettingModal(
          true,
          false,
          undefined,
          [
            {
              queryId,
              title,
              type,
              metrics,
              parameters,
              stacked,
              chartLayout,
              incrementedStacked,
              queries,
              wrapText,
              breakdownMode,
              grid,
              gridColumns,
              twoColumnMobile,
              yAxisDomain,
              allowDataOverflow,
              dimension,
              withoutMainQuery,
              codeResult,
              hasGlobalConditionalFormatting,
              globalConditionalFormattingColor,
              dataType,
              dialect,
            },
          ],
          undefined,
          conversationId,
        );
      },
      leftSection: <Icon name="union" size={14} color="one.4" />,
      closeMenuOnClick: true,
      color: 'one.4',
    };
  }, [
    allowDataOverflow,
    dimension,
    grid,
    gridColumns,
    incrementedStacked,
    hasGlobalConditionalFormatting,
    globalConditionalFormattingColor,
    metrics,
    parameters,
    queries,
    queryId,
    stacked,
    title,
    twoColumnMobile,
    type,
    wrapText,
    breakdownMode,
    yAxisDomain,
    conversationId,
    withoutMainQuery,
    codeResult,
    dataType,
    dialect,
    chartLayout,
  ]);

  const removeWidgetFromDashboard = useCallback(
    async (dashboard: WillyDashboardElement, queryId: string) => {
      if (!dashboard.id) return;

      await deleteWidgetFromDashboard(dashboard, queryId);

      if (userEmail && user?.uid) {
        updateHistory('widget_removed', {
          widgetId: queryId,
          title,
        });
      }
    },
    [title, updateHistory, user?.uid, userEmail],
  );

  const dashboardHasWidget = useCallback(
    (dashboardId: string) => {
      const currentDashboard = dashboards.find((d) => d.id === dashboardId);
      if (!currentDashboard || !currentDashboard.widgetIds?.length) {
        return false;
      }

      return currentDashboard.widgetIds.some((w) => w === queryId);
    },
    [dashboards, queryId],
  );

  const addToDashboard = useCallback(
    async (dashboard: WillyDashboardElement) => {
      if (dashboard.widgetIds?.length >= MAX_WIDGETS_PER_DASHBOARD || !dashboard.canEdit) {
        return;
      }
      if (dashboardHasWidget(dashboard.id)) {
        removeWidgetFromDashboard(dashboard, queryId!);
        toast.success('Section removed from report');
      } else {
        setPinning(true);
        const { isValidated, jsonQuery, sqlQuery, error } = await convertQueryToBuilder(
          mainQuery || '',
          currentShopId,
        );

        const newWidget: WillyWidgetElement = {
          queryId: jsonQuery?.queryId || queryId!,
          title: title?.toString() || '',
          type,
          metrics,
          parameters: parameters || [],
          stacked,
          chartLayout,
          incrementedStacked,
          queries: queries || [],
          wrapText: wrapText || false,
          breakdownMode: breakdownMode ?? false,
          grid,
          gridColumns,
          twoColumnMobile,
          yAxisDomain: yAxisDomain || DEFAULT_AXIS_DOMAIN,
          allowDataOverflow: allowDataOverflow || false,
          dimension: dimension || '',
          withoutMainQuery: withoutMainQuery ?? false,
          codeResult,
          hasGlobalConditionalFormatting: hasGlobalConditionalFormatting ?? false,
          globalConditionalFormattingColor: globalConditionalFormattingColor || '',
          dataType: dataType || 'nlq', //'forecast',
          dialect,
          builderSetup: jsonQuery,
          mode: !!jsonQuery ? 'builder' : 'sql',
        };

        await addWidgetToDashboard(dashboard, newWidget, currentShopId, conversationId);
        await refreshDashboardWidgets();

        setPinning(false);

        if (userEmail && user?.uid) {
          updateHistory('widget_added', {
            widgetId: queryId,
            title,
          });
        }
      }
    },
    [
      allowDataOverflow,
      breakdownMode,
      codeResult,
      conversationId,
      currentShopId,
      dashboardHasWidget,
      dataType,
      dimension,
      globalConditionalFormattingColor,
      grid,
      gridColumns,
      hasGlobalConditionalFormatting,
      incrementedStacked,
      metrics,
      parameters,
      queries,
      mainQuery,
      queryId,
      stacked,
      title,
      twoColumnMobile,
      type,
      updateHistory,
      user?.uid,
      userEmail,
      withoutMainQuery,
      wrapText,
      yAxisDomain,
      dialect,
      chartLayout,
      removeWidgetFromDashboard,
    ],
  );

  const loggingSource = useMemo(() => {
    switch (context) {
      case 'chat':
        return 'chat';
      case 'editor':
        return 'sql-editor';
      default:
        return 'pinned-from-other-report';
    }
  }, [context]);

  const dashboardItems = useMemo(() => {
    if (!dashboards.length) {
      return [];
    }

    const dashboardsItems = dashboards
      .filter((dashboard) => dashboard.canEdit || dashboardHasWidget(dashboard.id))
      .filter((dashboard) => ($isTwGlobalDashboardCreatorClaim.get() ? true : !dashboard.isGlobal))
      .filter((dashboard) => {
        if (!dashboard.name) {
          return false;
        }
        if (!freeSearch) {
          return true;
        }
        return dashboard.name.toLowerCase().includes(freeSearch.toLowerCase());
      })
      .sort((a, b) => {
        return a.name?.localeCompare(b.name || '') || 0;
      })
      .map((dashboard) => ({
        content: (
          <Flex justify={'space-between'} align={'center'}>
            <div
              className={`flex items-center gap-4 overflow-hidden ${
                dashboard.widgetIds?.length > MAX_WIDGETS_PER_DASHBOARD ? 'opacity-50' : ''
              }`}
            >
              {dashboard.canEdit ? (
                <Checkbox
                  disabled={pinning}
                  label={
                    <div className="flex gap-4 items-center w-full">
                      {dashboard.emoji && (
                        <div className="flex items-center w-auto">
                          {visibleLength(dashboard.emoji) > 1 ? (
                            <Icon name={dashboard.emoji as IconName} />
                          ) : (
                            dashboard.emoji
                          )}
                        </div>
                      )}
                      <span className="truncate text-[--gray-light-mode-700]">
                        {dashboard.isGlobal && '[Template] '}
                        {dashboard.name}
                      </span>
                    </div>
                  }
                  // color="gray.6"
                  checked={dashboardHasWidget(dashboard.id)}
                />
              ) : null}
            </div>
          </Flex>
        ),
        helpText:
          dashboard.widgetIds?.length > MAX_WIDGETS_PER_DASHBOARD
            ? `Max ${MAX_WIDGETS_PER_DASHBOARD} elements per dashboard`
            : '',
        disabled:
          dashboard.widgetIds?.length > MAX_WIDGETS_PER_DASHBOARD || !dashboard.canEdit || pinning,
        bgColor: dashboardHasWidget(dashboard.id) && dashboard.canEdit ? 'gray.2' : undefined,
        onClick: async () => {
          await addToDashboard(dashboard);
          successAlertWithAction(
            'Your Section has been added to your report',
            () => {
              navigate({
                pathname: `/dashboards/${dashboard.id}`,
              });
            },
            'View Dashboard',
          );

          genericEventLogger(currentAnalyticsEvent, {
            action: currentAnalyticsActionSet.PIN_RESULT,
            widget_type: willyMessageType,
            widget_id: queryId,
            widget_name: title,
            dashboard_id: dashboard.id,
            dashboard_name: dashboard.name,
            template_id: dashboard.globalDashboardId,
            template_name: dashboard.globalDashboardId ? dashboard.name : null,
            conversationId,
            source: loggingSource,
          });
        },
      }));

    return dashboardsItems;
  }, [
    dashboards,
    dashboardHasWidget,
    freeSearch,
    pinning,
    addToDashboard,
    currentAnalyticsEvent,
    currentAnalyticsActionSet.PIN_RESULT,
    willyMessageType,
    queryId,
    title,
    conversationId,
    navigate,
    loggingSource,
  ]);

  return pinTo ? (
    <div className="flex-shrink-0">
      <Button
        onClick={async () => {
          const dash = dashboards.find((d) => d.id === pinTo.dashboardId);
          const widget = dash?.widgetIds?.some((w) => w === pinTo.widgetId);
          if (dash) {
            try {
              if (widget) {
                await deleteWidgetFromDashboard(dash, pinTo.widgetId);
              }
              await pinTo.onAdd?.();
              await addToDashboard(dash);
              genericEventLogger(currentAnalyticsEvent, {
                action: currentAnalyticsActionSet.PIN_RESULT,
                widget_type: willyMessageType,
                widget_id: queryId,
                widget_name: title,
                dashboard_id: dash.id,
                dashboard_name: dash.name,
                template_id: dash.globalDashboardId,
                template_name: dash.globalDashboardId ? dash.name : null,
                conversationId,
              });
              toast.success('Your section has been updated on your board');
            } catch (e) {
              toast.error('An error occurred while updating the section');
              console.error(e);
            }
          }
        }}
        disabled={disabled}
        miw={124}
      >
        Add to Dashboard
      </Button>
    </div>
  ) : (
    <>
      <Menu
        closeOnItemClick={false}
        onClose={() => onClose?.()}
        shadow="md"
        disabled={disabled}
        withinPortal={withinPortal}
        position="right"
        width="400"
      >
        <Menu.Target>
          {activator ? (
            activator
          ) : (
            <div
              className={`${disabled ? 'opacity-50' : ''} flex items-center gap-4 overflow-hidden`}
            >
              <div className="flex items-center justify-center cursor-pointer">
                <Pin className="w-7 flex items-center fill-gray-500 dark:fill-gray-300" />
              </div>
            </div>
          )}
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label pos="sticky" p="md">
            <TextInput
              value={freeSearch}
              onChange={setFreeSearch}
              leadingIcon="search-major"
              placeholder="Search Dashboards"
            />
          </Menu.Label>
          <Box mah={200} overflow="auto" px="md">
            {dashboardItems.map((item, index) => (
              <Menu.Item
                key={index}
                disabled={item.disabled}
                onClick={item.onClick}
                {...(item.bgColor !== undefined ? { bg: item.bgColor as FormattedColor } : {})}
              >
                {item.content}
                {item.helpText && <Text size="xs">{item.helpText}</Text>}
              </Menu.Item>
            ))}
          </Box>
          <Menu.Divider my="md" />
          <Box px="md" pb="md">
            <Menu.Item
              leftSection={createNewDashboardItem.leftSection}
              closeMenuOnClick={createNewDashboardItem.closeMenuOnClick}
              onClick={createNewDashboardItem.onClick}
              color={createNewDashboardItem.color}
            >
              {createNewDashboardItem.content}
            </Menu.Item>
          </Box>
        </Menu.Dropdown>
      </Menu>
    </>
  );
};
