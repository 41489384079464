import _db from 'utils/DB';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';

import { SequencesList } from './SequencesList';
import { WillyTemplatesInner } from './dashboardManagment/WillyTemplates';

import { computeFeatureFlags } from '../../feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { UpgradePageFallBack } from '../../feature-flag-system/components';
import { WorkflowAiColumns } from './WorkflowAiColumns';

const SEQUENCE_NAV_ITEMS = [
  {
    id: 'shop',
    label: 'Agents',
    link: '/workflows/shop',
    // icon: 'clock',
    content: ({ setRunsOpen }) => <SequencesList isPage={true} setRunsOpen={setRunsOpen} />,
  },
  {
    id: 'templates',
    label: 'Templates',
    link: '/workflows/templates',
    // icon: 'button-play',
    content: (
      <div className="relative h-full px-6 sm:p-0 sm:pl-6">
        <WillyTemplatesInner
          templateType="sequence"
          setTemplateType={() => {}}
          isSequencesPage
          hideHeader
          onlyInner
        />
      </div>
    ),
  },
];

export const SequencesComponent = () => {
  const { pathname, search } = useLocation();
  const currentPage =
    SEQUENCE_NAV_ITEMS.find((item) => pathname.includes(item.link)) || SEQUENCE_NAV_ITEMS[0];
  const navigate = useNavigate();
  const [runsOpen, setRunsOpen] = useState<string>();

  return typeof currentPage?.content === 'function'
    ? currentPage.content({ setRunsOpen })
    : currentPage?.content;
};

export const Sequences = computeFeatureFlags(
  [FeatureFlag.TW_3_0_FF, FeatureFlag.TW_ONLY_3_0_FF],
  SequencesComponent,
  () => (
    <UpgradePageFallBack
      InAppContextBannerEnabled={false}
      title="TW 3.0"
      description="Chat, analyze and manage with TW 3.0, the AI assistant that helps you effortlessly interact with your eCommerce business."
      featureFlag={FeatureFlag.TW_3_0_FF}
    />
  ),
);
