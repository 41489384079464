import { useEffect } from 'react';
import { checkServicesConnection, updateShop } from '../../ducks/actions';
import { useAppDispatch } from '../../index';
import _db from '../../utils/DB';
import { useAppSelector } from '../../reducers/RootType';

export const useStoreInit = () => {
  const dispatch = useAppDispatch();
  const currentShopId = useAppSelector((state) => state.currentShopId);
  useEffect(() => {
    if (currentShopId) {
      return _db().onSnapshot((shopDataSnapshot) => {
        const data = shopDataSnapshot.data() || {};
        dispatch(checkServicesConnection(data));
        dispatch(updateShop(data));
      });
    }
  }, [dispatch, currentShopId]);
};
