import React from 'react';
import { Button } from '@shopify/polaris';
import { CashDollarMajor, CashEuroMajor, CashPoundMajor, GlobeMinor } from '@shopify/polaris-icons';
import { getConversionRate } from 'ducks/actions';
import { CURRENCIES } from '@tw/constants';
import { useAppDispatch } from 'index';
import { Menu } from '@tw/ui-components';
import { useStoreValue, useWritableStore } from '@tw/snipestate';
import { $currency, $shopCurrency } from '../$stores/$shop';

type CurrencyButtonProps = {
  onChange?: (e: string) => void;
  value?: string;
  isShopContext: boolean;
  activator?: (e: string) => React.ReactNode;
  dropdownPosition?: any;
  dropDownOffset?: number;
  withinPortal?: boolean;
};

export const CurrencyButton: React.FC<CurrencyButtonProps> = ({
  onChange,
  value,
  activator,
  dropdownPosition,
  isShopContext,
  dropDownOffset,
  withinPortal = true,
}) => {
  const [currency, setCurrency] = useWritableStore($currency);
  const shopCurrency = useStoreValue($shopCurrency);
  const dollarCurrencies = ['USD', 'CAD', 'AUD', 'BRL', 'NZD', 'SGD'];
  const options = Object.keys(CURRENCIES)
    .sort()
    .map((x) => ({ value: x, label: x }));

  const statsCurrency = value || currency;

  const dispatch = useAppDispatch();
  const handleChange = (newCurrency) => {
    if (isShopContext) {
      setCurrency(newCurrency);
    }
    if (onChange) {
      onChange(newCurrency);
    } else {
      if (isShopContext) {
        dispatch(getConversionRate(shopCurrency, newCurrency));
      }
    }
  };
  return (
    <Menu withinPortal={withinPortal} position={dropdownPosition} mah={100} offset={dropDownOffset}>
      <Menu.Target>
        {activator ? (
          activator(statsCurrency)
        ) : (
          <div className="relative w-fit">
            <Button
              disclosure
              icon={
                dollarCurrencies.includes(statsCurrency)
                  ? CashDollarMajor
                  : statsCurrency === 'EUR'
                    ? CashEuroMajor
                    : statsCurrency === 'GBP'
                      ? CashPoundMajor
                      : GlobeMinor
              }
            >
              {statsCurrency}
            </Button>
          </div>
        )}
      </Menu.Target>
      <Menu.Dropdown className="max-h-[250px] overflow-auto">
        {options.map((option) => (
          <Menu.Item
            key={option.value}
            onClick={() => {
              handleChange(option.value);
            }}
            bg={statsCurrency === option.value ? 'named2.4' : undefined}
          >
            {option.label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
