import { Anchor, Icon, Skeleton, Text } from '@tw/ui-components';
import { WillySidePanelWorkflowItem } from './WillySidePanelWorkflowsItem';
import { WorkflowSelector } from './WorkflowSelector';
import { Fragment, useCallback, useMemo } from 'react';
import _db from 'utils/DB';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { usePinnedWorkflows } from './hooks/usePinnedWorkflows';

type WillySidePanelWorkflowsProps = {
  chatWithSequenceId: string | null;
};

export const WillySidePanelWorkflows: React.FC<WillySidePanelWorkflowsProps> = ({
  chatWithSequenceId,
}) => {
  const {
    pinnedWorkflows,
    loading: loadingPinnedWorkflows,
    lastRunWorkflowRun,
    addPinnedWorkflow,
    removePinnedWorkflow,
  } = usePinnedWorkflows();
  const isSmall = useIsSmall();

  const toggleWorkflow = useCallback(
    async (workflowId) => {
      let newList = pinnedWorkflows?.map((wf) => {
        return wf?.id;
      });
      if (newList.includes(workflowId)) {
        newList = newList.filter((id) => id !== workflowId);
        removePinnedWorkflow(workflowId);
      } else {
        newList = [...newList, workflowId];
        addPinnedWorkflow(workflowId);
      }
      await _db().set({ pinnedSequences: newList }, { merge: true });
    },
    [pinnedWorkflows, addPinnedWorkflow, removePinnedWorkflow],
  );

  const workflowsForSidebar = useMemo(() => {
    if (!!lastRunWorkflowRun) {
      return [lastRunWorkflowRun, ...pinnedWorkflows];
    } else {
      return pinnedWorkflows;
    }
  }, [lastRunWorkflowRun, pinnedWorkflows]);

  return (
    <div>
      <div className="border-t border-solid border-l-0 border-r-0 border-b-0 willy-border" />
      <div className={`p-4 gap-4 flex flex-col ${isSmall ? 'mx-[16px]' : ''}`}>
        {loadingPinnedWorkflows ? (
          <>
            <Skeleton animate height={'1.5rem'} />
            <Skeleton animate height={'1.5rem'} />
            <Skeleton animate height={'1.5rem'} />
          </>
        ) : (
          <>
            {workflowsForSidebar?.map((workflow) => {
              const isActiveNavItem = chatWithSequenceId === workflow?.id;
              const latestRun = workflow?.runs?.[0] || null;
              return (
                <Fragment key={workflow?.id}>
                  <WillySidePanelWorkflowItem
                    workflowId={workflow?.id}
                    lastestRunId={latestRun?.id}
                    isActive={isActiveNavItem}
                    workflowName={workflow?.name}
                    read={latestRun?.read ?? false}
                  />
                </Fragment>
              );
            })}
            <WorkflowSelector
              activator={
                <Anchor fz="sm" fw={500} underline="never" ml="sm">
                  <span className="flex gap-3 items-center py-2">
                    <Icon name="union" size={14} color="one.6" />
                    <Text fz="sm" fw={500} c="one.6">
                      Add Agent
                    </Text>
                  </span>
                </Anchor>
              }
              selectedList={pinnedWorkflows.map((workflow) => workflow?.id)}
              toggleSelection={(id) => toggleWorkflow(id)}
              withInPortal={true}
              closeOnSelect={true}
            />
          </>
        )}
      </div>
    </div>
  );
};
