import React from 'react';
import Select, { GroupBase, Props } from 'react-select';

function StoreSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: Props<Option, IsMulti, Group>) {
  return (
    <div className={'BenchmarkSelect'}>
      <Select
        {...props}
        styles={{
          container: (provided) => ({
            ...provided,
            minWidth: 220,
          }),
          dropdownIndicator: (provided, state) =>
            ({
              ...provided,
              transition: 'all .5s ease',
              transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
            }) as any,
          option: (styles, state) => ({
            ...styles,
            '&:hover': {
              background: 'rgba(24, 119, 242, 0.1)',
              color: '#1877F2',
            },
            color: '#0C3854',
            textColor: 'black',
            fontSize: '14px',
            fill: '#D5EBFF',
          }),
          menu: (styles, state) => ({
            ...styles,
            zIndex: 100000,
          }),
          control: (provided, state) => ({
            ...provided,
            backgroundColor: 'rgba(0,0,0,0)',
            height: '36px',
            border: '1px solid rgba(192, 214, 234, 0.5019607843)',
          }),
          indicatorSeparator: (state) => ({
            display: 'none',
          }),
          singleValue: (provided, state) => ({
            ...provided,
            textColor: 'black',
            fontSize: '14px',
            fill: '#D5EBFF',
            color: 'black',
          }),
        }}
      />
    </div>
  );
}

export default StoreSelect;
