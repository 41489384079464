import { ServicesIds } from '@tw/types/module/services';
import allServices from 'constants/services';
import { useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router';
import Lottie from 'react-lottie-player';
import AttributionSettings from 'components/attribution-new/AttributionSettings';
import DropDown from 'components/ltv/DropDown';
import DatePicker from 'components/SummaryDatePicker';
import segmentationIndicator from '../../lotties/segmentation-indicator.json';
import { Button } from '@shopify/polaris';
import { CalendarMinor } from '@shopify/polaris-icons';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { useAppDispatch } from 'index';
import {
  changeActiveServiceId,
  chartOpenChanged,
  changeUseCCNexus,
} from 'ducks/creativesCockpit/creativesCockpit';
import AttributionDateModelPicker from 'components/attribution-new/AttributionDateModelPicker';
import AttributionModelPicker from 'components/attribution-new/AttributionModelPicker';
import _db from 'utils/DB';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { ActionIcon, Checkbox, Flex, Tooltip, Text, Icon } from '@tw/ui-components';
import { ConditionalWrapper } from 'components/library/TwConditionalWrapper';
import { useDarkMode } from 'dark-mode-control';
import { useComputedValue } from '@tw/snipestate';
import { $activeAppVersion } from '$stores/nav-config-stores';

const CreativeCockpitHeader: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<{ docId: string }>();
  const isSmall = useIsSmall();
  const darkMode = useDarkMode();
  const isThreePointOh = useComputedValue($activeAppVersion, (v) => v === '3.0');

  const dispatch = useAppDispatch();

  const { activeServiceId, activeType, showSegmentIndicator, shareData, chartOpen, useCCNexus } =
    useSelector((state: RootState) => state.creativeCockpit);
  // const { dateModel } = useSelector((state: RootState) => state.attribution);

  const [attributionSettingsOpen, setAttributionSettingsOpen] = useState(false);

  const isShareMode = window.location.href.includes('share/creative') && params.docId != null;

  const handleServiceChange = (id: ServicesIds) => {
    navigate({
      pathname: `${
        location.pathname.includes('dashboards') ? '/dashboards' : ''
      }/creative-cockpit/${id}/${activeType}`,
      search: location.search,
    });
    dispatch(changeActiveServiceId(id));
  };

  const services = useMemo(
    () =>
      Object.values(allServices)
        .filter((s) => s.hasCreative)
        .map((s) => {
          return s.id === 'google-ads'
            ? { value: s.id, label: 'YouTube' }
            : { value: s.id, label: s.title };
        }),
    [],
  );

  return (
    <div
      className={`flex items-center gap-4 flex-wrap ${
        isThreePointOh ? 'flex-row-reverse w-full justify-between' : ''
      }`}
    >
      <Flex align="center" gap="sm" wrap="wrap">
        {showSegmentIndicator && (
          <Tooltip label="Some ads are filtered by Ad Segmentation from the Summary page">
            <div style={!darkMode ? {} : { filter: 'brightness(3)' }}>
              <Lottie
                loop
                play
                animationData={segmentationIndicator}
                rendererSettings={{ preserveAspectRatio: 'xMinYMin slice' }}
                style={{ width: 35, height: 35 }}
              />
            </div>
          </Tooltip>
        )}
        {!isSmall && (
          <>
            <AttributionDateModelPicker />
            <AttributionModelPicker type={'creativeCockpit'} />
          </>
        )}
        <DropDown
          id="tr-creative-cockpot-dropdown-service"
          options={services}
          value={activeServiceId}
          handleSelect={handleServiceChange}
          disabled={isShareMode}
          size={isThreePointOh ? 'xs' : 'sm'}
        />
        <Tooltip label={`${chartOpen ? 'Hide' : 'Show'} Graph`}>
          <div>
            <ActionIcon
              id="att-header-line-chart"
              size={isThreePointOh ? 'md' : 'lg'}
              radius="sm"
              onClick={() => dispatch(chartOpenChanged(!chartOpen))}
              variant="activator"
              icon="line-chart"
            />
          </div>
        </Tooltip>
        {/* {!!hasNexus && (
        <div className="whitespace-nowrap">
          <ConditionalWrapper
            condition={dateModel === 'clickDate'}
            wrapper={(x) => (
              <Tooltip label='Note: Nexus is not available for "click date" model'>{x}</Tooltip>
            )}
          >
            <Checkbox
              checked={useCCNexus}
              label="Use Nexus"
              onChange={async (checked) => {
                try {
                  dispatch(changeUseCCNexus(checked));
                  await _db().set({ useCCNexus: checked }, { merge: true });
                } catch (e) {}
              }}
            />
          </ConditionalWrapper>
        </div>
      )} */}
        {isSmall && (
          <AttributionSettings
            isOpen={attributionSettingsOpen}
            onOpen={() => {
              setAttributionSettingsOpen((a) => !a);
            }}
            type="ads"
            isShareMode={isShareMode}
            hideCurrencyButton={true}
            hideOneDayConversionValue={true}
            hideMoreAttributionSettings={true}
            hideAttributionModel={!isSmall}
            hideDateModel={!isSmall}
          />
        )}
        {isShareMode && (
          <Button disclosure disabled={true} icon={CalendarMinor}>
            {shareData.endDateShare !== shareData.startDateShare
              ? shareData.startDateShare + ' - ' + shareData.endDateShare
              : shareData.startDateShare}
          </Button>
        )}
      </Flex>
      {!isShareMode && (
        <Flex gap="md">
          {isThreePointOh && (
            <Flex align="center" pl="sm" gap="sm">
              <Icon name="attribution2" color={'gray.5'} size={18} />
              <Text color="gray.7" weight={500} size="lg">
                Creative Cockpit
              </Text>
            </Flex>
          )}
          <DatePicker
            showGroupByButton={true}
            disabled={isShareMode}
            buttonSize={isThreePointOh ? 'xs' : 'sm'}
          />
        </Flex>
      )}
    </div>
  );
};

export default CreativeCockpitHeader;
