import React from 'react';

export const OrcaPageError = ({ appId, accountId, errorLoadingApp }) => {
  if (!appId) {
    return <div className="dark:text-white">app-id is missing from the URL</div>;
  }

  if (!accountId) {
    return <div className="dark:text-white">account-id is missing from the URL</div>;
  }

  if (errorLoadingApp) {
    return <div className="dark:text-white">{errorLoadingApp}</div>;
  }
  return null;
};
