import React from 'react';
const SendlaneIconBase64 = ({ small }) => (
  <img
    style={{ height: small ? 15 : 20 }}
    alt="Rakuten icon"
    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAwIiBoZWlnaHQ9IjMwMCIgdmlld0JveD0iMCAwIDMwMCAzMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMF8xMDFfOTczKSI+CjxwYXRoIGQ9Ik0yMzguMzU0IDEyMy4zMTVMMTQ1LjczNCAzMC42OTVDMTMwLjk4NCAxNS45MzUgMTA2Ljk3NCAxNS45MzUgOTIuMjIzOCAzMC42OTVMNjEuNjMzOCA2MS4yODVDNDYuODgzOCA3Ni4wMzUgNDYuODgzOCAxMDAuMDQ1IDYxLjYzMzggMTE0Ljc5NUw5OC42MjM4IDE1MS43ODVMNjMuOTczOCAxODguMTE1QzU3LjA5MzggMTk1LjMyNSA1My40MzM4IDIwNC43ODUgNTMuNjczOCAyMTQuNzQ1QzUzLjkxMzggMjI0LjY5NSA1OC4wMDM4IDIzMy45NzUgNjUuMjEzOCAyNDAuODQ1TDk1Ljg2MzggMjcwLjA3NUMxMDMuMDc0IDI3Ni45NTUgMTEyLjM0NCAyODAuMzc1IDEyMS41OTQgMjgwLjM3NUMxMzEuNDQ0IDI4MC4zNzUgMTQxLjI3NCAyNzYuNTA1IDE0OC41OTQgMjY4LjgzNUwxOTQuODY0IDIyMC4zMjVMMjM5LjM4NCAxNzMuNjQ1QzI0NC43MjQgMTY4LjA1NSAyNDguMTI0IDE2MS4xMTUgMjQ5LjI2NCAxNTMuNjM1QzI1MC4yNzQgMTQyLjc5NSAyNDYuNjM0IDEzMS41OTUgMjM4LjM1NCAxMjMuMzE1Wk04MS40MzM4IDk0Ljk5NUM3Ny41OTM4IDkxLjE2NSA3Ny41OTM4IDg0LjkxNSA4MS40MzM4IDgxLjA3NUwxMTIuMDI0IDUwLjQ5NUMxMTMuOTQ0IDQ4LjU3NSAxMTYuNDY0IDQ3LjYxNSAxMTguOTg0IDQ3LjYxNUMxMjEuNTA0IDQ3LjYxNSAxMjQuMDI0IDQ4LjU3NSAxMjUuOTM0IDUwLjQ5NUwxNjEuNDA0IDg1Ljk2NUwxMTcuOTU0IDEzMS41MTVMODEuNDMzOCA5NC45OTVaTTEyOC4zMzQgMjQ5LjUwNUMxMjQuNzk0IDI1My4yMTUgMTE4LjkwNCAyNTMuMzU1IDExNS4xOTQgMjQ5LjgxNUw4NC41NDM4IDIyMC41ODVDODAuODMzOCAyMTcuMDQ1IDgwLjY5MzggMjExLjE1NSA4NC4yMzM4IDIwNy40NDVMMTE4LjQzNCAxNzEuNTk1TDE2MS41MzQgMjE0LjY5NUwxMjguMzM0IDI0OS41MDVaTTIyMS40MzQgMTUwLjExNUMyMjEuNDI0IDE1Mi42MjUgMjIwLjQ2NCAxNTUuMTI1IDIxOC41NTQgMTU3LjAyNUwxODEuMDE0IDE5NC41NzVMMTM3Ljc2NCAxNTEuMzI1TDE4MS4yMTQgMTA1Ljc3NUwxODEuNDQ0IDEwNS41MzVMMjE4LjgxNCAxNDEuMTg1QzIyMC42MTQgMTQyLjg5NSAyMjEuNjM0IDE0NS4yMDUgMjIxLjY5NCAxNDcuNjg1QzIyMS43MTQgMTQ4LjUxNSAyMjEuNjI0IDE0OS4zMjUgMjIxLjQzNCAxNTAuMTE1WiIgZmlsbD0iI0ZGM0E3OSIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzEwMV85NzMiPgo8cmVjdCB3aWR0aD0iMTk4Ljg1NyIgaGVpZ2h0PSIyNjAuNzUiIGZpbGw9IndoaXRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1MC41NzEzIDE5LjYyNSkiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K"
  />
);

export default SendlaneIconBase64;
