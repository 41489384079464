import { Button, Icon, Text, ActionIcon, Modal, Flex, Textarea } from '@tw/ui-components';
import { WILLY_SEQUENCE_STEP_OPTIONS } from '../constants';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useStoreValue } from '@tw/snipestate';
import { $user } from '$stores/$user';
import { Dialect, DialectWithBoth, Message, StepWsStatus, WorkflowStep } from '../types/willyTypes';
import { AlanLoaderGray } from 'components/AlanLoader';
import { WillyMessageTemplate } from '../WillyMessageTemplate';
import { TreeVerticalLine } from './TreeVerticalLine';
import { FlowSubWorkflowStep } from './FlowSubWorkflowStep.tsx';
import { FlowLoadDataStep } from './FlowLoadDataStep';
import { FlowSendToDashboardStep } from './FlowSendToDashboardStep';
import { FlowSendToEmailStep } from './FlowSendToEmailStep';
import { FlowSendToSlackStep } from './FlowSendToSlackStep';
import { FlowRuleStep } from './FlowRuleStep';
import { FlowStepWithText } from './FlowStepWithText';
import { getMessagesFromWorkflowResponse, isStepInvalid } from '../utils/sequences';
import { FlowSendToWarehouse } from './FlowSendToWarehouse';
import { FlowRunQueryStep } from './FlowRunQueryStep';
import { Prism as ReactSyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { formatSqlSafely } from '../utils/willyUtils';
import { FlowSendToWebhookStep } from './FlowSendToWebhookStep';
import { FlowSendToGoogleSheetStep } from './FlowSendToGoogleSheetStep';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { FlowLoadRunsStep } from './FlowLoadRunsStep';
import { WillySimpleText } from '../WillySimpleText';
import { SequenceFlowStepHeader } from './SequenceFlowStepHeader';
import { FlowInsightsStep } from './FlowInsightsStep';
import { FlowPreloadGoogleSheetStep } from './FlowPreloadGoogleSheetStep';
import { FlowPreloadGoogleCSV } from './FlowPreloadCSVStep';
import { FlowLoopStep } from './FlowLoopStep';

type SequenceFlowStepProps = {
  sequenceId: string;
  step: WorkflowStep;
  stepNumber: number;
  deleteStep: (messageId: string) => void;
  isCollapsed: boolean;
  toggleCollapse: (messageId: string) => void;
  stepChange: (step: WorkflowStep) => void;
  allowRegenerateAnswers: boolean;
  onGenerateAnswer: (upToStepId?: string, onlyStepId?: string) => void;
  loadingAnswers: boolean;
  depth: number;
  stepRunInfo: StepWsStatus | null;
  saveSequences: (sequences: string[], messageId: string) => void;
  readOnly: boolean;
  isLastStep?: boolean;
  noMoreStepsAllowed?: boolean;
  parentStepId?: string;
  fromRule?: boolean;
  dialect: DialectWithBoth;
  useParentWidth?: boolean;
  viewOnly?: boolean;
  hideProgress?: boolean;
  siblingSteps: WorkflowStep[];
};

export const SequenceFlowStep: React.FC<SequenceFlowStepProps> = ({
  sequenceId,
  step,
  stepNumber,
  deleteStep,
  isCollapsed,
  toggleCollapse,
  stepChange,
  allowRegenerateAnswers,
  stepRunInfo,
  onGenerateAnswer,
  loadingAnswers,
  depth,
  saveSequences,
  readOnly,
  isLastStep,
  fromRule,
  dialect,
  noMoreStepsAllowed,
  useParentWidth,
  viewOnly,
  hideProgress,
  siblingSteps,
}) => {
  const user = useStoreValue($user);
  // const currentShopId = useStoreValue($currentShopId);
  const [answerHidden, setAnswerHidden] = useState<boolean>(true);
  const [assetModalOpened, setAssetModalOpened] = useState<boolean>(false);
  const [fullAnswerPopupOpen, setFullAnswerPopupOpen] = useState<boolean>(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isPristine, setIsPristine] = useState(true);
  const [showRephrasedText, setShowRephrasedText] = useState(true);
  const [canEditToolPreload, setCanEditToolPreload] = useState(false);

  // const suggestTitle = useCallback(async () => {
  //   if (readOnly || viewOnly) {
  //     return;
  //   }
  //   if (step.stepType !== 'tool' || !step.text || step.title || !currentShopId) {
  //     return;
  //   }

  //   const { data: title } = await axiosInstance.post('/v2/willy/suggest-prompt-title', {
  //     shopId: currentShopId,
  //     prompt: step.text,
  //   });
  //   if (title?.completion) {
  //     stepChange({ ...step, title: title.completion });
  //   }
  // }, [step, currentShopId, sequenceId, readOnly, viewOnly]);

  useEffect(() => {
    if (!!isLastStep) {
      setAnswerHidden(false);
    }
  }, [isLastStep]);

  // useEffect(() => {
  //   suggestTitle();
  // }, [suggestTitle]);

  const generatedAnswer = stepRunInfo?.response;
  const isRunning = stepRunInfo?.status === 'running';
  const isDone = stepRunInfo?.status === 'done';
  const MAX_ANSWER_BOX_HEIGHT = 300;

  const setAnswerBoxRef = useResizeObserver((entries) => {
    if (!entries[0]) return;
    const { blockSize: height } = entries[0].contentBoxSize[0];
    setIsOverflowing(height > MAX_ANSWER_BOX_HEIGHT);
  });

  const hasAnswer = useMemo(() => {
    return !!generatedAnswer;
  }, [generatedAnswer]);

  const canRegenerate = useMemo(() => {
    if (!allowRegenerateAnswers) {
      return false;
    }

    const olderSteps = siblingSteps.slice(0, siblingSteps.indexOf(step));

    return !isStepInvalid(step, olderSteps || []);
  }, [allowRegenerateAnswers, step, siblingSteps]);

  const stepError = useMemo(() => {
    return isStepInvalid(step, siblingSteps);
  }, [step, siblingSteps]);

  const toolConfig = useMemo(() => {
    if (step.stepType === 'tool') {
      const toolUsed = step.toolToUse ?? 'auto';
      const tool = WILLY_SEQUENCE_STEP_OPTIONS.find(
        (option) => option.type === 'tool' && option.toolName === toolUsed,
      );
      return tool;
    }
    const tool = WILLY_SEQUENCE_STEP_OPTIONS.find((option) => option.type === step.stepType);
    return tool;
  }, [step]);

  const messages: Message[] = useMemo(() => {
    if (!generatedAnswer) {
      return [];
    }
    return getMessagesFromWorkflowResponse(generatedAnswer, dialect as Dialect);
  }, [generatedAnswer, dialect]);

  const lastMessageFromUser = useMemo(() => {
    return messages?.findLast((message) => message.role === 'user');
  }, [messages]);

  const _onShowAnswer = async (upToStepId?: string, onlyStepId?: string) => {
    if (canRegenerate) {
      await onGenerateAnswer(upToStepId, onlyStepId);
    }
  };

  return (
    <div
      key={step.id}
      className="mx-5"
      style={{
        width: useParentWidth ? '100%' : `calc(700px - ${depth * 50}px)`,
      }}
    >
      {!(depth === 0 && stepNumber === 0) && !viewOnly && <TreeVerticalLine />}

      <div className={`flex flex-col`}>
        <SequenceFlowStepHeader
          step={step}
          isCollapsed={isCollapsed}
          isRunning={isRunning}
          isDone={isDone}
          toolConfig={toolConfig}
          stepRunInfo={stepRunInfo}
          loadingAnswers={loadingAnswers}
          stepNumber={stepNumber}
          stepChange={stepChange}
          deleteStep={deleteStep}
          setAssetModalOpened={setAssetModalOpened}
          toggleCollapse={toggleCollapse}
          readOnly={readOnly}
          viewOnly={viewOnly}
          hideProgress={hideProgress}
        />

        {!isCollapsed && (
          <div
            className={`flex flex-col p-5 rounded rounded-t-none gap-4 border border-solid border-gray-300 border-t-0 min-h-[232px]`}
          >
            <div className="flex justify-between relative">
              <div className="flex-1 min-h-[75px]">
                <div className="w-full relative">
                  {(step.stepType === 'tool' || step.stepType === 'rule') && (
                    <FlowStepWithText
                      readOnly={readOnly}
                      text={step.text || ''}
                      textChange={(text) => stepChange({ ...step, text })}
                      toolConfig={toolConfig}
                      setIsPristine={setIsPristine}
                      variables={step.variables || []}
                      depth={depth}
                      allowComments
                    />
                  )}
                  {step.stepType === 'insights' && (
                    <FlowInsightsStep
                      readOnly={readOnly}
                      step={step}
                      stepChange={stepChange}
                      setIsPristine={setIsPristine}
                      depth={depth}
                      toolConfig={toolConfig}
                    />
                  )}
                  {step.stepType === 'preloadData' && (
                    <div>
                      <FlowLoadDataStep
                        readOnly={readOnly}
                        step={step}
                        stepChange={stepChange}
                        setIsPristine={setIsPristine}
                        workflowDialect={dialect}
                      />
                    </div>
                  )}
                  {step.stepType === 'preloadRuns' && (
                    <div>
                      <FlowLoadRunsStep
                        readOnly={readOnly}
                        step={step}
                        stepChange={stepChange}
                        setIsPristine={setIsPristine}
                        sequenceId={sequenceId}
                      />
                    </div>
                  )}
                  {step.stepType === 'preloadGoogleSheet' && (
                    <FlowPreloadGoogleSheetStep
                      readOnly={readOnly}
                      onSave={(args) => {
                        setIsPristine(false);
                        return stepChange({
                          ...step,
                          ...args,
                        });
                      }}
                      {...step}
                    />
                  )}
                  {step.stepType === 'preloadCSV' && (
                    <FlowPreloadGoogleCSV
                      readOnly={readOnly}
                      onSave={(args) => {
                        setIsPristine(false);
                        return stepChange({
                          ...step,
                          ...args,
                        });
                      }}
                      {...step}
                    />
                  )}

                  {step.stepType === 'subSequence' && (
                    <FlowSubWorkflowStep
                      readOnly={readOnly}
                      onSave={(sequences) => saveSequences(sequences, step.id)}
                      sequenceIds={step.sequenceIds}
                      limitToOne={fromRule}
                      setIsPristine={setIsPristine}
                      parentSequenceId={sequenceId}
                    />
                  )}
                  {step.stepType === 'sendToDashboard' && (
                    <FlowSendToDashboardStep
                      readOnly={readOnly}
                      onSave={(dashboardId) => stepChange({ ...step, dashboardId })}
                      dashboardId={step.dashboardId}
                      setIsPristine={setIsPristine}
                    />
                  )}
                  {step.stepType === 'sendToEmail' && (
                    <FlowSendToEmailStep
                      readOnly={readOnly}
                      email={step.email}
                      setEmail={(email) => stepChange({ ...step, email })}
                      formats={step.formats}
                      setFormats={(formats) => {
                        setIsPristine(false);
                        stepChange({ ...step, formats });
                      }}
                      setIsPristine={setIsPristine}
                    />
                  )}
                  {step.stepType === 'sendToSlack' && (
                    <FlowSendToSlackStep
                      readOnly={readOnly}
                      setIsPristine={setIsPristine}
                      formats={step.formats}
                      setFormats={(formats) => stepChange({ ...step, formats })}
                    />
                  )}
                  {step.stepType === 'sendToGoogleSheet' && (
                    <FlowSendToGoogleSheetStep
                      readOnly={readOnly}
                      onSave={(args) => {
                        setIsPristine(false);
                        return stepChange({
                          ...step,
                          ...args,
                        });
                      }}
                      {...step}
                    />
                  )}
                  {step.stepType === 'sendToWarehouse' && (
                    <FlowSendToWarehouse
                      readOnly={readOnly}
                      depth={depth}
                      onSave={(
                        query,
                        params,
                        useDedicatedQuery,
                        providerId,
                        integrationId,
                        tableId,
                      ) =>
                        stepChange({
                          ...step,
                          query,
                          queryParams: params,
                          useDedicatedQuery: useDedicatedQuery,
                          providerId,
                          integrationId,
                          tableId,
                        })
                      }
                      providerId={step.providerId}
                      integrationId={step.integrationId}
                      tableId={step.tableId}
                      setIsPristine={setIsPristine}
                      query={step.query}
                      useDedicatedQuery={step.useDedicatedQuery}
                      toolConfig={toolConfig}
                      step={step}
                    />
                  )}
                  {step.stepType === 'runQuery' && (
                    <FlowRunQueryStep
                      depth={depth}
                      step={step}
                      onSave={(query, params) =>
                        stepChange({ ...step, query, queryParams: params })
                      }
                      readOnly={readOnly}
                      toolConfig={toolConfig}
                      setIsPristine={setIsPristine}
                    />
                  )}
                  {step.stepType === 'condition' && (
                    <FlowRuleStep
                      step={step}
                      stepChange={stepChange}
                      readOnly={readOnly}
                      setIsPristine={setIsPristine}
                    />
                  )}
                  {step.stepType === 'sendToWebhook' && (
                    <FlowSendToWebhookStep
                      step={step}
                      sequenceId={sequenceId}
                      stepChange={(url, headers) => stepChange({ ...step, url, headers })}
                      readOnly={readOnly}
                      setIsPristine={setIsPristine}
                    />
                  )}
                  {step.stepType === 'loop' && (
                    <FlowLoopStep
                      readOnly={readOnly}
                      setIsPristine={setIsPristine}
                      toolConfig={toolConfig}
                      step={step}
                      onSave={(query, params, maxIterations) =>
                        stepChange({ ...step, query, queryParams: params, maxIterations })
                      }
                      dialect={dialect}
                      depth={depth}
                    />
                  )}
                </div>
              </div>
            </div>
            {!!stepRunInfo?.rephrasedText && showRephrasedText && (
              <div className="bg-white p-3 rounded-md flex flex-col gap-2 relative">
                <div className="absolute top-2 right-2">
                  <ActionIcon
                    icon="close"
                    iconSize={16}
                    onClick={() => setShowRephrasedText(false)}
                  />
                </div>
                <Text fz={12} fw={500}>
                  The step was rephrased to:
                </Text>
                <div className="max-h-[200px] overflow-hidden">
                  <WillySimpleText text={stepRunInfo.rephrasedText} />
                </div>
              </div>
            )}
            <div className="mt-auto">
              {!!stepError && !isPristine && (
                <Text size="sm" fw="bold" color="red.4">
                  {stepError}
                </Text>
              )}
            </div>
            <div className="flex justify-between">
              <div className="flex gap-2">
                <Button
                  variant="white"
                  size="xs"
                  onClick={() => _onShowAnswer(step.id)}
                  disabled={!canRegenerate}
                >
                  Run until here
                </Button>
                <Button
                  variant="white"
                  size="xs"
                  onClick={() => _onShowAnswer(step.id, step.id)}
                  disabled={!canRegenerate}
                >
                  Run only this step
                </Button>
              </div>

              {hasAnswer && (
                <div
                  className="flex gap-3 items-center cursor-pointer"
                  onClick={() => {
                    setAnswerHidden((x) => !x);
                  }}
                >
                  <Text fz={12} fw={500}>
                    Response
                  </Text>
                  <span className={`transition-transform ${answerHidden ? '' : 'rotate-180'}`}>
                    <Icon name={'caret-down'} size={10} />
                  </span>
                </div>
              )}
            </div>
            {hasAnswer && !answerHidden && (
              <div
                className={`flex flex-col gap-4 transition-[height] duration-300 ease-in-out ${!answerHidden ? 'h-full' : 'h-0'}`}
              >
                {isRunning && (
                  <div className="flex items-center w-full justify-center">
                    <span className="scale-50">
                      <AlanLoaderGray />
                    </span>
                  </div>
                )}
                {!isRunning && !!messages?.length && (
                  <div className="relative">
                    <div
                      className={`max-h-[${MAX_ANSWER_BOX_HEIGHT}px] overflow-hidden`}
                      style={{ maxHeight: `${MAX_ANSWER_BOX_HEIGHT}px` }}
                    >
                      <div ref={setAnswerBoxRef} id="answer-wrapper">
                        {messages?.map((message, i, arr) => {
                          return (
                            <WillyMessageTemplate
                              message={message}
                              firstOfAssistant={
                                message.role !== 'user' && arr[i - 1]?.role === 'user'
                              }
                              lastOfAssistant={
                                (message.role !== 'user' && arr[i + 1]?.role === 'user') ||
                                i === arr.length - 1
                              }
                              lastMessageFromUser={lastMessageFromUser}
                              canEdit={false}
                              userName={''}
                              handleSubmit={() => {}}
                              key={message.id + '_element'}
                              loading={loadingAnswers}
                              codeActions={[]}
                              isLast={i === messages.length - 1}
                              conversationUser={user.uid || ''}
                              isSequenceMode
                              //   TODO:notsure
                              conversationMessages={messages}
                            />
                          );
                        })}
                      </div>
                    </div>
                    {isOverflowing && (
                      <div
                        onClick={() => setFullAnswerPopupOpen(true)}
                        className="bg-gradient-to-t from-white via-white to-transparent absolute bottom-0 h-[150px] items-end justify-center w-full flex cursor-pointer z-10"
                      >
                        <Text fz={14} fw={600} color="one.6">
                          Open Full Answer
                        </Text>
                      </div>
                    )}
                  </div>
                )}
                {!!stepRunInfo?.comments?.length && (
                  <div className="flex flex-col gap-2">
                    {stepRunInfo.comments.map((comment) => (
                      <Text fz={12} key={comment}>
                        {comment}
                      </Text>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      {!noMoreStepsAllowed && !viewOnly && <TreeVerticalLine />}

      <Modal opened={assetModalOpened} onClose={() => setAssetModalOpened(false)}>
        <ToolPreloadAssets
          toolPreload={step}
          canEdit={canEditToolPreload}
          onEdit={(val) => {
            if (step.stepType !== 'tool') {
              return;
            }
            if (step.toolPreload?.name === 'TextToSQL') {
              stepChange({
                ...step,
                toolPreload: { ...step.toolPreload, generatedQuery: val },
              });
            }
            if (step.toolPreload?.name === 'TextToPython') {
              stepChange({
                ...step,
                toolPreload: { ...step.toolPreload, pythonCode: val },
              });
            }
          }}
        />
        <Modal.Footer>
          <Flex gap="xs" justify="flex-end">
            <Button variant="white" onClick={() => setAssetModalOpened(false)}>
              Close
            </Button>
            <Button onClick={() => setCanEditToolPreload((x) => !x)} variant="white">
              {canEditToolPreload ? 'Close edit mode' : 'Edit mode'}
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                if (step.stepType !== 'tool') {
                  return;
                }
                stepChange({
                  ...step,
                  toolPreload: null,
                });
                setAssetModalOpened(false);
              }}
            >
              Remove{' '}
              {step.stepType === 'tool'
                ? step.toolPreload?.name === 'TextToSQL'
                  ? 'query'
                  : 'code'
                : 'assets'}
            </Button>
          </Flex>
        </Modal.Footer>
      </Modal>

      <Modal.Root
        fullScreen
        opened={fullAnswerPopupOpen}
        onClose={() => setFullAnswerPopupOpen(false)}
      >
        <Modal.Overlay />
        <Modal.Content className="">
          <Modal.Header>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body className="bg-white">
            <div id="answer-wrapper">
              {messages?.map((message, i, arr) => {
                return (
                  <WillyMessageTemplate
                    message={message}
                    firstOfAssistant={false}
                    lastOfAssistant={
                      (message.role !== 'user' && arr[i + 1]?.role === 'user') ||
                      i === arr.length - 1
                    }
                    lastMessageFromUser={lastMessageFromUser}
                    canEdit={false}
                    userName={''}
                    handleSubmit={() => {}}
                    key={message.id + '_element'}
                    loading={loadingAnswers}
                    codeActions={[]}
                    isLast={i === messages.length - 1}
                    conversationUser={user.uid || ''}
                    isSequenceMode
                    //   TODO:notsure
                    conversationMessages={messages}
                  />
                );
              })}
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
};

type ToolPreloadAssetsProps = {
  toolPreload: WorkflowStep;
  canEdit: boolean;
  onEdit: (val: string) => void;
};

export const ToolPreloadAssets: React.FC<ToolPreloadAssetsProps> = ({
  toolPreload,
  canEdit,
  onEdit,
}) => {
  const code = useMemo(() => {
    if (toolPreload.stepType !== 'tool') {
      return 'No assets found';
    }
    if (toolPreload.toolPreload?.name === 'TextToSQL') {
      return formatSqlSafely(toolPreload.toolPreload?.generatedQuery || 'Query not generated');
    }
    if (toolPreload.toolPreload?.name === 'TextToPython') {
      return toolPreload.toolPreload?.pythonCode || 'Code not generated';
    }
    return 'No assets found';
  }, [toolPreload]);

  if (toolPreload.stepType !== 'tool') {
    return null;
  }

  return (
    <div>
      {canEdit && (
        <Textarea autosize size="md" value={code} onChange={(e) => onEdit(e.target.value)} />
      )}
      {!canEdit && (
        <ReactSyntaxHighlighter
          language={toolPreload.toolPreload?.name === 'TextToSQL' ? 'sql' : 'python'}
          style={vscDarkPlus}
          showLineNumbers
          customStyle={{
            maxHeight: '300px',
            overflow: 'auto',
          }}
        >
          {code}
        </ReactSyntaxHighlighter>
      )}
    </div>
  );
};
