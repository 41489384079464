import { InsightsFilter } from '@tw/types/module/services/insights';
import { Button, Flex, TextInput, Text, Modal } from '@tw/ui-components';
import { useState } from 'react';

export type SetFilterNameModalProps = {
  filter?: Partial<InsightsFilter>;
  onClose: () => void;
  onSave: (newName: string) => void;
};
export const SetFilterNameModal: React.FC<SetFilterNameModalProps> = ({
  filter,
  onSave,
  onClose,
}) => {
  const [name, setName] = useState<string>(filter?.name || 'Untitled Filter');

  return (
    <Modal
      opened={true}
      onClose={onClose}
      title={<Text tt="capitalize">Name Quick Filter</Text>}
      headerBorder
    >
      <Modal.Body pt="lg">
        <TextInput value={name} onChange={setName} />
      </Modal.Body>
      <Modal.Footer>
        <Flex justify="end" align="center" gap="md">
          <Button variant="secondary" size="sm" onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={!name} size="sm" onClick={() => onSave(name)}>
            Save
          </Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};
