import { useMemo } from 'react';
import { matchRoutes } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export const useIsFeedItemPage = () => {
  const location = useLocation();
  const matchedRoute = useMemo(() => {
    return matchRoutes([{ path: 'workflows/view/:workflowId/:runId' }], location);
  }, [location]);

  return matchedRoute;
};
