import { Image, Text } from '@tw/ui-components';
import buildingInPublicGif from './building_public.gif';

export const BuildingInPublicTag = () => {
  return (
    <div className="bg-[#CCFBF2] w-[150px] h-[28px] rounded-[10px] flex items-center px-[6px] gap-[7px]">
      <Image src={buildingInPublicGif} h={24} />
      <div className="pt-[1px]">
        <Text color="teal.8" fz={12} fw={500}>
          Building in public
        </Text>
      </div>
    </div>
  );
};
