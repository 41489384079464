import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Tooltip, Popover, ActionIcon } from '@tw/ui-components';
import { genericEventLogger, analyticsEvents, dashboardsActions } from 'utils/dataLayer';
import { NlqResponse, WillyDashboardElement } from '../types/willyTypes';
import { takeSampleOfData } from '../utils/willyUtils';
import { WillySimpleText } from '../WillySimpleText';
import { getSocket } from '../WillySocket';
import { useCurrentPopoverOpenerButtonTitle } from 'components/useGetPopoverOpenerButtonTitle';

type WidgetInsightsProps = {
  dashboard: WillyDashboardElement;
  widgetTitle: string;
  rawData: NlqResponse;
  loading: boolean | undefined;
  messageId: string;
};

export const WidgetInsights = ({
  dashboard,
  widgetTitle,
  rawData,
  loading,
  messageId,
}: WidgetInsightsProps) => {
  const willySocket = useMemo(() => getSocket(), []);
  const currentPopoverLabel = useCurrentPopoverOpenerButtonTitle();
  const [popoverActive, setPopoverActive] = useState(false);
  const [widgetInsights, setWidgetInsights] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const generateWidgetInsights = useCallback(() => {
    setError(null);

    timeoutRef.current = setTimeout(() => {
      setError('Request timed out. Please try again.');
    }, 10000);

    genericEventLogger(analyticsEvents.DASHBOARDS, {
      action: dashboardsActions.GENERATE_WIDGET_INSIGHTS,
      widgetTitle,
      dashboardId: dashboard?.id || '',
      messageId,
    });

    willySocket.emit('explain-widget', {
      dashboard,
      dateRange: currentPopoverLabel,
      widgetTitle,
      widgetQuestion: rawData?.question ?? '',
      sqlQuery: rawData?.generatedQuery ?? '',
      parsedData: takeSampleOfData(rawData?.data ?? {}),
      totalRows: rawData?.twTotalCount ?? 0,
      messageId,
    });

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [
    widgetTitle,
    dashboard,
    willySocket,
    currentPopoverLabel,
    rawData?.question,
    rawData?.generatedQuery,
    rawData?.data,
    rawData?.twTotalCount,
    messageId,
  ]);

  useEffect(() => {
    const handleWidgetInsight = (data: any) => {
      if (data.messageId !== messageId) {
        return;
      }

      if (data.type === 'chunk' && data.text) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        setError(null);
        setWidgetInsights((prevInsights) => `${prevInsights}${data.text}`);
      } else if (data.type === 'error') {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        setError('Something went wrong while generating insights. Please try again.');
      }
    };

    willySocket.on('widget-insight', handleWidgetInsight);

    // Handle socket disconnection
    willySocket.on('disconnect', () => {
      setError('Lost connection to server. Please try again.');
    });

    return () => {
      willySocket.off('widget-insight', handleWidgetInsight);
      willySocket.off('disconnect');
    };
  }, [messageId, willySocket]);

  return (
    <Popover
      shadow="md"
      opened={popoverActive}
      onClose={() => setPopoverActive(false)}
      position="bottom"
    >
      <Tooltip label="Widget Insights">
        <Popover.Target>
          <ActionIcon
            icon="star-plus"
            disabled={loading}
            onClick={() => {
              setPopoverActive(!popoverActive);

              if (!popoverActive) {
                genericEventLogger(analyticsEvents.DASHBOARDS, {
                  action: dashboardsActions.VIEW_WIDGET_INSIGHTS,
                  widgetTitle,
                  dashboardId: dashboard?.id || '',
                  messageId,
                });

                if (widgetInsights.length === 0) {
                  generateWidgetInsights();
                }
              }
            }}
          />
        </Popover.Target>
      </Tooltip>
      <Popover.Dropdown p="lg" w="50vw" h="50vh" maw="400px" mah="500px" overflow="scroll">
        <div>
          {error ? (
            <div className="text-red-500">{error}</div>
          ) : widgetInsights.length > 0 ? (
            <WillySimpleText text={widgetInsights} />
          ) : (
            <div>Generating insights...</div>
          )}
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};
