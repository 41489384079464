import { useMemo } from 'react';
import {
  WillyDataColumn,
  RawNlqData,
  NlqResponseDataType,
  VisualizationTypes,
  MessageTypes,
} from '../types/willyTypes';
import { keyIsSomeDate } from '../utils/willyUtils';

export const useDefaultType = (
  data?: RawNlqData,
  dataColumns?: WillyDataColumn,
  question?: string,
  dataType?: NlqResponseDataType,
  visualizationType?: VisualizationTypes,
) => {
  const defaultType = useMemo(() => {
    return guessDefaultType(data, dataColumns, question, dataType, visualizationType);
  }, [question, dataColumns, data, dataType, visualizationType]);

  return defaultType;
};

export function guessDefaultType(
  data?: RawNlqData,
  dataColumns?: WillyDataColumn,
  question?: string,
  dataType?: NlqResponseDataType,
  visualizationType?: VisualizationTypes,
): MessageTypes {
  const byVisualizationType = convertVisualizationTypeToMessageType(visualizationType);
  if (byVisualizationType) {
    return byVisualizationType;
  }
  if (dataType === 'forecast') {
    return 'chart';
  }
  if (!dataColumns || !data) {
    return 'table';
  }

  if (
    ['state', 'city', 'country'].some((x) => question?.toLowerCase().includes(x)) ||
    dataColumns.x.some(
      (x) =>
        x.includes('state') ||
        x.includes('city') ||
        x.includes('country') ||
        x.includes('location'),
    )
  ) {
    return 'map';
  }

  if (['chart', 'graph', 'plot'].some((x) => question?.toLowerCase().includes(x))) {
    return 'chart';
  } else if (['table', 'list', 'tabular'].some((x) => question?.toLowerCase().includes(x))) {
    return 'table';
  }

  if (dataColumns?.x?.length === 0 && dataColumns?.y?.length < 2) {
    return 'tile';
  }

  if (dataColumns?.x?.length === 0 && dataColumns?.y?.length >= 2) {
    return 'pie';
  }

  const dataLength = data.find((d) => d.name === dataColumns.x[0])?.value?.length || 0;

  if (dataLength > 1) {
    return 'table';
  }

  if (!dataColumns.x.some((x) => keyIsSomeDate(x))) {
    return 'tile';
  }

  if (dataColumns?.y?.length > 0) {
    return 'table';
  }

  return 'table';
}

export function convertVisualizationTypeToMessageType(
  visualizationType?: VisualizationTypes,
): MessageTypes | undefined {
  if (!visualizationType) {
    return undefined;
  }
  if (visualizationType === 'Auto') {
    return undefined;
  }
  if (visualizationType === 'Table') {
    return 'table';
  }
  if (visualizationType === 'Tile') {
    return 'tile';
  }
  if (visualizationType === 'Pie') {
    return 'pie';
  }
  if (visualizationType === 'Map') {
    return 'map';
  }
  if (
    visualizationType === 'Bar' ||
    visualizationType === 'Line' ||
    visualizationType === 'Scatter' ||
    visualizationType === 'StackedBar' ||
    visualizationType === 'Area'
  ) {
    return 'chart';
  }

  return undefined;
}
