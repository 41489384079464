import { $currentShopId } from '$stores/$shop';
import {
  $isAdminClaim,
  $isShopAdmin,
  $isTwGlobalDashboardCreatorClaim,
  $userId,
} from '$stores/$user';
import { $derived, $mutableDerived, $mutableObserver, $observer, $store } from '@tw/snipestate';
import {
  Column,
  ColumnName,
  ScheduleItem,
  WillyDataSequence,
  WillyElementType,
} from 'components/Willy/types/willyTypes';
import _db, { firestoreRef, toArray } from 'utils/DB';
import { $ffStore } from '../../feature-flag-system';
import { $shopWithSensory } from '../$shopWithSensory';
import { $globalDashboardsStatistics } from './$globalDashboardsStatistics';
import { services } from '@tw/types/module/services';
import { FeatureFlag, FeatureFlagConfigKey } from '@tw/feature-flag-system/module/types';
import { isEqual } from 'lodash';

const $globalSequencesSnapshot = $observer(
  { data: [] as WillyDataSequence[], loading: false, error: null as string | null },
  (get, set) => {
    if (!get($userId)) return;

    set({ ...get(), loading: true });
    return firestoreRef()
      .collection('global_data_sequences')
      .onSnapshot((querySnapshot) =>
        set({ ...get(), loading: false, data: toArray(querySnapshot) }),
      );
  },
);

const $privateGlobalSequences = $observer(
  { data: [] as WillyDataSequence[], loading: false, error: null as string | null },
  (get, set) => {
    const snapshot = get($globalSequencesSnapshot);
    if (snapshot.loading) {
      set({ ...get(), loading: true });
      return;
    }

    const ffComputer = get($ffStore);
    if (!ffComputer.isReady) return;

    const shopWithSensory = get($shopWithSensory);
    const globalDashboardsStatistics = get($globalDashboardsStatistics);
    const isTwGlobalDashboardCreatorClaim = get($isTwGlobalDashboardCreatorClaim);
    const isAdminClaim = get($isAdminClaim);
    const { allowList } = ffComputer.getConfigById(FeatureFlag.TEMPLATES_FF);
    const allowedSeqIds = new Set(allowList);

    const data = snapshot.data
      .sort((a, b) => {
        if (a.name < b.name) return -1;
        return 1;
      })
      .map((sequence) => {
        let isProviderLocked = false;
        const { providers, providersBlockingCombination } = sequence;
        if (!!providers?.length) {
          const providersIsConnected = providers.map(
            (x) => services[x]?.getIsConnected?.(shopWithSensory) ?? true,
          );

          if (providersBlockingCombination === 'NONE') {
            isProviderLocked = false;
          } else if (providersBlockingCombination === 'OR') {
            isProviderLocked = providersIsConnected.every((x) => !x);
          } else {
            isProviderLocked = providersIsConnected.includes(false);
          }
        }

        const pkgMap = ffComputer.ffPackagesConfig;

        const packages: FeatureFlagConfigKey[] = [];
        if (pkgMap) {
          for (let pkgName in pkgMap) {
            const _pkgName = pkgName as FeatureFlagConfigKey;
            const val = pkgMap?.[_pkgName]?.[FeatureFlag.TEMPLATES_FF]?.value;
            if (Array.isArray(val) && (val as any[]).includes(sequence.id)) packages.push(_pkgName);
          }
        }

        const defaultPackages: FeatureFlagConfigKey[] = [];
        if (pkgMap) {
          for (let pkgName in pkgMap) {
            const _pkgName = pkgName as FeatureFlagConfigKey;
            const val = pkgMap?.[_pkgName]?.[FeatureFlag.WILLY_DEFAULT_TEMPLATES_FF]?.value;
            if (Array.isArray(val) && (val as any[]).includes(sequence.id))
              defaultPackages.push(_pkgName);
          }
        }

        return {
          ...sequence,
          type: 'sequence',
          isGlobal: true,
          installCount: globalDashboardsStatistics[sequence.id]?.installed ?? 0,
          packages,
          defaultPackages,
          canEdit: isTwGlobalDashboardCreatorClaim,
          isLocked: !!(allowedSeqIds.size && !allowedSeqIds.has(sequence.id)),
          isProviderLocked,
        } satisfies WillyDataSequence;
      })
      .filter(({ isBeta }) => (isAdminClaim ? true : !isBeta))
      .filter(({ isHide }) => !isHide)
      .filter((x) => !x.deleted);

    set({ ...get(), loading: false, data });
  },
);

export const $globalSequences = $derived((get) => get($privateGlobalSequences).data);

export const $loadingGlobalSequences = $derived((get) => get($privateGlobalSequences).loading);

export const $shopSequenceSnapshot = $observer<WillyDataSequence[]>([], (get, set) => {
  const shopId = get($currentShopId);
  if (!shopId) return set([]);
  return _db(shopId)
    .collection('data_sequences')
    .onSnapshot((querySnapshot) => {
      let data: WillyDataSequence[] = toArray(querySnapshot);
      set(data);
    });
});

export const $shopSequencesRaw = $observer<WillyDataSequence[]>([], (get, set) => {
  if (!get($userId)) return;

  const shopId = get($currentShopId);
  if (!shopId) return set([]);
  const shopSequenceSnapshot = get($shopSequenceSnapshot);
  const globalSequences = get($globalSequences);
  const isUserTwAdmin = get($isAdminClaim);
  const isShopAdmin = get($isShopAdmin);
  const shopSequences = shopSequenceSnapshot
    .filter((x) => x.name)
    .filter((x) => !x.deleted)
    .map((seq) => {
      let isLocked = false;
      let isProviderLocked = false;

      if (
        seq.globalDashboardId &&
        globalSequences.map((x) => x.id).includes(seq.globalDashboardId)
      ) {
        const globalSeq = globalSequences.find((d) => d.id === seq.globalDashboardId);
        if (globalSeq) {
          isLocked = globalSeq.isLocked ?? false;
          isProviderLocked = globalSeq.isProviderLocked ?? false;
        }
      }

      return {
        ...seq,
        isLocked,
        isProviderLocked,
        canEdit: isUserTwAdmin || isShopAdmin, //TODO @Yitzchak Sviridyuk permission
        isGlobal: false,
        type: 'sequence' as WillyElementType,
      };
    })
    .sort((a, b) => {
      if (a.name < b.name) return -1;
      return 1;
    });
  if (isEqual(shopSequences, get())) return;
  set(shopSequences);
});

export const $shopSequences = $mutableDerived<WillyDataSequence[]>((get) => {
  const shopSequencesRaw = get($shopSequencesRaw);
  return shopSequencesRaw; // 🧐
});

export const $sequencesUnreadReportsPerDashboard = $observer<Record<string, number>>(
  {},
  (get, set) => {
    const shopId = get($currentShopId);
    const userId = get($userId);
    if (!shopId || !userId) return;

    return firestoreRef()
      .collectionGroup('workflow_feed')
      .where('shopId', '==', shopId)
      .onSnapshot((querySnapshot) => {
        const data = querySnapshot.docs.reduce(
          (acc, doc) => {
            const { read } = doc.data();
            if (read) {
              return acc;
            }
            const dashboardId = doc.ref.parent.parent?.id;
            if (!dashboardId) return acc;
            acc[dashboardId] = (acc[dashboardId] || 0) + 1;
            return acc;
          },
          {} as Record<string, number>,
        );
        set(data);
      });
  },
);

export const $globalAndShopSequences = $mutableDerived<WillyDataSequence[]>((get) => {
  const shopSequences = get($shopSequences);
  const globalSequences = get($globalSequences);

  // return all of the 2 lists above, but if the id exists in both lists, return the shop sequence
  // but if one of the shop's sequences has "globalDashboardId", remove the global with this globalDashboardId as an id
  return [...globalSequences, ...shopSequences].filter((seq, index, self) => {
    if (seq.globalDashboardId) {
      return !globalSequences.find((g) => g.id === seq.globalDashboardId);
    }
    return index === self.findIndex((t) => t.id === seq.id);
  });
});

export const $defaultAiColumns = $derived(async (get): Promise<Column<ColumnName>[]> => {
  const userId = get($userId);
  if (!userId) {
    return [];
  }
  const globalColumns = await firestoreRef().collection('ai_columns').get();
  return toArray(globalColumns);
});

export const $shopAiColumns = $derived(async (get): Promise<Column<ColumnName>[]> => {
  const shopId = get($currentShopId);
  const userId = get($userId);
  if (!shopId || !userId) {
    return [];
  }
  const columns = await _db(shopId).collection('ai_columns').get();
  return toArray(columns);
});

export const $mergedAiColumns = $mutableDerived<{
  columns: Column<ColumnName>[];
  loading: boolean;
  error?: unknown;
}>((get) => {
  const defaultAiColumns = get($defaultAiColumns);
  const shopAiColumns = get($shopAiColumns);
  return {
    columns:
      defaultAiColumns.data?.map((column) => {
        const shopColumn = shopAiColumns.data?.find((c) => c.key === column.key);
        return { ...column, ...shopColumn };
      }) ?? [],
    loading: defaultAiColumns.pending || shopAiColumns.pending,
    error: defaultAiColumns.error || shopAiColumns.error,
  };
});

export async function updateShopColumn(shopId: string, column: Column<ColumnName>) {
  await _db(shopId).collection('ai_columns').doc(column.key).set(column, { merge: true });
}
