import { $isFreeShop } from '$stores/willy/$subscription';
import { TW_LS_ATTRIBUTION_DATE_MODEL_KEY, TW_LS_ATTRIBUTION_WINDOW } from 'constants/attribution';
import { AttributionWindowArr } from 'constants/types';
import { changeAttributionWindow } from 'ducks/attribution/actions';
import { upgradePixelModalOpened } from 'ducks/subscription';
import { useFeatureFlagComputer } from 'feature-flag-system';
import { useAppDispatch } from 'index';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { analyticsEvents, genericEventLogger, upgradeActions } from 'utils/dataLayer';

import { Tooltip } from '@shopify/polaris';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { useStoreValue } from '@tw/snipestate';
import { Button, Flex, Icon, Menu, Text } from '@tw/ui-components';

export const AttributionWindowItems = AttributionWindowArr.map((item) => ({
  label: item === 'lifetime' ? 'Lifetime' : item === '1' ? '1 day' : item + ' days',
  value: item,
}));

type AttributionWindowPickerProps = {
  theme?: 'light' | 'dark' | 'none';
  withinPortal?: boolean;
  onClose?: (value: boolean) => void;
};

const AttributionWindowPicker: React.FC<AttributionWindowPickerProps> = ({
  theme,
  withinPortal = true,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const { attributionWindow, useNewModels, attributionModel } = useSelector(
    (state: RootState) => state.attribution,
  );
  const isClickDateModel = localStorage.getItem(TW_LS_ATTRIBUTION_DATE_MODEL_KEY) === 'clickDate';
  const ffComputer = useFeatureFlagComputer();
  const { blockList: ffBlockedWindows } = ffComputer.getConfigById(
    FeatureFlag.LIMIT_PIXEL_ATTRIBUTION_WINDOWS_FF,
  );
  const isFreeShop = useStoreValue($isFreeShop);

  return (
    <Menu
      disabled={
        isClickDateModel ||
        (useNewModels && attributionModel === 'ppsViews') ||
        (!useNewModels && attributionModel !== 'lastPlatformClick-v2')
      }
      withinPortal={withinPortal}
      position={withinPortal ? 'bottom' : 'right-start'}
      offset={withinPortal ? undefined : 16}
    >
      <Menu.Target>
        <div>
          <Tooltip
            content={
              !useNewModels && attributionModel !== 'lastPlatformClick-v2'
                ? 'Attribution Windows are available only for the TW model'
                : isClickDateModel
                  ? 'Attribution Windows are not available for Click Date model'
                  : ''
            }
          >
            {withinPortal ? (
              <Button
                size="xs"
                variant="activator"
                //forceColorScheme={theme}
                rightSection="caret-down"
                fullWidth
                fz="sm"
                fw={500}
              >
                Attribution Window
              </Button>
            ) : (
              <Flex align="center" gap="md" justify="space-between" id="subscription-filter">
                <Text size="sm" weight={500} color="named2.0">
                  Attribution Window: {attributionWindow}
                </Text>
                <Icon name="chevron-right-minor" size={12} color="named.8" />
              </Flex>
            )}
          </Tooltip>
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        {ffBlockedWindows.length > 0 ? (
          <>
            {AttributionWindowItems.filter((x) => !ffBlockedWindows.includes(x.value)).map(
              (option) => (
                <Menu.Item
                  key={option.value}
                  onClick={() => {
                    dispatch(changeAttributionWindow(option.value));
                    localStorage.setItem(TW_LS_ATTRIBUTION_WINDOW, JSON.stringify(option.value));
                  }}
                  bg={attributionWindow === option.value ? 'named2.4' : undefined}
                >
                  {option.label}
                </Menu.Item>
              ),
            )}
            <Menu.Divider />
            <Menu.Item
              key={'upgrade'}
              onClick={() => {
                dispatch(upgradePixelModalOpened('Attribution Windows'));
                genericEventLogger(analyticsEvents.UPGRADE, {
                  action: upgradeActions.UPGRADE_CLICKED,
                  isFoundersDash: isFreeShop,
                  pathname: location.pathname,
                  upgrade_path: 'LIMIT_PIXEL_ATTRIBUTION_WINDOWS_FF',
                  targetToUnlock: 'attributionWindows',
                });
                onClose && onClose(false);
              }}
            >
              <Flex gap="md" align="center">
                <Text size="sm" weight={600}>
                  Upgrade to unlock
                </Text>
                <Button size="xs">Upgrade</Button>
              </Flex>
            </Menu.Item>
            {AttributionWindowItems.filter((x) => ffBlockedWindows.includes(x.value)).map(
              (option) => (
                <Menu.Item key={option.value} disabled>
                  {option.label}
                </Menu.Item>
              ),
            )}
          </>
        ) : (
          AttributionWindowItems.map((option) => (
            <Menu.Item
              key={option.value}
              onClick={() => {
                dispatch(changeAttributionWindow(option.value));
                localStorage.setItem(TW_LS_ATTRIBUTION_WINDOW, JSON.stringify(option.value));
              }}
              bg={attributionWindow === option.value ? 'named2.4' : undefined}
            >
              {option.label}
            </Menu.Item>
          ))
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

export default AttributionWindowPicker;
