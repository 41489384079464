import { Icon } from '@tw/ui-components';
import { ColumnsSelector } from 'components/library/TWTable/ColumnSelector';
import { BaseColumn, savedPreset } from 'components/library/TWTable/types';
import { localStoragePixelColumnsKeys } from 'constants/attribution';
import { changeSelectedColumns } from 'ducks/attribution/actions';
import { CustomMetricsType } from 'ducks/customMetrics';
import { useAppDispatch } from 'index';
import {
  $allPixelColumnsIncludingCustom,
  selectPixelColumnsForCustomMetrics,
} from 'pages/Attribution/selectPixelColumns';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { PixelColumn } from 'types/attribution';
import _db, { userDb } from 'utils/DB';
import { useAttributionActiveSource } from 'utils/useAttributionActiveSource';
import { v4 as uuidV4 } from 'uuid';
import { defaultPresets } from 'components/attribution-new/columns';
import { useStoreValue } from '@tw/snipestate';

type props = {
  customActivator?: (onClick: () => void) => React.ReactNode;
};
export const AttributionHeaderColumnSelector: FC<props> = ({ customActivator }) => {
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const { selectedColumns } = useSelector((state: RootState) => state.attribution);
  const allPixelColumns = useStoreValue($allPixelColumnsIncludingCustom);
  const customMetricsColumns = useSelector(selectPixelColumnsForCustomMetrics);
  const dispatch = useAppDispatch();
  const activeSource = useAttributionActiveSource();
  const [savedPresets, setSavedPresets] = useState<savedPreset[]>([]);

  const key = localStoragePixelColumnsKeys.all;

  const collection = useMemo(() => {
    if (!currentShopId) return null;
    return _db(currentShopId).collection('attributionColumnPresets');
  }, [currentShopId]);

  const formatColumnsToRender = useCallback(
    (columns: PixelColumn[]) => {
      return columns.map((c) => ({
        ...c,
        columnMetadata: {
          subTitle: c.showInSources &&
            !c.showInSources.includes(activeSource) &&
            activeSource !== 'all' && (
              <p className="text-xl text-gray-400 italic">(Not available for this page)</p>
            ),
        },
        disableSort:
          c.showInSources && !c.showInSources.includes(activeSource) && activeSource !== 'all',
      }));
    },
    [activeSource],
  );

  const columnsToRender: BaseColumn<any, any>[] = useMemo(() => {
    return formatColumnsToRender(allPixelColumns as PixelColumn[]);
  }, [allPixelColumns, formatColumnsToRender]);

  const selectedColumnsToRender = useMemo(() => {
    return formatColumnsToRender(selectedColumns);
  }, [selectedColumns, formatColumnsToRender]);

  const savedPresetPlusDefault = useMemo(() => {
    const customMetricsPreset: savedPreset[] = customMetricsColumns?.length
      ? [
          {
            id: 'custom_metrics',
            name: 'Custom Metrics',
            description: 'Only metrics you created',
            columns: customMetricsColumns,
            isDefault: true,
          },
        ]
      : [];
    return [...savedPresets, ...defaultPresets['all'], ...customMetricsPreset];
  }, [savedPresets, customMetricsColumns]);

  useEffect(() => {
    (async () => {
      collection?.onSnapshot((snapshot) => {
        const presets = snapshot.docs.map((d) => ({ ...d.data(), id: d.id }) as any);
        const p = presets.map((p) => {
          return {
            ...p,
            columns: p.columns
              .map((c) => allPixelColumns.find((ac) => ac.key === c))
              .filter((c) => c),
          };
        });

        setSavedPresets(p);
      });
    })();
  }, [allPixelColumns, currentShopId, collection]);

  return (
    <div id="att-header-column-selector">
      <ColumnsSelector
        customActivator={customActivator}
        selectedColumns={selectedColumnsToRender}
        columns={columnsToRender}
        icon={<Icon name="columns-3-major" size={18} />}
        storageKey={key!}
        title={
          <span className="flex items-center gap-3">
            <Icon name="columns-3-major" size={18} />
            <span>Select Columns</span>
          </span>
        }
        setSelectedColumns={async (columns) => {
          dispatch(changeSelectedColumns(columns));
          await userDb().set(
            {
              shops: {
                [currentShopId]: {
                  ui: {
                    attribution: {
                      selectedColumns: columns.map((c) => c.key),
                    },
                  },
                },
              },
            },
            {
              merge: true,
            },
          );
        }}
        customMetricsType={CustomMetricsType.Attribution}
        savedPresets={savedPresetPlusDefault}
        allowSavePreset
        onSavePreset={async (selectedColumns, presetName, presetDescription = '') => {
          const uid = uuidV4();
          const newPreset = {
            name: presetName,
            description: presetDescription,
            columns: selectedColumns.map((c) => c),
            id: uid,
          };

          setSavedPresets((old) => [...old, newPreset]);
          await collection
            ?.doc(uid)
            .set({ ...newPreset, columns: newPreset.columns.map((c) => c.key) }, { merge: true });
        }}
        onDeletedPreset={async (presetId) => {
          setSavedPresets((old) => old.filter((p) => p.id !== presetId));
          await collection?.doc(presetId).delete();
        }}
        onEditPreset={async (id, name, description = '', columns) => {
          const newPreset = {
            name,
            description,
            columns,
            id,
          };

          setSavedPresets((old) => {
            return old.map((p) => {
              if (p.id === id) {
                return newPreset;
              }

              return p;
            });
          });
          await collection
            ?.doc(id)
            .set({ ...newPreset, columns: newPreset.columns.map((c) => c.key) }, { merge: true });
        }}
      />
    </div>
  );
};
