import { $derived, $mutableDerived, $observer, $store, ReadableStore } from '@tw/snipestate';
import { Shop } from '@tw/types';
import { isInIframe, isProduction } from 'config';
import { $redux } from './$redux';
import { $dialect, $userId } from './$user';
import _db, { userDb } from 'utils/DB';
import { $globalConfig } from './$globalConfig';
import Cookies from 'utils/Cookies';

const getInitialData = () => {
  const search = new URLSearchParams(location.search);
  const shopDomain = search.get('shop-domain') || search.get('shop-id') || null;
  let initialData = shopDomain ?? Cookies.get('currentShopId');

  if (isInIframe) {
    const idParam = new URLSearchParams(window.location.search).get('id');
    initialData = idParam?.length ? idParam : null;
  }

  if (initialData) {
    Cookies.set('currentShopId', initialData);
  }

  return initialData;
};

// make sure to clear active accounts when a new url is entered into the site
window.addEventListener('beforeunload', () => {
  if ($userId.get()) {
    userDb().update({ activeAccounts: [] });
  }
});

/** Only use as a readable store, because to properly set this, other things need to be done */
export const $currentShopId: ReadableStore<string | null> = $store(getInitialData());

export async function set_$currentShopId(shopId: string | null) {
  //@ts-ignore
  $currentShopId.set(shopId);

  if (!shopId) {
    console.log('clearing current shop id');
    Cookies.remove('currentShopId');
  } else {
    Cookies.set('currentShopId', shopId + '');
  }
  // userDb depends on user existing in state.
  // want to try replacing using userDb with $user store and having db
  // track all side effects
  if ($userId.get()) {
    await userDb().update({ activeAccounts: [] });
  }
}

const ssrShopData: Partial<Shop> | undefined = window.twShop;

export const $shop = $observer<Partial<Shop>>(
  ssrShopData || { shopName: '', subscription: {} },
  async (get, set) => {
    const userId = get($userId);
    const currentShopId = get($currentShopId);
    if (!currentShopId || !userId) return;

    // continue to listen for updates
    return _db(currentShopId).onSnapshot((shopDataSnapshot) => {
      set(shopDataSnapshot.data() || get());
    });
  },
);

export const $isSaas = $derived((get) => get($shop).msp === 'stripe');
export const $msp = $derived((get) => get($shop).msp);
export const $mspConnected = $derived((get) => !!get($shop).mspConnected);
export const $timezone = $derived((get) => get($shop).timezone);
export const $industry = $derived((get) => get($shop).industry);
export const $subscriptionActive = $derived((get) => get($shop).subscriptionActive);
export const $createdAt = $derived((get) => get($shop).createdAt);
export const $pixelActive = $derived((get) => get($shop).pixel);

export const $dontShowDeletedDashboard = $derived(
  (get) => get($shop).dontShowDeletedDashboard ?? false,
);

const $shopSwitcherAccountList = $derived((get) => get($redux)?.shopSwitcherAccountList || null);
export const $activeAccounts = $derived((get) => {
  const currentShopId = get($currentShopId);
  const userId = get($userId);
  if (!currentShopId || !userId) return null;

  const shopSwitcherAccountList = get($shopSwitcherAccountList) || [];
  const savedShops = [...new Set([...shopSwitcherAccountList, currentShopId])];
  return savedShops;
});

export const $shopCurrency = $derived((get) => get($shop).currency ?? 'USD');
const $currencySetByUser = $derived((get) => {
  const currencySetByUser = get($shop).currencySetByUser;
  return currencySetByUser ?? null;
});

export const $currency = $mutableDerived((get) => {
  const shopCurrency = get($shopCurrency);
  const currencySetByUser = get($currencySetByUser);
  return currencySetByUser ?? shopCurrency;
});

export const $shopForceBQInsight = $derived((get) => get($shop).forceBQInsight ?? null);
export const $useCHInsight = $mutableDerived((get) => {
  const shopForceBQInsight = get($shopForceBQInsight);

  if (shopForceBQInsight === true) {
    return false;
  }

  return true;
});

export const $shopForceBQCDP = $derived((get) => get($shop).forceBQCDP ?? null);
export const $useCHCdp = $mutableDerived((get) => {
  const shopForceBQInsight = get($shopForceBQCDP);

  if (shopForceBQInsight === true) {
    return false;
  }

  return false;
});

export const $mobyUserIds = $derived((get) => get($shop).mobyUserIds ?? null);

export const $shopPackageVersion = $derived((get) => get($shop).packageVersion);
