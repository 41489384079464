import './billing.scss';
import { useAppDispatch } from 'index';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { components } from 'react-select';
import { toast } from 'react-toastify';
import { Stripe } from 'stripe';
import { Checkbox, Icon, Modal, Spinner, Tooltip } from '@shopify/polaris';
import { BankMajor, InfoMinor } from '@shopify/polaris-icons';
import { AddressElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { setPaymentMethod, setPaymentMethodId } from '../../../ducks/signupJourney';
import { type RootState } from '../../../reducers/RootType';
import axiosInstance from '../../../utils/axiosInstance';
import PMSelectSettings from './PMSelectSettings';
import { getShopFeatures } from 'ducks/shop';
import { Button } from '@tw/ui-components';
import { goToPodsView } from 'utils/goToPodsView';
import { useNavigate } from 'react-router-dom';
import { $isFreeShop } from '$stores/willy/$subscription';
import { useStoreValue } from '@tw/snipestate';
import { calculatePriceWithCoupon } from 'components/UpgradePlan/PriceDisplay';
import { subscriptionPlan } from '@tw/types/module/services/subscription-manager';
import { SelectedAddon } from './Addons/AddonTypes';
import { amplitudeTracker } from 'utils/AmplitudeTracker';

type AppProps = {
  setIsModalOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  isUnpaid?: boolean;
  plan?: subscriptionPlan;
  addons?: SelectedAddon[];
  couponInUse?: any;
  setSkipAddonsStep?: (skipAddonsStep: boolean) => void;
  paymentMethodId?: string;
  setPaymentMethodIdLocal?: (paymentMethodId: string) => void;
  setAddress?: (address: {
    postal_code?: string;
    country?: string;
    city?: string;
    line1?: string;
    line2?: string;
  }) => void;
};

const CardOptionTemplate = (props) => (
  <div className={'flex-container card-select-settings-dropdown'}>
    {props.data.type === 'card' && (
      <>
        <img
          className={'card-icon-template-settings'}
          src={`/signupJourney/cardIcons/${props.data?.card?.brand}.svg`}
          alt=""
        />
        <div className={'digit4-settings'}> {` **** ${props.data?.card?.last4}`}</div>
        <div className={'exp-settings'}>
          {' '}
          {`EXP. ${props.data?.card?.exp_month}/ ${props.data?.card?.exp_year}`}
        </div>
      </>
    )}
    {props.data.type === 'us_bank_account' && (
      <>
        <div className={'card-icon-template-settings'}>
          <Icon source={BankMajor} color="subdued" />
        </div>
        <div className={'digit4-settings'}> {` **** ${props.data?.us_bank_account?.last4}`}</div>
        <div className={'exp-settings'}> {` ${props.data?.us_bank_account?.bank_name}`}</div>
      </>
    )}
  </div>
);

const { Option, SingleValue } = components;
const IconOption = (props) => (
  <Option {...props}>
    <CardOptionTemplate {...props} />
  </Option>
);
const customSingleValue = (props) => (
  <SingleValue {...props}>
    <CardOptionTemplate {...props} />
  </SingleValue>
);

const ChangePaymentMethodModal: React.FC<AppProps> = ({
  setIsModalOpen,
  isOpen,
  isUnpaid,
  plan,
  addons = [],
  couponInUse,
  setSkipAddonsStep,
  paymentMethodId,
  setPaymentMethodIdLocal,
  setAddress,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true);
  const [customerPaymentsMethod, setCustomerPaymentsMethod] = useState<Stripe.PaymentMethod[]>([]);
  const [paymentInfoComplete, setPaymentInfoComplete] = useState<boolean>(false);
  const [newSelected, setNewSelected] = useState<boolean>(true);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<Stripe.PaymentMethod>(
    null as any,
  );
  const currentShop = useSelector((state: RootState) => state.currentShop);
  const subscription = useSelector((state: RootState) => state.subscription);
  const isFreeShop = useStoreValue($isFreeShop);
  const [isAddressComplete, setIsAddressComplete] = useState(false);

  useEffect(() => {
    if (isFreeShop && paymentMethodId) {
      setIsLoadingPage(false);
      return;
    }

    const fetchPaymentMethodById = async (
      paymentMethodId: string,
    ): Promise<Stripe.PaymentMethod | null> => {
      try {
        const url = `/v2/subscription-manager/payments/paymentMethod/${paymentMethodId}`;
        const response = await axiosInstance.get<Stripe.PaymentMethod>(url);
        if (response.status < 400 && response.data) {
          return response.data;
        }
        return null;
      } catch (error) {
        console.error(`Error fetching payment method with ID ${paymentMethodId}:`, error);
        return null;
      }
    };

    const getPaymentMethodByCustomerId = async (customerId: string) => {
      setIsLoadingPage(true);
      try {
        const url = `v2/subscription-manager/payments/customer/${customerId}`;
        const response = await axiosInstance.get<Stripe.PaymentMethod[]>(url);
        if (response.status < 400 && response.data.length > 0) {
          setCustomerPaymentsMethod(response.data);
          setSelectedPaymentMethod(response.data[0]);
          setNewSelected(false);
        }
      } catch (e) {
        console.error(`Error getting customer payments:`, e);
      } finally {
        setIsLoadingPage(false);
      }
    };

    const fetchPaymentMethods = async () => {
      setIsLoadingPage(true);
      try {
        if (isFreeShop && paymentMethodId) {
          const paymentMethod = await fetchPaymentMethodById(paymentMethodId);
          if (paymentMethod) {
            setSelectedPaymentMethod(paymentMethod);
            setNewSelected(false);
          }
          return;
        }
        if (!isFreeShop && currentShop?.customerId) {
          await getPaymentMethodByCustomerId(currentShop.customerId);
        }
      } catch (e) {
        console.error(`Error fetching payment methods:`, e);
      } finally {
        setIsLoadingPage(false);
      }
    };

    fetchPaymentMethods();
  }, [paymentMethodId, currentShop?.customerId, isFreeShop]);

  const stripeElementChange = (e: any) => {
    setPaymentInfoComplete(!e.empty && e.complete);
  };

  const createPaymentMethodForFreeShop = async () => {
    try {
      if (!stripe || !elements) {
        return { success: false, errorMessage: 'Stripe elements are not initialized.' };
      }

      const { error, setupIntent }: any = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
        redirect: 'if_required',
      });

      if (error) {
        console.error('Error creating payment method for free shop:', error);
        amplitudeTracker('IN APP JOURNEY', `FREE SHOP PAYMENT METHOD ERROR: ${error.message}`);
        return { success: false, errorMessage: error.message };
      }

      if (setupIntent?.status === 'succeeded' && setupIntent.payment_method) {
        setPaymentMethodIdLocal?.(setupIntent.payment_method);
        dispatch(setPaymentMethodId(setupIntent.payment_method));
        setSkipAddonsStep?.(true);
        return { success: true, paymentMethodId: setupIntent.payment_method };
      }

      return { success: false, errorMessage: 'Failed to create payment method.' };
    } catch (error) {
      console.error('Unexpected error in createPaymentMethodForFreeShop:', error);
      return { success: false, errorMessage: 'Unexpected error occurred.' };
    }
  };
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (isAddressComplete && paymentInfoComplete && isFreeShop) {
        try {
          const { success, errorMessage, paymentMethodId } = await createPaymentMethodForFreeShop();

          if (!success) {
            toast(`Failed to create payment method: ${errorMessage}`, { type: 'error' });
            return { success: false };
          }
          if (success) {
            setIsModalOpen(false);
            return { success: true };
          }
        } catch (error) {
          console.error('Error creating payment method for free shop:', error);
          toast('An unexpected error occurred while creating a payment method.', { type: 'error' });
          return { success: false };
        } finally {
          setIsLoading(false);
        }
      }

      if (newSelected && paymentInfoComplete) {
        if (!stripe || !elements) {
          toast.error('Something went wrong');
          return;
        }
        setIsLoading(true);
        const { error, setupIntent }: any = await stripe.confirmSetup({
          elements,
          confirmParams: {
            return_url: `${window.location.origin}`,
          },
          redirect: 'if_required',
        });
        if (error) {
          console.log(error);
          setIsLoading(false);
          toast.error(`${error.message}`);
        } else if (setupIntent && setupIntent?.status === 'succeeded') {
          const url = `/v2/subscription-manager/subscriptions/${subscription.subscription}`;
          const response = await axiosInstance.patch(url, {
            subscribe: [],
            unsubscribe: [],
            paymentMethodId: setupIntent.payment_method,
          });

          if (!isUnpaid) {
            dispatch(getShopFeatures(currentShop.shopId));
            // dispatch(setPaymentMethod(res.paymentMethod as Stripe.PaymentMethod));
            dispatch(setPaymentMethod({ id: setupIntent.payment_method } as Stripe.PaymentMethod));
            dispatch(setPaymentMethodId(setupIntent.payment_method));
            toast.success('Your payment method was updated successfully!!');
            setIsLoading(false);
            setIsModalOpen(false);
            goToPodsView(navigate, dispatch);
          } else if (isUnpaid) {
            await handleUnpaidSubscription(setupIntent.payment_method);
          }
        }
      } else {
        setIsLoading(true);
        const url = `/v2/subscription-manager/subscriptions/${subscription.subscription}`;
        const response = await axiosInstance.patch(url, {
          subscribe: [],
          unsubscribe: [],
          paymentMethodId: selectedPaymentMethod.id,
        });

        if (!isUnpaid) {
          dispatch(setPaymentMethodId(selectedPaymentMethod.id));
          dispatch(setPaymentMethod(selectedPaymentMethod));
          toast.success('Your payment method was successfully updated!!');
          setIsLoading(false);
          setIsModalOpen(false);
          goToPodsView(navigate, dispatch);
        }

        if (isUnpaid) {
          await handleUnpaidSubscription(selectedPaymentMethod.id);
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });

      toast.error(
        `An error occured while trying to update you payment method - ${
          error.message || ' please try again soon or contact Customer Success'
        }`,
      );
    } finally {
      setIsLoading(false);
      dispatch(getShopFeatures(currentShop.shopId));
    }
  };

  const handleUnpaidSubscription = async (newPM) => {
    try {
      const res: any = await chargeOpenInvoice(newPM);
      if (res?.data?.status === 'succeeded') {
        await resetBillingCycle();
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(getShopFeatures(currentShop.shopId));
    }
  };

  const chargeOpenInvoice = async (newPM) => {
    try {
      if (subscription?.payment_intent && newPM) {
        const url = `/v2/subscription-manager/invoices/recharge-open`;
        const res = await axiosInstance.post(url, {
          paymentIntentId: subscription.payment_intent,
          paymentMethodId: newPM,
        });
        return res;
      } else
        toast.error('We are missing data in order to complete the process. Please contact us.');
    } catch (err) {
      toast.error(`Something went wrong - ${err?.data?.message}`);
      setIsLoading(false);
      return { err };
    }
  };

  const resetBillingCycle = async () => {
    try {
      await axiosInstance.post(
        `/v2/subscription-manager/subscriptions/reset-billing-cycle/${subscription.subscription}`,
      );
      dispatch(setPaymentMethodId(selectedPaymentMethod.id));
      dispatch(setPaymentMethod(selectedPaymentMethod));
      toast.success('Your payment method was successfully updated!!');
      setIsModalOpen(false);
      goToPodsView(navigate, dispatch);
    } catch (err) {}
  };

  const selectPM = (value) => {
    setSelectedPaymentMethod(value);
  };

  const disabled = useMemo(() => {
    if (isLoading) return true;
    if (isFreeShop && !isAddressComplete) return true;
    if (!newSelected) {
      if (!selectedPaymentMethod) return true;
    }
    if (newSelected && !paymentInfoComplete) {
      return true;
    }
    return false;
  }, [
    isLoading,
    isFreeShop,
    isAddressComplete,
    newSelected,
    paymentInfoComplete,
    selectedPaymentMethod,
  ]);

  const handleAddressChange = async (event) => {
    if (event.complete) {
      const address = event.value?.address || {};
      setAddress?.({
        postal_code: address.postal_code || '',
        country: address.country || '',
        city: address.city || '',
        line1: address.line1 || '',
        line2: address.line2 || '',
      });
      setIsAddressComplete(true);
    } else {
      setIsAddressComplete(false);
    }
  };

  return (
    <Modal
      title={
        isFreeShop ? (
          <>
            <div className={'title'}>Add Payment Method</div>
            <div className={'subTitle'}>
              Add a payment method to be used for this store’s subscription.
            </div>
          </>
        ) : (
          <>
            <div className={'title'}>Change Payment Method</div>
            <div className={'subTitle'}>
              Update the payment method with which you are paying for this store’s subscription.
              Invoices will continue to be sent to the billing user on file.
            </div>
          </>
        )
      }
      open={isOpen}
      onClose={() => {
        setIsModalOpen(false);
      }}
    >
      <Modal.Section>
        <div className={'modal-update-con'}>
          {!isLoadingPage && (
            <>
              <div className="payment-details-content-settings">
                {((!isFreeShop && customerPaymentsMethod.length > 0) ||
                  (isFreeShop && paymentMethodId && customerPaymentsMethod)) && (
                  <div>
                    <div className={'check-box-margin avb-pm-chk'}>
                      <Checkbox
                        checked={!newSelected}
                        onChange={() => setNewSelected(false)}
                        label={
                          <div className="txt-tooltip">
                            Available payment methods:{' '}
                            <Tooltip
                              content={
                                'Payment methods listed are those associated with that billing user'
                              }
                            >
                              {' '}
                              <Icon source={InfoMinor} color="subdued"></Icon>
                            </Tooltip>
                          </div>
                        }
                      />
                    </div>
                    {!newSelected && (
                      <PMSelectSettings
                        isSearchable={false}
                        getOptionLabel={() => ''}
                        options={customerPaymentsMethod}
                        value={selectedPaymentMethod}
                        onChange={selectPM}
                        components={{ Option: IconOption, SingleValue: customSingleValue }}
                      />
                    )}

                    <div className={'check-box-margin-settings add-pm'}>
                      <Checkbox
                        checked={newSelected}
                        onChange={() => setNewSelected(true)}
                        label={'Add new payment method:'}
                      />
                    </div>
                  </div>
                )}
                {((customerPaymentsMethod.length > 0 && newSelected) ||
                  customerPaymentsMethod.length === 0 ||
                  (isFreeShop && !paymentMethodId)) &&
                  newSelected && (
                    <div>
                      <PaymentElement onChange={stripeElementChange} />
                      <AddressElement
                        options={{
                          mode: 'billing',
                          fields: {
                            phone: 'never',
                          },
                          autocomplete: {
                            mode: 'google_maps_api',
                            apiKey: 'your_google_maps_api_key',
                          },
                          defaultValues: {
                            address: {
                              postal_code: '',
                              country: '',
                            },
                          },
                        }}
                        onChange={handleAddressChange}
                      />
                    </div>
                  )}
              </div>
              <div>
                <div
                  className={`flex-container center rounded-button bg-btn-blue ${
                    isLoading && 'loading-settings'
                  }`}
                >
                  <Button onClick={handleSubmit} disabled={disabled}>
                    {isLoading ? 'Submitting...' : 'Submit'}
                  </Button>
                </div>
                <div className={'flex-container middle center stripe-icon-settings'}>
                  <div className={'pow-by-settings'}>{'Powered by'}</div>
                  <img
                    onClick={() => window.open('https://stripe.com', '_blank')}
                    alt={''}
                    src={'/signupJourney/stripe_icon2.svg'}
                  />
                </div>
              </div>
            </>
          )}
          {isLoadingPage && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
              }}
            >
              <Spinner accessibilityLabel="App is loading" size="large" />
            </div>
          )}
        </div>
      </Modal.Section>
    </Modal>
  );
};

export default ChangePaymentMethodModal;
