import { Icon, ResourceItem, ResourceList, TextStyle } from '@shopify/polaris';
import { ExternalSmallMinor } from '@shopify/polaris-icons';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { useFeatureFlag } from 'feature-flag-system';

export default function PixelSubscription({ subscription }) {
  const { shouldNotBeSeen: cantSeeLinks } = useFeatureFlag(FeatureFlag.PIXEL_PURCHASE_POPUP_FF);
  if (!subscription) return null;
  return (
    <div>
      <div>
        {
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.625rem',
              alignItems: 'flex-start',
            }}
          >
            {
              (
                <TextStyle variation="strong">
                  Subscription ID: {subscription.subscriptionId}
                </TextStyle>
              ) as any
            }
            {(<TextStyle variation="strong">MRR: {subscription.mrr}</TextStyle>) as any}
            {(<TextStyle variation="strong">ARR: {subscription.arr}</TextStyle>) as any}
          </div>
        }
      </div>
      <ResourceList
        resourceName={{ singular: 'product', plural: 'products' }}
        items={
          subscription.subscriptionItems?.map((item) => ({
            id: item.subscription_item_id,
            productName: item.product_id,
            variantName: item.variant_id,
            price: item.price,
            quantity: item.quantity,
            interval: item.interval,
            interval_count: item.interval_count,
            discount: item.discount_percent_off
              ? `${item.discount_percent_off}%`
              : item.discount_amount_off || 0,
          })) || []
        }
        renderItem={(item: any) => {
          const { id, price, quantity, variantName, productName, interval, interval_count } = item;
          return (
            <ResourceItem
              verticalAlignment="center"
              id={id}
              accessibilityLabel={`View details for ${id}`}
              name={id}
              onClick={() => {}}
            >
              <h3>
                <TextStyle variation="strong">
                  {productName} {variantName && `(${variantName})`}
                </TextStyle>
              </h3>
              <div>Price: {price}</div>
              <div>Quantity: {quantity}</div>
              <div>Discount: {item.discount}</div>
              <div>
                Frequency: {interval_count} {interval}
              </div>
            </ResourceItem>
          );
        }}
      />
    </div>
  );
}
