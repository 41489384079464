import React, { useState, useMemo, useCallback, ReactNode, Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { ViewMajor } from '@shopify/polaris-icons';
import { v4 as uuidv4 } from 'uuid';
import {
  WillyParameter,
  WidgetQuery,
  MessageTypes,
  NlqResponse,
  WillyMetric,
  ChatSources,
  GridOptions,
  GridColumnOptions,
  AxisDomain,
  WidgetPermissions,
  WillyDashboardElement,
  CodeInterpreterResponse,
  NlqResponseDataType,
  CodeExecutionResponse,
  Dialect,
  WillyWidgetElement,
  WorkflowStep,
  WillyChartLayout,
  WillySort,
  WillyToolName,
  ToolResult,
  WorkflowStepToolPreload,
} from '../types/willyTypes';
import { services, ServicesIds } from '@tw/types/module/services';
import allServices from 'constants/services';
import { PinMessage } from '../PinMessage';
import { WillyQuestionAndSql } from '../WillyQuestionAndSql';
import { Button as ShopifyButton, Stack, ButtonGroup, TextField } from '@shopify/polaris';
import ReactSwitch from 'react-switch';
import {
  Checkbox,
  Loader,
  Modal,
  Text,
  Flex,
  MultiSelect,
  Icon,
  Tooltip,
  Menu,
  FormattedColor,
  ActionIcon,
  Button,
  Switch,
  Badge,
} from '@tw/ui-components';
import CSVExport from 'utils/CSVExport';
import moment from 'moment-timezone';
import _db, { firestoreRef } from 'utils/DB';
import { useSortable } from '@dnd-kit/sortable';
import { ReactComponent as DragHandle } from '../../../icons/drag-handle.svg';
import { useDashHistory } from '../hooks/useDashHistory';
import { unionBy } from 'lodash';
import { useInWillyConversationContext } from '../hooks/useInWillyConversationContext';
import { $tables } from '$stores/willy/$tables';
import { useSelector } from 'react-redux';
import {
  deleteWidgetFromDashboard,
  executeCustomQuery,
  filterHasValue,
  generateColorScale,
  getCurrentAnalyticsActionSet,
  getDataLayerEventByContext,
  getMainElementDbRef,
  getToolPreloadFromToolResult,
} from '../utils/willyUtils';
import { $isAdminClaim, $isTwGlobalDashboardCreatorClaim } from '$stores/$user';
import {
  genericEventLogger,
  analyticsEvents,
  chatActions,
  dashboardsActions,
  sqwhaleActions,
} from 'utils/dataLayer';
import { Segment, SegmentType } from '@tw/types/module/services/cdp';
import { buildWhaleGPTSegmentQuery } from 'components/CDP/QueryBuilder/utils';
import SyncModal from 'components/CDP/SegmentsList/SyncModal';
import { getSegmentDataView } from 'components/CDP/SegmentsList/utils';
import { useAvailableTypes } from '../hooks/useAvailableTypes';
import { WidgetVisualizationPopover } from '../widgetVisualizationTypes/WidgetVisualizationPopover';
import { RootState } from 'reducers/RootType';
import { toast } from 'react-toastify';
import axiosInstance from 'utils/axiosInstance';
import { ColorPickerPopover } from '../ColorPicker';
import { useFeatureFlag } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { WillyCodeSnippet } from '../WillyCodeSnippet';
import { formatNumber } from 'utils/formatNumber';
import { ColumnsSelector } from './ColumnsSelector';
import { DEFAULT_DIALECT } from '../constants';
import { refreshDashboardWidgets, useDashContext } from '../dashContext';
import { useStoreValue } from '@tw/snipestate';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { MaxSectionsReachedTooltip } from '../dashboardManagment/WillyEditDashboardMenu';
import { $activeAccounts, $timezone } from '$stores/$shop';
import { $currentDateRange, $granularity } from '$stores/willy/$dateRange';
import { setCDPSegmentsFromServer } from 'ducks/cdp/segments';
import { useAppDispatch } from 'index';
import { setSequenceSteps } from '$stores/willy/$seqBuilder';
import { WidgetMetaData } from './WidgetMetaData';
import { WidgetInsights } from './WidgetInsights';

type MenuItem = {
  content: string | JSX.Element;
  onClick: () => void;
  active?: boolean;
  disabled?: boolean;
  helpText?: string;
  leftSection?: React.ReactNode;
  color?: FormattedColor | string;
  loading?: boolean;
  error?: string;
  hidden?: boolean;
  notCloseMenuOnClick?: boolean;
  onDisabledWrapper?: React.FC<{ target: ReactNode }>;
};

type Section = { items: MenuItem[] };

type WillyChartHeaderProps = {
  type: MessageTypes;
  withoutMainQuery?: boolean;
  metrics: WillyMetric[];
  queries?: WidgetQuery[];
  stacked: boolean;
  stackedChanged: (v: boolean) => void;
  permission: WidgetPermissions;
  incrementedStacked: boolean;
  loading?: boolean;
  rawData: NlqResponse;
  title: string;
  queryId?: string;
  dashboard?: WillyDashboardElement;
  isGlobalDashboard?: boolean;
  isCustomView?: boolean;
  serviceIds?: ServicesIds[];
  parameters?: WillyParameter[];
  isDynamic?: boolean;
  wrapText?: boolean;
  context: ChatSources;
  currency?: string;
  titleChanged: (v: string) => void;
  queriesChanged: (queries: WidgetQuery[]) => Promise<void>;
  typeChanged: (v: MessageTypes) => void;
  permissionChanged: (v: ServicesIds[]) => void;
  incrementedStackedChanged: (v: boolean) => void;
  metricsChanged: (id: string, v: WillyMetric[]) => Promise<void>;
  twoColumnMobile?: boolean;
  setTwoColumnMobile?: (v: boolean) => void;
  grid?: GridOptions;
  setGrid?: (v: GridOptions) => void;
  gridColumns?: GridColumnOptions;
  setGridColumns?: (v: 2 | 3 | 4) => void;
  skinny?: boolean;
  setSkinny?: (v: boolean) => void;
  yAxisDomain: AxisDomain;
  setYAxisDomain: (v: AxisDomain) => void;
  allowDataOverflow?: boolean;
  setAllowDataOverflow: (v: boolean) => void;
  dimension: string;
  secondaryToolbarOpen: boolean;
  pinnedSection?: boolean;
  setHidePinnedSection?: (v: boolean) => void;
  errorInQuery: Record<string, string>;
  warningInQuery: Record<string, string>;
  widgetMainResource: 'customers' | 'unknown';
  CDPSegmentId?: string;
  hasGlobalConditionalFormatting: boolean;
  setHasGlobalConditionalFormatting: (v: boolean) => void;
  globalConditionalFormattingColor: string;
  setGlobalConditionalFormattingColor: (v: string) => void;
  breakdownMode?: boolean;
  isDnd?: boolean;
  editLayout?: boolean;
  setWidgetEditorOpen: (boolean) => void;
  inWidgetEditor?: boolean;
  codeResult?: CodeInterpreterResponse;
  codeInterpreterInputData?: string;
  codeResultChanged?: (v: CodeExecutionResponse) => Promise<void>;
  dataType?: NlqResponseDataType;
  historicalQueryIds?: string[];
  dialect: Dialect;
  contextSourceIds?: { dashboardId: string; widgetId: string; onAdd?: () => void };
  showQuickEditing?: boolean;
  setShowQuickEditing?: (v: boolean) => void;
  mode?: WillyWidgetElement['mode'];
  showFilters: boolean;
  setShowFilters: (v: boolean) => void;
  inMobileDrawer?: boolean;
  chartLayout?: WillyChartLayout;
  setChartLayout?: (layout: WillyChartLayout) => void;
  orderBy?: { column: string; sort: WillySort };
  onResizeReorder?: () => void;
  customFiltersAvailable?: boolean;
  appliedCustomFilters?: number;
};

const emptyTitle = 'Click to set title';
const minmax = ['min', 'max'] as const;

export const WillyWidgetHeader: React.FC<WillyChartHeaderProps> = ({
  queryId,
  withoutMainQuery,
  dashboard,
  isGlobalDashboard,
  isCustomView,
  loading,
  type,
  currency,
  metrics,
  queries,
  rawData,
  stacked,
  stackedChanged,
  permission,
  permissionChanged,
  incrementedStacked,
  title,
  parameters,
  isDynamic,
  serviceIds,
  wrapText,
  context,
  titleChanged,
  queriesChanged,
  typeChanged,
  incrementedStackedChanged,
  metricsChanged,
  twoColumnMobile,
  setTwoColumnMobile,
  grid,
  setGrid,
  gridColumns,
  setGridColumns,
  skinny,
  setSkinny,
  yAxisDomain,
  setYAxisDomain,
  allowDataOverflow,
  setAllowDataOverflow,
  dimension,
  secondaryToolbarOpen,
  pinnedSection,
  setHidePinnedSection,
  errorInQuery,
  warningInQuery,
  widgetMainResource,
  CDPSegmentId,
  hasGlobalConditionalFormatting,
  setHasGlobalConditionalFormatting,
  globalConditionalFormattingColor,
  setGlobalConditionalFormattingColor,
  breakdownMode,
  isDnd,
  editLayout,
  setWidgetEditorOpen,
  inWidgetEditor,
  codeResult,
  codeInterpreterInputData,
  codeResultChanged,
  dataType,
  historicalQueryIds,
  dialect,
  contextSourceIds,
  showQuickEditing,
  setShowQuickEditing,
  mode,
  showFilters,
  setShowFilters,
  inMobileDrawer,
  chartLayout,
  setChartLayout,
  orderBy,
  onResizeReorder,
  customFiltersAvailable,
  appliedCustomFilters,
}) => {
  const { $widgetsLeft } = useDashContext();
  const canMakeMoreWidgets = !!useStoreValue($widgetsLeft);
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const { updateHistory } = useDashHistory();
  const conversationIdFromRoute = useInWillyConversationContext();
  const [showQuestion, setShowQuestion] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const [layoutSettingsOpen, setLayoutSettingsOpen] = useState(false);
  const [childDropdownsActive, setChildDropdownsActive] = useState<any[]>([]);
  const [tilesSettingsOpen, setTilesSettingsOpen] = useState(false);
  const [widgetSettingsOpen, setWidgetSettingsOpen] = useState(false);
  const [yAxisDomainModalOpen, setYAxisDomainModalOpen] = useState(false);
  const [conditionalFormattingOpen, setConditionalFormattingOpen] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [exportError, setExportError] = useState<string | null>(null);
  const [cdpSyncModalOpen, setCdpSyncModalOpen] = useState<boolean>(false);
  const { segmentsList, isSegmentsListInitialized } = useSelector(
    (state: RootState) => state.CDPSegments,
  );
  const user = useSelector((state: RootState) => state.user);
  const userEmail = useSelector((state: RootState) => state.userEmail);
  const googleSheetsAccounts = useSelector((state: RootState) => state.googleSheetsAccounts);
  const { shouldNotBeSeen: isCVSExportBlocked } = useFeatureFlag(FeatureFlag.CSV_EXPORTING_FF);
  const { shouldNotBeSeen: isSQLBLocked } = useFeatureFlag(FeatureFlag.SQL_FF);
  // const { shouldNotBeSeen: isChatBlocked } = useFeatureFlag(FeatureFlag.CHAT_FF);
  const isTwGlobalDashboardCreatorClaim = useStoreValue($isTwGlobalDashboardCreatorClaim);
  const isAdmin = useStoreValue($isAdminClaim);
  const [menuOpened, setMenuOpened] = useState(false);
  const [duplicating, setDuplicating] = useState(false);
  const allTables = useStoreValue($tables);
  const activeAccounts = useStoreValue($activeAccounts);
  const timezone = useStoreValue($timezone);
  const granularity = useStoreValue($granularity);
  const currentDateRange = useStoreValue($currentDateRange);
  const availableTypes = useAvailableTypes(rawData?.data!, metrics);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const dlEvent = useMemo(() => {
    return context === 'chat' || context === 'sequence'
      ? analyticsEvents.CHAT
      : context === 'editor'
        ? analyticsEvents.SQWHALE
        : analyticsEvents.DASHBOARDS;
  }, [context]);

  const currentAnalyticsActionSet = useMemo(() => {
    return context === 'chat' || context === 'sequence'
      ? chatActions
      : context === 'editor'
        ? sqwhaleActions
        : dashboardsActions;
  }, [context]);

  const isSmall = useIsSmall();

  const { attributes, listeners } = useSortable({
    id: queryId || '',
  });

  const allQueries = useMemo(() => {
    const savedQueries = queries || []; // from firestore
    const executedQueries = rawData?.queries || []; // from Moshe in loadSavedQuery

    const mergedData = savedQueries.map((item) => {
      const newItem = executedQueries.find((x) => x.id === item.id);
      return newItem ? { ...item, query: newItem.query } : item;
    });

    // Add items from newData that are not in savedData
    const result = unionBy(mergedData, executedQueries, 'id');

    return result;
  }, [queries, rawData?.queries]);

  useEffect(() => {
    if (!isSegmentsListInitialized) {
      dispatch(setCDPSegmentsFromServer());
    }
  }, [dispatch, isSegmentsListInitialized]);

  const CDPSegment: Segment | undefined = useMemo(() => {
    if (CDPSegmentId) {
      return segmentsList.find((s) => s.id === CDPSegmentId);
    }

    const query = queries?.[0];
    if (!query) return;

    const segmentQuestion = query.question;
    const segmentQuery = query.query;
    const queryId = query.id;
    const segmentTitle = title?.replace(/^"|"$/g, '');

    return {
      id: '',
      createdAt: new Date(),
      updatedAt: new Date(),
      name: segmentTitle || segmentQuestion,
      description: segmentQuestion,
      shopDomain: currentShopId,
      query: buildWhaleGPTSegmentQuery(queryId, segmentQuestion, segmentQuery),
      type: SegmentType.WHALE_GPT,
    } as Segment;
  }, [CDPSegmentId, queries, title, currentShopId, segmentsList]);

  const createCDPSync = useCallback(() => {
    setCdpSyncModalOpen(true);
  }, []);

  const canEdit = useMemo(() => {
    if (isSmall && !inMobileDrawer) {
      return false;
    }
    if (!dashboard?.id) {
      return true;
    }

    return !!dashboard?.canEdit;
  }, [dashboard?.canEdit, dashboard?.id, isSmall, inMobileDrawer]);

  const hideForCannotEditInTemplate = useMemo(() => {
    return !canEdit && dashboard?.isGlobal;
  }, [canEdit, dashboard]);

  const removeWidgetFromDashboard = useCallback(async () => {
    if (!dashboard || !queryId) return;

    await deleteWidgetFromDashboard(dashboard, queryId);

    if (userEmail && user?.uid) {
      updateHistory('widget_removed', {
        widgetId: queryId,
        title,
      });
    }
  }, [dashboard, queryId, title, updateHistory, user?.uid, userEmail]);

  const createWorkflowFromWidget = useCallback(
    async (addGSStep?: boolean) => {
      if (!queryId) {
        return;
      }

      const foundQuestion = queries?.find((q) => q.question && q.question.length > 0)?.question;
      const foundQuery = queries?.find((q) => q.query && q.query.length > 0)?.query;
      let toolToUse: WillyToolName | undefined = undefined;
      if (codeResult) {
        toolToUse = 'TextToPython';
      } else if (rawData?.dataType === 'forecast') {
        toolToUse = 'Forecasting';
      } else if (rawData?.dataType === 'mmm') {
        toolToUse = 'MarketingMixModel';
      } else {
        toolToUse = 'TextToSQL';
      }

      let toolPreload: WorkflowStepToolPreload | undefined = undefined;
      if (toolToUse === 'TextToPython') {
        toolPreload = getToolPreloadFromToolResult({
          name: toolToUse,
          ...codeResult,
        });
      } else if (toolToUse === 'TextToSQL') {
        toolPreload = getToolPreloadFromToolResult({
          name: toolToUse,
          errorMessages: !!rawData?.error ? [rawData.error] : undefined,
          nlqResponse: rawData,
        });
      }

      let steps: WorkflowStep[] = [
        foundQuery
          ? {
              createdAt: new Date().toISOString(),
              id: uuidv4(),
              stepType: 'runQuery',
              query: foundQuery ?? '',
              queryParams: parameters
                ?.filter((p) => p.isQueryParameter)
                .reduce(
                  (acc, param) => {
                    acc[param.column] = Array.isArray(param.value)
                      ? param.value.join(',')
                      : param.value;
                    return acc;
                  },
                  {} as Record<string, string>,
                ),
            }
          : {
              createdAt: new Date().toISOString(),
              id: uuidv4(),
              stepType: 'tool',
              text: foundQuestion ?? '',
              toolToUse,
              preserveHistory: true,
              toolPreload,
              variables: parameters
                ?.filter((p) => p.isQueryParameter)
                .map((x) => {
                  return {
                    key: x.column,
                    value: Array.isArray(x.value) ? x.value.join(',') : x.value,
                  };
                }),
            },
      ];

      if (addGSStep)
        steps.push({
          id: uuidv4(),
          createdAt: new Date().toISOString(),
          stepType: 'sendToGoogleSheet',
          spreadsheetId: '',
          spreadsheetName: '',
          ssNameError: false,
          worksheetId: '',
          worksheetName: '',
          wsNameError: false,
          sheetsAccount: '',
        });

      setSequenceSteps({ steps });

      navigate({
        pathname: `/workflows/create`,
        search: window.location.search,
      });
    },
    [navigate, parameters, queries, queryId, codeResult, rawData],
  );

  const menuOptions = useMemo<Section[]>(() => {
    const editSection: Section = {
      items: [
        {
          hidden: !canEdit || loading,
          content: 'Edit Section',
          onClick: () => {
            setWidgetEditorOpen(true);
          },
          leftSection: <Icon name="edit" size={14} />,
        },
        {
          hidden: !onResizeReorder,
          content: 'Adjust layout',
          onClick: () => onResizeReorder?.(),
          leftSection: <Icon name="adjust-layout" size={14} />,
        },
        {
          hidden: !canEdit || type !== 'chart',
          content: (
            <Flex align="center" gap="xs">
              <Text size="sm" weight={500}>
                Show Quick Editing
              </Text>
              <Switch
                checked={showQuickEditing}
                onChange={() => setShowQuickEditing?.(!showQuickEditing)}
                size="sm"
              />
            </Flex>
          ),
          onClick: () => {},
          leftSection: <Icon name="flash" size={14} />,
        },
      ],
    };
    const underTheHoodSection: Section = {
      items: [
        {
          hidden: !allQueries.length || context === 'editor' || !queryId,
          content: 'View Query',
          onClick: () => {
            setShowQuestion(true);
          },
          leftSection: <Icon name={isSQLBLocked ? 'lock' : 'sql'} size={14} />,
          disabled: isSQLBLocked && !isAdmin,
        },
        {
          hidden: !codeResult,
          content: 'View Code Interpreter Result',
          onClick: () => {
            setShowCode((x) => !x);
          },
          leftSection: <Icon name={'python'} size={14} />,
        },
        {
          hidden: !$isTwGlobalDashboardCreatorClaim.get(),
          content: 'Widget Meta Data',
          onClick: () => {
            setWidgetSettingsOpen((x) => !x);
          },
          leftSection: <Icon name="meta-data" size={14} />,
        },
      ],
    };

    const dataDisplaySection: Section = {
      items: [
        {
          hidden: type !== 'chart' || !canEdit || !stacked,
          content: 'Incremented stack',
          helpText: 'Stacked bars will be incremented by the previous bar',
          onClick: () => {
            incrementedStackedChanged(!incrementedStacked);
          },
          active: !!incrementedStacked,
          disabled: !stacked,
        },
        {
          hidden: type !== 'funnel',
          content: 'Skinny bars',
          helpText: 'force the bars in the chart to be skinny',
          onClick: () => {
            setSkinny?.(!skinny);
          },
          active: !!skinny,
        },
      ],
    };
    const reportActionsSection: Section = {
      items: [
        {
          hidden: !canEdit || !dashboard,
          content: 'Duplicate',
          loading: !!duplicating,
          onClick: async () => {
            try {
              setDuplicating(true);
              const { data } = await axiosInstance.post(`/v2/willy/duplicate-widget`, {
                dashboardId: dashboard?.id,
                queryId: queryId,
                shopId: currentShopId,
                isCustomView,
                isGlobal: isGlobalDashboard,
                mode,
              });
              await refreshDashboardWidgets();
            } catch (e) {
              console.log(e);
              toast.error('Something went wrong');
            } finally {
              setDuplicating(false);
              genericEventLogger(getDataLayerEventByContext(context), {
                action: getCurrentAnalyticsActionSet(context).PIN_RESULT,
                modifier: 'duplicate',
                widget_type: type,
                widget_id: queryId,
                widget_name: title,
                dashboard_id: dashboard?.id,
                dashboard_name: dashboard?.name,
                conversationId: conversationIdFromRoute,
                template_id: dashboard?.globalDashboardId,
                template_name: dashboard?.globalDashboardId && dashboard?.name,
                source: 'duplicated-from-same-report',
              });
            }
          },
          leftSection: <Icon name="duplicate" size={14} />,
          disabled: !canMakeMoreWidgets,
          onDisabledWrapper: ({ target }) => (
            <MaxSectionsReachedTooltip>{target}</MaxSectionsReachedTooltip>
          ),
        },
        {
          content: (
            <PinMessage
              willyMessageType={type}
              queryId={queryId}
              type={type}
              title={title}
              metrics={metrics}
              queries={queries}
              stacked={stacked}
              incrementedStacked={incrementedStacked}
              parameters={parameters || []}
              wrapText={wrapText}
              dashboardId={dashboard?.id}
              grid={grid}
              gridColumns={gridColumns}
              twoColumnMobile={twoColumnMobile}
              disabled={loading}
              dimension={dimension}
              allowDataOverflow={allowDataOverflow}
              yAxisDomain={yAxisDomain}
              breakdownMode={breakdownMode}
              withoutMainQuery={withoutMainQuery}
              codeResult={codeResult}
              context={context}
              dialect={dialect}
              activator={
                <Flex w="100%" align="center" justify="space-between">
                  <Text size="sm" weight={500}>
                    Copy to Dashboard
                  </Text>
                  <div className={`flex transition-transform `}>
                    <Icon name="chevron-right-minor" size={10} />
                  </div>
                </Flex>
              }
              onClose={() => setMenuOpened(false)}
              withinPortal={false}
              hasGlobalConditionalFormatting={hasGlobalConditionalFormatting}
              globalConditionalFormattingColor={globalConditionalFormattingColor}
              dataType={dataType}
              chartLayout={chartLayout}
            />
          ),
          notCloseMenuOnClick: true,
          onClick: () => {},
          leftSection: <Icon name="plus-square" size={14} />,
        },
        {
          content: 'Export',
          hidden: !!codeResult,
          onClick: async () => {
            if (!queryId) {
              setExportError('Query not found');
              return;
            }

            if (!rawData) {
              return;
            }
            const { generatedQuery } = rawData;
            if (!generatedQuery) {
              setExportError('Query not found');
              return;
            }

            setExporting(true);
            setExportError(null);
            const result = await executeCustomQuery({
              query: generatedQuery,
              activeAccounts: activeAccounts || [],
              currency: currency || 'USD',
              dialect,
              granularity,
              shopId: currentShopId,
              pageSize: Number.MAX_SAFE_INTEGER,
              timezone: timezone || 'UTC',
              orderBy: orderBy?.column,
              orderDirection: orderBy?.sort || undefined,
              queryParams: parameters?.reduce(
                (acc, param) => {
                  if (param.column === 'start_date' && isDynamic && currentDateRange) {
                    acc[param.column] = currentDateRange.start.format('YYYY-MM-DD');
                  } else if (param.column === 'end_date' && isDynamic && currentDateRange) {
                    acc[param.column] = currentDateRange.end.format('YYYY-MM-DD');
                  } else {
                    acc[param.column] = Array.isArray(param.value)
                      ? param.value.join(',')
                      : param.value;
                  }
                  return acc;
                },
                {} as Record<string, string>,
              ),
            });

            let { data, dataColumns, error, errorForInterface } = result;
            if (error || errorForInterface) {
              setExporting(false);
              setExportError(error || errorForInterface || 'Something went wrong');
              return;
            }

            const xIndex = dataColumns?.x?.[0] || dataColumns?.y[0] || 'date';
            const xData = data.find((x) => x.name === xIndex)?.value || [];
            const tableData = xData.map((date, i) => {
              const row: any = {};
              if (xIndex === 'date') {
                const firstXDate = moment(xData[0]);
                const isOneDay = xData.every((x) => moment(x).isSame(firstXDate, 'day'));
                row.date = moment(date).format(isOneDay ? 'LTS' : 'LL');
              } else {
                row[xIndex] = xData[i];
              }
              data
                ?.filter((x) => x.name !== xIndex)
                .forEach((x) => {
                  const metric = metrics.find((m) => m.key === x.name);
                  if (metric?.isDate) {
                    const noDateFormat = metric.dateFormat === 'none';
                    row[x.name] = noDateFormat
                      ? x.value[i]?.toString()
                      : moment(x.value[i]).format(metric?.dateFormat);
                  }
                  row[x.name] =
                    typeof x.value[i] === 'object'
                      ? JSON.stringify(x.value[i])
                      : typeof x.value[i] === 'number'
                        ? formatNumber(+x.value[i], {
                            style: metric?.format || 'decimal',
                            currency,
                            minimumFractionDigits: metric?.minimumFractionDigits ?? 0,
                            maximumFractionDigits: metric?.toFixed ?? 2,
                          })
                        : x.value[i];
                });
              return row;
            });
            const exportTitle = title || `Export from ${moment().format('L')}`;
            CSVExport(tableData, exportTitle);
            genericEventLogger(getDataLayerEventByContext(context), {
              action: getCurrentAnalyticsActionSet(context).EXPORT_CSV,
              dashboard_name: dashboard?.name,
              dashboard_id: dashboard?.id,
              template_id: dashboard?.id,
              template_name: dashboard?.id && dashboard?.name,
              widget_id: queryId,
              widget_title: exportTitle,
            });

            setExporting(false);
          },
          disabled: isCVSExportBlocked || exporting,
          leftSection: <Icon name={isCVSExportBlocked ? 'lock' : 'export'} size={14} />,
          loading: exporting,
          error: exportError || undefined,
        },
        // {
        //   content: 'Sync as SCDP segment',
        //   hidden: widgetMainResource !== 'customers' || queries?.length !== 1,
        //   onClick: createCDPSync,
        // },
      ],
    };

    const workflowActions: Section = {
      items: [
        {
          content: 'Create agent',
          leftSection: <Icon name="play-arrow" size={14} />,
          hidden: queries?.length !== 1,
          onClick: () => createWorkflowFromWidget(),
        },
        ...(googleSheetsAccounts?.length > 0 && googleSheetsAccounts.find((x) => !x.disconnected)
          ? [
              {
                content: 'Create agent to Google Sheet',
                leftSection: <Icon name="play-arrow" size={14} />,
                hidden: queries?.length !== 1,
                onClick: () => createWorkflowFromWidget(true),
              },
            ]
          : []),
      ],
    };

    const destructiveReportActionSection: Section = {
      items: [
        {
          content: 'Delete from Dashboard',
          hidden: !canEdit || !dashboard,
          color: 'red.6',
          onClick: async () => {
            await removeWidgetFromDashboard();
            toast.success('Section removed from dashboard');
            genericEventLogger(getDataLayerEventByContext(context), {
              action: getCurrentAnalyticsActionSet(context).REMOVE_WIDGET,
              dashboardId: dashboard?.id,
              dashboard_name: dashboard?.name,
              dashboard_id: dashboard?.id,
              template_id: dashboard?.globalDashboardId,
              template_name: dashboard?.globalDashboardId && dashboard?.name,
              widgetId: queryId,
              title,
              type,
            });
          },
          leftSection: <Icon name="delete" color="red.6" size={20} />,
        },
      ],
    };
    const sections: Section[] = inMobileDrawer
      ? [{ ...editSection }, { ...reportActionsSection }, { ...destructiveReportActionSection }]
      : [
          { ...editSection },
          { ...underTheHoodSection },
          { ...dataDisplaySection },
          { ...reportActionsSection },
          { ...workflowActions },
          { ...destructiveReportActionSection },
        ];

    return (
      sections
        .map((s) => ({
          ...s,
          items: s.items.filter((i) => !i.hidden),
        }))
        // finally only take the items that are supposed to be visible
        .filter((s) => s.items.length > 0)
    );
  }, [
    canMakeMoreWidgets,
    canEdit,
    inMobileDrawer,
    loading,
    type,
    showQuickEditing,
    allQueries.length,
    queryId,
    isSQLBLocked,
    isAdmin,
    codeResult,
    withoutMainQuery,
    stacked,
    incrementedStacked,
    skinny,
    dashboard,
    context,
    title,
    metrics,
    queries,
    parameters,
    wrapText,
    grid,
    gridColumns,
    twoColumnMobile,
    dimension,
    allowDataOverflow,
    yAxisDomain,
    breakdownMode,
    dialect,
    hasGlobalConditionalFormatting,
    globalConditionalFormattingColor,
    dataType,
    isCVSExportBlocked,
    setWidgetEditorOpen,
    setShowQuickEditing,
    incrementedStackedChanged,
    setSkinny,
    currentShopId,
    isCustomView,
    conversationIdFromRoute,
    rawData,
    currency,
    removeWidgetFromDashboard,
    duplicating,
    mode,
    activeAccounts,
    timezone,
    exporting,
    exportError,
    isDynamic,
    currentDateRange,
    granularity,
    createWorkflowFromWidget,
    googleSheetsAccounts,
    isGlobalDashboard,
    chartLayout,
    orderBy,
    onResizeReorder,
  ]);

  const updateHistoryItem = useCallback(
    async (newHistoryProps) => {
      const conversation = await _db()
        .collection('conversations')
        .doc(conversationIdFromRoute)
        .get();
      const conversationData = conversation.data();
      const conversationHistory = conversationData?.history || [];
      const historyItem = conversationHistory?.find((x) =>
        x.data?.some((d) => d.queryId === queryId),
      );

      const newHistoryItem = {
        ...historyItem,
        data: historyItem?.data?.map((d) => {
          if (d.queryId === queryId) {
            return {
              ...d,
              ...newHistoryProps,
            };
          }
          return d;
        }),
      };

      const newConversationHistory = conversationHistory.map((x) => {
        if (x.messageId === newHistoryItem.messageId) {
          return newHistoryItem;
        }
        return x;
      });

      await _db().collection('conversations').doc(conversationIdFromRoute).set(
        {
          history: newConversationHistory,
        },
        {
          merge: true,
        },
      );
    },
    [conversationIdFromRoute, queryId],
  );

  const exampleScale = useMemo(() => {
    const exampleScale = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    const colors = generateColorScale(globalConditionalFormattingColor, exampleScale);
    return Object.values(colors);
  }, [globalConditionalFormattingColor]);

  return (
    <div key={queryId} className="flex items-center gap-4 sm:flex-nowrap h-16">
      {loading && (
        <div className="flex items-center flex-shrink-0">
          <Loader size="xs" />
        </div>
      )}
      {!loading && !!queryId && !!errorInQuery[queryId] && (
        <div className="flex items-center flex-shrink-0">
          <Tooltip label={errorInQuery[queryId]}>
            <Icon name="info" color="red.9" />
          </Tooltip>
        </div>
      )}
      {!loading && !!queryId && !!warningInQuery?.[queryId] && (
        <div className="flex items-center flex-shrink-0">
          <Tooltip label={warningInQuery[queryId]}>
            <Icon name="info" color="yellow.4" />
          </Tooltip>
        </div>
      )}
      {isTwGlobalDashboardCreatorClaim && !codeResult && (
        <div>({dialect === 'bigquery' ? 'BQ' : 'CH'})</div>
      )}
      {serviceIds && serviceIds.length > 0 && (
        <div className="flex items-center gap-4">
          {serviceIds?.map((s) => (
            <div className="flex items-center gap-2" key={s}>
              {allServices[s]?.icon?.({ small: false })}
            </div>
          ))}
        </div>
      )}
      <div
        contentEditable={canEdit && !pinnedSection}
        suppressContentEditableWarning
        className={`mr-auto text-ellipsis overflow-hidden ${
          !secondaryToolbarOpen ? 'whitespace-nowrap' : ''
        } font-semibold`}
        onClick={(e) => {
          if (!title) {
            const range = document.createRange();
            range.selectNodeContents(e.currentTarget);
            const selection = window.getSelection();
            selection?.removeAllRanges();
            selection?.addRange(range);
          }
          e.currentTarget.focus();
        }}
        onBlur={async (e) => {
          const newTitle = e.currentTarget.innerText;
          if (newTitle === emptyTitle) {
            return;
          }
          if (!newTitle?.trim()) {
            e.currentTarget.innerText = (title as string) || emptyTitle;
            return;
          }

          titleChanged(newTitle);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.currentTarget.blur();
          }
        }}
      >
        <span className="dark:text-gray-300">{title || emptyTitle}</span>
      </div>
      {pinnedSection && !!setHidePinnedSection && (
        <div
          className="cursor-pointer"
          onClick={() => {
            setHidePinnedSection(true);
          }}
        >
          <ViewMajor className="w-8 h-8 flex items-center fill-gray-500 dark:fill-gray-300" />
        </div>
      )}
      {((!pinnedSection && !inWidgetEditor && !isSmall) || (isSmall && inMobileDrawer)) && (
        <div
          className={`absolute h-full 
          ${showQuestion || childDropdownsActive.length > 0 ? ' !visible' : ''}
          ${
            context === 'dashboard' ||
            context === 'editor' ||
            context === 'chat' ||
            context === 'sequence'
              ? ' right-4 '
              : ' right-0 '
          }
          flex items-center gap-2 pl-4 bg-white
        `}
        >
          <>
            <span
              className={`flex items-center opacity-0 transition-opacity group-hover:opacity-100 ${
                secondaryToolbarOpen ? ' !opacity-100' : ''
              } `}
            >
              {!!dashboard && (
                <WidgetInsights
                  dashboard={dashboard}
                  widgetTitle={title}
                  rawData={rawData}
                  loading={loading}
                  messageId={queryId ?? uuidv4()}
                />
              )}
              {canEdit && (
                <WidgetVisualizationPopover
                  queryId={queryId}
                  widgetType={type}
                  metrics={metrics}
                  availableTypes={availableTypes}
                  onChange={async (t) => {
                    typeChanged(t);
                    updateHistory('widget_type_updated', {
                      widgetId: queryId,
                      title,
                      type: t,
                    });
                    genericEventLogger(dlEvent, {
                      action: currentAnalyticsActionSet.CHANGE_VIZ_TYPE,
                      dashboard_name: dashboard?.name,
                      dashboard_id: dashboard?.id,
                      template_id: dashboard?.id,
                      template_name: dashboard?.id && dashboard?.name,
                      widget_id: queryId,
                      widget_title: title,
                      original_type: type,
                      new_type: t,
                    });
                  }}
                  updateMetrics={metricsChanged}
                  stacked={stacked}
                  setStacked={stackedChanged}
                  chartLayout={chartLayout}
                  setChartLayout={setChartLayout}
                />
              )}
              {!!allQueries.length && !!queryId && context !== 'editor' && (
                <div>
                  <WillyQuestionAndSql
                    dashboardId={dashboard?.id}
                    isCustomViewDashboard={isCustomView}
                    isGlobalDashboard={isGlobalDashboard}
                    queries={allQueries}
                    showCTA={false}
                    show={showQuestion}
                    setShow={setShowQuestion}
                    conversationId={rawData?.conversationId}
                    queryId={queryId}
                    queryDialect={rawData?.dialect}
                    queriesChanged={queriesChanged}
                    historicalQueryIds={historicalQueryIds}
                    parameters={parameters}
                    isDynamic={isDynamic}
                    mode={mode}
                    setTableBuilderModalOpened={() => {
                      setWidgetEditorOpen(true);
                      setShowQuestion(false);
                    }}
                    canEdit={canEdit}
                  />
                </div>
              )}
              {!!codeResult && !!codeResult.pythonCode && context !== 'editor' && (
                <div className={'-ml-2'}>
                  <WillyCodeSnippet
                    show={showCode}
                    setShow={setShowCode}
                    codeResultChanged={codeResultChanged}
                    code={codeResult.pythonCode}
                    output={codeResult.codeResults}
                    files={codeResult.files}
                    error={codeResult.error}
                    codeInterpreterInputData={codeInterpreterInputData}
                    dashboard={dashboard}
                    conversationId={rawData?.conversationId}
                    context={context}
                  />
                </div>
              )}
              {(!!parameters?.length || customFiltersAvailable) && !hideForCannotEditInTemplate && (
                <Tooltip label={showFilters ? 'Hide Filters' : 'Show Filters'} position="top">
                  <div className="relative">
                    <ActionIcon
                      icon="filter"
                      iconSize={18}
                      onClick={() => setShowFilters(!showFilters)}
                    />
                    {!showFilters &&
                      (parameters?.some((p) => p.visible && filterHasValue(p)) ||
                        appliedCustomFilters) && (
                        <div className="absolute -top-5 -right-2 p-1 bg-white rounded-full pointer-events-none">
                          <Badge size="xs" circle color="one.4">
                            {(parameters?.filter((p) => p.visible && filterHasValue(p)).length ||
                              0) + (appliedCustomFilters || 0)}
                          </Badge>
                        </div>
                      )}
                  </div>
                </Tooltip>
              )}
              <Menu
                opened={menuOpened}
                onChange={() => setMenuOpened((prev) => !prev)}
                closeOnItemClick={false}
              >
                <Menu.Target>
                  <span>
                    <ActionIcon icon="three-dots" iconSize={18} />
                  </span>
                </Menu.Target>
                <Menu.Dropdown>
                  {menuOptions.map((section, i) => {
                    return (
                      <div key={i}>
                        {section.items.map((item, j) => {
                          const {
                            content,
                            notCloseMenuOnClick,
                            helpText,
                            color,
                            loading,
                            error,
                            onClick,
                            disabled,
                            onDisabledWrapper: Wrapper,
                            ...rest
                          } = item;
                          const menuItem = (
                            <Menu.Item
                              disabled={disabled || loading}
                              {...rest}
                              onClick={() => {
                                if (!notCloseMenuOnClick) {
                                  setMenuOpened(false);
                                }
                                onClick();
                              }}
                            >
                              <Text size="sm" weight={500} c={color as FormattedColor}>
                                <span className="flex items-center gap-2">
                                  {!!loading && <Loader size="xs" />}
                                  {!!error && (
                                    <Tooltip label={error}>
                                      <Icon name="info" color="red.9" />
                                    </Tooltip>
                                  )}
                                  {content}
                                </span>
                              </Text>
                              {helpText && <Text size="xs">{helpText}</Text>}
                            </Menu.Item>
                          );

                          if (disabled && Wrapper) {
                            return <Wrapper key={j} target={menuItem} />;
                          }

                          return <Fragment key={j}>{menuItem}</Fragment>;
                        })}
                        {i < menuOptions.length - 1 && <Menu.Divider />}
                      </div>
                    );
                  })}
                </Menu.Dropdown>
              </Menu>
            </span>
            {context === 'chat' && (
              <PinMessage
                willyMessageType={type}
                context={context}
                queryId={queryId}
                type={type}
                title={title}
                metrics={metrics}
                queries={queries}
                stacked={stacked}
                incrementedStacked={incrementedStacked}
                parameters={parameters || []}
                wrapText={wrapText}
                codeResult={codeResult}
                dashboardId={dashboard?.id}
                grid={grid}
                gridColumns={gridColumns}
                twoColumnMobile={twoColumnMobile}
                disabled={loading}
                dimension={dimension}
                allowDataOverflow={allowDataOverflow}
                yAxisDomain={yAxisDomain}
                globalConditionalFormattingColor={globalConditionalFormattingColor}
                hasGlobalConditionalFormatting={hasGlobalConditionalFormatting}
                dataType={dataType}
                activator={
                  <span className="opacity-100">
                    <Button
                      mr="xs"
                      size="xs"
                      fz="xs"
                      iconSize={8}
                      rightSection="arrow-down-3"
                      onClick={() => {
                        genericEventLogger(dlEvent, {
                          action: currentAnalyticsActionSet.OPEN_ADD_TO_REPORT,
                          widget_type: type,
                          widget_id: queryId,
                          widget_name: title,
                          dashboard_id: dashboard?.id,
                          dashboard_name: dashboard?.name,
                          conversationId: conversationIdFromRoute,
                          template_id: dashboard?.globalDashboardId,
                          template_name: dashboard?.globalDashboardId && dashboard?.name,
                        });
                      }}
                    >
                      Save to
                    </Button>
                  </span>
                }
                pinTo={contextSourceIds}
                dialect={dialect || DEFAULT_DIALECT}
                chartLayout={chartLayout}
              />
            )}

            {dashboard && isDnd && canEdit && editLayout && secondaryToolbarOpen && (
              <div
                id={'drag-' + queryId}
                aria-label={'Drag ' + queryId}
                className={`flex opacity-0 transition-opacity group-hover:opacity-100 ${
                  secondaryToolbarOpen ? ' !opacity-100' : ''
                }`}
              >
                <DragHandle {...attributes} {...listeners} className="w-8 cursor-move text-logo" />
              </div>
            )}
          </>
        </div>
      )}

      {!!tilesSettingsOpen && (
        <ColumnsSelector
          open={tilesSettingsOpen}
          setOpen={setTilesSettingsOpen}
          type={type}
          metrics={metrics}
          queryId={queryId}
          dashboard={dashboard}
          metricsChanged={metricsChanged}
        />
      )}

      <WidgetMetaData
        open={widgetSettingsOpen}
        onClose={() => setWidgetSettingsOpen(false)}
        permission={permission}
        permissionChanged={permissionChanged}
        data={rawData}
      />

      <Modal
        size="xs"
        title="Conditional Formatting"
        opened={conditionalFormattingOpen}
        onClose={() => {
          setConditionalFormattingOpen(false);
        }}
      >
        <div className="flex flex-col gap-4">
          <Checkbox
            checked={hasGlobalConditionalFormatting}
            label="Enable conditional formatting for the table"
            onChange={(checked) => {
              setHasGlobalConditionalFormatting(checked);
            }}
          />
          <ColorPickerPopover
            color={globalConditionalFormattingColor}
            onChange={setGlobalConditionalFormattingColor}
            label="Color"
            activatorClassName="w-8 h-8"
            onReset={() => {}}
          />
          <Text>Generated Range</Text>
          <Flex>
            {exampleScale.map((color, i) => {
              return (
                <div
                  key={i}
                  className="w-6 h-3 rounded-[2px] mr-1"
                  style={{
                    backgroundColor: color,
                  }}
                ></div>
              );
            })}
          </Flex>
          <Flex direction="column" gap="md">
            <Text fw="bold">Metrics Involved</Text>
            <Flex direction="column" gap="xs" mah="200px" overflow="auto">
              <Checkbox
                mb="md"
                disabled={!hasGlobalConditionalFormatting}
                checked={metrics.every((m) => m.applyOnGlobalConditionalFormatting)}
                label="Apply to all metrics"
                indeterminate={
                  metrics.some((m) => m.applyOnGlobalConditionalFormatting) &&
                  metrics.some((m) => !m.applyOnGlobalConditionalFormatting)
                }
                onChange={(checked) => {
                  if (!queryId) {
                    return;
                  }
                  const newMetrics = metrics.map((m) => {
                    return {
                      ...m,
                      applyOnGlobalConditionalFormatting: checked,
                    };
                  });
                  metricsChanged(queryId, newMetrics);
                }}
              />
              {metrics.map((m) => {
                return (
                  <Checkbox
                    key={m.key}
                    disabled={!hasGlobalConditionalFormatting}
                    checked={m.applyOnGlobalConditionalFormatting}
                    label={m.name}
                    onChange={(checked) => {
                      if (!queryId) {
                        return;
                      }
                      const newMetrics = metrics.map((x) => {
                        if (x.key === m.key) {
                          return {
                            ...x,
                            applyOnGlobalConditionalFormatting: checked,
                          };
                        }
                        return x;
                      });
                      metricsChanged(queryId, newMetrics);
                    }}
                  />
                );
              })}
            </Flex>
          </Flex>
        </div>
      </Modal>

      <Modal
        size="xs"
        title="Card Layout"
        opened={layoutSettingsOpen}
        onClose={() => {
          setLayoutSettingsOpen(false);
        }}
      >
        <div className="sm:hidden">
          <Stack wrap={false}>
            <Stack.Item>
              <p className="font-semibold">Two Columns</p>
            </Stack.Item>
            <Stack.Item>
              <ReactSwitch
                onColor="#1877f2"
                handleDiameter={16}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={10}
                width={30}
                className="align-middle"
                checked={twoColumnMobile ?? false}
                onChange={(checked) => {
                  setTwoColumnMobile?.(checked);
                }}
              />
            </Stack.Item>
          </Stack>
        </div>
        <div className="hidden sm:flex flex-col gap-8">
          <Stack wrap={false}>
            <Stack.Item>
              <p id="flex_grid_toggle" className="font-semibold">
                Flex / Grid
              </p>
            </Stack.Item>
            <Stack.Item>
              <ReactSwitch
                onColor="#1877f2"
                handleDiameter={16}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={10}
                width={30}
                className="align-middle"
                checked={grid === 'grid'}
                onChange={(checked) => {
                  setGrid?.(!checked ? 'flex' : 'grid');
                }}
              />
            </Stack.Item>
          </Stack>
          {grid === 'grid' && (
            <ButtonGroup segmented>
              <ShopifyButton
                size="slim"
                pressed={gridColumns === 2}
                onClick={() => {
                  setGridColumns?.(2);
                }}
              >
                2
              </ShopifyButton>
              <ShopifyButton
                size="slim"
                pressed={gridColumns === 3}
                onClick={() => {
                  setGridColumns?.(3);
                }}
              >
                3
              </ShopifyButton>
              <ShopifyButton
                size="slim"
                pressed={gridColumns === 4}
                onClick={() => {
                  setGridColumns?.(4);
                }}
              >
                4
              </ShopifyButton>
            </ButtonGroup>
          )}
        </div>
      </Modal>

      <Modal
        size="xs"
        title="Y Axis Domain"
        opened={yAxisDomainModalOpen}
        onClose={() => {
          setYAxisDomainModalOpen(false);
        }}
      >
        <div className="flex flex-col gap-4">
          {minmax.map((m) => {
            return (
              <div key={m}>
                <Text fw="bold">{m === 'min' ? 'Min' : 'Max'}: </Text>
                <div className="flex flex-col gap-4">
                  <Checkbox
                    checked={yAxisDomain?.[m] === 'auto'}
                    label="Auto"
                    onChange={(checked) => {
                      setYAxisDomain({
                        ...yAxisDomain,
                        [m]: checked ? 'auto' : 0,
                      });
                    }}
                  />
                  <Checkbox
                    checked={
                      m === 'min' ? yAxisDomain?.[m] === 'dataMin' : yAxisDomain?.[m] === 'dataMax'
                    }
                    label={`Data ${m === 'min' ? 'Min' : 'Max'}`}
                    onChange={(checked) => {
                      setYAxisDomain({
                        ...yAxisDomain,
                        [m]: checked ? `data${m.charAt(0).toUpperCase() + m.slice(1)}` : 0,
                      });
                    }}
                  />

                  <TextField
                    autoComplete="off"
                    disabled={
                      yAxisDomain[m] === `data${m.charAt(0).toUpperCase() + m.slice(1)}` ||
                      yAxisDomain[m] === 'auto'
                    }
                    label="Custom"
                    type="number"
                    value={typeof yAxisDomain[m] === 'number' ? yAxisDomain[m].toString() : '0'}
                    onChange={(v) => {
                      setYAxisDomain({
                        ...yAxisDomain,
                        [m]: parseInt(v),
                      });
                    }}
                  />
                </div>
              </div>
            );
          })}
          <Checkbox
            checked={allowDataOverflow}
            label="Allow data overflow from the domain"
            onChange={(checked) => {
              setAllowDataOverflow(checked);
            }}
          />
        </div>
      </Modal>

      {CDPSegment && (
        <SyncModal
          isOpened={cdpSyncModalOpen}
          onClose={() => setCdpSyncModalOpen(false)}
          onNewSegmentCreated={(segmentId) => updateHistoryItem({ CDPSegmentId: segmentId })}
          segment={getSegmentDataView(CDPSegment, '')}
          isNewSyncOpenByDefault
          showExistingSyncs={false}
        />
      )}
    </div>
  );
};
