import { ActionIcon, Flex, Icon, IconName, Menu, Text, Tooltip } from '@tw/ui-components';
import { FC, useMemo, useState } from 'react';
import {
  ChartType,
  ChartTypeItem,
  ChartWithComboType,
  MessageTypeElement,
  MessageTypes,
  WillyChartLayout,
  WillyMetric,
} from '../types/willyTypes';
import { ReactComponent as DecorativeComboChart } from '../icons/comboChart.svg';
import { BuildingInPublicTag } from 'components/StatusTags/BuildingInPublicTag';

type WidgetVisualizationPopoverProps = {
  queryId?: string;
  widgetType: MessageTypes;
  metrics: WillyMetric[];
  updateMetrics: (id: string, data: WillyMetric[]) => void;
  activator?: (
    onClick: () => void,
    value?: string,
    icon?: IconName | JSX.Element,
  ) => React.ReactNode;
  onChange: (type: MessageTypes) => void;
  availableTypes: {
    chart: {
      label: string;
      items: ChartTypeItem<ChartWithComboType>[];
    };
    other: {
      label: string;
      items: MessageTypeElement<MessageTypes>[];
    };
  };
  stacked: boolean;
  setStacked?: (val: boolean) => void;
  chartLayout?: WillyChartLayout;
  setChartLayout?: (layout: WillyChartLayout) => void;
};

const comboChartItem: ChartTypeItem<'combo'> = {
  id: 'combo',
  title: 'Combo',
  icon: 'combo-chart',
  decorativeIcon: <DecorativeComboChart />,
};

export const WidgetVisualizationPopover: FC<WidgetVisualizationPopoverProps> = ({
  queryId,
  widgetType,
  metrics,
  updateMetrics,
  activator,
  onChange,
  availableTypes,
  stacked,
  setStacked,
  chartLayout,
  setChartLayout,
}) => {
  const [opened, setOpened] = useState<boolean>(false);
  const widgetTypeElement = useMemo(
    () => availableTypes.other.items.find((type) => type.id === widgetType),
    [availableTypes.other.items, widgetType],
  );

  const chartTypeElement: ChartTypeItem<ChartWithComboType> | undefined = useMemo(() => {
    if (widgetType !== 'chart') return;
    let chartType: ChartWithComboType = 'combo';
    const isAllMetricsWithSameChartType = metrics.every(
      (metric) => metric.chartType === metrics[0]?.chartType,
    );
    if (isAllMetricsWithSameChartType && metrics[0]?.chartType) {
      chartType = metrics[0]?.chartType;
    }
    if (chartType === 'bar' && stacked) {
      chartType = 'stacked-bar';
    }
    if (chartLayout === 'vertical' && chartType === 'bar') {
      chartType = 'horizontal-bar';
    }
    if (chartType === 'combo') {
      return comboChartItem;
    }
    return availableTypes.chart.items.find((type) => type.id === chartType);
  }, [availableTypes.chart.items, chartLayout, metrics, stacked, widgetType]);

  const handleTypeChange = (type: MessageTypes, chartType?: ChartType) => {
    onChange(type);
    if (type === 'chart' && chartType) {
      if (!queryId) return;
      let newMetrics = metrics.map((x) => ({ ...x, chartType }));
      if (chartType === 'bar' && stacked) {
        setStacked?.(false);
      }
      if (chartType !== 'horizontal-bar' && chartLayout === 'vertical') {
        setChartLayout?.('horizontal');
      }
      if (chartType === 'stacked-bar') {
        setStacked?.(true);
        newMetrics = newMetrics.map((x) => ({ ...x, yAxisId: 'left', chartType: 'bar' }));
      }
      if (chartType === 'horizontal-bar') {
        setChartLayout?.('vertical');
        newMetrics = newMetrics.map((x) => ({ ...x, yAxisId: 'left', chartType: 'bar' }));
      }
      updateMetrics(queryId, newMetrics);
    }
  };

  return (
    <Menu
      opened={opened}
      onChange={() => {
        setOpened(!opened);
      }}
      shadow="md"
      position={activator ? undefined : 'bottom-end'}
    >
      <Tooltip label="Visualization Type">
        <Menu.Target>
          {activator ? (
            activator(
              () => setOpened((prev) => !prev),
              chartTypeElement ? chartTypeElement.title : widgetTypeElement?.title,
              chartTypeElement ? chartTypeElement.icon : widgetTypeElement?.icon,
            )
          ) : (
            <ActionIcon
              icon={chartTypeElement ? chartTypeElement.icon : widgetTypeElement?.icon}
              onClick={() => setOpened((prev) => !prev)}
            />
          )}
        </Menu.Target>
      </Tooltip>
      <Menu.Dropdown maw={700}>
        <Flex direction="column" gap="xs">
          {Object.values(availableTypes).map((mainType) => {
            return (
              <Flex direction="column" gap="xs" key={`main-type-${mainType.label}`}>
                {mainType.items
                  .filter((item) => !!item.decorativeIcon)
                  .concat(
                    mainType.label === 'CHART' && chartTypeElement?.id === 'combo'
                      ? [comboChartItem]
                      : [],
                  )
                  .map(
                    (
                      item: ChartTypeItem<ChartWithComboType> | MessageTypeElement<MessageTypes>,
                    ) => {
                      return (
                        <Menu.Item
                          key={item.id}
                          onClick={() => {
                            if (item.id === 'combo') return;
                            const vars =
                              mainType.label === 'CHART'
                                ? {
                                    type: 'chart' as MessageTypes,
                                    chartType: item.id as ChartType,
                                  }
                                : { type: item.id as MessageTypes };
                            handleTypeChange(vars.type, vars.chartType);
                            setOpened(false);
                          }}
                          leftSection={
                            <Icon
                              name={item.icon}
                              color={
                                (chartTypeElement ?? widgetTypeElement)?.id === item.id
                                  ? 'one.5'
                                  : 'gray.5'
                              }
                            />
                          }
                          bg={
                            (chartTypeElement ?? widgetTypeElement)?.id === item.id
                              ? 'one.0'
                              : undefined
                          }
                          rightSection={item.id === 'sankey' && <BuildingInPublicTag />}
                        >
                          <Text
                            size="xs"
                            weight={500}
                            color={
                              (chartTypeElement ?? widgetTypeElement)?.id === item.id
                                ? 'one.6'
                                : 'gray.6'
                            }
                          >
                            {item.title}
                          </Text>
                        </Menu.Item>
                      );
                    },
                  )}
              </Flex>
            );
          })}
        </Flex>
      </Menu.Dropdown>
    </Menu>
  );
};
