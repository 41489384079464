import { AttributionModels } from '@tw/types';
import DropDown from 'components/ltv/DropDown';
import {
  TW_LS_ATTRIBUTION_INCLUDE_ONE_DAY_VIEW,
  TW_LS_ATTRIBUTION_MODEL_KEY,
  useAllAttributionModels,
  PIXEL_ATTRIBUTION_NEW_MODELS_MODES,
} from 'constants/attribution';
import { addOneDayView, changeAttributionModel, setUseNewModels } from 'ducks/attribution/actions';
import { useAppDispatch } from 'index';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { AllServicesIds, PixelAttributionModel } from 'types/attribution';
import { SourceTypesWithExtra } from 'types/services';
import { useDarkMode } from 'dark-mode-control';
import { set, snakeCase } from 'lodash';
import { AttributionMultipaneDropdown } from 'components/attribution-new/AttributionMultipaneDropdown';
import { useStoreValue } from '@tw/snipestate';
import { $activeAppVersion } from '$stores/nav-config-stores';
import { useFeatureFlagComputer } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { Button, Text } from '@tw/ui-components';
import { isDefined } from 'utils/isDefined';
import { upgradePixelModalOpened } from 'ducks/subscription';
import { analyticsEvents, genericEventLogger, upgradeActions } from 'utils/dataLayer';
import { $isFreeShop } from '$stores/willy/$subscription';

type AttributionModelPickerProps = {
  type: SourceTypesWithExtra;
  sourceId?: AllServicesIds | 'tw_referrer';
  zIndex?: number;
  maxHeight?: number | string;
  customActivator?: (label: string | React.ReactNode) => React.ReactNode;
};

type AttributionModelsWithExtra = AttributionModels | 'lastPlatformClickPlusViews';
type AttributionModes = 'current' | 'nextgen';

const AttributionModelPicker: React.FC<AttributionModelPickerProps> = ({
  type,
  sourceId,
  zIndex,
  maxHeight,
  customActivator,
}) => {
  const dispatch = useAppDispatch();

  const { attributionModel, includeOneDayView, useNewModels, showNewModels } = useSelector(
    (state: RootState) => state.attribution,
  );
  const pixelVersion = useSelector((state: RootState) => state.attribution.pixelVersion);
  const displayGenerationPicker = showNewModels;

  const doDarkMode = useDarkMode();

  const activeAppVersion = useStoreValue($activeAppVersion);
  const isThreePointOh = activeAppVersion === '3.0';

  // don't know why this suddenly became "unknown" - coercing back to any
  const allAttributionModels: any = useAllAttributionModels();
  const ffComputer = useFeatureFlagComputer();
  const { blockList: ffBlockedModels } = ffComputer.getConfigById(
    FeatureFlag.LIMIT_PIXEL_ATTRIBUTION_MODELS_FF,
  );
  const isFreeShop = useStoreValue($isFreeShop);

  const setModelDisabled = useCallback(
    (
      type: SourceTypesWithExtra,
      m: PixelAttributionModel,
      sourceId?: AllServicesIds | 'tw_referrer',
    ) => {
      return (
        m.disableOnTypes.includes(type) ||
        (m.enableOnSources && sourceId && !m.enableOnSources.includes(sourceId)) ||
        ffBlockedModels.includes(m.value)
      );
    },
    [ffBlockedModels],
  );

  const allModels = useMemo(() => {
    let models = allAttributionModels.map((m: PixelAttributionModel) => ({
      ...m,
      id: snakeCase(m.shortLabel),
      disabled: setModelDisabled(type, m, sourceId) || m.disabled,
      blocked: ffBlockedModels.includes(m.value),
    }));
    return models;
  }, [allAttributionModels, setModelDisabled, type, sourceId, ffBlockedModels]);
  const isModelAllowed = useCallback(
    (model: string) => !allModels?.find((m: any) => m.value === model)?.disabled,
    [allModels],
  );

  const [activeModel, setActiveModel] = useState<AttributionModelsWithExtra>(
    isModelAllowed(attributionModel)
      ? attributionModel
      : allModels?.find((m: any) => !m.disabled)?.value || 'lastPlatformClick',
  );

  useEffect(() => {
    if (attributionModel.includes('lastPlatformClick') && includeOneDayView) {
      setActiveModel('lastPlatformClickPlusViews');
    } else {
      setActiveModel(attributionModel);
    }
  }, [attributionModel, includeOneDayView]);

  // we need to change the model dynamically if user changed the: type, sourceId
  useEffect(() => {
    if (!isModelAllowed(activeModel))
      handleAttributionModelChange(
        allModels?.find((m) => !m.disabled)?.value || 'lastPlatformClick',
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, activeModel, sourceId]);

  const handleAttributionModelChange = useCallback(
    (model: AttributionModelsWithExtra | AttributionModelsWithExtra[]) => {
      if (Array.isArray(model)) return;

      if (model === 'lastPlatformClickPlusViews') {
        model = useNewModels ? 'lastPlatformClick-v2' : 'lastPlatformClick';
        dispatch(addOneDayView(true));
        localStorage.setItem(TW_LS_ATTRIBUTION_INCLUDE_ONE_DAY_VIEW, JSON.stringify(true));
      } else {
        localStorage.setItem(TW_LS_ATTRIBUTION_INCLUDE_ONE_DAY_VIEW, JSON.stringify(false));
        dispatch(addOneDayView(false));
      }
      localStorage.setItem(TW_LS_ATTRIBUTION_MODEL_KEY, model);
      dispatch(changeAttributionModel(model));
    },
    [dispatch, useNewModels],
  );

  const handleModelsModeChange = useCallback(
    async (val: AttributionModes) => {
      const selectedNewModels = val === 'nextgen';
      dispatch(setUseNewModels(selectedNewModels));
    },
    [dispatch],
  );

  const [openedState, setOpenedState] = useState(false);
  const toggle = useCallback((isOpen?: boolean) => {
    if (isDefined(isOpen)) setOpenedState(isOpen);
    else setOpenedState((x) => !x);
  }, []);

  const sections = useMemo(() => {
    if (ffBlockedModels?.length) {
      return [
        { options: allModels.filter((x) => !x.blocked), title: '' },
        {
          options: allModels.filter((x) => x.blocked),
          title: (
            <div className="flex space-between align-items-center">
              <Text fw={600}>Upgrade to Unlock</Text>
              <Button
                onClick={() => {
                  dispatch(upgradePixelModalOpened('Attribution Models'));
                  genericEventLogger(analyticsEvents.UPGRADE, {
                    action: upgradeActions.UPGRADE_CLICKED,
                    isFoundersDash: isFreeShop,
                    pathname: location.pathname,
                    upgrade_path: 'LIMIT_PIXEL_ATTRIBUTION_MODELS_FF',
                    targetToUnlock: 'attributionModels',
                  });
                  toggle(false);
                }}
              >
                Upgrade
              </Button>
            </div>
          ),
        },
      ];
    }
  }, [allModels, dispatch, ffBlockedModels?.length, isFreeShop, toggle]);

  return (
    <>
      <AttributionMultipaneDropdown
        id="att-header-model-picker"
        zIndex={zIndex}
        // maxHeight={maxHeight}
        options={allModels}
        theme={doDarkMode ? 'dark' : 'none'}
        value={activeModel}
        handleSelect={(value) =>
          handleAttributionModelChange(
            value as AttributionModelsWithExtra | AttributionModelsWithExtra[],
          )
        }
        activatorSize={isThreePointOh ? 'xs' : undefined}
        customActivator={customActivator}
        sections={sections}
        openController={{ opened: openedState, toggle }}
      />
      {displayGenerationPicker && (
        <DropDown
          id="att-header-model-picker-mode"
          options={PIXEL_ATTRIBUTION_NEW_MODELS_MODES}
          theme={doDarkMode ? 'dark' : 'none'}
          value={useNewModels ? 'nextgen' : 'current'}
          handleSelect={handleModelsModeChange}
          sections={[
            {
              options: [],
              title: (
                <a
                  href="https://kb.triplewhale.com/en/articles/8061913-next-generation-attribution-models-beta"
                  target="_blank"
                  className="cursor-pointer text-2xl font-normal text-logo"
                  id="att-header-model-picker-mode-learn-more"
                >
                  Learn what is changing
                </a>
              ),
            },
          ]}
          size={isThreePointOh ? 'xs' : 'sm'}
        />
      )}
    </>
  );
};

export default AttributionModelPicker;
