/**
 * This is new hook that will handle the socket connection for the sequence flow
 * I should replace useSequenceSocket with this in the future
 */

import { useEffect, useCallback } from 'react';
import { InsightsSteamEvent } from '../types/willyTypes';
import { useStoreValue } from '@tw/snipestate';
import { $socket } from '$stores/$socket';

type SequenceFlowSocketProps = {
  messageId: string;
  setInsightsText: React.Dispatch<React.SetStateAction<string>>;
};

export const useInsightsStreamSocket = (props: SequenceFlowSocketProps) => {
  const { messageId, setInsightsText } = props;

  const socket = useStoreValue($socket);

  const onNewInsightsDelta = useCallback(
    (data: InsightsSteamEvent) => {
      const { messageId: steamMessageId, delta } = data;

      if (!messageId) {
        return;
      }

      if (messageId !== steamMessageId) {
        return;
      }

      setInsightsText((prev) => prev + delta);
    },
    [messageId, setInsightsText],
  );

  useEffect(() => {
    const func = (msg) => {
      if (msg.eventType !== 'willy_insights_stream') {
        return;
      }
      onNewInsightsDelta(msg.data);
    };
    socket.on('message', func);

    return () => {
      socket.off('message', func);
    };
  }, [socket, onNewInsightsDelta]);
};
