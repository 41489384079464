import {
  FilterProperty,
  FilterPropertyCategory,
  InsightsFilter,
  InsightsFilterCondition,
} from '@tw/types/module/services/insights';
import { Flex, Box, Select, Text, QuantityDecorator, Anchor, ActionIcon } from '@tw/ui-components';
import {
  DEFAULT_COMPARATORS,
  getPropertyCategory,
  getPropertyType,
} from 'components/Insights/Filters/constants';
import { useEffect, useMemo, useState } from 'react';
import { chain } from 'lodash';
import FilterComparator from 'components/Insights/Filters/FilterComparator';
import { isFilterDirty } from 'components/Insights/Filters/utils';
import { getFilterQueryLabel } from 'components/Insights/Filters/queryTranslation';

const CATEGORIES_SORT = [
  FilterPropertyCategory.ORDERS,
  FilterPropertyCategory.PRODUCTS,
  FilterPropertyCategory.CUSTOMERS,
  FilterPropertyCategory.ATTRIBUTION,
];

export type CustomFilterQueryBuilderProps = {
  onChange: (query: InsightsFilter['query']) => void;
  query: InsightsFilter['query'];
  availableProperties: FilterProperty[];
};
export const CustomFilterQueryBuilder: React.FC<CustomFilterQueryBuilderProps> = ({
  query,
  onChange,
  availableProperties,
}) => {
  const filterPropsForCategory = (category: FilterPropertyCategory) => {
    return availableProperties.filter((prop) => getPropertyCategory(prop) === category);
  };

  const defaultFilterForCategory = (category: FilterPropertyCategory) => {
    const categoryFilters = filterPropsForCategory(category);
    return {
      property: categoryFilters[0],
      value: '',
      comparator: DEFAULT_COMPARATORS[getPropertyType(categoryFilters[0])],
    };
  };

  const dirtyFiltersForCategory = (category: FilterPropertyCategory) => {
    return (categoryConditionMap[category] || []).filter(isFilterDirty);
  };

  const filterConditionUpdated = (
    category: FilterPropertyCategory,
    index: number,
    condition: Partial<InsightsFilterCondition>,
  ) => {
    const conditions = categoryConditionMap[category] || [];
    const currentCondition = conditions[index] || {};
    const newCondition = { ...currentCondition, ...condition };

    setCategoryConditionMap({
      ...categoryConditionMap,
      [category]: [...conditions.slice(0, index), newCondition, ...conditions.slice(index + 1)],
    });
  };

  const categories: FilterPropertyCategory[] = useMemo(() => {
    return chain(availableProperties)
      .map((prop) => getPropertyCategory(prop))
      .uniq()
      .sort((a, b) => CATEGORIES_SORT.indexOf(a) - CATEGORIES_SORT.indexOf(b))
      .value();
  }, [availableProperties]);

  const [selectedCategory, setSelectedCategory] = useState<FilterPropertyCategory>(
    query[0]?.property ? getPropertyCategory(query[0].property) : categories[0],
  );
  const [categoryConditionMap, setCategoryConditionMap] = useState<{
    [key in FilterPropertyCategory]?: InsightsFilterCondition[];
  }>(
    categories.reduce(
      (map, category) => {
        const filters = query.filter((f) => getPropertyCategory(f.property) === category);
        map[category] = filters.length ? filters : [defaultFilterForCategory(category)];
        return map;
      },
      {} as { [key in FilterPropertyCategory]?: InsightsFilterCondition[] },
    ),
  );

  const deleteFilterCondition = (category: FilterPropertyCategory, index: number) => {
    setCategoryConditionMap({
      ...categoryConditionMap,
      [category]: categoryConditionMap[category]?.filter((_, i) => i !== index),
    });
  };

  const addFilterToCategory = (category: FilterPropertyCategory) => {
    setCategoryConditionMap({
      ...categoryConditionMap,
      [category]: [...(categoryConditionMap[category] || []), defaultFilterForCategory(category)],
    });
  };

  useEffect(() => {
    onChange(
      Object.values(categoryConditionMap)
        .flat()
        .filter(isFilterDirty)
        .map(
          (f) =>
            Object.fromEntries(
              Object.entries(f).filter(([_, value]) => value !== undefined),
            ) as InsightsFilterCondition,
        ),
    );
  }, [categoryConditionMap, onChange]);

  return (
    <Flex gap={0}>
      {/* tabs */}
      <Flex direction="column" gap="xs" borderRight="1px solid var(--mantine-color-gray-2)" p="lg">
        {categories.map((category) => (
          <QuantityDecorator quantity={dirtyFiltersForCategory(category).length}>
            <Box
              key={category}
              onClick={() => setSelectedCategory(category)}
              bg={selectedCategory === category ? 'gray.2' : 'transparent'}
              cursor="pointer"
              px="xs"
              py={4 as any}
              borderRadius={'4px'}
              tt="capitalize"
              pr="xl"
            >
              <Text>{category}</Text>
            </Box>
          </QuantityDecorator>
        ))}
      </Flex>
      {/* context */}
      {selectedCategory && (
        <Flex direction="column" gap="sm" m="lg">
          {(
            categoryConditionMap[selectedCategory] || [defaultFilterForCategory(selectedCategory)]
          ).map((f, index) => (
            <>
              <Flex gap="sm" align="center" key={index}>
                <Select
                  data={filterPropsForCategory(selectedCategory).map((prop) => ({
                    value: prop,
                    label: getFilterQueryLabel(prop),
                  }))}
                  value={f.property}
                  onChange={(property) => {
                    filterConditionUpdated(selectedCategory, index, {
                      property: property as FilterProperty,
                      comparator: DEFAULT_COMPARATORS[getPropertyType(property as FilterProperty)],
                    });
                  }}
                />
                <FilterComparator
                  key={`${index}-${f.property}`}
                  comparatorType={getPropertyType(f.property)}
                  comparator={f.comparator}
                  value={f.value}
                  value1={f.value1}
                  value2={f.value2}
                  unit={f.unit}
                  property={f.property}
                  onChanged={({ comparator, value, value1, value2, unit }) => {
                    filterConditionUpdated(selectedCategory, index, {
                      ...f,
                      comparator,
                      value,
                      value1,
                      value2,
                      unit,
                    });
                  }}
                ></FilterComparator>
                <ActionIcon
                  icon="trash"
                  ml="auto"
                  onClick={() => deleteFilterCondition(selectedCategory, index)}
                />
              </Flex>
              {index < (categoryConditionMap[selectedCategory]?.length ?? 0) - 1 && (
                <Flex align="center" gap="sm">
                  <Text size="sm">OR</Text>
                  <Box h={1} bg="gray.2" w={'100%'} />
                </Flex>
              )}
            </>
          ))}
          <Anchor underline="never" fw={500} onClick={() => addFilterToCategory(selectedCategory)}>
            + Add Condition
          </Anchor>
        </Flex>
      )}
    </Flex>
  );
};
