import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  ALLOWED_FILTER_PROPS,
  CohortFiltersType,
  CohortsBrekdown,
  CohortsMetric,
  TableViewOptions,
  getOptionLabel,
} from './constants';
import DropDown from 'components/ltv/DropDown';
import { TimeframeFilter } from './TimeframeFilter';
import { analyticsEvents, insightsActions, genericEventLogger } from 'utils/dataLayer';
import { FiltersContext } from 'ducks/insights/filterReducers';
import CDPSegmentDropdown from 'components/ltv/CDPSegmentDropdown';
import InsightFilter from '../Filters/InsightFilter';
import InsightsFilterField from '../InsightFilterField';
import { $isAdminClaim } from '$stores/$user';
import { useStoreValue, useWritableStore } from '@tw/snipestate';
import { Checkbox } from '@tw/ui-components';
import { $useCHInsight } from '../../../$stores/$shop';

const CohortsFilters: React.FC<{
  tableViewOptions: TableViewOptions;
  onChange: (value: TableViewOptions) => void;
}> = ({ tableViewOptions, onChange }) => {
  const isAdmin = useStoreValue($isAdminClaim);
  const [useCHInsight, setUseCHInsight] = useWritableStore($useCHInsight);

  useEffect(() => {
    onChange({ ...tableViewOptions, dialect: useCHInsight ? 'clickhouse' : 'bigquery' });
  }, [onChange, tableViewOptions, useCHInsight]);

  const BREAK_DOWN_OPTION = Object.values(CohortsBrekdown);

  const breakDownOption =
    BREAK_DOWN_OPTION.map((o) => ({ label: getOptionLabel(o), value: o })) || [];

  const METRICS_OPTIONS = Object.values(CohortsMetric);
  const metricsOptions = METRICS_OPTIONS.map((o) => ({ label: getOptionLabel(o), value: o })) || [];

  const [cohortFilter, setCohortFilter] = useState<CohortFiltersType>({
    breakdown: CohortsBrekdown.FIRST_ORDER_AT,
  });

  const handleMetricChanged = (value) => {
    genericEventLogger(analyticsEvents.COHORTS, {
      action: insightsActions.FILTER_CHANGED,
      filterType: 'metric',
      value: value,
    });
    onChange({ ...tableViewOptions, metric: value });
  };

  const handleBreakdownChanged = (value) => {
    genericEventLogger(analyticsEvents.COHORTS, {
      action: insightsActions.FILTER_CHANGED,
      filterType: 'breakdown',
      value: value,
    });

    setCohortFilter({ ...cohortFilter, breakdown: value });
  };

  const tooltipContent: ReactNode = useMemo(() => {
    switch (tableViewOptions.metric) {
      case CohortsMetric.LTV:
        return (
          <span>
            <p>Total sales of cohort per unique customers in the cohort:</p>
            <p className="italic">Total Sales / number of unique customers in cohort</p>
          </span>
        );
      case CohortsMetric.RETENTION_RATE:
        return 'The percentage of unique customers who made a additional order during the selected time frame';
      case CohortsMetric.TOTAL_SALES:
        return (
          <span>
            <p>
              Total revenue earned from customers within each cohort
              <p>(after taxes shipping and discounts)</p>
            </p>
            <p className="italic">Gross Sales + Tax collected + Shipping collected - Discounts</p>
          </span>
        );
      case CohortsMetric.SIZE:
        return 'The number of unique customers in the time period';
      default:
        return '';
    }
  }, [tableViewOptions.metric]);

  return (
    <div className="border-b border-solid border-[#D1D5DB] border-0 pb-[16px] mb-[28px] flex items-end flex-wrap gap-[12px]">
      <TimeframeFilter />

      <InsightsFilterField
        label="Breakdown by"
        tooltipContent="The categories by which to breakdown the cohorts"
      >
        <DropDown
          handleSelect={(value) => handleBreakdownChanged(value)}
          options={breakDownOption}
          value={cohortFilter.breakdown}
        ></DropDown>
      </InsightsFilterField>

      <InsightsFilterField label="Metric" tooltipContent={tooltipContent} tooltipMaxWidth={230}>
        <DropDown
          handleSelect={(value) => handleMetricChanged(value)}
          options={metricsOptions}
          value={tableViewOptions.metric}
        ></DropDown>
      </InsightsFilterField>

      <InsightsFilterField label="Segments">
        <CDPSegmentDropdown />
      </InsightsFilterField>

      <InsightFilter
        filterFields={ALLOWED_FILTER_PROPS}
        filtersContext={FiltersContext.Cohorts}
        filterDescription={{
          tooltipText:
            'Filters are applied on the first order. All 0 amount orders are automatically filtered out.',
          titleText: 'Filters are applied on the first orders',
        }}
      />

      {isAdmin && (
        <div className="ml-auto">
          <Checkbox
            label="Use clickhouse (only admin)"
            onChange={(checked) => setUseCHInsight(checked)}
            checked={tableViewOptions.dialect === 'clickhouse'}
          />
        </div>
      )}
    </div>
  );
};
export default CohortsFilters;
