import { Flex, Tooltip, Icon, FormattedColor } from '@tw/ui-components';
import { useStoreValue } from '@tw/snipestate';
import {
  $isFeedbackVisible,
  setFromNav,
  toggleFeedbackVisibility,
} from 'components/Feedback/FeedbackButton';
import { analyticsEvents, feedbackActions, genericEventLogger } from 'utils/dataLayer';

export type FeedbackControlProps = {
  iconSize?: number;
  iconColor?: FormattedColor;
};

export function FeedbackControl({ iconSize = 27, iconColor }: FeedbackControlProps) {
  const isFeedbackVisible = useStoreValue($isFeedbackVisible);

  const handleToggleFeedback = () => {
    if (!isFeedbackVisible) {
      toggleFeedbackVisibility(true);
      setFromNav(true);
    } else {
      genericEventLogger(analyticsEvents.FEEDBACK, {
        action: feedbackActions.HIDE_FEEDBACK_BUTTON,
        source: 'Nav Button',
        path: window.location.pathname,
      });
      toggleFeedbackVisibility(false);
    }
  };

  return (
    <div id="feedback-control-icon">
      <Flex align="center" justify="center" w="100%" h="100%">
        <Tooltip
          label={isFeedbackVisible ? 'Hide feedback' : 'Show feedback'}
          onClick={handleToggleFeedback}
        >
          <div className="flex cursor-pointer">
            <Icon size={iconSize} name="feedback-icon" color={iconColor} />
          </div>
        </Tooltip>
      </Flex>
    </div>
  );
}
