import { useAppSelector } from '../reducers/RootType';
import { useEffect, useMemo, useState } from 'react';
import { urlParams } from '../config';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { logoutOnPress, signInWithCustomToken, updateCurrentShopId } from '../ducks/auth';
import axiosInstance from '../utils/axiosInstance';
import firebase from 'firebase/compat/app';
import { toast } from 'react-toastify';
import { appDetailsType } from './OrcaApp';
import { getProviders } from '../ducks/sensory';
import { useAppDispatch } from '../index';
import { sleep } from '../utils/sleep';

export const useOrcaPage = () => {
  const providersConfig = useAppSelector((state) => state.sensory.providers);
  const [appDetails, setAppDetails] = useState<appDetailsType | null>(null);
  const [errorLoadingApp, setErrorLoadingApp] = useState<string>('');
  const [loadingSignIn, setLoadingSignIn] = useState(false);
  const [accountId, setAccountId] = useState<string | null>(urlParams.get('account-id'));
  const [appId, setAppId] = useState<string | null>(urlParams.get('app-id'));
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);
  const isLoggedIn = useAppSelector((state) => state.isLoggedIn);
  const loadingAuth = useAppSelector((state) => state.loading);
  const [token, setToken] = useState<string | null>(urlParams.get('token'));
  const dispatch = useAppDispatch();
  const notLoggedIn = !loadingAuth && !isLoggedIn;

  useEffect(() => {
    if (!urlParams.get('shop-id') && accountId) {
      updateCurrentShopId(accountId);
    }
  }, [accountId]);

  useEffect(() => {
    if (!accountId) {
      //try to get the account id from local storage
      const acc = localStorage.getItem('account-id');
      if (acc) {
        setAccountId(acc);
      }
    } else {
      localStorage.setItem('account-id', accountId);
    }
  }, [accountId]);

  useEffect(() => {
    if (!appId) {
      //try to get the account id from local storage
      const ap = localStorage.getItem('app-id');
      if (ap) {
        setAppId(ap);
      }
    } else {
      localStorage.setItem('app-id', appId);
    }
  }, [appId]);

  useEffect(() => {
    if (user.uid && appId && accountId) {
      (async () => {
        try {
          const data = (
            await axiosInstance.post(`/v2/orcabase/client-app/get-app-info`, {
              appId,
              accountId,
            })
          ).data;
          setAppDetails(data);
        } catch (e) {
          setErrorLoadingApp(e.data);
        }
        try {
          await dispatch(getProviders(false));
        } catch (e) {
          // dispatch(logoutOnPress());
          await sleep(1000);
          location.reload();
        }
      })();
    }
  }, [appId, accountId, user.uid, dispatch]);

  useEffect(() => {
    (async () => {
      if (loadingAuth) return;
      if (!accountId || !appId) return;
      if (loadingSignIn) return;
      if (!isLoggedIn) {
        setLoadingSignIn(true);
        if (!token) {
          setErrorLoadingApp(`token is missing`);
          setLoadingSignIn(false);
        }
        try {
          try {
            await signInWithCustomToken(token);
          } catch {
            toast.error(`Invalid email or password`);
          }
        } catch (e) {
          setErrorLoadingApp(e.data);
        }
        setLoadingSignIn(false);
      }
    })();
  }, [notLoggedIn, navigate, accountId, appId, loadingAuth, isLoggedIn, token, loadingSignIn]);

  const loadingPage = useMemo(
    () => providersConfig.length === 0 || !appDetails || loadingAuth || loadingSignIn,
    [providersConfig, appDetails, loadingAuth, loadingSignIn],
  );

  console.log({ loadingPage, appDetails, errorLoadingApp, appId, accountId, providersConfig });

  return { loadingPage, appDetails, errorLoadingApp, appId, accountId };
};
