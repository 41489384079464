import AttentiveIconBase64 from 'components/AttentiveIconBase64';
import EnquirelabsIconBase64 from 'components/EnquirelabsIconBase64';
import FacebookIconBase64 from 'components/FacebookIconBase64';
import GoogleAdsIconBase64 from 'components/GoogleAdsIconBase64';
import GoogleAnalyticsIconBase64 from 'components/GoogleAnalyticsIconBase64';
import GoogleIconBase64 from 'components/GoogleIconBase64';
import GorgiasIconBase64 from 'components/GorgiasIconBase64';
import KnoLogoSVG from 'components/Icons/KnoLogoSVG';
import InstagramIconBase64 from 'components/InstagramIconBase64';
import PinterestIconBase64 from 'components/PinterestIconBase64';
import RechargeIconBase64 from 'components/Recharge/RechargeIconBase64';
import MountaionIconBase64 from 'components/Mountain/MountainIconBase64';
import CriteoIconBase64 from './components/Criteo/CriteoIconBase64';
import SmsbumpIconBase64 from './components/Smsbump/SmsbumpIconBase64';
import SmsbumpYotpoEmailSMSIconBase64 from './components/SmsbumpYotpoEmailSMS/SmsbumpYotpoEmailSMSIconBase64';
import SmsbumpYotpoReviewsIconBase64 from './components/SmsbumpYotpoReviews/SmsbumpYotpoReviewsIconBase64';
import ShopifyIconBase64 from 'components/ShopifyIconBase64';
import SnapchatIconBase64 from 'components/SnapchatIconBase64';
import TiktokIconBase64 from 'components/TiktokIconBase64';
import TripleWhaleIconBase64 from 'components/TripleWhaleIconBase64';
import YoutubeIconBase64 from 'components/YoutubeIconBase64';
import React from 'react';

import { MarketingMinor } from '@shopify/polaris-icons';
import { SummaryMetricIdsTypes, SummaryMetricsIconKeys } from '@tw/types/module/SummaryMetrics';

import { ReactComponent as AffluencerIcon } from './icons/affluencer-hub.svg';
import { ReactComponent as Blended } from './icons/blended-metrics.svg';
import { ReactComponent as Pixel } from './icons/pixel.svg';
import { ReactComponent as Attribution } from 'icons/attribution2.svg';

import { ReactComponent as Willy } from './icons/willy-whale.svg';
import AmazonIconBase64 from 'components/AmazonBase64';
import TwitterIconBase64 from 'components/TwitterIconBase64';
import MicrosoftIconSVG from 'components/Icons/MicrosoftSvg';
import KlaviyoIconBase64 from 'components/KlaviyoIconBase64';
import CustomMetricsIconSVG from 'components/CustomMetricsIconSVG';
import SalesPlatformIcon from 'components/SalesPlatform/SalesPlatformIcon';
import ShipstationIconBase64 from 'components/Shipstation/ShipstationIconBase64';
import ShipbobIconBase64 from 'components/Shipbob/ShipbobIconBase64';
import PostscriptIconBase64 from 'components/PostscriptBase64';
import { Icon } from '@tw/ui-components';
import { SalesPlatform } from '@tw/types/module/services';
import OkendoIconBase64 from 'components/Okendo/OkendoBase64';
import SkioIconBase64 from 'components/Skio/SkioIconBase64';
import StackAdaptIconBase64 from 'components/StackAdapt/StackAdapt';
import ApplovinIconBase64 from 'components/Applovin/ApplovinIconBase64';
import AdrollIconBase64 from 'components/Adroll/AdrollIconBase64';
import RedditIconBase64 from 'components/Reddit/RedditIconBase64';
import LinkedinIconBase64 from 'components/LinkedIn/LinkedinIconBase64';
import MailchimpIconBase64 from 'components/Mailchimp/MailchimpIconBase64';
import RakutenIconBase64 from 'components/Rakuten/RakutenIconBase64';
import SendlaneIconBase64 from 'components/Sendlane/SendlaneIconBase64';
export type TWIconProps = React.SVGProps<SVGSVGElement> & {
  title?: string | undefined;
  small?: boolean;
  mspName?: SalesPlatform;
};

export const IconMapper: Record<SummaryMetricsIconKeys, React.FC<TWIconProps>> = {
  pixel: Attribution,
  blended: Blended,
  klaviyo: ({ small }) => <KlaviyoIconBase64 small={small ?? true} />,
  tripleWhale: ({ small }) => <TripleWhaleIconBase64 small={small ?? true} />,
  shopify: ({ small, mspName }) => <SalesPlatformIcon small={small} mspName={mspName} />,
  enquirelabs: ({ small }) => <EnquirelabsIconBase64 small={small ?? true} />,
  googleAds: ({ small }) => <GoogleAdsIconBase64 small={small ?? true} />,
  google: ({ small }) => <GoogleIconBase64 small={small ?? true} />,
  facebook: ({ small }) => <FacebookIconBase64 small={small ?? true} />,
  googleAnalytics: ({ small }) => <GoogleAnalyticsIconBase64 small={small ?? true} />,
  tiktok: ({ small }) => <TiktokIconBase64 small={small ?? true} />,
  pinterest: ({ small }) => <PinterestIconBase64 small={small ?? true} />,
  snapchat: ({ small }) => <SnapchatIconBase64 small={small ?? true} />,
  youtube: ({ small }) => <YoutubeIconBase64 small={small ?? true} />,
  instagram: ({ small }) => <InstagramIconBase64 small={small ?? true} />,
  gorgias: ({ small }) => <GorgiasIconBase64 small={small ?? true} />,
  recharge: ({ small }) => <RechargeIconBase64 small={small ?? true} />,
  attentive: ({ small }) => <AttentiveIconBase64 small={small ?? true} />,
  kno: ({ small }) => <KnoLogoSVG small={small ?? true} />,
  influencers: ({ small }) => <AffluencerIcon width={18} height={18} />,
  benchmarks: () => <MarketingMinor className="darkmode-white-filter" width={18} height={18} />,
  amazon: ({ small }) => <AmazonIconBase64 small={small ?? true} />,
  twitter: ({ small }) => <TwitterIconBase64 small={small ?? true} />,
  microsoft: ({ small }) => <MicrosoftIconSVG small={small ?? true} />,
  customMetrics: ({ small }) => <CustomMetricsIconSVG small={small ?? true} />,
  stripe: ({ small }) => <SalesPlatformIcon small={small} />,
  willy: Willy,
  mountain: ({ small }) => <MountaionIconBase64 small={small ?? true} />,
  criteo: ({ small }) => <CriteoIconBase64 small={small ?? true} />,
  shipstation: ({ small }) => <ShipstationIconBase64 small={small ?? true} />,
  shipbob: ({ small }) => <ShipbobIconBase64 small={small ?? true} />,
  smsbump: ({ small }) => <SmsbumpIconBase64 small={small ?? true} />,
  smsbumpYotpoEmailSMS: ({ small }) => <SmsbumpYotpoEmailSMSIconBase64 small={small ?? true} />,
  smsbumpYotpoReviews: ({ small }) => <SmsbumpYotpoReviewsIconBase64 small={small ?? true} />,
  okendo: ({ small }) => <OkendoIconBase64 small={small ?? true} />,
  postscript: ({ small }) => <PostscriptIconBase64 small={small ?? true} />,
  'blended-metrics': Blended,
  'triple-whale-logo': ({ small }) => <TripleWhaleIconBase64 small={small ?? true} />,
  'facebook-circle': ({ small }) => <FacebookIconBase64 small={small ?? true} />,
  'google-ads': ({ small }) => <GoogleAdsIconBase64 small={small ?? true} />,
  'snapchat-circle': ({ small }) => <SnapchatIconBase64 small={small ?? true} />,
  taboola: ({ small }) => <Icon name="taboola" size={small ? 15 : 20} />,
  outbrain: ({ small }) => <Icon name="outbrain" size={small ? 15 : 20} />,
  linkedin: ({ small }) => <LinkedinIconBase64 small={small ? 15 : 20} />,
  skio: ({ small }) => <Icon name="skio" size={small ? 15 : 20} />,
  bigquery: ({ small }) => <Icon name="bigquery" size={small ? 15 : 20} />,
  applovin: ({ small }) => <ApplovinIconBase64 small={small ?? true} />,
  'custom-msp': ({ small }) => <Icon name="custom-msp" size={small ? 15 : 20} />,
  reddit: ({ small }) => <RedditIconBase64 small={small ?? true} />,
  bigcommerce: ({ small }) => <SalesPlatformIcon small={small} />,
  woocommerce: ({ small }) => <SalesPlatformIcon small={small} />,
  mailchimp: ({ small }) => <MailchimpIconBase64 small={small} />,
  adroll: ({ small }) => <AdrollIconBase64 small={small ?? true} />,
  stackadapt: ({ small }) => <StackAdaptIconBase64 small={small} />,
  rakuten: ({ small }) => <RakutenIconBase64 small={small} />,
  triplewhale: ({ small }) => <TripleWhaleIconBase64 small={small ?? true} />,
  impact: ({ small }) => <Icon name="impact" size={small ? 15 : 20} />,
  sendlane: ({ small }) => <SendlaneIconBase64 small={small} />,
  salesforce: ({ small }) => <SalesPlatformIcon small={small} />,
};
