import './CreativesCockpit.scss';

import amplitude from 'amplitude-js';
import { CreativesCockpitContext } from 'components/CreativesCockpit/context';
import CreativeChart from 'components/CreativesCockpit/CreativeChart/CreativeChart';
import CreativeCockpitHighlights from 'components/CreativesCockpit/CreativeCockpitHighlights/CreativeCockpitHighlights';
import CreativeDashboard from 'components/CreativesCockpit/CreativeDashboard/CreativeDashboard';
import { ShareLink } from 'components/CreativesCockpit/CreativeShare/ShareCreativeLink';
import { columns, CreativeColumn } from 'components/CreativesCockpit/CreativeTable/columns';
import { buildCreativeVideoUrl } from 'components/CreativesCockpit/utils';
import FacebookIconBase64 from 'components/FacebookIconBase64';
import BubbleToggleButton from 'components/library/BubbleToggleButton/BubbleToggleButton';
import TiktokIconBase64 from 'components/TiktokIconBase64';
import { InAppContextBanner } from 'components/InAppContextBanner';
import {
  CreativeTypesElements,
  MAX_ACTIVE_CREATIVES,
  TW_CREATIVE_COLUMNS,
  TW_CREATIVE_PAGE_SIZE,
  TW_CREATIVE_TRENDS_PRIMARY_METRIC,
  TW_CREATIVE_TRENDS_SECONDARY_METRIC,
} from 'constants/creativeCockpit';
import { CHART_COLORS } from 'constants/general';
import allServices from 'constants/services';
import { lightboxClose, lightboxOpen } from 'ducks/actions';
import { $currency, $currentShopId } from '$stores/$shop';
import {
  changeActiveCreativeType,
  changeUseCCNexus,
  createShareDoc,
  getCreatives,
  getCreativeSegments,
  getDataFromFireBase,
  shouldShowSegmentIndicator,
  toggleSharePopup,
} from 'ducks/creativesCockpit/creativesCockpit';
import { tiktokDisconnect } from 'ducks/tiktok';
import firebase from 'firebase/compat/app';
import { useAppDispatch } from 'index';
import React, { FC, useCallback, useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router';
import { type RootState } from 'reducers/RootType';
import { amplitudeTracker } from 'utils/AmplitudeTracker';
import { selectIsTiktokHasCreativeRoles } from 'utils/selectors';
import { AccountConnection, Collapsible, Page, SortDirection, Stack } from '@shopify/polaris';
import {
  CreativeRequest,
  CreativeSegment,
  CreativeTypes,
  CreativeTypesRoles,
  HighlightCreative,
  EntityWithMetrics,
} from '@tw/types';
import { ServicesIds } from '@tw/types/module/services';
import { Card, Flex, ActionIcon, Tabs } from '@tw/ui-components';

import {
  ChartData,
  ChartMode,
  CreativeSegmentClient,
  DashboardView,
  ICreativesCockpitContext,
  SelectableCreative,
  SortingDimension,
} from '../../types/creativeCockpit';
import { creativeReducer, segmentsReducer } from './reducers';
import { MobileCancelMajor } from '@shopify/polaris-icons';
import {
  AllSegments,
  CreateEditCKSegmentModal,
} from 'components/CreativesCockpit/CreativeSegments/createEditSegment';
import {
  AllFilters,
  CreateEditMetricFilterModal,
} from 'components/CreativesCockpit/CreativeSegments/createEditMetricFilter';
import { TabDescriptor } from '@shopify/polaris/build/ts/latest/src/components/Tabs/types';
import { DraggableProvider } from 'components/DraggableProvider/DraggableProvider';
import CreativeCard from 'components/CreativesCockpit/CreativeCard/CreativeCard';
import { facebookConnectOnPress } from '../../ducks/facebook';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { computeFeatureFlags } from 'feature-flag-system';
import { UpgradePageFallBack } from 'feature-flag-system/components';
import { isCreativeVideo } from 'utils/FileChecks';
import _db from 'utils/DB';
import { toast } from 'react-toastify';
import { genericEventLogger } from 'utils/dataLayer';
import { shopIntegrations } from 'ducks/shopIntegrations';
import { useIntegrationsConfig } from '../../routes/integrations/hooks/useIntegrationsConfig';
import { IntegrationWidget } from '../../routes/integrations/components/IntegrationWidget';
import { useStoreValue } from '@tw/snipestate';
import LockedPageTemplate from 'pages/Attribution/LockedPageTemplate';
import firstImage from '../../components/attribution-new/assets/reports.svg';
import secondSectionImage from '../../components/attribution-new/assets/dropdown.png';
import quateImage from '../../components/attribution-new/assets/quote.png';
import logo from '../../components/attribution-new/assets/logo.png';
import { emptyObject } from 'utils/emptyObject';

const getDefaultColumns = (): CreativeColumn[] => {
  let defaultColumns: CreativeColumn[] = [];
  const columnsFromLocalStorage = localStorage.getItem(TW_CREATIVE_COLUMNS);
  if (columnsFromLocalStorage) {
    const columnIds: string[] = JSON.parse(columnsFromLocalStorage);
    defaultColumns = columnIds
      .map((x) => columns.find((column) => column.key === x)!)
      .filter((x) => x);
  } else {
    defaultColumns = columns.filter((x) => x.isDefault);
  }
  return defaultColumns;
};

const getDefaultPageSize = (): number => {
  const pageSizeFromLocalStorage = localStorage.getItem(TW_CREATIVE_PAGE_SIZE);
  if (pageSizeFromLocalStorage) {
    return parseInt(pageSizeFromLocalStorage);
  }
  return 12;
};

type CreativesCockpitProps = {};

const emptyArray = [];

// Move selector logic to a separate function, ideally in a selectors file
const selectDateRange = (state: RootState) => {
  const dateRange = state.mainDatePickerSelectionRange;
  if (!dateRange) return emptyObject<{ start: string; end: string }>();
  return {
    start: dateRange.start.format('YYYY-MM-DD'),
    end: dateRange.end.format('YYYY-MM-DD'),
  };
};

const CreativesCockpitComponent: FC<CreativesCockpitProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const params = useParams<{ serviceId: ServicesIds; assetType: CreativeTypes; docId: string }>();

  //selectors
  const {
    segments: segmentsData,
    activeType,
    sharePopupOpen,
    shareData,
    filters: filtersData,
    chartOpen,
    useCCNexus,
  } = useSelector((state: RootState) => state.creativeCockpit);

  const isTiktokHasCreativeRoles = useSelector(selectIsTiktokHasCreativeRoles);
  const integrations = useIntegrationsConfig();
  const tiktokIntegration = integrations.find((x) => x.serviceId === 'tiktok-ads');
  const accountSegments = useSelector((state: RootState) => state.adSegmentations.segments);

  // Use the extracted selector
  const { start, end } = useSelector(selectDateRange);

  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const shop = useSelector((state: RootState) => state.shop);
  const isLightboxOpen = useSelector((state: RootState) => state.isLightboxOpen);

  // state variables
  const [sortBy, setSortBy] = useState<SortingDimension>('spend');
  const [sortDirection, setSortDirection] = useState<SortDirection>('descending');
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [averages, setAverages] = useState<EntityWithMetrics>();
  const [maximums, setMaximums] = useState<EntityWithMetrics>();
  const [serviceId, setServiceId] = useState<ServicesIds>();
  const [topSection, setTopSection] = useState<'highlights' | 'trends'>('trends');
  const [selectedCreatives, setSelectedCreatives] = useState<SelectableCreative[]>([]);
  const [loadingCreatives, setLoadingCreatives] = useState(false);
  const [loadingSegments, setLoadingSegments] = useState(true);
  const [totalCreativesNumber, setTotalCreativesNumber] = useState<number>(0);
  const [creativeInModal, setCreativeInModal] = useState<SelectableCreative | null>(null);
  const [modalVideoUrl, setModalVideoUrl] = useState<string>('');
  const [selectedColumns, setSelectedColumns] = useState<CreativeColumn[]>(getDefaultColumns());
  const [pageSize, setPageSize] = useState<number>(getDefaultPageSize());
  const [shareUrl, setShareUrl] = useState('');
  const [creatingLink, setCreatingLink] = useState(false);
  const isLoggedIn = useSelector((state: RootState) => state.isLoggedIn);
  const { highlightsCreatives } = useSelector((state: RootState) => state.creativeCockpit);
  const hasPixelInstalled = useSelector((state: RootState) => state.hasPixelInstalled);
  const attribution = useSelector((state: RootState) => state.attribution);
  const includeOneDayView = attribution.includeOneDayView;
  const [sharedHighlightsCreatives, setSharedHighlightsCreatives] =
    useState<HighlightCreative[]>(highlightsCreatives);
  const [shareHasPixelInstalled, setShareHasPixelInstalled] = useState<any>(false);
  const [chartMode, setChartMode] = useState<ChartMode>('line');
  const [chartData, setChartData] = useState<ChartData>('creative');
  const defaultPrimaryMetric = localStorage.getItem(TW_CREATIVE_TRENDS_PRIMARY_METRIC) || 'spend';
  const defaultSecondaryMetric =
    localStorage.getItem(TW_CREATIVE_TRENDS_SECONDARY_METRIC) || 'roas';
  const [primaryMetric, setPrimaryMetric] = useState(defaultPrimaryMetric);
  const [secondaryMetric, setSecondaryMetric] = useState(defaultSecondaryMetric);
  const [showTimeoutError, setShowTimeoutError] = useState('');
  const [dashboardView, setDashboardView] = useState<DashboardView>('card');
  const serviceAccounts = useSelector(shopIntegrations)[serviceId!] || emptyArray;
  // reducers
  const [creatives, creativesDispatch] = useReducer(creativeReducer, []);
  const [segments, segmentsDispatch] = useReducer(segmentsReducer, []);
  const activeCurrency = useStoreValue($currency);

  //local variables
  const isShareMode = window.location.href.includes('share/creative') && params.docId != null;
  const docId = isShareMode ? params.docId : '';
  let query = new URLSearchParams(search);
  const shopIdShare = isShareMode ? query?.get('shopId') || '' : '';
  const tabs: (TabDescriptor & { index: number })[] = Object.values({
    ...CreativeTypesElements,
  })
    .filter((tab) => !tab.hideInCategories)
    .map((type, index) => ({
      id: type.key,
      content: type.label,
      accessibilityLabel: type.key,
      panelID: type.key,
      index,
    }));

  const serviceName = serviceId === 'google-ads' ? 'YouTube' : allServices[serviceId!]?.title || '';

  const initCreatives = (creatives: SelectableCreative[]) =>
    creativesDispatch({ type: 'init', payload: creatives });

  const addCreatives = (creatives: SelectableCreative[]) =>
    creativesDispatch({ type: 'add', payload: creatives });

  const toggleCreativeSelection = (creative: SelectableCreative) => {
    if (!creative.selected && selectedCreatives.length >= MAX_ACTIVE_CREATIVES) {
      return;
    }
    creativesDispatch({ type: 'toggle_select', payload: creative });
    if (creativeInModal?.id === creative.id) {
      setCreativeInModal({ ...creativeInModal, selected: !creativeInModal.selected });
    }
  };

  const toggleCreativeActive = (creative: SelectableCreative) =>
    creativesDispatch({ type: 'toggle_active', payload: creative });

  const toggleSegmentActive = (segment: CreativeSegmentClient) =>
    segmentsDispatch({ type: 'toggle_active', payload: segment });

  const initSegments = (segments: CreativeSegment[]) =>
    segmentsDispatch({ type: 'init', payload: segments });

  const loadCreatives = useCallback(
    async (pageIndex: number, isInit: boolean, isExport: boolean = false) => {
      if (isShareMode && docId) {
        try {
          const { data } = shareData;
          initCreatives((data?.creatives as SelectableCreative[]) || []);
          setAverages(data?.averages || {});
          setMaximums(data?.maximums || {});
          setTotalCreativesNumber(data?.averages?.numberOfAssets || 0);
          initSegments(data?.segments || []);
        } catch (e) {
          console.log("can't load cc report ", e);
        }
      } else {
        if (!start || !end || !serviceId || !activeType) {
          return;
        }

        const segments = Object.values<any>({
          ...accountSegments?.[serviceId],
          ...segmentsData?.[serviceId],
        }).filter((seg: any) => seg.enabled);
        // .map((x) => x.expressionList);

        // eslint-disable-next-line react-hooks/exhaustive-deps
        const accountSegmentsEnabled = Object.values<any>({
          ...accountSegments?.[serviceId],
        }).filter((seg: any) => seg.enabled);
        dispatch(shouldShowSegmentIndicator(accountSegmentsEnabled.length > 0));
        // setShouldShowAdsSegmentIndicator(accountSegmentsEnabled.length > 0);

        const filters = Object.values<any>({
          ...filtersData?.[serviceId],
        }).filter((filter: any) => filter.enabled);

        const granularity = start === end ? 'hour' : 'day';
        const params: CreativeRequest = {
          start,
          end,
          service_id: serviceId,
          data_type: 'ads-metrics',
          account_ids: serviceAccounts.map((x) => `${x.id}`),
          granularity,
          entity: 'ad',
          creative_type: activeType,
          sort_by: sortBy,
          sort_direction: sortDirection,
          page: pageIndex,
          filters,
          segments,
          currency: activeCurrency,
          pixel_attribution_model: attribution.attributionModel,
          pixel_date_model: attribution.dateModel,
          shopDomain: $currentShopId.get()!,
          ...(isExport ? {} : { pageSize: dashboardView === 'card' ? 12 : pageSize }),
          includeOneDayView,
        };

        if (pageIndex === 0) {
          setLoadingCreatives(true);
        }
        setLoadingSegments(true);

        const {
          creatives = [],
          averages = {},
          maximums,
          error = false,
        } = await getCreatives(params);

        if (error && segments.length > 0) {
          setShowTimeoutError(
            'Error requesting data from the server. Our support and development teams have been notified. Meanwhile, try to choose a different Segment.',
          );
        } else {
          setShowTimeoutError('');
        }

        if (isInit) {
          initCreatives(creatives as SelectableCreative[]);
          setAverages(averages);
          setMaximums(maximums);
          setTotalCreativesNumber(averages.numberOfAssets);
        } else {
          addCreatives(creatives as SelectableCreative[]);
        }

        setLoadingCreatives(false);
        const { segments: creativeSegments = [] } = await getCreativeSegments(params);
        setLoadingSegments(false);
        initSegments(creativeSegments);
        if (activeType === 'Segments') {
          setChartData('segment');
          setDashboardView('table');
        }
      }
    },
    [
      isShareMode,
      docId,
      shareData,
      start,
      end,
      serviceId,
      activeType,
      accountSegments,
      segmentsData,
      dispatch,
      filtersData,
      serviceAccounts,
      sortBy,
      sortDirection,
      activeCurrency,
      attribution.attributionModel,
      attribution.dateModel,
      dashboardView,
      pageSize,
      includeOneDayView,
    ],
  );

  const setSharedDataFromFireBase = useCallback(
    async (docId) => {
      amplitude.getInstance().logEvent('Creative Cockpit: Shared page viewed', {
        shopDomain: shopIdShare,
      });
      const { data } = shareData;
      setServiceId(data?.service_id);
      dispatch(changeActiveCreativeType(data?.entity));
      setPageIndex(data?.pageIndex);
      setTopSection(data?.topSection);
      setSharedHighlightsCreatives(data?.sharedHighlightsCreatives);
      setShareHasPixelInstalled(data?.shareHasPixelInstalled);
      setChartMode(data?.chartModeShare);
      setChartData(data?.chartData);
      setPrimaryMetric(data?.primaryMetric);
      setSecondaryMetric(data?.secondaryMetric);
      setDashboardView(data?.dashboardView);
      setSortBy(data?.sortBy);
      setSortDirection(data?.sortDirection);
    },
    [shopIdShare, dispatch, shareData],
  );

  const logIn = useCallback(async () => {
    if (isShareMode) {
      try {
        firebase
          .auth()
          .signInAnonymously()
          .then(async () => {
            console.log('login success..');
            const token = await firebase.auth().currentUser?.getIdToken()!;
            console.log(token);
          })
          .catch((err) => {
            console.log(JSON.stringify(err));
          });
      } catch (e) {
        console.log(JSON.stringify(e));
        // setLoading(false);
      }
    }
  }, [isShareMode]);

  useEffect(() => {
    if (isShareMode && docId) {
      dispatch(getDataFromFireBase(shopIdShare, docId));
    }
  }, [dispatch, docId, isShareMode, shopIdShare]);

  useEffect(() => {
    if (!isLoggedIn) {
      logIn();
    }
  }, [isLoggedIn, logIn]);

  useEffect(() => {
    setSharedHighlightsCreatives(highlightsCreatives);
  }, [highlightsCreatives]);

  const createShareLink = async () => {
    if (!hasPixelInstalled) return;
    setCreatingLink(true);
    const granularity = start === end ? 'hour' : 'day';
    console.log('createShareLink: start');

    let url = `${window.location.origin}/share/creative/`;

    try {
      const data = {
        creatives: creatives,
        averages: averages,
        maximums: maximums,
        segments: segments,
        creativeInModal: creativeInModal,
        shopId: currentShopId,
        sharedHighlightsCreatives: sharedHighlightsCreatives,
        chartModeShare: chartMode,
        chartData: chartData,
        primaryMetric: primaryMetric,
        pageIndex: pageIndex,
        secondaryMetric: secondaryMetric,
        dashboardView: dashboardView,
        start,
        end,
        service_id: serviceId || 'facebook-ads',
        data_type: 'ads-metrics',
        granularity,
        entity: activeType,
        topSection: topSection,
        shareHasPixelInstalled: hasPixelInstalled,
        sortBy,
        sortDirection,
      };
      const { shareDoc } = await createShareDoc(data, currentShopId);
      console.log('shareDoc', shareDoc);
      url += `${shareDoc}?&shopId=${currentShopId}`;
      setShareUrl(url);
      setCreatingLink(false);
      amplitude.getInstance().logEvent('Creative Cockpit: Page shared', {
        shopDomain: shopIdShare,
      });
    } catch (e) {
      toast.error('Error creating share link');
      genericEventLogger('error', {
        message: 'error creating creative-cockpit share link',
        creatives_count: creatives.length,
      });
      console.log('error to create the share link :', e);
    }
  };

  const checkFacebookAdsScopes = () => {
    try {
      const needsPermissionFromFB = !!shop.facebookAdsScopes
        ? shop.facebookAdsScopes.indexOf('pages_read_engagement') === -1
        : true;
      if (needsPermissionFromFB && shop.thereIsNoThumbnailUrl && serviceId === 'facebook-ads')
        return true;
      return false;
    } catch (error) {
      return;
    }
  };

  const handleTabChange = (selectedTabIndex: number) => {
    const types = Object.values({ ...CreativeTypesRoles });
    const index = tabs.findIndex((tab) => tab.index === selectedTabIndex);
    if (types[index] === 'Segments') {
      setTopSection('trends');
      setDashboardView('table');
      setChartData('segment');
      setTotalCreativesNumber(segments?.length);
    }
    navigate({
      pathname: `${
        pathname.includes('dashboards') ? '/dashboards' : ''
      }/creative-cockpit/${serviceId}/${types[index]}`,
      search,
    });
    amplitude
      .getInstance()
      .logEvent('Creative Cockpit: Creative Type Selected', { creativeType: types[index] });
    dispatch(changeActiveCreativeType(types[index]));
    setPageIndex(0);
  };

  const getId = (creative) => {
    if (!creative) return;
    if (creative.assetType !== 'video') {
      return creative.thumbnail?.split('/')?.pop()?.split('.')?.[0];
    }
    return creative.id;
  };

  useEffect(() => {
    const id = getId(creativeInModal);
    const url = buildCreativeVideoUrl(serviceId!, id);
    setModalVideoUrl(url);
  }, [serviceId, creativeInModal]);

  useEffect(() => {
    loadCreatives(0, true);
  }, [loadCreatives]);

  useEffect(() => {
    const serviceId = params.serviceId;
    const assetType = params.assetType;
    const docId = params.docId;
    const { pathname, search } = window.location;

    // if is not creative cockpit page, then return
    if (!pathname.includes('creative-cockpit')) {
      return;
    }

    if (isShareMode && docId) {
      // setDocId(docId);
      setSharedDataFromFireBase(docId);
      return;
    }

    if (serviceId && allServices[serviceId] && allServices[serviceId]?.hasCreative) {
      setServiceId(serviceId);
    } else {
      const s: ServicesIds = 'facebook-ads';
      const t: CreativeTypes = 'ad';

      navigate({
        pathname: `${
          pathname.includes('dashboards') ? '/dashboards' : ''
        }/creative-cockpit/${s}/${t}`,
        search,
      });

      setServiceId(s);
    }

    if (assetType && CreativeTypesRoles[assetType]) {
      dispatch(changeActiveCreativeType(assetType));
    } else {
      const t: CreativeTypes = 'ad';
      if (serviceId) {
        navigate({
          pathname: `${
            pathname.includes('dashboards') ? '/dashboards' : ''
          }/creative-cockpit/${serviceId}/${t}`,
          search,
        });
      }
      dispatch(changeActiveCreativeType(t));
    }
  }, [
    params.serviceId,
    params.assetType,
    params.docId,
    navigate,
    isShareMode,
    shopIdShare,
    setSharedDataFromFireBase,
    dispatch,
  ]);

  useEffect(() => {
    if (pageIndex === 0) return;
    loadCreatives(pageIndex, false);
    // Don't do it on initial or any other change than pageIndex
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  useEffect(() => {
    const selected = creatives.filter((c) => c.selected);
    selected.forEach((creative, i) => (creative.color = CHART_COLORS[i]));
    setSelectedCreatives(selected);
  }, [creatives]);

  useEffect(() => {
    if (
      creativeInModal &&
      (isCreativeVideo(creativeInModal) || creativeInModal.assetType === 'image')
    ) {
      dispatch(
        lightboxOpen({
          resources: [
            {
              url: isCreativeVideo(creativeInModal)
                ? modalVideoUrl
                : creativeInModal.image || creativeInModal.thumbnail,
              type: isCreativeVideo(creativeInModal) ? 'video' : 'photo',
              altTag: isCreativeVideo(creativeInModal) ? '' : creativeInModal.name,
              thumbnail: creativeInModal.thumbnail,
              extraData: {
                serviceId: serviceId,
                assetId: getId(creativeInModal),
                accountId: creativeInModal.account_id,
              },
            },
          ],
        }),
      );
    } else if (!creativeInModal) dispatch(lightboxClose());
  }, [creativeInModal, dispatch, modalVideoUrl, serviceId]);

  useEffect(() => {
    if (!isLightboxOpen.open) {
      setCreativeInModal(null);
    }
  }, [isLightboxOpen]);

  const contextProps: ICreativesCockpitContext = {
    creatives,
    segments,
    selectedCreatives,
    averages,
    maximums,
    sortDirection,
    sortBy,
    pageIndex,
    type: activeType!,
    serviceId: serviceId!,
    loadingCreatives,
    loadingSegments,
    totalCreativesNumber,
    selectedColumns,
    currency: activeCurrency,
    setSelectedColumns,
    toggleCreativeSelection,
    toggleCreativeActive,
    toggleSegmentActive,
    setSortBy,
    setSortDirection,
    setPageIndex,
    setCreativeInModal,
    chartMode,
    setChartMode,
    chartData,
    setChartData,
    primaryMetric,
    setPrimaryMetric,
    secondaryMetric,
    setSecondaryMetric,
    dashboardView,
    setDashboardView,
    isShareMode: isShareMode,
    shareHasPixelInstalled,
    serviceName,
    setPageSize,
    loadCreatives,
    pageSize,
    creativeInModal,
  };
  const highlightsDisabled = activeType === 'Segments' ? 'disabled' : '';
  return (
    <CreativesCockpitContext.Provider value={contextProps}>
      {creativeInModal &&
        !isCreativeVideo(creativeInModal) &&
        creativeInModal.assetType !== 'image' && (
          <DraggableProvider
            usingDragger={false}
            initialPosition="950 center"
            className="draggable-card scroll-smooth
              cursor-grab overflow-scroll shadow-slate-500 shadow-lg bg-white rounded-lg sm:w-[25vw] w-[30vw] h-[80vh] max-w-[40vw]"
          >
            <div
              className="absolute right-0 top-0 p-4 z-10 bg-white/40 rounded flex cursor-pointer"
              onClick={() => {
                setCreativeInModal(null);
              }}
            >
              <MobileCancelMajor className="w-8 h-8 fill-primary" />
            </div>
            <CreativeCard creative={creativeInModal} />
          </DraggableProvider>
        )}
      <Page fullWidth data-testid="CreativesCockpit">
        {checkFacebookAdsScopes() && (
          <div className="popup-reconnect">
            <AccountConnection
              accountName=""
              connected={false}
              action={{
                content: 'Connect to Meta',
                onAction: facebookConnectOnPress,
              }}
              title={
                <div className="flex gap-6.5 items-center">
                  <FacebookIconBase64 small={true} />
                  Please reconnect Meta to see more creative thumbnails
                </div>
              }
            />
          </div>
        )}
        <InAppContextBanner page="creativeCockpit" />
        <div className="creatives-cockpit">
          {serviceId === 'tiktok-ads' && !isTiktokHasCreativeRoles && (
            <IntegrationWidget {...tiktokIntegration!} />
          )}
          <Card padding="sm" radius="md">
            <Card.Section px="md" pb="sm">
              <Flex gap="sm">
                {hasPixelInstalled && (
                  <div>
                    <ActionIcon
                      id="att-creative-cockpit-share-button"
                      size="lg"
                      radius="sm"
                      onClick={() => {
                        dispatch(toggleSharePopup(true));
                        createShareLink();
                      }}
                      disabled={isShareMode}
                      variant="activator"
                      icon="share-minor"
                      iconSize={20}
                    />
                    <ShareLink
                      isOpen={sharePopupOpen}
                      type={'facebook-ads'}
                      shareUrl={shareUrl}
                      creatingLink={creatingLink}
                    />
                  </div>
                )}
                <div>
                  <AllSegments
                    plain={false}
                    onApply={() => {
                      loadCreatives(0, true);
                    }}
                    isShareMode={isShareMode}
                  />
                  <CreateEditCKSegmentModal
                    handleNew={() => {
                      loadCreatives(0, true);
                    }}
                  />
                </div>
                <div>
                  <AllFilters
                    plain={false}
                    onApply={() => {
                      loadCreatives(0, true);
                    }}
                  />
                  <CreateEditMetricFilterModal
                    handleNew={() => {
                      loadCreatives(0, true);
                    }}
                  />
                </div>
              </Flex>
            </Card.Section>

            <Card.Section p="md" withBorder>
              <Stack vertical>
                <Stack.Item>
                  <Stack alignment="center">
                    <Stack.Item fill>
                      <Tabs value={activeType}>
                        <Tabs.List className={isShareMode ? 'share-mode' : ''}>
                          {tabs.map((tab) => (
                            <Tabs.Tab
                              key={tab.id}
                              value={tab.id}
                              onClick={() => handleTabChange(tab.index)}
                            >
                              {tab.content}
                            </Tabs.Tab>
                          ))}
                        </Tabs.List>
                      </Tabs>
                    </Stack.Item>
                    <Stack.Item>
                      <Stack>
                        <Stack.Item>
                          <div
                            className={`${highlightsDisabled} ${isShareMode ? 'share-mode' : ''}`}
                          >
                            <BubbleToggleButton
                              onClick={() => {
                                if (activeType !== 'Segments') {
                                  setTopSection('highlights');
                                  amplitudeTracker('Creative Cockpit: Highlights Clicked');
                                }
                              }}
                              active={topSection === 'highlights'}
                              label="Creative Highlights"
                              id="tr-create-cockpit-creative-highlights"
                            />
                          </div>
                        </Stack.Item>
                        <Stack.Item>
                          <div className={`${isShareMode ? 'share-mode' : ''}`}>
                            <BubbleToggleButton
                              onClick={() => {
                                setTopSection('trends');
                                amplitudeTracker('Creative Cockpit: Trends Clicked');
                              }}
                              active={topSection === 'trends'}
                              label="Trends"
                              id="tr-create-cockpit-trends"
                            />
                          </div>
                        </Stack.Item>
                      </Stack>
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
                {showTimeoutError && (
                  <Stack.Item>
                    <span>{showTimeoutError}</span>
                  </Stack.Item>
                )}
                <Stack.Item>
                  {topSection === 'highlights' && (
                    <CreativeCockpitHighlights
                      isShareMode={isShareMode}
                      sharedHighlightsCreatives={sharedHighlightsCreatives}
                    />
                  )}
                  <div className="creative-cockpit-chart-wrapper group relative">
                    <Collapsible
                      open={topSection === 'trends' && chartOpen}
                      id="attribution-collapsible"
                    >
                      <CreativeChart />
                    </Collapsible>
                  </div>
                </Stack.Item>
              </Stack>
            </Card.Section>
            <Card.Section p="md">
              <CreativeDashboard isShareMode={isShareMode} />
            </Card.Section>
          </Card>
        </div>
      </Page>
    </CreativesCockpitContext.Provider>
  );
};

const CreativesCockpit = computeFeatureFlags<CreativesCockpitProps>(
  FeatureFlag.CC_CREATIVE_ANALYSIS_FF,
  CreativesCockpitComponent,
  () => (
    <LockedPageTemplate
      badgeText="Pixel"
      bannerPageName="creativeCockpit"
      mainTitle="First-party data you can trust"
      mainDescription="Harness the power of first-party data with the Triple Pixel — providing more accurate attribution to fuel profitable growth."
      descriptionMaxWidth="400px"
      firstImage={firstImage}
      secondTitle="Models for every strategy"
      secondSectionImage={secondSectionImage}
      sections={[
        {
          subtitle: 'End-to-end customer journey tracking',
          description:
            'Capture every customer interaction with our advanced tracking technology, ensuring no opportunity is missed.',
        },
        {
          subtitle: 'Recognize new vs returning customers',
          description:
            'Triple Whale’s pixel makes it simple to identify new versus returning visitors and optimize campaigns for each.',
        },
        {
          subtitle: 'Understand the Total Impact of your marketing',
          description:
            'Combine multi-touch attribution and post-purchase survey data using our Total Impact attribution model — for a holistic understanding of your marketing mix.',
        },
      ]}
      secondSectionTitleMargin="40px"
      quote="At MaryRuth’s, we are big advocates of Total Impact. Not just because it made the numbers look better, but because it really aligned - I can’t say that enough - with everything we were intuitively and subconsciously knowing. We knew things the data wasn’t showing. We knew the reason it wasn’t showing is because the data is so complex and the way we measured was too simple. It really helped us."
      quoteAuthor="Wyatt Lowe"
      quoteAuthorTitle="Director of Digital Growth, MaryRuth’s"
      logo={logo}
      quateImage={quateImage}
    />
    // <UpgradePageFallBack
    //   InAppContextBannerEnabled={true}
    //   InAppContextBannerPage={'creativeCockpit'}
    //   featureFlag={FeatureFlag.CC_CREATIVE_ANALYSIS_FF}
    //   description="Analysis on the creative ID level"
    // />
  ),
);

export default CreativesCockpit;
