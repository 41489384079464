import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { TW_LS_ATTRIBUTION_WINDOW, TW_SUMMARY_PIXEL_COLUMNS } from 'constants/attribution';
import { ColumnsSelector } from './library/TWTable/ColumnSelector';
import { Columns3Major } from '@shopify/polaris-icons';
import { useContext } from 'react';
import { SummaryContext } from 'ducks/summary';
import AttributionModelPicker from './attribution-new/AttributionModelPicker';
import { AllServicesIds } from 'types/attribution';
import AttributionDateModelPicker from './attribution-new/AttributionDateModelPicker';
import AttributionWindowPicker from './attribution-new/AttributionWindowPicker';
import { useAttributionActiveSource } from 'utils/useAttributionActiveSource';
import { useAttributionActivePage } from 'utils/useAttributionActivePage';
import { SourceTypesWithExtra } from 'types/services';
import useAllPixelColumns from './attribution-new/columns';

const PixelSummaryFilterBar: React.FC = () => {
  const { pixelColumns, setPixelColumns } = useContext(SummaryContext);
  const sourceCategory = useAttributionActivePage();
  const activeSource = useAttributionActiveSource();
  const allPixelColumns = useAllPixelColumns();

  return (
    <div className="flex items-center gap-4 flex-wrap">
      <div className="flex items-center gap-4">
        <AttributionModelPicker
          type={sourceCategory as SourceTypesWithExtra}
          sourceId={activeSource as AllServicesIds | 'tw_referrer'}
        />
        <AttributionWindowPicker />
      </div>
      <div className="flex items-center gap-4">
        <AttributionDateModelPicker />
        <div className="ml-auto flex items-center">
          <ColumnsSelector
            columns={allPixelColumns}
            selectedColumns={pixelColumns!}
            setSelectedColumns={setPixelColumns!}
            icon="columns-3-major"
            storageKey={TW_SUMMARY_PIXEL_COLUMNS}
            title={
              <span className="flex items-center gap-2">
                <Columns3Major width={20} height={20} />
                <span>Select Columns</span>
              </span>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default PixelSummaryFilterBar;
