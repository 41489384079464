import { Text, TextInput, ActionIcon, Menu, Button, Switch, Icon } from '@tw/ui-components';
import { WorkflowStepSendToWebhook } from '../types/willyTypes';
import { useCallback, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useStoreValue } from '@tw/snipestate';
import { $currentShopId } from '$stores/$shop';
import { Prism as ReactSyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { isValidUrl } from '../utils/isValidUrl';

type FlowSendToWebhookStepProps = {
  step: WorkflowStepSendToWebhook;
  stepChange: (url: string, headers: { id: string; key: string; value: string }[]) => void;
  readOnly: boolean;
  sequenceId: string;
  setIsPristine: (isPristine: boolean) => void;
};

export const FlowSendToWebhookStep: React.FC<FlowSendToWebhookStepProps> = ({
  step,
  stepChange,
  readOnly,
  sequenceId,
  setIsPristine,
}) => {
  const currentShopId = useStoreValue($currentShopId);
  const [previewLanguage, setPreviewLanguage] = useState<'javascript' | 'go' | 'python' | null>(
    'javascript',
  );
  const [showPreviewPayload, setShowPreviewPayload] = useState(false);
  const [urlError, setUrlError] = useState<string | null>(null);
  const preview = useMemo(() => {
    if (!previewLanguage) {
      return '';
    }

    return createWebhookRequestPreview(previewLanguage, step.url, currentShopId, sequenceId);
  }, [step.url, currentShopId, sequenceId, previewLanguage]);

  return (
    <div className="flex flex-col justify-between gap-6">
      <div className="flex flex-col gap-4">
        <Text>URL</Text>
        <TextInput
          disabled={readOnly}
          value={step.url}
          onChange={(e) => {
            setIsPristine(false);
            stepChange(e, step.headers);
            if (isValidUrl(e)) {
              setUrlError(null);
            } else {
              setUrlError('Invalid URL');
            }
          }}
          type="url"
          error={urlError}
        />
      </div>

      <div className="flex flex-col gap-4">
        <div className="flex gap-2 items-center">
          <ActionIcon
            icon="plus"
            disabled={readOnly}
            onClick={() => {
              const newHeaders = [...step.headers, { id: uuidv4(), key: '', value: '' }];
              stepChange(step.url, newHeaders);
              setIsPristine(false);
            }}
          />
          <Text>Custom Headers</Text>
        </div>
        <FlowSendToWebhookStepHeaders
          readOnly={readOnly}
          headers={step.headers}
          onChange={(headers) => stepChange(step.url, headers)}
          onRemove={(id) => {
            const newHeaders = step.headers.filter((header) => header.id !== id);
            stepChange(step.url, newHeaders);
            setIsPristine(false);
          }}
        />

        <div className="flex items-center gap-4 switch-root">
          <Switch
            label="Preview Request"
            checked={!!previewLanguage}
            onChange={(event) => {
              setPreviewLanguage(event.currentTarget.checked ? 'javascript' : null);
              setShowPreviewPayload(false);
            }}
          />
          <Switch
            label="Preview Payload"
            checked={showPreviewPayload}
            onChange={(event) => {
              setShowPreviewPayload(event.currentTarget.checked);
              setPreviewLanguage(null);
            }}
          />

          {!!previewLanguage && (
            <Menu position="bottom-start">
              <Menu.Target>
                <div>
                  <Button variant="white" leftSection={<Icon name="code-bracket" />}>
                    {previewLanguage === 'javascript'
                      ? 'Node (Express)'
                      : previewLanguage === 'go'
                        ? 'Go (Native)'
                        : 'Python (http.client)'}
                  </Button>
                </div>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item onClick={() => setPreviewLanguage('javascript')}>
                  Node (Express)
                </Menu.Item>
                <Menu.Item onClick={() => setPreviewLanguage('go')}>Go (Native)</Menu.Item>
                <Menu.Item onClick={() => setPreviewLanguage('python')}>
                  Python (http.client)
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          )}
        </div>
        {showPreviewPayload && (
          <ReactSyntaxHighlighter
            language="bash"
            wrapLines
            wrapLongLines
            style={vscDarkPlus}
            showLineNumbers
            customStyle={{
              whiteSpace: 'pre-wrap',
              borderRadius: '8px',
              width: '650px',
            }}
          >
            {createWebhookCurlPreview(step.url, step.headers, currentShopId, sequenceId)}
          </ReactSyntaxHighlighter>
        )}

        <div className="w-full preview-webhook-step">
          {!!previewLanguage && (
            <ReactSyntaxHighlighter
              language={previewLanguage}
              wrapLines
              wrapLongLines
              style={vscDarkPlus}
              showLineNumbers
              customStyle={{
                // maxHeight: '600px',
                // overflow: 'auto',
                whiteSpace: 'pre-wrap',
                borderRadius: '8px',
                width: '650px',
              }}
            >
              {preview}
            </ReactSyntaxHighlighter>
          )}
        </div>
      </div>
    </div>
  );
};

export function createWebhookRequestPreview(
  language: 'javascript' | 'go' | 'python',
  url: string,
  shopId: string | null,
  workflowId: string,
) {
  if (language === 'javascript') {
    return `const express = require('express');
const axios = require('axios');

const app = express();

app.post('${url}', async (req, res) => {
  const data = JSON.parse(req.body);
  const headers = req.headers;
    
  if (headers['X-Tw-Workflow-Id'] !== '${workflowId}') {
    return res.status(403).send("This is not the agent I'm looking for");
  }

  console.log(headers);
  console.log(data);

  res.send('ok');
});

app.listen(3000, () => {
  console.log('Server is running on port 3000');
});
  
`;
  } else if (language === 'go') {
    return `package main

import (
	"encoding/json"
	"fmt"
	"io/ioutil"
	"log"
	"net/http"
)

const (
	url = "${url}"
	workflowId = "${workflowId}"
)

func handler(w http.ResponseWriter, r *http.Request) {
	if r.Method != http.MethodPost {
		http.Error(w, "Invalid request method", http.StatusMethodNotAllowed)
		return
	}

	body, err := ioutil.ReadAll(r.Body)
	defer r.Body.Close()
	if err != nil {
		http.Error(w, "Unable to read request body", http.StatusBadRequest)
		return
	}

	var data interface{}
	if err := json.Unmarshal(body, &data); err != nil {
		http.Error(w, "Invalid JSON", http.StatusBadRequest)
		return
	}

	headers := r.Header

	if headers.Get("X-Tw-Workflow-Id") != workflowId {
		http.Error(w, "This is not the agent I'm looking for", http.StatusForbidden)
		return
	}

	fmt.Println("Headers:", headers)
	fmt.Println("Data:", data)

	w.Write([]byte("ok"))
}

func main() {
	http.HandleFunc(url, handler)

	fmt.Println("Server is running on port 3000")
	if err := http.ListenAndServe(":3000", nil); err != nil {
		log.Fatal(err)
	}
}

`;
  } else if (language === 'python') {
    return `from http.server import BaseHTTPRequestHandler, HTTPServer
import json

url = '${url}'
workflowId = '${workflowId}'

class MyHandler(BaseHTTPRequestHandler):
    def do_POST(self):
        if self.path != url:
            self.send_response(404)
            self.end_headers()
            self.wfile.write(b'Not Found')
            return

        content_length = int(self.headers.get('Content-Length', 0))
        body = self.rfile.read(content_length)

        try:
            data = json.loads(body)
        except json.JSONDecodeError:
            self.send_response(400)
            self.end_headers()
            self.wfile.write(b'Invalid JSON')
            return

        headers = self.headers

        if headers.get('X-Tw-Workflow-Id') != '${workflowId}':
            self.send_response(403)
            self.end_headers()
            self.wfile.write(b"This is not the agent I'm looking for")
            return

        print('Headers:', dict(headers))
        print('Data:', data)

        self.send_response(200)
        self.end_headers()
        self.wfile.write(b'ok')

def run(server_class=HTTPServer, handler_class=MyHandler, port=3000):
    server_address = ('', port)
    httpd = server_class(server_address, handler_class)
    print(f'Server is running on port {port}')
    httpd.serve_forever()

if __name__ == '__main__':
    run()

`;
  } else {
    return 'Language not supported';
  }
}

export function createWebhookCurlPreview(
  url: string,
  headers: { id: string; key: string; value: string }[],
  shopId: string | null,
  sequenceId: string,
) {
  const requiredHeaders = [
    { key: 'Content-Type', value: 'application/json' },
    { key: 'X-Tw-Topic', value: 'workflow/run' },
    { key: 'X-Tw-Id', value: shopId },
    { key: 'X-Tw-Workflow-Id', value: sequenceId },
    { key: 'X-Tw-Run-Id', value: '<Your run id>' },
    { key: 'X-Tw-Trigger-At', value: new Date().toISOString() },
  ];

  const allHeaders = [...requiredHeaders, ...headers];
  return `curl -X POST "${url}" \\\n -H ${allHeaders
    .map((header) => `"${header.key}: ${header.value}"`)
    .join(' \\\n -H ')}`;
}

type FlowSendToWebhookStepHeadersProps = {
  readOnly: boolean;
  headers: { id: string; key: string; value: string }[];
  onChange: (headers: { id: string; key: string; value: string }[]) => void;
  onRemove: (id: string) => void;
};

export const FlowSendToWebhookStepHeaders: React.FC<FlowSendToWebhookStepHeadersProps> = ({
  readOnly,
  headers,
  onChange,
  onRemove,
}) => {
  const handleHeaderKeyChange = useCallback(
    (id: string, key: string) => {
      onChange(
        headers.map((header) => {
          if (header.id === id) {
            return { ...header, key };
          }
          return header;
        }),
      );
    },
    [headers, onChange],
  );

  const handleHeaderValueChange = useCallback(
    (id: string, value: string) => {
      onChange(
        headers.map((header) => {
          if (header.id === id) {
            return { ...header, value };
          }
          return header;
        }),
      );
    },
    [headers, onChange],
  );

  const handleRemove = useCallback(
    (id: string) => {
      onRemove(id);
    },
    [onRemove],
  );

  return (
    <div className="flex flex-col gap-2">
      {headers?.map(({ id, key, value }) => (
        <FlowSendToWebhookStepHeader
          key={id}
          id={id}
          k={key}
          v={value}
          readOnly={readOnly}
          onChangeKey={(key) => handleHeaderKeyChange(id, key)}
          onChangeValue={(value) => handleHeaderValueChange(id, value)}
          onRemove={handleRemove}
        />
      ))}
    </div>
  );
};

type FlowSendToWebhookStepHeaderProps = {
  id: string;
  k: string;
  v: string;
  readOnly: boolean;
  onChangeKey: (key: string) => void;
  onChangeValue: (value: string) => void;
  onRemove: (key: string) => void;
};

export const FlowSendToWebhookStepHeader: React.FC<FlowSendToWebhookStepHeaderProps> = ({
  id,
  k,
  v,
  readOnly,
  onChangeKey,
  onChangeValue,
  onRemove,
}) => {
  return (
    <div className="flex gap-2 items-center">
      <ActionIcon icon="minus" onClick={() => onRemove(id)} disabled={readOnly} />
      <TextInput value={k} onChange={(e) => onChangeKey(e)} disabled={readOnly} />
      <TextInput value={v} onChange={(e) => onChangeValue(e)} disabled={readOnly} />
    </div>
  );
};
