import { type AppDispatch } from 'index';
import moment from '@tw/moment-cached';
import { toast } from 'react-toastify';
import { type RootState } from 'reducers/RootType';
import { combineReducers, Reducer } from 'redux';
import axiosInstance from 'utils/axiosInstance';
import { services, ServicesIds } from '@tw/types/module/services';
import { INIT_SHOP } from 'ducks/constants';
import { MetricsFilterExpression } from '@tw/types/module/types/MetricsQueryStringParams';
import { ProductAnalyticsParams } from '@tw/types/module/services/insights/productAnalytics';
import { StatRequest } from '@tw/types/module/types/Attribution';
import { uniqBy } from 'lodash';
import { ProductEntityType } from 'types/productAnalytics';
import { genericEventLogger, analyticsEvents, productAnalyticsActions } from 'utils/dataLayer';
import {
  FilterProperty,
  FilterPropertyCategory,
  InsightsFilterQuery,
} from '@tw/types/module/services/insights';
import { DatePickerTimePeriods } from 'components/useDatePickerSelectedOptions';
import { $shopCurrency } from '../$stores/$shop';
import { getPropertyCategory } from 'components/Insights/Filters/constants';
import { Dialect } from '@tw/types';

export const GET_PRODUCTS_COUNT = 'GET_PRODUCTS_COUNT';
export const PRODUCTS = 'PRODUCTS';
export const PRODUCT_SKUS = 'PRODUCT_SKUS';
export const PRODUCT_IDS = 'PRODUCT_IDS';
export const SKU_IDS = 'SKU_IDS';
export const COMPARISON_PRODUCTS = 'COMPARISON_PRODUCTS';
export const COMPARISON_PRODUCT_SKUS = 'COMPARISON_PRODUCT_SKUS';
export const AD_STATS = 'AD_STATS';
export const PRODUCT_SKUS_AD_STATS = 'PRODUCT_SKUS_AD_STATS';
export const AD_IMGS = 'AD_IMGS';
export const PRODUCT_SKUS_AD_IMGS = 'PRODUCT_SKUS_AD_IMGS';
export const COMPARISON_AD_STATS = 'COMPARISON_AD_STATS';
export const IS_LOADING_PRODUCTS = 'IS_LOADING_PRODUCTS';
export const IS_LOADING_PRODUCT_SKUS = 'IS_LOADING_PRODUCT_SKUS';
export const IS_LOADING_PRODUCT_SKUS_COMPARISONS = 'IS_LOADING_PRODUCT_SKUS_COMPARISONS';
export const IS_LOADING_PRODUCT_SKUS_ADS = 'IS_LOADING_PRODUCT_SKUS_ADS';
export const IS_LOADING_ADS = 'IS_LOADING_ADS';
export const IS_LOADING_PRODUCT_COMPARISONS = 'IS_LOADING_PRODUCT_COMPARISONS';
export const IS_LOADING_ADS_COMPARISONS = 'IS_LOADING_ADS_COMPARISONS';
export const GET_PRODUCTS_FORECAST = 'GET_PRODUCTS_FORECAST';
export const ITEM_TYPE = 'ITEM_TYPE';
export const ROWS_PER_PAGE = 'ROWS_PER_PAGE';
export const CHART_OPEN = 'CHART_OPEN';
export const PRODUCT_UPDATED = 'PRODUCT_UPDATED';
export const SEARCH_TERM_CHANGED = 'SEARCH_TERM_CHANGED';
export const SET_SKU_PROD_ID = 'SET_SKU_PROD_ID';
export const TOGGLE_SELECTED_PRODUCT = 'TOGGLE_SELECTED_PRODUCT';
export const TOGGLE_ACTIVE_PRODUCT = 'TOGGLE_ACTIVE_PRODUCT';
export const TOGGLE_INCLUDE_FREE_PRODUCTS = 'TOGGLE_INCLUDE_FREE_PRODUCTS';
export const TOGGLE_INCLUDE_ACTIVE_PRODUCTS_ONLY = 'TOGGLE_INCLUDE_ACTIVE_PRODUCTS_ONLY';
export const PA_DIALECT = 'TOGGLE_DIALECT';

export const toggleSelectedProduct = (creative: any) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { currentShopId } = getState();
    genericEventLogger(analyticsEvents.PRODUCT_ANALYTICS, {
      action: !creative.selected
        ? productAnalyticsActions.SELECT_CREATIVE
        : productAnalyticsActions.DESELECT_CREATIVE,
      shop: currentShopId,
      type: creative.assetType,
      handle: creative.handle,
      name: creative.name,
    });

    dispatch({
      type: TOGGLE_SELECTED_PRODUCT,
      payload: creative.id,
    });
  };
};

export const toggleActiveProduct = (productId: string) => ({
  type: TOGGLE_ACTIVE_PRODUCT,
  payload: productId,
});

export const getProductAdStats = ({
  productIds = [],
  itemType = 'products',
  isCompare = false,
  skuProductId = '',
  paFilters = [] as InsightsFilterQuery[],
  ads = undefined,
  dialect = 'bigquery',
}) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      currentShopId,
      mainDatePickerSelectionRange,
      datesToCompare: { start: compareStart, end: compareEnd },
      shopTimezone,
      shop,
      groupStatsBy,
      subscription: { features },
    } = getState();
    const startDate = moment(compareStart);
    const endDate = moment(compareEnd);
    const granularity = groupStatsBy as 'day' | 'hour' | 'month' | 'week';
    try {
      if (!mainDatePickerSelectionRange) {
        return;
      }
      if (!features.includes('PIXEL')) {
        dispatch({
          type: isCompare
            ? IS_LOADING_ADS_COMPARISONS
            : skuProductId
              ? IS_LOADING_PRODUCT_SKUS_ADS
              : IS_LOADING_ADS,
          payload: false,
        });
        dispatch({
          type: isCompare ? COMPARISON_AD_STATS : skuProductId ? PRODUCT_SKUS_AD_STATS : AD_STATS,
          payload: [],
        });
        return;
      }

      const { start, end } = mainDatePickerSelectionRange;
      dispatch({
        type: isCompare
          ? IS_LOADING_ADS_COMPARISONS
          : skuProductId
            ? IS_LOADING_PRODUCT_SKUS_ADS
            : IS_LOADING_ADS,
        payload: true,
      });
      dispatch({
        type: isCompare ? COMPARISON_AD_STATS : skuProductId ? PRODUCT_SKUS_AD_STATS : AD_STATS,
        payload: [],
      });

      let serviceIds: ServicesIds[] = [
        'facebook-ads',
        'google-ads',
        'tiktok-ads',
        'snapchat-ads',
        'pinterest-ads',
      ];
      const attrFilters = paFilters.filter((filter) =>
        filter.filter(
          (f) => getPropertyCategory(f.property) === FilterPropertyCategory.ATTRIBUTION,
        ),
      );

      if (attrFilters.length) {
        const sourceFilter = attrFilters.find((filter) =>
          filter.some((f) => f.property === 'source'),
        );
        if (sourceFilter) {
          serviceIds = [];
          attrFilters.forEach((filter) => {
            filter.forEach((f) => {
              switch (f.property) {
                case 'source':
                  const values = Array.isArray(f.value) ? f.value : [f.value];
                  if (['equal', 'is_in'].includes(f.comparator) && values) {
                    values.forEach((value) => {
                      serviceIds.push(value as ServicesIds);
                    });
                  } else if (['not_equal', 'is_not_in'].includes(f.comparator) && values) {
                    serviceIds = serviceIds.length
                      ? serviceIds
                      : [
                          'facebook-ads',
                          'google-ads',
                          'tiktok-ads',
                          'snapchat-ads',
                          'pinterest-ads',
                        ];
                    values.forEach((value) => {
                      serviceIds = serviceIds.filter((x) => x !== value);
                    });
                  }
              }
            });
          });
        }
      }

      const accountIds = serviceIds
        .map((serviceId) => ({
          serviceId,
          accountIds: services[serviceId]?.getAccounts(shop)?.map((account) => account.id) || [],
        }))
        .filter((x) => x.accountIds.length)
        .reduce(
          (acc, x) => ({
            ...acc,
            [x.serviceId]: x.accountIds,
          }),
          {} as Record<ServicesIds, string[]>,
        );

      const filters = serviceIds.reduce(
        (acc, serviceId) => ({
          ...acc,
          [serviceId]: [],
        }),
        {} as Record<ServicesIds, MetricsFilterExpression[][]>,
      );

      const params: StatRequest & {
        productIds: string[] | undefined;
        variantIds: string[] | undefined;
        attrFilters: InsightsFilterQuery[];
        useClickhouse: boolean;
      } = {
        shopDomain: currentShopId,
        model: 'lastPlatformClick-v2',
        startDate: isCompare ? startDate.format() : moment(start).tz(shopTimezone).format(),
        endDate: isCompare
          ? endDate.endOf('day').format()
          : moment(end).tz(shopTimezone).endOf('day').format(),
        dateModel: 'eventDate',
        breakdown: 'adId',
        accountIds,
        currency: $shopCurrency.get(),
        includeOneDayFacebookView: false,
        granularity,
        sources: serviceIds,
        timezone: shopTimezone,
        filters,
        productIds: itemType === 'products' ? productIds?.filter((x) => x) : undefined,
        variantIds: itemType === 'variants' ? productIds?.filter((x) => x) : undefined,
        attrFilters,
        useClickhouse: dialect === 'clickhouse',
      };
      const res = await axiosInstance.post(`/v2/attribution/get-product-stats`, params);
      dispatch({
        type: isCompare ? COMPARISON_AD_STATS : skuProductId ? PRODUCT_SKUS_AD_STATS : AD_STATS,
        payload: res.data?.productStats ?? [],
      });

      if (!ads) {
        ads =
          res.data?.productStats?.map((x: any) => ({
            productId: x.productId,
            ads: x.ads,
          })) || [];
      }

      const adImages =
        (ads || ([] as any))?.flatMap((ad) => {
          const { productId, ads } = ad || {};
          return (
            ads?.map((ad) => {
              const { imageUrl, name, serviceId, adId } = ad || {};
              return {
                src: imageUrl ?? '',
                alt: name ?? adId ?? '',
                id: adId ?? '',
                serviceId: serviceId ?? '',
                productId: productId ?? '',
              };
            }) || []
          );
        }) || [];

      dispatch({
        type: skuProductId ? PRODUCT_SKUS_AD_IMGS : AD_IMGS,
        payload: adImages,
      });
    } catch (error) {
      dispatch({
        type: isCompare ? COMPARISON_AD_STATS : skuProductId ? PRODUCT_SKUS_AD_STATS : AD_STATS,
        payload: [],
      });
      console.error('product-analytics-error', { error });
      toast.error('Something went wrong');
    } finally {
      dispatch({
        type: isCompare
          ? IS_LOADING_ADS_COMPARISONS
          : skuProductId
            ? IS_LOADING_PRODUCT_SKUS_ADS
            : IS_LOADING_ADS,
        payload: false,
      });
    }
  };
};

export const changeSearchTerm = (searchTerm: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch({ type: SEARCH_TERM_CHANGED, searchTerm });
  };
};

export const getProductsForAnalytics = ({
  page = 0,
  limit = 12,
  itemType = 'products',
  isCompare = false,
  orderBy = 'itemsSoldTotal',
  order = -1,
  skuProductId = '',
  filters = [] as InsightsFilterQuery[],
}) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      currentShopId,
      mainDatePickerSelectionRange,
      datesToCompare: { start: compareStart, end: compareEnd },
      productAnalytics: {
        searchTerm,
        productsFullDetails,
        skusFullDetails,
        includeFreeProducts,
        includeOnlyActiveProducts,
        dialect,
      },
      groupStatsBy,
    } = getState();

    const fullDetailsArr = skuProductId ? skusFullDetails : productsFullDetails;
    const isInit = page === 0;

    try {
      if (!mainDatePickerSelectionRange) {
        return;
      }
      const { start, end, id } = mainDatePickerSelectionRange;

      if (id === DatePickerTimePeriods.TODAY) return;

      dispatch({
        type: isCompare
          ? IS_LOADING_PRODUCT_COMPARISONS
          : skuProductId
            ? IS_LOADING_PRODUCT_SKUS
            : IS_LOADING_PRODUCTS,
        payload: true,
      });
      dispatch({
        type: isCompare
          ? IS_LOADING_ADS_COMPARISONS
          : skuProductId
            ? IS_LOADING_PRODUCT_SKUS_ADS
            : IS_LOADING_ADS,
        payload: true,
      });

      const startDate = moment(compareStart);
      const endDate = moment(compareEnd);

      let endDateToFetch: moment.Moment;
      if (isCompare) {
        if (startDate.isSame(endDate, 'day')) {
          endDateToFetch = startDate;
        } else {
          endDateToFetch = endDate;
        }
      } else {
        if (start.isSame(end, 'day')) {
          endDateToFetch = start;
        } else {
          endDateToFetch = end;
        }
      }

      const params: ProductAnalyticsParams = {
        shopId: currentShopId,
        page,
        granularity: groupStatsBy as 'month' | 'week' | 'day',
        startDate: isCompare ? startDate.format('YYYY-MM-DD') : start.format('YYYY-MM-DD'),
        endDate: endDateToFetch.format('YYYY-MM-DD'),
        limit,
        groupBy:
          itemType === 'products' ? FilterProperty.PRODUCT_ID : FilterProperty.PRODUCT_VARIANT_ID,
        sortBy: orderBy,
        sortDirection: order === -1 ? 'desc' : 'asc',
        searchTerm,
        productId: skuProductId,
        includeFreeProducts,
        includeOnlyActiveProducts,
        filters,
        dialect,
      };

      const { data } = await axiosInstance.post(
        `/v2/insights/product-analytics/get-products`,
        params,
      );

      const allUniqueProducts = uniqBy(
        (isInit ? [] : fullDetailsArr).concat(data.products ?? []),
        'id',
      ).map((c, i) => ({
        ...c,
        active: true,
        selected: isInit && i < 3, // only 3 products can be selected at once
      }));

      const productIds = data.products.map((x) => x.id);

      dispatch({ type: skuProductId ? SKU_IDS : PRODUCT_IDS, payload: productIds });

      const hasAds = data.products.some((x) => !!x.metrics.ads);
      const ads = hasAds
        ? data.products?.map((x: any) => ({
            productId: x.id,
            ads: x.metrics.ads,
          }))
        : undefined;
      dispatch(
        getProductAdStats({
          productIds,
          itemType,
          isCompare,
          skuProductId,
          paFilters: filters,
          ads,
          dialect,
        }),
      );
      dispatch({
        type: isCompare ? COMPARISON_PRODUCTS : skuProductId ? PRODUCT_SKUS : PRODUCTS,
        payload: allUniqueProducts,
      });

      if (!isCompare && !skuProductId) {
        dispatch({ type: GET_PRODUCTS_COUNT, payload: data.count ?? 0 });
        dispatch(getForecastMetadata());
      }
      if (!data.products.length) {
        toast.warn(`Hm, No items found.`);
      }
    } catch (error) {
      console.log({ error });
      toast.error('Something went wrong');
      dispatch({
        type: isCompare ? COMPARISON_PRODUCTS : skuProductId ? PRODUCT_SKUS : PRODUCTS,
        payload: [],
      });
    } finally {
      dispatch({
        type: isCompare
          ? IS_LOADING_PRODUCT_COMPARISONS
          : skuProductId
            ? IS_LOADING_PRODUCT_SKUS
            : IS_LOADING_PRODUCTS,
        payload: false,
      });
    }
  };
};

export const saveProductAnalyticsForecast = (payload) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { currentShopId } = getState();
    try {
      await axiosInstance.post('/v2/shopify/mongo/save-products-forecast-metadata', {
        shopId: currentShopId,
        payload,
      });
      toast.success('Success!', { autoClose: 2500 });
      dispatch(getForecastMetadata());
      dispatch({
        type: PRODUCT_UPDATED,
        payload,
      });
    } catch (error) {
      toast.error('Uh oh, we were unable to save your leadTime');
    }
  };
};

export const applySetLeadTime = (value) => {
  return (dispatch) => {
    dispatch(saveProductAnalyticsForecast({ defaultValue: value }));
  };
};

export const getForecastMetadata = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { currentShopId } = getState();
    try {
      const res = await axiosInstance.post('/v2/shopify/mongo/get-products-forecast-metadata', {
        shopId: currentShopId,
      });
      dispatch({ type: GET_PRODUCTS_FORECAST, payload: res.data });
    } catch (error) {
      console.log({ error });
    }
  };
};

const skusFullDetails = (state = [], action: { type: string; payload: any }) => {
  switch (action.type) {
    case INIT_SHOP:
      return [];
    case PRODUCT_SKUS:
      return action.payload;
    default:
      return state;
  }
};

const productsFullDetails = (state = [], action: { type: string; payload: any }) => {
  switch (action.type) {
    case INIT_SHOP:
      return [];
    case PRODUCTS:
      return action.payload;
    case PRODUCT_UPDATED:
      const retVal = [...state];
      const index = retVal.findIndex((product: any) => product.id === String(action.payload.id));
      if ((retVal[index] as any)?.metrics)
        (retVal[index] as any).metrics.leadTime = String(action.payload.value);
      return retVal;
    case TOGGLE_SELECTED_PRODUCT:
      return state.map((product: any) => {
        if (product.id === action.payload) {
          return {
            ...product,
            selected: !product.selected,
          };
        }
        return product;
      });
    case TOGGLE_ACTIVE_PRODUCT:
      return state.map((product: any) => {
        if (product.id === action.payload) {
          return {
            ...product,
            active: !product.active,
          };
        }
        return product;
      });
    default:
      return state;
  }
};
const adStats = (state = [], action: { type: string; payload: any }) => {
  switch (action.type) {
    case INIT_SHOP:
      return [];
    case AD_STATS:
      return action.payload;
    default:
      return state;
  }
};
const productSkusAdStats = (state = [], action: { type: string; payload: any }) => {
  switch (action.type) {
    case INIT_SHOP:
      return [];
    case PRODUCT_SKUS_AD_STATS:
      return action.payload;
    default:
      return state;
  }
};
const adImgs = (state = [], action: { type: string; payload: any }) => {
  switch (action.type) {
    case INIT_SHOP:
      return [];
    case AD_IMGS:
      return action.payload;
    default:
      return state;
  }
};
const skuAdImgs = (state = [], action: { type: string; payload: any }) => {
  switch (action.type) {
    case INIT_SHOP:
      return [];
    case PRODUCT_SKUS_AD_IMGS:
      return action.payload;
    default:
      return state;
  }
};
const comparisonAdStats = (state = [], action: { type: string; payload: any }) => {
  switch (action.type) {
    case INIT_SHOP:
      return [];
    case COMPARISON_AD_STATS:
      return action.payload;
    default:
      return state;
  }
};

const comparisonsFullDetails = (state = [], action: { type: string; payload: [] }) => {
  switch (action.type) {
    case INIT_SHOP:
      return [];
    case COMPARISON_PRODUCTS:
      return action.payload;
    default:
      return state;
  }
};
const comparisonsProductSkusFullDetails = (state = [], action: { type: string; payload: [] }) => {
  switch (action.type) {
    case INIT_SHOP:
      return [];
    case COMPARISON_PRODUCT_SKUS:
      return action.payload;
    default:
      return state;
  }
};
const productIds = (state = [], action: { type: string; payload: [] }) => {
  switch (action.type) {
    case INIT_SHOP:
      return [];
    case PRODUCT_IDS:
      return action.payload;
    default:
      return state;
  }
};
const skuIds = (state = [], action: { type: string; payload: [] }) => {
  switch (action.type) {
    case INIT_SHOP:
      return [];
    case SKU_IDS:
      return action.payload;
    default:
      return state;
  }
};

const productsCount = (state = 0, action: { type: string; payload: number }) => {
  switch (action.type) {
    case INIT_SHOP:
      return null;
    case GET_PRODUCTS_COUNT:
      return action.payload;
    default:
      return state;
  }
};
const isLoadingProducts = (state = false, action: { type: string; payload: boolean }) => {
  switch (action.type) {
    case IS_LOADING_PRODUCTS:
      return action.payload;
    default:
      return state;
  }
};
const isLoadingProductSkus = (state = false, action: { type: string; payload: boolean }) => {
  switch (action.type) {
    case INIT_SHOP:
      return true;
    case IS_LOADING_PRODUCT_SKUS:
      return action.payload;
    default:
      return state;
  }
};
const isLoadingAdStats = (state = false, action: { type: string; payload: boolean }) => {
  switch (action.type) {
    case INIT_SHOP:
      return true;
    case IS_LOADING_ADS:
      return action.payload;
    default:
      return state;
  }
};
const isLoadingSkuAdStats = (state = false, action: { type: string; payload: boolean }) => {
  switch (action.type) {
    case INIT_SHOP:
      return true;
    case IS_LOADING_PRODUCT_SKUS_ADS:
      return action.payload;
    default:
      return state;
  }
};
const isLoadingProductComparisons = (state = false, action: { type: string; payload: boolean }) => {
  switch (action.type) {
    case INIT_SHOP:
      return true;
    case IS_LOADING_PRODUCT_COMPARISONS:
      return action.payload;
    default:
      return state;
  }
};
const chartOpen = (state = false, action: { type: string; payload: boolean }) => {
  switch (action.type) {
    case CHART_OPEN:
      return action.payload;
    default:
      return state;
  }
};

const itemType = (state = 'products', action: { type: string; payload: ProductEntityType }) => {
  switch (action.type) {
    case ITEM_TYPE:
      return action.payload;
    default:
      return state;
  }
};
const pageSize = (state = 12, action: { type: string; payload: number }) => {
  switch (action.type) {
    case ROWS_PER_PAGE:
      return action.payload;
    default:
      return state;
  }
};
const forecastMetadata: Reducer<{ defaultValue: number }> = (
  state = { defaultValue: 0 },
  action,
) => {
  switch (action.type) {
    case GET_PRODUCTS_FORECAST:
      return action.payload;
    default:
      return state;
  }
};

const searchTerm: Reducer<string> = (state = '', action) => {
  switch (action.type) {
    case INIT_SHOP:
      return '';
    case SEARCH_TERM_CHANGED:
      return action.searchTerm;
    default:
      return state;
  }
};

const includeFreeProducts: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_INCLUDE_FREE_PRODUCTS:
      return !state;
    default:
      return state;
  }
};

const includeOnlyActiveProducts: Reducer<boolean> = (state = true, action) => {
  switch (action.type) {
    case TOGGLE_INCLUDE_ACTIVE_PRODUCTS_ONLY:
      return !state;
    default:
      return state;
  }
};

const dialect: Reducer<Dialect> = (state = 'bigquery', action) => {
  switch (action.type) {
    case PA_DIALECT:
      return action.payload;
    default:
      return state;
  }
};

export const reducers = combineReducers({
  adStats,
  adImgs,
  chartOpen,
  comparisonAdStats,
  comparisonsFullDetails,
  comparisonsProductSkusFullDetails,
  forecastMetadata,
  searchTerm,
  includeFreeProducts,
  includeOnlyActiveProducts,
  isLoadingAdStats,
  isLoadingProductComparisons,
  isLoadingProducts,
  isLoadingProductSkus,
  isLoadingSkuAdStats,
  itemType,
  pageSize,
  productsCount,
  productsFullDetails,
  productIds,
  productSkusAdStats,
  skuAdImgs,
  skuIds,
  skusFullDetails,
  dialect,
});
