import { $derived, $effect, $observer, $refreshable, $store } from '@tw/snipestate';
import moment from '@tw/moment-cached';
import { $ffStore } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { $currentShopId, $shop } from '../$shop';
import axiosInstance from '../../utils/axiosInstance';
import { $userId } from '../$user';
import {
  DEFAULT_BILLING_GENERATION,
  mobyFreePriceIdForPlanPreview,
  mobyProductId,
} from '@tw/constants';
import { subscription, subscriptionPlan } from '@tw/types/module/services/subscription-manager';
import { isProduction } from '../../config';

export const MOBY_FREE_PRICE_ID_FOR_PLAN_PREVIEW = mobyFreePriceIdForPlanPreview(isProduction);
export const MOBY_PRODUCT_ID = mobyProductId(isProduction);

export const $shopSubscription = $store<subscription | undefined>();

export const $subscriptionId = $derived((get) => get($shopSubscription)?.subscription ?? null);

export const $subscriptionItems = $derived((get) => get($shopSubscription)?.items || []);

export const $subscriptionPlans = $derived((get) =>
  get($subscriptionItems).filter((item) => item.product_type === 'package'),
);

export const $subscriptionAddons = $derived((get) =>
  get($subscriptionItems).filter((item) => item.product_type === 'addon'),
);

export const $isPackagePlanPreview = $derived((get) => {
  const subscription = get($shopSubscription);
  return !!subscription?.items
    ?.filter((x) => x.price_id !== MOBY_FREE_PRICE_ID_FOR_PLAN_PREVIEW)
    .some((item) => item.plan_preview_end_date);
});

export const $isPackagePlanPreviewBefore = $derived((get) => {
  const subscription = get($shopSubscription);
  const isPackagePlanPreview = get($isPackagePlanPreview);
  return (
    !isPackagePlanPreview &&
    !!subscription?.shop_items_history
      ?.filter((x) => x.price_id !== MOBY_FREE_PRICE_ID_FOR_PLAN_PREVIEW)
      .some((item) => item.plan_preview_end_date)
  );
});

export const $packagePlanPreviewBeforeName = $derived((get) => {
  const subscription = get($shopSubscription);
  return (
    subscription?.shop_items_history
      ?.filter((x) => x.price_id !== MOBY_FREE_PRICE_ID_FOR_PLAN_PREVIEW)
      .filter((item) => item.plan_preview_end_date && item.plan_preview_start_date)
      .sort((a, b) => moment(a.plan_preview_end_date).diff(moment(b.plan_preview_end_date)))[0]
      ?.product_name || null
  );
});

export const $currentPlan = $derived(
  (get) =>
    get($subscriptionItems).find(
      (item) => item.product_type === 'package' && item.plan_preview_start_date == null,
    ) ?? null,
);

export const $currentPlanName = $derived((get) => get($currentPlan)?.product_name || null);

export const $currentPackagePlanPreviewPlanName = $derived((get) => {
  const item = get($currentPackagePlanPreview);
  const planPreviewName: string | undefined = item?.product_name;
  return planPreviewName || null;
});

export const $currentPackagePlanPreview = $derived((get) => {
  const subscription = get($shopSubscription);
  return subscription?.items
    ?.filter((x) => x.price_id !== MOBY_FREE_PRICE_ID_FOR_PLAN_PREVIEW)
    .find((item) => item.plan_preview_end_date && item.product_type === 'package');
});

export const $planPreviewPackageDayLeft = $derived((get) => {
  const subscription = get($shopSubscription);
  const planPreviewItem = subscription?.items
    ?.filter((x) => x.price_id !== MOBY_FREE_PRICE_ID_FOR_PLAN_PREVIEW)
    .find((item) => item.plan_preview_end_date);

  if (planPreviewItem) {
    const planPreviewEndDate = moment(planPreviewItem.plan_preview_end_date);
    const today = moment();
    return planPreviewEndDate.diff(today, 'days') + 1;
  }
  return 0;
});

export const $isFreeShop = $derived((get) => {
  return get($currentPlanName) === 'Founders Dash';
});

export const $currentRevenue = $derived((get) => {
  const shop = get($shop);
  return shop?.annualRevenue?.revenue ?? 0;
});

export const $plans = $observer(
  { loading: false, error: null, data: [] as subscriptionPlan[] },
  async (get, set) => {
    const uid = get($userId);
    if (!uid) return set({ ...get(), data: [] });

    try {
      set({ ...get(), loading: true });

      const url = `v2/subscription-manager/plans?generation=${DEFAULT_BILLING_GENERATION}`;
      const { data } = await axiosInstance.get(url);
      set({ loading: false, error: null, data });
    } catch (err) {
      // keep previous data by doing ...get(), so that we can still show previous
      // data even though there's an error
      set({ ...get(), loading: false, error: err });
    }
  },
);

export const $availablePlans = $refreshable(async (get) => {
  const uid = get($userId);
  const shopId = get($currentShopId);
  if (!uid || !shopId) return { packages: [], addons: [] };
  try {
    const url = `v2/subscription-manager/plans/${shopId}`;
    const { data } = await axiosInstance.get<{
      packages: subscriptionPlan[];
      addons: subscriptionPlan[];
    }>(url);
    return data;
  } catch (e) {
    return null;
  }
});

export const $isUnlimitedAccessToChat = $derived((get) => {
  // we should suggest and moby seats anymore
  return true;
  // const ffComputer = get($ffStore);
  // if (!ffComputer.isReady) return false;
  // return !ffComputer.getConfigById(FeatureFlag.CHAT_DATA_FF).shouldNotBeSeen;
});

export const $3_0Access = $derived((get) => {
  const ffComputer = get($ffStore);
  if (!ffComputer.isReady) return false;
  return (
    !ffComputer.getConfigById(FeatureFlag.TW_ONLY_3_0_FF).shouldNotBeSeen ||
    !ffComputer.getConfigById(FeatureFlag.TW_3_0_FF).shouldNotBeSeen
  );
});

export const $contractEndDate = $derived((get) => {
  const sub = get($shopSubscription);
  if (!sub?.contract_end_date) {
    return { endDate: new Date(), formattedDate: '', daysLeft: 0 };
  }

  const endDate = new Date(sub.contract_end_date);
  const formattedDate = endDate.toLocaleDateString('en-US', {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  });
  const msPerDay = 86400000;
  const daysLeft = Math.round((endDate.getTime() - Date.now()) / msPerDay);

  return { endDate, formattedDate, daysLeft };
});

export const $nextInvoiceDate = $derived((get) => {
  const msPerDay = 86400000;
  const sub = get($shopSubscription);

  const endDate = new Date(new Date(sub?.current_period_end || Date.now()).getTime());
  const formattedDate = endDate.toLocaleDateString('en-US', {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  });
  const daysLeft = Math.round((endDate.getTime() - Date.now()) / msPerDay);

  return { endDate, formattedDate, daysLeft };
});

export const $subscriptionPrice = $derived((get) => {
  const subItems = get($subscriptionItems);
  if (!subItems.length) return 0; // TODO: See what to put here in this case

  return subItems
    .filter((i) => i.plan_preview_start_date === null || !i.plan_preview_start_date)
    .reduce((a, i) => a + +i.price, 0); // TODO: See if it's correct to compute the sum here like this
});

export const $isUserHasMobyPlanPreviewBefore = $derived((get) => {
  const subscription = get($shopSubscription);
  const isUserHasMobyPlanPreview = get($isUserHasMobyPlanPreview);
  return (
    !isUserHasMobyPlanPreview &&
    !!subscription?.shop_items_history?.some(
      (x) =>
        !!x?.plan_preview_start_date &&
        x?.price_id === MOBY_FREE_PRICE_ID_FOR_PLAN_PREVIEW &&
        x?.metadata?.userId === get($userId),
    )
  );
});

export const $isShopHasPremiumPlusBefore = $derived((get) => {
  const subscription = get($shopSubscription);
  return !!subscription?.shop_items_history?.some(
    (x) => !!x?.plan_preview_start_date && x?.product_name.includes('Plus'),
  );
});

export const $premiumPlusDayLeft = $derived((get) => {
  const subscription = get($shopSubscription);
  const sub = subscription?.items?.find(
    (x) => !!x?.plan_preview_start_date && x?.product_name.includes('Plus'),
  );
  if (!sub) return null;
  //diff between today to plan_preview_start_date
  return moment(sub.plan_preview_end_date).diff(moment(), 'days') + 1;
});

export const $isUserHasMobyPlanPreview = $derived((get) => {
  const subscription = get($shopSubscription);
  return !!subscription?.items?.some(
    (x) =>
      !!x?.plan_preview_start_date &&
      x?.price_id === MOBY_FREE_PRICE_ID_FOR_PLAN_PREVIEW &&
      x?.metadata?.userId === get($userId),
  );
});

export const $planPreviewMobyDayLeft = $derived((get) => {
  const subscription = get($shopSubscription);
  const planPreviewMoby = subscription?.items?.find(
    (x) =>
      x.price_id === MOBY_FREE_PRICE_ID_FOR_PLAN_PREVIEW &&
      x?.metadata?.userId === get($userId) &&
      x.plan_preview_end_date,
  );

  if (planPreviewMoby) {
    const planPreviewEndDate = moment(planPreviewMoby.plan_preview_end_date);
    const today = moment();
    return planPreviewEndDate.diff(today, 'days') + 1;
  }
  return 0;
});

export const $mobyUpgradeSplitTest = $observer<{
  dayTrial: number;
  isOnlyUpgrade: boolean;
  isFromSplitTest: boolean;
}>(
  {
    dayTrial: 0,
    isOnlyUpgrade: false,
    isFromSplitTest: false,
  },
  async (get, set) => {
    const userId = get($userId);
    const shopId = get($currentShopId);
    if (!userId || !shopId) {
      set({ dayTrial: 0, isOnlyUpgrade: false, isFromSplitTest: false });
      return;
    }
    // const response = (
    //   await axiosInstance.post(`/v2/willy/moby-trial-split-test`, {
    //     shopId,
    //     userId,
    //   })
    // ).data;

    const response = { dayTrial: 7, isOnlyUpgrade: false, isFromSplitTest: false };
    set(response);
  },
);

export const $annualPrice = $derived(async (get) => {
  const shopId = get($currentShopId);
  if (!shopId) return null;
  const getAnnualPriceUrl = `/v2/subscription-manager/subscriptions/gross-annual-price/${shopId}`;
  return (await axiosInstance.get<number>(getAnnualPriceUrl)).data;
});
