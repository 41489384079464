import allServices from 'constants/services';
import { combineReducers, Reducer } from 'redux';
import axiosInstance from '../utils/axiosInstance';
import { AppDispatch } from 'index';
import { INIT_SHOP, RECEIVED_SENSORY_INTEGRATIONS } from './constants';
import { loadingNewStats, newStatsReceived, statsLoadingError } from './newStats';
import { services, ServicesIds } from '@tw/types/module/services';
import { RealtimeEvent } from '@tw/types';
import {
  ProviderAuth,
  SENSORY_IGNORE_PROVIDERS,
  SENSORY_SUPPORTED_PROVIDERS,
  SensorySummaryRequest,
  WorkflowIntegrationStatus,
} from '@tw/types/module/sensory';
import { groupStats } from '@tw/stats/module/groupStats';
import { toast } from 'react-toastify';
import { RootState } from '../reducers/RootType';
import { createSelector } from 'reselect';
import {
  Integration,
  Provider,
  ProviderCredential,
} from '@tw/types/module/services/account-manager';
import { ShopIntegrationProperties } from '@tw/types/module/types/ShopProviders';
import { ClientActivitiesGroup } from '../components/ActivityFeed/Types';
import { closeRedirectNotice, startConnect } from './shopIntegrations';
import { $currency, $currentShopId, $msp } from '../$stores/$shop';
import { fetchServiceStats } from './newStatsUtils';
import { $v3_0_Enabled } from '$stores/$v3_0_Enabled';
import { confirm } from '@tw/ui-components';
import { $navigate } from '../$stores/$navigate';
import { getShopFSDocId } from '@tw/constants/module/utils';
import { sleep } from '../utils/sleep';

const RECEIVED_PROVIDERS = 'RECEIVED_PROVIDERS';
const RECEIVED_ACCOUNTS = 'RECEIVED_ACCOUNTS';
const RECEIVED_SENSORY_FOR_SHOP = 'RECEIVED_SENSORY_FOR_SHOP';
export const SENSORY_SAVING_INTEGRATION_CHANGES = 'SENSORY_SAVING_INTEGRATION_CHANGES';
export const SENSORY_SAVING_INTEGRATION_CHANGES_FAILED =
  'SENSORY_SAVING_INTEGRATION_CHANGES_FAILED';
export const notReleasedProviders = createSelector(
  [
    (state: RootState) => state.sensory.sensorySupportedProviders,
    (state: RootState) => state.sensory.providers,
  ],
  (sensorySupportedProviders, providers) => {
    return providers
      .filter((provider) => !sensorySupportedProviders.includes(provider.id))
      .filter((provider) => !SENSORY_IGNORE_PROVIDERS.includes(provider.id))
      .map((provider) => provider.id);
  },
);

export const tryToOpenDialogIfOpenCred = () => (dispatch, getState) => {
  const { sensory } = getState();
  const { providers, sensoryAccountConfigScreenIsOpen, sensorySupportedProviders } = sensory;

  const twVersion = $v3_0_Enabled.get() ? '3.0' : '2.0';
  const openCreds = providers?.filter(
    (provider) =>
      sensorySupportedProviders.includes(provider.id) &&
      (!provider.twVersion || +provider.twVersion <= +twVersion) &&
      provider.credentials?.length > 0 &&
      !(provider.integrations?.length > 0) &&
      provider.choose_account,
  );
  if (openCreds?.length > 0 && !sensoryAccountConfigScreenIsOpen) {
    dispatch(sensoryConfigOpen(openCreds[0]));
  }
};

export const getAllSensoryNewStats =
  (integrations: { [key in ServicesIds]: ShopIntegrationProperties[] }) =>
  async (dispatch, getState) => {
    const { sensory, shop } = getState();
    const { sensorySupportedProviders } = sensory;

    Object.entries(integrations)
      .filter(([providerId, integrations]) => {
        return (
          !SENSORY_IGNORE_PROVIDERS.includes(providerId as ServicesIds) &&
          sensorySupportedProviders.includes(providerId) &&
          (typeof services[providerId].isSensory === 'function'
            ? services[providerId].isSensory(shop)
            : services[providerId].isSensory) &&
          !services[providerId].willyTableType?.includes('ads') &&
          integrations.length > 0
        );
      })
      .filter(([providerId, integrations]) => !services[providerId].forceOldFetch)
      .forEach(([providerId, integrations]) => {
        dispatch(getSensoryNewStats(providerId as ServicesIds, integrations));
      });
  };

export const sensoryProviderConvertResponse: (providerResponse: Provider[]) => {
  [key in ServicesIds]: { integrations: Integration[]; credentials: ProviderCredential[] };
} = (providerResponse: Provider[]) => {
  return providerResponse.reduce(
    (acc, provider) => {
      const { integrations, id, credentials } = provider;
      return {
        ...acc,
        [id]: {
          ...provider,
          integrations,
          credentials,
        },
      };
    },
    {} as {
      [key in ServicesIds]: { integrations: Integration[]; credentials: ProviderCredential[] };
    },
  );
};

export const getProvidersAndIntegrations = async (
  shopId,
  internalUpdate = false,
  lastProviders = null,
) => {
  const providers = internalUpdate
    ? lastProviders!
    : (await axiosInstance.get<Provider[]>(`/v2/account-manager/integrations/providers/${shopId}`))
        ?.data ?? [];
  const integrations: (Integration & { domain: string })[] = providers
    ?.filter((provider) => provider && Array.isArray(provider.integrations))
    .map((provider) =>
      provider?.integrations
        ? provider?.integrations?.map((integration) => ({
            ...integration,
            domain: provider.domain,
          }))
        : [],
    )
    .flat();

  const sensoryForShop = sensoryProviderConvertResponse(providers);
  return { providers, integrations, sensoryForShop };
};

export const sensoryRealtimeUpdate =
  (data: RealtimeEvent<WorkflowIntegrationStatus>) => async (dispatch, getState) => {
    const { sensory, currentShopId } = getState();
    const { providers } = sensory;
    const newProviders = providers.map((provider) => {
      if (provider.integrations?.some((int) => int.id === data.account)) {
        return {
          ...provider,
          integrations: provider.integrations.map((int) => {
            if (int.id === data.account) {
              let newWorkflowStatus;
              if (!int.workflowStatus) {
                newWorkflowStatus = [data.data];
              }
              if (int.workflowStatus.some((wf) => wf.workflowId === data.data?.workflowId)) {
                if (data.data?.doneDate) {
                  // need to remove form the array the workflowId that ends
                  newWorkflowStatus = int.workflowStatus.filter(
                    (wf) => wf.workflowId !== data.data?.workflowId,
                  );
                } else {
                  //workflow status exist just update it
                  newWorkflowStatus = int.workflowStatus.map((wf) => {
                    if (wf.workflowId === data.data?.workflowId) {
                      return data.data;
                    }
                    return wf;
                  });
                }
              }

              // new workflow added in realtime only if it's not done yet
              if (
                !int.workflowStatus.some((wf) => wf.workflowId === data.data?.workflowId) &&
                !data.data?.doneDate
              ) {
                newWorkflowStatus = [...int.workflowStatus, data.data];
              }

              return {
                ...int,
                status: data.data?.integrationStatus,
                workflowStatus: newWorkflowStatus,
              };
            }
            return int;
          }),
        };
      }
      return provider;
    });
    const { integrations } = await getProvidersAndIntegrations(currentShopId, true, newProviders);
    dispatch({ type: RECEIVED_SENSORY_INTEGRATIONS, integrations });
  };

export const getProviders =
  (openCredsDialog = true) =>
  async (dispatch, getState) => {
    const { currentShopId } = getState();

    const { providers, integrations, sensoryForShop } =
      await getProvidersAndIntegrations(currentShopId);
    dispatch({ type: RECEIVED_PROVIDERS, providers });
    dispatch({ type: RECEIVED_SENSORY_FOR_SHOP, sensoryForShop });
    dispatch({ type: RECEIVED_SENSORY_INTEGRATIONS, integrations });

    if (openCredsDialog) {
      dispatch(tryToOpenDialogIfOpenCred());
    }
  };

export const getSensoryNewStats =
  (providerId: ServicesIds, integrations: ShopIntegrationProperties[], isSilent = false) =>
  async (dispatch, getState) => {
    const { mainDatePickerSelectionRange, groupStatsBy, currentShopId, shopTimezone } = getState();

    if (!mainDatePickerSelectionRange) {
      return;
    }

    try {
      const groupedStats = await getSensoryNewStatsForShop(
        providerId,
        integrations,
        isSilent,
        mainDatePickerSelectionRange,
        $currency.get(),
        groupStatsBy,
        () =>
          dispatch(
            loadingNewStats({
              [providerId]: true,
            }),
          ),
        currentShopId,
        shopTimezone,
      );
      dispatch(
        newStatsReceived({
          stats: groupedStats,
          serviceId: providerId,
        }),
      );
    } catch (e) {
      console.error(e);
      dispatch(
        statsLoadingError({
          serviceId: providerId,
        }),
      );
    }
  };

export const getSensoryNewStatsForShop = async (
  serviceId: ServicesIds,
  integrations: ShopIntegrationProperties[],
  isSilent,
  mainDatePickerSelectionRange,
  currency,
  groupStatsBy,
  setLoading,
  currentShopId,
  timezone,
) => {
  let { start, end } = mainDatePickerSelectionRange;
  const format = 'YYYY-MM-DD';
  start = start.format(format);
  end = end.format(format);

  const params: SensorySummaryRequest & { shopId: string } = {
    start,
    end,
    providerId: serviceId,
    integrations,
    shopId: currentShopId,
  };
  if (!isSilent) {
    setLoading();
  }

  let url = allServices[serviceId]?.apiUrl;
  if (!url) {
    return [];
  }

  const { stats } = await fetchServiceStats<any>(params, url, 'post', false);

  const groupedStats = groupStats(stats, groupStatsBy, undefined, undefined, true);
  return groupedStats;
};

// export const getOpenCreds = () => async (dispatch, getState) => {
//   const { currentShopId } = getState();
//   const openCreds =
//     (await axiosInstance.get(`/v2/account-manager/integrations/openCreds/${currentShopId}`))
//       ?.data ?? [];
//   dispatch({ type: RECEIVED_ACCOUNTS, openCreds });
// };

export const CLOSE_SENSORY_ACCOUNTS_CONFIG_SCREEN = 'CLOSE_SENSORY_ACCOUNTS_CONFIG_SCREEN';
export const closeSensoryAccountsConfigScreen = () => ({
  type: CLOSE_SENSORY_ACCOUNTS_CONFIG_SCREEN,
});

export const TOGGLE_SENSORY_AUTH_CONFIG_SCREEN_OPEN = 'TOGGLE_SENSORY_AUTH_CONFIG_SCREEN_OPEN';
export const toggleSensoryAuthConfigScreenOpen = () => ({
  type: TOGGLE_SENSORY_AUTH_CONFIG_SCREEN_OPEN,
});

export const sensoryAccountConnect =
  (provider, clientAuthParams = {}) =>
  async (dispatch, getState) => {
    if (provider.auth_config?.[0]?.auth_method === 'oauth') {
      dispatch(sensoryStartConnect(provider));
      const { currentShopId } = getState();
      const response = await axiosInstance.post(`/v2/account-manager/integrations/getUrl`, {
        providerId: provider.id,
        buId: getShopFSDocId(currentShopId),
        redirectUrl: window.location.href,
        clientAuthParams,
      });
      const url = response?.data?.url;
      if (url) {
        const state = getState().integrations;
        if (state.redirectNoticeIsOpen) {
          window.location.replace(url);
        }
      } else {
        dispatch(closeRedirectNotice());
        toast.error(`Error while redirecting to ${provider.name} authentication page`);
      }
    } else if (provider.auth_config?.[0]?.auth_method === 'basic_auth') {
      //this is where the popup would go - and when we have fields
      // const { currentShopId } = getState();
      // const response = await axiosInstance.post(`/v2/account-manager/integrations/newCreds`, {
      //   provider_id: provider.id,
      //   bu_id: currentShopId,
      //   fields: {
      //     //TODO this is where the fields would go
      //   },
      // });
    }
  };

export const sensoryAuthReconnect =
  (provider, credentialId, clientAuthParams = {}) =>
  async (dispatch, getState) => {
    if (provider.auth_config?.[0]?.auth_method === 'oauth') {
      dispatch(sensoryStartConnect(provider));
      const { currentShopId } = getState();
      const response = await axiosInstance.post(`/v2/account-manager/integrations/oAuthReconnect`, {
        providerId: provider.id,
        buId: currentShopId,
        redirectUrl: window.location.href,
        clientAuthParams,
        credentialId,
      });
      const url = response?.data?.url;
      if (url) {
        const state = getState().integrations;
        if (state.redirectNoticeIsOpen) {
          window.location.replace(url);
        }
      } else {
        dispatch(closeRedirectNotice());
        toast.error(`Error while redirecting to ${provider.name} authentication page`);
      }
    } else if (provider.auth_config?.[0]?.auth_method === 'basic_auth') {
      //TODO
    }
  };

export const sensoryAccountChanged = (accountsToUpdated) => async (dispatch, getState) => {
  const { currentShopId } = getState();
  await axiosInstance.post(`/v2/account-manager/integrations/manageIntegration`, {
    bu_id: currentShopId,
    provider_accounts: accountsToUpdated,
  });
  await dispatch(sensoryGetProviderAccounts());
  await dispatch(getProviders());
};

export const connectInt = (integration) => async (dispatch, getState) => {
  const { currentShopId } = getState();
  try {
    await axiosInstance.post(`/v2/account-manager/integrations/connect-int`, {
      bu_id: currentShopId,
      integration,
    });
  } catch (e) {
    dispatch({ type: SENSORY_SAVING_INTEGRATION_CHANGES_FAILED });
    toast.error('Error while connecting integration');
  }
  await dispatch(sensoryGetProviderAccounts());
  await dispatch(getProviders());
};

export const reconnectInt = (integration) => async (dispatch, getState) => {
  const { currentShopId } = getState();
  try {
    await axiosInstance.post(`/v2/account-manager/integrations/reconnect-int`, {
      bu_id: currentShopId,
      integration,
    });
  } catch (e) {
    dispatch({ type: SENSORY_SAVING_INTEGRATION_CHANGES_FAILED });
    toast.error('Error while reconnecting integration');
  }
  await dispatch(sensoryGetProviderAccounts());
  await dispatch(getProviders());
};

export const updateInt =
  (integration, openCredsDialog = true) =>
  async (dispatch, getState) => {
    const integrationId = integration.integration_id;
    const { currentShopId } = getState();
    await axiosInstance.put(`/v2/account-manager/integrations/${integrationId}`, {
      bu_id: currentShopId,
      integration,
    });
    await dispatch(sensoryGetProviderAccounts());
    await dispatch(getProviders(openCredsDialog));
  };

export const saveIntegrationSettings =
  (integration, currentSettings, showWarning = true) =>
  async (dispatch) => {
    try {
      if (
        !showWarning ||
        (await confirm({
          title: 'Update Account',
          message: 'Are you sure you want to update account?',
        }))
      ) {
        dispatch(async () => {
          dispatch({ type: SENSORY_SAVING_INTEGRATION_CHANGES });
          integration.settings = currentSettings;
          await dispatch(updateInt(integration, showWarning));
        });
      }
    } catch (err) {
      console.error(err);
      if (!showWarning) {
        throw err;
      }
    }
  };

export const disconnectInt = (integration) => async (dispatch, getState) => {
  const { currentShopId } = getState();
  await axiosInstance.post(`/v2/account-manager/integrations/disconnect-int`, {
    bu_id: currentShopId,
    integration,
  });
  await dispatch(sensoryGetProviderAccounts());
  await dispatch(getProviders());
};

export const deleteInt = (integrationId: string) => async (dispatch, getState) => {
  await axiosInstance.delete(`/v2/account-manager/integrations/${integrationId}`);
  await dispatch(sensoryGetProviderAccounts());
  await dispatch(getProviders());
};

export const sensoryAuthApply = (provider, credentials) => async (dispatch, getState) => {
  try {
    const { currentShopId } = getState();

    // Validate credentials.
    for (const field of provider.auth_config[0]?.fields) {
      if (
        field.validate?.regex &&
        !new RegExp(field.validate.regex).test(credentials[field.field])
      ) {
        toast.warning(field.validate.errorMessage || `The ${field.label} field is not valid.`);
        return;
      }
    }

    // case where using both modal and oauth.
    if (provider.auth_config?.[0]?.auth_method === 'oauth') {
      await dispatch(sensoryAccountConnect(provider, credentials));
      return;
    }

    await axiosInstance.post(`/v2/account-manager/integrations/newCreds`, {
      providerId: provider.id,
      buId: currentShopId,
      credentials,
    });
    dispatch(toggleSensoryAuthConfigScreenOpen());
    await dispatch(getProviders());
  } catch (e) {
    //in case of choose_account = true, and could not find any account
    if (e.data?.message.includes('an error occurred while select account')) {
      toast.warning(
        `We could not find an account associated with the integration. Please configure your ${provider.name} account correctly and reconnect.`,
        { position: 'top-center', autoClose: false },
      );
      dispatch(toggleSensoryAuthConfigScreenOpen());
      await dispatch(getProviders());
    } else {
      toast.error(e.data?.message || 'Error while saving credentials');
    }
  }
};

export const OPEN_SENSORY_ACCOUNTS_CONFIG = 'OPEN_SENSORY_ACCOUNTS_CONFIG';
export const OPEN_SENSORY_AUTH_CONFIG = 'OPEN_SENSORY_AUTH_CONFIG';
export const SENSORY_PROVIDER_CONFIG_DATA = 'SENSORY_PROVIDER_CONFIG_DATA';
export const OPEN_SENSORY_REDIRECT_NOTICE = 'OPEN_SENSORY_REDIRECT_NOTICE';
export const sensoryConfigOpen = (provider) => (dispatch) => {
  dispatch({ type: OPEN_SENSORY_ACCOUNTS_CONFIG });
  dispatch({ type: SENSORY_PROVIDER_CONFIG_DATA, provider });
};

export const sensoryAuthConfigOpen = (provider) => (dispatch) => {
  dispatch({ type: OPEN_SENSORY_AUTH_CONFIG });
  dispatch({ type: SENSORY_PROVIDER_CONFIG_DATA, provider });
};

export const sensoryStartConnect = (provider) => (dispatch) => {
  dispatch({ type: SENSORY_PROVIDER_CONFIG_DATA, provider });
  dispatch(startConnect(provider.name));
};

const SENSORY_PROVIDER_ACCOUNTS_DATA = 'SENSORY_PROVIDER_ACCOUNTS_DATA';
export const sensoryGetProviderAccounts = () => async (dispatch: AppDispatch, getState) => {
  const {
    currentShopId,
    sensory: { sensoryProviderConfigData },
  } = getState();
  const { data } = await axiosInstance.post(`/v2/account-manager/integrations/accounts`, {
    provider_id: sensoryProviderConfigData.id,
    credentials_ids: sensoryProviderConfigData.credentials,
    buId: currentShopId, //todo: delete
    bu_id: currentShopId,
  });
  dispatch({ type: SENSORY_PROVIDER_ACCOUNTS_DATA, accounts: data ?? [] });
};

const SENSORY_DYNAMIC_SETTINGS = 'SENSORY_DYNAMIC_SETTINGS';
const SENSORY_LOADING_DYNAMIC_SETTINGS = 'SENSORY_LOADING_DYNAMIC_SETTINGS';
const SENSORY_LOADING_DYNAMIC_SETTINGS_FINISHED = 'SENSORY_LOADING_DYNAMIC_SETTINGS_FINISHED';
export const sensoryGetProviderSettings = () => async (dispatch: AppDispatch, getState) => {
  const {
    currentShopId,
    sensory: { sensoryProviderConfigData },
  } = getState();
  try {
    if (sensoryProviderConfigData?.settings) {
      const hasDynamicSettings = sensoryProviderConfigData?.settings?.find(
        (setting) => setting.scope == 'fetcher',
      );
      if (hasDynamicSettings) {
        try {
          dispatch({ type: SENSORY_LOADING_DYNAMIC_SETTINGS });
          const { data } = await axiosInstance.post(
            `/v2/account-manager/integrations/get-dynamic-settings`,
            {
              providerId: sensoryProviderConfigData.id,
              buId: currentShopId,
              credentialsId: sensoryProviderConfigData?.credentials[0]?.id,
            },
          );
          dispatch({
            type: SENSORY_DYNAMIC_SETTINGS,
            dynamicSettings: {
              providerId: sensoryProviderConfigData.id,
              dynamicData: data,
            },
          });
        } catch (e) {
          console.error('Error fetching dynamic settings', e);
        }
        dispatch({ type: SENSORY_LOADING_DYNAMIC_SETTINGS_FINISHED });
      }
    }
  } catch (e) {
    console.error('Error fetching dynamic settings', e);
  }
};

export const sensoryAccountDisconnect =
  (credentialId: string, isMSP = false) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: SENSORY_SAVING_INTEGRATION_CHANGES });
      await axiosInstance.delete(
        `/v2/account-manager/integrations/credential/${credentialId}?msp=${isMSP}&bu_id=${$currentShopId.get()}`,
      );
    } catch (e) {
      dispatch({ type: SENSORY_SAVING_INTEGRATION_CHANGES_FAILED });
      toast.error('Error deleting credentials');
      return;
    }
    if (isMSP) {
      toast.success(`The ${$msp.get()} has been successfully uninstalled!`);
      setTimeout(() => {
        window.location.href = `/welcome/connect?shop-id=${$currentShopId.get()}`;
      }, 3000);
    }
    //await dispatch(sensoryGetProviderAccounts());
    dispatch(closeSensoryAccountsConfigScreen());
    if (!isMSP) {
      toast.success('The account has been successfully disconnected');
    }
    await dispatch(getProviders());
  };

const providerAccounts = (state = [], action) => {
  switch (action.type) {
    case SENSORY_PROVIDER_ACCOUNTS_DATA:
      return action.accounts;
    default:
      return state;
  }
};

const providers = (state = [], action) => {
  switch (action.type) {
    case RECEIVED_PROVIDERS:
      return action.providers || state;
    default:
      return state;
  }
};

const sensoryForShop = (state = [], action) => {
  switch (action.type) {
    case RECEIVED_SENSORY_FOR_SHOP:
      return action.sensoryForShop || state;
    default:
      return state;
  }
};

// const openCreds = (state = [], action) => {
//   switch (action.type) {
//     case RECEIVED_ACCOUNTS:
//       return action.openCreds || state;
//     default:
//       return state;
//   }
// };

const sensoryAccountConfigScreenIsOpen = (state = false, action) => {
  switch (action.type) {
    case OPEN_SENSORY_ACCOUNTS_CONFIG:
      return true;
    case CLOSE_SENSORY_ACCOUNTS_CONFIG_SCREEN:
      return false;
    default:
      return state;
  }
};

const sensoryAuthConfigScreenIsOpen = (state = false, action) => {
  switch (action.type) {
    case OPEN_SENSORY_AUTH_CONFIG:
      return true;
    case TOGGLE_SENSORY_AUTH_CONFIG_SCREEN_OPEN:
      return !state;
    default:
      return state;
  }
};

const sensoryIntegrations = (state = [], action) => {
  switch (action.type) {
    case RECEIVED_SENSORY_INTEGRATIONS:
      return action.integrations || state;
    default:
      return state;
  }
};

const sensoryIntegrationsLoaded = (state = false, action) => {
  switch (action.type) {
    case RECEIVED_SENSORY_INTEGRATIONS:
      return true;
    default:
      return state;
  }
};

const sensoryProviderConfigData = (state = {}, action) => {
  switch (action.type) {
    case SENSORY_PROVIDER_CONFIG_DATA:
      return action.provider;
    default:
      return state;
  }
};

const sensoryProviderConfigLoading = (state = false, action) => {
  switch (action.type) {
    case OPEN_SENSORY_ACCOUNTS_CONFIG:
      return true;
    case SENSORY_PROVIDER_ACCOUNTS_DATA:
      return false;
    case SENSORY_SAVING_INTEGRATION_CHANGES:
      return true;
    case SENSORY_SAVING_INTEGRATION_CHANGES_FAILED:
      return false;
    default:
      return state;
  }
};

const sensoryProviderSettingLoading = (state = false, action) => {
  switch (action.type) {
    case SENSORY_LOADING_DYNAMIC_SETTINGS:
      return true;
    case SENSORY_LOADING_DYNAMIC_SETTINGS_FINISHED:
      return false;
    default:
      return state;
  }
};
const sensoryProviderDynamicSettings = (state = [], action) => {
  switch (action.type) {
    case SENSORY_DYNAMIC_SETTINGS:
      return action.dynamicSettings;
    default:
      return state;
  }
};

const sensorySupportedProviders = (state = SENSORY_SUPPORTED_PROVIDERS, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return [...state, ...(action.sensoryBetaProviders ?? [])];
    default:
      return state;
  }
};

//Used for realtime changes and only for this!!
const integrationsStatus: Reducer<{ [key in string]: Integration }> = (state = {}, action) => {
  switch (action.type) {
    case RECEIVED_SENSORY_INTEGRATIONS:
      return action.integrations.reduce((acc, integration) => {
        acc[integration.id] = integration;
        return acc;
      }, {});
    default:
      return state;
  }
};

export const reducers = combineReducers({
  providerAccounts,
  providers,
  sensoryForShop,
  integrationsStatus,
  sensorySupportedProviders,
  sensoryAccountConfigScreenIsOpen,
  sensoryAuthConfigScreenIsOpen,
  sensoryIntegrationsLoaded,
  sensoryIntegrations,
  sensoryProviderConfigData,
  sensoryProviderConfigLoading,
  sensoryProviderSettingLoading,
  sensoryProviderDynamicSettings,
});
