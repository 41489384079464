import { providerDomainEnum } from '@tw/types/module/sensory/integration/generalTypes';

export const integrationCategories = {
  [providerDomainEnum.ads]: {
    title: 'Advertising',
    description:
      'Gain full visibility into your advertising efforts—from ad spend to cross-channel ROAS & more.',
  },
  [providerDomainEnum.ctv]: {
    title: 'CTV',
    description: 'Gain insights into the performance of your CTV advertising campaigns.',
  },
  [providerDomainEnum.subscription]: {
    title: 'Subscription',
    description:
      'Analyze subscription performance, track retention metrics, and identify growth opportunities, helping you make data-driven decisions that boost recurring revenue.',
  },
  [providerDomainEnum.sms]: {
    title: 'Email / SMS',
    description: 'Gain visibility into your customer journey across your owned channels.',
  },
  [providerDomainEnum.pps]: {
    title: 'Post Purchase Survey',
    description: 'Analyze post-purchase data and incorporate it in your attribution model.',
  },
  [providerDomainEnum.shipping]: {
    title: 'Shipping',
    description:
      'Understand your shipping and logistics journey and leverage these metrics across your analyses.',
  },
  [providerDomainEnum.api]: {
    title: 'Team Collaboration',
    description: 'Automate reporting delivery and collaborate with your entire organization.',
  },
  [providerDomainEnum.crm]: {
    title: 'Customer Service',
    description:
      'View customer service metrics alongside your most important business metrics — including total number of tickets, average response time, and average resolution time.',
  },
  [providerDomainEnum.reviews]: {
    title: 'Reviews',
    description:
      'Understand reviews data across business and product catalog to best inform marketing and merchandising decisions.',
  },
  [providerDomainEnum.sales]: {
    title: 'Marketplace',
    description:
      'Unify marketplace advertising with the rest of your channels—enabling better attribution, optimization, and data-driven decision-making across every channel.',
  },
  [providerDomainEnum.affluencers]: {
    title: 'Influencer & Affiliate',
    description:
      'Track affiliate and influencer campaigns, links, and more to get a holistic view of performance and understand how these channels are impacting conversions.',
  },
  [providerDomainEnum.dataWarehouse]: {
    title: 'Data Warehouse',
    description:
      'Seamlessly sync your Triple Whale data to your data warehouse for deeper analysis and custom insights.',
  },
  [providerDomainEnum.content]: {
    title: 'Content',
    description: 'Understand content data to support your business decisions with key insights.',
  },
  [providerDomainEnum.saas]: {
    title: 'SaaS',
    description: 'Gain insights into your SaaS business and how to improve your product.',
  },
  other: {
    title: 'Other',
    description: '',
  },
};
