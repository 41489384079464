import AttributionSettings from 'components/attribution-new/AttributionSettings';
import React, { useCallback, useState } from 'react';
import DatePicker from 'components/SummaryDatePicker';
import { useAppDispatch } from 'index';
import {
  AddAffluencerModalOpenChanged,
  AffluencerHubChartOpenChanged,
  AffluencerFreeSearchChanged,
  AffluencerHubImportModalOpenChanged,
} from 'ducks/affluencerHub/actions';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import AttributionDateModelPicker from 'components/attribution-new/AttributionDateModelPicker';
import AttributionModelPicker from 'components/attribution-new/AttributionModelPicker';
import {
  ActionIcon,
  Button,
  Flex,
  Icon,
  Text,
  TextInput,
  Tooltip,
  useWindowSize,
} from '@tw/ui-components';
import { GRID_BREAKPOINTS } from 'components/TopBar/constants';
import { useStoreValue } from '@tw/snipestate';
import { $activeAppVersion } from '$stores/nav-config-stores';

export const AffluencerHubHeader = () => {
  const dispatch = useAppDispatch();
  const windowSize = useWindowSize('width', GRID_BREAKPOINTS);
  const isSmall = windowSize === 'mobile';
  const { affluencerHubChartOpen, freeSearch } = useSelector(
    (state: RootState) => state.affluencerHub,
  );
  const [attributionSettingsOpen, setAttributionSettingsOpen] = useState(false);
  const toggleAttributionSettingsOpen = useCallback(() => {
    setAttributionSettingsOpen((o) => !o);
  }, []);
  const [filterWidth, setFilterWidth] = useState(isSmall ? '90px' : '');

  const activeAppVersion = useStoreValue($activeAppVersion);
  const isThreePointOh = activeAppVersion === '3.0';

  return (
    <div className={'flex items-center gap-4' + (windowSize !== 'desktop' ? ' flex-wrap' : '')}>
      <Flex align="center" pl="sm" gap="sm">
        <Icon name="attribution2" color={'gray.5'} size={18} />
        <Text color="gray.7" weight={500} size="lg">
          Affluencer Hub
        </Text>
      </Flex>
      <div id="att-header-filter-text-field" style={{ width: filterWidth }}>
        <TextInput
          type="search"
          leadingIcon={<Icon name="search-major" size={12} />}
          value={freeSearch}
          onChange={(value) => dispatch(AffluencerFreeSearchChanged(value))}
          placeholder="Filter affluencers"
          onFocus={() => isSmall && setFilterWidth('')}
          onBlur={() => isSmall && setFilterWidth('90px')}
          size={isThreePointOh ? 'xs' : 'sm'}
        />
      </div>
      <Button
        onClick={() => dispatch(AddAffluencerModalOpenChanged(true))}
        leftSection={<Icon name="plus-circle" />}
        variant="activator"
        size={isThreePointOh ? 'xs' : 'sm'}
      >
        Add Affluencer
      </Button>
      <Button
        onClick={() => dispatch(AffluencerHubImportModalOpenChanged(true))}
        variant="activator"
        size={isThreePointOh ? 'xs' : 'sm'}
      >
        Import Affluencer
      </Button>
      <Tooltip label={affluencerHubChartOpen ? 'Hide Graph' : 'Show Graph'}>
        <div>
          <ActionIcon
            id="att-header-line-chart"
            size={isThreePointOh ? 'md' : 'lg'}
            radius="sm"
            onClick={() => dispatch(AffluencerHubChartOpenChanged(!affluencerHubChartOpen))}
            variant="activator"
            icon="line-chart"
          />
        </div>
      </Tooltip>
      {!isSmall && <AttributionDateModelPicker />}
      {!isSmall && <AttributionModelPicker type={'ads'} />}
      <AttributionSettings
        isOpen={attributionSettingsOpen}
        onOpen={toggleAttributionSettingsOpen}
        type="ads"
        hideAttributionModel={!isSmall}
        hideDateModel={!isSmall}
        hideExportCsv={true}
        hideWrapLines={true}
        hideCustomAdSpend={true}
      />
      <DatePicker showGroupByButton={false} buttonSize={isThreePointOh ? 'xs' : 'sm'} />
    </div>
  );
};

export default AffluencerHubHeader;
