import { combineReducers, Reducer } from 'redux';
import { toast } from 'react-toastify';
import { type RootState } from 'reducers/RootType';
import { Segment, SegmentInstance } from '@tw/types/module/services/cdp';
import moment from '@tw/moment-cached/module/timezone';

import { type AppDispatch } from 'index';
import axiosInstance from 'utils/axiosInstance';
import { SegmentDataView } from 'components/CDP/SegmentsList/utils';
import { $useCHCdp } from '../../$stores/$shop';

// TYPES
export const IS_SEGMENTS_LIST_INITIALIZED = 'IS_SEGMENTS_LIST_INITIALIZED';
export const CDP_SEGMENTS_LIST = 'CDP_SEGMENTS_LIST';
export const CDP_SEGMENT_TEMPLATE = 'CDP_SEGMENT_TEMPLATE';
export const SEGMENTS_TRENDS = 'SEGMENTS_TRENDS';
export const IS_SEGMENTS_TRENDS_LOADING = 'IS_SEGMENTS_TRENDS_LOADING';
export const CDP_SEGMENTS_CHART_OPENED = 'CDP_SEGMENTS_CHART_OPENED';
export const SET_CURRENT_SEGMENT = 'SET_CURRENT_SEGMENT';

// ACTIONS
export const setCDPSegmentsFromServer = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { currentShopId } = getState();

    try {
      const url = `/v2/cdp/get-segments-for-shop/${currentShopId}?useClickhouse=${$useCHCdp.get()}`;
      const { data } = await axiosInstance.get<{ segments: Segment[] }>(url);

      dispatch({
        type: CDP_SEGMENTS_LIST,
        payload: data.segments,
      });

      dispatch({
        type: IS_SEGMENTS_LIST_INITIALIZED,
        payload: true,
      });
    } catch (error) {}
  };
};

export const newCDPSegmentCreated = (segment: Segment) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { segmentsList } = getState().CDPSegments;
    segmentsList.push(segment);

    dispatch({
      type: CDP_SEGMENTS_LIST,
      payload: [...segmentsList],
    });
  };
};

export const setSegmentTemplate = (segment: Segment | null) => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: CDP_SEGMENT_TEMPLATE,
      payload: segment,
    });
  };
};

export const updateSegment = (segmentId: string, params: Partial<Segment>) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { segmentsList } = getState().CDPSegments;
    const updatedSegment = segmentsList.find((s) => s.id === segmentId);

    if (updatedSegment) {
      const segment: Segment = { ...updatedSegment, ...params };
      segmentsList.splice(segmentsList.indexOf(updatedSegment), 1, segment);
    }

    dispatch({
      type: CDP_SEGMENTS_LIST,
      payload: [...segmentsList],
    });
  };
};

export const updateDeletedSegment = (segmentId: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { segmentsList } = getState().CDPSegments;
    const deletedSegment = segmentsList.find((s) => s.id === segmentId);

    if (deletedSegment) {
      segmentsList.splice(segmentsList.indexOf(deletedSegment), 1);
    }

    dispatch({
      type: CDP_SEGMENTS_LIST,
      payload: [...segmentsList],
    });
  };
};

export const fetchSegmentTrends = (segmentId: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { CDPSegments, mainDatePickerSelectionRange } = getState();
    if (!CDPSegments || !mainDatePickerSelectionRange) {
      return;
    }
    const { segmentsTrends } = CDPSegments;
    const { start: startDate, end: endDate } = mainDatePickerSelectionRange;
    const { currentShopId } = getState();

    try {
      dispatch({
        type: IS_SEGMENTS_TRENDS_LOADING,
        payload: true,
      });

      const url = '/v2/cdp/segment-metrics';
      const { data } = await axiosInstance.post<SegmentInstance[]>(url, {
        shopDomain: currentShopId,
        segmentId,
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
        useClickhouse: $useCHCdp.get(),
      });
      segmentsTrends[segmentId] = data;

      dispatch({
        type: SEGMENTS_TRENDS,
        payload: { ...segmentsTrends },
      });
    } catch (error) {
      toast.error('failed to fetch cdp segments list');
    } finally {
      dispatch({
        type: IS_SEGMENTS_TRENDS_LOADING,
        payload: false,
      });
    }
  };
};

export const chartOpenChanged = (opened: boolean = false) => ({
  type: CDP_SEGMENTS_CHART_OPENED,
  payload: opened,
});

export const setCurrentSegment = (segment: SegmentDataView | null = null) => {
  return {
    type: SET_CURRENT_SEGMENT,
    payload: segment,
  };
};

// REDUCRES
const isSegmentsListInitialized: Reducer<boolean> = (state: boolean = false, action) => {
  switch (action.type) {
    case IS_SEGMENTS_LIST_INITIALIZED:
      return action.payload;
    default:
      return state;
  }
};

const segmentsList: Reducer<Segment[]> = (state: Segment[] = [], action) => {
  switch (action.type) {
    case CDP_SEGMENTS_LIST:
      return action.payload;
    default:
      return state;
  }
};

const segmentTemplate: Reducer<Segment | null> = (state = null, action) => {
  switch (action.type) {
    case CDP_SEGMENT_TEMPLATE:
      return action.payload;
    default:
      return state;
  }
};

const segmentsTrends: Reducer<{ [key in string]: SegmentInstance[] }> = (state = {}, action) => {
  switch (action.type) {
    case SEGMENTS_TRENDS:
      return action.payload;
    default:
      return state;
  }
};

const isSegmentsTrendsLoading: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case IS_SEGMENTS_TRENDS_LOADING:
      return action.payload;
    default:
      return state;
  }
};

const chartOpened: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case CDP_SEGMENTS_CHART_OPENED:
      return action.payload;
    default:
      return state;
  }
};

const currentSegment: Reducer<SegmentDataView | null> = (state = null, action) => {
  switch (action.type) {
    case SET_CURRENT_SEGMENT:
      return action.payload;
    default:
      return state;
  }
};

export const reducers = combineReducers({
  isSegmentsListInitialized,
  segmentsList,
  segmentTemplate,
  segmentsTrends,
  isSegmentsTrendsLoading,
  chartOpened,
  currentSegment,
});
