import { $userId } from '$stores/$user';
import { $observer } from '@tw/snipestate';
import { $currentShopId } from '$stores/$shop';
import _db, { toArray, userDb } from 'utils/DB';
import { InsightsFilter } from '@tw/types/module/services/insights';

export const $customFilters = $observer<InsightsFilter[]>([], (get, set) => {
  const shopId = get($currentShopId);
  const userId = get($userId);
  if (!shopId || !userId) return;

  return userDb(userId)
    .collection('willy')
    .doc(shopId)
    .collection('custom_filters')
    .onSnapshot((querySnapshot) => {
      const data = toArray(querySnapshot);

      set(data);
    });
});

export async function createCustomFilter(filter: InsightsFilter): Promise<string | undefined> {
  const shopId = $currentShopId.get();
  const userId = $userId.get();
  if (!shopId || !userId) return;

  const newDocRef = userDb(userId)
    .collection('willy')
    .doc(shopId)
    .collection('custom_filters')
    .doc();

  await newDocRef.set(filter);
  return newDocRef.id;
}

export async function deleteCustomFilter(filterId: string): Promise<void> {
  const shopId = $currentShopId.get();
  const userId = $userId.get();
  if (!shopId || !userId) return;

  await userDb(userId)
    .collection('willy')
    .doc(shopId)
    .collection('custom_filters')
    .doc(filterId)
    .delete();

  // TODO: delete ID from all widgets if applied
}

export async function updateCustomFilter(
  filterId: string,
  props: Partial<InsightsFilter>,
): Promise<void> {
  const shopId = $currentShopId.get();
  const userId = $userId.get();
  if (!shopId || !userId) return;

  await userDb(userId)
    .collection('willy')
    .doc(shopId)
    .collection('custom_filters')
    .doc(filterId)
    .set(props, { merge: true });
}
