export type Route = `/${string}`;

export type EducationFooterConfig = {
  [r: Route]: {
    /**
     * Example: "Learn more about {{real-time reporting}} and creating custom metrics".
     *
     * Everything in the double curly brackets needs to be hyperlinked.
     */
    template: string;
    /**
     * An array of links to be inserted in the template. The links will go into the template
     * in order.  Link 0 will hyperlink the first set of {{}}, etc.
     */
    links: string[];
  };
};

export const FooterConfig: EducationFooterConfig = {
  '/summary': {
    template: 'Learn more about {{real-time reporting and creating custom metrics}}',
    links: [
      'https://kb.triplewhale.com/en/articles/5725275-using-the-summary-page-pinning-metrics',
    ],
  },
  '/activity-feed': {
    template: 'Learn more about {{correlating events}} and forecasting',
    links: [
      'https://kb.triplewhale.com/en/articles/7913743-lighthouse-correlations-anomalies-activities',
    ],
  },
  '/benchmarks': {
    template: 'Learn more about {{benchmark data and measuring success}}',
    links: ['https://kb.triplewhale.com/en/articles/6476726-triple-benchmarks'],
  },
  '/templates': {
    template: '',
    links: [],
  },
  '/attribution/all/all': {
    template: 'Learn more about {{attribution models}} and making informed marketing decisions',
    links: ['https://kb.triplewhale.com/en/articles/5960333-attribution-models-in-triple-pixel'],
  },
  '/attribution/ads/all': {
    template: 'Learn more about {{attribution models}} and making informed marketing decisions',
    links: ['https://kb.triplewhale.com/en/articles/5960333-attribution-models-in-triple-pixel'],
  },
  '/attribution/email/all': {
    template: 'Learn more about {{attribution models}} and making informed marketing decisions',
    links: ['https://kb.triplewhale.com/en/articles/5960333-attribution-models-in-triple-pixel'],
  },
  '/attribution/social/all': {
    template: 'Learn more about {{attribution models}} and making informed marketing decisions',
    links: ['https://kb.triplewhale.com/en/articles/5960333-attribution-models-in-triple-pixel'],
  },
  '/attribution/affluencer-hub': {
    template: 'Learn more about {{tracking affiliate & influencer marketing traffic}}',
    links: [
      'https://kb.triplewhale.com/en/articles/6458728-track-affiliate-influencer-revenue-with-affluencer-hub',
    ],
  },
  '/attribution/customer-journeys': {
    template: 'Learn more about optimizing {{customer journeys}}',
    links: [
      'https://kb.triplewhale.com/en/articles/6126307-aggregated-customer-journeys-with-triple-pixel',
    ],
  },
  '/attribution/settings': {
    template: 'Learn more about {{how the Triple Pixel works}}',
    links: ['https://kb.triplewhale.com/en/articles/5960325-how-the-triple-pixel-works'],
  },
  '/attribution/traffic-rules': {
    template: 'Learn more about {{how Traffic Rules work}}',
    links: ['https://kb.triplewhale.com/en/articles/7004492-traffic-rules-for-triple-pixel'],
  },
  '/utm-builder': {
    template: 'Learn more about {{how the Triple Pixel works}}',
    links: ['https://kb.triplewhale.com/en/articles/5960325-how-the-triple-pixel-works'],
  },
  '/creative-cockpit/facebook-ads/ad': {
    template: 'Learn more about {{identifying high-performing creatives}}',
    links: [
      'https://kb.triplewhale.com/en/articles/6362638-analyze-creative-performance-with-creative-cockpit',
    ],
  },
  '/cdp/segments': {
    template: 'Learn more about {{creating & syncing customer segments}}',
    links: ['https://kb.triplewhale.com/en/articles/6926597-smart-customer-data-platform-scdp'],
  },
  '/insights/cohorts': {
    template: 'Learn more about {{cohort analysis and increasing customer lifetime value}}',
    links: ['https://kb.triplewhale.com/en/articles/5725663-customer-cohorts'],
  },
  '/product-analytics': {
    template: 'Learn more about {{product upsell and cross-sell}} opportunities',
    links: ['https://kb.triplewhale.com/en/articles/6969814-product-analytics'],
  },
  '/ltv/bar-view': {
    template: 'Learn more about {{retention & increasing customer lifetime value}}',
    links: ['https://kb.triplewhale.com/en/articles/5725663-customer-cohorts'],
  },
  '/ltv/time-between-orders': {
    template: 'Learn more about {{retention & increasing customer lifetime value}}',
    links: ['https://kb.triplewhale.com/en/articles/5725663-customer-cohorts'],
  },
  '/ltv/customer-journey': {
    template: 'Learn more about {{retention & increasing customer lifetime value}}',
    links: ['https://kb.triplewhale.com/en/articles/5725663-customer-cohorts'],
  },
  '/insights/cart-analysis': {
    template: 'Learn more about {{customer buying behavior}} & increasing average order value',
    links: ['https://kb.triplewhale.com/en/articles/5725676-cart-analysis'],
  },
  '/aov': {
    template: 'Learn more about {{customer buying behavior}} & increasing average order value',
    links: ['https://kb.triplewhale.com/en/articles/5725676-cart-analysis'],
  },
  '/post-purchase-survey/builder': {
    template:
      'Learn more about customer survey data & {{unlocking Total Impact attribution reporting}}',
    links: [
      'https://kb.triplewhale.com/en/articles/6486354-triple-whale-post-purchase-survey#h_b8786024df',
    ],
  },
  '/post-purchase-survey/report': {
    template:
      'Learn more about customer survey data & {{unlocking Total Impact attribution reporting}}',
    links: [
      'https://kb.triplewhale.com/en/articles/6486354-triple-whale-post-purchase-survey#h_b8786024df',
    ],
  },
  '/store-data': {
    template: 'Learn more about {{real-time profit tracking}} & managing business finances ',
    links: [
      'https://kb.triplewhale.com/en/articles/6119764-tracking-profits-setting-up-your-expenses',
    ],
  },
  '/pod-and-store-settings': {
    template: 'Learn more about {{managing multiple shops from a single Pod}}',
    links: ['https://kb.triplewhale.com/en/articles/6224580-managing-multiple-shops-pods-view'],
  },
  '/cost-settings': {
    template: 'Learn more about {{real-time profit tracking}} & managing business finances ',
    links: [
      'https://kb.triplewhale.com/en/articles/6119764-tracking-profits-setting-up-your-expenses',
    ],
  },
  '/api-keys': {
    template: 'Learn more about {{API integrations}}',
    links: [
      'https://kb.triplewhale.com/en/articles/8116412-creating-and-managing-api-keys-in-triple-whale',
    ],
  },
  '/integrations': {
    template: 'Don’t see an integration you need? Use the {{Triple Whale API}}',
    links: ['https://triplewhale.readme.io/reference/introduction-to-the-triple-whale-api'],
  },
  '/reports': {
    template: 'Learn more about {{report automation}}',
    links: [],
  },
  '/optimizations': {
    template: 'Learn more about {{setting up your account for success}}',
    links: [
      'https://kb.triplewhale.com/en/articles/5677051-getting-started-onboarding-to-triple-whale',
    ],
  },
};
