import axiosInstance from 'utils/axiosInstance';
import {
  DayOfWeek,
  DialectWithBoth,
  ScheduleItem,
  ScheduleSequenceParams,
} from '../types/willyTypes';
import { IndustryTypes } from '@tw/types/module/types/IndustryType';
import { v4 as uuidV4 } from 'uuid';
import _db from 'utils/DB';

type CreateScheduleParams = {
  sequenceId: string;
  days: DayOfWeek[];
  hours: number[];
  interval: string | null;
  userId: string;
  shopId: string;
  currency: string;
  timezone: string;
  scheduleIdToUpdate?: string;
  additionalShopIds: string[];
  dialect: DialectWithBoth;
  industry: IndustryTypes;
};

export async function createSchedule({
  sequenceId,
  days,
  hours,
  interval,
  userId,
  shopId,
  currency,
  timezone,
  scheduleIdToUpdate,
  additionalShopIds,
  dialect,
  industry,
}: CreateScheduleParams) {
  const scheduleId = scheduleIdToUpdate || uuidV4();
  try {
    const method = scheduleIdToUpdate ? axiosInstance.put : axiosInstance.post;
    const { data } = await method<any, any, ScheduleSequenceParams>(
      `/v2/sequences/schedules${scheduleIdToUpdate ? `/${scheduleIdToUpdate}` : ''}`,
      {
        shopId,
        sequenceId,
        scheduleId,
        currency,
        schedule: [
          {
            dayOfWeek: days,
            hour: hours,
          },
        ],
        interval,
        timezone,
        userId,
        additionalShopIds,
        dialect,
        industry,
      },
    );
  } catch (e) {
    throw e;
  }

  return scheduleId;
}

export async function deleteSchedule(
  sequenceId: string,
  scheduleId: string,
  shopId: string,
  userId: string,
) {
  const originalSchedule = await _db(shopId).collection('data_sequences').doc(sequenceId).get();
  if (!originalSchedule.exists) {
    return;
  }

  try {
    await _db(shopId)
      .collection('data_sequences')
      .doc(sequenceId)
      .set({ schedule: null }, { merge: true });
    await axiosInstance.delete(`/v2/sequences/schedules/${scheduleId}`, {
      data: {
        shopId,
        userId,
        sequenceId,
      },
    });
  } catch (e) {
    // we still want to remove the document from firestore even if the request to the server fails
    // because maybe it failed because the schedule was already deleted from temporal
    // (I couldn't find a way to check if a schedule exists in temporal without making the server to throw an error)
    // const data = originalSchedule.data();
    // if (originalSchedule.exists && !!data) {
    //   await getScheduleCollection(userId, shopId).doc(scheduleId).set(data);
    // }
    // throw e;
  }
}

export async function executeSequence(scheduleId: string, shopId: string, userId: string) {
  await axiosInstance.post(`/v2/sequences/schedules/${scheduleId}/execute`, {
    shopId,
    userId,
  });
}

export async function getScheduleInfo(scheduleId: string, shopId: string, userId: string) {
  const { data } = await axiosInstance.post(`/v2/sequences/schedules/get-schedule/${scheduleId}`, {
    shopId,
    userId,
  });
  return data;
}
