import {
  ChatResponseWorkflow,
  ChatResponseWorkflowEdit,
  ChatResponseWorkflowStarted,
  ToolProgress,
} from './../types/willyTypes';
import { useCallback, useEffect, useMemo } from 'react';
import {
  ChatResponseDone,
  ChatResponseError,
  ChatResponseInsight,
  ChatResponses,
  ChatResponseStarted,
  ChatResponseToolDone,
  ChatResponseToolStarted,
  ChatResponseToolStep,
  ChatSources,
  Message,
} from '../types/willyTypes';
import { openAiStopSequence, useWillySocket } from '../WillySocket';
import { $currentShopId } from '$stores/$shop';
import {
  analyticsEvents,
  chatActions,
  dashboardsActions,
  genericEventLogger,
  sqwhaleActions,
} from 'utils/dataLayer';
import { getToolName } from '../utils/willyUtils';
import { useStoreValue } from '@tw/snipestate';
import { v4 as uuidV4 } from 'uuid';
import { $socket } from '$stores/$socket';

type UseChatSocketProps = {
  source: ChatSources;
  currentConversationId?: string;
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  setWillyLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export function useChatSocket(props: UseChatSocketProps) {
  const { currentConversationId, source, setMessages, setWillyLoading } = props;

  const { socket } = useWillySocket();
  const currentShopId = useStoreValue($currentShopId);

  const currentAnalyticsEvent = useMemo(() => {
    return source === 'chat'
      ? analyticsEvents.CHAT
      : source === 'editor'
        ? analyticsEvents.SQWHALE
        : analyticsEvents.DASHBOARDS;
  }, [source]);

  const currentAnalyticsActionSet = useMemo(() => {
    return source === 'chat' || source === 'sequence'
      ? chatActions
      : source === 'editor'
        ? sqwhaleActions
        : dashboardsActions;
  }, [source]);

  const messageStart = useCallback(
    (msg: ChatResponseStarted) => {
      const { messageId, conversationId, shopId } = msg;
      if (currentConversationId !== conversationId || shopId !== currentShopId) {
        return;
      }
    },
    [currentConversationId, currentShopId],
  );

  const messageDone = useCallback(
    (msg: ChatResponseDone) => {
      const { conversationId, shopId } = msg;
      if (currentConversationId !== conversationId || shopId !== currentShopId) {
        return;
      }

      setWillyLoading(false);
    },
    [currentShopId, currentConversationId, setWillyLoading],
  );

  const messageError = useCallback(
    (msg: ChatResponseError) => {
      const { conversationId, shopId, messageId, error, originalQuestion } = msg;
      if (conversationId !== currentConversationId || shopId !== currentShopId) {
        return;
      }
      setWillyLoading(false);

      setMessages((messages) => {
        const lastMessage = messages.at(-1);
        let toolProgress: ToolProgress | undefined = undefined;
        if (lastMessage) {
          if (lastMessage.toolProgress) {
            toolProgress = {
              ...lastMessage.toolProgress,
              progress: 100,
              step: msg.error,
              error: true,
            };
          }
          return messages.map((m) => {
            if (m.id === lastMessage.id) {
              return {
                ...m,
                toolProgress,
              };
            }
            return m;
          });
        }
        return [
          ...messages,
          {
            id: messageId,
            role: 'assistant',
            text: error,
            error: msg.error,
            conversationId,
            originalQuestion,
          },
        ];
      });

      genericEventLogger(currentAnalyticsEvent, {
        action: currentAnalyticsActionSet.CHAT_RESPONSE,
        prompt_text: msg.error,
        response_type: 'error',
        conversationId: currentConversationId,
      });
    },
    [
      setMessages,
      currentConversationId,
      currentAnalyticsActionSet.CHAT_RESPONSE,
      currentAnalyticsEvent,
      setWillyLoading,
      currentShopId,
    ],
  );

  const messageInsight = useCallback(
    (msg: ChatResponseInsight) => {
      const { text, conversationId, shopId, messageId, originalQuestion } = msg;
      if (
        text === openAiStopSequence ||
        currentConversationId !== conversationId ||
        shopId !== currentShopId
      ) {
        return;
      }

      setMessages((messages) => {
        if (messages.some((m) => m.id === messageId)) {
          return messages.map((m) => {
            if (m.id === messageId) {
              const rawText = (m.text ?? '') + text;
              return {
                ...m,
                text: rawText,
                role: 'assistant',
              };
            }
            return m;
          });
        } else {
          return [
            ...messages,
            {
              id: messageId,
              role: 'assistant',
              text,
              conversationId,
              originalQuestion,
            },
          ];
        }
      });
    },
    [currentConversationId, currentShopId, setMessages],
  );

  const messageToolStarted = useCallback(
    (msg: ChatResponseToolStarted) => {
      const { conversationId, shopId, messageId, tool, originalQuestion, question } = msg;
      if (conversationId !== currentConversationId || shopId !== currentShopId) {
        return;
      }

      const name = getToolName(tool);

      setMessages((messages) => {
        if (messages.some((m) => m.id === messageId)) {
          return messages.map((m) => {
            if (m.id === messageId) {
              return {
                ...m,
                toolProgress: {
                  name: tool,
                  progress: 5,
                  messageId,
                  step: `Starting ${name} for "${question || originalQuestion || ''}"`,
                },
              };
            }
            return m;
          });
        } else {
          return [
            ...messages,
            {
              id: messageId,
              role: 'assistant',
              conversationId,
              originalQuestion,
              toolProgress: {
                name: tool,
                progress: 5,
                messageId,
                step: `Starting ${name} for "${question || originalQuestion || ''}"`,
              },
            },
          ];
        }
      });
    },
    [setMessages, currentShopId, currentConversationId],
  );

  const messageToolStep = useCallback(
    (msg: ChatResponseToolStep) => {
      const { delta, messageId, shopId, tool, conversationId, originalQuestion } = msg;

      const { progress, reason, content, contentLanguage } = delta;

      const stepMessage = reason.toLowerCase() === 'error' ? content ?? reason : reason;

      if (conversationId !== currentConversationId || shopId !== currentShopId) {
        return;
      }

      setMessages((messages) => {
        if (messages.some((m) => m.id === messageId)) {
          return messages.map((m) => {
            if (m.id === messageId) {
              return {
                ...m,
                toolProgress: {
                  name: tool,
                  progress,
                  messageId,
                  step: stepMessage,
                  body: (m.toolProgress?.body || '') + (content || ''),
                  language: contentLanguage || 'text',
                },
              };
            }
            return m;
          });
        } else {
          return [
            ...messages,
            {
              id: messageId,
              role: 'assistant',
              conversationId,
              originalQuestion,
              toolProgress: {
                name: tool,
                progress,
                messageId,
                step: stepMessage,
                body: content,
                language: contentLanguage || 'text',
              },
            },
          ];
        }
      });
    },
    [setMessages, currentConversationId, currentShopId],
  );

  const messageWorkflow = useCallback(
    (msg: ChatResponseWorkflow | ChatResponseWorkflowEdit | ChatResponseWorkflowStarted) => {
      const { workflowId, conversationId, shopId, originalQuestion, messageId, text } = msg;
      if (conversationId !== currentConversationId || shopId !== currentShopId) {
        return;
      }
      setMessages((old) => {
        return [
          ...old,
          {
            id: uuidV4(),
            role: 'assistant',
            conversationId,
            originalQuestion,
            text,
          },
        ];
      });
    },
    [currentConversationId, currentShopId, setMessages],
  );

  const messageToolDone = useCallback(
    (msg: ChatResponseToolDone) => {
      const { conversationId, shopId, toolResults, originalQuestion, messageId, question } = msg;
      if (conversationId !== currentConversationId || shopId !== currentShopId) {
        return;
      }

      const { name } = toolResults;

      let newMessage: Message = {
        id: messageId,
        role: 'tool',
        toolResults,
        toolsNames: [name],
        conversationId,
        originalQuestion,
        toolProgress: {
          name,
          progress: 100,
          step: `Finished analyzing for "${question}"`,
        },
      };

      newMessage.toolResults = toolResults;

      if (name === 'TextToPython') {
        newMessage = {
          ...newMessage,
          toolProgress: {
            ...newMessage.toolProgress!,
            body: toolResults.pythonCode,
            language: 'python',
          },
        };
      } else if (name === 'TextToSQL') {
        const nlqResponse = toolResults.nlqResponse;
        const {
          data,
          question,
          originalQuestion,
          dataColumns,
          dataType,
          verified,
          generatedQuery,
          dialect,
          visualizationType,
        } = nlqResponse || {};
        newMessage = {
          ...newMessage,
          dialect,
          question: question,
          originalQuestion,
          verified,
          toolProgress: {
            ...newMessage.toolProgress!,
            body: generatedQuery,
            language: 'sql',
          },
        };
      } else if (name === 'Forecasting') {
        const { message } = toolResults;
        const { dialect } = message || {};
        newMessage = {
          ...newMessage,
          dialect,
        };
      }

      setMessages((messages) => {
        if (messages.some((m) => m.id === newMessage.id)) {
          return messages.map((m) => {
            if (m.id === newMessage.id) {
              return {
                ...m,
                ...newMessage,
              };
            }
            return m;
          });
        }
        return [...messages, newMessage];
      });

      genericEventLogger(currentAnalyticsEvent, {
        action: currentAnalyticsActionSet.MESSAGE_DONE,
        prompt_text: msg.question,
        messageId: msg.messageId,
        response: 'data',
        conversationId: msg.conversationId,
        tools: msg.toolResults.name,
      });
    },
    [
      setMessages,
      currentShopId,
      currentConversationId,
      currentAnalyticsActionSet.MESSAGE_DONE,
      currentAnalyticsEvent,
    ],
  );

  const deleteLoadingMessage = useCallback(() => {
    setMessages((messages) => {
      return messages.filter((m) => !m.loading);
    });
  }, [setMessages]);

  const deleteBuildingMessage = useCallback(() => {
    setMessages((messages) => {
      return messages.filter((m) => !m.building);
    });
  }, [setMessages]);

  useEffect(() => {
    async function newChat(msg: ChatResponses) {
      deleteLoadingMessage();

      if (msg.type !== 'tool-build') {
        deleteBuildingMessage();
      }

      switch (msg.type) {
        case 'started':
          messageStart(msg);
          break;

        case 'done':
          messageDone(msg);
          break;
        case 'error':
          messageError(msg);
          break;

        case 'insight':
          messageInsight(msg);
          break;
        case 'tool-started':
          messageToolStarted(msg);
          break;
        case 'tool-step':
          messageToolStep(msg);
          break;
        case 'tool-done':
          messageToolDone(msg);
          break;
        case 'workflow_run':
        case 'workflow_edit':
        case 'workflow_started':
          messageWorkflow(msg);
          break;
        default:
          break;
      }
    }

    socket.on('chat-response', newChat);

    return () => {
      socket.off('chat-response', newChat);
    };
  }, [
    currentConversationId,
    currentAnalyticsActionSet.MESSAGE_DONE,
    currentAnalyticsEvent,
    socket,
    messageDone,
    messageError,
    messageInsight,
    messageStart,
    messageToolDone,
    messageToolStarted,
    messageToolStep,
    deleteLoadingMessage,
    deleteBuildingMessage,
    messageWorkflow,
  ]);
}
