import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TextInput, Select, Loader, Center } from '@tw/ui-components';
import type { RootState } from 'reducers/RootType';
import type { FC } from 'react';
import { getSpreadsheetsForAccount, getWorksheetsForSpreadsheet } from 'ducks/googleSheets';

interface IGoogleSheetsAccountSelectorProps {
  readonly value: string;
  readonly onChange: (value: string | null) => void;
  readonly label?: React.ReactNode;
  readonly disabled?: boolean;
}

export default function GoogleSheetsAccountSelector({
  value,
  onChange,
  label,
  disabled,
}: IGoogleSheetsAccountSelectorProps) {
  const accounts = useSelector((state: RootState) => state.activeGoogleSheetsAccounts);

  useEffect(() => {
    if (!value && accounts.length > 0) {
      onChange(accounts[0].id ?? '');
    }
  }, [value]);

  return (
    <Select
      searchable
      label={label || 'Select Google Sheets Account'}
      data={accounts?.map((a) => ({
        label: `${a.name} ${a.email ? `(${a.email})` : ''}`,
        value: a.id,
      }))}
      onChange={onChange}
      value={value}
      disabled={disabled}
    />
  );
}

const CenteredDotLoader: FC<{}> = () => (
  <Center my="sm">
    <Loader type="dots" />
  </Center>
);

const createNewSSOption = {
  label: 'Create New Spreadsheet',
  value: 'create_new',
};

type GoogleSheetsSpreadsheetSelectorProps = {
  label?: string;
  sheetsAccount: string;
  disabled?: boolean;
  id: string;
  onChange: (args: { id?: string; name?: string; error?: boolean }) => void;
  name: string;
  canCreateNew?: boolean;
};
export const GoogleSheetsSpreadsheetSelector: FC<GoogleSheetsSpreadsheetSelectorProps> = ({
  label,
  sheetsAccount,
  id,
  name,
  onChange,
  disabled,
  canCreateNew = true,
}: GoogleSheetsSpreadsheetSelectorProps) => {
  const [loading, setLoading] = useState(false);
  const [spreadsheets, setSpreadsheets] = useState<{ id: string; name: string }[]>([]);
  const [nameError, setNameError] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      if (!sheetsAccount) {
        return;
      }
      setLoading(true);
      const ssList = await getSpreadsheetsForAccount(sheetsAccount);
      setSpreadsheets(ssList);
      setLoading(false);
    })();
  }, [sheetsAccount]);

  const validate = (v: string | null) => {
    if (v && spreadsheets?.some((s) => s.name === v)) {
      setNameError('Spreadsheet name already exists');
      return true;
    }
    setNameError(null);
    return false;
  };

  const setName = (v: string | null) => {
    onChange({
      name: v ?? '',
      error: validate(v),
    });
  };

  const setId = (v: string | null) => {
    let opt = options.find((o) => o.value === v);
    if (opt && opt.value !== 'create_new') {
      onChange({
        id: opt.value,
        name: opt.label,
      });
    } else if (opt) {
      onChange({
        id: opt.value,
      });
    }
  };

  useEffect(() => {
    if (id !== 'create_new') {
      return;
    }
    onChange({ id: 'create_new', name: '' });
  }, [id]);

  const options = (canCreateNew ? [createNewSSOption] : []).concat(
    sheetsAccount && spreadsheets?.length > 0
      ? spreadsheets?.map((s) => ({
          label: s.name,
          value: s.id,
        }))
      : [],
  );

  return loading ? (
    <CenteredDotLoader />
  ) : (
    <>
      <Select
        searchable
        my="sm"
        label={label || 'Select A Spreadsheet'}
        data={options}
        onChange={setId}
        value={id}
        disabled={disabled}
      />
      {id === 'create_new' && (
        <TextInput
          my="sm"
          label="New Spreadsheet Name"
          value={name}
          error={nameError}
          onChange={setName}
          placeholder="My Cool Spreadsheet"
        />
      )}
    </>
  );
};

const createNewWSOption = {
  label: 'Create New Worksheet',
  value: 'create_new',
};

type GoogleSheetsWorksheetSelectorProps = {
  spreadsheetId: string;
  sheetsAccount: string;
  label?: string;
  disabled?: boolean;
  id: string;
  name: string;
  onChange: (args: { id?: string; name?: string; error?: boolean }) => void;
  canCreateNew?: boolean;
};
export const GoogleSheetsWorksheetSelector: FC<GoogleSheetsWorksheetSelectorProps> = ({
  label,
  spreadsheetId,
  sheetsAccount,
  id,
  name,
  onChange,
  disabled,
  canCreateNew = true,
}: GoogleSheetsWorksheetSelectorProps) => {
  const [loading, setLoading] = useState(false);
  const [worksheets, setWorksheets] = useState<{ id: string; title: string }[]>([]);
  const [nameError, setNameError] = useState<string | null>(null);

  const validate = (v: string | null) => {
    if (v && worksheets?.some((s) => s.title === v)) {
      setNameError('Worksheet name already exists');
      return true;
    }
    setNameError(null);
    return false;
  };

  const setName = (v: string | null) => {
    onChange({
      name: v ?? '',
      error: validate(v),
    });
  };

  const setId = (v: string | null) => {
    let opt = options.find((o) => o.value === v);
    if (opt && opt.value !== 'create_new') {
      onChange({
        id: opt.value,
        name: opt.label,
      });
    } else if (opt) {
      onChange({
        id: opt.value,
      });
    }
  };

  useEffect(() => {
    (async () => {
      setNameError(null);
      if (!sheetsAccount || !spreadsheetId || spreadsheetId === 'create_new') {
        return;
      }
      setLoading(true);
      const wsList = await getWorksheetsForSpreadsheet({ spreadsheetId, sheetsAccount });
      setWorksheets(wsList?.map((ws) => ({ id: '' + ws.id, title: ws.title })));
      setLoading(false);
    })();
  }, [sheetsAccount, spreadsheetId]);

  const options = (canCreateNew ? [createNewWSOption] : []).concat(
    worksheets.map((s) => ({
      label: s.title,
      value: s.id,
    })),
  );

  return loading ? (
    <CenteredDotLoader />
  ) : (
    <>
      {spreadsheetId && spreadsheetId !== 'create_new' && !!options.length && (
        <Select
          searchable
          my="sm"
          label={label || 'Select A Worksheet'}
          data={options}
          onChange={setId}
          value={id}
          disabled={disabled}
        />
      )}
      {canCreateNew && spreadsheetId && (id === 'create_new' || spreadsheetId === 'create_new') && (
        <TextInput
          my="sm"
          label="New Worksheet Name"
          value={name}
          error={nameError}
          onChange={setName}
          placeholder="My Cool Worksheet"
        />
      )}
    </>
  );
};
