import amplitude from 'amplitude-js';
import StatusIndicator from 'components/library/StatusIndicator/StatusIndicator';
import { BaseColumn } from 'components/library/TWTable/types';
import {
  CREATIVES_NO_COPY_MESSAGE,
  CreativeTypesElements,
  DISABLE_SHOW_IN_CHART_MESSAGE,
  MAX_ACTIVE_CREATIVES,
} from 'constants/creativeCockpit';
import { metrics } from 'constants/metrics/metrics';
import allServices from 'constants/services';
import ShowMoreText from 'components/ShowMoreText';
import { SelectableCreative } from 'types/creativeCockpit';
import { formatNumber } from 'utils/formatNumber';
import { ReactComponent as Arrow } from '../../../icons/arrow.svg';
import { Icon, SkeletonBodyText, Tooltip } from '@shopify/polaris';
import { ExternalMinor, QuestionMarkMinor } from '@shopify/polaris-icons';
import { ServicesIds } from '@tw/types/module/services';
import { OrdersButton } from 'components/attribution-new/OrdersButton';
import CreativeThumbnail from '../CreativeThumbnail/CreativeThumbnail';
import CreativeTextField from './CreativeTextField';
import { SnoozeProduct } from 'pages/ProductAnalytics/SnoozeProduct';
import PixelIconSVG from 'components/PixelIconSVG';
import { calculateDelta } from 'utils/calculate-delta';
import { MetricsKeys } from '@tw/types/module/metrics/metrics';
import { CreativeTypes } from '@tw/types';
import { LockedElement } from 'components/library/LockedFeatures/LockedElement';
import { properCase } from 'utils/properCase';
import { TableCellLoader } from 'components/library/Loaders/TableCellLoader';
import PicturesGallery from 'components/ProductAnalytics/PicturesGallery';
import { sum } from '@tw/stats/module/generalUtils';
import { safeDivide } from 'utils/attributions';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { windowSize } from 'utils/classes/WindowSizeObserver';
import { Checkbox } from '@tw/ui-components';
import TripleWhaleIconBase64 from 'components/TripleWhaleIconBase64';

export type CreativeColumnProps = {
  index?: number;
  showInTypes: (CreativeTypes | 'all')[];
  showInServices?: ServicesIds[];
  availableOnCustomMetrics?: boolean;
};

export type CreativeColumn = BaseColumn<
  SelectableCreative,
  | MetricsKeys
  | 'showInGraph'
  | 'preview'
  | 'product'
  | 'type'
  | 'numberOfAds'
  | 'campaign'
  | 'adset'
  | 'link'
  | 'snooze'
  | 'leadTime'
  | 'productStatus'
> &
  CreativeColumnProps;
const headingDarkCss = 'text-[#97B2D0] w-full pl-2 flex items-left justify-left';
const previewColumn: CreativeColumn = {
  key: 'preview',
  name: 'Preview',
  isDefault: true,
  isFixed: true,
  sortable: false,
  dataType: 'text',
  showInTypes: ['ad', 'adName', 'copy', 'image', 'video', 'Segments'],
  Heading: (metadata) => {
    const { type, isDarkMode, viewBy } = metadata;

    if (type === 'product') {
      return (
        <div
          className={`${
            isDarkMode
              ? headingDarkCss
              : 'header-wrapper pl-2 flex items-left gap-4 font-medium justify-left'
          }`}
        >
          <span className="capitalize">{viewBy ? viewBy : 'Product'}</span>
        </div>
      );
    }
    return (
      <>
        {type === 'ad'
          ? 'Ad'
          : type === 'copy'
            ? 'Copy'
            : type === 'Segments'
              ? 'Segments'
              : 'Creative'}
      </>
    );
  },
  Value: (creative, metadata) => {
    const { setCreativeInModal, setSkusModalProduct, itemType, loadingColumn } = metadata;
    const width = ['image', 'video'].includes(creative.assetType)
      ? 60
      : windowSize.isSmall
        ? 150
        : 230;
    return (
      <div style={{ width }}>
        {creative.assetType !== 'copy' && (
          <div className="creative-preview cursor-pointer flex gap-4 items-center">
            {creative.assetType === 'ad' && (
              <>
                <StatusIndicator status={creative.status} />
              </>
            )}
            <div className="flex-shrink-0">
              <CreativeThumbnail
                creative={creative}
                onClick={() => setCreativeInModal?.(creative)}
              />
            </div>
            {itemType === 'products' ? (
              <p
                style={{ overflowWrap: 'anywhere' }}
                className={`${!loadingColumn && 'text-logo'}`}
                onClick={() => !loadingColumn && setSkusModalProduct?.(creative)}
              >
                {creative.formattedName || creative.name}
              </p>
            ) : (
              <p style={{ overflowWrap: 'anywhere' }}>{creative.formattedName || creative.name}</p>
            )}
          </div>
        )}
        {creative.assetType === 'copy' && (
          <div className="overflow-y-auto overflow-x-hidden h-40">
            <ShowMoreText lines={3}>{creative.body || CREATIVES_NO_COPY_MESSAGE}</ShowMoreText>
          </div>
        )}
      </div>
    );
  },
};

export const baseColumns: CreativeColumn[] = [
  {
    key: 'showInGraph',
    name: 'Show In Graph',
    isDefault: true,
    isFixed: true,
    sortable: false,
    dataType: 'text',
    showInTypes: ['ad', 'adName', 'copy', 'image', 'video', 'product'],
    Heading: () => (
      <div className="flex gap-4 items-center justify-center">
        <TripleWhaleIconBase64 small={true} />
      </div>
    ),
    Value: (creative, metadata) => {
      const { toggleCreativeSelection, selectedCreatives } = metadata;
      const showInChartCheckbox = (
        <Checkbox
          disabled={!creative.selected && (selectedCreatives?.length || 0) >= MAX_ACTIVE_CREATIVES}
          checked={creative.selected}
          onChange={() => toggleCreativeSelection!(creative)}
        />
      );
      return !creative.selected && (selectedCreatives?.length || 0) >= MAX_ACTIVE_CREATIVES ? (
        <>
          <Tooltip content={DISABLE_SHOW_IN_CHART_MESSAGE}>{showInChartCheckbox}</Tooltip>
        </>
      ) : creative.selected || (selectedCreatives?.length || 0) < MAX_ACTIVE_CREATIVES ? (
        <div className="flex gap-4 items-center justify-center">{showInChartCheckbox}</div>
      ) : (
        <></>
      );
    },
  },
  previewColumn,
  {
    ...previewColumn,
    key: 'product',
    name: 'Product',
    sortable: true,
    showInTypes: ['product'],
  },
  {
    key: 'type',
    name: 'Type',
    isDefault: true,
    sortable: false,
    dataType: 'text',
    showInTypes: ['video', 'image', 'copy', 'ad', 'adName', 'Segments'],
    Heading: () => <>Type</>,
    Value: (creative) => <>{CreativeTypesElements[creative.assetType].label}</>,
  },
  {
    key: 'numberOfAds',
    name: 'Number Of Ads',
    isDefault: true,
    sortable: false,
    dataType: 'text',
    showInTypes: ['video', 'image', 'copy', 'adName', 'product'],
    Heading: (metadata) => {
      const { isDarkMode } = metadata;
      return <div className={`${isDarkMode ? headingDarkCss : ''}`}># Ads</div>;
    },
    Value: (creative, metadata) => {
      const { loadingColumn, PRODUCT_NAME, setAdsModalProduct, type, adImgs } = metadata;
      const adImages = adImgs?.filter((ad) => ad.productId === creative.id) || [];

      return (
        <div className="flex gap-4 items-center justify-center max-w-[196px]">
          <LockedElement
            featureFlag={FeatureFlag.PIXEL_ALL_FF}
            tooltipContent={`Install Pixel to unlock the full potential of ${PRODUCT_NAME}!`}
            showBlur={true}
          >
            {loadingColumn ? (
              <TableCellLoader />
            ) : type === 'product' ? (
              creative.numberOfAds > 0 ? (
                <div className="pl-[4px] grid grid-cols-3">
                  <div
                    className=" relative cursor-pointer"
                    title={`${creative.numberOfAds} ads`}
                    onClick={() => setAdsModalProduct?.(creative)}
                  >
                    <PicturesGallery
                      images={adImages}
                      w={40}
                      h={40}
                      numImgsToDisplay={3}
                      overlap={true}
                    />
                  </div>
                </div>
              ) : (
                '-'
              )
            ) : (
              creative.numberOfAds
            )}
          </LockedElement>
        </div>
      );
    },
    Total: (totals, metadata) => {
      const { loadingColumn, type } = metadata || {};
      if (type !== 'product') return <></>;
      const value = sum(totals?.map((c) => c?.numberOfAds || 0));
      return (
        <div className="flex gap-4 items-center justify-center max-w-[196px]">
          {loadingColumn ? <TableCellLoader /> : value > 0 ? value : '-'}
        </div>
      );
    },
  },
  {
    key: 'campaign',
    name: 'Campaign',
    isDefault: true,
    sortable: false,
    dataType: 'text',
    showInTypes: ['ad'],
    Heading: () => <div className="w-48">Campaign</div>,
    Value: (creative) => <p style={{ wordBreak: 'break-word' }}>{creative.campaignName}</p>,
  },
  {
    key: 'adset',
    name: 'Adset',
    isDefault: true,
    sortable: false,
    dataType: 'text',
    showInTypes: ['ad'],
    Heading: () => <div className="w-48">Ad Set</div>,
    Value: (creative) => <p style={{ wordBreak: 'break-word' }}>{creative.adsetName}</p>,
  },
  {
    key: 'link',
    name: 'Link',
    isDefault: true,
    sortable: false,
    dataType: 'text',
    showInTypes: ['ad', 'product', 'sku'],
    Heading: (metadata) => {
      const { isDarkMode } = metadata;
      return (
        <div
          className={`${
            isDarkMode
              ? headingDarkCss
              : 'header-wrapper flex items-center font-medium justify-center'
          }`}
        >
          Link
        </div>
      );
    },
    Value: (creative, metadata) => {
      const { serviceId, isDarkMode } = metadata;
      const { assetType, productUrl } = creative;
      const isProduct = assetType === 'product' || assetType === 'sku';
      const logEventText = isProduct
        ? 'Product Analytics: Product'
        : 'Creative Cockpit: Ads Manager';

      return (
        <a
          target="_blank"
          href={
            isProduct
              ? productUrl
              : allServices[serviceId!]?.externalAdsUrl?.(
                  serviceId!,
                  'ad',
                  creative.account_id,
                  undefined,
                  undefined,
                  creative.id,
                ) || undefined
          }
          onClick={() => amplitude.getInstance().logEvent(`${logEventText} Link Clicked`)}
          rel="noreferrer"
        >
          {isDarkMode ? (
            <Icon source={ExternalMinor} color="highlight" />
          ) : (
            <Icon source={ExternalMinor} />
          )}
        </a>
      );
    },
  },
  {
    key: 'snooze',
    name: 'Snooze',
    isDefault: false,
    sortable: false,
    dataType: 'text',
    showInTypes: ['product', 'sku'],
    Heading: (metadata) => {
      const { isDarkMode } = metadata;
      return (
        <div
          className={`${
            isDarkMode
              ? headingDarkCss
              : 'header-wrapper flex items-center font-medium justify-center'
          }`}
        >
          Snooze
          <span className="absolute left-0 top-0">
            <Tooltip
              content={'Do not send lighthouse notification for this product'}
              dismissOnMouseOut
            >
              <span>
                <QuestionMarkMinor width={14} height={14} className="opacity-50" />
              </span>
            </Tooltip>
          </span>
        </div>
      );
    },
    Value: (product) => <SnoozeProduct product={product} />,
  },
  {
    key: 'leadTime',
    name: 'Lead Time',
    isDefault: false,
    sortable: false,
    dataType: 'numeric',
    showInTypes: ['product', 'sku'],
    Heading: (metadata) => {
      const { isDarkMode } = metadata;
      return (
        <div
          className={`${
            isDarkMode
              ? headingDarkCss
              : 'header-wrapper flex items-center font-medium justify-center'
          }`}
        >
          Lead Time
          <span className="absolute left-0 top-0">
            <Tooltip content={'Days to warn before running out of stock'} dismissOnMouseOut>
              <span>
                <QuestionMarkMinor width={14} height={14} className="opacity-50" />
              </span>
            </Tooltip>
          </span>
        </div>
      );
    },
    Value: (product) => <CreativeTextField creative={product} />,
  },
  {
    key: 'productStatus',
    name: 'Product Status',
    isDefault: false,
    sortable: true,
    dataType: 'text',
    showInTypes: ['product', 'sku'],
    Heading: () => (
      <div className="header-wrapper flex items-center font-medium justify-center">
        Product Status
      </div>
    ),
    Value: (product) => <div className="text-center">{product.status}</div>,
  },
];

export const columnsToAddOnlyInProductAnalytics: CreativeColumn['key'][] = [
  'spend',
  'clicks',
  'impressions',
  'cpc',
  'ctr',
  'cpm',
  'revenue',
  'ncRevenue',
  'ncOrdersWithProduct',
  'ncGrossProfit',
  'ordersWithProduct',
  'itemsSoldAvg',
  'itemsSoldTotal',
  'itemsInInventory',
  'grossProfit',
  'returnRate',
  'rpr',
  'daysOfStock',
  'totalSold30',
  'avgSold30',
  'ltv60',
  'ltv90',
  'ltv180',
  'ltv365',
  'leadTime',
  'dateStockRunsOut',
  'contributionMargin',
  'contributionMarginPerUnit',
  'modePurchasePrice',
  'avgPurchasePrice',
  'addedToCartEvents',
];

const metricColumns: CreativeColumn[] = Object.values(metrics)
  .filter((m) => m.showInCreativeTable?.length || m.showInCreativeCard?.length)
  .map((metric) => ({
    key: metric.key,
    name: metric.label,
    isDefault: metric.showInCreativeByDefault,
    sortable: metric.allowOrderBy,
    dataType: 'text',
    showInTypes: metric.showInCreativeTable,
    availableOnCustomMetrics: true,
    showInServices: columnsToAddOnlyInProductAnalytics.includes(metric.key)
      ? [...(metric.showInServices ? metric.showInServices : []), 'triple-whale']
      : metric.showInServices,
    Heading: (metadata) => {
      const { serviceId, isDarkMode } = metadata;
      const service = allServices[serviceId!]!;
      const serviceIcon = service?.icon?.({ small: true });
      const pixelIcon = <TripleWhaleIconBase64 small={true} />;
      return (
        <div
          className={`${
            isDarkMode
              ? headingDarkCss
              : 'header-wrapper flex items-center gap-4 font-medium justify-center'
          }`}
        >
          {metric.type === 'ads' && serviceIcon}
          {metric.type === 'pixel' && pixelIcon}
          {metric.shortLabel}
          {metric.key !== 'spend' && (
            <span className="absolute left-0 top-0">
              <Tooltip content={metric.Tip({ serviceId })} dismissOnMouseOut>
                <span>
                  <QuestionMarkMinor width={14} height={14} className="opacity-50" />
                </span>
              </Tooltip>
            </span>
          )}
        </div>
      );
    },
    Value: (creative, metadata) => {
      const {
        currency,
        showComparisons,
        loadingCreatives,
        loadingColumn,
        PRODUCT_NAME,
        isDarkMode,
      } = metadata;
      const value = creative?.metrics[metric?.key];
      const valueStr =
        value === null
          ? '-'
          : formatNumber(value || 0, {
              style: metric?.format,
              currency,
              maximumFractionDigits: metric?.toFixed,
              minimumFractionDigits: metric?.toFixed,
            });
      const feature =
        metric.type === 'ads' || metric.type === 'pixel' ? FeatureFlag.PIXEL_ALL_FF : undefined;

      const tooltipContent = `Install ${properCase(
        feature?.toLowerCase() || '',
      )} to unlock the full potential of ${PRODUCT_NAME}!`;

      const currencyRegex = /[^\d.-]/g;
      const floatValue = parseFloat(valueStr.replace(currencyRegex, ''));
      const isLoadingCell =
        loadingColumn &&
        (floatValue === 0 || metric.key.includes('contributionMargin') || metric.key === 'roas');

      const prevPeriodItem = creative.comparisons;
      const prevPeriodItemValue = prevPeriodItem?.[metric.key];
      const percentageChange =
        !creative?.metrics[metric.key] || !prevPeriodItemValue
          ? null
          : calculateDelta(creative?.metrics[metric.key]!, prevPeriodItemValue);

      const deltaIsPositive =
        (percentageChange && percentageChange > 0 && !metric.valueIsNegative) ||
        (percentageChange! < 0 && metric.valueIsNegative);

      const valueColor =
        metric.key !== 'pixelProfit' && metric.key !== 'pixelCvDelta'
          ? 'text-inherit'
          : creative.metrics[metric.key] < 0
            ? 'text-negative'
            : creative.metrics[metric.key] > 0
              ? 'text-green'
              : 'text-inherit';

      const prevValueStr = loadingCreatives ? (
        <div className="w-6 h-6 flex items-center">
          <div className="flex-auto">
            <SkeletonBodyText lines={1} />
          </div>
        </div>
      ) : !prevPeriodItemValue ? (
        <></>
      ) : (
        <span className="text-secondary-text text-lg flex items-center gap-2">
          <span>
            {formatNumber(prevPeriodItemValue, {
              style: metric.format,
              currency,
              minimumFractionDigits: metric.toFixed,
              maximumFractionDigits: metric.toFixed,
            })}
          </span>
          <Arrow
            width={14}
            height={14}
            className={`arrow-icon ${deltaIsPositive ? 'text-green' : 'text-negative'} ${
              !percentageChange ? 'hidden' : percentageChange < 0 ? 'rotate-180' : ''
            }`}
          />
          {typeof percentageChange === 'number' && (
            <span className={`${deltaIsPositive ? 'text-green' : 'text-negative'}`}>
              {formatNumber(percentageChange, {
                style: 'percent',
                minimumFractionDigits: 0,
                maximumFractionDigits: 1,
              }).replace('-', '')}
            </span>
          )}
        </span>
      );

      return creative.assetType === 'ad' &&
        (metric.key === 'pixelPurchases' ||
          metric.key === 'pixelConversionValue' ||
          metric.key === 'pixelRoas') ? (
        <>
          {
            <div className={'flex items-center justify-center'}>
              <OrdersButton
                item={
                  {
                    name: creative.name!,
                    id: creative.id!,
                    ...creative.metrics,
                    entity: 'ad',
                    serviceId: metadata.serviceId,
                  } as any
                }
                metricKey={metric.key}
                showRoasButton={false}
                showOrdersButton={true}
              />
            </div>
          }
        </>
      ) : (
        <div
          className={`px-4 py-2 flex items-center h-full attribution-table-campaign-row
          ${metric.key === 'pixelRoas' && !isDarkMode ? 'triple-roas-row py-2 min-h-[40px]' : ''}`}
        >
          <div className="flex gap-4 items-center justify-center w-full">
            <LockedElement featureFlag={feature} tooltipContent={tooltipContent} showBlur={true}>
              {isLoadingCell ? (
                <TableCellLoader />
              ) : showComparisons ? (
                <>
                  <div className={valueColor}>{valueStr}</div>
                  <div className={valueColor}>{prevValueStr}</div>
                </>
              ) : (
                <div className={valueColor}>{valueStr}</div>
              )}
            </LockedElement>
          </div>
        </div>
      );
    },
    Total: (totals, metadata) => {
      if (!totals || !totals.length) return <></>;

      if (!totals.some((c) => c?.metrics[metric.key] !== null))
        return <div className="text-center">-</div>;

      const {
        currency = 'USD',
        numDays,
        showComparisons,
        loadingCreatives,
        loadingColumn,
        PRODUCT_NAME,
      } = metadata || {};
      // TODO: Fix the text here in tooltip.  Also for above
      const feature =
        metric.type === 'ads' || metric.type === 'pixel' ? FeatureFlag.PIXEL_ALL_FF : undefined;
      const tooltipContent = `Install ${properCase(
        feature?.toLowerCase() || '',
      )} to unlock the full potential of ${PRODUCT_NAME}!`;
      const metrics = totals?.map((c) => c?.metrics) || {};
      const comparisons = totals?.map((c) => c?.comparisons || {});
      const isLocalAvg: boolean = ['ltv60', 'ltv90', 'ltv180', 'ltv365', 'itemsSoldAvg'].includes(
        metric?.key,
      );
      const value = isLocalAvg
        ? safeDivide(
            metric?.calculateSum?.(metrics || []) || 0,
            metric?.key === 'itemsSoldAvg' ? numDays : totals?.length || 1,
          )
        : metric?.calculateSum?.(metrics || []) || 0;

      const valueStr = (
        <>
          {metric?.key === 'dateStockRunsOut' ? (
            <></>
          ) : (
            formatNumber(value, {
              currency,
              style: metric?.format,
              minimumFractionDigits:
                typeof metric?.minimumFractionDigits !== 'undefined'
                  ? metric?.minimumFractionDigits
                  : metric?.toFixed,
              maximumFractionDigits: metric?.toFixed,
            })
          )}
        </>
      );

      const prevPeriodItemValue = metric?.calculateSum?.(comparisons || []) || 0;
      const percentageChange =
        !value || !prevPeriodItemValue ? null : calculateDelta(value!, prevPeriodItemValue!);

      const deltaIsPositive =
        (percentageChange && percentageChange > 0 && !metric?.valueIsNegative) ||
        (percentageChange! < 0 && metric?.valueIsNegative);

      const prevValueStr = loadingCreatives ? (
        <div className="w-6 h-6 flex items-center">
          <div className="flex-auto">
            <SkeletonBodyText lines={1} />
          </div>
        </div>
      ) : !prevPeriodItemValue ? (
        <></>
      ) : (
        <span className="text-secondary-text text-lg flex items-center gap-2">
          <span>
            {formatNumber(prevPeriodItemValue, {
              style: metric?.format,
              currency,
              minimumFractionDigits: metric?.toFixed,
              maximumFractionDigits: metric?.toFixed,
            })}
          </span>
          <Arrow
            width={14}
            height={14}
            className={`${deltaIsPositive ? 'text-green' : 'text-negative'} ${
              !percentageChange ? 'hidden' : percentageChange < 0 ? 'rotate-180' : ''
            }`}
          />
          {typeof percentageChange === 'number' && (
            <span className={`${deltaIsPositive ? 'text-green' : 'text-negative'}`}>
              {formatNumber(percentageChange, {
                style: 'percent',
                minimumFractionDigits: 0,
                maximumFractionDigits: 1,
              })?.replace('-', '')}
            </span>
          )}
        </span>
      );

      const valueColor =
        (metric?.key !== 'pixelProfit' && metric?.key !== 'pixelCvDelta') || !value
          ? 'text-inherit'
          : value < 0
            ? 'text-negative'
            : value > 0
              ? 'text-green'
              : 'text-inherit';
      return (
        <div className="flex gap-4 items-center justify-center w-full">
          <LockedElement featureFlag={feature} tooltipContent={tooltipContent} showBlur={true}>
            {loadingColumn ? (
              <TableCellLoader />
            ) : showComparisons ? (
              <>
                <div className={valueColor}>{valueStr}</div>
                <div className={valueColor}>{prevValueStr}</div>
              </>
            ) : (
              <div className={'px-4 py-2 flex items-center justify-center ' + valueColor}>
                {valueStr}
              </div>
            )}
          </LockedElement>
        </div>
      );
    },
  }));

export const columns: CreativeColumn[] = [...baseColumns, ...metricColumns];
