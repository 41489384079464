import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useDarkMode } from 'dark-mode-control';
import { Box, OptionList, Popover, TextField, Tooltip } from '@shopify/polaris';
import { uniqBy } from 'lodash';
import { ButtonProps, IconName, Icon, Button as TwButton } from '@tw/ui-components';

type DropDownProps = {
  id?: string;
  options?: {
    value: any;
    label: React.ReactNode;
    shortLabel?: string;
    icon?: React.ReactNode;
  }[];
  size?: ButtonProps['size'];
  value: any;
  handleSelect: any;
  title?: string;
  disabled?: boolean;
  hint?: string;
  theme?: 'light' | 'dark' | 'none';
  showFilter?: boolean;
  zIndexOverride?: number;
  sections?: any[];
  extraElements?: React.ReactNode[];
  hideChevron?: boolean;
  loading?: boolean;
  customActivator?: React.ReactNode;
  allowMultiple?: boolean;
  closeOnSelect?: boolean;
  preventCloseOnChildOverlayClick?: boolean;
  onChange?: (value: any) => void;
  hideLabel?: boolean;
  buttonStyle?: React.CSSProperties;
  showManualArrow?: boolean;
};
const DropDown: React.FC<DropDownProps> = (props) => {
  const {
    options,
    size = 'sm',
    value,
    handleSelect,
    title = '',
    disabled = false,
    hint = '',
    theme = undefined,
    showFilter = false,
    zIndexOverride = 0,
    id,
    sections,
    extraElements,
    hideChevron = false,
    loading = false,
    customActivator,
    allowMultiple = false,
    closeOnSelect = true,
    preventCloseOnChildOverlayClick = false,
    onChange,
    buttonStyle,
    showManualArrow = false,
  } = props;

  const doDarkMode = useDarkMode();
  const darkTheme = theme === 'dark';
  const componentTheme = theme && theme !== 'none' ? theme : doDarkMode ? 'dark' : 'light';
  const [popoverActive, setPopoverActive] = useState(false);
  const [filter, setFilter] = useState('');
  const togglePopoverActive = useCallback(() => {
    setPopoverActive((popoverActive) => !popoverActive);
  }, []);

  useEffect(() => {
    if (onChange) {
      onChange(popoverActive);
    }
  }, [popoverActive, onChange]);

  const handleChange = useCallback(
    (selected: string[]) => {
      if (closeOnSelect) {
        setPopoverActive(false);
      }
      const selectedToChange = allowMultiple ? selected : selected[0];
      return handleSelect(selectedToChange);
    },
    [allowMultiple, handleSelect, closeOnSelect],
  );

  const activator = useMemo(() => {
    const allOptionsFromSections = uniqBy(
      [...(options || []), ...(sections?.flatMap((x) => x.options || []) || [])],
      'value',
    ).filter(Boolean);

    const selectedOptions = allowMultiple
      ? allOptionsFromSections.filter((opt) => value.includes(opt.value))
      : [allOptionsFromSections.find((opt) => opt?.value === value)].filter(Boolean);
    const label = allowMultiple
      ? title || selectedOptions.map((o) => o.shortLabel || o.label).join(', ')
      : selectedOptions[0]?.shortLabel || selectedOptions[0]?.label || title;
    const icon = !allowMultiple ? selectedOptions[0]?.icon : undefined;
    if (customActivator) {
      return (
        <div
          onClick={() => {
            if (!disabled && !loading) {
              togglePopoverActive();
            }
          }}
        >
          {customActivator}
        </div>
      );
    }
    return (
      <TwButton
        size={size}
        variant="activator"
        forceColorScheme={componentTheme}
        onClick={togglePopoverActive}
        disabled={disabled || loading}
        loading={loading}
        fullWidth
        rightSection={!showManualArrow ? 'caret-down' : undefined}
        style={buttonStyle}
      >
        {icon && (
          <span style={{ marginRight: '8px', display: 'inline-flex', alignItems: 'center' }}>
            <Icon name={icon as IconName} />
          </span>
        )}
        <span style={{ flex: 1, marginRight: '8px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {label}
        </span>
        {showManualArrow && (
          <span
            style={{
              position: 'absolute',
              right: '13px',
              display: 'inline-flex',
              alignItems: 'center',
            }}
          >
            <Icon name="caret-down" size={14} color="gray.4" />
          </span>
        )}
      </TwButton>
    );
  }, [
    options,
    sections,
    value,
    title,
    allowMultiple,
    customActivator,
    disabled,
    loading,
    componentTheme,
    togglePopoverActive,
    size,
    showManualArrow,
    buttonStyle,
  ]);

  const filterCb = useCallback(
    (option) => {
      const trimmed = filter.toLowerCase().trim().trimStart();
      return (
        !showFilter ||
        !trimmed?.length ||
        option.label?.toLowerCase?.()?.indexOf(trimmed) > -1 ||
        option.value?.toLowerCase().indexOf(trimmed) > -1
      );
    },
    [filter, showFilter],
  );

  return (
    <div
      id={id}
      className={`tw-nice ${theme === 'light' ? 'tw-nice-light' : ''} ${
        darkTheme && doDarkMode ? 'tw-nice-dark' : ''
      } ${value !== undefined ? 'tw-nice--hasValue' : ''}${
        hideChevron ? ' tw-nice--hideChevron' : ''
      }`}
    >
      <Popover
        preferredAlignment="left"
        active={popoverActive}
        preventCloseOnChildOverlayClick={preventCloseOnChildOverlayClick}
        zIndexOverride={zIndexOverride}
        activator={hint ? <Tooltip content={hint}>{activator}</Tooltip> : activator}
        onClose={togglePopoverActive}
        fullHeight={true}
      >
        {showFilter && (
          <div className="tw-input-inside-drop-down">
            <TextField
              clearButton
              placeholder="Search"
              value={filter}
              onChange={setFilter}
              label=""
              labelHidden
              onClearButtonClick={() => setFilter('')}
              autoComplete="off"
            />
          </div>
        )}
        <OptionList
          allowMultiple={allowMultiple}
          options={options?.filter(filterCb).map((option) => ({
            ...option,
            label: (
              <div className="flex items-center gap-4">
                {option.icon ? (
                  <Icon name={option.icon as IconName} />
                ) : (
                  <Icon name="triple-whale-logo" />
                )}
                <span>{option.label}</span>
              </div>
            ),
          }))}
          selected={allowMultiple ? value : [value]}
          onChange={handleChange}
          sections={sections?.map((x) => ({
            ...x,
            options: x.options.filter(filterCb).map((option) => ({
              ...option,
              label: (
                <div className="flex items-center gap-2">
                  {option.icon ? (
                    <Icon name={option.icon as IconName} />
                  ) : (
                    <Icon name="triple-whale-logo" />
                  )}
                  <span>{option.label}</span>
                </div>
              ),
            })),
          }))}
        />
        {extraElements && extraElements.length > 0 && (
          <Popover.Pane fixed>
            {extraElements.map((e, i) => (
              <Box
                padding="4"
                borderBlockStart={options?.length || sections?.length ? 'base' : undefined}
                key={`extra-element-${i}`}
              >
                {e}
              </Box>
            ))}
          </Popover.Pane>
        )}
      </Popover>
      <style>
        {hideChevron &&
          `
          .tw-nice--hideChevron .Polaris-Icon__Svg,
          .tw-nice--hideChevron .Polaris-Button__Icon {
            display: none; 
          }
        `}
      </style>
    </div>
  );
};

export default DropDown;
