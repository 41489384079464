import { Dialect } from '@tw/types';
import { FilterProperty, TimeFrame } from '@tw/types/module/services/insights';
import { DEFAULT_FILTERS_LIST } from '../Filters/constants';

export enum CohortsBrekdown {
  FIRST_ORDER_AT = 'first_order_at',
}

export enum CohortsMetric {
  LTV = 'ltv',
  TOTAL_SALES = 'total_sales',
  SIZE = 'size',
  RETENTION_RATE = 'retention_rate',
}

export type CohortMetricsObj = { [key in CohortsMetric]: number };

export type CohortRow = {
  key: any;
  size: number;
  NCPA: number;
  RPR: number;
  totalSpend: number;
  frames: {
    key: number;
    metrics: CohortMetricsObj;
    secondOrderOnlyMetrics?: CohortMetricsObj;
  }[];
};

export type CohortTable = {
  cohortBreakdown: CohortsBrekdown;
  cohortBreakdownTimeFrame?: TimeFrame;
  frameBreakDown: TimeFrame;
  cohorts: CohortRow[];
};

export type TableViewOptions = {
  isCumulative: boolean;
  showNCPAPayback: boolean;
  metric: CohortsMetric;
  secondOrderOnly: boolean;
  dialect?: Dialect;
};

export const TABLE_COLORS = [
  {
    background: '#EBF7FF',
    text: '#0C3B78',
    border: '#D8EFFF',
  },
  {
    background: '#D8EFFF',
    text: '#0C3B78',
    border: '#C5E7FE',
  },
  {
    background: '#C5E7FE',
    text: '#0C3B78',
    border: '#9DD6FD',
  },
  {
    background: '#9DD6FD',
    text: '#0C3B78',
    border: '#66BBF4',
  },
  {
    background: '#66BBF4',
    text: '#0C3B78',
    border: '#409AD6',
  },
  {
    background: '#409AD6',
    text: '#FFFFFF',
    border: '#2472A6',
  },
  {
    background: '#2472A6',
    text: '#FFFFFF',
    border: '#1A5378',
  },
];

export type CohortFiltersType = {
  breakdown: CohortsBrekdown;
};

const COHORTS_TRANSLATION = {
  [CohortsBrekdown.FIRST_ORDER_AT]: 'First order at',
  [CohortsMetric.TOTAL_SALES]: 'Total sales',
  [CohortsMetric.SIZE]: 'Number of customers',
  [CohortsMetric.RETENTION_RATE]: 'Retention rate',
  [CohortsMetric.LTV]: 'LTV',
  [TimeFrame.YEAR]: 'Year',
  [TimeFrame.MONTH]: 'Month',
  [TimeFrame.WEEK]: 'Week',
  [TimeFrame.DAY]: 'Day',
};

export const getOptionLabel = (option: string) => {
  return COHORTS_TRANSLATION[option] || option;
};

export const ALLOWED_FILTER_PROPS = DEFAULT_FILTERS_LIST.filter(
  (f) => !(f === FilterProperty.IS_FIRST_ORDER),
);
