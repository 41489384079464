import { useDataLayerTracking, useShopDomainSync } from '../Router/hooks';
import { Route, Routes } from 'react-router-dom';
import { OrcaIntegrations } from './OrcaIntegrations';
import NotFound from '../NotFound';
import { IframeComponents } from '../Router/utils';
import React from 'react';
import { ServicesIds } from '@tw/types/module/services';
import { Auth2Login } from './auth2/Auth2Login/Auth2Login';
import { Auth2Consent } from './auth2/Auth2Consent/Auth2Consent';
import { AppShell } from '@tw/ui-components';
import { OrcaSql } from './OrcaSql';
import { OrcaMoby } from './OrcaMoby';
import { MiniPopupsAndConfigs } from './miniPopupAndConfigs';
import { useStoreInit } from './hooks/useStoreInit';
import { OrcaAgent } from './OrcaAgent';

export type appDetailsType = {
  providers: ServicesIds[];
  appName: string;
};

export const OrcaApp = () => {
  useShopDomainSync();
  useDataLayerTracking();
  useStoreInit();

  return (
    <div className={'h-screen'}>
      <AppShell padding={0} bg="named.3">
        <Routes>
          <Route path="/orcabase/oauth2/login" element={<Auth2Login />} />
          <Route path="/orcabase/oauth2/consent" element={<Auth2Consent />} />
          {/*<Route element={<AuthWrapperOrca />}>*/}
          {/*  <Route path="/orcabase/signin" element={<OrcaSignin />} />*/}
          {/*  <Route path="/orcabase/signup" element={<OrcaSignup />} />*/}
          {/*</Route>*/}
          {/*<Route element={<ShopWrapperOrca />}>*/}
          <Route path="/orcabase/integrations" element={<OrcaIntegrations />} />
          <Route path="/orcabase/sql" element={<OrcaSql />} />
          <Route path="/orcabase/moby" element={<OrcaMoby />} />
          <Route path="/orcabase/agent" element={<OrcaAgent />} />
          {/*</Route>*/}
          <Route path="*" element={<NotFound />} />
        </Routes>
        <MiniPopupsAndConfigs />
        {/*<IframeComponents />*/}
      </AppShell>
    </div>
  );
};
