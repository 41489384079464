import { Icon, Kbd, Text, Tooltip } from '@tw/ui-components';
import { stepActionType, WorkflowStepVariable } from '../types/willyTypes';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { RichTextarea, RichTextareaHandle } from 'rich-textarea';
import { WillySimpleText } from '../WillySimpleText';
import { MdToolbar } from './MdToolbar';

type FlowStepWithTextProps = {
  text: string;
  variables: WorkflowStepVariable[];
  readOnly: boolean;
  toolConfig?: stepActionType;
  depth: number;
  editMode?: boolean;
  withToolbar?: boolean;
  allowComments?: boolean;
  textChange: (text: string) => void;
  setIsPristine: (isPristine: boolean) => void;
};

export const FlowStepWithText: React.FC<FlowStepWithTextProps> = ({
  text,
  variables,
  readOnly,
  toolConfig,
  depth,
  textChange,
  setIsPristine,
  editMode,
  withToolbar,
  allowComments,
}) => {
  const textAreaRef = useRef<RichTextareaHandle>(null);
  const placeholderRef = useRef<HTMLDivElement>(null);
  const [placeholderHeight, setPlaceholderHeight] = useState<number>(0);
  const [placeholderIndex, setPlaceholderIndex] = useState<number>(0);
  const [selection, setSelection] = useState<{ start: number; end: number } | null>(null);

  const placeholder = useMemo(() => {
    if (!toolConfig?.examplePrompts) {
      return '';
    }
    const index =
      ((placeholderIndex % toolConfig?.examplePrompts.length) + toolConfig?.examplePrompts.length) %
      toolConfig?.examplePrompts.length;
    return toolConfig?.examplePrompts[index];
  }, [placeholderIndex, toolConfig]);

  const saveDraft = useCallback(
    (promptText: string) => {
      textChange(promptText);
    },
    [textChange],
  );

  const keyHit = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Tab') {
        e.preventDefault(); // Prevent default tab behavior
        textChange(placeholder);
        setIsPristine(false);
      }
    },
    [placeholder, textChange, setIsPristine],
  );

  useLayoutEffect(() => {
    if (placeholderRef.current) {
      setPlaceholderHeight(placeholderRef.current.offsetHeight);
    }
  }, [placeholderRef.current?.offsetHeight]);

  return (
    <>
      {readOnly ? (
        <Text fw={500} fz={14} py="5.5px" px="12px">
          {text}
        </Text>
      ) : (
        <>
          {withToolbar && !editMode && (
            <MdToolbar text={text} textChange={textChange} selection={selection} />
          )}
          <div className="w-full z-30 relative" style={{ minHeight: `${placeholderHeight}px` }}>
            {!editMode && (
              <div className="flex flex-col gap-4">
                <RichTextarea
                  ref={textAreaRef}
                  onSelect={(event) => {
                    const start = (event.target as HTMLTextAreaElement).selectionStart;
                    const end = (event.target as HTMLTextAreaElement).selectionEnd;
                    setSelection({ start, end });
                  }}
                  value={text}
                  onChange={(e) => saveDraft(e.target.value)}
                  onKeyDown={(e) => {
                    setIsPristine(false);
                    keyHit(e);
                  }}
                  autoHeight
                  spellCheck={false}
                  autoCorrect="off"
                  autoCapitalize="off"
                  autoComplete="off"
                  style={{
                    fontSize: '14px',
                    fontWeight: '500',
                    border: 'none',
                    position: 'relative',
                    width: `calc(700px - ${depth === 0 ? 25 : depth * 50}px)`,
                    zIndex: 30,
                    outline: 'none',
                    cursor: 'text',
                    resize: 'none',
                  }}
                >
                  {(text) => {
                    // Split the text into lines
                    const lines = text.split('\n');

                    return (
                      <>
                        {lines.map((line, lineIndex) => {
                          // Check if the line starts with '//' (comment)
                          const isComment = line.trimStart().startsWith('//');

                          if (isComment && allowComments) {
                            // Render commented lines without variable processing
                            return (
                              <div key={lineIndex} className="text-lime-800">
                                {line}
                                {lineIndex < lines.length - 1 && <br />}
                              </div>
                            );
                          } else {
                            // Process variables within the line
                            const vars = line.match(/{{[^}]+}}/g);
                            const textParts = line.split(/{{[^}]+}}/g);

                            const lineContent = textParts.map((part, index) => {
                              const v = vars?.[index];
                              const stepVar = variables?.find(
                                (v2) =>
                                  v2.key ===
                                  v?.replace(/\{\{\s*(.*?)\s*\}\}/g, (_, p1) =>
                                    p1.replace(/\s+/g, ''),
                                  ),
                              );

                              return (
                                <span key={index}>
                                  {part}
                                  {!!v && (
                                    <Tooltip label={stepVar?.value || 'Variable not found'}>
                                      <span
                                        className={`underline decoration-dotted ${
                                          !!stepVar?.value ? 'text-blue-500' : 'text-red-500'
                                        }`}
                                      >
                                        {v}
                                      </span>
                                    </Tooltip>
                                  )}
                                </span>
                              );
                            });

                            return (
                              <div key={lineIndex}>
                                {lineContent}
                                {lineIndex < lines.length - 1 && <br />}
                              </div>
                            );
                          }
                        })}
                      </>
                    );
                  }}
                </RichTextarea>
              </div>
            )}
            {!!editMode && <WillySimpleText text={text} />}
            {!text && (
              <div className="flex flex-col gap-5 absolute top-[3px]" ref={placeholderRef}>
                <div className="">
                  <span className="text-[14px] font-semibold text-gray-400 mr-2">
                    {placeholder}
                  </span>
                  <span className="cursor-pointer relative z-30">
                    <Kbd onClick={() => textChange(placeholder)}>Tab</Kbd>
                  </span>
                </div>
                <div
                  className="cursor-pointer z-30"
                  onClick={() => {
                    setPlaceholderIndex(placeholderIndex + 1);
                  }}
                >
                  <Icon name="refresh" />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
