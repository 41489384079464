import {
  ActionList,
  Button as PolarisButton,
  List,
  Listbox,
  Popover,
  TextField,
  Tooltip,
} from '@shopify/polaris';
import Editor, { DiffEditor, Monaco } from '@monaco-editor/react';
import { useDarkMode } from 'dark-mode-control';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  CircleAlertMajor,
  InfoMinor,
  PlayMinor,
  StopMajor,
  MobileVerticalDotsMajor,
} from '@shopify/polaris-icons';
import axiosInstance from 'utils/axiosInstance';
import { useSelector } from 'react-redux';
import { RootState, useAppSelector } from 'reducers/RootType';
import {
  DEFAULT_AXIS_DOMAIN,
  DEFAULT_DIALECT,
  MAX_ITEMS_PER_PAGE,
  MENU_SIZE,
} from 'components/Willy/constants';
import {
  EditorInstance,
  GridColumnOptions,
  LogItem,
  Message,
  MessageTypes,
  NlqResponse,
  SavedQuery,
  WillyMetric,
  WillyParameter,
  GridOptions,
  TileModes,
  AxisDomain,
  WidgetQuery,
  CodeExecutionResponse,
  DataFromWarehouseResponse,
  Dialect,
} from 'components/Willy/types/willyTypes';
import { MobileCancelMajor } from '@shopify/polaris-icons';
import copyToClipboard from 'utils/copyToClipboard';
import { v4 as uuidV4 } from 'uuid';
import { keywords } from './dataStuff/keywords';
import {
  answerNlqQuestion,
  createWillyMetricsFromRawData,
  extractSnippets,
  extractOptionalVariables,
  extractVariablesFromWhereClause,
  formatSqlSafely,
  convertDataToJson,
  executeRce,
  executeCustomQuery,
} from 'components/Willy/utils/willyUtils';
import {
  ActionIcon,
  Badge,
  Card,
  Flex,
  Icon,
  Loader,
  Modal,
  MultiSelect,
  Select,
  Tabs,
  Text,
  Button,
  TextInput,
  Checkbox,
  Textarea,
} from '@tw/ui-components';
import { ReactComponent as AiChatSpark } from 'components/Icons/ai-chat-spark.svg';
import { Allotment } from 'allotment';
import { editor } from 'monaco-editor';
import { VerifiedQuestions } from 'components/Willy/VerifiedQuestions';
import { AlanLoaderGray } from 'components/AlanLoader';
import { WillyPageWrapper } from 'components/Willy/WillyPageWrapper';
import { WillyWidget } from 'components/Willy/WillyWidget';
import { getSocket } from 'components/Willy/WillySocket';
import { useWillyTitle } from 'components/Willy/hooks/useWillyTitle';
import { AskAnSQWhaleExpert } from 'components/Willy/AskAnSQWhaleExpert';

import { SqlEditorTabs } from './SqlEditorTabs';
import _db, { toArray, userDb } from 'utils/DB';
import { useNavigate, useLocation } from 'react-router-dom';
import { SAVED_QUERIES_COLLECTION, initialComments } from './constants';
import { ChatWithQuery } from './ChatWithQuery';
import { SchemaTables } from './Schema';
import { $derived, useStoreValue, useWritableStore } from '@tw/snipestate';
import {
  SqlFilterBuilder,
  setFilterBuilder,
  useActiveFilterBuilder,
  FilterBuilderType,
} from './SqlFilterBuilder';
import {
  resetSqlStatusByMessageId,
  useAISqlBuilderForFilter,
} from './dataStuff/ai/useAISqlBuilderForFilter';
import { toast } from 'react-toastify';
import { capitalize, uniq } from 'lodash';
import { CodeAction } from 'components/Willy/WillySimpleText';
import { WillyEditMetric } from 'components/Willy/WillyEditMetric';
import ActiveCurrencyButton from 'components/ActiveCurrencyButton';
import ScrollToBottom from 'react-scroll-to-bottom';
import { WillyScrollToBottom } from 'components/Willy/WillyScrollToBottom';
import { WillyJson } from 'components/Willy/WillyJson';
import { WillyRawTable } from 'components/Willy/WillyRawTable';
import { type TabDescriptor, WillyPanelTabs } from 'components/Willy/WillyPanelTabs';
import { baseURL } from 'config';
import {
  $activeQuery,
  $allSnippets,
  $customTablesAndViews,
  $customViews,
  $errorSchema,
  $loadingSchema,
  $regularTables,
  $relatedSchema,
  $schemaInModal,
  $tables,
  $tabs,
} from '$stores/willy/$tables';
import { SavedQueries } from './SavedQueries';
import { WillySearchInput } from 'components/Willy/WillySearchInput';
import useDebounce from 'utils/useDebounce';
import { sleep } from 'utils/sleep';
import { analyticsEvents, genericEventLogger, sqwhaleActions } from 'utils/dataLayer';
import { BqColumn } from './dataStuff/columns/types';
import { UpgradePageFallBack } from 'feature-flag-system/components';
import { computeFeatureFlags, useFeatureFlag } from 'feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { PinMessage } from 'components/Willy/PinMessage';
import { cohortColor } from 'constants/general';
import { Snippets } from './Snippets';
import { SaveSnippet } from './SaveSnippet';
import { DatePickerSimple } from './DatePickerSimple';
import { createNewTab } from './createNewTab';
import firebase from 'firebase/compat/app';
import { $activeAccounts, $currency, $industry } from '../../$stores/$shop';
import { WillyFormGroup } from 'components/Willy/WillyFormGroup';
import { $willyRulePopup } from 'components/Willy/WillyRules/$stores';
import { createNewRule } from 'components/Willy/WillyRules/utils';
import { Rules } from './Rules';
import { $dialect, $isAdminClaim } from '$stores/$user';
import DropDown from 'components/ltv/DropDown';
import { useFilteredItems } from 'components/Willy/hooks/useFilteredItems';
import { extractCustomQueryColumns } from 'components/Willy/utils/queryFormatter';
import { BuilderTable } from '@tw/willy-data-dictionary/module/columns/types';
import { QueryValueRecord } from 'components/Willy/types/willyTypes';
import { emptyObject } from 'utils/emptyObject';
export type NlqQueryDoc = {
  sqlQuery: string;
  question: string;
  title?: string;
  id: string;
  userOwner: string;
  createdAt: firebase.firestore.Timestamp;
};

const emptyArr = [];
const userFilterOptions = [
  { value: 'user', label: 'Mine' },
  { value: 'store', label: 'Store' },
];

type FreeQueryProps = {
  initialQuery?: string;
  initialData?: string;
  language?: 'sql' | 'python';
  openNewTab?: boolean;
  queryVars?: string[];
  queryVarsValues?: QueryValueRecord;
  queryVarsValuesChanged?: (queryVarsValues: Record<string, any>) => void;
  queryChanged?: (query: string) => void;
  dataChanged?: (data: string) => void;
  /** Decide if nav resizer should show up */
  showResizer?: boolean;
  onParametersChanged?: (parameters: WillyParameter[]) => void;
  sourceIds?: { dashboardId: string; widgetId: string; onAdd?: () => void };
};

export const $activeFilterBuilder = $derived((get) => {
  const tabs = get($tabs);
  const activeEditor = tabs.find((t) => t.active);
  if (!activeEditor) return {} as FilterBuilderType;
  return activeEditor.filterBuilder;
});

type TabsValues = 'table' | 'json' | 'visualization' | 'console';

export const FreeQueryComponent: React.FC<FreeQueryProps> = ({
  initialQuery,
  initialData,
  language,
  queryVars,
  queryVarsValues,
  openNewTab,
  queryVarsValuesChanged,
  queryChanged,
  dataChanged,
  showResizer = false,
  onParametersChanged,
  sourceIds,
}) => {
  const doDarkMode = useDarkMode();
  const navigate = useNavigate();
  const { search } = useLocation();
  useAISqlBuilderForFilter();
  const isRunning = useRef<Record<string, boolean>>({});
  const monacoRef = useRef<Monaco>();
  const editorRef = useRef<editor.IStandaloneCodeEditor>();
  const shouldSuggest = useRef(true);
  const isInitialLoad = useRef(true);
  const activeAccounts = useStoreValue($activeAccounts);
  const tables = useStoreValue($tables);
  const isAdmin = useStoreValue($isAdminClaim);
  const regularTables = useStoreValue($regularTables);
  const loadingSchema = useStoreValue($loadingSchema);
  const errorSchema = useStoreValue($errorSchema);
  const relatedSchema = useStoreValue($relatedSchema);
  const dialect = useStoreValue($dialect);
  const industry = useStoreValue($industry);
  const customTablesAndViews = useStoreValue($customTablesAndViews);
  const snippets = useStoreValue($allSnippets);
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const user = useSelector((state: RootState) => state.user);
  const currency = useStoreValue($currency);
  const shopTimezone = useSelector((state: RootState) => state.shopTimezone);
  const [sqlResults, setSqlResults] = useState<NlqResponse>();
  const [widgetQueries, setWidgetQueries] = useState<WidgetQuery[]>([]);
  const [builderSetup, setBuilderSetup] = useState<BuilderTable>();
  const [snippetModalOpen, setSnippetModalOpen] = useState(false);
  const [selectedText, setSelectedText] = useState('');
  const [activeTab, setActiveTab] = useState('schema');
  const [userQueries, setUserQueries] = useState<NlqQueryDoc[]>(emptyArr);
  const [shopQueries, setShopQueries] = useState<NlqQueryDoc[]>(emptyArr);
  const [userQueriesPage, setUserQueriesPage] = useState(0); // TODO: pagination
  const [shopQueriesPage, setShopQueriesPage] = useState(0); // TODO: pagination
  const [queriesSearch, setQueriesSearch] = useState('');
  const [userFilter, setUserFilter] = useState(userFilterOptions[0].value);
  const [queriesLoading, setQueriesLoading] = useState(false);
  const [rawData, setRawData] = useState<Record<string, NlqResponse>>({});
  const [tabs, setTabs] = useWritableStore($tabs);
  const activeQuery = useStoreValue($activeQuery);
  const [type, setType] = useState<MessageTypes>('table');
  const [stacked, setStacked] = useState(false);
  const [breakdownMode, setBreakdownMode] = useState(false);
  const [skinny, setSkinny] = useState(false);
  const [yAxisDomain, setYAxisDomain] = useState<AxisDomain>(DEFAULT_AXIS_DOMAIN);
  const [hasConditionalFormatting, setHasConditionalFormatting] = useState(false);
  const [conditionalFormattingColor, setConditionalFormattingColor] = useState(cohortColor);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [allowDataOverflow, setAllowDataOverflow] = useState(false);
  const [dimension, setDimension] = useState('');
  const [wrapText, setWrapText] = useState(false);
  const [grid, setGrid] = useState('flex' as GridOptions);
  const [gridColumns, setGridColumns] = useState(2 as GridColumnOptions);
  const [twoColumnMobile, setTwoColumnMobile] = useState(false);
  const [tileMode, setTileMode] = useState('tile' as TileModes);
  const [incrementedStacked, setIncrementedStacked] = useState(false);
  const [metrics, setMetrics] = useState<WillyMetric[]>(emptyArr);
  const [parameters, setParameters] = useState<WillyParameter[]>(emptyArr);
  const [queryParameters, setQueryParameters] = useState<WillyParameter[]>(emptyArr);
  const [saveQueryModalOpen, setSaveQueryModalOpen] = useState(false);
  const [queryName, setQueryName] = useState('');
  const [savedQueries, setSavedQueries] = useState<SavedQuery[]>(emptyArr);
  const [savedQueriesSearch, setSavedQueriesSearch] = useState('');
  const [savingQuery, setSavingQuery] = useState(false);
  const [editorReady, setEditorReady] = useState(false);
  const [cursorPosition, setCursorPosition] = useState<{ lineNumber: number; column: number }>({
    lineNumber: 1,
    column: 1,
  });
  const [editorSettingsOpen, setEditorSettingsOpen] = useState(false);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [autofixLoading, setAutofixLoading] = useState(false);
  const [diffEditorValue, setDiffEditorValue] = useState<{ edited: string; old: string } | null>(
    null,
  );
  const [chatOpen, setChatOpen] = useState<boolean>(false);
  const [chatMinimized, setChatMinimized] = useState<boolean>(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [conversationId, setConversationId] = useState<string>('');
  const [queryCurrency, setQueryCurrency] = useState<string>(currency);
  const [editMetricModalOpen, setEditMetricModalOpen] = useState<{
    open: boolean;
    queryId?: string;
    metricId?: string;
  }>({ open: false });
  const [queryVarsOpen, setQueryVarsOpen] = useState(false);
  const [activeResultsTab, setActiveResultsTab] = useState('visualization');
  const [lastQueryDialect, setLastQueryDialect] = useState<Record<string, Dialect>>({});
  const [applyGlobalFilters, setApplyGlobalFilters] = useState(true);
  const { shouldNotBeSeen: isChatBlocked } = useFeatureFlag(FeatureFlag.CHAT_FF);

  const debouncedSavedQueriesSearch = useDebounce(savedQueriesSearch, 500);
  const filteredQueries = useFilteredItems([...shopQueries, ...userQueries], queriesSearch, [
    'question',
    'sqlQuery',
    'title',
  ]);

  const savedQueriesSearchResults = useMemo(() => {
    if (!debouncedSavedQueriesSearch) {
      return savedQueries;
    }
    return savedQueries.filter((q) =>
      q.name.toLowerCase().includes(debouncedSavedQueriesSearch.toLowerCase()),
    );
  }, [savedQueries, debouncedSavedQueriesSearch]);

  // const [queryParametersWithValues, setQueryParametersWithValues] = useState<QueryValueRecord>({});

  const queryVarsInActiveQuery = useMemo(() => {
    if (!activeQuery) {
      return emptyArr;
    }
    const queryVarsInActiveQuery = extractVariablesFromWhereClause(activeQuery);
    return queryVarsInActiveQuery;
  }, [activeQuery]);

  const optionalVariables = useMemo(() => {
    if (!activeQuery) {
      return [];
    }
    const optionals = extractOptionalVariables(activeQuery, queryVarsInActiveQuery);

    return optionals;
  }, [activeQuery, queryVarsInActiveQuery]);

  const activeEditorTab = useMemo(() => {
    return tabs.find((t) => t.active);
  }, [tabs]);

  const queryParametersWithValues = activeEditorTab?.queryParametersWithValues || emptyObject();

  const setQueryParametersWithValues = useCallback(
    (values: QueryValueRecord) => {
      setTabs((old) => {
        return old.map((t) => {
          if (t.model.id === activeEditorTab?.model.id) {
            return { ...t, queryParametersWithValues: values };
          }
          return t;
        });
      });
    },
    [setTabs, activeEditorTab?.model.id],
  );

  // mimic the React.SetStateAction<QueryValueRecord> type
  const setQueryParametersWithValuesCallback: React.Dispatch<
    React.SetStateAction<QueryValueRecord>
  > = useCallback(
    (action: React.SetStateAction<QueryValueRecord>) => {
      const newValue = typeof action === 'function' ? action(queryParametersWithValues) : action;
      setQueryParametersWithValues(newValue);
    },
    [setQueryParametersWithValues, queryParametersWithValues],
  );

  useEffect(() => {
    if (!queryVars?.length || !queryVarsValues || !Object.keys(queryVarsValues)?.length) {
      return;
    }
    if (queryParametersWithValues && Object.keys(queryParametersWithValues).length) {
      return;
    }
    const parametersWithValues = queryVars.reduce((acc, p) => {
      return {
        ...acc,
        [p]: queryVarsValues[p],
      };
    }, {} as QueryValueRecord);

    setQueryParametersWithValues(parametersWithValues);
  }, [queryVars, queryVarsValues, queryParametersWithValues, setQueryParametersWithValues]);

  const chatFocus = useMemo(() => {
    return messages?.length > 0 && chatOpen;
  }, [messages, chatOpen]);

  const activeEditorTypeIsQuery = useMemo(() => {
    return activeEditorTab?.savedQueryType === 'query' || !activeEditorTab?.savedQueryType;
  }, [activeEditorTab?.savedQueryType]);

  const resultsTabs = useMemo(() => {
    let tabs: TabDescriptor[] = [];

    if (activeEditorTab?.language === 'python') {
      tabs = [
        { value: 'json', label: 'JSON' },
        { value: 'table', label: 'Raw Table' },
        { value: 'console', label: 'Console' },
      ];
    } else {
      tabs = [
        { value: 'visualization', label: 'Visualization' },
        { value: 'table', label: 'Raw Table' },
        { value: 'json', label: 'JSON' },
        { value: 'console', label: 'Console' },
      ];
    }

    return tabs;
  }, [activeEditorTab?.language]);

  const findRelevantColumn = useCallback(
    (parameter: string) => {
      return regularTables.reduce(
        (acc, t) => {
          if (acc) {
            return acc;
          }
          return t.columns.find((c) => c.id === parameter) ?? acc;
        },
        null as BqColumn | null,
      );
    },
    [regularTables],
  );

  const autosaveTabs = useCallback(() => {
    const allTabsEditor = tabs.reduce(
      (acc, q) => {
        const modelId = q.model.id;

        return acc.concat({
          modelId,
          query: q.query,
          tabName: q.tabName,
          active: q.active,
          language: q.language,
          jsonData: q.jsonData,
          queryId: q.queryId,
        });
      },
      [] as {
        modelId: string;
        query: string;
        tabName?: string;
        active?: boolean;
        language: 'sql' | 'python';
        jsonData?: string;
        queryId?: string;
      }[],
    );

    const data = JSON.stringify({
      queries: allTabsEditor,
      shopId: currentShopId,
      userId: user?.uid,
    });

    navigator.sendBeacon(`${baseURL}/v2/willy/autosave`, data);
  }, [tabs, currentShopId, user.uid]);

  useEffect(() => {
    const beforeUnload = (e: BeforeUnloadEvent) => {
      autosaveTabs();
    };
    window.addEventListener('beforeunload', beforeUnload);
    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [autosaveTabs]);

  useEffect(() => {
    const queryParameters: WillyParameter[] = Object.entries(queryParametersWithValues || {})
      .map(([key, value]) => {
        if (!value) {
          return;
        }
        const existsInActiveQuery = activeQuery?.includes(`@${key}`);
        if (!existsInActiveQuery) {
          return;
        }
        const isOptional = optionalVariables?.includes(key);
        const column = findRelevantColumn(key);
        let options = column?.options?.map((o) => o.value);
        if (options?.length || value.options?.length) {
          const optionsToAdd = value.options || options;
          options = [...optionsToAdd, value.value];
        }
        const p: WillyParameter = {
          column: column?.id || key,
          value: value.value,
          query: value.query,
          isQueryParameter: true,
          operator: '=',
          options: [...new Set(options)],
          visible: !isOptional,
        };

        return p;
      })
      .filter((p) => !!p);

    setQueryParameters(queryParameters);
  }, [queryParametersWithValues, findRelevantColumn, optionalVariables, activeQuery]);

  const editorQueryVarsValues = useMemo(() => {
    if (!queryVarsInActiveQuery?.length) {
      return {};
    }

    // here....
    return queryVarsInActiveQuery.reduce((acc, p) => {
      return {
        ...acc,
        [p]: queryParametersWithValues[p]?.value || '',
      };
    }, {});
  }, [queryVarsInActiveQuery, queryParametersWithValues]);

  const disableRun = useMemo(() => {
    const disable =
      !activeEditorTab?.model?.id ||
      !activeQuery ||
      Object.keys(editorQueryVarsValues).some((p) => {
        if (optionalVariables?.includes(p)) {
          return false;
        }

        if (Array.isArray(editorQueryVarsValues[p])) {
          return !editorQueryVarsValues[p].length;
        }

        return !editorQueryVarsValues[p];
      });

    return disable;
  }, [activeEditorTab?.model?.id, activeQuery, editorQueryVarsValues, optionalVariables]);

  const codeActions = useMemo(() => {
    const actions: CodeAction[] = [
      {
        text: 'Use this query',
        onAction: (sql: string) => {
          if (!activeEditorTab?.model?.id) {
            return;
          }
          const formatted = formatSqlSafely(sql);

          genericEventLogger(analyticsEvents.SQWHALE, {
            action: sqwhaleActions.USE_THIS_QUERY,
            query: formatted,
          });

          setTabs((old) => {
            return old.map((t) => {
              if (t.model.id === activeEditorTab?.model.id) {
                return {
                  ...t,
                  query: formatted,
                };
              } else {
                return t;
              }
            });
          });
        },
        supportedLanguages: ['sql'],
      },
    ];

    return actions;
  }, [activeEditorTab?.model?.id, setTabs]);

  const filterBuilder = useActiveFilterBuilder();

  const queryFromQueryString = useMemo(() => {
    const params = new URLSearchParams(search);
    const query = params.get('query');

    return query;
  }, [search]);

  const dataFromQueryString = useMemo(() => {
    const params = new URLSearchParams(search);
    const data = params.get('data');

    return data;
  }, [search]);

  const languageFromQueryString = useMemo(() => {
    const params = new URLSearchParams(search);
    const language = params.get('language');

    return language;
  }, [search]);

  const defaultValue = useMemo(() => {
    if (initialQuery) {
      return initialQuery;
    }

    if (queryFromQueryString) {
      return queryFromQueryString;
    }
    return initialComments;
  }, [queryFromQueryString, initialQuery]);

  const activeRawData = useMemo(() => {
    if (!activeEditorTab?.model?.id) {
      return undefined;
    }
    return rawData?.[activeEditorTab.model.id];
  }, [rawData, activeEditorTab?.model?.id]);

  const activeMainQuery = useMemo(() => {
    return activeRawData?.queries?.find((q) => q.id === activeRawData?.queryId) ?? null;
  }, [activeRawData?.queryId, activeRawData?.queries]);

  useEffect(() => {
    if (!isInitialLoad.current || !activeEditorTab?.model?.id) {
      return;
    }
    isInitialLoad.current = false;
    const params = new URLSearchParams(search);
    const query = params.get('query');
    if (query) {
      const formattedQuery = formatSqlSafely(query);
      setTabs((old) => {
        return old.map((t) => {
          if (t.model.id === activeEditorTab?.model.id) {
            return {
              ...t,
              query: formattedQuery,
            };
          } else {
            return t;
          }
        });
      });
    }
  }, [search, activeEditorTab?.model?.id, setTabs]);

  useEffect(() => {
    if (!editorRef.current || !monacoRef.current) {
      return;
    }
    setTabs((old) => {
      if (!old.length) {
        const newTab = createNewTab(
          {
            index: 0,
            language: 'sql',
            schema: tables,
            editorRef: editorRef.current!,
            monacoRef: monacoRef.current!,
            name: 'untitled query',
            savedQueryType: 'query',
          },
          setTabs,
        );
        return [newTab];
      }
      return old.map((t) => ({ ...t, schema: tables }));
    });
  }, [setTabs, tables]);

  // unmount cleanup
  useEffect(() => {
    return () => {
      setTabs(emptyArr);
    };
  }, [setTabs]);

  useEffect(() => {
    const activeEditor = tabs.find((t) => t.active);
    if (!activeEditor || !activeEditorTab?.model?.id) {
      return;
    }
    const sqlBuilder = activeEditor.sqlBuilderAI;
    editorRef?.current?.updateOptions({ readOnly: sqlBuilder?.loading === true });
    if (sqlBuilder?.loading === true) {
      setTabs((old) => {
        return old.map((t) => {
          if (t.model.id === activeEditorTab?.model.id) {
            return {
              ...t,
              query: `--- building the query, please wait...
              ${sqlBuilder?.existingQuery?.trim() ?? ''}`,
            };
          }
          return t;
        });
      });
    }
    if (sqlBuilder?.text) {
      setTabs((old) => {
        return old.map((t) => {
          if (t.model.id === activeEditorTab?.model.id) {
            return {
              ...t,
              query: sqlBuilder?.text?.replace('```sql', '').replace('```', '') ?? '',
            };
          }
          return t;
        });
      });
    }
    if (sqlBuilder?.error) {
      toast.error(capitalize(sqlBuilder?.error));
      setTabs((old) => {
        return old.map((t) => {
          if (t.model.id === activeEditorTab?.model.id) {
            return {
              ...t,
              query: sqlBuilder?.existingQuery?.trim() ?? '',
            };
          }
          return t;
        });
      });
    }
    if (sqlBuilder?.text || sqlBuilder?.error) {
      resetSqlStatusByMessageId(sqlBuilder?.messageId);
    }
  }, [activeEditorTab?.model?.id, setTabs, tabs]);

  useEffect(() => {
    if (!activeEditorTab?.model?.id) {
      return;
    }
    const q = activeEditorTab?.query;
    if (queryChanged) {
      queryChanged(q);
    }
  }, [activeEditorTab?.model?.id, activeEditorTab?.query, queryChanged]);

  useEffect(() => {
    if (!activeEditorTab?.model?.id) {
      return;
    }
    if (!dataChanged) {
      return;
    }
    const json = activeEditorTab?.jsonData;
    if (!json) {
      return;
    }
    dataChanged(json);
  }, [activeEditorTab?.model?.id, activeEditorTab?.jsonData, dataChanged]);

  const { title, setTitle } = useWillyTitle(activeRawData?.queryId!);

  const resultsLength = useMemo(() => {
    return sqlResults?.data?.[0]?.value?.length || 0;
  }, [sqlResults]);

  const willySocket = useMemo(() => getSocket(), []);

  const changeLoadingTabs = useCallback(
    (modelId?: string, loading?: boolean) => {
      setTabs((old) => {
        const tab = old.find((t) => t.model.id === modelId);
        if (!tab) return old;
        return old.map((t) => {
          if (t.model.id === modelId) {
            return { ...t, loading };
          }
          return t;
        });
      });
    },
    [setTabs],
  );

  const changeLogsTabs = useCallback(
    (modelId?: string, logs?: LogItem[]) => {
      setTabs((old) => {
        const tab = old.find((t) => t.model.id === modelId);
        if (!tab) return old;
        return old.map((t) => {
          if (t.model.id === modelId) {
            if (!logs?.length) {
              return { ...t, logs: [] };
            }
            return { ...t, logs: [...(t.logs || []), ...(logs || [])] };
          }
          return t;
        });
      });
    },
    [setTabs],
  );

  const changeErrorTabs = useCallback(
    (modelId?: string, error?: string) => {
      setTabs((old) => {
        const tab = old.find((t) => t.model.id === modelId);
        if (!tab) return old;
        return old.map((t) => {
          if (t.model.id === modelId) {
            return { ...t, error };
          }
          return t;
        });
      });

      if (!!error) {
        setActiveResultsTab('console');
        changeLogsTabs(modelId, [{ log: error, type: 'error', time: new Date() }]);

        const errorPosition = error.split('at [')[1];
        if (!errorPosition || !editorRef.current || !activeEditorTab?.model) {
          return;
        }
        const [line, column] = errorPosition.replace(']', '').split(':').map(Number);
        // const selection = new monacoRef.current!.Selection(line, column, line, column + 5);

        // editorRef.current?.setSelection(selection);
        if (!monacoRef.current) {
          return;
        }
        const owner = activeEditorTab.model.getLanguageId();

        monacoRef.current.editor.setModelMarkers(activeEditorTab?.model, owner, [
          {
            startLineNumber: line,
            startColumn: column,
            endLineNumber: line,
            endColumn: column + 5,
            message: error,
            severity: monacoRef.current.MarkerSeverity.Error,
          },
        ]);
      }
    },
    [setTabs, changeLogsTabs, activeEditorTab?.model],
  );

  const cancelRunningQuery = useCallback(() => {
    if (!activeEditorTab?.model?.id) {
      return;
    }
    isRunning.current = {
      ...isRunning.current,
      [activeEditorTab?.model.id]: false,
    };
    changeLoadingTabs(activeEditorTab?.model.id, false);

    setRawData((old) => {
      const newRawData = { ...old };
      delete newRawData[activeEditorTab?.model.id];
      return newRawData;
    });
  }, [activeEditorTab?.model?.id, changeLoadingTabs]);

  const executeQuery = useCallback(
    async (query: string, usePreAgg?: boolean) => {
      if (disableRun) {
        return;
      }
      if (!activeAccounts) {
        return;
      }
      const modelId = activeEditorTab?.model?.id;
      if (!modelId) {
        return;
      }
      const isPython = activeEditorTab?.language === 'python';
      const data = activeEditorTab?.jsonData;

      genericEventLogger(analyticsEvents.SQWHALE, {
        action: isPython ? sqwhaleActions.RUN_PYTHON : sqwhaleActions.RUN_QUERY,
        query,
      });

      isRunning.current = {
        ...isRunning.current,
        [modelId]: true,
      };

      setTitle('');
      changeErrorTabs(modelId, '');
      changeLoadingTabs(modelId, true);
      setSqlResults(undefined);
      setMetrics([]);

      let d: CodeExecutionResponse | NlqResponse;
      try {
        if (isPython) {
          const boartSignal = new AbortController().signal;
          d = await executeRce(boartSignal, query, data);
        } else {
          d = await executeCustomQuery({
            query,
            shopId: currentShopId,
            activeAccounts,
            currency,
            timezone: shopTimezone,
            queryParams: Object.fromEntries(
              Object.entries(queryParametersWithValues).map(([k, v]) => [k, v.value]),
            ),
            dialect: dialect || DEFAULT_DIALECT,
            usePreAgg,
            page: 1,
            pageSize: MAX_ITEMS_PER_PAGE,
            applyGlobalFilters,
          });
        }

        // user cancelled the query
        if (!isRunning.current[modelId]) {
          return;
        }
        changeLoadingTabs(modelId, false);

        if (isPython) {
          const { filesOutput } = d as CodeExecutionResponse;
          const data: NlqResponse = {
            data: filesOutput?.data || [],
            dataColumns: filesOutput?.dataColumns || { x: [], y: [] },
          };
          setRawData((old) => {
            if (!Object.keys(old || {})?.length) {
              return { [modelId]: data };
            }
            return {
              ...old,
              [modelId]: d as NlqResponse,
            };
          });
        } else {
          setRawData((old) => {
            if (!Object.keys(old || {})?.length) {
              return { [modelId]: d as NlqResponse };
            }
            return {
              ...old,
              [modelId]: d as NlqResponse,
            };
          });

          setLastQueryDialect((old) => {
            return {
              ...old,
              [modelId]: dialect || DEFAULT_DIALECT,
            };
          });

          setQueryCurrency(currency);
          setActiveResultsTab((old) => {
            if (old === 'console') {
              return 'visualization';
            }
            return old;
          });
        }

        isRunning.current = {
          ...isRunning.current,
          [modelId]: false,
        };

        let logToAdd: LogItem[] = [];
        if (isPython) {
          logToAdd = [
            {
              log: `Code executed, run id: ${(d as CodeExecutionResponse).executionId}`,
              type: 'info',
              time: new Date(),
            },
            ...((d as CodeExecutionResponse).dataFiles?.[0]?.url
              ? [
                  {
                    log: `Code return the following file: ${
                      (d as CodeExecutionResponse).dataFiles?.[0]?.url
                    }`,
                    type: 'info',
                    time: new Date(),
                  } as LogItem,
                ]
              : []),
          ];
        } else {
          logToAdd = [
            {
              log: `Query executed in ${(d as NlqResponse).bq || 0} seconds`,
              type: 'info',
              time: new Date(),
            },
          ];
        }

        changeLogsTabs(modelId, logToAdd);

        if (isPython) {
          const { output, executionError, executionId, filesOutput, code, dataFiles } =
            d as CodeExecutionResponse;
          if (output) {
            changeLogsTabs(modelId, [{ log: output, type: 'info', time: new Date() }]);
          }
          if (executionError) {
            changeLogsTabs(modelId, [{ log: executionError, type: 'error', time: new Date() }]);
          }
          return;
        } else {
          const { question, queryId, generatedQuery, error } = d as NlqResponse;

          genericEventLogger(analyticsEvents.SQWHALE, {
            action: sqwhaleActions.SQL_RESULT,
            question,
            query: generatedQuery,
            has_data: (d as NlqResponse).data?.length > 0,
            is_error: error,
          });

          if (error) {
            changeErrorTabs(modelId, error);
            return;
          } else {
            const owner = activeEditorTab?.model.getLanguageId();
            monacoRef.current?.editor.setModelMarkers(activeEditorTab.model, owner, []);
            willySocket.emit('suggest-title', {
              generatedQuery: generatedQuery!,
              question,
              shopId: currentShopId,
              mode: 'title',
              queryId: queryId!,
            });
          }
        }
      } catch (e) {
        changeErrorTabs(modelId, e.message);
        changeLoadingTabs(modelId, false);
        setSqlResults(undefined);
        setMetrics([]);
      }
    },
    [
      activeEditorTab?.model,
      activeEditorTab?.language,
      activeEditorTab?.jsonData,
      setTitle,
      changeErrorTabs,
      changeLoadingTabs,
      currentShopId,
      activeAccounts,
      currency,
      queryParametersWithValues,
      changeLogsTabs,
      willySocket,
      shopTimezone,
      disableRun,
      dialect,
      applyGlobalFilters,
    ],
  );

  const getSelectedQuery = useCallback(() => {
    const selection = editorRef.current?.getSelection();
    const model = editorRef.current?.getModel();
    let selectedQuery = '';
    if (selection && model) {
      const selectedText = model.getValueInRange(selection);
      const isValidQuery = ['with', 'select'].some((k) =>
        selectedText?.toLowerCase().trim().startsWith(k),
      );
      if (isValidQuery) {
        selectedQuery = selectedText;
      }
    }

    return selectedQuery;
  }, []);

  const runQuery = useCallback(
    (usePreAgg?: boolean) => {
      if (!activeEditorTab?.model?.id) {
        return;
      }
      const selectedQuery = getSelectedQuery();

      const query =
        selectedQuery ||
        tabs.find((t) => t.model.id === activeEditorTab?.model?.id)?.query.replace(/;\s*$/, '');
      if (!query?.trim()) {
        return;
      }
      executeQuery(query, usePreAgg);
    },
    [activeEditorTab?.model?.id, executeQuery, tabs, getSelectedQuery],
  );

  const formatDocument = useCallback(async () => {
    if (!editorRef || !activeEditorTab?.model?.id) {
      return;
    }
    try {
      await editorRef.current?.getAction('editor.action.formatDocument')?.run();
      changeErrorTabs(activeEditorTab?.model.id, '');
    } catch (e) {
      changeErrorTabs(activeEditorTab?.model.id, e.message);
    }
  }, [editorRef, activeEditorTab?.model?.id, changeErrorTabs]);

  const toggleWrapLines = useCallback(() => {
    if (!editorRef) {
      return;
    }
    editorRef.current?.updateOptions({
      wordWrap: editorRef.current?.getOption(editor.EditorOption.wordWrap) === 'off' ? 'on' : 'off',
    });
  }, []);

  const saveQuery = useCallback(
    async (queryId?: string, tabName?: string) => {
      if (!activeEditorTab?.model?.id) {
        return;
      }
      const editorValue = activeEditorTab.query;
      const jsonData = activeEditorTab?.jsonData;

      try {
        const cols = extractCustomQueryColumns(editorValue) || [];
        if (queryId) {
          const obj: SavedQuery = {
            query: editorValue,
            name: tabName || 'Untitled',
            updatedAt: new Date(),
            language: activeEditorTab?.language || 'sql',
            id: queryId,
            columns: cols.map((c) => {
              return {
                id: c,
                name: c,
                type: 'string',
                title: c,
              };
            }),
          };

          if (jsonData) {
            obj.jsonData = jsonData;
          }
          await _db().collection(SAVED_QUERIES_COLLECTION).doc(queryId).set(obj, {
            merge: true,
          });
          genericEventLogger(analyticsEvents.SQWHALE, {
            action: sqwhaleActions.SAVE_QUERY,
            query_id: queryId,
            query_name: queryName,
          });
        } else {
          const docId = _db().collection(SAVED_QUERIES_COLLECTION).doc().id;
          const obj: SavedQuery = {
            id: docId,
            query: editorValue,
            updatedAt: new Date(),
            name: queryName,
            language: activeEditorTab?.language || 'sql',
            jsonData: jsonData || '',
            columns: cols.map((c) => {
              return {
                id: c,
                name: c,
                type: 'unknown',
                title: c,
              };
            }),
          };
          await _db().collection(SAVED_QUERIES_COLLECTION).doc(docId).set(obj, { merge: true });
          setTabs((old) => {
            return old.map((t) => {
              if (t.model.id === activeEditorTab?.model.id) {
                return {
                  ...t,
                  tabName: queryName,
                  touched: false,
                  queryId: docId,
                  active: true,
                };
              }
              return { ...t, active: false };
            });
          });
          genericEventLogger(analyticsEvents.SQWHALE, {
            action: sqwhaleActions.SAVE_NEW_QUERY,
            query_id: queryId,
            query_name: queryName,
          });
        }
        setQueryName('');
        changeErrorTabs(activeEditorTab?.model?.id, '');
      } catch (e) {
        changeErrorTabs(activeEditorTab?.model?.id, e.message);
      }
    },
    [
      activeEditorTab?.jsonData,
      activeEditorTab?.model.id,
      activeEditorTab?.language,
      activeEditorTab?.query,
      changeErrorTabs,
      queryName,
      setTabs,
    ],
  );

  useEffect(() => {
    setActiveResultsTab(activeEditorTab?.language === 'python' ? 'console' : 'visualization');
  }, [activeEditorTab?.language]);

  useEffect(() => {
    const editor = editorRef.current;
    const monaco = monacoRef.current;
    if (!editor || !monaco) {
      return;
    }
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter, () => {
      const query = editor.getValue();
      const selectedQuery = getSelectedQuery();
      if (!query?.trim()) {
        return;
      }
      executeQuery(selectedQuery || query);
    });
    const queryId = activeEditorTab?.queryId;
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS, () => {
      if (queryId === undefined || !activeEditorTypeIsQuery) {
        setSaveQueryModalOpen(true);
      } else {
        const tabName = activeEditorTab?.tabName;
        saveQuery(queryId, tabName);
      }
    });
  }, [
    editorRef,
    monacoRef,
    executeQuery,
    activeEditorTab?.queryId,
    saveQuery,
    activeEditorTab?.tabName,
    activeEditorTypeIsQuery,
    getSelectedQuery,
  ]);

  useEffect(() => {
    if (editorReady) {
      editorRef.current?.onDidChangeCursorPosition((e) => {
        const { lineNumber, column } = e.position;
        setCursorPosition({ lineNumber, column });
      });
    }
  }, [editorReady]);

  const updateMetrics = useCallback(async (id: string, data: WillyMetric[]) => {
    setMetrics(data);
  }, []);

  const updateQueries = useCallback(async (queries: WidgetQuery[]) => {
    setWidgetQueries(queries);
  }, []);

  const updateParameters = useCallback((data: WillyParameter[]) => {
    const queryParameters = data.filter((p) => p.isQueryParameter);
    const regularParameters = data.filter((p) => !p.isQueryParameter);
    setParameters(regularParameters);
    setQueryParameters(queryParameters);
  }, []);

  const allParameters = useMemo(() => {
    const uniqueParameters = (parameters || []).reduce((acc, p) => {
      if (acc.find((ap) => ap.column === p.column)) {
        return acc;
      }
      return [...acc, p];
    }, queryParameters);

    return uniqueParameters;
  }, [parameters, queryParameters]);

  useEffect(() => {
    if (onParametersChanged) {
      onParametersChanged(queryParameters);
    }
  }, [queryParameters, onParametersChanged]);

  useEffect(() => {
    const {
      data = [],
      parameters = [],
      error,
      serviceIds = [],
      queries = [],
      queryId,
      question,
      generatedQuery,
      dataType,
    } = activeRawData || {};

    if (!activeRawData || !activeEditorTab?.model?.id) {
      return;
    }
    if (error) {
      changeErrorTabs(activeEditorTab?.model.id, error);
      changeLoadingTabs(activeEditorTab?.model.id, false);
      setSqlResults(undefined);
      setMetrics([]);
      setParameters([]);
      setWidgetQueries([]);
      return;
    }

    const metrics = createWillyMetricsFromRawData({
      data,
      sqlQuery: generatedQuery || '',
      initialMetrics: emptyArr,
      servicesIds: serviceIds,
      dataType,
      visualizationType: activeRawData.visualizationType,
    });
    setMetrics(metrics);
    setParameters(parameters);

    let allQueries = queries;

    if (queryId) {
      const firstQuery = {
        id: queryId,
        question: question || '',
        metricsKeys: metrics.map((m) => m.key),
        query: generatedQuery || '',
      };
      allQueries = [firstQuery, ...allQueries];
    }

    setWidgetQueries(allQueries);

    setSqlResults(activeRawData);
  }, [activeRawData, activeEditorTab?.model?.id, changeLoadingTabs, changeErrorTabs]);

  useEffect(() => {
    (async () => {
      setQueriesLoading(true);
      const [{ data }, { data: data2 }] = await Promise.all([
        axiosInstance.get(`/v2/willy/user-queries?page=${userQueriesPage}&shopId=${currentShopId}`),
        axiosInstance.get(`/v2/willy/shop-queries?page=${shopQueriesPage}&shopId=${currentShopId}`),
      ]);
      setUserQueries(data.queries || []);
      setShopQueries(data2.queries || []);
      setQueriesLoading(false);
    })();
  }, [currentShopId, userQueriesPage, shopQueriesPage]);

  useEffect(() => {
    const unsubscribe = _db()
      .collection(SAVED_QUERIES_COLLECTION)
      .orderBy('createdAt', 'desc')
      .onSnapshot((snapshot) => {
        const queries = toArray(snapshot);
        setSavedQueries(queries);
      });

    return () => unsubscribe();
  }, []);

  const autofixError = useCallback(
    async (activeEditorTab: EditorInstance) => {
      const query = activeQuery;
      if (!query) {
        return;
      }
      setAutofixLoading(true);
      const queryWithError = query;
      const { data } = await axiosInstance.post('/v2/willy/autofix-query', {
        query: queryWithError,
        errorMessage: activeEditorTab?.error,
        shopId: currentShopId,
        relatedSchema,
      });
      setAutofixLoading(false);
      let fixedQuery = data.fixedQuery;
      if (fixedQuery.startsWith('Query: ')) {
        fixedQuery = fixedQuery.split('Query: ')[1];
      }
      setDiffEditorValue({
        edited: fixedQuery,
        old: queryWithError,
      });
    },
    [currentShopId, activeQuery, relatedSchema],
  );

  const acceptChanges = useCallback(() => {
    if (!activeEditorTab?.model?.id) {
      return;
    }
    setDiffEditorValue(null);
    setTabs((old) => {
      return old.map((t) => {
        if (t.model.id === activeEditorTab?.model?.id) {
          return {
            ...t,
            query: diffEditorValue?.edited!,
          };
        }
        return t;
      });
    });
  }, [activeEditorTab?.model?.id, setTabs, diffEditorValue?.edited]);

  const closeDiffEditor = useCallback(() => {
    setDiffEditorValue(null);
  }, []);

  useEffect(() => {
    return () => {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has('query')) {
        searchParams.delete('query');
      }

      if (searchParams.has('language')) {
        searchParams.delete('language');
      }

      navigate(
        {
          pathname: window.location.pathname,
          search: searchParams.toString(),
        },
        { replace: true },
      );
    };
  }, [navigate]);

  if (errorSchema && !loadingSchema) {
    return (
      <Text ta="center">
        {errorSchema} Please refresh the page or contact support if the issue persists.
      </Text>
    );
  }

  return (
    <>
      <WillyPageWrapper
        showResizer={showResizer}
        tabs={[
          { value: 'schema', label: 'Schema' },
          { value: 'templates', label: 'Templates' },
          { value: 'snippets', label: 'Snippets' },
          { value: 'rules', label: 'Rules' },
          { value: 'history', label: 'History' },
        ]}
        defaultSize={sourceIds ? 300 : undefined}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        sidePanelContent={
          activeTab === 'schema' ? (
            <>
              {tabs.find((t) => t.active)?.model?.id && (
                <SchemaTables
                  editorRef={editorRef}
                  setCustomViews={$customViews.set}
                  monacoRef={monacoRef}
                />
              )}
            </>
          ) : activeTab === 'snippets' ? (
            <Snippets editorRef={editorRef.current!} monacoRef={monacoRef.current} />
          ) : activeTab === 'rules' ? (
            <Rules editorRef={editorRef.current!} monacoRef={monacoRef.current} />
          ) : activeTab === 'history' ? (
            <>
              <div className="flex items-start pr-6 mb-6 sticky top-0 bg-white z-10">
                <div className="grow">
                  <WillySearchInput
                    value={queriesSearch}
                    onChange={setQueriesSearch}
                    placeholder="Search queries"
                  />
                </div>
                <div className="mt-6">
                  <DropDown
                    handleSelect={(v) => setUserFilter(v)}
                    options={userFilterOptions}
                    value={userFilter}
                  />
                </div>
              </div>
              <Listbox
                onSelect={(value) => {
                  if (!activeEditorTab?.model?.id) {
                    return;
                  }
                  setTabs((old) => {
                    return old.map((t) => {
                      if (t.model.id === activeEditorTab?.model.id) {
                        return {
                          ...t,
                          query: value,
                        };
                      }
                      return t;
                    });
                  });
                }}
              >
                {filteredQueries
                  .filter((q) => {
                    if (userFilter === 'store') {
                      return true;
                    }
                    return false;
                  })
                  .map((q, i) => (
                    <Listbox.Action key={`query_${i}`} value={q.sqlQuery}>
                      {/* {q.title || q.question} */}
                      <div className="flex flex-col gap-1 prose dark:prose-invert">
                        <div className="line-clamp-3">{q.sqlQuery}</div>
                      </div>
                    </Listbox.Action>
                  ))}
              </Listbox>
              {queriesLoading && (
                <div className="flex w-full h-full justify-center items-center">
                  <AlanLoaderGray />
                </div>
              )}
            </>
          ) : activeTab === 'templates' ? (
            <VerifiedQuestions
              withSearch={true}
              onClick={async (question) => {
                if (!activeEditorTab || !activeAccounts || !dialect) {
                  return;
                }
                changeLoadingTabs(activeEditorTab.model.id, true);
                changeErrorTabs(activeEditorTab.model.id, '');
                setTabs((old) => {
                  return old.map((t) => {
                    if (t.model.id === activeEditorTab.model.id) {
                      return {
                        ...t,
                        query: '-- translating question to SQL\n-- please wait\n',
                      };
                    }
                    return t;
                  });
                });

                try {
                  const response = await answerNlqQuestion({
                    source: 'chat',
                    shopId: currentShopId,
                    additionalShopIds: activeAccounts,
                    messageId: uuidV4(),
                    question,
                    conversationId: uuidV4(),
                    generateInsights: false,
                    stream: false,
                    returnQueryOnly: false,
                    currency,
                    timezone: shopTimezone,
                    relatedSchema,
                    dialect,
                    industry: industry || 'other',
                  });

                  const message = response.messages.find(
                    (m) => m.role === 'tool' && m.toolResults?.name === 'TextToSQL',
                  );

                  // only for TS
                  if (!message) {
                    return;
                  }
                  if (message.role !== 'tool') {
                    return;
                  }
                  if (message.toolResults?.name !== 'TextToSQL') {
                    return;
                  }
                  const toolResults = message?.toolResults;
                  if (!toolResults || toolResults.name !== 'TextToSQL') {
                    return;
                  }
                  const data = toolResults.nlqResponse;
                  const error = toolResults.errorMessages;
                  const allData = [...(response.data || []), data].filter((x) => !!x);

                  error?.forEach((e) => {
                    changeErrorTabs(activeEditorTab.model.id, e);
                  });

                  const queriesText = allData
                    ?.map(
                      (r, i) =>
                        `-- Query ${i + 1}: \n${r.generatedQuery || `-- Error: ${r.error || error?.join('\n')}`}\n`,
                    )
                    .join('\n');

                  setTabs((old) => {
                    return old.map((t) => {
                      if (t.model.id === activeEditorTab.model.id) {
                        return {
                          ...t,
                          query:
                            allData?.length > 0
                              ? formatSqlSafely(queriesText)
                              : `/*\n${response.text || toolResults.errorMessages?.join('\n')}\n*/`,
                        };
                      }
                      return t;
                    });
                  });
                } catch (e) {
                  changeErrorTabs(activeEditorTab.model.id, e.message);
                }
                changeLoadingTabs(activeEditorTab.model.id, false);
              }}
            />
          ) : null
        }
        mainContent={
          <div className="w-full h-full @container willy-contain-layout">
            <Allotment
              vertical
              onChange={([_, n]) =>
                setFilterBuilder((old) => ({
                  ...old,
                  viewPort: n,
                }))
              }
            >
              <Allotment.Pane
                maxSize={MENU_SIZE}
                minSize={MENU_SIZE}
                className="free-query-header flex items-center !overflow-auto"
              >
                <div className={`w-full ${doDarkMode ? 'tw-nice-dark' : ''} p-4`}>
                  <div className="flex items-center gap-4">
                    <>
                      <div className="whitespace-nowrap">
                        <Button
                          // loading={activeEditorTab?.loading}
                          disabled={disableRun}
                          rightSection={
                            activeEditorTab?.loading ? (
                              <StopMajor className="w-8 flex fill-white" />
                            ) : (
                              <PlayMinor className="w-8 flex fill-white" />
                            )
                          }
                          onClick={() => {
                            if (activeEditorTab?.loading) {
                              cancelRunningQuery();
                            } else {
                              runQuery();
                            }
                          }}
                        >
                          {activeEditorTab?.loading
                            ? 'Cancel'
                            : `Run ${isAdmin ? `(${dialect === 'bigquery' ? 'BQ' : 'CH'})` : ''}`}
                        </Button>
                      </div>
                      <div className="whitespace-nowrap">
                        <PolarisButton
                          loading={activeEditorTab?.loadingSave || savingQuery}
                          disabled={
                            !activeEditorTab?.model?.id ||
                            !activeQuery ||
                            activeEditorTab?.loading ||
                            !editorReady
                          }
                          onClick={async () => {
                            if (
                              activeEditorTab?.queryId === undefined ||
                              !activeEditorTypeIsQuery
                            ) {
                              setSaveQueryModalOpen(true);
                            } else {
                              const tabName = activeEditorTab?.tabName;
                              setSavingQuery(true);
                              await saveQuery(activeEditorTab.queryId, tabName);
                              await sleep(200);
                              setSavingQuery(false);
                            }
                          }}
                          connectedDisclosure={{
                            disabled:
                              !activeEditorTab?.model?.id ||
                              !activeQuery ||
                              activeEditorTab?.loading ||
                              !editorReady,
                            actions: [
                              {
                                content: 'Save as new',
                                disabled:
                                  !activeEditorTab?.model?.id ||
                                  !activeQuery ||
                                  activeEditorTab?.loading ||
                                  !editorReady,
                                onAction: () => {
                                  setSaveQueryModalOpen(true);
                                },
                              },
                              {
                                content: 'Save Selection as Snippet',
                                disabled:
                                  !activeEditorTab?.model?.id ||
                                  !activeQuery ||
                                  activeEditorTab?.loading ||
                                  !editorReady,
                                onAction: () => {
                                  const selection = editorRef.current?.getSelection();
                                  const model = editorRef.current?.getModel();
                                  let value = '';
                                  if (selection && model) {
                                    value = model.getValueInRange(selection);
                                    setSnippetModalOpen(true);
                                    setSelectedText(value);
                                  }
                                },
                              },
                              {
                                content: 'Save Selection as Rule',
                                disabled:
                                  !activeEditorTab?.model?.id ||
                                  !activeQuery ||
                                  activeEditorTab?.loading ||
                                  !editorReady,
                                onAction: () => {
                                  const selection = editorRef.current?.getSelection();
                                  const model = editorRef.current?.getModel();
                                  let value = '';
                                  if (selection && model) {
                                    value = model.getValueInRange(selection);
                                    $willyRulePopup.set({
                                      isOpen: true,
                                      query: value || model.getValue(),
                                      rule: null,
                                      ruleSaved: async (rule) => {
                                        await createNewRule(rule);
                                      },
                                    });
                                  }
                                },
                              },
                            ],
                          }}
                        >
                          {savingQuery ? 'Saving...' : 'Save Query'}
                        </PolarisButton>
                      </div>
                      <div>
                        <Popover
                          active={editorSettingsOpen}
                          onClose={() => {
                            setEditorSettingsOpen(false);
                          }}
                          activator={
                            <ActionIcon
                              size="lg"
                              variant="default"
                              disabled={!activeEditorTab?.model?.id || !activeQuery}
                              icon={'menu-vertical'}
                              onClick={() => {
                                setEditorSettingsOpen((x) => !x);
                              }}
                            />
                          }
                        >
                          <ActionList
                            onActionAnyItem={() => {
                              setEditorSettingsOpen(false);
                            }}
                            items={[
                              {
                                content: 'Copy',
                                disabled: !activeEditorTab?.model?.id || !activeQuery,
                                onAction: () => {
                                  if (!activeEditorTab?.model?.id) {
                                    return;
                                  }
                                  copyToClipboard(activeQuery);
                                },
                              },
                              {
                                content: 'Format',
                                disabled: !activeEditorTab?.model?.id || !activeQuery,
                                onAction: () => {
                                  formatDocument();
                                },
                              },
                              {
                                content: 'Wrap lines',
                                disabled: !activeEditorTab?.model?.id || !activeQuery,
                                active:
                                  editorRef.current?.getOption(editor.EditorOption.wordWrap) ===
                                  'on',
                                onAction: () => {
                                  toggleWrapLines();
                                },
                              },
                              {
                                content: (
                                  <Checkbox
                                    label="Apply global filters"
                                    checked={applyGlobalFilters}
                                    onChange={(checked) => {
                                      setApplyGlobalFilters(checked);
                                    }}
                                  />
                                ) as any,
                              },
                            ]}
                          />
                        </Popover>
                      </div>
                      <div>
                        <Button
                          variant="white"
                          disabled={!activeEditorTab?.model?.id || !activeQuery}
                          onClick={() => {
                            if (!activeEditorTab?.model?.id) {
                              return;
                            }
                            setTabs((old) => {
                              return old.map((t) => {
                                if (t.model.id === activeEditorTab?.model.id) {
                                  return {
                                    ...t,
                                    query: '',
                                    schema: tables,
                                  };
                                }
                                return t;
                              });
                            });
                            setSqlResults(undefined);
                            changeErrorTabs(activeEditorTab.model.id, '');
                            setMetrics([]);
                            setParameters([]);
                          }}
                        >
                          Clear
                        </Button>
                      </div>

                      {(Object.keys(editorQueryVarsValues).length > 0 ||
                        Object.keys(queryVars || []).length > 0) && (
                        <span className="whitespace-nowrap">
                          <PolarisButton
                            icon={
                              Object.keys(editorQueryVarsValues).some(
                                (p) => !editorQueryVarsValues[p],
                              )
                                ? CircleAlertMajor
                                : undefined
                            }
                            outline
                            destructive={Object.keys(editorQueryVarsValues).some((p) => {
                              if (optionalVariables?.includes(p)) {
                                return false;
                              }

                              if (Array.isArray(editorQueryVarsValues[p])) {
                                return !editorQueryVarsValues[p].length;
                              }
                              return !editorQueryVarsValues[p];
                            })}
                            onClick={() => {
                              setQueryVarsOpen((x) => !x);
                            }}
                          >
                            Query variables
                          </PolarisButton>
                        </span>
                      )}
                    </>

                    <div className="ml-auto flex gap-4 items-center">
                      {!!activeEditorTab?.error && !activeEditorTab.loading && (
                        <div className="flex gap-2 items-center overflow-hidden">
                          <Tooltip content={activeEditorTab.error} preferredPosition="below">
                            <div className="flex">
                              <InfoMinor className="w-6 h-6 fill-red-600 flex-shrink-0" />
                            </div>
                          </Tooltip>
                          <p
                            className="text-red-600 whitespace-nowrap overflow-hidden text-ellipsis"
                            style={{ wordBreak: 'break-all' }}
                          >
                            Error occurred, see details in the console
                          </p>
                          <PolarisButton
                            loading={autofixLoading}
                            onClick={() => {
                              autofixError(activeEditorTab);
                            }}
                          >
                            Autofix
                          </PolarisButton>
                        </div>
                      )}
                      <ActiveCurrencyButton />
                      <Tooltip content={isChatBlocked ? 'Upgrade to access Chat' : 'Chat'}>
                        <PolarisButton
                          icon={AiChatSpark}
                          pressed={chatOpen}
                          onClick={() => {
                            setChatOpen((x) => !x);
                            genericEventLogger(analyticsEvents.SQWHALE, {
                              action: sqwhaleActions.OPEN_CHAT,
                            });
                          }}
                          disabled={isChatBlocked}
                        />
                      </Tooltip>
                      <AskAnSQWhaleExpert />
                      <Badge color={'one.0'}>
                        <Text fw={500} size={'xs'} color={'one.5'}>
                          Beta
                        </Text>
                      </Badge>
                    </div>
                  </div>
                </div>
              </Allotment.Pane>
              <Allotment.Pane className="h-full w-full">
                <Allotment vertical className={`w-full h-full `}>
                  <Allotment.Pane>
                    <Allotment defaultSizes={[60, 20]} snap>
                      <Allotment.Pane snap={false}>
                        <SqlEditorTabs
                          customTables={customTablesAndViews}
                          editorRef={editorRef.current!}
                          monacoRef={monacoRef.current!}
                          tabNameChange={(modelId, name) => {
                            _db().collection(SAVED_QUERIES_COLLECTION).doc(modelId).set(
                              {
                                name,
                              },
                              {
                                merge: true,
                              },
                            );
                          }}
                        />
                        <Allotment
                          className="h-full flex"
                          defaultSizes={activeEditorTab?.language === 'python' ? [50, 50] : [100]}
                        >
                          <Allotment.Pane className={`h-full`}>
                            <div className="h-full flex flex-col">
                              {activeEditorTab?.language === 'python' && (
                                <Text px="sm" size="xs">
                                  Code (Python)
                                </Text>
                              )}

                              <Editor
                                loading={activeEditorTab?.loading}
                                language={activeEditorTab?.language}
                                onMount={async (editor, monaco) => {
                                  editorRef.current = editor;
                                  monacoRef.current = monaco;

                                  const newModel = editor.getModel()!;
                                  newModel.onDidChangeContent((e) => {
                                    setTabs((old) => {
                                      return old.map((t, i) => {
                                        if (t.model.id === newModel.id) {
                                          return {
                                            ...t,
                                            touched: true,
                                          };
                                        }
                                        return t;
                                      });
                                    });
                                  });
                                  newModel.onDidChangeContent((e) => {
                                    setTabs((old) => {
                                      return old.map((t, i) => {
                                        if (t.model.id === newModel.id) {
                                          return {
                                            ...t,
                                            touched: true,
                                          };
                                        }
                                        return t;
                                      });
                                    });
                                  });
                                  const newTab: EditorInstance = {
                                    model: newModel,
                                    state: editor.saveViewState(),
                                    tabName: 'Untitled tab 1',
                                    nameEditable: false,
                                    filterBuilder: { isPaneOpen: false },
                                    schema: tables,
                                    nameChanged: false,
                                    active: true,
                                    query: newModel.getValue(),
                                    language: 'sql',
                                  };

                                  if (queryFromQueryString) {
                                    newTab.query = queryFromQueryString;
                                  } else if (initialQuery) {
                                    newTab.query = initialQuery;
                                  }

                                  if (languageFromQueryString) {
                                    newTab.language = languageFromQueryString as 'sql' | 'python';
                                  } else if (language) {
                                    newTab.language = language;
                                  }

                                  if (dataFromQueryString) {
                                    newTab.jsonData = dataFromQueryString;
                                  } else if (initialData) {
                                    newTab.jsonData = initialData;
                                  }

                                  newTab.query = formatSqlSafely(newTab.query);
                                  let initialTabs = [newTab];
                                  if (user.uid && !queryFromQueryString && !initialQuery) {
                                    const userDoc = await userDb().get();
                                    const userData = userDoc.data();

                                    const { shops = {} } = userData || {};
                                    const shop = shops[currentShopId] || {};
                                    const { editorAutosaveTabs = [] } = shop;
                                    if (editorAutosaveTabs.length) {
                                      initialTabs = editorAutosaveTabs.map((t) => ({
                                        ...newTab,
                                        tabName: t.tabName,
                                        model: monaco.editor.createModel(t.query, 'sql'),
                                        query: t.query,
                                        active: t.active,
                                        language: t.language || 'sql',
                                        jsonData: t.jsonData,
                                        queryId: t.queryId,
                                      }));
                                    }

                                    if (openNewTab) {
                                      const newModel = monaco.editor.createModel(
                                        initialComments,
                                        'sql',
                                      );
                                      initialTabs = [
                                        ...initialTabs.map((t) => ({
                                          ...t,
                                          active: false,
                                        })),
                                        {
                                          ...newTab,
                                          model: newModel,
                                          active: true,
                                          query: initialComments,
                                        },
                                      ];
                                    }
                                  }

                                  setTabs(initialTabs);
                                  setEditorReady(true);

                                  editor.focus();
                                  editor.setPosition({
                                    column: Math.max(1, editor.getModel()!.getLineLength(1)),
                                    lineNumber: Math.max(1, editor.getModel()!.getLineCount()),
                                  });
                                  monaco.languages.registerHoverProvider('sql', {
                                    provideHover: async function (model, position, token) {
                                      // const word = model.getWordUntilPosition(position);
                                      const word = model.getWordAtPosition(position);

                                      if (!word) {
                                        return {
                                          contents: [],
                                        };
                                      }

                                      const table = tables.find((t) => {
                                        return t.columns.some((c) => c.name === word.word);
                                      });

                                      const field = table?.columns.find(
                                        (c) => c.name === word.word,
                                      );
                                      const possibleSnippet = snippets.find(
                                        (s) => s.name === word.word,
                                      );

                                      if (possibleSnippet) {
                                        return {
                                          contents: [
                                            {
                                              value: `**Snippet**`,
                                            },
                                            {
                                              value: possibleSnippet.name,
                                            },
                                            {
                                              value: `\`\`\`sql\n${possibleSnippet.snippet}\n\`\`\``,
                                            },
                                          ],
                                        };
                                      }

                                      if (!field) {
                                        return {
                                          contents: [],
                                        };
                                      }

                                      const { name, type, title, description } = field;

                                      return {
                                        contents: [
                                          {
                                            value: `**${title}**`,
                                          },
                                          ...(!!description ? [{ value: description }] : []),
                                          {
                                            value: `type: ***${type}***`,
                                          },
                                        ],
                                      };
                                    },
                                  });
                                  monaco.languages.registerInlineCompletionsProvider('sql', {
                                    freeInlineCompletions: (completions) => {
                                      return completions;
                                    },
                                    provideInlineCompletions: async function (
                                      model,
                                      position,
                                      context,
                                      token,
                                    ) {
                                      // await new Promise((resolve) => setTimeout(resolve, 1000));
                                      if (!shouldSuggest.current) {
                                        return {
                                          items: [],
                                        };
                                      }

                                      // const fullText = model.getValue();

                                      // if (fullText?.trim()?.length < 3) {
                                      //   return {
                                      //     items: [],
                                      //   };
                                      // }

                                      shouldSuggest.current = false;

                                      setTimeout(() => {
                                        shouldSuggest.current = true;
                                      }, 2000);

                                      const id = uuidV4();

                                      const word = model.getWordUntilPosition(position);

                                      const textUntilPosition = model.getValueInRange({
                                        startLineNumber: 1,
                                        startColumn: 1,
                                        endLineNumber: position.lineNumber,
                                        endColumn: word.endColumn,
                                      });

                                      const textAfterPosition = model.getValueInRange({
                                        startLineNumber: position.lineNumber,
                                        startColumn: word.endColumn,
                                        endLineNumber: position.lineNumber,
                                        endColumn: position.lineNumber + 100,
                                      });

                                      let completion = '';

                                      const isLookingForValue =
                                        textUntilPosition.trim().endsWith(' ') ||
                                        textUntilPosition.trim().endsWith(' ');

                                      setLoadingSuggestions(true);

                                      try {
                                        // const { data } = await axiosInstance.post('/v2/willy/complete-sql', {
                                        //   shopId: currentShopId,
                                        //   prefix: textUntilPosition,
                                        //   suffix: textAfterPosition,
                                        //   id: id,
                                        // });

                                        // completion = data.completion;
                                        // willySocket.emit('complete-sql' as any, {
                                        // });

                                        // willySocket.on('code-complete' as any, (msg: any) => {
                                        //   const { messageId, shopId, completion } = msg;
                                        //   if (id === messageId && shopId === currentShopId) {
                                        //     console.log('completion', completion);
                                        //     editorRef.current?.trigger('willy', 'editor.action.triggerSuggest', {
                                        //       auto: true,
                                        //       completion,
                                        //     });

                                        //     // suggestions = [];

                                        //     // suggestions.push(completion);
                                        //     // console.log('suggestions', suggestions);
                                        //   }
                                        // });

                                        // while (suggestions.length === 0) {
                                        //   await sleep(100);
                                        // }
                                        setLoadingSuggestions(false);
                                        return {
                                          items: [
                                            {
                                              insertText: completion,
                                            },
                                          ],
                                        };
                                      } catch (e) {
                                        setLoadingSuggestions(false);
                                        return {
                                          items: [],
                                        };
                                      }
                                    },
                                  });
                                  monaco.languages.registerCompletionItemProvider('sql', {
                                    triggerCharacters: ['='],
                                    provideCompletionItems: function (
                                      model,
                                      position,
                                      context,
                                      token,
                                    ) {
                                      const word = model.getWordUntilPosition(position);

                                      const textUntilPosition = model.getValueInRange({
                                        startLineNumber: 1,
                                        startColumn: 1,
                                        endLineNumber: position.lineNumber,
                                        endColumn: word.endColumn,
                                      });

                                      const fullText = model.getValue();

                                      let tableSuggestions: any[] = [];
                                      let columnSuggestions: any[] = [];
                                      let valueSuggestions: any[] = [];
                                      let keywordSuggestions: any[] = [];

                                      const isLookingForValue = textUntilPosition
                                        .trim()
                                        .endsWith('=');
                                      if (isLookingForValue) {
                                        valueSuggestions = tables.flatMap((t) => {
                                          if (fullText.includes(t.id)) {
                                            return t.columns
                                              .filter((x) => x.type !== 'formula')
                                              .flatMap((c) => {
                                                if (
                                                  c.options &&
                                                  textUntilPosition.trim().endsWith(`${c.name} =`)
                                                ) {
                                                  let options = c.options;
                                                  if (c.getOptionsFunc) {
                                                    options = c.getOptionsFunc(fullText);
                                                  }
                                                  return options.map((o) => ({
                                                    label: o.label,
                                                    kind: monaco.languages.CompletionItemKind.Value,
                                                    detail: o.value,
                                                    insertText:
                                                      typeof o.value === 'boolean'
                                                        ? ` ${o.value}`
                                                        : " '" + o.value + "'",
                                                    range: {
                                                      startLineNumber: position.lineNumber,
                                                      endLineNumber: position.lineNumber,
                                                      startColumn: word.startColumn,
                                                      endColumn: word.endColumn,
                                                    },
                                                  }));
                                                }
                                                return [];
                                              });
                                          }
                                          return [];
                                        });
                                      } else {
                                        keywordSuggestions = keywords.map((k) => ({
                                          label: k,
                                          kind: monaco.languages.CompletionItemKind.Keyword,
                                          insertText: k,
                                          insertTextRules:
                                            monaco.languages.CompletionItemInsertTextRule
                                              .InsertAsSnippet,
                                          range: {
                                            startLineNumber: position.lineNumber,
                                            endLineNumber: position.lineNumber,
                                            startColumn: word.startColumn,
                                            endColumn: word.endColumn,
                                          },
                                        }));

                                        tableSuggestions = tables.flatMap((table) => {
                                          return ['from', 'join'].map((keyword) => ({
                                            label: `${keyword} ${table.id}`,
                                            detail: table.name,
                                            kind: monaco.languages.CompletionItemKind.Folder,
                                            documentation: 'The table name',
                                            insertText: `${keyword} ${table.id}`,
                                            insertTextRules:
                                              monaco.languages.CompletionItemInsertTextRule
                                                .InsertAsSnippet,
                                            range: {
                                              startLineNumber: position.lineNumber,
                                              endLineNumber: position.lineNumber,
                                              startColumn: word.startColumn,
                                              endColumn: word.endColumn,
                                            },
                                          }));
                                        });

                                        columnSuggestions = tables.flatMap((t) => {
                                          if (fullText.includes(t.id)) {
                                            return t.columns
                                              .filter((x) => x.type !== 'formula')
                                              .map((c) => ({
                                                label: c.name,
                                                detail: c.type.toUpperCase(),
                                                kind: monaco.languages.CompletionItemKind.Property,
                                                documentation: c.type,
                                                insertText: c.name,
                                                insertTextRules:
                                                  monaco.languages.CompletionItemInsertTextRule
                                                    .InsertAsSnippet,
                                                range: {
                                                  startLineNumber: position.lineNumber,
                                                  endLineNumber: position.lineNumber,
                                                  startColumn: word.startColumn,
                                                  endColumn: word.endColumn,
                                                },
                                              }));
                                          }
                                          return [];
                                        });
                                      }

                                      const suggestions = [
                                        ...tableSuggestions,
                                        ...columnSuggestions,
                                        ...keywordSuggestions,
                                        ...valueSuggestions,
                                      ];

                                      return {
                                        suggestions,
                                      };
                                    },
                                  });
                                  monaco.languages.registerCompletionItemProvider('sql', {
                                    // on trigger 'snippet:' show all snippets
                                    triggerCharacters: [':'],
                                    provideCompletionItems: function (
                                      model,
                                      position,
                                      context,
                                      token,
                                    ) {
                                      const word = model.getWordUntilPosition(position);

                                      const textUntilPosition = model.getValueInRange({
                                        startLineNumber: 1,
                                        startColumn: 1,
                                        endLineNumber: position.lineNumber,
                                        endColumn: word.endColumn,
                                      });

                                      let snippetSuggestions: any[] = [];

                                      if (textUntilPosition.trim().endsWith('snippet:')) {
                                        snippetSuggestions = snippets.map((s) => ({
                                          label: s.name,
                                          kind: monaco.languages.CompletionItemKind.Snippet,
                                          insertText: s.name,
                                          range: {
                                            startLineNumber: position.lineNumber,
                                            endLineNumber: position.lineNumber,
                                            startColumn: word.startColumn,
                                            endColumn: word.endColumn,
                                          },
                                        }));
                                      }

                                      return {
                                        suggestions: snippetSuggestions,
                                      };
                                    },
                                  });
                                  monaco.languages.registerDocumentFormattingEditProvider('sql', {
                                    provideDocumentFormattingEdits(model, options) {
                                      const formatted = formatSqlSafely(model.getValue(), dialect);
                                      return [
                                        {
                                          range: model.getFullModelRange(),
                                          text: formatted,
                                        },
                                      ];
                                    },
                                  });
                                  monaco.languages.registerDocumentRangeFormattingEditProvider(
                                    'sql',
                                    {
                                      provideDocumentRangeFormattingEdits(model, range, options) {
                                        const formatted = formatSqlSafely(
                                          model.getValueInRange(range),
                                          dialect,
                                        );
                                        return [
                                          {
                                            range: range,
                                            text: formatted,
                                          },
                                        ];
                                      },
                                    },
                                  );
                                  editor.addAction({
                                    id: 'save_as_snippet',
                                    label: 'Save as snippet',
                                    keybindings: [
                                      monaco.KeyMod.CtrlCmd |
                                        monaco.KeyCode.KeyS |
                                        monaco.KeyCode.KeyD,
                                    ],
                                    contextMenuGroupId: 'navigation',
                                    contextMenuOrder: 1.5,
                                    run: (ed) => {
                                      const model = ed.getModel();
                                      // get the highlighted text
                                      const selection =
                                        ed.getSelection() || new monaco.Selection(1, 1, 1, 1);
                                      const value = model?.getValueInRange(selection);
                                      if (!value) {
                                        return;
                                      }
                                      setSnippetModalOpen(true);
                                      setSelectedText(value);
                                    },
                                  });
                                }}
                                options={{
                                  autoIndent: 'full',
                                  // wordWrap: 'on',
                                }}
                                height="100%"
                                className="rounded overflow-hidden"
                                defaultLanguage="sql"
                                defaultValue={defaultValue}
                                theme={doDarkMode ? 'vs-dark' : 'light'}
                                value={activeEditorTab?.model?.id ? activeQuery : ''}
                                onChange={(q = '') => {
                                  if (!activeEditorTab?.model?.id) {
                                    return;
                                  }

                                  setTabs((old) => {
                                    return old.map((t) => {
                                      if (t.model.id === activeEditorTab.model.id) {
                                        return {
                                          ...t,
                                          query: q,
                                        };
                                      }
                                      return t;
                                    });
                                  });
                                }}
                              />
                            </div>
                          </Allotment.Pane>
                          {activeEditorTab?.language === 'python' && (
                            <Allotment.Pane className="w-1/2 flex flex-col">
                              <Text px="sm" size="xs">
                                Data (JSON)
                              </Text>
                              <Editor
                                language="json"
                                defaultLanguage="json"
                                value={activeEditorTab?.jsonData}
                                onMount={(editor, monaco) => {
                                  const mySchema = {
                                    $schema: 'http://json-schema.org/draft-07/schema#',
                                    type: 'array',
                                    items: {
                                      type: 'object',
                                      properties: {
                                        file_name: {
                                          type: 'string',
                                          description:
                                            'The name of the file to load to the Python script.',
                                        },
                                        data: {
                                          type: 'array',
                                          description:
                                            'The data to load to the Python script under this file name.',
                                          items: {
                                            type: 'object',
                                            additionalProperties: {
                                              oneOf: [
                                                {
                                                  type: 'string',
                                                },
                                                {
                                                  type: 'number',
                                                },
                                                {
                                                  type: 'boolean',
                                                },
                                                {
                                                  type: 'null',
                                                },
                                              ],
                                            },
                                          },
                                        },
                                      },
                                      required: ['file_name', 'data'],
                                      additionalProperties: false,
                                    },
                                  };
                                  monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
                                    validate: true,
                                    schemas: [
                                      {
                                        uri: 'http://myserver/schema.json', // The URI is not fetched; it's just an identifier
                                        fileMatch: ['*'], // Associate with model URIs, e.g., ['foo.json', 'bar.json']
                                        schema: mySchema,
                                      },
                                    ],
                                  });
                                }}
                                onChange={(v) => {
                                  setTabs((old) => {
                                    return old.map((t) => {
                                      if (t.model.id === activeEditorTab.model.id) {
                                        return {
                                          ...t,
                                          jsonData: v,
                                        };
                                      }
                                      return t;
                                    });
                                  });
                                }}
                                height={'100%'}
                                defaultValue={JSON.stringify(activeEditorTab?.jsonData, null, 2)}
                              />
                            </Allotment.Pane>
                          )}
                        </Allotment>
                      </Allotment.Pane>

                      <Allotment.Pane
                        snap={false}
                        maxSize={1000}
                        minSize={500}
                        visible={filterBuilder.isPaneOpen}
                      >
                        <div className={'m-5 h-full'}>
                          <Flex justify="space-between" align="center">
                            <Text as="p" fw="bold" mr="md" c={doDarkMode ? 'gray.4' : 'gray.7'}>
                              Filter Builder
                            </Text>
                            <ActionIcon
                              variant="transparent"
                              size="sm"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setFilterBuilder((old) => ({
                                  ...old,
                                  isPaneOpen: false,
                                }));
                              }}
                              icon={<MobileCancelMajor />}
                            />
                          </Flex>
                          <div className={'m-5 h-[calc(100%-60px)] overflow-auto'}>
                            <SqlFilterBuilder />
                          </div>
                        </div>
                      </Allotment.Pane>
                    </Allotment>
                  </Allotment.Pane>
                  <Allotment.Pane
                    maxSize={MENU_SIZE}
                    minSize={MENU_SIZE}
                    className="save-results flex items-center gap-4 pr-4"
                  >
                    <WillyPanelTabs
                      value={activeResultsTab}
                      tabs={resultsTabs}
                      onTabChange={(v) => {
                        setActiveResultsTab(v as TabsValues);

                        genericEventLogger(analyticsEvents.SQWHALE, {
                          action: sqwhaleActions.CHANGE_RESULT_VIEW,
                          view: v,
                        });
                      }}
                    />
                    {activeResultsTab === 'visualization' && (
                      <PinMessage
                        willyMessageType={type}
                        queryId={sqlResults?.queryId!}
                        type={type}
                        title={title}
                        metrics={metrics}
                        queries={[
                          {
                            id: sqlResults?.queryId!,
                            query: sqlResults?.generatedQuery!,
                            question: sqlResults?.question!,
                          },
                        ]}
                        stacked={stacked}
                        incrementedStacked={incrementedStacked}
                        parameters={allParameters}
                        wrapText={wrapText}
                        //dashboardId={message.conversationId}
                        grid={grid}
                        gridColumns={gridColumns}
                        twoColumnMobile={twoColumnMobile}
                        disabled={!sqlResults}
                        dimension={dimension}
                        allowDataOverflow={allowDataOverflow}
                        yAxisDomain={yAxisDomain}
                        breakdownMode={breakdownMode}
                        globalConditionalFormattingColor={conditionalFormattingColor}
                        hasGlobalConditionalFormatting={hasConditionalFormatting}
                        activator={
                          <Button
                            w={120}
                            mr="xs"
                            rightSection="arrow-down-3"
                            disabled={!sqlResults}
                            onClick={() => {
                              genericEventLogger(analyticsEvents.SQWHALE, {
                                action: sqwhaleActions.OPEN_ADD_TO_REPORT,
                                widget_type: type,
                              });
                            }}
                          >
                            Save to
                          </Button>
                        }
                        context={'editor'}
                        pinTo={sourceIds}
                        dialect={
                          lastQueryDialect[activeEditorTab?.model?.id || ''] || DEFAULT_DIALECT
                        }
                      />
                    )}
                    {activeResultsTab === 'json' && !!activeRawData && (
                      <div className="whitespace-nowrap">
                        <PolarisButton
                          onClick={() => {
                            const tab = createNewTab(
                              {
                                index: tabs.length,
                                language: 'python',
                                schema: tables,
                                editorRef: editorRef.current!,
                                monacoRef: monacoRef.current!,
                                initialCode: '# Your Python code here',
                                name: `Analyzed data from ${activeEditorTab?.tabName}`,
                                active: true,
                                savedQueryType: 'query',
                                jsonData: JSON.stringify(
                                  convertDataToJson(activeRawData.data),
                                  null,
                                  2,
                                ),
                              },
                              setTabs,
                            );

                            setTabs((old) => {
                              return old
                                .map(
                                  (t) =>
                                    ({
                                      ...t,
                                      active: false,
                                    }) as EditorInstance,
                                )
                                .concat(tab);
                            });
                          }}
                        >
                          Analyze with Python
                        </PolarisButton>
                      </div>
                    )}
                  </Allotment.Pane>
                  <Allotment.Pane maxSize={800}>
                    {activeEditorTab?.loading && activeResultsTab !== 'console' && (
                      <div className="flex w-full h-full justify-center items-center">
                        <AlanLoaderGray />
                      </div>
                    )}
                    {(!sqlResults || !activeRawData) &&
                      !activeEditorTab?.loading &&
                      activeResultsTab !== 'console' && (
                        <Card
                          className="flex items-center justify-center h-full"
                          bg={doDarkMode ? 'gray.9' : undefined}
                        >
                          <Text as="p" size="lg" fw="bold" c="gray.4">
                            {!!activeEditorTab?.error
                              ? activeEditorTab.error
                              : 'Run a query to see results'}
                          </Text>
                        </Card>
                      )}
                    {activeResultsTab === 'json' && !!activeRawData && (
                      <WillyJson nlqData={activeRawData!} />
                    )}
                    {activeResultsTab === 'table' && !!activeRawData?.data && (
                      <WillyRawTable data={activeRawData.data} />
                    )}
                    {activeResultsTab === 'visualization' && (
                      <>
                        {!!sqlResults && !!activeRawData && !activeEditorTab?.loading && (
                          <WillyWidget
                            permission={{ providers: [] }}
                            permissionChanged={() => {}}
                            context="editor"
                            question={sqlResults.question}
                            queryId={sqlResults.queryId!}
                            type={type}
                            title={title}
                            currency={queryCurrency}
                            parameters={allParameters}
                            parametersChanged={updateParameters}
                            titleChanged={setTitle}
                            typeChanged={setType}
                            metrics={metrics}
                            metricsChanged={updateMetrics}
                            queries={widgetQueries}
                            queriesChanged={updateQueries}
                            stacked={stacked}
                            incrementedStacked={incrementedStacked}
                            stackedChanged={setStacked}
                            incrementedStackedChanged={setIncrementedStacked}
                            initialRawData={activeRawData}
                            wrapText={wrapText}
                            setWrapText={setWrapText}
                            setEditMetricModalOpen={setEditMetricModalOpen}
                            grid={grid}
                            setGrid={setGrid}
                            gridColumns={gridColumns}
                            setGridColumns={setGridColumns}
                            twoColumnMobile={twoColumnMobile}
                            setTwoColumnMobile={setTwoColumnMobile}
                            tileMode={tileMode}
                            setTileMode={setTileMode}
                            skinny={skinny}
                            setSkinny={setSkinny}
                            yAxisDomain={yAxisDomain}
                            setYAxisDomain={setYAxisDomain}
                            allowDataOverflow={allowDataOverflow}
                            setAllowDataOverflow={setAllowDataOverflow}
                            dimension={dimension}
                            setDimension={setDimension}
                            hasGlobalConditionalFormatting={hasConditionalFormatting}
                            setHasGlobalConditionalFormatting={setHasConditionalFormatting}
                            globalConditionalFormattingColor={conditionalFormattingColor}
                            setGlobalConditionalFormattingColor={setConditionalFormattingColor}
                            breakdownModeChanged={async (val) => {
                              setBreakdownMode(val);
                            }}
                            breakdownMode={breakdownMode}
                            dialect={
                              lastQueryDialect[activeEditorTab?.model?.id || ''] || DEFAULT_DIALECT
                            }
                            builderSetup={builderSetup}
                            builderSetupChanged={async (val) => {
                              setBuilderSetup(val);
                            }}
                            filtersOpen={filtersOpen}
                            setFiltersOpen={setFiltersOpen}
                            paginationType="server"
                            isSyncCharts={false}
                          />
                        )}
                      </>
                    )}
                    {activeResultsTab === 'console' && (
                      <>
                        <div className="flex items-center gap-2 p-2 border-b border-solid border-zinc-300 border-t-0 border-l-0 border-r-0">
                          <div
                            className="ml-auto cursor-pointer flex items-center"
                            onClick={() => {
                              if (!activeEditorTab?.model?.id) {
                                return;
                              }
                              const owner = activeEditorTab.model.getLanguageId();
                              changeLogsTabs(activeEditorTab.model.id, []);
                              monacoRef.current?.editor.setModelMarkers(
                                activeEditorTab.model,
                                owner,
                                [],
                              );
                            }}
                          >
                            <Icon name={'delete'} />
                          </div>
                        </div>
                        <ScrollToBottom className="w-full h-[calc(100%-27px)] p-4">
                          <WillyScrollToBottom showArrow={false}>
                            <div className="flex flex-col gap-2 justify-center">
                              {activeEditorTab?.logs?.map((l, i, arr) => (
                                <p
                                  key={`log_${l.time.getTime()}_${i}`}
                                  className={`text-gray-600 ${
                                    l.type === 'error' ? 'text-red-600' : 'text-gray-600'
                                  }`}
                                >
                                  <span>[{l.type.toUpperCase()}]</span>{' '}
                                  <span>[{l.time.toLocaleString()}]</span>:{' '}
                                  <span className="font-semibold whitespace-pre-wrap">{l.log}</span>
                                  {l.type === 'error' && i === arr.length - 1 && (
                                    <PolarisButton
                                      size="slim"
                                      loading={autofixLoading}
                                      onClick={() => {
                                        autofixError(activeEditorTab);
                                      }}
                                    >
                                      Autofix
                                    </PolarisButton>
                                  )}
                                </p>
                              ))}
                            </div>
                          </WillyScrollToBottom>
                        </ScrollToBottom>
                      </>
                    )}
                  </Allotment.Pane>
                  <Allotment.Pane className="flex items-center p-1" minSize={25} maxSize={25}>
                    <Text size="xs" display="flex" lh={1} px="xs">
                      Ln {cursorPosition.lineNumber}, Col {cursorPosition.column}
                    </Text>
                    <Text size="xs" display="flex" lh={1} px="xs">
                      <span className="flex gap-1 items-center">
                        <span>&#123; &#125;</span>
                        <span>{activeEditorTab?.language === 'python' ? 'Python' : 'SQL'}</span>
                      </span>
                    </Text>

                    {(loadingSuggestions || activeEditorTab?.loading) && (
                      <div className="ml-auto flex items-center">
                        <Loader type="oval" size="xs" />
                      </div>
                    )}
                    {!!sqlResults && (
                      <Text size="xs" display="flex" lh={1} px="xs" ml="auto">
                        {resultsLength} rows queried
                      </Text>
                    )}
                  </Allotment.Pane>
                </Allotment>
              </Allotment.Pane>
            </Allotment>
            {chatOpen && (
              <ChatWithQuery
                source="editor"
                query={!activeEditorTab?.model?.id ? '' : activeQuery}
                codeActions={codeActions}
                chatClosed={() => {
                  setChatOpen(false);
                }}
                chatMinimized={chatMinimized}
                setChatMinimized={setChatMinimized}
                chatFocus={chatFocus}
                chatOpen={chatOpen}
                messages={messages}
                setMessages={setMessages}
                returnQueryOnly={false}
                conversationId={conversationId}
                setConversationId={setConversationId}
              />
            )}
          </div>
        }
      />
      <Modal
        opened={saveQueryModalOpen}
        onClose={() => {
          setSaveQueryModalOpen(false);
        }}
        title="Save query"
        size="md"
      >
        <div className="p-4">
          <TextField
            requiredIndicator
            autoComplete="off"
            value={queryName}
            onChange={setQueryName}
            label="Name"
            helpText="Saved queries can found under 'Saved queries' tab on the left."
          />
        </div>
        <div className="p-4 flex gap-4">
          <Button
            variant="white"
            onClick={() => {
              setSaveQueryModalOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!queryName?.trim()}
            loading={savingQuery}
            onClick={async () => {
              if (!queryName?.trim()) {
                return;
              }
              setSavingQuery(true);
              await saveQuery();
              setSavingQuery(false);
              setSaveQueryModalOpen(false);
            }}
          >
            Save
          </Button>
        </div>
      </Modal>
      <Modal
        opened={!!diffEditorValue}
        onClose={closeDiffEditor}
        size="xl"
        title="Autofix"
        zIndex={300}
      >
        <Modal.Header>
          <Button onClick={acceptChanges}>Accept changes</Button>
          <Button variant="white" onClick={closeDiffEditor}>
            Discard
          </Button>
        </Modal.Header>
        <DiffEditor
          options={{
            inDiffEditor: true,
            autoIndent: 'full',
            readOnly: true,
          }}
          modified={diffEditorValue?.edited}
          original={diffEditorValue?.old}
          language="sql"
          height="75vh"
          className="rounded overflow-hidden"
          theme={doDarkMode ? 'vs-dark' : 'light'}
        />
      </Modal>

      <Modal
        opened={queryVarsOpen}
        onClose={() => {
          setQueryVarsOpen(false);
        }}
        zIndex={300}
      >
        {Object.keys(editorQueryVarsValues).length > 0 && (
          <div className="flex flex-col gap-4">
            {uniq(Object.keys(editorQueryVarsValues)).map((p) => {
              return (
                <QueryVariableInput
                  key={`query_var_${p}`}
                  p={p}
                  optionalVariables={optionalVariables}
                  activeQuery={activeQuery}
                  editorQueryVarsValues={editorQueryVarsValues}
                  // isError={isError}
                  findRelevantColumn={findRelevantColumn}
                  queryParametersWithValues={queryParametersWithValues}
                  setQueryParametersWithValues={setQueryParametersWithValuesCallback}
                  queryVarsValuesChanged={queryVarsValuesChanged}
                />
              );
            })}
          </div>
        )}

        {Object.keys(queryVars || []).length > 0 && (
          <div>
            <Text fw="bold">Available variables</Text>
            <List type="bullet">
              {queryVars!.map((p) => (
                <List.Item key={`query_var_${p}`}>
                  <div className="flex items-center gap-2">
                    <div className="font-semibold">{p}</div>
                  </div>
                </List.Item>
              ))}
            </List>
          </div>
        )}
        <Modal.Footer>
          <div className="p-4 flex gap-4 flex-row-reverse">
            <Button
              disabled={Object.values(queryParametersWithValues || {})?.some(
                (v) => !v?.value?.toString().trim() || v?.options?.some((o) => !o.trim()),
              )}
              onClick={() => {
                setQueryVarsOpen(false);
              }}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <SaveSnippet
        snippet={selectedText}
        open={snippetModalOpen}
        onClose={() => setSnippetModalOpen(false)}
      />

      <WillyEditMetric
        open={editMetricModalOpen.open}
        metric={metrics?.find((m) => m.key === editMetricModalOpen.metricId) ?? null}
        availableMetrics={metrics}
        parameters={parameters}
        onClose={() => setEditMetricModalOpen({ open: false })}
        onSaved={async ({ ...rest }) => {
          setMetrics((old) => {
            return old.map((m) => {
              if (m.key === editMetricModalOpen.metricId) {
                return {
                  ...m,
                  ...rest,
                };
              }
              return m;
            });
          });
          setEditMetricModalOpen({ open: false });
        }}
        onRemoved={async (metric) => {
          setMetrics((old) => {
            return old.filter((m) => m.key !== metric.key);
          });
          setEditMetricModalOpen({ open: false });
        }}
      />
    </>
  );
};

export const FreeQuery = computeFeatureFlags(FeatureFlag.SQL_FF, FreeQueryComponent, () => (
  <UpgradePageFallBack
    featureFlag={FeatureFlag.SQL_FF}
    InAppContextBannerEnabled={false}
    title="SQL Editor"
    description="Write SQL queries and get results in a table, chart or JSON format."
  />
));

type QueryVariableInputProps = {
  p: string;
  activeQuery?: string;
  optionalVariables: string[];
  queryParametersWithValues: Record<string, { value: string; options: string[]; query?: string }>;
  editorQueryVarsValues: Record<string, any>;
  queryVarsValuesChanged?: (v: Record<string, any>) => void;
  setQueryParametersWithValues: React.Dispatch<React.SetStateAction<QueryValueRecord>>;
  findRelevantColumn: (p: string) => BqColumn | null;
};

export const QueryVariableInput: React.FC<QueryVariableInputProps> = ({
  p,
  activeQuery,
  optionalVariables,
  queryParametersWithValues,
  editorQueryVarsValues,
  queryVarsValuesChanged,
  setQueryParametersWithValues,
  findRelevantColumn,
}) => {
  const isMultiSelectSupportedYet = false;
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const snippets = useStoreValue($allSnippets);

  const isRequiredVariable = useMemo(() => {
    return !optionalVariables?.includes(p);
  }, [optionalVariables, p]);

  let isError = useMemo(() => {
    let error = false;
    if (!isRequiredVariable) {
      error = false;
    } else if (Array.isArray(editorQueryVarsValues[p])) {
      error = !editorQueryVarsValues[p].length;
    } else {
      error = !editorQueryVarsValues[p];
    }

    return error;
  }, [editorQueryVarsValues, isRequiredVariable, p]);

  const snippetParameters = useMemo(() => {
    if (!activeQuery) {
      return [];
    }
    const snippets = extractSnippets(activeQuery);
    return snippets;
  }, [activeQuery]);

  const col = findRelevantColumn(p);
  const isDate = p === 'start_date' || p === 'end_date';
  const isSnippet = snippetParameters?.includes(p);

  const input = useMemo(() => {
    if (isSnippet) {
      return (
        <div className="flex flex-col gap-1 overflow-auto w-full">
          <Select
            label="Select snippet as a default value"
            allowDeselect={false}
            value={queryParametersWithValues[p]?.value || ''}
            data={snippets.map((s) => ({ label: s.name, value: s.name }))}
            onChange={(v) => {
              const snippet = snippets.find((s) => s.name === v);
              if (!snippet) {
                return;
              }
              setQueryParametersWithValues((old) => ({
                ...old,
                [p]: {
                  value: snippet.name,
                  options: old[p]?.options || [],
                  query: '',
                },
              }));
              if (!!queryVarsValuesChanged) {
                queryVarsValuesChanged({
                  ...editorQueryVarsValues,
                  [p]: snippet.name,
                });
              }
            }}
          />
          <MultiSelect
            label="When this query in a dashboard, let the user to select from these snippets"
            value={queryParametersWithValues[p]?.options || []}
            data={snippets.map((s) => ({ label: s.name, value: s.name }))}
            onChange={(v) => {
              setQueryParametersWithValues((old) => ({
                ...old,
                [p]: {
                  value: old[p]?.value || '',
                  options: v,
                  query: old[p]?.query || '',
                },
              }));
              if (!!queryVarsValuesChanged) {
                queryVarsValuesChanged({
                  ...editorQueryVarsValues,
                  [p]: v,
                });
              }
            }}
          />
          {!!queryParametersWithValues[p]?.value && (
            <div className="max-w-full overflow-auto rounded-lg">
              <SyntaxHighlighter
                language={'sql'}
                style={vscDarkPlus}
                customStyle={{
                  margin: 0,
                }}
              >
                {snippets.find((s) => s.name === queryParametersWithValues[p].value)?.snippet || ''}
              </SyntaxHighlighter>
            </div>
          )}
        </div>
      );
    }
    if (isDate) {
      return (
        <Popover
          active={datePickerOpen}
          onClose={() => setDatePickerOpen(false)}
          activator={
            <Button
              variant="activator"
              onClick={() => {
                setDatePickerOpen(true);
              }}
            >
              {queryParametersWithValues[p]?.value || 'Select date'}
            </Button>
          }
        >
          <div className="p-4">
            <DatePickerSimple
              onClose={() => setDatePickerOpen(false)}
              onChange={(date) => {
                const { start } = date;
                setQueryParametersWithValues((old) => ({
                  ...old,
                  [p]: {
                    value: start,
                    options: old[p]?.options || [],
                    query: old[p]?.query || '',
                  },
                }));
                if (!!queryVarsValuesChanged) {
                  queryVarsValuesChanged({
                    ...editorQueryVarsValues,
                    [p]: start,
                  });
                }
              }}
              selected={[queryParametersWithValues[p]?.value]}
            />
          </div>
        </Popover>
      );
    }

    if (!col?.options) {
      return (
        <div className="flex flex-col gap-2">
          <TextInput
            value={queryParametersWithValues[p]?.value || ''}
            label="Default value"
            type={col?.type === 'numeric' ? 'number' : 'text'}
            onChange={(v) => {
              if (col?.type === 'numeric' && isNaN(+v)) {
                return;
              }
              setQueryParametersWithValues((old) => ({
                ...old,
                [p]: {
                  value: col?.type === 'numeric' ? (+v as unknown as string) : v,
                  options: old[p]?.options || [],
                  query: old[p]?.query || '',
                },
              }));
              if (!!queryVarsValuesChanged) {
                queryVarsValuesChanged({
                  ...editorQueryVarsValues,
                  [p]: col?.type === 'numeric' ? +v : v,
                });
              }
            }}
          />

          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-2">
              <Text size="sm" fw="bold">
                Options for this parameter
              </Text>
              <Text size="sm" color="gray.6">
                Options are used to provide a list of values that can be selected for this
                parameter.
              </Text>
            </div>
            <Button
              rightSection={<Icon name="plus-circle" />}
              variant="white"
              size="sm"
              onClick={() => {
                setQueryParametersWithValues((old) => {
                  const options = old[p]?.options || [];
                  return {
                    ...old,
                    [p]: {
                      value: old[p]?.value || '',
                      options: [...new Set(options.concat(''))],
                      query: old[p]?.query || '',
                    },
                  };
                });
              }}
            >
              Add option
            </Button>
            <div className="flex items-center gap-1">
              {queryParametersWithValues[p]?.options
                .filter((x) => !!x)
                .map((o, i) => {
                  return (
                    <Badge variant="outline" key={o}>
                      {o}
                    </Badge>
                  );
                })}
            </div>
            {queryParametersWithValues[p]?.options?.map((o, i) => {
              return (
                <div className="flex items-center gap-2" key={i}>
                  <TextInput
                    value={o}
                    required
                    error={!o.trim() ? 'Option cannot be empty' : null}
                    onChange={(v) => {
                      setQueryParametersWithValues((old) => {
                        const newOptions = old[p]?.options || [];
                        return {
                          ...old,
                          [p]: {
                            value: old[p]?.value || '',
                            options: newOptions.map((x, j) => (j === i ? v : x)),
                            query: old[p]?.query || '',
                          },
                        };
                      });
                    }}
                  />
                  <ActionIcon
                    icon="delete"
                    onClick={() => {
                      setQueryParametersWithValues((old) => {
                        const newOptions = old[p]?.options || [];
                        return {
                          ...old,
                          [p]: {
                            value: old[p]?.value || '',
                            options: newOptions.filter((x, j) => j !== i),
                            query: old[p]?.query || '',
                          },
                        };
                      });
                    }}
                  />
                </div>
              );
            })}
          </div>
          <WillyFormGroup>
            <Text size="sm" fw="bold">
              Populate options from a query (max 100 values)
            </Text>
            <Textarea
              minRows={7}
              maxRows={7}
              autosize
              value={queryParametersWithValues[p]?.query || ''}
              onChange={(v) => {
                setQueryParametersWithValues((old) => ({
                  ...old,
                  [p]: {
                    value: old[p]?.value || '',
                    options: old[p]?.options || [],
                    query: v.target.value || '',
                  },
                }));
              }}
              placeholder="SELECT DISTINCT
            channel
          FROM
            pixel_orders_table
          WHERE
            event_date > DATE_SUB(CURRENT_DATE(), INTERVAL 90 day)
          GROUP BY
            channel"
            />
          </WillyFormGroup>
        </div>
      );
    }
    if (col.options) {
      return (
        <div className="flex flex-col gap-2">
          {col?.multiSelect && isMultiSelectSupportedYet && (
            <MultiSelect
              styles={{
                input: {
                  minWidth: '200px',
                },
                dropdown: {
                  zIndex: 3000,
                },
              }}
              value={
                !queryParametersWithValues[p].value
                  ? []
                  : Array.isArray(queryParametersWithValues[p]?.value)
                    ? (queryParametersWithValues[p]?.value as unknown as string[]) || []
                    : [queryParametersWithValues[p]?.value || '']
              }
              data={col.options}
              onChange={(v) => {
                setQueryParametersWithValues((old) => ({
                  ...old,
                  [p]: {
                    value: v as unknown as string,
                    options: old[p]?.options || [],
                    query: old[p]?.query || '',
                  },
                }));
                if (!!queryVarsValuesChanged) {
                  queryVarsValuesChanged({
                    ...editorQueryVarsValues,
                    [p]: v,
                  });
                }
              }}
            />
          )}
          {(!col?.multiSelect || !isMultiSelectSupportedYet) && (
            <Select
              value={queryParametersWithValues[p]?.value || ''}
              data={[{ label: '-', value: '' }, ...col.options]}
              onChange={(v) => {
                if (!v) {
                  return;
                }
                setQueryParametersWithValues((old) => ({
                  ...old,
                  [p]: {
                    value: v,
                    options: old[p]?.options || [],
                    query: old[p]?.query || '',
                  },
                }));
                if (!!queryVarsValuesChanged) {
                  queryVarsValuesChanged({
                    ...editorQueryVarsValues,
                    [p]: v,
                  });
                }
              }}
            />
          )}
          <Text size="xs" color="gray.6">
            Limit options for this parameter to a predefined list of values.
          </Text>

          <MultiSelect
            value={queryParametersWithValues[p]?.options || []}
            data={col.options}
            onChange={(v) => {
              setQueryParametersWithValues((old) => ({
                ...old,
                [p]: {
                  value: old[p]?.value || '',
                  options: v,
                  query: old[p]?.query || '',
                },
              }));
              if (!!queryVarsValuesChanged) {
                queryVarsValuesChanged({
                  ...editorQueryVarsValues,
                  [p]: v,
                });
              }
            }}
          />
        </div>
      );
    }
  }, [
    col?.multiSelect,
    col?.options,
    col?.type,
    editorQueryVarsValues,
    isDate,
    isSnippet,
    p,
    queryParametersWithValues,
    queryVarsValuesChanged,
    setQueryParametersWithValues,
    snippets,
    isMultiSelectSupportedYet,
    datePickerOpen,
  ]);

  return (
    <div
      key={`query_var_${p}`}
      className={`flex flex-col gap-4 pb-4 border-b border-solid border-zinc-300 border-t-0 border-l-0 border-r-0 last-of-type:border-b-0 ${
        isError ? 'text-red-700' : ''
      }`}
    >
      <div className="font-semibold font-mono">
        <span>@{p}</span>
        {isRequiredVariable && <span className="text-red-600">*</span>}
        <span>:</span>
      </div>

      <div className="flex items-center gap-1">
        {input}
        {!!col?.type && (
          <span className=" whitespace-nowrap">
            <Badge>{col.type}</Badge>
          </span>
        )}
      </div>
    </div>
  );
};
