import moment from '@tw/moment-cached';
import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CohortsParams } from '@tw/types/module/services/insights';
import { Loader } from '@tw/ui-components';
import { RootState } from 'reducers/RootType';
import { FiltersContext, appliedInsightsFiltersQueries } from 'ducks/insights/filterReducers';
import InsightsSection from '../InsightsSection';
import CohortsTable from './CohortsTable';
import { CohortTable as CohortTableType, CohortsMetric, TableViewOptions } from './constants';
import CohortTableViewOptions from './CohortTableViewOptions';
import CohortsFilters from './CohortsFilters';
import axiosInstance from 'utils/axiosInstance';
import '../insights.scss';
import { ShopifyReconnectBanner } from '../ShopifyReconnectBanner';

const CancelToken = axios.CancelToken;
let cancel;

const Cohorts: React.FC = () => {
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const [cohortData, setCohortData] = useState<CohortTableType | null>();
  const [tableViewOptions, setTableViewOptions] = useState<TableViewOptions>({
    isCumulative: true,
    showNCPAPayback: false,
    metric: CohortsMetric.LTV,
    secondOrderOnly: false,
  });

  const { [FiltersContext.Cohorts]: appliedFilters } = useSelector(appliedInsightsFiltersQueries);

  const { timeframe, dateRange } = useSelector((state: RootState) => state.insights);

  const { ltvSelectedCDPSegment } = useSelector((state: RootState) => state.ltv);
  const [tableRef, setTableRef] = useState();

  const handleTableOptionsChange = useCallback(
    (options) => {
      setTableViewOptions(options);
    },
    [setTableViewOptions],
  );

  const getData = useCallback(async () => {
    // Cancel the previous request if it's still ongoing
    if (cancel) {
      cancel();
    }

    let body: CohortsParams = {
      shopId: currentShopId,
      startDate: dateRange!.start.format('YYYY-MM-DD'),
      endDate: dateRange!.end.format('YYYY-MM-DD'),
      timeFrame: timeframe,
      filters: appliedFilters,
      CDPSegmentId: ltvSelectedCDPSegment,
      // @ts-ignore
      dialect: tableViewOptions.dialect,
    };

    try {
      const data = (
        await axiosInstance.post(`/v2/insights/cohorts`, body, {
          cancelToken: new CancelToken(function executor(c) {
            cancel = c;
          }),
        })
      ).data;
      setCohortData(data);
    } catch (error) {
      if (error.message === 'canceled') return;
      toast.error('Failed to fetch cohorts data');
    }
  }, [
    appliedFilters,
    currentShopId,
    dateRange,
    ltvSelectedCDPSegment,
    timeframe,
    tableViewOptions.dialect,
  ]);

  useEffect(() => {
    if (!dateRange?.start || !dateRange?.end) return;

    setCohortData(null);
    getData();
  }, [dateRange?.start, dateRange?.end, timeframe, appliedFilters, ltvSelectedCDPSegment, getData]);

  return (
    <div className="insights-page">
      <ShopifyReconnectBanner className={'mb-5'} />
      <InsightsSection>
        <>
          <CohortsFilters tableViewOptions={tableViewOptions} onChange={handleTableOptionsChange} />
          <CohortTableViewOptions
            tableViewOptions={tableViewOptions}
            onChange={handleTableOptionsChange}
            tableRef={tableRef}
            cohortData={cohortData}
          />
          {cohortData ? (
            <div className="overflow-auto">
              <CohortsTable
                setTableRef={(e) => {
                  setTableRef(e);
                }}
                tableData={cohortData}
                tableViewOptions={tableViewOptions}
              />
            </div>
          ) : (
            <div className="flex justify-center">
              <Loader />
            </div>
          )}
        </>
      </InsightsSection>
    </div>
  );
};

export default Cohorts;
