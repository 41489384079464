import { CreateEditSegmentModal } from 'components/CreateEditSegmentationModal';
import {
  createSegment,
  editSegment,
  openModalSegmentation,
  searchAds,
  shopifySegmentSearch,
} from 'ducks/serviceSegmentations';
import { getNewStatsForAllServices } from 'ducks/newStats';
import { useAppDispatch } from 'index';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppSelector, type RootState } from 'reducers/RootType';

import {
  bigCommerceOperands,
  operands,
  operandsNames,
  operatorsCONTAINS,
  operatorsEQUALS,
  operatorsNames,
  shopifyOperands,
  shopifyOperandsNames,
} from './types';
import { shopIntegrations } from 'ducks/shopIntegrations';
import { retry } from '@reduxjs/toolkit/query';
import axiosInstance from '../../utils/axiosInstance';

export const CreateEditAdSegmentModal = () => {
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const shopMsp = useAppSelector((state: RootState) => state.msp);
  const {
    modalData: { isOpen, serviceId, segmentId },
    currentSegment,
    segments,
  } = useSelector((state: RootState) => state.adSegmentations);
  const integrations = useSelector(shopIntegrations);
  const shopifySourcesDictionaryAsArray = useSelector(
    (state: RootState) => state.shopifySourcesDictionaryAsArray as any[],
  );
  const shopifySourcesDictionaryNameToId = useSelector(
    (state: RootState) => state.shopifySourcesDictionaryNameToId as any[],
  );

  const shopifySourcesDictionaryIdToName = useSelector(
    (state: RootState) => state.shopifySourcesDictionaryIdToName as any[],
  );

  const isEdit = !!segmentId;

  const dispatch = useAppDispatch();

  const getOperandsByOperator = (operand: string) => {
    if (serviceId === 'shopify') {
      return [...operatorsEQUALS, ...operatorsCONTAINS];
    } else {
      return operand.includes('id') ? operatorsEQUALS : operatorsCONTAINS;
    }
  };

  const translateSegmentValue = async (segment: any, serviceId) => {
    if (serviceId === 'shopify') {
      const { expressionList } = segment;
      return {
        ...segment,
        expressionList: await Promise.all(
          expressionList.map(async (exp: any) => {
            let value = exp.value;
            if (shopifySourcesDictionaryNameToId[exp.value] && exp.operand === 'sources') {
              //In case what user want to save is in the dictionary but the shop has theis value anyway, we don't want to use the dictionary value
              const body = {
                type: 'sources',
                shopId: currentShopId,
                query: exp.value,
              };
              const { data: list } = await axiosInstance.post<any>(
                '/v2/shopify/mongo/search-segment',
                body,
              );
              if (list.length === 0) {
                value = shopifySourcesDictionaryNameToId[exp.value];
              }
            }

            return {
              ...exp,
              value,
            };
          }),
        ),
      };
    } else {
      return segment;
    }
  };

  const translateSegmentValueGet = (segment: any, serviceId) => {
    if (serviceId === 'shopify') {
      const { expressionList } = segment;
      return {
        ...segment,
        expressionList: expressionList.map((exp: any) => ({
          ...exp,
          value:
            shopifySourcesDictionaryIdToName[exp.value] && exp.operand === 'sources'
              ? shopifySourcesDictionaryIdToName[exp.value]
              : exp.value,
        })),
      };
    } else {
      return segment;
    }
  };

  const openerandsByMsp = useMemo(() => {
    switch (shopMsp) {
      case 'shopify':
        return shopifyOperands;
      case 'bigcommerce':
      case 'woocommerce':
        return bigCommerceOperands;
      default:
        return shopifyOperands;
    }
  }, [shopMsp]);

  return (
    <CreateEditSegmentModal
      onClose={() => dispatch(openModalSegmentation('', ''))}
      isOpen={isOpen}
      type={serviceId}
      isEdit={isEdit}
      id={segmentId}
      editSegment={async (segment) => {
        dispatch(editSegment(await translateSegmentValue(segment, serviceId), serviceId));
      }}
      serviceId={serviceId}
      createSegment={async (segment) =>
        dispatch(createSegment(await translateSegmentValue(segment, serviceId), serviceId))
      }
      currentSegment={
        isEdit
          ? translateSegmentValueGet(segments[serviceId][segmentId], serviceId)
          : currentSegment
      }
      getOperators={(operator) =>
        getOperandsByOperator(operator).map((op) => ({
          label: operatorsNames[op] || op,
          value: op,
        }))
      }
      operands={
        serviceId === 'shopify'
          ? openerandsByMsp.map((op) => ({
              label: shopifyOperandsNames[op],
              value: op,
            }))
          : operands.map((op) => ({ label: operandsNames[op] || op, value: op }))
      }
      search={async (searchStr: string, operand: string) => {
        if (serviceId === 'shopify') {
          const searchRes = (
            (await shopifySegmentSearch(operand, currentShopId, searchStr)) || []
          ).filter((x) => x && !x.startsWith(' '));
          let localRes: any[] = [];
          if (operand === 'sources') {
            localRes = shopifySourcesDictionaryAsArray
              .filter((x) => x.name.toLowerCase().includes(searchStr.toLowerCase()))
              .map((x) => x.name);
          }
          const finalRes = [...new Set(searchRes.concat(localRes))];
          const mapRes = finalRes.map((res) => ({
            label: res,
            value: res,
          }));
          return [{ label: searchStr, value: searchStr }, ...mapRes];
        } else {
          const entity = operand.split('_')[0];
          const res = await searchAds(searchStr, serviceId, entity, integrations);
          const mapRes = res.map((r) => ({ label: r.name, value: r.name }));
          return [{ label: searchStr, value: searchStr }, ...mapRes];
        }
      }}
      handleNew={(serviceId) => {
        // ugly hack to get the new stats after the segment is ready in redux
        setTimeout(() => {
          dispatch(getNewStatsForAllServices(false, [serviceId]));
        }, 1000);
      }}
      operatorBetweenExpressions={'and'}
      defaultExpression={
        serviceId === 'shopify'
          ? {
              operand: 'sources',
              operator: 'equals',
              value: '',
            }
          : undefined
      }
    />
  );
};
