import ConnectedLists from 'components/ConnectedLists';
import React, { useCallback, useMemo, useState } from 'react';

import {
  ActionList,
  ComplexAction,
  Icon,
  List,
  Modal,
  Popover,
  Text,
  TextField,
  Tooltip,
} from '@shopify/polaris';
import { DeleteMinor, EditMinor, SearchMinor } from '@shopify/polaris-icons';
import { BaseColumn, ConnectedListItem, savedPreset } from './types';
import { useAppDispatch } from 'index';
import { useDarkMode } from 'dark-mode-control';
import { CustomMetricsType, toggleCustomMetricModal } from 'ducks/customMetrics';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { toast } from 'react-toastify';
import { confirmationModal } from 'ducks/confirmationModal';
import { ActionIcon, IconName } from '@tw/ui-components';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useStoreValue } from '@tw/snipestate';
import { $activeAppVersion } from '$stores/nav-config-stores';
import { $isAdminClaim } from '$stores/$user';

type ColumnsSelectorProps = {
  columns: BaseColumn<any, any>[];
  storageKey: string;
  icon: JSX.Element | IconName;
  title: string | JSX.Element;
  selectedColumns: BaseColumn<any, any>[];
  setSelectedColumns: (columns: BaseColumn<any, any>[]) => void;
  customMetricsType?: CustomMetricsType;
  allowSavePreset?: boolean;
  savedPresets?: savedPreset[];
  skipPresets?: boolean;
  onSavePreset?: (
    selectedColumns: BaseColumn<any, any>[],
    presetName: string,
    presetDescription: string,
  ) => Promise<void>;
  onDeletedPreset?: (presetId: string) => Promise<void>;
  onEditPreset?: (
    presetId: string,
    presetName: string,
    presetDescription: string,
    presetColumns: BaseColumn<any, any>[],
  ) => Promise<void>;
  customActivator?: (onClick: () => void) => React.ReactNode;
};

export const ColumnsSelector: React.FC<ColumnsSelectorProps> = (props) => {
  const {
    columns,
    storageKey,
    icon,
    title,
    selectedColumns: selectedColumnsProp,
    allowSavePreset,
    savedPresets,
    customMetricsType,
    setSelectedColumns,
    onSavePreset,
    onDeletedPreset,
    onEditPreset,
    skipPresets,
    customActivator,
  } = props;

  const isAdmin = useStoreValue($isAdminClaim);

  const columnsToShow = useMemo(() => {
    return columns.filter((x) => !x.adminOnly || isAdmin);
  }, [columns, isAdmin]);

  const selectedColumns = useMemo(() => {
    return columnsToShow
      .filter((x) => x.isFixed)
      .concat(selectedColumnsProp.filter((x) => !x.isFixed));
  }, [columnsToShow, selectedColumnsProp]);

  const createCustomMetric = useMemo(() => customMetricsType !== undefined, [customMetricsType]);

  const availableColumns = columnsToShow?.filter(
    (c) => !selectedColumns?.find((selected) => selected?.key === c?.key) && !c?.isFixed,
  );

  const dispatch = useAppDispatch();
  const doDarkMode = useDarkMode();
  const isSmall = useIsSmall();

  const [active, setActive] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const handleChange = useCallback(() => setActive(!active), [active]);
  const [savePresetModalOpen, setSavePresetModalOpen] = useState(false);
  const [presetName, setPresetName] = useState('');
  const [presetDescription, setPresetDescription] = useState('');
  const [editPresetModalData, setEditPresetModalData] = useState<savedPreset | undefined>(
    undefined,
  );

  const activeAppVersion = useStoreValue($activeAppVersion);
  const isThreePointOh = activeAppVersion === '3.0';

  const handleToggleCustomMetricModal = useCallback(
    () => dispatch(toggleCustomMetricModal({ isModalOpen: true, customMetricsType })),
    [dispatch, customMetricsType],
  );

  const formatItems = useCallback(
    (x: BaseColumn<any, any>): ConnectedListItem => ({
      id: x.key,
      title: x.name,
      pinned: typeof x.isFixed === 'boolean' ? x.isFixed : false,
      disabled: x.disableSort,
      subTitle: x.columnMetadata?.subTitle || '',
      onEdit: x.onEdit,
    }),
    [],
  );

  const setAndSaveSelectedColumns = useCallback(
    (cols: BaseColumn<any, any>[]) => {
      const fixedColumns = columnsToShow?.filter((x) => x.isFixed);
      const myColumns = fixedColumns?.concat(cols?.filter((x) => !x?.isFixed));
      setSelectedColumns(myColumns);
      const columnIds = myColumns.map((x) => x.key);
      localStorage.setItem(storageKey, JSON.stringify(columnIds));
    },
    [columnsToShow, setSelectedColumns, storageKey],
  );

  const dragChange = (newOrder: ConnectedListItem[]) => {
    const columnsInOrder = newOrder
      ?.map((x) => columnsToShow?.find((column) => column.key === x.id)!)
      ?.filter((c) => c && !c.isFixed);
    const fixedColumns = columnsToShow?.filter((x) => x.isFixed);
    setAndSaveSelectedColumns([...fixedColumns, ...columnsInOrder]);
  };

  const hideColumn = useCallback(
    (item: ConnectedListItem) => {
      const newColumns = selectedColumns?.filter((x) => x?.key !== item.id);
      setAndSaveSelectedColumns(newColumns);
    },
    [selectedColumns, setAndSaveSelectedColumns],
  );

  const showColumn = useCallback(
    (item: ConnectedListItem) => {
      const newColumn = columnsToShow?.find((x) => x.key === item.id);
      if (!newColumn) {
        return;
      }
      setAndSaveSelectedColumns([...selectedColumns, newColumn]);
    },
    [columnsToShow, selectedColumns, setAndSaveSelectedColumns],
  );

  const activator = useMemo(() => {
    return (
      <div className="column-selector-activator">
        {customActivator ? (
          customActivator(() => {
            skipPresets ? handleChange() : setMenuActive((x) => !x);
          })
        ) : (
          <Tooltip dismissOnMouseOut content={title}>
            <ActionIcon
              id="tr-column-selector"
              size={isThreePointOh ? 'md' : 'lg'}
              radius="sm"
              onClick={() => {
                skipPresets ? handleChange() : setMenuActive((x) => !x);
              }}
              variant="activator"
              icon={icon}
            />
          </Tooltip>
        )}
      </div>
    );
  }, [customActivator, title, isThreePointOh, icon, skipPresets, handleChange]);

  const extraActionToDropDown = useMemo(() => {
    const actions: ComplexAction[] = [
      {
        content: 'Customize Columns',
        onAction: () => {
          setMenuActive(false);
          handleChange();
        },
      },
    ];
    if (allowSavePreset && onSavePreset) {
      actions.push({
        content: 'Save as new preset',
        onAction: () => {
          setSavePresetModalOpen(true);
          setMenuActive(false);
        },
      });
    }
    return actions;
  }, [allowSavePreset, handleChange, onSavePreset]);

  const secondaryActions = useMemo(() => {
    const actions: ComplexAction[] = [];

    if (createCustomMetric) {
      actions.push({
        content: 'Create Custom Metric',
        onAction: handleToggleCustomMetricModal,
        id: 'create_custom_metric_attribution',
      });
    }

    if (allowSavePreset && onSavePreset) {
      actions.push({
        content: 'Save as new preset',
        onAction: () => {
          setSavePresetModalOpen(true);
        },
      });
    }

    return actions;
  }, [allowSavePreset, createCustomMetric, handleToggleCustomMetricModal, onSavePreset]);

  return (
    <>
      {!skipPresets ? (
        <Popover
          activator={activator}
          active={menuActive}
          onClose={() => setMenuActive(false)}
          sectioned
          fluidContent
          preventCloseOnChildOverlayClick
        >
          <Popover.Pane>
            <ActionList
              sections={
                !allowSavePreset
                  ? []
                  : savedPresets?.length
                    ? []
                    : [{ items: [{ content: 'No Presets Saved' }] }]
              }
              items={savedPresets?.map((x) => ({
                active:
                  x.columns.map((x) => x.key).join(',') ===
                  selectedColumns?.map((x) => x.key).join(','),
                content: (
                  <span className="flex gap-4" _data-tooltip-id={`tooltip_${x.id}_preset`}>
                    {!x.isDefault && (
                      <span className="flex items-center gap-2">
                        <DeleteMinor
                          onClick={(e) => {
                            if (!onDeletedPreset) {
                              return;
                            }
                            e.stopPropagation();
                            setMenuActive(false);
                            dispatch(
                              confirmationModal({
                                title: 'Delete Preset',
                                text: `Are you sure you want to delete the preset "${x.name}"?`,
                                confirmButtonText: 'Delete',
                                onConfirm: () => {
                                  onDeletedPreset(x.id);
                                },
                                onCancel: () => {},
                              }),
                            );
                          }}
                          className="cursor-pointer flex w-10 h-10 border border-solid border-slate-400 fill-slate-600 rounded-md p-1"
                        />
                        <EditMinor
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditPresetModalData(x);
                            setMenuActive(false);
                          }}
                          className="cursor-pointer flex w-10 h-10 border border-solid border-slate-400 fill-slate-600 rounded-md p-1"
                        />
                      </span>
                    )}
                    <span className="flex flex-col">
                      <Text as="span" variant="bodyMd" fontWeight="bold">
                        <span className="flex items-center gap-2">
                          <span>{x.name}</span>
                        </span>
                      </Text>
                      <Text as="span" variant="bodySm" color="subdued">
                        <span className="block max-w-lg overflow-hidden whitespace-nowrap text-ellipsis">
                          {x.description}
                        </span>
                      </Text>
                    </span>
                  </span>
                ) as any,
                onAction: () => {
                  setAndSaveSelectedColumns(x.columns);
                  setMenuActive(false);
                },
              }))}
            />
          </Popover.Pane>
          <Popover.Pane fixed>
            <ActionList items={extraActionToDropDown} />
          </Popover.Pane>
        </Popover>
      ) : (
        <>{activator}</>
      )}

      {/* preset tooltips */}
      {savedPresets?.map((x) => {
        const cols = x.columns.filter((x) => !x.isFixed);
        return (
          <ReactTooltip
            key={x.id}
            hidden={isSmall}
            style={{ zIndex: 9999999999, borderRadius: '8px' }}
            opacity={1}
            offset={30}
            id={`tooltip_${x.id}_preset`}
            place="left-end"
            delayShow={800}
            delayHide={300}
            noArrow={false}
            variant={doDarkMode ? 'dark' : 'light'}
          >
            <div className="flex flex-col gap-10" data-tooltip-id={`tooltip_${x.id}_preset`}>
              <Text as="span" variant="bodyMd" fontWeight="bold">
                {x.name}
              </Text>
              <Text as="span" variant="bodyMd" fontWeight="bold">
                Here are the metrics and details
                <br />
                included in this column preset:
              </Text>
              <List>
                {cols.slice(0, 10).map((c) => (
                  <List.Item key={c.key}>{c.name}</List.Item>
                ))}
              </List>
              {cols.length > 10 && (
                <Text as="span" variant="bodySm" fontWeight="bold">
                  + {cols.length - 10} more
                </Text>
              )}
            </div>
          </ReactTooltip>
        );
      })}
      <ColumnsPicker
        active={active}
        onClose={handleChange}
        selectedColumns={selectedColumns}
        availableColumns={availableColumns}
        title={title || 'Customize Columns'}
        dragChange={dragChange}
        formatItems={formatItems}
        hideColumn={hideColumn}
        showColumn={showColumn}
        limitHeight={createCustomMetric ? true : false}
        primaryAction={{
          content: allowSavePreset ? 'Apply to current preset' : 'Done',
          onAction: handleChange,
        }}
        secondaryActions={secondaryActions}
      />

      <ColumnsPicker
        active={!!editPresetModalData}
        onClose={() => {
          setEditPresetModalData(undefined);
        }}
        selectedColumns={editPresetModalData?.columns!}
        availableColumns={availableColumns
          .filter((x) => !editPresetModalData?.columns?.includes(x))
          .map((x) => ({ ...x, disableSort: false }))}
        title={
          <span className="flex items-center gap-2">
            <span>Edit Preset:</span>
            <span
              className="border border-b border-dotted border-t-0 border-l-0 border-r-0 border-slate-400"
              contentEditable
              suppressContentEditableWarning
              onBlur={(e) => {
                const { id, description, columns } = editPresetModalData!;
                setEditPresetModalData({
                  id,
                  name: e.currentTarget.innerText,
                  description,
                  columns,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  e.stopPropagation();
                  e.currentTarget.blur();
                }
              }}
            >
              {editPresetModalData?.name}
            </span>
          </span>
        }
        description={
          <span
            className="text-base text-gray-500 italic border border-b border-dotted border-t-0 border-l-0 border-r-0 border-slate-400"
            contentEditable
            suppressContentEditableWarning
            onBlur={(e) => {
              const { id, name, columns } = editPresetModalData!;
              setEditPresetModalData({
                id,
                name,
                description: e.currentTarget.innerText,
                columns,
              });
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                e.stopPropagation();
                e.currentTarget.blur();
              }
            }}
          >
            {editPresetModalData?.description}
          </span>
        }
        dragChange={(newOrder) => {
          const columnsInOrder: BaseColumn<any, any>[] = newOrder
            .filter((x) => !x.pinned)
            .filter((x) => !!x)
            .map((x) => {
              const col = editPresetModalData?.columns.find((y) => y.key === x.id);
              return col!;
            });

          const fixed = editPresetModalData?.columns.filter((x) => x.isFixed) || [];
          setEditPresetModalData((x) => {
            if (!x) {
              return x;
            }
            return {
              ...x,
              columns: [...fixed, ...columnsInOrder],
            };
          });
        }}
        formatItems={formatItems}
        hideColumn={async (column) => {
          setEditPresetModalData((x) => {
            if (!x) {
              return x;
            }
            return {
              ...x,
              columns: x.columns.filter((y) => y.key !== column.id),
            };
          });
        }}
        showColumn={(column) => {
          setEditPresetModalData((x) => {
            if (!x) {
              return x;
            }
            const newCol = availableColumns.find((y) => y.key === column.id);
            if (!newCol) {
              return x;
            }
            return {
              ...x,
              columns: [...x.columns, newCol],
            };
          });
        }}
        limitHeight={createCustomMetric ? true : false}
        primaryAction={{
          content: 'Save',
          onAction: async () => {
            const { id, name, description, columns } = editPresetModalData!;
            setAndSaveSelectedColumns(columns);
            setEditPresetModalData(undefined);
            await onEditPreset?.(id, name, description, columns);
            toast('Preset Saved', { type: 'success', autoClose: 1000 });
          },
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => {
              setEditPresetModalData(undefined);
            },
          },
        ]}
      />

      <Modal
        open={savePresetModalOpen}
        onClose={() => setSavePresetModalOpen(false)}
        title="Save Preset"
        primaryAction={{
          content: 'Save',
          disabled: !presetName?.trim(),
          onAction: () => {
            if (!presetName || !onSavePreset) {
              return;
            }
            setSavePresetModalOpen(false);
            onSavePreset(selectedColumns, presetName, presetDescription);
            setPresetName('');
            setPresetDescription('');
            setActive(false);
            toast('Preset Saved', { type: 'success', autoClose: 1000 });
          },
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => setSavePresetModalOpen(false),
          },
        ]}
      >
        <Modal.Section>
          <div
            className="flex flex-col gap-4"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (!presetName || !onSavePreset) {
                  return;
                }
                setSavePresetModalOpen(false);
                onSavePreset(selectedColumns, presetName, presetDescription);
                setPresetName('');
                setPresetDescription('');
              }
            }}
          >
            <TextField
              autoComplete="off"
              label="Preset Name"
              type="text"
              requiredIndicator
              value={presetName}
              onChange={setPresetName}
              placeholder="Enter Preset Name"
            />

            <TextField
              autoComplete="off"
              label="Preset Description"
              value={presetDescription}
              onChange={setPresetDescription}
              multiline={3}
              placeholder="Enter Preset Description"
            />
          </div>
        </Modal.Section>
      </Modal>
    </>
  );
};

type ConnectedListsProps = {
  active: boolean;
  title: React.ReactNode;
  description?: React.ReactNode;
  primaryAction: ComplexAction;
  secondaryActions: ComplexAction[];
  selectedColumns: BaseColumn<any, any>[];
  availableColumns: BaseColumn<any, any>[];
  limitHeight: boolean;
  onClose: () => void;
  formatItems: (item: BaseColumn<any, any>) => ConnectedListItem;
  dragChange: (newOrder: ConnectedListItem[]) => void;
  hideColumn: (item: ConnectedListItem) => void;
  showColumn: (item: ConnectedListItem) => void;
};

const ColumnsPicker: React.FC<ConnectedListsProps> = (props) => {
  const {
    active,
    availableColumns,
    limitHeight,
    selectedColumns,
    primaryAction,
    secondaryActions,
    title,
    description,
    onClose,
    formatItems,
    dragChange,
    hideColumn,
    showColumn,
  } = props;

  const isSmall = useIsSmall();
  const [freeSearch, setFreeSearch] = useState('');

  const filterItems = useCallback(
    (item: BaseColumn<any, any>) => {
      if (!freeSearch.trimStart().trimEnd()) {
        return true;
      }
      const lower = freeSearch.toLowerCase();
      return item.name?.toLowerCase()?.includes(lower) || item.key?.toLowerCase()?.includes(lower);
    },
    [freeSearch],
  );

  return (
    <Modal
      open={active}
      onClose={onClose}
      title={
        <span className="flex flex-col gap-2 items-start">
          <span>{title}</span>
          {description && description}
        </span>
      }
      primaryAction={primaryAction}
      secondaryActions={secondaryActions}
      limitHeight={limitHeight}
    >
      <div className="sm:sticky static top-0 z-10">
        <Modal.Section>
          <TextField
            clearButton
            onClearButtonClick={() => setFreeSearch('')}
            focused={!isSmall}
            autoComplete="off"
            prefix={<Icon source={SearchMinor} />}
            value={freeSearch}
            onChange={setFreeSearch}
            labelHidden
            label
            placeholder="Search Items"
          />
        </Modal.Section>
      </div>
      <Modal.Section>
        <ConnectedLists
          items1={selectedColumns?.filter(filterItems)?.map(formatItems)}
          items2={availableColumns?.filter(filterItems)?.map(formatItems)}
          title1={'Included Items'}
          title2={'More Items'}
          items1orderOnChange={dragChange}
          itemMoveFrom1to2onPress={hideColumn}
          itemMoveFrom2to1onPress={showColumn}
        />
      </Modal.Section>
    </Modal>
  );
};
