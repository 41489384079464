import firebase from 'firebase/compat/app';
import { startUi } from 'ducks/shop';
import { startStore, updateShop } from 'ducks/actions';
import { getAppsForShop } from 'ducks/shop';
import db, { getUserEmail, getUserId, serverTimestamp, userData, userDb } from 'utils/DB';
import Cookies from '../utils/Cookies';
import moment from '@tw/moment-cached/module/timezone';
import { isFacebookIframe, isInChromeExtensionIframe, isLocalhost, urlParams } from 'config';
import Api from '../utils/Api';
import { toast } from 'react-toastify';
import { formatSections } from 'utils/selectors';
import SECTIONS from 'constants/defaultTileSections';
import { createSelector } from 'reselect';
import { Reducer } from 'redux';
import axiosInstance from '../utils/axiosInstance';
import { uniqBy } from 'lodash';
import { services, ServicesIdsArray } from '@tw/types/module/services';
import { dataLayerSetCurrentShop, dataLayerInformAuth, dataLayerLogout } from 'utils/dataLayer';
import { clearLastViewedDashboard } from 'components/Willy/utils/lastViewedDashboard';
import { isFromMobileApp as mobileAppInNav } from 'config';

import {
  INIT_SHOP,
  INIT_UI,
  ON_AUTH_STATE_CHANGED,
  SET_CURRENT_SHOP,
  START_SIGNIN_IN,
  AUTH_FAILED,
  START_SIGNIN_UP,
  JUST_LOGGED_IN,
  USER_DATA_ON_LOAD,
  WORKSPACES_LOADED,
  DECODED_TOKEN,
  WORKSPACES_SHOPS,
} from 'ducks/constants';
import { $currentShopId, set_$currentShopId } from '$stores/$shop';
import { clearInAppContextBanner } from 'utils/InAppContextBanners';
import { setIsMobileApp } from './mobileApp';
import { datadogRum } from '@datadog/browser-rum';
import { RootState } from 'reducers/RootType';
import { reactNativeSaveData } from 'utils/reactNativePostMessage';
import { updateFFComputerConfKeys } from 'feature-flag-system';
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  getAdditionalUserInfo,
  signInWithPopup as firebaseSignInWithPopup,
} from 'firebase/auth';
import { windowSize } from 'utils/classes/WindowSizeObserver';
import { getShopImageUrl } from 'utils/getShopImageUrl';
import { sleep } from '../utils/sleep';
import { $user, $userId } from '$stores/$user';
import { User } from 'components/UserProfileManagment/User/constants';
import {
  createShopProvidersStatusFromProviders,
  hasCredentials,
  providers,
  providersFromShopWithSensory,
  shopProvidersStatus,
} from './shopIntegrations';
import { Providers } from '../types/services';
import type { ShopNavItem } from 'components/Nav/components/navs/StoresNav/types';
import { toggleLauncherVisibility } from 'utils/intercom';
import { isClrScheme } from 'dark-mode-control/utils';
import { updateColorScheme } from 'dark-mode-control';

const trackVisit = (user, data, dispatch, getState) => {
  const { isFromMobileApp } = getState().mobileApp;
  var obj: any = {
    lastVisit: serverTimestamp(),
  };

  if (isFromMobileApp) {
    obj.lastMobileVisit = serverTimestamp();
  }

  if (isInChromeExtensionIframe) {
    obj.chromeExtension = true;
    if (isFacebookIframe) {
      obj.chromeExtensionInFacebook = true;
    }
  }
  userDb().set(obj, { merge: true });
  dataLayerInformAuth(user, data, dispatch);
};

// const createOrJoinWorkspace = async (user, data) => {
//   if (data.isCreatedFromSignUp && !data.workspaces) {
//     const workspaceRef = await workspaceDb().add({ name: data.accountName })
//     await workspaceRef.set({ users: { [user.uid]: { roles: ['owner'] } } }, { merge: true })
//     await userDb().set({ workspaces: { [workspaceRef.id ]: { roles: ['owner'] } } }, { merge: true });
//   } else//sign-up from invited email
//   {
//
//   }
// };
export const serviceAds = Object.keys(services).filter((service) =>
  Object.keys(services[service].dataTypes).includes('ads-metrics'),
);

export const initShopData = () => {
  return (dispatch, getState: () => RootState) => {
    let isInit = false;
    const onInit = (shop) => {
      if (!shop || Object.keys(shop).length <= 0) {
        console.log('initShopData no shop data available yet');
        return;
      }
      const { isBetaShop } = shop;
      moment.tz.setDefault(shop.timezone);
      if (isBetaShop) {
        dispatch(getAppsForShop());
      }
      dispatch(startStore({ ...shop }));
      dataLayerSetCurrentShop(shop);
      reactNativeSaveData('currentShopId', $currentShopId.get());
    };
    if (window.twShop) {
      onInit(window.twShop);
      isInit = true;
    }
    db()
      .get()
      .then((data) => {
        if (!isInit) {
          onInit(data.data());
        }
        db().onSnapshot((shopDataSnapshot) => {
          const data = shopDataSnapshot.data() || {};
          moment.tz.setDefault(data.timezone);
          dispatch(updateShop(data));
        });
      });
  };
};

export function initAuth() {
  return (dispatch, getState: () => RootState) => {
    if (mobileAppInNav) {
      dispatch(setIsMobileApp());
    }
    const {
      mobileApp: { isFromMobileApp },
      currentShopId,
    } = getState();
    firebase.auth().onAuthStateChanged((user) => {
      dispatch(onAuthStateChanged(user));
      //----------------------------------------------------------------------------------//

      // save jwt into cookie
      if (user) {
        user.getIdToken().then((idToken) => {
          dispatch(decodeJwt(idToken));

          if (location.hostname === 'localhost') {
            return;
          }
          return axiosInstance({
            baseURL: isLocalhost ? 'http://localhost/frontend' : window.location.origin,
            url: 'sessionLogin',
            method: 'POST',
            data: { idToken },
          })
            .then((res) => {
              if (res.data?.session) {
                const cookie = res.data?.session;
                reactNativeSaveData('session', cookie);
              }
            })
            .catch();
        });
      }
      localStorage.userEmail = user?.email;

      if (!user) return;
      datadogRum.addAction('initAuth');

      function onData(data) {
        //Some weired bug I saw in Penina computer, don't know where is coming from
        if (
          (Object.keys(data ?? {}).length === 1 && data.firebaseMessagingTokenChrome) ||
          !data.email
        ) {
          return;
        }

        dispatch(startUi(data));

        // TODO: Marking one place where we dispatch fake redux ($user is doing the real work)
        $user.set({ ...data, email: user?.email ?? '', uid: user?.uid! });
        dispatch({ type: 'GET_CURRENT_USER', user: { ...data, email: user?.email ?? '' } });
        dispatch(userDataOnLoad(data));
        //----------------------------------------------------------------------------------//

        if (user?.email === 'apptesting@triplewhale.com') {
          toggleLauncherVisibility(false);
        }

        const userColorScheme = (() => {
          const colorScheme = data?.colorScheme ?? data?.darkMode;

          if (typeof colorScheme === 'boolean') return colorScheme ? 'dark' : 'light';
          if (isClrScheme(colorScheme)) return colorScheme;

          return null;
        })();
        updateColorScheme(userColorScheme);

        // whenever we get these, we need to update the ffComputer, since
        // it needs to know about all the features when doing calculation
        // for a plan the user might want to update to, etc.
        const features = data?.shops?.[currentShopId]?.features || [];
        updateFFComputerConfKeys(features);
      }

      if (window.twUser) {
        onData(window.twUser);
      }
      userDb()
        .get()
        .then((userDataSnapshot) => {
          const data = userDataSnapshot.data() || {};
          onData(data);
          const expoToken = localStorage.getItem('EXPO_TOKEN');
          if (isFromMobileApp) {
            userFromAppOnLoad(user, expoToken);
          }
          trackVisit(user, data, dispatch, getState);
          dispatch(getPods({ ...data, uid: user?.uid }));
        });
    });
  };
}

const decodeJwt = (jwt) => (dispatch) => {
  function b64DecodeUnicode(str) {
    return decodeURIComponent(
      atob(str.replace(/-/g, '+').replace(/_/g, '/'))
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join(''),
    );
  }

  // Split the JWT token into its three parts: header, payload, and signature
  const [headerB64, payloadB64, signatureB64] = jwt.split('.');

  // Decode the header and payload parts
  const decodedPayload = JSON.parse(b64DecodeUnicode(payloadB64));
  dispatch({ type: DECODED_TOKEN, payload: decodedPayload });
};

export const shouldDelayAppRender = () => {
  var facebookAccountId = urlParams.get('facebook_account_id');
  return !!facebookAccountId;
};

const waitForFirebase = async () => {
  return new Promise((res, rej) => {
    var inter;
    const _wait = () => {
      if (firebase.apps.length) {
        clearInterval(inter);
        res(true);
      }
    };
    inter = setInterval(_wait, 15);
  });
};
export const userDataOnLoad = (data) => ({
  type: USER_DATA_ON_LOAD,
  ...data,
});

export const SELECT_WORKSPACE_FROM_DB = 'SELECT_WORKSPACE_FROM_DB';
export const getPods = (data) => {
  return async (dispatch) => {
    let workspaces;
    let isError = false;
    try {
      workspaces = (await axiosInstance.post(`/v2/account-manager/pods/getPods`, data)).data;
    } catch (e) {
      isError = true;
    }
    let selectedLastWorkspace;
    try {
      selectedLastWorkspace = (await userData())?.selectedWorkspaceId;
    } catch (e) {}

    dispatch({
      type: WORKSPACES_LOADED,
      workspaces: workspaces ?? [],
    });

    if (!isError) {
      const shops = uniqBy(
        (workspaces?.reduce((acc, ws) => {
          return [...acc, ...ws.shops];
        }, []) ?? []) as any[],
        (x) => x.shopId,
      ).sort((a, b) => (a.shopId > b.shopId ? 1 : -1));
      dispatch({
        type: WORKSPACES_SHOPS,
        shops,
      });
    }

    if (selectedLastWorkspace) {
      dispatch({
        type: SELECT_WORKSPACE_FROM_DB,
        selectedLastWorkspace,
      });
    }
  };
};

export const userFromAppOnLoad = async (user, token) => {
  // alert(token.length > 0);
  if (token && token.length > 0) {
    try {
      await firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .set({ expoPushToken: token }, { merge: true });
    } catch (error) {
      console.error(error);
    }
  } else {
    try {
      // alert('removing token from db');
      await firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .set({ expoPushToken: null }, { merge: true });
    } catch (error) {
      console.error(error);
    }
  }
};

// export const SET_WORKSPACE = 'SET_WORKSPACE';
// export const setCurrentWorkspace = (currentWorkspace) =>
//   ({
//     type: SET_WORKSPACE,
//     payload: currentWorkspace,
//   });

export const switchStore = (shopId?: string, navigateToRoot = false) => {
  return async (dispatch) => {
    Cookies.remove('ui');
    const search = new URLSearchParams(window.location.search);

    if (!shopId) {
      search.delete('shop-id');
    } else {
      if (search.has('shop-id')) {
        search.set('shop-id', shopId!);
      } else {
        search.append('shop-id', shopId!);
      }
    }

    // Only allow the $currentShopId store to handle setting + effects of setting
    await set_$currentShopId(shopId!);

    const url = (navigateToRoot ? '/summary' : window.location.pathname) + '?' + search.toString();
    window.location.replace(url);
  };
};

export const saveStoreNoRedirect = (shopId) => {
  return (dispatch) => {
    dispatch(updateCurrentShopId(shopId));
  };
};

export const onAuthStateChanged = (user) => ({
  type: ON_AUTH_STATE_CHANGED,
  user,
});

export const loginSuccess = (details?: any) => {
  return {
    type: JUST_LOGGED_IN,
    ...details,
  };
};

export const setCurrentShopId = (shopId) => ({
  type: SET_CURRENT_SHOP,
  shopId,
});

export const startSigninIn = () => {
  return {
    type: START_SIGNIN_IN,
  };
};

export const authFailed = () => {
  return {
    type: AUTH_FAILED,
  };
};

export const startSigninUp = () => {
  return {
    type: START_SIGNIN_UP,
  };
};

export const signInWithEmailAndPassword = async (email, password) => {
  try {
    var response = await firebase.auth().signInWithEmailAndPassword(email, password);
    return response;
  } catch (e) {
    throw e;
  }
};

export const signInWithCustomToken = async (token) => {
  try {
    var response = await firebase.auth().signInWithCustomToken(token);
    return response;
  } catch (e) {
    throw e;
  }
};

export const checkIfUserDeleted = async (email) => {
  try {
    const { data } = await axiosInstance.post(`/v2/account-manager/users/checkIfUserDeleted`, {
      email,
    });
    return data;
  } catch (e) {
    return false;
  }
};

export const markUserAdDeleted = async (uid) => {
  try {
    const { data } = await axiosInstance.post(`/v2/account-manager/users/markUserAsDeleted`);
    return data;
  } catch (e) {
    return false;
  }
};

export const sendPasswordResetEmail = (email) => {
  return firebase
    .auth()
    .sendPasswordResetEmail(email, { url: `${window.location.origin}/signin` })
    .then(() => {
      toast.success(`Reset password email sent to: ${email}`);
    });
};

export const createUserWithEmailAndPassword = async (
  email,
  password,
  accountType = {},
  personalDetails: any = {},
  agencyName = '',
  emailVerified = false,
  isFromQuickSignup = false,
  revenue = '',
) => {
  try {
    let name = `${email ? email.split('@')?.[0] ?? email + "'s" : 'My'} Pod`;
    if (agencyName) {
      name = agencyName;
    } else {
      const { firstName, lastName } = personalDetails;
      if (firstName && lastName) {
        name = `${firstName} ${lastName}'s Pod`;
      }
    }

    await axiosInstance.post(`/v2/account-manager/users/signup`, {
      ...personalDetails,
      accountTypeObj: accountType,
      email,
      password,
      isCreatedFromInvitation: false,
      isCreatedFromSignUp: true,
      podName: name,
      emailVerified: emailVerified,
      isCreatedFromQuickSignup: isFromQuickSignup,
      revenue,
    });

    await signInWithEmailAndPassword(email, password);
  } catch (e) {
    throw e;
  }
};

export const LOGOUT = 'LOGOUT';

export function logoutOnPress() {
  return (dispatch, getState: () => RootState) => {
    Cookies.remove('token');
    Cookies.remove('session');
    Cookies.remove('ui');
    datadogRum.addAction('logoutOnPress');
    const {
      mobileApp: { isFromMobileApp },
      currentShopId,
    } = getState();
    dataLayerLogout();
    clearInAppContextBanner(currentShopId);
    clearLastViewedDashboard();

    firebase
      .auth()
      .signOut()
      .then(() => {
        document.location.replace(`/signin${isFromMobileApp ? '?app=1' : ''}`);
        dispatch(updateCurrentShopId());
        dispatch({ type: LOGOUT });
      });
    /*TODO change to signup after new sign up store flow*/
  };
}

const signInWithGoogleCredentials = async (googleAccessToken: string) => {
  const credential = GoogleAuthProvider.credential(null, googleAccessToken);
  await firebase.auth().signInWithCredential(credential);
};

const signInWithFacebookCredentials = async (facebookAccessToken: string) => {
  const credential = FacebookAuthProvider.credential(facebookAccessToken);
  await firebase.auth().signInWithCredential(credential);
};

export const signInUpWithGoogle = async (
  googleAccessToken: string,
  signIn?: boolean,
  comparisonEmail?: string,
) => {
  try {
    const response = await axiosInstance.post(
      `/v2/account-manager/users/get-user-by-google-token`,
      {
        token: googleAccessToken,
      },
    );
    const { user, googleUser } = response.data || {};
    if (comparisonEmail && googleUser.email !== comparisonEmail) {
      throw new Error(`Email: ${googleUser.email} does not match the invitation email`);
    }

    if ((!user && !signIn) || (user && signIn)) {
      await signInWithGoogleCredentials(googleAccessToken);
      if (!user) {
        const uid = getUserId();
        try {
          await axiosInstance.post(`/v2/account-manager/users/update-new-user-data`, {
            userId: uid ?? user.id,
            lastName: googleUser.family_name ?? googleUser.name,
            firstName: googleUser.given_name ?? '',
            phone: googleUser.phoneNumber,
            accountType: 'BRAND',
            isCreatedFromInvitation: false,
            isCreatedFromSignUp: true,
            isCreatedFromQuickSignup: true,
            email: googleUser.email,
            verifyEmail: true,
            signUpProvider: 'google',
            picture: googleUser.picture,
          });
        } catch (e) {
          console.log(e);
        }
      }
    } else {
      throw new Error(
        `User ${user ? 'is already' : 'does not'} exist${user ? 's' : ''}. ${
          user ? 'Please sign in below' : 'Create a user by clicking "Sign Up"'
        }`,
      );
    }
  } catch (e) {
    throw e;
  }
};

export const signInUpWithFacebook = async (
  facebookAccessToken: string,
  signIn?: boolean,
  comparisonEmail?: string,
) => {
  try {
    const response = await axiosInstance.post(
      `/v2/account-manager/users/get-user-by-facebook-token`,
      {
        token: facebookAccessToken,
      },
    );
    const { user, facebookUser } = response.data || {};
    if (comparisonEmail && facebookUser.email !== comparisonEmail) {
      throw new Error(`Email: ${facebookUser.email} does not match the invitation email`);
    }
    if ((!user && !signIn) || (user && signIn)) {
      await signInWithFacebookCredentials(facebookAccessToken);
      if (!user) {
        const uid = getUserId();
        try {
          await axiosInstance.post(`/v2/account-manager/users/update-new-user-data`, {
            verifyEmail: true,
            userId: uid ?? user.id,
            lastName: facebookUser.family_name ?? facebookUser.name,
            firstName: facebookUser.given_name ?? '',
            phone: facebookUser.phoneNumber,
            accountType: 'BRAND',
            isCreatedFromInvitation: false,
            isCreatedFromSignUp: true,
            isCreatedFromQuickSignup: true,
            email: facebookUser.email,
            signUpProvider: 'facebook',
          });
        } catch (e) {
          console.log(e);
        }
      }
    } else {
      throw new Error(
        `User ${user ? 'is already' : 'does not'} exist${user ? 's' : ''}. ${
          user ? 'Please sign in below' : 'Create a user by clicking "Sign Up"'
        }`,
      );
    }
  } catch (e) {
    throw e;
  }
};

const shops = (state = [], action) => {
  switch (action.type) {
    case USER_DATA_ON_LOAD:
      return action.shops || state;
    default:
      return state;
  }
};

const workspaces = (state: any[] = [], action) => {
  let selected;
  switch (action.type) {
    case WORKSPACES_LOADED:
      return action.workspaces ?? state;
    case PINNED_UPDATED:
      const { tiles, shopId } = action.payload;
      selected = state.find((x) => x.id === action.payload.workspaceId);
      selected = { ...selected, pinned: { ...selected.pinned, [shopId]: tiles } };
      return state.map((item) => (item.id === action.payload.workspaceId ? selected : item));
    case BLENDED_UPDATED:
      selected = state.find((x) => x.id === action.payload.workspaceId);
      selected = { ...selected, blended: action.payload.tiles };
      return state.map((item) => (item.id === action.payload.workspaceId ? selected : item));
    case USER_UPDATED:
      const { users } = action.payload;
      selected = state.find((x) => x.id === action.payload.workspaceId);
      selected = { ...selected, users };
      return state.map((item) => (item.id === action.payload.workspaceId ? selected : item));
    case PRESET_UPDATED:
      const { presets } = action.payload;
      selected = state.find((x) => x.id === action.payload.workspaceId);
      selected = { ...selected, presets };
      return state.map((item) => (item.id === action.payload.workspaceId ? selected : item));
    default:
      return state;
  }
};

const getPodsLoading = (state = true, action) => {
  switch (action.type) {
    case WORKSPACES_LOADED:
      return false;
    default:
      return state;
  }
};

const shopAsArrayFromPods = (state: any[] = [], action) => {
  switch (action.type) {
    case WORKSPACES_SHOPS:
      return action.shops ?? state;
    default:
      return state;
  }
};

export const SET_WORKSPACE_ID = 'SET_WORKSPACE_ID';
const selectedWorkspaceId = (state = '', action) => {
  switch (action.type) {
    case WORKSPACES_LOADED:
      return action.workspaces?.[0]?.id ?? state;
    case SELECT_WORKSPACE_FROM_DB:
      return action.selectedLastWorkspace ?? state;
    case SET_WORKSPACE_ID:
      return action.payload;
    default:
      return state;
  }
};

export const selectedWorkspaceSelector = createSelector(
  [(state) => state.workspaces, (state) => state.selectedWorkspaceId],
  (workspaces, selectedWorkspaceId) => {
    if (!Array.isArray(workspaces)) return {};
    return workspaces?.find((x) => x.id === selectedWorkspaceId) ?? workspaces?.[0] ?? {};
  },
);

export const selectedFilteredShopsSelector = createSelector(
  [selectedWorkspaceSelector, (state) => state.workspaceShopFilterIds],
  (selectedWorkspace, workspaceShopFilterIds) =>
    selectedWorkspace?.shops?.filter((x) => !workspaceShopFilterIds.includes(x.shopId)) ?? [],
);

export const selectedFilteredAndSearchShopsSelector = createSelector<any, any>(
  [selectedFilteredShopsSelector, (state) => state.workspaceShopSearchIds],
  (selectedFilteredShops, workspaceShopSearchIds) =>
    !workspaceShopSearchIds?.length
      ? selectedFilteredShops
      : selectedFilteredShops.filter((x) => workspaceShopSearchIds.includes(x.shopId)) ?? [],
);

export const getShopConnectionInfo = (shopWithSensory) => {
  const providersProps: Providers = providersFromShopWithSensory(shopWithSensory);
  const providersStatus = createShopProvidersStatusFromProviders(providersProps);

  const connectionInfo = {
    ...ServicesIdsArray.reduce(
      (acc, providerId) => ({
        ...acc,
        [providerId]: hasCredentials(providersStatus[providerId]),
      }),
      {},
    ),
    amazon: !!(Object.values(shopWithSensory.amazon?.sellerAccounts || {})?.filter(
      (x: any) => !x.region || ['na', 'eu', 'fe'].includes(x.region),
    )).length,
    'triple-whale': true,
  };

  return connectionInfo;
};
export const getShopsSections = (shopWithSensory, isBetaShop = false) => {
  const connectionInfo = getShopConnectionInfo(shopWithSensory);

  const fs = formatSections(
    false,
    SECTIONS.filter((s) => !s.beta),
    [],
    [],
    [],
    connectionInfo,
  );
  return fs;
};

const PINNED_UPDATED = 'PINNED_UPDATED';
export const setPinnedItems = ({ tiles, shopId }) => {
  return async (dispatch, getState) => {
    const user = (await userDb().get())?.data();
    const { workspaces } = user!;
    const { selectedWorkspaceId, workspaces: workspacesState } = getState();
    const selectedWorkspace = workspacesState.find((x) => x.id === selectedWorkspaceId);
    const lastPinned = selectedWorkspace?.pinned || {};
    await userDb().update({
      workspaces: {
        ...workspaces,
        [selectedWorkspace.id]: {
          ...workspaces[selectedWorkspace.id],
          pinned: { ...lastPinned, [shopId]: tiles },
        },
      },
    });
    dispatch({
      type: PINNED_UPDATED,
      payload: { tiles, shopId, workspaceId: selectedWorkspaceId },
    });
  };
};

const BLENDED_UPDATED = 'BLENDED_UPDATED';
export const setBlendedItems = ({ tiles }) => {
  return async (dispatch, getState) => {
    const user = (await userDb().get())?.data();
    const { workspaces } = user!;
    const { selectedWorkspaceId, workspaces: workspacesState } = getState();
    const selectedWorkspace = workspacesState.find((x) => x.id === selectedWorkspaceId);
    await userDb().update({
      workspaces: {
        ...workspaces,
        [selectedWorkspace.id]: {
          ...workspaces[selectedWorkspace.id],
          blended: tiles,
        },
      },
    });
    dispatch({
      type: BLENDED_UPDATED,
      payload: { tiles, workspaceId: selectedWorkspaceId },
    });
  };
};

export const setPresets = (presets) => {
  return async (dispatch, getState) => {
    const user = (await userDb().get())?.data();
    const { workspaces } = user!;
    const { selectedWorkspaceId, workspaces: workspacesState } = getState();
    const selectedWorkspace = workspacesState.find((x) => x.id === selectedWorkspaceId);
    await userDb().update({
      workspaces: {
        ...workspaces,
        [selectedWorkspace.id]: {
          ...workspaces[selectedWorkspace.id],
          presets: presets,
        },
      },
    });
    dispatch({
      type: PRESET_UPDATED,
      payload: { presets, workspaceId: selectedWorkspaceId },
    });
  };
};

const WORKSPACE_SHOP_FILTER_IDS = 'WORKSPACE_SHOP_FILTER_IDS';
export const excludeShopsFromWorkspace = (shopsIds) => (dispatch) => {
  dispatch({
    type: WORKSPACE_SHOP_FILTER_IDS,
    payload: shopsIds,
  });
};

const WORKSPACE_SHOP_FILTER_SEARCH_IDS = 'WORKSPACE_SHOP_FILTER_SEARCH_IDS';
export const selectedSearchIdsShopsForWorkspace = (shopsIds) => (dispatch) => {
  dispatch({
    type: WORKSPACE_SHOP_FILTER_SEARCH_IDS,
    payload: shopsIds,
  });
};

const USER_UPDATED = 'USER_UPDATED';
const PRESET_UPDATED = 'PRESET_UPDATED';
export const setWorkspaceUser = (data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: USER_UPDATED,
      payload: data,
    });
  };
};

const workspaceShopFilterIds = (state = [], action) => {
  switch (action.type) {
    case WORKSPACE_SHOP_FILTER_IDS:
      return action.payload;
    default:
      return state;
  }
};

const workspaceShopSearchIds = (state = [], action) => {
  switch (action.type) {
    case WORKSPACE_SHOP_FILTER_SEARCH_IDS:
      return action.payload;
    default:
      return state;
  }
};

const ADD_USER_STORE_MANUALLY = 'ADD_USER_STORE_MANUALLY';
const ADD_USER_STORES_MANUALLY = 'ADD_USER_STORES_MANUALLY';
const AGENCY_SIGNUP_STORES = 'AGENCY_SIGNUP_STORES';

export const addUserShopManually = (shopId) => ({
  type: ADD_USER_STORE_MANUALLY,
  payload: shopId,
});

export const addUserShopsManually = (shopId) => ({
  type: ADD_USER_STORES_MANUALLY,
  payload: shopId,
});

export const setAgencySignUpStores = (shops) => {
  if (shops.length > 1) {
    Cookies.set('agencySignUpStores', JSON.stringify(shops));
  } else {
    Cookies.remove('agencySignUpStores');
  }
  return (dispatch) => {
    dispatch({
      type: AGENCY_SIGNUP_STORES,
      payload: shops,
    });
  };
};

const shopsAsArray: Reducer<ShopNavItem[]> = (state = [], action) => {
  switch (action.type) {
    case USER_DATA_ON_LOAD:
      return action.shops
        ? Object.entries(action.shops)
            .map(([shopId, val]) => ({
              shopId,
              shopImage: getShopImageUrl(shopId),
              ...(val as object),
            }))
            .sort((a, b) => (a.shopId > b.shopId ? 1 : -1))
        : state;
    case WORKSPACES_SHOPS:
      if (!Array.isArray(action.shops) || (state.length > 0 && action.shops.length === 0))
        return state;

      return action.shops
        .map(({ shopId, ...rest }) => ({
          shopId: shopId || '',
          shopImage: getShopImageUrl(shopId),
          ...rest,
        }))
        .sort((a, b) => (a.shopId > b.shopId ? 1 : -1));
    case ADD_USER_STORE_MANUALLY:
      return [
        ...state,
        ...[{ shopId: action.payload, shopImage: getShopImageUrl(action.payload) }],
      ];
    case ADD_USER_STORES_MANUALLY:
      return [...state, ...action.payload.map((x) => ({ shopId: x }))];
    default:
      return state;
  }
};

const agencySignUpStores = (
  state = JSON.parse(Cookies.get('agencySignUpStores') ?? '[]') || [],
  action,
) => {
  switch (action.type) {
    case AGENCY_SIGNUP_STORES:
      return action.payload || state;
    default:
      return state;
  }
};

const loadingShops = (state = true, action) => {
  switch (action.type) {
    case USER_DATA_ON_LOAD:
      return false;
    default:
      return state;
  }
};

const loadingUserData = (state = true, action) => {
  switch (action.type) {
    case USER_DATA_ON_LOAD:
      return false;
    default:
      return state;
  }
};

const loadingWorkspaces = (state = true, action) => {
  switch (action.type) {
    case WORKSPACES_LOADED:
      return false;
    default:
      return state;
  }
};
const defaultWorksapceTime = {
  start: moment().subtract(1, 'w').startOf('day'),
  end: moment().subtract(1, 'd').endOf('day'),
};
export const WORKSPACES_TIME_RANGE = 'WORKSPACES_TIME_RANGE';
const workspaceTimeRange = (state = defaultWorksapceTime, action) => {
  switch (action.type) {
    case WORKSPACES_LOADED:
      return defaultWorksapceTime;
    case WORKSPACES_TIME_RANGE:
      return action.payload;
    default:
      return state;
  }
};

export const applyWorkspaceTimeRange = (date) => (dispatch) => {
  dispatch({
    type: WORKSPACES_TIME_RANGE,
    payload: date,
  });
};

export const selectedWorkspaceChanged = (id) => (dispatch) => {
  dispatch({
    type: SET_WORKSPACE_ID,
    payload: id,
  });
};

const UPDATE_CURRENT_SHOP = 'UPDATE_CURRENT_SHOP';
const UPDATE_ACTIVE_ACCOUNTS = 'UPDATE_ACTIVE_ACCOUNTS';

export const updateCurrentShopId = (newVal: string | null = null) => {
  set_$currentShopId(newVal);

  if (newVal) {
    if (window.Intercom) {
      window.Intercom('update', {
        shopifyUrl: newVal,
      });
    }
  } else {
    if (window.Intercom) {
      window.Intercom('update', {
        shopifyUrl: '',
      });
    }
    Cookies.remove('ui');
  }

  if (!newVal) {
    dataLayerSetCurrentShop(null);
  }

  return (dispatch) => {
    dispatch({
      type: UPDATE_CURRENT_SHOP,
      payload: newVal,
    });
  };
};

export const updateActiveAccounts = (accounts: string[]) => {
  return async (dispatch) => {
    userDb().update({ activeAccounts: accounts ?? [] });

    dispatch({
      type: UPDATE_ACTIVE_ACCOUNTS,
      payload: { accounts },
    });
  };
};

const currentShopId: Reducer<string> = (state = $currentShopId.get() || '', action) => {
  switch (action.type) {
    case UPDATE_CURRENT_SHOP:
      return action.payload;
    // case SET_CURRENT_SHOP:
    //   return action.shopId;
    // case INIT_UI:
    //   if (!state && action.shops && Object.keys(action.shops).length) return Object.keys(action.shops)[0];
    //   return state;
    default:
      return state;
  }
};

const activeAccountsInternal: Reducer<string[]> = (state = [], action) => {
  switch (action.type) {
    case INIT_SHOP:
      let shopIds = new Set(state);
      if (action?.shopId) shopIds.add(action.shopId);
      return [...shopIds];
    case UPDATE_CURRENT_SHOP:
      let shopIds2 = new Set(state);
      if (action?.payload) shopIds2.add(action.payload);
      return [...shopIds2];
    case UPDATE_ACTIVE_ACCOUNTS:
      return action.payload.accounts;
    case USER_DATA_ON_LOAD:
      let uniqueAccounts = new Set([...(action.activeAccounts ?? []), ...state]);
      return [...uniqueAccounts];
    default:
      return state;
  }
};

const shopSwitcherAccountList: Reducer<string[]> = (state = [], action) => {
  switch (action.type) {
    case UPDATE_ACTIVE_ACCOUNTS:
      return action.payload.accounts;
    case USER_DATA_ON_LOAD:
      let uniqueAccounts = new Set([...(action.activeAccounts ?? []), ...state]);
      return [...uniqueAccounts];
    default:
      return state;
  }
};

const justLoggedIn = (state = false, action) => {
  switch (action.type) {
    case JUST_LOGGED_IN:
      return true;
    case LOGOUT:
      return false;
    default:
      return state;
  }
};

const tokenDecoded = (state = {}, action) => {
  switch (action.type) {
    case DECODED_TOKEN:
      return action.payload;
    case LOGOUT:
      return false;
    default:
      return state;
  }
};

const justLoggedOut = (state = false, action) => {
  switch (action.type) {
    case JUST_LOGGED_IN:
      return false;
    case LOGOUT:
      return true;
    default:
      return state;
  }
};

const stripeCustomerLink = (state = null, action) => {
  switch (action.type) {
    case INIT_UI:
      return action.stripeLink || state;
    default:
      return state;
  }
};

const roles: Reducer<string[]> = (state = ['owner'], action) => {
  switch (action.type) {
    case INIT_UI:
      const { shops } = action;
      const currentShopId = $currentShopId.get();
      if (!shops || !Object.keys(shops).length || !currentShopId) return state;
      const shop = shops[currentShopId];

      const { roles } = Object(shop);
      if (!roles) return state;

      return roles;
    default:
      return state;
  }
};

const loggingNow = (state = false, action) => {
  switch (action.type) {
    case START_SIGNIN_IN:
      return true;
    case JUST_LOGGED_IN:
      return false;
    case AUTH_FAILED:
      return false;
    default:
      return state;
  }
};

const signinUpNow = (state = false, action) => {
  switch (action.type) {
    case START_SIGNIN_UP:
      return true;
    case JUST_LOGGED_IN:
      return false;
    case AUTH_FAILED:
      return false;
    default:
      return state;
  }
};

export const reducers = {
  shops,
  currentShopId,
  activeAccountsInternal,
  loadingShops,
  loadingUserData,
  justLoggedIn,
  justLoggedOut,
  workspaces,
  shopAsArrayFromPods,
  getPodsLoading,
  // selectedWorkspace,
  workspaceShopFilterIds,
  workspaceShopSearchIds,
  agencySignUpStores,
  shopSwitcherAccountList,
  shopsAsArray,
  tokenDecoded,
  loadingWorkspaces,
  selectedWorkspaceId,
  roles,
  stripeCustomerLink,
  loggingNow,
  workspaceTimeRange,
  signinUpNow,
};
