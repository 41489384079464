import React from 'react';
import { Loader } from '@tw/ui-components';
import { useOrcaPage } from './useOrcaPage';
import { OrcaPageError } from './OrcaPageError';
import Integrations from '../routes/integrations/Integrations';

export const OrcaIntegrations: React.FC = () => {
  const { loadingPage, appDetails, errorLoadingApp, appId, accountId } = useOrcaPage();

  if (!appId || !accountId || errorLoadingApp) {
    return <OrcaPageError errorLoadingApp={errorLoadingApp} appId={appId} accountId={accountId} />;
  }

  return (
    <div className={'h-screen overflow-scroll'}>
      {!loadingPage && appDetails && (
        <Integrations
          appName={appDetails.appName}
          allowProviders={appDetails.providers}
          isOrca={true}
        />
      )}
      {loadingPage && (
        <div className="flex flex-col justify-center items-center w-full h-full">
          <Loader />
        </div>
      )}
    </div>
  );
};
