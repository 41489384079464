import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DataTable, Button, Pagination, Autocomplete } from '@shopify/polaris';
import { CirclePlusMajor, EditMajor, ImportMinor } from '@shopify/polaris-icons';
import { useAppSelector, type RootState } from '../reducers/RootType';
import { useAppDispatch } from 'index';
import TWAlert from './library/TWAlert/TWAlert';
import { ShippingProfile } from './ShippingProfile/ShippingProfile';
import { CURRENCIES } from '@tw/constants';
import DeleteRowButton from './DeleteRowWithConfirmButton';
import { loadShippingProfileData, shippingProfileOnDelete } from 'ducks/shipping';
import { Icon, Loader } from '@tw/ui-components';
import { ImportCostsModal } from './ImportShippingCostsModal';
import SALES_PLATFORMS from 'constants/SalesPlatform';
import { useStoreValue } from '@tw/snipestate';
import { $shopCurrency } from '../$stores/$shop';

var cellHeight = {
  height: 37,
};

var cell = {
  ...cellHeight,
  display: 'flex',
  alignItems: 'center',
};

const SearchBar = ({ profiles, setProfiles }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const profileList = profiles.map((profile) => {
    return {
      value: profile?.name,
      label: profile?.name,
      obj: profile,
    };
  });
  return (
    <div className="pb-5">
      <Autocomplete
        id="autocompl"
        options={
          searchTerm === ''
            ? profileList
            : profileList.filter((option) => option.label.match(new RegExp(searchTerm, 'i')))
        }
        preferredPosition="below"
        selected={[]}
        onSelect={(updatedSelection) => {
          const selectedText = updatedSelection.map((selectedItem) => {
            const matchedOption = profileList.filter((option) => {
              return option.value.match(selectedItem);
            });
            return matchedOption[0];
          });
          setSearchTerm(selectedText[0].label);
          setProfiles([selectedText[0].obj]);
        }}
        textField={
          <Autocomplete.TextField
            autoComplete="off"
            onChange={setSearchTerm}
            label={''}
            value={searchTerm}
            prefix={<Icon name="search-major" />}
            placeholder="Search Shipping Profiles"
            clearButton
            onClearButtonClick={async () => {
              setSearchTerm('');
              setProfiles(profiles);
            }}
          />
        }
      />
    </div>
  );
};

const DefaultShippingSection = () => {
  const dispatch = useAppDispatch();
  const shippingProfiles = useSelector((state: RootState) => state.shippingProfiles);
  const [convertedProfiles, setConvertedProfiles] = useState<any[]>(shippingProfiles);
  const [profileModalIsOpen, setProfileModalIsOpen] = useState<boolean>(false);
  const currency = useStoreValue($shopCurrency);
  const loadingSegments = useSelector((state: RootState) => state.shopify.loadingSegments);
  const [toggleImportShippingOrderModal, setToggleImportShippingOrderModal] =
    useState<boolean>(false);
  const PAGE_SIZE = 25;
  const [page, setPage] = useState(0);
  const hasPagination =
    convertedProfiles.length > PAGE_SIZE && convertedProfiles.length === shippingProfiles.length;
  const [paginationItems, setPaginationItems] = useState<any[]>(convertedProfiles);
  const shopMsp = useAppSelector((state) => state.msp);
  const MSP_TEXT = shopMsp ? SALES_PLATFORMS[shopMsp]?.title : shopMsp;

  useEffect(() => {
    const converted = shippingProfiles
      ?.map((p) => {
        if (p?.weightType === 'lbs') {
          const costs = p?.shipping_costs.map((sc) => ({
            ...sc,
            minWeight: Math.round(sc?.minWeight * 0.00220462 * 100) / 100,
            maxWeight: Math.round(sc?.maxWeight * 0.0022046 * 100) / 100,
          }));
          return {
            ...p,
            shipping_costs: costs,
          };
        } else return p;
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    setConvertedProfiles(converted);
  }, [shippingProfiles]);

  useEffect(() => {
    setPaginationItems(convertedProfiles.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE));
  }, [page, convertedProfiles]);
  return (
    <>
      <div className="py-5">
        <TWAlert
          type={'info'}
          message={
            'If you use multiple default shipping options, they will be prioritized according to the order below. '
          }
          title={''}
        />
      </div>
      <div className="flex flex-col gap-3">
        <div className="p-5">
          <div className="flex space-between align-items-center">
            <div>Shipping costs by Order ID</div>
            <Button
              icon={ImportMinor}
              onClick={() => {
                setToggleImportShippingOrderModal(true);
              }}
            >
              {' '}
              Import Costs
            </Button>
          </div>
          <div className="text-gray-500">
            Import shipping charges based on Order ID as seen in {MSP_TEXT}
          </div>
        </div>
      </div>
      <div className="w-full h-[1px] bg-[#E5E7EB] mt-5"></div>
      <div className="pt-5 px-5">
        <div className="flex space-between align-items-center mb-5">
          <div>Shipping costs by Dynamic Rate</div>
          <Button
            icon={CirclePlusMajor}
            onClick={() => setProfileModalIsOpen(true)}
            disabled={loadingSegments}
          >
            Create a Shipping Profile
          </Button>
        </div>
        <div className="text-gray-500">
          Create a fulfllment profile to dashboard dynamic shipping costs based on zones,
          weight-tiers and more.
        </div>
        {loadingSegments && (
          <div className="pt-5">
            <Loader />
          </div>
        )}
        {convertedProfiles?.length > 0 && (
          <>
            <div className="text text-[15px] pt-7 mb-5">Shipping Profiles</div>
            <SearchBar profiles={shippingProfiles} setProfiles={setConvertedProfiles} />
            <DataTable
              columnContentTypes={['text', 'text', 'text', 'text']}
              headings={['Name', 'Zones', 'Settings', 'Shipping Rates', '']}
              rows={paginationItems
                ?.sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((profile) => [
                  <div style={cell}>
                    <div>{profile?.name}</div>
                  </div>,
                  <div style={cell}>
                    <div>
                      {profile?.worldwide
                        ? 'Worldwide'
                        : `${profile?.zones?.length} countr${
                            profile?.zones?.length === 1 ? 'y' : 'ies'
                          }${
                            profile?.zones?.some((z) => z?.states?.length > 0)
                              ? `/ ${
                                  profile.zones
                                    .filter((x) => x?.states?.length)
                                    .map((x) => x.states)
                                    .flat(2).length
                                } states`
                              : ''
                          }`}
                    </div>
                  </div>,
                  <div>
                    {profile?.shipping_methods?.length ? (
                      <div className="flex flex-col gap-3">
                        <div>
                          {profile?.shipping_costs[0]?.type === 'fixed'
                            ? 'Fixed Rate'
                            : 'Weight Based'}
                        </div>
                        <div>{profile?.shipping_methods?.length} additional method(s)</div>
                      </div>
                    ) : (
                      <div style={cell}>
                        {profile?.shipping_costs[0]?.type === 'fixed'
                          ? 'Fixed Rate'
                          : 'Weight Based'}
                      </div>
                    )}
                  </div>,
                  <div>
                    {profile.shipping_costs.length === 1 ? (
                      <div style={cell}>
                        {CURRENCIES[currency]}
                        {profile?.shipping_costs[0]?.cost}{' '}
                        {profile?.shipping_costs[0]?.type === 'weight_based' &&
                          ` - Orders ${profile?.shipping_costs[0]?.minWeight}-${
                            profile?.shipping_costs[0]?.maxWeight
                          } ${profile?.weightType === 'lbs' ? 'lbs' : 'g'}`}
                      </div>
                    ) : (
                      <div className="flex flex-col gap-3">
                        {profile?.shipping_costs.map((sc, i) => {
                          return (
                            <div key={i} className="flex">
                              {CURRENCIES[currency]}
                              {sc?.cost}
                              {sc?.type === 'weight_based' &&
                                ` - Orders ${sc?.minWeight}-${sc?.maxWeight} ${
                                  profile?.weightType === 'lbs' ? 'lbs' : 'g'
                                }`}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>,
                  <div className="flex gap-4" style={cellHeight}>
                    <Button
                      icon={<EditMajor width={16} height={16} />}
                      onClick={() => {
                        setProfileModalIsOpen(true);
                        dispatch(loadShippingProfileData(profile));
                      }}
                    />
                    <DeleteRowButton
                      primaryAction={() => {
                        dispatch(shippingProfileOnDelete(profile?.id, profile?.zones));
                      }}
                      title="Delete Shipping Profile"
                      description={`Are you sure you want to delete shipping profile - ${profile?.name}?`}
                      style={{
                        height: '25px',
                      }}
                    />
                  </div>,
                ])}
            />
            {hasPagination && (
              <Pagination
                hasPrevious={page > 0}
                onPrevious={() => setPage(page - 1)}
                hasNext={page < convertedProfiles.length / PAGE_SIZE - 1}
                onNext={() => setPage(page + 1)}
              />
            )}
          </>
        )}
      </div>
      <ShippingProfile isOpen={profileModalIsOpen} setIsOpen={setProfileModalIsOpen} />
      <ImportCostsModal
        isOpen={toggleImportShippingOrderModal}
        onClose={() => setToggleImportShippingOrderModal(false)}
        type="shippingCosts"
      />
    </>
  );
};

export default DefaultShippingSection;
