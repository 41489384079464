import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useStoreValue } from '@tw/snipestate';
import { formatSequenceItemDate, getMessagesFromWorkflowResponse } from '../../utils/sequences';
import { $shopSequences } from '$stores/willy/$sequences';
import {
  Dialect,
  Message,
  RunDoc,
  WorkflowInsightsResponse,
  WorkflowResponse,
  WorkflowStepBaseResponse,
} from '../../types/willyTypes';
import _db, { toArray } from 'utils/DB';
import { $currentShopId } from '$stores/$shop';
import axiosInstance from 'utils/axiosInstance';
interface UseSequenceMessagesProps {
  activeWorkflowId?: string;
  activeWorkflowRunId?: string;
  isSequenceViewer?: boolean;
}

export const useSequenceMessages = ({
  activeWorkflowId,
  activeWorkflowRunId,
  isSequenceViewer,
}: UseSequenceMessagesProps) => {
  const navigate = useNavigate();
  const sequences = useStoreValue($shopSequences);
  const currentShopId = useStoreValue($currentShopId);
  const [lastRun, setLastRun] = useState<RunDoc | null>(null);
  const [runsMessages, setRunsMessages] = useState<Message[]>([]);
  const [runDate, setRunDate] = useState<string | null>(null);
  const [loadingRunMessages, setLoadingRunMessages] = useState<boolean>(false);
  const [insightStep, setInsightStep] = useState<
    (WorkflowInsightsResponse & WorkflowStepBaseResponse) | null
  >(null);

  const sequence = useMemo(() => {
    return sequences.find((x) => x.id === activeWorkflowId);
  }, [sequences, activeWorkflowId]);

  const fetchData = useCallback(async () => {
    if (!activeWorkflowId) {
      return;
    }

    let sequenceRunsArray: RunDoc[] = [];

    try {
      setLoadingRunMessages(true);
      const query = _db(currentShopId)
        .collection('data_sequences')
        .doc(activeWorkflowId)
        .collection('runs');

      if (activeWorkflowRunId) {
        const doc = query.doc(activeWorkflowRunId);
        const docData = await doc.get();
        const runDoc = docData.data() as RunDoc;
        if (runDoc) {
          sequenceRunsArray.push(runDoc);
        }
      } else {
        const collection = query.orderBy('runAt', 'desc').limit(1);
        const sequenceRuns = await collection.get();
        const runsAsArray = toArray(sequenceRuns);

        if (runsAsArray.length > 0) {
          // redirect if sequence viewer
          if (isSequenceViewer) {
            navigate(`/workflows/view/${activeWorkflowId}/${runsAsArray[0].id}`);
          } else {
            // otherwise, push latest run to sequenceRunsArray
            sequenceRunsArray.push(runsAsArray[0]);
          }
        }
      }
    } catch {
      setLoadingRunMessages(false);
      return;
    }

    const lastRun =
      sequenceRunsArray.find((run) => run.id === activeWorkflowRunId) || sequenceRunsArray[0];
    setLastRun(lastRun);

    if (!lastRun) {
      setLoadingRunMessages(false);
      return;
    }

    const filteredHistory = await getRunMessages(activeWorkflowId, lastRun.id, currentShopId);

    const insightStep = filteredHistory.findLast((x) => {
      return x.stepType === 'insights';
    });

    if (insightStep) {
      setInsightStep(insightStep);
    }

    const messages = filteredHistory.flatMap((x) => {
      return getMessagesFromWorkflowResponse(x, sequence?.dialect as Dialect);
    });
    setRunsMessages(messages);
    const runDate = lastRun?.runAt ?? lastRun?.createdAt;
    setRunDate(formatSequenceItemDate(runDate));

    if (lastRun?.read === false) {
      await markRunAsRead(activeWorkflowId, lastRun.id);
    }

    setLoadingRunMessages(false);
  }, [
    activeWorkflowId,
    currentShopId,
    activeWorkflowRunId,
    isSequenceViewer,
    navigate,
    sequence?.dialect,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    sequence,
    messages: runsMessages,
    insightStep,
    runDate,
    lastRun,
    loadingRunMessages,
  };
};

export const getRunMessages = async (workflowId, runId, shopId) => {
  const oldRunHistory = await _db()
    .collection('data_sequences')
    .doc(workflowId)
    .collection('runs')
    .doc(runId)
    .collection('responses')
    .get();

  const oldRunHistoryArray = toArray<WorkflowResponse>(oldRunHistory);
  const { data } = await axiosInstance.post('/v2/sequences/workflows/get-run-history', {
    shopId,
    sequenceId: workflowId,
    runId,
  });

  const runHistoryArray = [...oldRunHistoryArray, ...(data.runHistory || [])];

  const filteredHistory = runHistoryArray?.filter((h) => {
    if (
      h.stepType === 'sendToWarehouse' ||
      h.stepType === 'sendToDashboard' ||
      h.stepType === 'sendToEmail' ||
      h.stepType === 'sendToGoogleSheet' ||
      h.stepType === 'condition' ||
      h.stepType === 'subSequence' ||
      h.stepType === 'rule'
    ) {
      return false;
    }
    return true;
  });

  return filteredHistory;
};

export const markRunAsRead = async (sequenceId: string, runId: string) => {
  await _db()
    .collection('data_sequences')
    .doc(sequenceId)
    .collection('runs')
    .doc(runId)
    .update({ read: true });
};
