import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Icon, Text, TextInput, confirm } from '@tw/ui-components';
import { toast } from 'react-toastify';
import { $currentShopId } from '$stores/$shop';
import { $userId } from '$stores/$user';
import { $categoriesList, $dashboardsMap } from './stores';
import type { CategoryListItem } from './types';
import {
  createCategoryItemFromDash,
  createCategoryListItem,
  createDefaultChildItem,
  createFormattedCategoryChildMetaData,
  formatChildren,
} from './utils';
import { resetChangeFolderModal, updateChangeFolderModal } from './ChangeCategoryModal';
import axiosInstance from 'utils/axiosInstance';

export async function initCategoryCreatorModal() {
  let categoryName = '';
  const ok = await confirm({
    title: (
      <Text fz="lg" fw={500}>
        New Folder
      </Text>
    ),
    message: (() => {
      const [_newName, set_NewName] = useState('');
      return (
        <TextInput
          mb="md"
          value={categoryName}
          onChange={(val) => {
            set_NewName(val);
            categoryName = val;
          }}
        />
      );
    }) as React.FC,
    reverseOrder: true,
    confirmText: 'Create',
    modalProps: { size: 'xs' },
  });

  if (!ok) return;

  try {
    return await createNewDashboardCategory(categoryName);
  } catch (err) {
    console.error('Error creating new category:>>', err);
  }
}

export async function createNewDashboardCategory(categoryName: string) {
  const { list } = $categoriesList.get();
  for (const { id } of list) {
    if (id !== categoryName) continue;
    toast.warn(`A category with name "${categoryName}" already exists`);
  }

  const newId = uuidv4();
  const newItem = createCategoryListItem({
    id: newId,
    depth: 0,
    type: 'folder',
    value: categoryName,
    metadata: createFormattedCategoryChildMetaData({
      isOpen: false,
      category: newId,
    }),
    children: createDefaultChildItem(newId),
  });

  try {
    await axiosInstance.post<CategoryListItem>('/v2/willy/create-dashboard-category', {
      shopId: $currentShopId.get() || '',
      category: newItem,
    });

    toast.success(`Dashboard group: "${categoryName}" created successfully`);
    return newItem;
  } catch (err) {
    console.error(err);
    toast.error(`Error creating report group: "${categoryName}"`);
  }
}

export async function deleteDashboardCategory(categoryId: string) {
  const confirmed = await confirm({
    title: (
      <div className="flex items-center">
        <Icon name="exclamation" color="red.6" size={20} />
        <Text ml="sm" fz="xl" fw={600} ta="center">
          Delete Folder
        </Text>
      </div>
    ),
    message: (
      <Text mb="lg">
        Are you sure you want to delete your folder? All of your dashboards within the folder will
        be permanently removed. This action cannot be undone.
      </Text>
    ),
    confirmText: 'Delete',
  });

  if (!confirmed) return;

  try {
    await axiosInstance.post<{ message: string }>('/v2/willy/delete-dashboard-category', {
      shopId: $currentShopId.get(),
      categoryId,
    });

    toast.success(`Dashboard folder "${categoryId}" has been successfully deleted`);
  } catch (err) {
    console.error(`Error deleting dashboard folder "${categoryId}"`, err);
    toast.error(`There was an error deleting report folder "${categoryId}"`);
  }
}

export async function renameDashboardCategory(id: string) {
  const { list } = $categoriesList.get();
  const categoryIndex = list.findIndex((c) => c.id === id);
  if (categoryIndex === -1) {
    toast.error(`Category with id ${id} doesn't exist`);
    return;
  }

  const category = list[categoryIndex];
  const oldName = category.value;
  let newName = oldName || 'Unnamed Folder';

  const confirmed = await confirm({
    title: (
      <Text fz="xl" fw={500} ta="center">
        Rename Folder
      </Text>
    ),
    message: (() => {
      const [_newName, set_NewName] = useState('');
      return (
        <TextInput
          mb="md"
          value={newName}
          onChange={(val) => {
            set_NewName(val);
            newName = val;
          }}
        />
      );
    }) as React.FC,
    confirmText: 'Save',
    reverseOrder: true,
    modalProps: { size: 'sm' },
  });

  if (!confirmed) return;

  try {
    await axiosInstance.post('/v2/willy/rename-dashboard-category', {
      shopId: $currentShopId.get(),
      categoryId: id,
      newName,
    });

    toast.success(`Dashboard group "${oldName}" has been successfully renamed to ${newName}`);
  } catch (err) {
    console.error(`Error renaming group "${oldName}"`, err);
    toast.error(`There was an error renaming report group "${oldName}"`);
  }
}

export async function updateDashboardCategories(
  updatedCategories: CategoryListItem[],
  withToast = false,
) {
  const shopId = $currentShopId.get();
  const userId = $userId.get();

  if (!shopId || !userId) {
    toast.error('No current shop set');
    return;
  }

  try {
    await axiosInstance.post('/v2/willy/update-dashboard-categories', {
      shopId,
      updatedCategories,
    });

    if (withToast) toast.success(`Successfully updated dashboards folders`);
  } catch (err) {
    console.error('Error updating dashboard categories: ', err);
    if (withToast) toast.error(`Error updating dashboards folders: ${err.message}`);
  }
}

export async function addItemToCategoriesList(item: CategoryListItem) {
  const shopId = $currentShopId.get();
  const userId = $userId.get();

  if (!shopId || !userId) {
    toast.error('No current shop set');
    return;
  }

  try {
    await axiosInstance.post('/v2/willy/add-dashboard-category-item', {
      shopId,
      item,
    });
  } catch (err) {
    console.error('Error adding categories list item: ', err);
  }
}

export type MoveDashboardToCategoryArgs = {
  dashboardId: string;
  newCategoryId: string;
  /** Determines if toast should pop up on success. true by default */
  withToast?: boolean;
};
export async function moveDashboardToCategory({
  dashboardId: dId,
  newCategoryId,
  withToast = true,
}: MoveDashboardToCategoryArgs) {
  const dashboard = $dashboardsMap.get().get(dId);
  if (!dashboard) return;

  updateChangeFolderModal({ loading: true });

  const cats = structuredClone($categoriesList.get().list);
  const newCategory = cats
    .filter((item) => item.type === 'folder')
    .find((item) => item.id === newCategoryId);

  if (!newCategory) {
    updateChangeFolderModal({ loading: false });
    toast.error(`The category you're trying to move this dashboard to doesn't exist.`);
    return;
  }

  const currIndex = cats.findIndex((i) => i.id === dId || i.children.some((c) => c.id === dId));
  // nothing to move - should be impossible
  if (currIndex === -1) {
    updateChangeFolderModal({ loading: false });
    toast.error(`The item you're trying to move doesn't exist.`);
    return;
  }

  const currentItem = cats[currIndex];
  let itemToMove: CategoryListItem | null = null;
  if (currentItem.type === 'item' && currentItem.depth === 0) {
    itemToMove = cats.splice(currIndex, 1)[0];
  } else if (currentItem.type === 'folder' || currentItem.depth > 0) {
    const childIndex = currentItem.children.findIndex((child) => child.id === dId);
    if (childIndex !== -1) itemToMove = currentItem.children.splice(childIndex, 1)[0];
  }

  if (itemToMove) itemToMove.depth = 1;
  itemToMove ??= createCategoryItemFromDash({
    augmentedDash: dashboard,
    type: 'item',
    depth: 1,
  });

  newCategory.children.push(itemToMove);
  const formattedChildren = formatChildren(newCategory);
  if (formattedChildren) newCategory.children = formattedChildren;

  try {
    await axiosInstance.post<{ message: string }>('/v2/willy/update-dashboard-categories', {
      shopId: $currentShopId.get(),
      updatedCategories: cats,
    });

    if (withToast) {
      toast.success(
        `Successfully moved dashboard: ${dashboard.name} to category ${newCategory.value}`,
      );
    }
  } catch (err) {
    toast.error(
      `Error moving dashboard: ${dashboard.name} to category ${newCategory.value} ` + err.message,
    );
    console.error(`Error moving dashboard:`, err);
  }

  resetChangeFolderModal();
}
