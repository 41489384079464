import { $currentShopId } from '$stores/$shop';
import { $availablePlans, $currentRevenue, $isFreeShop } from '$stores/willy/$subscription';
import { openBookDemoModal } from '$stores/willy/$upgradePopupManager';
import { setUpgradeClickSource } from 'ducks/signupJourney';
import { upgradePixelClosed } from 'ducks/subscription';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useAppDispatch } from 'index';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'reducers/RootType';
import axiosInstance from 'utils/axiosInstance';
import { analyticsEvents, genericEventLogger, upgradeCtaActions } from 'utils/dataLayer';
import formatPrice from 'utils/formatPrice';

import { useComputedValue, useStoreValue } from '@tw/snipestate';
import { Button, Flex, Group, Icon, List, Modal, Size, Text } from '@tw/ui-components';

import upgradeAttributionImg from '../../../images/upgradeAttribution.png';

export const UpgradeGrowthModal = () => {
  const isSmall = useIsSmall();
  const { isModalOpen, source } = useSelector((state: RootState) => state.upgradePixelModalOpen);
  const dispatch = useAppDispatch();
  const GMV = useStoreValue($currentRevenue);
  const navigate = useNavigate();
  const isProd = useSelector((state: RootState) => state.isProd);
  const GROWTH_PLAN = isProd ? 'prod_NwQaYvRzJywYyL' : 'prod_Nr7LOirfdEJMXs';
  const allPackages = useComputedValue($availablePlans, (x) => x.data?.packages);
  const growthPackage = allPackages?.find(
    (x) => x.product_id === GROWTH_PLAN && x.billing_interval === 'month',
  );
  const isFreeShop = useStoreValue($isFreeShop);

  const upgrade = useCallback(async () => {
    genericEventLogger(analyticsEvents.UPGRADE, {
      action: upgradeCtaActions.HIT_CALL_TO_ACTION,
      pathname: location.pathname,
      plan: 'Founders Dash',
      offer: 'Growth Plan',
      call_to_action: GMV > 1000000 ? upgradeCtaActions.BOOK_DEMO : analyticsEvents.UPGRADE,
      referring_upgrade_click: source,
      isFoundersDash: isFreeShop,
      gmv: GMV,
    });

    if (GMV < 1000000) {
      dispatch(setUpgradeClickSource(source));
      navigate({
        pathname: `/store-settings/plans`,
        search: `&selectedProduct=${GROWTH_PLAN}`,
      });
    } else {
      openBookDemoModal('pricing');
      await axiosInstance.post('/v2/bi/hubspot-update', {
        source: 'UPDATE_COMPANY',
        shopDomain: $currentShopId.get(),
        demo_source: source,
      });
    }
    dispatch(upgradePixelClosed());
  }, [GMV, GROWTH_PLAN, dispatch, isFreeShop, navigate, source]);

  return (
    <Modal
      opened={isModalOpen}
      onClose={() => dispatch(upgradePixelClosed())}
      size={isSmall ? ('95%' as Size) : ('65%' as Size)}
      padding={0}
      withCloseButton={false}
    >
      <Modal.Header
        bg="green"
        mih={250}
        style={{
          position: 'relative',
          zIndex: 1,
        }}
      >
        <div className="absolute top-[10px] right-[10px]">
          <Modal.CloseButton />
        </div>

        <Flex justify="space-between" align="flex-start" gap="xl" w="100%">
          <Flex direction="column" gap="md" ml={'xl'} w={`${isSmall ? '60%' : '40%'}`}>
            <Text fw={800} color="white" size={'xl'} mt={15}>
              Upgrade to Growth Plan to Unlock{' '}
              {source === 'Campaign Attribution' ? 'Campaign and Ad Level Data' : `More ${source}`}
            </Text>
            <Text size={'sm'} color="white">
              Gain the tools you need to track profitability and optimize marketing performance
              across all your key channels.
            </Text>
            {!!GMV && GMV < 1000000 && !!growthPackage?.price && (
              <Flex gap={'xs'} align={'center'}>
                <Text size={'xl'} fw={800} color="white">
                  ${formatPrice(Number(growthPackage?.price) ?? 0)}
                </Text>
                <Text size={'sm'} color="white">
                  /month
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Modal.Header>
      <Flex justify="space-between" mt={'xl'}>
        <div className="ml-5">
          <Flex direction="column" gap="md" ml={'lg'}>
            <div>
              <Text size="lg" fw={500}>
                Attribution
              </Text>
              <List>
                <List.Item icon={<Icon size={16} color="green.5" name="check-thin" />}>
                  Multi-touch attribution via Triple Pixel
                </List.Item>
                <List.Item icon={<Icon size={16} color="green.5" name="check-thin" />}>
                  7 attribution models
                </List.Item>
                <List.Item icon={<Icon size={16} color="green.5" name="check-thin" />}>
                  Campaign and ad level analysis
                </List.Item>
              </List>
            </div>

            <div>
              <Text size="lg" fw={500}>
                Business Intelligence
              </Text>
              <List>
                <List.Item icon={<Icon size={16} color="green.5" name="check-thin" />}>
                  Core Dashboards
                </List.Item>
                <List.Item icon={<Icon size={16} color="green.5" name="check-thin" />}>
                  Automated Reports
                </List.Item>
                <List.Item icon={<Icon size={16} color="green.5" name="check-thin" />}>
                  Custom Metric Builder
                </List.Item>
                <List.Item icon={<Icon size={16} color="green.5" name="check-thin" />}>
                  And much more...
                </List.Item>
              </List>
            </div>

            <Group justify="left" pt={20} pb={20}>
              <Button
                onClick={() => {
                  upgrade();
                }}
              >
                {GMV < 1000000 ? 'Get Growth' : 'Book a Demo'}
              </Button>
            </Group>
          </Flex>
        </div>
        {!isSmall && (
          <div
            className="mt-[-60px]"
            style={{
              transform: 'translateY(-100px)',
              zIndex: 2,
              position: 'relative',
            }}
          >
            <img
              src={upgradeAttributionImg}
              alt="Upgrade Attribution"
              className="max-w-[80%] ml-40"
            />
          </div>
        )}
      </Flex>
    </Modal>
  );
};
