import {
  Badge,
  Button,
  Checkbox,
  List,
  Menu,
  Modal,
  Popover,
  Select,
  TextInput,
} from '@tw/ui-components';

import { ActionIcon } from '@tw/ui-components';

import { WILLY_SEQUENCE_STEP_CATEGORIES } from '../constants';
import { WILLY_SEQUENCE_STEP_OPTIONS } from '../constants';
import { useMemo, useState } from 'react';
import { StepWsStatus, WorkflowStep, WorkflowStepInsights } from '../types/willyTypes';
import { stepActionType, SearchSource } from '../types/willyTypes';
import { Icon, Loader, Text, Tooltip } from '@tw/ui-components';
import { detectDynamicParametersInStep } from '../utils/sequences';
import { DateOptionsList } from 'pages/FreeQuery/DateOptionsList';
import { DatePickerSimple } from 'pages/FreeQuery/DatePickerSimple';
import { uniqBy } from 'lodash';
import { InsightsOutputSettingsModal } from './InsightsOutputSettingsModal';

type SequenceFlowStepHeaderProps = {
  step: WorkflowStep;
  isCollapsed: boolean;
  isRunning: boolean;
  isDone: boolean;
  toolConfig?: stepActionType;
  error?: string;
  stepRunInfo: StepWsStatus | null;
  loadingAnswers: boolean;
  stepNumber: number;
  readOnly: boolean;
  stepChange: (prompt: WorkflowStep) => void;
  deleteStep: (id: string) => void;
  toggleCollapse: (id: string) => void;
  setAssetModalOpened: (open: boolean) => void;
  viewOnly?: boolean;
  hideProgress?: boolean;
};

export const SequenceFlowStepHeader: React.FC<SequenceFlowStepHeaderProps> = ({
  step,
  isCollapsed,
  isRunning,
  isDone,
  toolConfig,
  stepRunInfo,
  loadingAnswers,
  stepNumber,
  readOnly,
  stepChange,
  deleteStep,
  toggleCollapse,
  setAssetModalOpened,
  viewOnly,
  hideProgress,
}) => {
  const [editTitle, setEditTitle] = useState(false);
  const [variablesModalOpened, setVariablesModalOpened] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const [outputSettingsModalOpened, setOutputSettingsModalOpened] = useState(false);

  const categoryConfig = useMemo(() => {
    const tool = WILLY_SEQUENCE_STEP_OPTIONS.find((option) => option.type === step.stepType);
    const toolCategory = WILLY_SEQUENCE_STEP_CATEGORIES.find(
      (option) => option.id === tool?.category,
    );
    return toolCategory;
  }, [step]);

  const hasVariablesInStep = detectDynamicParametersInStep(step);

  const isError = stepRunInfo?.status === 'error';
  const isSkipped = stepRunInfo?.status === 'skipped';
  const isAwaiting =
    stepRunInfo?.status !== 'done' &&
    stepRunInfo?.status !== 'error' &&
    stepRunInfo?.status !== 'running' &&
    stepRunInfo?.status !== 'skipped' &&
    loadingAnswers;
  const error = stepRunInfo?.error;
  const progress = stepRunInfo?.progress;

  const isParallelDisabled =
    readOnly ||
    step.stepType === 'subSequence' ||
    step.stepType === 'rule' ||
    step.stepType === 'condition' ||
    step.stepType === 'loop';

  return (
    <div
      className={`group flex p-5 rounded  items-center gap-3 justify-between cursor-pointer border border-solid border-gray-300 ${isCollapsed ? '' : 'border-b-0 rounded-b-none'} `}
      style={{
        backgroundColor: `var(--mantine-color-${toolConfig?.color}-${['one', 'two'].includes(categoryConfig?.color || '') ? '0' : '1'})`,
      }}
    >
      <div className="flex gap-3 items-center">
        {isRunning && <Loader size="xs" />}
        {!isRunning && isDone && !error && <Icon name="check-thin" color="green.4" size={16} />}
        {!!error && isError && (
          <Tooltip label={<div className="max-w-[400px] whitespace-pre-wrap">{error}</div>}>
            <div className="flex">
              <Icon name="info" color="red.4" size={16} />
            </div>
          </Tooltip>
        )}
        {isSkipped && <Icon name="circle-x" color="gray.4" size={16} />}
        {isAwaiting && <Icon name="clock" color="gray.4" size={16} />}
        <Icon
          name={toolConfig?.icon || 'triple-whale-rounded-logo'}
          color={`${toolConfig ? toolConfig?.color : 'one'}.4`}
        />
        <Text fw={500}>
          <div className="flex items-center gap-2">
            <span>{stepNumber + 1}.</span>
            <span
              className={`input-text line-clamp-1 ${hideProgress ? '' : 'max-w-97 '} ${editTitle ? 'outline-none cursor-text' : ''}`}
              ref={(el) => {
                if (!el) return;
                if (editTitle) {
                  const range = document.createRange();
                  range.selectNodeContents(el);
                  const selection = window.getSelection();
                  selection?.removeAllRanges();
                  selection?.addRange(range);

                  el.focus();
                } else {
                  el.blur();
                }
              }}
              suppressContentEditableWarning
              contentEditable={editTitle}
              onBlur={(e) => {
                setEditTitle(false);
                stepChange({
                  ...step,
                  title: e.currentTarget.innerText,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  e.currentTarget.blur();
                }
              }}
            >
              {step.title || toolConfig?.name}
            </span>
          </div>
        </Text>
        {!viewOnly && !readOnly && (
          <div className="flex gap-3 items-center opacity-0 transition-opacity group-hover:opacity-100">
            <ActionIcon
              icon="edit"
              iconSize={12}
              onClick={() => {
                setEditTitle(true);
              }}
            />
          </div>
        )}
        {!!progress && !hideProgress && (
          <Text fz={12} color="gray.4" fw={500}>
            <span className="line-clamp-1">({progress})</span>
          </Text>
        )}
      </div>
      {!viewOnly && (
        <div className="flex gap-3 items-center opacity-0 transition-opacity group-hover:opacity-100">
          <Menu closeOnItemClick={false} opened={menuOpened} onClose={() => setMenuOpened(false)}>
            <Menu.Target>
              <ActionIcon icon="settings" iconSize={16} onClick={() => setMenuOpened(true)} />
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item>
                <div className="flex items-center gap-2">
                  <Checkbox
                    checked={step.runInParallel}
                    disabled={isParallelDisabled}
                    onChange={(checked) => {
                      stepChange({
                        ...step,
                        runInParallel: checked,
                      });
                    }}
                    label="Run in parallel"
                  />
                  {isParallelDisabled && (
                    <Tooltip label={`${toolConfig?.name} can't run in parallel`}>
                      <Icon name="info" color="gray.4" size={16} />
                    </Tooltip>
                  )}
                </div>
              </Menu.Item>
              {((!!step.variables && step.variables.length > 0) || hasVariablesInStep) && (
                <Menu.Item
                  onClick={() => {
                    setVariablesModalOpened(true);
                    setMenuOpened(false);
                  }}
                >
                  Variables
                </Menu.Item>
              )}
              {step.stepType === 'tool' &&
                (step.toolToUse === 'Forecasting' ||
                  step.toolToUse === 'MarketingMixModel' ||
                  step.toolToUse === 'TextToSQL' ||
                  step.toolToUse === 'TextToPython') && (
                  <>
                    <Menu.Item>
                      <Checkbox
                        label={
                          step.toolToUse === 'TextToPython'
                            ? 'Preserve Python code'
                            : 'Preserve SQL query'
                        }
                        disabled={readOnly}
                        checked={!!step.preserveHistory}
                        onChange={(checked) => {
                          stepChange({
                            ...step,
                            preserveHistory: checked,
                          });
                          setMenuOpened(false);
                        }}
                      />
                    </Menu.Item>
                    <Menu.Item
                      disabled={!step.toolPreload || !step.preserveHistory}
                      onClick={() => {
                        setAssetModalOpened(true);
                        setMenuOpened(false);
                      }}
                    >
                      View {step.toolToUse === 'TextToPython' ? 'Code' : 'Query'}
                    </Menu.Item>
                  </>
                )}
              {step.stepType === 'insights' && (
                <Menu.Item
                  onClick={() => {
                    setOutputSettingsModalOpened(true);
                    setMenuOpened(false);
                  }}
                >
                  Output Settings
                </Menu.Item>
              )}
              {step.stepType === 'tool' && step.toolToUse === 'Searching' && (
                <Menu.Item>
                  <Select
                    data={[
                      { label: 'Internal', value: 'internal' },
                      { label: 'External', value: 'external' },
                    ]}
                    label="Search source"
                    disabled={readOnly}
                    value={step.searchSource}
                    onChange={(value) => {
                      stepChange({
                        ...step,
                        searchSource: value as SearchSource,
                      });
                      setMenuOpened(false);
                    }}
                  />
                </Menu.Item>
              )}
            </Menu.Dropdown>
          </Menu>

          {!readOnly && (
            <ActionIcon icon="delete" iconSize={20} onClick={() => deleteStep(step.id)} />
          )}
          <div className={`transition-transform ${isCollapsed ? '' : 'rotate-180'}`}>
            <ActionIcon
              icon="chevron-down"
              iconSize={10}
              onClick={() => {
                toggleCollapse(step.id);
              }}
            />
          </div>
        </div>
      )}

      <InsightsOutputSettingsModal
        opened={outputSettingsModalOpened}
        onClose={() => setOutputSettingsModalOpened(false)}
        step={step as WorkflowStepInsights}
        stepChange={stepChange as (step: WorkflowStepInsights) => void}
        readOnly={readOnly}
      />

      <Modal.Root
        size="md"
        centered
        opened={variablesModalOpened}
        onClose={() => setVariablesModalOpened(false)}
        style={{
          overflow: 'visible !important',
        }}
      >
        <Modal.Overlay />
        <Modal.Content w={'400px'} className="!flex flex-col h-full !overflow-y-visible">
          <Modal.Header>
            <Modal.Title>Variables</Modal.Title>
            <Button variant="white" onClick={() => setVariablesModalOpened(false)}>
              Close
            </Button>
          </Modal.Header>
          <div className="w-full h-[1px] bg-[#E5E7EB] mt-5"></div>
          <Modal.Body className="flex flex-auto overflow-visible !p-6.5">
            <VariablesList step={step} stepChange={stepChange} />
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
};

type VariablesListProps = {
  step: WorkflowStep;
  stepChange: (step: WorkflowStep) => void;
};

const VariablesList: React.FC<VariablesListProps> = ({ step, stepChange }) => {
  const variables = step.variables;
  let dynamicParams = detectDynamicParametersInStep(step);
  dynamicParams = uniqBy(dynamicParams, 'key');

  const [datePickerOpened, setDatePickerOpened] = useState<
    Record<'start_date' | 'end_date' | 'event_date', boolean>
  >({
    start_date: false,
    end_date: false,
    event_date: false,
  });

  if (!dynamicParams) {
    return null;
  }

  return (
    <List spacing="xs" size="sm" center>
      {dynamicParams.map((param) => {
        const isEventDate = param.key === 'event_date';
        const isDate =
          param.key === 'start_date' ||
          param.key === 'end_date' ||
          param.key === 'prev_start_date' ||
          param.key === 'prev_end_date' ||
          isEventDate;
        const variable = variables?.find((v) => v.key === param.key);

        const isVariableDefined = !!variable && variable.value.trim() !== '';
        return (
          <List.Item
            key={param.key}
            display="flex"
            dir="column"
            icon={
              <Badge
                circle
                variant="filled"
                size="xs"
                color={isVariableDefined ? 'green.4' : 'red.4'}
              />
            }
          >
            <div className="flex items-center gap-2">
              <Text color={isVariableDefined ? 'green.4' : 'red.4'}>{param.key}</Text>

              {isDate && (
                <Popover
                  withinPortal={false}
                  opened={datePickerOpened[param.key]}
                  onClose={() => setDatePickerOpened({ ...datePickerOpened, [param.key]: false })}
                >
                  <Popover.Target>
                    <div>
                      <Button
                        variant="activator"
                        onClick={() =>
                          setDatePickerOpened({
                            ...datePickerOpened,
                            [param.key]: !datePickerOpened[param.key],
                          })
                        }
                      >
                        {variable?.value || 'Select date'}
                      </Button>
                    </div>
                  </Popover.Target>
                  <Popover.Dropdown maw="300px" p="xs">
                    <div className="flex flex-col gap-4 max-h-100 overflow-y-auto">
                      {isEventDate && (
                        <DateOptionsList
                          selectedOption={variable?.value as string}
                          onOptionSelect={(option) => {
                            stepChange({
                              ...step,
                              variables: [{ key: param.key, value: option }],
                            });
                            setDatePickerOpened({
                              ...datePickerOpened,
                              [param.key]: false,
                            });
                          }}
                        />
                      )}

                      {!isEventDate && (
                        <DatePickerSimple
                          selected={
                            Array.isArray(variable?.value)
                              ? variable?.value
                              : variable?.value.split(',')
                          }
                          onChange={(value) => {
                            stepChange({
                              ...step,
                              variables: [{ key: param.key, value: value.start }],
                            });

                            setDatePickerOpened({
                              ...datePickerOpened,
                              [param.key]: false,
                            });
                          }}
                          onClose={() =>
                            setDatePickerOpened({
                              ...datePickerOpened,
                              [param.key]: false,
                            })
                          }
                        />
                      )}
                    </div>
                  </Popover.Dropdown>
                </Popover>
              )}
              {!isDate && (
                <TextInput
                  value={variable?.value || ''}
                  onChange={(value) => {
                    stepChange({
                      ...step,
                      variables: [{ key: param.key, value }],
                    });
                  }}
                  placeholder="Enter value"
                />
              )}
              {isDate && (
                <Tooltip label="This variable will be overridden by the date picker in the UI if it is exists">
                  <Icon name="info" color="gray.4" size={16} />
                </Tooltip>
              )}
            </div>
          </List.Item>
        );
      })}
    </List>
  );
};
