import React, { FC, useEffect, useState } from 'react';
import { Spinner } from '@shopify/polaris';
import axiosInstance from '../../../utils/axiosInstance';
import { useSelector } from 'react-redux';
import { type RootState } from '../../../reducers/RootType';
import { signInWithCustomToken, signInWithEmailAndPassword } from '../../../ducks/auth';
import { toast } from 'react-toastify';
import { Text } from '@tw/ui-components';

interface Auth2LoginProps {}

export const Auth2Login: FC<Auth2LoginProps> = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [getLoginData, setGetLoginData] = useState<{
    skip: boolean;
    context: any;
    appId: string;
  } | null>(null);
  // const [showSignup, setShowSignup] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const login_challenge = urlParams.get('login_challenge');
  const firebaseUser = useSelector((state: RootState) => state.firebaseUser);

  useEffect(() => {
    if (!login_challenge) {
      setError('Missing login_challenge in query parameters.');
      setIsLoading(false);
      return;
    }

    let isMounted = true;
    const fetchLoginData = async () => {
      try {
        if (!getLoginData) {
          const {
            data: { skip, context, appId },
          } = await axiosInstance.get(
            `/v2/users/oauth2/flow/login-request?login_challenge=${login_challenge}`,
          );
          if (isMounted) setGetLoginData({ skip, context, appId });
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching login data:', error);
          setError('Failed to fetch login data. Please try again.');
          setIsLoading(false);
        }
      }
    };

    fetchLoginData();
    return () => {
      isMounted = false;
    };
  }, [login_challenge, getLoginData]);

  useEffect(() => {
    const handleLogin = async () => {
      try {
        if (getLoginData && firebaseUser) {
          const { data } = await axiosInstance.post(
            `/v2/users/oauth2/flow/login-request?login_challenge=${login_challenge}`,
          );
          window.location.replace(data.redirect_to);
        }
        if (getLoginData && !firebaseUser) {
          const { context } = getLoginData;
          const { token } = context ?? {};
          if (!token) {
            setError('Failed to authenticate. Please contact support.');
            setIsLoading(false);
            return;
          }
          try {
            try {
              await signInWithCustomToken(token);
            } catch (e) {
              return toast.error(`Failed to authenticate. Please contact support.`);
            }
          } catch (e) {
            setError('Your account must be registered with OrcaBase. Please contact support.');
            setIsLoading(false);
          }
        }
      } catch (error) {
        console.error('Error handling login:', error);
        setError('Failed to process login. Please try again.');
        setIsLoading(false);
      }
    };

    handleLogin();
  }, [firebaseUser, getLoginData, login_challenge]);

  return (
    <div
      style={{
        position: 'fixed',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      {error && (
        <Text size={'xl'} color={'red.3'}>
          Error: {error}
        </Text>
      )}
      {isLoading && !error && <Spinner accessibilityLabel="App is loading" size="large" />}
    </div>
  );
};
