import {
  Button,
  Container,
  Flex,
  Grow,
  Icon,
  Modal,
  confirm,
  Size,
  Text,
  TextInput,
  Tabs,
  ActionIcon,
} from '@tw/ui-components';
import {
  GridColumnOptions,
  GridOptions,
  MessageTypes,
  NlqResponse,
  TileModes,
  WillyBaseMainElement,
  WillyDashboardElement,
  WillyMetric,
  WillyParameter,
  WillyWidgetElement,
} from '../types/willyTypes';
import { WillyWidget, WillyWidgetProps } from '../WillyWidget';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TileConfiguration } from './TileConfiguration';
import { TableCustomize } from './TableCustomize';
import { WidgetDataEditor } from './WidgetDataEditor';
import { MetricsEditor } from './MetricsEditor';
import { ChartCustomize } from './ChartCustomize';
import { useAvailableTypes } from '../hooks/useAvailableTypes';
import { refreshDashboardWidgets } from '../dashContext';
import { fetchQueryBuilderData, getMainElementDbRef } from '../utils/willyUtils';
import { FieldValue } from 'utils/DB';
import { TableBuilderTab } from './TableBuilder/TableBuilderTab';
import { WidgetVisualizationPopover } from '../widgetVisualizationTypes/WidgetVisualizationPopover';
import { messageTypes } from '../constants';
import { LetsBuild } from './TableBuilder/LetsBuild';
import { useStoreValue } from '@tw/snipestate';
import { $currentDateRange } from '$stores/willy/$dateRange';
import { $activeAccounts, $currentShopId } from '$stores/$shop';
import { BuilderTable } from '@tw/willy-data-dictionary/module/columns/types';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';

type WillyWidgetEditorProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  type: MessageTypes;
  dataHasBreakdown?: boolean;
  showPreviousPeriod?: boolean;
  setShowPreviousPeriod?: (showPreviousPeriod: boolean) => void;
  setSaved?: (saved: boolean) => void;
  onSave?: (widget: WillyWidgetElement) => void;
  rightYAxisLabel?: string;
  setRightYAxisLabel?: (label: string) => void;
  leftYAxisLabel?: string;
  setLeftYAxisLabel?: (label: string) => void;
  xAxisLabel?: string;
  setXAxisLabel?: (label: string) => void;
} & WillyWidgetProps;

const emptyArr = [];

const DATE_FORMAT = 'YYYY-MM-DD';

export const WillyWidgetEditor: React.FC<WillyWidgetEditorProps> = ({
  open,
  setOpen,
  queryId,
  isDynamic,
  filtersOpen,
  type,
  title,
  metrics,
  queries,
  stacked = false,
  isCustomView = false,
  incrementedStacked = false,
  question = '',
  initialRawData,
  parameters,
  wrapText,
  currency,
  stackedChanged,
  permissionChanged,
  parametersChanged,
  incrementedStackedChanged,
  metricsChanged,
  typeChanged,
  titleChanged,
  setEditMetricModalOpen,
  setWrapText,
  setFiltersOpen,
  dashboard,
  loadInitialData,
  isSyncCharts,
  grid = 'flex',
  setGrid = () => {},
  gridColumns = 2,
  setGridColumns = () => {},
  twoColumnMobile = false,
  setTwoColumnMobile = () => {},
  tileMode = 'tile',
  setTileMode = () => {},
  skinny = false,
  setSkinny = () => {},
  yAxisDomain,
  setYAxisDomain,
  allowDataOverflow,
  setAllowDataOverflow,
  dimension,
  permission,
  setDimension,
  CDPSegmentId,
  hasGlobalConditionalFormatting,
  globalConditionalFormattingColor,
  setHasGlobalConditionalFormatting,
  setGlobalConditionalFormattingColor,
  breakdownMode,
  breakdownModeChanged,
  queriesChanged,
  dataHasBreakdown,
  showPreviousPeriod,
  setShowPreviousPeriod,
  initialPreviousPeriodRawData,
  loadInitialPreviousPeriodData,
  dialect,
  mode,
  builderSetup,
  builderSetupChanged,
  setSaved,
  onSave,
  chartLabel,
  setChartLabel,
  rightYAxisLabel,
  setRightYAxisLabel,
  leftYAxisLabel,
  setLeftYAxisLabel,
  xAxisLabel,
  setXAxisLabel,
  chartLayout,
  setChartLayout,
  customFilters,
}) => {
  const currentShopId = useStoreValue($currentShopId);
  const activeAccounts = useStoreValue($activeAccounts);
  const datePicker = useStoreValue($currentDateRange);
  const isSmall = useIsSmall();
  const [sideBarOpened, setSideBarOpened] = useState(true);

  const [activeTab, setActiveTab] = useState<string>('data');

  const [editedWidgetType, setEditedWidgetType] = useState<MessageTypes>(type);
  const [editedWrapText, setEditedWrapText] = useState<boolean>();
  const [editedHasGlobalConditionalFormatting, setEditedHasGlobalConditionalFormatting] =
    useState<boolean>();
  const [editedGlobalConditionalFormattingColor, setEditedGlobalConditionalFormattingColor] =
    useState<string>(globalConditionalFormattingColor);
  const [editedMetrics, setEditedMetrics] = useState<WillyMetric[]>(emptyArr);
  const [editedGrid, setEditedGrid] = useState<GridOptions>(grid);
  const [editedGridColumns, setEditedGridColumns] = useState<GridColumnOptions>(gridColumns);
  const [editedTileMode, setEditedTileMode] = useState<TileModes>(tileMode);
  const [editedTwoColumnMobile, setEditedTwoColumnMobile] = useState<boolean>(twoColumnMobile);
  const [editedParameters, setEditedParameters] = useState<WillyParameter[]>(emptyArr);
  const [editedBreakdownMode, setEditedBreakdownMode] = useState<boolean>(breakdownMode ?? false);
  const [editedShowPreviousPeriod, setEditedShowPreviousPeriod] = useState<boolean>(
    showPreviousPeriod ?? false,
  );
  const [editedAllowDataOverflow, setEditedAllowDataOverflow] =
    useState<boolean>(allowDataOverflow);
  const [editedYAxisDomain, setEditedYAxisDomain] = useState(yAxisDomain);
  const [editedSkinny, setEditedSkinny] = useState(skinny);
  const [editedStacked, setEditedStacked] = useState(stacked);
  const [editedDimension, setEditedDimension] = useState(dimension);
  const [editedBuilderSetup, setEditedBuilderSetup] = useState<BuilderTable | undefined>(
    builderSetup,
  );
  const [editedChartLabel, setEditedChartLabel] = useState(chartLabel);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [previewData, setPreviewData] = useState<NlqResponse>();
  const [editedRightYAxisLabel, setEditedRightYAxisLabel] = useState<string>(rightYAxisLabel ?? '');
  const [editedLeftYAxisLabel, setEditedLeftYAxisLabel] = useState<string>(leftYAxisLabel ?? '');
  const [editedXAxisLabel, setEditedXAxisLabel] = useState<string>(xAxisLabel ?? '');
  const [editedChartLayout, setEditedChartLayout] = useState(chartLayout ?? 'horizontal');

  const isBuilder = useMemo(() => mode === 'builder' && builderSetup, [builderSetup, mode]);

  const availableTypes = useAvailableTypes(initialRawData?.data, editedMetrics);

  const updateEditedMetrics = useCallback(async (id: string, data: WillyMetric[]) => {
    setEditedMetrics(data);
  }, []);

  const tabs = useMemo(() => {
    if (isBuilder) {
      return [
        { value: 'data', label: 'Build' },
        { value: 'customize', label: 'Stylize' },
      ];
    }
    return [
      { value: 'data', label: 'Data' },
      { value: 'customize', label: 'Customize' },
    ];
  }, [isBuilder]);

  const onResetCustomization = useCallback(async () => {
    const approved = await confirm({
      title: 'Are you sure?',
      message: 'This will reset the element to its default state',
    });
    if (approved) {
      const partialDashboard: Partial<WillyDashboardElement> = {
        id: dashboard?.id,
        isCustomView: isCustomView,
        isGlobal: dashboard?.isGlobal ?? false,
      };
      await getMainElementDbRef(partialDashboard as WillyBaseMainElement)
        .collection('widgets')
        .doc(queryId)
        .set(
          {
            metrics: [],
            stacked: FieldValue.delete(),
            incrementedStacked: FieldValue.delete(),
            showPreviousPeriod: FieldValue.delete(),
            wrapText: FieldValue.delete(),
            grid: FieldValue.delete(),
            gridColumns: FieldValue.delete(),
            twoColumnMobile: FieldValue.delete(),
            tileMode: FieldValue.delete(),
          },
          {
            merge: true,
          },
        );

      await refreshDashboardWidgets();
    }
  }, [dashboard?.id, dashboard?.isGlobal, isCustomView, queryId]);

  const fetchPreviewData = useCallback(async () => {
    if (!datePicker || !editedBuilderSetup || !currentShopId || !activeAccounts) {
      return;
    }
    const { columns, table } = editedBuilderSetup;
    if (!table || !columns.length) {
      return;
    }

    const { start, end } = datePicker;

    const shouldAddDate = !editedBuilderSetup.filters.some(
      (f) => f.column === 'event_date' && f.value,
    );
    const builderData: BuilderTable = {
      ...editedBuilderSetup,
      filters: [
        ...editedBuilderSetup.filters.filter((f) => f.visible || f.visibleInDashboard),
        ...(shouldAddDate
          ? [
              {
                column: 'event_date',
                operator: 'between',
                value: [start.format(DATE_FORMAT), end.format(DATE_FORMAT)],
              } as any,
            ]
          : []),
      ],
      customFilters,
    };

    // const cols = addEventDateIfNoAnyDimension(builder.columns, currentTable);
    // setBuilder({ ...builder, columns: cols });

    const res = await fetchQueryBuilderData(builderData, {
      shopId: currentShopId,
      additionalShopIds: activeAccounts,
    });

    return res;
  }, [datePicker, editedBuilderSetup, currentShopId, activeAccounts, customFilters]);

  useEffect(() => {
    if (!editedBuilderSetup) {
      return;
    }
    setIsPreviewLoading(true);
    fetchPreviewData().then((res) => {
      setPreviewData(res);
      setIsPreviewLoading(false);
    });
  }, [editedBuilderSetup, fetchPreviewData]);

  useEffect(() => {
    setEditedWrapText(wrapText);
    setEditedHasGlobalConditionalFormatting(hasGlobalConditionalFormatting);
    setEditedGlobalConditionalFormattingColor(globalConditionalFormattingColor);
    setEditedMetrics(metrics);
    setEditedGrid(grid);
    setEditedGridColumns(gridColumns);
    setEditedTileMode(tileMode);
    setEditedTwoColumnMobile(twoColumnMobile);
    setEditedParameters(parameters ?? []);
    setEditedBreakdownMode(breakdownMode ?? false);
    setEditedShowPreviousPeriod(showPreviousPeriod ?? false);
    setEditedAllowDataOverflow(allowDataOverflow);
    setEditedYAxisDomain(yAxisDomain);
    setEditedSkinny(skinny);
    setEditedRightYAxisLabel(rightYAxisLabel ?? '');
    setEditedLeftYAxisLabel(leftYAxisLabel ?? '');
    setEditedXAxisLabel(xAxisLabel ?? '');
    setEditedStacked(stacked);
    setEditedWidgetType(type);
    setEditedDimension(dimension);
    setEditedBuilderSetup(builderSetup);
    setEditedChartLabel(chartLabel);
    setEditedChartLayout(chartLayout ?? 'horizontal');
    setSideBarOpened(true);
  }, [
    globalConditionalFormattingColor,
    grid,
    gridColumns,
    hasGlobalConditionalFormatting,
    metrics,
    tileMode,
    twoColumnMobile,
    wrapText,
    open,
    parameters,
    breakdownMode,
    showPreviousPeriod,
    allowDataOverflow,
    yAxisDomain,
    skinny,
    rightYAxisLabel,
    leftYAxisLabel,
    xAxisLabel,
    stacked,
    type,
    dimension,
    builderSetup,
    chartLabel,
    chartLayout,
  ]);

  const handleSave = useCallback(async () => {
    if (editedWrapText !== undefined) setWrapText(editedWrapText);
    if (editedHasGlobalConditionalFormatting !== undefined)
      setHasGlobalConditionalFormatting(editedHasGlobalConditionalFormatting);
    if (editedGlobalConditionalFormattingColor)
      setGlobalConditionalFormattingColor(editedGlobalConditionalFormattingColor);
    setGrid(editedGrid ?? grid);
    setGridColumns(editedGridColumns ?? gridColumns);
    setTileMode(editedTileMode ?? tileMode);
    setTwoColumnMobile(editedTwoColumnMobile ?? twoColumnMobile);
    parametersChanged?.(editedParameters);
    setOpen(false);
    await breakdownModeChanged?.(editedBreakdownMode);
    setShowPreviousPeriod?.(editedShowPreviousPeriod);
    setAllowDataOverflow(editedAllowDataOverflow);
    setYAxisDomain(editedYAxisDomain);
    setSkinny(editedSkinny);
    stackedChanged(editedStacked);
    typeChanged(editedWidgetType);
    setDimension(editedDimension);
    setChartLayout?.(editedChartLayout);

    setLeftYAxisLabel?.(editedLeftYAxisLabel);
    setRightYAxisLabel?.(editedRightYAxisLabel);
    setXAxisLabel?.(editedXAxisLabel);

    if (editedChartLabel) setChartLabel?.(editedChartLabel);
    if (editedBuilderSetup) await builderSetupChanged(editedBuilderSetup);
    //this always need to be the last one
    await metricsChanged(queryId ?? '', editedMetrics);

    if (onSave && queryId) {
      onSave({
        queryId: queryId,
        title: title,
        dialect,
        incrementedStacked: incrementedStacked,
        metrics: editedMetrics,
        parameters: editedBuilderSetup?.filters,
        stacked: editedStacked,
        chartLabel: editedChartLabel,
        type: editedWidgetType,
        twoColumnMobile: editedTwoColumnMobile,
        gridColumns: editedGridColumns,
        skinny: editedSkinny,
        chartLayout: editedChartLayout,
        grid: editedGrid,
        wrapText: editedWrapText,
        allowDataOverflow: editedAllowDataOverflow,
        yAxisDomain: editedYAxisDomain,
        rightYAxisLabel: editedRightYAxisLabel,
        leftYAxisLabel: editedLeftYAxisLabel,
        xAxisLabel: editedXAxisLabel,
        tileMode: editedTileMode,
        hasGlobalConditionalFormatting: editedHasGlobalConditionalFormatting,
        globalConditionalFormattingColor: editedGlobalConditionalFormattingColor,
        mode: mode,
        builderSetup: editedBuilderSetup,
        queries: [
          {
            id: queryId,
            query: '', // TODO: generate query
            question: '',
          },
        ],
      });
    }
  }, [
    editedWrapText,
    setWrapText,
    editedHasGlobalConditionalFormatting,
    setHasGlobalConditionalFormatting,
    editedGlobalConditionalFormattingColor,
    setGlobalConditionalFormattingColor,
    setGrid,
    editedGrid,
    grid,
    setGridColumns,
    editedGridColumns,
    gridColumns,
    setTileMode,
    editedTileMode,
    tileMode,
    setTwoColumnMobile,
    editedTwoColumnMobile,
    twoColumnMobile,
    parametersChanged,
    editedParameters,
    setOpen,
    breakdownModeChanged,
    editedBreakdownMode,
    setShowPreviousPeriod,
    editedShowPreviousPeriod,
    setAllowDataOverflow,
    editedAllowDataOverflow,
    setYAxisDomain,
    editedYAxisDomain,
    setSkinny,
    editedSkinny,
    stackedChanged,
    editedStacked,
    typeChanged,
    editedWidgetType,
    setDimension,
    editedDimension,
    editedBuilderSetup,
    builderSetupChanged,
    metricsChanged,
    queryId,
    editedMetrics,
    onSave,
    title,
    dialect,
    incrementedStacked,
    mode,
    editedChartLabel,
    setChartLabel,
    editedLeftYAxisLabel,
    editedRightYAxisLabel,
    editedXAxisLabel,
    setLeftYAxisLabel,
    setRightYAxisLabel,
    setXAxisLabel,
    editedChartLayout,
    setChartLayout,
  ]);

  return (
    <Modal
      opened={open}
      onClose={() => setOpen(false)}
      headerBorder
      size={'100%' as Size}
      padding={0}
      withCloseButton={false}
      fullScreen={isSmall}
    >
      <Modal.Header bg="white">
        <Flex justify="space-between" px="md" align="center" w="100%">
          <Flex align="center" gap="md">
            <Icon name="widget-editor" size={16} />
            <Text weight={500}>Edit Section</Text>
          </Flex>
          <Flex ml="auto" gap="md">
            <Button variant="activator" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                handleSave();
                setSaved?.(true);
              }}
            >
              Save
            </Button>
          </Flex>
        </Flex>
      </Modal.Header>
      <Flex overflow="hidden" h={isSmall ? 'calc(100vh - 60px)' : 'calc(90vh - 60px'}>
        <Container
          className="border border-solid border-[#e5e7eb] border-l-0 border-t-0 border-b-0 overflow-visible"
          px={0}
          py="lg"
          w={isSmall && !sideBarOpened ? 30 : 340} //"30%"
          bg="white"
          h="100%"
          pos="sticky"
          right={0}
          m={0}
        >
          {isSmall && (
            <div className="absolute top-0 right-0 h-full w-1">
              <div
                className={`right-0 rounded-full bg-white w-[20px] h-[20px] flex justify-center items-center
                    shadow-xl absolute top-[calc(20px+var(--top-bar-height))] translate-x-[10px] z-[100]`}
              >
                <ActionIcon
                  size="sm"
                  radius="round"
                  variant="activator"
                  icon={sideBarOpened ? 'chevron-left-minor' : 'chevron-right-minor'}
                  iconSize={12}
                  onClick={() => setSideBarOpened((x) => !x)}
                />
              </div>
            </div>
          )}
          {sideBarOpened && (
            <Flex direction="column" overflow="auto" h="100%">
              <Tabs
                value={activeTab}
                onChange={(value) => {
                  if (value) setActiveTab(value);
                }}
                px="md"
                pb="md"
              >
                <Tabs.List grow justify="center">
                  {tabs.map((tab) => (
                    <Tabs.Tab
                      fz="md"
                      fw={500}
                      color={activeTab === tab.value ? 'one.5' : 'gray.5'}
                      key={tab.value}
                      value={tab.value}
                    >
                      {tab.label}
                    </Tabs.Tab>
                  ))}
                </Tabs.List>
              </Tabs>
              {/* <SegmentedControl
              size="md"
              value={activeTab}
              data={tabs}
              onChange={(value) => setActiveTab(value)}
              mx="md"
            /> */}
              <Container h={1} w="100%" bg="gray.2" />
              {activeTab === 'data' &&
                (isBuilder ? (
                  <TableBuilderTab
                    queryId={queryId}
                    builder={editedBuilderSetup!}
                    setBuilder={setEditedBuilderSetup}
                    updateEditedMetrics={updateEditedMetrics}
                    editedMetrics={editedMetrics}
                  />
                ) : (
                  <WidgetDataEditor
                    metrics={editedMetrics}
                    updateMetrics={updateEditedMetrics}
                    queryId={queryId}
                    widgetType={editedWidgetType}
                    dimension={editedDimension}
                    setDimension={setEditedDimension}
                    rawData={previewData ?? initialRawData}
                    stacked={editedStacked}
                    setStacked={setEditedStacked}
                  />
                ))}
              {activeTab === 'customize' && (
                <Flex direction="column" gap="md" pt="md">
                  <WidgetVisualizationPopover
                    queryId={queryId}
                    widgetType={editedWidgetType}
                    metrics={editedMetrics}
                    availableTypes={availableTypes}
                    onChange={setEditedWidgetType}
                    updateMetrics={updateEditedMetrics}
                    activator={(onClick, value, icon) => (
                      <TextInput
                        label="Visualization Type"
                        value={value ?? editedWidgetType}
                        leadingIcon={icon ?? messageTypes[editedWidgetType].icon}
                        onClick={onClick}
                        pb="xs"
                        px="md"
                      />
                    )}
                    stacked={editedStacked}
                    setStacked={setEditedStacked}
                    chartLayout={editedChartLayout}
                    setChartLayout={setEditedChartLayout}
                  />
                  {editedWidgetType === 'table' && queryId && (
                    <TableCustomize
                      editedWrapText={editedWrapText}
                      setEditedWrapText={setEditedWrapText}
                      editedHasGlobalConditionalFormatting={editedHasGlobalConditionalFormatting}
                      setEditedHasGlobalConditionalFormatting={
                        setEditedHasGlobalConditionalFormatting
                      }
                      editedGlobalConditionalFormattingColor={
                        editedGlobalConditionalFormattingColor
                      }
                      setEditedGlobalConditionalFormattingColor={
                        setEditedGlobalConditionalFormattingColor
                      }
                      editedMetrics={editedMetrics}
                      updateEditedMetrics={updateEditedMetrics}
                      queryId={queryId}
                      editedBreakdownMode={editedBreakdownMode}
                      setEditedBreakdownMode={setEditedBreakdownMode}
                      dataHasBreakdown={dataHasBreakdown ?? false}
                    />
                  )}
                  {(editedWidgetType === 'tile' || editedWidgetType === 'summaryBox') && (
                    <TileConfiguration
                      grid={editedGrid}
                      setGrid={setEditedGrid}
                      gridColumns={editedGridColumns}
                      setGridColumns={setEditedGridColumns}
                      tileMode={editedTileMode}
                      setTileMode={setEditedTileMode}
                      twoColumnMobile={editedTwoColumnMobile}
                      setTwoColumnMobile={setEditedTwoColumnMobile}
                    />
                  )}
                  {editedWidgetType === 'chart' && (
                    <ChartCustomize
                      showPreviousPeriod={editedShowPreviousPeriod}
                      setShowPreviousPeriod={setEditedShowPreviousPeriod}
                      allowDataOverflow={editedAllowDataOverflow}
                      setAllowDataOverflow={setEditedAllowDataOverflow}
                      yAxisDomain={editedYAxisDomain}
                      setYAxisDomain={setEditedYAxisDomain}
                      skinny={editedSkinny}
                      setSkinny={setEditedSkinny}
                      stacked={editedStacked}
                      setStacked={setEditedStacked}
                      chartLabel={editedChartLabel}
                      setChartLabel={setEditedChartLabel}
                      rightYAxisLabel={editedRightYAxisLabel}
                      setRightYAxisLable={setEditedRightYAxisLabel}
                      leftYAxisLabel={editedLeftYAxisLabel}
                      setLeftYAxisLable={setEditedLeftYAxisLabel}
                      xAxisLabel={editedXAxisLabel}
                      setXAxisLable={setEditedXAxisLabel}
                    />
                  )}

                  <MetricsEditor
                    metrics={editedMetrics}
                    updateMetrics={updateEditedMetrics}
                    queryId={queryId}
                    customize
                    widgetType={editedWidgetType}
                  />

                  <Button
                    mx="md"
                    variant="activator"
                    leftSection={<Icon name="refresh" size={16} />}
                    onClick={onResetCustomization}
                  >
                    Reset Customization
                  </Button>
                </Flex>
              )}
            </Flex>
          )}
        </Container>
        {((isSmall && !sideBarOpened) || !isSmall) && (
          <Grow
            p={isSmall ? 'md' : 'xl'}
            // mah="80vh"
            // h="80vh"
            h="100%"
            overflow="auto"
            maw={isSmall ? undefined : 'calc(100% - 340px)'}
          >
            {isBuilder && !previewData ? (
              isPreviewLoading ? (
                <Flex w="100%" h="100%" justify="center" align="center">
                  <Text weight={500}>Loading...</Text>
                </Flex>
              ) : (
                <LetsBuild />
              )
            ) : (
              <WillyWidget
                permission={permission}
                permissionChanged={permissionChanged}
                //ref={widgetRef}
                currency={currency}
                context="chat"
                titleChanged={titleChanged}
                typeChanged={setEditedWidgetType}
                question={question}
                queryId={queryId}
                title={title}
                type={editedWidgetType}
                stacked={editedStacked}
                parameters={editedParameters}
                incrementedStacked={incrementedStacked}
                stackedChanged={setEditedStacked}
                incrementedStackedChanged={incrementedStackedChanged}
                metrics={editedMetrics ?? []}
                metricsChanged={updateEditedMetrics}
                queries={queries}
                initialRawData={previewData ?? initialRawData}
                wrapText={editedWrapText}
                setWrapText={setEditedWrapText}
                setEditMetricModalOpen={setEditMetricModalOpen}
                loadInitialData={isPreviewLoading || loadInitialData}
                // autoHeight={true}
                isDynamic={isDynamic}
                grid={editedGrid}
                setGrid={setEditedGrid}
                gridColumns={editedGridColumns}
                setGridColumns={setEditedGridColumns}
                twoColumnMobile={editedTwoColumnMobile}
                setTwoColumnMobile={setEditedTwoColumnMobile}
                tileMode={editedTileMode}
                setTileMode={setEditedTileMode}
                skinny={editedSkinny}
                setSkinny={setEditedSkinny}
                yAxisDomain={editedYAxisDomain}
                setYAxisDomain={setEditedYAxisDomain}
                allowDataOverflow={editedAllowDataOverflow}
                setAllowDataOverflow={setEditedAllowDataOverflow}
                dimension={editedDimension}
                setDimension={setEditedDimension}
                CDPSegmentId={CDPSegmentId}
                globalConditionalFormattingColor={editedGlobalConditionalFormattingColor ?? ''}
                hasGlobalConditionalFormatting={editedHasGlobalConditionalFormatting ?? false}
                setHasGlobalConditionalFormatting={setEditedHasGlobalConditionalFormatting}
                setGlobalConditionalFormattingColor={setEditedGlobalConditionalFormattingColor}
                breakdownMode={editedBreakdownMode}
                breakdownModeChanged={breakdownModeChanged}
                queriesChanged={queriesChanged}
                inWidgetEditor
                initialShowPreviousPeriod={editedShowPreviousPeriod}
                initialPreviousPeriodRawData={initialPreviousPeriodRawData}
                loadInitialPreviousPeriodData={loadInitialPreviousPeriodData}
                dialect={dialect}
                mode={mode}
                builderSetup={editedBuilderSetup}
                builderSetupChanged={async (v) => setEditedBuilderSetup(v)}
                filtersOpen={filtersOpen}
                setFiltersOpen={setFiltersOpen}
                paginationType="server"
                chartLabel={editedChartLabel}
                setChartLabel={setEditedChartLabel}
                rightYAxisLabel={editedRightYAxisLabel}
                setRightYAxisLabel={setEditedRightYAxisLabel}
                leftYAxisLabel={editedLeftYAxisLabel}
                setLeftYAxisLabel={setEditedLeftYAxisLabel}
                xAxisLabel={editedXAxisLabel}
                setXAxisLabel={setEditedXAxisLabel}
                isSyncCharts={isSyncCharts}
                chartLayout={editedChartLayout}
                setChartLayout={setEditedChartLayout}
              />
            )}
          </Grow>
        )}
      </Flex>
    </Modal>
  );
};
