import './priceDisplay.scss';

import React from 'react';
import { BillingInterval } from '@tw/types/module/services/subscription-manager';
import formatPrice from 'utils/formatPrice';
import content from 'routes/auth/content';
import { isDefined } from '../../utils/isDefined';
import { Flex, Text } from '@tw/ui-components';

export const calculatePriceWithCoupon = (
  coupon?: any,
  price?: number | null,
  product_id?: string,
): number => {
  if (!coupon && isDefined(price)) return price;
  if (!coupon || !isDefined(price)) return 0;

  const applies_to: string[] | null = coupon.applies_to?.products ?? coupon?.applies_to;

  if (!isDefined(applies_to) || applies_to?.some((prodId) => prodId === product_id)) {
    if (isDefined(coupon.percent_off)) return price * ((100 - coupon.percent_off) / 100);
    if (isDefined(coupon.amount_off)) return price - coupon.amount_off;
  }

  return price;
};

type AppProps = {
  price: number;
  insteadOf: number;
  selected: boolean;
  earlyBird?: boolean;
  billingInterval?: BillingInterval | string;
  intervalCount: number;
};

export const PriceDisplay: React.FC<AppProps> = ({
  price,
  insteadOf,
  selected,
  earlyBird,
  billingInterval = 'month',
  intervalCount = 1,
}) => {
  return (
    <Flex align="center" gap="md">
      {+price === 0.0 || +price === 0 ? (
        <Text color="gray.9" weight={700} size="lg">
          Free
        </Text>
      ) : (
        <Flex align="center" gap="xs">
          <Text color="gray.9" weight={700} size="lg">
            ${formatPrice(price)}
          </Text>
          {billingInterval !== 'one_time' && (
            <Text color="gray.4" weight={500} size="sm">
              {`/${intervalCount > 1 ? intervalCount : ''} ${billingInterval}`}
            </Text>
          )}
        </Flex>
      )}
      {price !== insteadOf && (
        <Flex align="center">
          <Text color="gray.4" weight={700} size="lg" td="line-through">
            ${formatPrice(insteadOf)}
          </Text>
          {billingInterval !== 'one_time' && (
            <Text color="gray.4" weight={500} size="sm" td="line-through">
              {`/${intervalCount > 1 ? intervalCount : ''} ${billingInterval}`}
            </Text>
          )}
        </Flex>
      )}
      {earlyBird && <Text size="sm">🐣 Early Bird Pricing!</Text>}
    </Flex>
  );
};
export default PriceDisplay;
