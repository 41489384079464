export const WILLY_NAV_ITEMS: {
  id: string;
  label: string;
  link: string;
  withHeader: boolean;
  isWorkflowPage?: boolean;
  hideFromSidePanel?: boolean;
}[] = [
  {
    id: 'chat',
    label: 'Chat',
    link: '/chat/shop',
    withHeader: false,
  },
  {
    id: 'history',
    label: 'Chat History',
    link: '/chat/history',
    withHeader: false,
  },
  {
    id: 'prompt_library',
    label: 'Template Library',
    link: '/chat/templates',
    withHeader: false,
  },
  {
    id: 'agents',
    label: "Shop's Agents",
    link: '/workflows',
    withHeader: true,
    isWorkflowPage: true,
  },
  {
    id: 'chat_with_sequence_run',
    label: 'Chat With Sequence Run',
    link: '/chat/sequenceRun',
    withHeader: false,
    hideFromSidePanel: true,
  },
];
