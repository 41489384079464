import { AFFLUENCER } from 'constants/types';
import { $currentShopId, $activeAccounts } from '$stores/$shop';
import moment from '@tw/moment-cached/module/timezone';
import axiosInstance from 'utils/axiosInstance';

export const getOrdersByUtmId = async (
  dateModel,
  attributionModel,
  source,
  type,
  id,
  unmatchedIds,
  start,
  end,
  campaigns,
  name,
  attributionName,
  attributionWindow,
  useNewModels,
  subscriptionTags,
  integrationId,
  campaignIds,
  adsetId,
  esKey = '',
  stat = 'order',
) => {
  const isOther = !id;
  let attributionKeyNotIn, attributionValuesNotIn;
  if (isOther) {
    if (name !== 'Other') {
      type = 'attributedFrom';
      id = attributionName;
    } else {
      console.log('other');
      attributionKeyNotIn = type;
      type = 'source';
      id = source;
      attributionValuesNotIn = campaigns.map((x) => x.campaign_id);
      attributionValuesNotIn.push('fake_enquire', 'fake_kno', 'fake_triplesurvey');
    }
  }

  const pixelVersion = parseInt(new URLSearchParams(location.search).get('pixelVersion') || '');
  const attributionFilters: any = [];

  const handleSingleValue = (value) => {
    // `(not-set)` should be simple string
    return value?.length === 1 ? value[0] : value;
  };

  // push current entity filter
  let entityId;
  if (source === AFFLUENCER) {
    entityId = name || id;
  } else {
    entityId = type === 'campaignId' && esKey ? esKey : id;
  }
  const filterValue = [...(entityId ? [entityId] : []), ...(unmatchedIds || [])];
  attributionFilters.push({ key: type, value: handleSingleValue(filterValue) });

  //  push parent entities filter
  if (campaignIds.length && type !== 'campaignId') {
    attributionFilters.push({ key: 'campaignId', value: handleSingleValue(campaignIds) });
  }
  if (adsetId.length && type !== 'adsetId') {
    attributionFilters.push({ key: 'adsetId', value: handleSingleValue(adsetId) });
  }
  const params: any = {
    shopDomain: $currentShopId.get(),
    additionalShopIds: $activeAccounts.get() || [],
    model: attributionModel,
    attributionFilters,
    unmatchedIds,
    integrationId,
    subscriptionTags,
    startDate: start,
    endDate: end,
    isClickDate: dateModel === 'clickDate',
    source: source === 'tw_referrer' ? 'organic_and_social' : source,
    attributionKeyNotIn,
    attributionValuesNotIn,
    attributionWindow,
    useNewModels,
    ...(pixelVersion && {
      eventVersion: pixelVersion,
    }),
    stat,
  };

  let response;
  params.name = name || '';
  const isToday = moment(start).isSame(moment(), 'day');
  ({ data: response } = await axiosInstance.post(`v2/attribution/get-attributed-orders`, params));

  const orders = response.orders
    .sort((a, b) => ((a.created_at || a.eventDate) < (b.created_at || b.eventDate) ? 1 : -1))
    .map((x) => ({
      ...x,
      first_name: x.first_name || x.firstName,
      last_name: x.last_name || x.lastName,
      order_name: x.order_name || x.orderName,
      total_price: x.total_price || x.totalPrice,
      created_at: x.created_at || x.eventDate,
      customer_id: x.customer_id || x.customerId,
    }));
  return orders;
};

export const reducers = {};
