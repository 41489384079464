import { useCallback, useEffect, useRef } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppProvider, Frame, Toast } from '@shopify/polaris';
import '@shopify/polaris/build/esm/styles.css';
import enTranslations from '@shopify/polaris/locales/en.json';
import './App.scss';
import './DarkMode.scss';
import 'allotment/dist/style.css';
import 'react-toastify/dist/ReactToastify.css';
import './initializers';
import './index.css';
import NavigationLink from './components/NavigationLink';
import { loginSuccess, signInUpWithGoogle } from './ducks/auth';
import MainRouter from './Router/Router';
import Amplitude from './components/Amplitude';
import { useAppDispatch } from 'index';
import { checkingForImporting, finishedCheckForImporting } from 'ducks/tiles';
import axiosInstance from 'utils/axiosInstance';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import {
  goOffline,
  goOnline,
  importingNewInfraDetected,
  serviceJustFinishedImporting,
  summaryRefreshOnPress,
} from 'ducks/actions';
import { $socket } from '$stores/$socket';
import { isServiceId } from 'utils/isServiceId';
import moment from '@tw/moment-cached/module/timezone';
import { type Granularity, type MetricsFilterExpression, RealtimeEvent } from '@tw/types';
import { DataTypesRoles, services } from '@tw/types/module/services';
import { fetchAdsMetrics, getNewStatsForAllServices, serviceHasOwnMetrics } from 'ducks/newStats';
import { ErrorBoundary } from 'pages/ErrorPages/ErrorBoundary';
import { getShopData } from 'utils/DB';
import { HIDE_OFFLINE_BANNER } from 'ducks/constants';
import { LicenseManager } from 'ag-grid-enterprise';
import { shopIntegrations } from 'ducks/shopIntegrations';
import { useRealTimeRegister } from './hooks/useRealTimeRegister';
import { sensoryRealtimeUpdate } from './ducks/sensory';
import { WorkflowIntegrationStatus } from '@tw/types/module/sensory';
import { showCountdownToast } from './components/CountdownToastToRefresh/CountdownToastToRefresh';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { $currency, $currentShopId } from '$stores/$shop';
import { TWToastContainer } from 'TWToastContainer';
import { useStoreValue } from '@tw/snipestate';
import { Integrations } from './routes/integrations/Integrations';
import { OrcaApp } from './orcabase/OrcaApp';

LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-050247 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Triple Whale Inc. )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Triple Whale )_only_for_( 3 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( Triple Whale )_need_to_be_licensed___( Triple Whale )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 5 November 2024 )____[v2]_MTczMDc2NDgwMDAwMA==946ff039a7fbd814497a9e7d081ff555',
);

function useIsInIntegations() {
  return window.location.pathname.startsWith('/orcabase');
}

const App = () => {
  const isInIntegrations = useIsInIntegations();
  const dispatch = useAppDispatch();
  const socket = useStoreValue($socket);
  useRealTimeRegister();
  // useInactivityTimeoutRefresh();
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const user = useSelector((state: RootState) => state.user);
  const importingServices = useSelector((state: RootState) => state.importingServices);
  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );
  const shopTimezone = useSelector((state: RootState) => state.shopTimezone);
  const { segments } = useSelector((state: RootState) => state.adSegmentations);
  const currency = useStoreValue($currency);
  const integrations = useSelector(shopIntegrations);
  const groupStatsBy = useSelector((state: RootState) => state.groupStatsBy);
  const showOfflineBanner = useSelector((state: RootState) => state.showOfflineBanner);

  let lastFunc = useRef<any>(null);
  let lastRan = useRef<any>(null);

  const throttle = useCallback(
    (func, limit = 10000) => {
      return function (...args) {
        // @ts-ignore
        const context = this;
        if (!lastRan.current) {
          dispatch(func.apply(context, args));
          lastRan.current = Date.now();
        } else {
          clearTimeout(lastFunc.current);
          lastFunc.current = setTimeout(
            function () {
              if (Date.now() - lastRan.current >= limit) {
                dispatch(func.apply(context, args));
                lastRan.current = Date.now();
              }
            },
            limit - (Date.now() - lastRan.current),
          );
        }
      };
    },
    [dispatch, lastFunc, lastRan],
  );

  const workflowImportingIndicator = useCallback(
    (message: RealtimeEvent<WorkflowIntegrationStatus>) => {
      if (message?.eventType !== 'workflow_update') {
        return;
      }
      dispatch(sensoryRealtimeUpdate(message));
    },
    [dispatch],
  );

  const updateDate = useCallback(() => {
    const now = moment();
    const hour = now.hour();

    if (hour === 0) {
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(updateDate, 60000 * 60);
    return () => clearInterval(interval);
  }, [updateDate]);

  const updateImportingIndicator = useCallback(
    async (message) => {
      if (message?.eventType !== 'job_manager_update') {
        return;
      }
      try {
        dispatch(checkingForImporting());
        let _services;

        const url = `/v2/job-manager/jobs?jobType=initial&status=in_progress&shopDomain=${currentShopId}`;
        let integrationUrl = `/v2/job-manager/integration/jobs?jobType=initial&status=in_progress&accountId=${currentShopId}`;
        const { data } = await axiosInstance.get(url);
        _services = data;

        try {
          let integrationAccountIds: { serviceId: string; accountId: { $in: string[] } }[] = [];
          let queryNewIntegration = !!currentShopId;

          if (currentShopId) {
            const shop = await getShopData();
            integrationAccountIds = ['bing', 'twitter-ads'].map((service) => {
              const accountIds = services[service]
                .getAccounts(shop)
                ?.map((account) => String(account.id));
              if (accountIds?.length) {
                queryNewIntegration = true;
              }
              return {
                serviceId: service,
                accountId: { $in: accountIds },
              };
            });
          }
          try {
            if (queryNewIntegration) {
              const { data } = await axiosInstance.get(
                `${integrationUrl}${
                  currentShopId ? '&query=' + JSON.stringify(integrationAccountIds) : ''
                }`,
              );
              _services = _services.concat(data);
            }
          } catch (e) {
            console.error('error get integration importing services', e);
          }
        } catch (e) {
          console.error('error get integration importing services', e);
        }
        if (!_services || !Array.isArray(_services)) {
          return;
        }

        const __services = {};
        _services.forEach((job) => {
          __services[job.serviceId] = job;
        });

        Object.keys(importingServices).forEach((key) => {
          if (!__services[key]) {
            dispatch(serviceJustFinishedImporting(key));
          }
        });
        dispatch(importingNewInfraDetected(__services));
        dispatch(finishedCheckForImporting());
      } catch (e) {
        console.error('error get importing services', e);
      }
    },
    [currentShopId, dispatch, importingServices],
  );

  const clientAction = useCallback(
    async (message) => {
      if (message?.eventType !== 'client-actions') {
        return;
      }
      const { scope, data } = message;

      if (scope === 'force-refresh') {
        const { date, shopId, email } = data;
        const dateFromRealTime = new Date(date);
        const lastReloadISODate = new Date(window.lastReloadISODate);
        if (
          dateFromRealTime >= lastReloadISODate &&
          (!shopId ? true : shopId === currentShopId) &&
          (!email ? true : email === user.email)
        ) {
          showCountdownToast(30);
        }
      }
    },
    [currentShopId, user.email],
  );

  const updateStats = useCallback(
    async (message) => {
      if (message?.eventType !== 'metrics_table_update') {
        return;
      }
      try {
        const { data, scope } = message;
        if (!data || !scope) return;
        if (!isServiceId(scope)) return;

        const { dates } = data;
        if (!dates || !Object.keys(dates)?.length) return;

        if (!mainDatePickerSelectionRange || !shopTimezone) return;

        moment.tz.setDefault(shopTimezone);
        const { start, end } = mainDatePickerSelectionRange;
        if (!start || !end) return;

        const [date] = dates;
        if (!date) return;

        if (!moment(date).isBetween(moment(start), moment(end), null, '[]')) {
          return;
        }

        var isOneDay = moment(start).diff(end, 'days') === 0;
        let granularity: Granularity = 'day';
        if (isOneDay) {
          granularity = 'hour';
        }

        const channelSegments = segments?.[scope];
        const filters = Object.values<any>(channelSegments || {}).filter((seg: any) => seg.enabled);

        const params = {
          currency,
          start: moment(start).format('YYYY-MM-DD'),
          end: moment(end).format('YYYY-MM-DD'),
          granularity,
          service_id: scope,
          shopId: $currentShopId.get(),
          data_type: DataTypesRoles['ads-metrics'],
          filters: filters.map((x) =>
            JSON.stringify(x.expressionList),
          ) as unknown as MetricsFilterExpression[][],
          account_ids: integrations[scope]?.map((x) => x.id),
        };

        const statsUrl = serviceHasOwnMetrics(scope)
          ? `v2/${scope}/metrics`
          : 'v2/metrics-table/get-metrics';
        const method = serviceHasOwnMetrics(scope) ? 'get' : 'post';
        throttle(fetchAdsMetrics, 20000)(params, statsUrl, method, scope, groupStatsBy);
      } catch (e) {
        console.error('error get importing services', e);
      }
    },
    [
      throttle,
      mainDatePickerSelectionRange,
      shopTimezone,
      segments,
      currency,
      integrations,
      groupStatsBy,
    ],
  );

  const updateShopifyStats = useCallback(
    async (message) => {
      if (message?.eventType !== 'shopify_update') {
        return;
      }
      try {
        let updateStart = message?.data?.start;
        if (!updateStart) return;
        updateStart = moment(updateStart).tz(shopTimezone);
        if (!mainDatePickerSelectionRange || !shopTimezone) return;
        const { start, end } = mainDatePickerSelectionRange;
        if (!start || !end) return;
        if (!updateStart.isBetween(moment(start), moment(end), 'day', '[]')) return;
        throttle(getNewStatsForAllServices)(true, ['shopify']);
      } catch {}
    },
    [throttle, mainDatePickerSelectionRange, shopTimezone],
  );

  useEffect(() => {
    if (!socket) return;

    const a = socket.on('message', updateStats);
    socket.on('message', updateShopifyStats);

    return () => {
      a.off('message', updateStats);
      socket.off('message', updateShopifyStats);
    };
  }, [updateStats, updateShopifyStats, socket]);

  useEffect(() => {
    if (!socket) return;
    const a = socket.on('message', updateImportingIndicator);
    return () => {
      a.off('message', updateImportingIndicator);
    };
  }, [updateImportingIndicator, socket]);

  useEffect(() => {
    if (!socket) return;
    const a = socket.on('message', clientAction);
    return () => {
      a.off('message', clientAction);
    };
  }, [clientAction, socket]);

  useEffect(() => {
    if (!socket) return;
    const a = socket.on('message', workflowImportingIndicator);
    return () => {
      a.off('message', workflowImportingIndicator);
    };
  }, [workflowImportingIndicator, socket]);

  const afterSignIn = useCallback(
    async (accessToken) => {
      await signInUpWithGoogle(accessToken, true);
      dispatch(loginSuccess());
    },
    [dispatch],
  );

  useEffect(() => {
    if (window.ReactNativeWebView) {
      const myFunc = async (event: MessageEvent) => {
        try {
          const parsed = JSON.parse(event.data);
          if (parsed.type === 'appState' && parsed.state === 'active') {
            summaryRefreshOnPress();
          }
          if (parsed.type === 'googleLoginSuccess') {
            afterSignIn(parsed.googleAccessToken);
          }
        } catch {}
      };
      window.addEventListener('message', myFunc);
      return () => {
        window.removeEventListener('message', myFunc);
      };
    }
  }, [afterSignIn]);

  useEffect(() => {
    const onlineHandler = () => {
      dispatch(goOnline());
    };

    const offlineHandler = () => {
      dispatch(goOffline());
    };

    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);

    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    };
  }, [dispatch]);

  return (
    <AppProvider i18n={enTranslations} linkComponent={NavigationLink}>
      <ErrorBoundary>
        <BrowserRouter>
          {!isInIntegrations ? <MainRouter /> : <OrcaApp />}
          <Amplitude />
          <TWToastContainer />
          {showOfflineBanner ? <OfflineIndicator /> : null}
        </BrowserRouter>
      </ErrorBoundary>
    </AppProvider>
  );
};

export default App;

const OfflineIndicator = () => {
  const dispatch = useAppDispatch();
  return (
    <Frame>
      <Toast
        duration={100000}
        content="You are offline. Please check your internet connection and try again."
        onDismiss={() => {
          dispatch({
            type: HIDE_OFFLINE_BANNER,
          });
        }}
      />
    </Frame>
  );
};

// window.addEventListener('beforeunload', function (event) {
//   // Show confirmation dialog
//   event.preventDefault(); // Required for Chrome
//   event.returnValue = ''; // Required for most browsers
//   return 'asdasdasd'; // For older browsers
// });
