import { TextInput, Icon, ActionIcon } from '@tw/ui-components';

type WillySearchInputProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
  autoFocus?: boolean;
  clearable?: boolean;
};

export const WillySearchInput: React.FC<WillySearchInputProps> = ({
  value,
  onChange,
  placeholder,
  className,
  autoFocus,
  clearable,
}) => {
  return (
    <div
      className={`bg-white overflow-hidden sticky top-0 z-10  dark:tw-nice-dark ${className} p-6.5`}
    >
      <TextInput
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        leadingIcon={<Icon name="search-major" size={14} />}
        trailingIcon={
          clearable && value?.length > 0 ? (
            <ActionIcon icon="close" iconSize={12} onClick={() => onChange('')} />
          ) : undefined
        }
      />
    </div>
  );
};
