// components
import { Page } from '@shopify/polaris';
import UpgradeBar from 'components/library/LockedFeatures/UpgradeBar';
// utils
import { useIntegrationsConfig } from './hooks/useIntegrationsConfig';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { Text, TextInput } from '@tw/ui-components';
import React, { useMemo, useState } from 'react';
import { ServicesIds } from '@tw/types/module/services';
import { integrationCategories } from './integrationCategories';
import { WillySelect } from 'components/Willy/dashboardManagment/WillySelect';
import { ShopProviderStatusEnum } from '@tw/types/module/types/ShopProviders';
import { SearchMinor } from '@shopify/polaris-icons';
import { Icon as PolarisIcon } from '@shopify/polaris';
import { IntegrationWidget } from './components/IntegrationWidget';
import { providerDomainEnum } from '@tw/types/module/sensory/integration/generalTypes';

// Updated Integrations component
export const Integrations: React.FC<{
  appName?: string;
  allowProviders?: ServicesIds[];
  isOrca?: boolean;
}> = ({ appName = 'Triple Whale', allowProviders, isOrca = false }) => {
  const [selectedDomain, setSelectedDomain] = useState('');
  const [selectedConnectionStatus, setSelectedConnectionStatus] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const integrationsWidgets = useIntegrationsConfig();

  // Add a duplicate widget with overridden domain for "Yotpo"
  const extendedWidgets = useMemo(() => {
    const additionalWidgets = integrationsWidgets
      .filter((widget) => widget.name === 'Yotpo')
      .map((widget) => ({
        ...widget,
        domain: providerDomainEnum.reviews,
      }));

    return [...integrationsWidgets, ...additionalWidgets];
  }, [integrationsWidgets]);

  const domainFilteredWidgets = extendedWidgets.filter((widget) =>
    selectedDomain ? widget.domain === selectedDomain : true,
  );

  const connectionFilteredWidgets = domainFilteredWidgets.filter((widget) =>
    selectedConnectionStatus === 'connected'
      ? !!widget.providerState?.status &&
        widget.providerState.status !== ShopProviderStatusEnum.disconnected
      : selectedConnectionStatus === 'not_connected'
        ? widget.providerState?.status === ShopProviderStatusEnum.disconnected
        : true,
  );

  const filteredWidgets = connectionFilteredWidgets.filter((widget) =>
    searchTerm ? widget.name.toLowerCase().includes(searchTerm.toLowerCase()) : true,
  );

  const domainFilterOptions = [
    { label: 'All Categories', value: '' },
    ...Object.entries(integrationCategories)
      .filter(([domain]) => extendedWidgets.some((widget) => widget.domain === domain))
      .map(([key, category]) => ({
        label: category.title,
        value: key,
      })),
  ];

  const connectionFilterOptions = [
    { label: 'All Integrations', value: '' },
    { label: 'Connected', value: 'connected' },
    { label: 'Not Connected', value: 'not_connected' },
  ];

  const groupedWidgets = Object.entries(integrationCategories).map(([domain, category]) => {
    const widgets = filteredWidgets.filter(
      (widget) =>
        widget.domain === domain &&
        (!allowProviders ? true : allowProviders.includes(widget.serviceId)),
    );
    return {
      domain,
      title: category.title,
      description: category.description,
      widgets,
    };
  });
  const otherWidgets = filteredWidgets.filter(
    (widget) =>
      widget.domain === undefined ||
      !Object.keys(integrationCategories).includes(widget.domain as string),
  );

  if (otherWidgets.length > 0) {
    groupedWidgets.push({
      domain: 'other',
      title: integrationCategories.other.title,
      description: integrationCategories.other.description,
      widgets: otherWidgets,
    });
  }

  const SearchInput = useMemo(
    () => (
      <div className="dark:tw-nice-dark w-full">
        <TextInput
          placeholder="Search integrations"
          value={searchTerm}
          onChange={(v) => setSearchTerm(v)}
          leadingIcon={<PolarisIcon source={SearchMinor} color="base" />}
        />
      </div>
    ),
    [searchTerm],
  );

  return (
    <>
      {!isOrca && <UpgradeBar feature={FeatureFlag.LIMIT_LOOKBACK_FF} />}
      <div className="px-4 sm:px-8 md:px-16 lg:px-32 xl:px-[190px]">
        <div className="mx-auto max-w-[1000px]">
          {/* Header Section */}
          <div
            className="mt-[50px] w-full rounded-[12px] bg-[var(--mantine-color-named-2)] shadow-md px-8 py-5"
            style={{
              boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)',
            }}
          >
            <div className="h-fit w-full flex justify-between items-center flex-col md:flex-row overflow-hidden">
              <div className="copy flex flex-col justify-between max-w-3xl h-full gap-6 text-center md:text-left">
                <div className="flex gap-4 align-bottom">
                  <h1 className="dark:text-white text-[var(--mantine-color-gray-6)] text-6xl font-extrabold leading-8">
                    {appName} Integrations
                  </h1>
                  {isOrca && (
                    <span className="font-bold dark:text-white">(Powered by Triple Whale)</span>
                  )}
                </div>
                {!isOrca && (
                  <Text size="sm" fw="400" lh="20px" color="gray.6" mb="lg" maw="400px" mt="md">
                    Easily connect external apps and platforms with your Triple Whale account to add
                    more data to your dashboard.
                  </Text>
                )}
              </div>
              <img
                className="hidden md:block max-w-[300px] h-auto"
                src="/integration/header.svg"
                alt=""
              />
            </div>
          </div>

          {/* Filters and Search Bar Section */}
          <div className="w-full flex justify-between items-center mt-16 space-x-4">
            <div className="flex space-x-4">
              <WillySelect
                data={domainFilterOptions}
                targetClassName="justify-start min-w-0 flex items-center"
                value={selectedDomain}
                onChange={(val) => setSelectedDomain(val as string)}
                withBorder={true}
                dropdownMinWidth="200px"
              />
              <WillySelect
                data={connectionFilterOptions}
                targetClassName="justify-start min-w-0 flex items-center"
                value={selectedConnectionStatus}
                onChange={(val) => setSelectedConnectionStatus(val as string)}
                withBorder={true}
                dropdownMinWidth="200px"
              />
            </div>
            <div className="flex-grow">{SearchInput}</div>
          </div>

          {/* Main Content */}
          <div className="w-full flex flex-col items-center mt-8">
            {groupedWidgets.map(
              (group) =>
                group.widgets.length > 0 && (
                  <div key={group.domain} className="w-full mb-20">
                    <Text size="lg" fw="600" lh="24px" color="gray.8" mb="sm">
                      {group.title}
                    </Text>
                    {group.description && (
                      <Text size="sm" fw="400" lh="20px" color="gray.6" mb="lg">
                        {group.description}
                      </Text>
                    )}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      {group.widgets.map((widget) => (
                        <IntegrationWidget key={widget.id} {...widget} />
                      ))}
                    </div>
                  </div>
                ),
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Integrations;
