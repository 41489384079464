import { $derived, $mutableDerived, $store, useStoreValue } from '@tw/snipestate';
import {
  $isPackagePlanPreview,
  $planPreviewPackageDayLeft,
  $currentPackagePlanPreviewPlanName,
  $isUnlimitedAccessToChat,
  $isUserHasMobyPlanPreviewBefore,
  $isUserHasMobyPlanPreview,
  $isPackagePlanPreviewBefore,
  $planPreviewMobyDayLeft,
  $isShopHasPremiumPlusBefore,
  $mobyUpgradeSplitTest,
  $premiumPlusDayLeft,
  $packagePlanPreviewBeforeName,
  $plans,
  $shopSubscription,
  MOBY_FREE_PRICE_ID_FOR_PLAN_PREVIEW,
} from './$subscription';
import { $activeAppVersion, $activeRoute } from '$stores/nav-config-stores';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { $ffStore } from '../../feature-flag-system';
import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { userDb } from 'utils/DB';
import { $user } from '$stores/$user';
import { analyticsEvents, genericEventLogger, upgradeCtaActions } from 'utils/dataLayer';
import { $currentShopId } from '$stores/$shop';

export const $shouldShowMobyUpgradeCtas = $derived((get) => {
  const fullAccess = get($isUnlimitedAccessToChat);
  const isUserHasMobyPlanPreview = get($isUserHasMobyPlanPreview);
  return !fullAccess && !isUserHasMobyPlanPreview;
});

//MOBY POPUP
export const $showMobyPopup = $derived((get) => {
  const shouldShow = get($shouldShowMobyUpgradeCtas);
  const popupClosedUser = get($user)?.shops?.[$currentShopId.get() ?? '']?.mobyPopupClosed;
  return shouldShow && !popupClosedUser;
});

export const $unlockMobyModal = $store<{ opened: boolean; initial: boolean }>({
  opened: false,
  initial: false,
});

export const openUnlockMobyModal = (initial = false) => {
  $unlockMobyModal.set({ opened: true, initial: initial });

  if (initial) {
    genericEventLogger(analyticsEvents.CHAT, {
      action: upgradeCtaActions.VIEWED_INITIAL_FREE_TRIAL_POPUP,
    });
  }
};

export const closeUnlockMobyModal = async () => {
  const isInitialPopup = $unlockMobyModal.get().initial;
  if (isInitialPopup) {
    const currentShopId = $currentShopId.get();
    if (currentShopId) {
      await userDb().set(
        { shops: { [currentShopId]: { mobyPopupClosed: true } } },
        { merge: true },
      );
    }
    genericEventLogger(analyticsEvents.CHAT, {
      action: upgradeCtaActions.CLOSE_INITIAL_FREE_TRIAL_POPUP,
    });
  }
  $unlockMobyModal.set({ opened: false, initial: false });
};

//FREE TRIAL BANNER
export const $trialBannerPackage = $mutableDerived((get) => {
  const isPackagePlanPreview = get($isPackagePlanPreview);
  const isPackagePlanPreviewBefore = get($isPackagePlanPreviewBefore);
  const currentPlanName = get($currentPackagePlanPreviewPlanName);
  const expiredPlanName = get($packagePlanPreviewBeforeName);
  const planPreviewPackageDayLeft = get($planPreviewPackageDayLeft);
  const isCurrentPackageHighFromExpiredPlanPreview = get(
    $isCurrentPackageHighFromExpiredPlanPreview,
  );
  const bannerClosedUser = !!get($user)?.shops?.[$currentShopId.get() ?? '']?.trialBannerClosed;
  return {
    show:
      !bannerClosedUser &&
      !isCurrentPackageHighFromExpiredPlanPreview &&
      (isPackagePlanPreviewBefore || isPackagePlanPreview),
    daysLeft: isPackagePlanPreviewBefore ? 0 : planPreviewPackageDayLeft,
    currentPlanName: isPackagePlanPreviewBefore ? expiredPlanName : currentPlanName,
  };
});

export const $trialBannerMoby = $mutableDerived((get) => {
  const isUserHasMobyPlanPreviewBefore = get($isUserHasMobyPlanPreviewBefore);
  const isUserHasMobyPlanPreview = get($isUserHasMobyPlanPreview);
  const planPreviewMobyDayLeft = get($planPreviewMobyDayLeft);

  const bannerClosedUser = !!get($user)?.shops?.[$currentShopId.get() ?? '']?.trialBannerClosed;
  return {
    show: !bannerClosedUser && (isUserHasMobyPlanPreview || isUserHasMobyPlanPreviewBefore),
    daysLeft: isUserHasMobyPlanPreviewBefore ? 0 : planPreviewMobyDayLeft,
  };
});

export const $isCurrentPackageHighFromExpiredPlanPreview = $derived((get) => {
  const subscription = get($shopSubscription);
  const plans = get($plans);
  if (plans.loading) return true;

  const expired = subscription?.shop_items_history
    ?.filter((x) => x.price_id !== MOBY_FREE_PRICE_ID_FOR_PLAN_PREVIEW)
    .find((item) => item.plan_preview_end_date);

  const currentPackage = subscription?.items?.find((x) => x.product_type === 'package');

  if (!expired || !currentPackage) return false;

  const expiredRank = plans.data.find((x) => x.product_id === expired.product_id)?.product_sort;
  const currentRank = plans.data.find(
    (x) => x.product_id === currentPackage.product_id,
  )?.product_sort;
  if (expiredRank === undefined || currentRank === undefined) return false;

  return currentRank >= expiredRank;
});

export const closeTrialBanner = (saveInDB: boolean) => async () => {
  if (saveInDB) {
    const currentShopId = $currentShopId.get();
    if (currentShopId) {
      await userDb().set(
        { shops: { [currentShopId]: { trialBannerClosed: true } } },
        { merge: true },
      );
    }
  }
  $trialBannerMoby.set({
    show: false,
    daysLeft: 0,
  });
  $trialBannerPackage.set({
    show: false,
    daysLeft: 0,
    currentPlanName: '',
  });
};

export const $fromPremiumPlusToMobyModal = $mutableDerived((get) => {
  return {
    show: false,
  };
});

export const $showFromPremiumPlusToMobyModal = $derived((get) => {
  const fromPremiumPlusToMobyModalClosed =
    !!get($user)?.shops?.[$currentShopId.get() ?? '']?.fromPremiumPlusToMobyModalClosed;
  const isShopHasPremiumPlusBefore = get($isShopHasPremiumPlusBefore);
  const isUserHasMobyPlanPreview = get($isUserHasMobyPlanPreview);
  const premiumPlusDayLeft = get($premiumPlusDayLeft);
  const isUltimate = get($isUnlimitedAccessToChat);

  const shouldShowModal =
    (isShopHasPremiumPlusBefore || (premiumPlusDayLeft && premiumPlusDayLeft < 3)) &&
    !isUserHasMobyPlanPreview &&
    !isUltimate &&
    !fromPremiumPlusToMobyModalClosed;

  if (shouldShowModal) {
    genericEventLogger(analyticsEvents.GENERAL, {
      action: upgradeCtaActions.VIEW_PREMIUM_PLUS_TO_MOBY_UPGRADE_POPUP,
    });
  }
  return shouldShowModal;
});

export const closeFromPremiumPlusToMobyModal = async () => {
  const currentShopId = $currentShopId.get();
  if (currentShopId) {
    await userDb().set(
      { shops: { [currentShopId]: { fromPremiumPlusToMobyModalClosed: true } } },
      { merge: true },
    );
  }
  $fromPremiumPlusToMobyModal.set({
    show: false,
  });
};

export const FREE_TRIAL_BANNER_HEIGHT = 48;

export const FREE_TRIAL_BANNER_HEIGHT_VAR = '--free-trial-banner-height';

//FREE TRIAL POPUP

export const openFreeTrialPopup = (source, isUpgrade) => {
  $freeTrialOrUpgradeModal.set({ opened: true, source: source, upgradeOnly: isUpgrade });
  genericEventLogger(analyticsEvents.CHAT, {
    action: upgradeCtaActions.FREE_TRIAL_POPUP_VIEWED,
    trialDays: $mobyUpgradeSplitTest.get().dayTrial,
    source,
    isOnlyUpgrade: $mobyUpgradeSplitTest.get().isOnlyUpgrade,
    isFromSplitTest: $mobyUpgradeSplitTest.get().isFromSplitTest,
  });
};

export const closeFreeTrialPopup = async (log = true) => {
  const source = $freeTrialOrUpgradeModal.get().source;
  const isInitialPopup = source === 'initial';
  if (isInitialPopup) {
    const currentShopId = $currentShopId.get();
    if (currentShopId) {
      await userDb().set(
        { shops: { [currentShopId]: { freeTrialPopupClosed: true } } },
        { merge: true },
      );
    }
  }
  $freeTrialOrUpgradeModal.set({ opened: false, source: null, upgradeOnly: false });
};

export const $freeTrialOrUpgradeModal = $store<{
  opened: boolean;
  source: null | string;
  upgradeOnly: boolean;
}>({
  opened: false,
  source: null,
  upgradeOnly: false,
});

//BOOK DEMO MODAL

export const $bookDemoModal = $store<{ opened: boolean; source: 'pricing' | 'sonar' | '' }>({
  opened: false,
  source: '',
});

export const openBookDemoModal = (source: 'pricing' | 'sonar' | '') => {
  $bookDemoModal.set({ opened: true, source: source });
};

export const closeBookDemoModal = () => {
  $bookDemoModal.set({ opened: false, source: '' });
};

// // ASK QUESTION MODAL

export const $askQuestionModal = $store<{ opened: boolean }>({
  opened: false,
});

export const openAskQuestionModal = () => {
  $askQuestionModal.set({ opened: true });
};

export const closeAskQuestionModal = () => {
  $askQuestionModal.set({ opened: false });
};

////UNPAID MODAL
export const $unpaidModal = $store<{
  opened: boolean;
  source: 'unpaid' | 'incomplete' | 'past_due' | null;
}>({
  opened: false,
  source: null,
});

export const openUnpaidModal = (source: 'unpaid' | 'incomplete' | 'past_due' | null) => {
  $unpaidModal.set({ opened: true, source: source });
};

export const closeUnpaidModal = () => {
  $unpaidModal.set({ opened: false, source: null });
};

export type UpgradeEventSource =
  | 'moby'
  | 'chat'
  | 'dashboards'
  | 'template_library'
  | 'prompt_library'
  | (string & {});

export const logUgradeEvent = (source: UpgradeEventSource | null, action) => {
  let SOURCE;
  switch (source) {
    case 'moby':
      SOURCE = 'moby_popup';
      break;
    case 'chat':
      SOURCE = analyticsEvents.CHAT;
      break;
    case 'dashboards':
      SOURCE = analyticsEvents.DASHBOARDS;
      break;
    case 'template_library':
      SOURCE = analyticsEvents.TEMPLATE_LIBRARY;
      break;
    case 'prompt_library':
      SOURCE = analyticsEvents.PROMPT_LIBRARY;
      break;
    default:
      SOURCE = source;
  }
  genericEventLogger(SOURCE, {
    action: action,
  });
};
