import React from 'react';
const RakutenIconBase64 = ({ small }) => (
  <img
    style={{ height: small ? 15 : 20 }}
    alt="Rakuten icon"
    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODgiIGhlaWdodD0iOTMiIHZpZXdCb3g9IjAgMCA4OCA5MyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzIDkzTDAuNSA4Mi41SDg3LjhMMTMgOTNaIiBmaWxsPSIjODUyOUNDIi8+CjxwYXRoIGQ9Ik0yNy4xIDc0LjY5OTZWNTEuNTk5NkgzNy4xTDU0LjUgNzQuNjk5Nkg3Mi4yTDUxLjMgNDYuNzk5NkM1Ny44IDQyLjA5OTYgNjIuMSAzNC40OTk2IDYyLjEgMjUuNzk5NkM2Mi4xIDExLjU5OTYgNTAuNiAwLjA5OTYwOTQgMzYuNCAwLjA5OTYwOTRIMTNWNzQuNjk5NkgyNy4xWk0yNy4xIDE0LjI5OTZIMzYuNEM0Mi44IDE0LjI5OTYgNDggMTkuMzk5NiA0OCAyNS43OTk2QzQ4IDMyLjE5OTYgNDIuOCAzNy4zOTk2IDM2LjQgMzcuMzk5NkgyNy4xVjE0LjI5OTZaIiBmaWxsPSIjODUyOUNDIi8+Cjwvc3ZnPgo="
  />
);

export default RakutenIconBase64;
