import { createSelector } from 'reselect';
import { CustomMetricsType } from 'ducks/customMetrics';
import { selectCustomMetricsColumns } from 'utils/customMetrics';
import { selectConnectedServices } from 'utils/selectors';
import { $allPixelColumns } from 'components/attribution-new/columns';
import { $derived } from '@tw/snipestate';
import { $redux } from '$stores/$redux';

export const selectPixelColumnsForCustomMetrics = createSelector(
  [selectCustomMetricsColumns(CustomMetricsType.Attribution)],
  (columns) => columns,
);

const selectCustomAttributionMetricsColumns = selectCustomMetricsColumns(
  CustomMetricsType.Attribution,
);

const $pixelColumnsForCustomMetrics = $derived((get) => {
  const reduxState = get($redux);
  return !reduxState ? null : selectCustomAttributionMetricsColumns(reduxState);
});

const $connectedServices = $derived((get) => {
  const reduxState = get($redux);
  return !reduxState ? null : selectConnectedServices(reduxState);
});

export const $allMetrics = $derived((get) => {
  const connectedServices = get($connectedServices);
  const allPixelColumns = get($allPixelColumns);

  return allPixelColumns.filter((metric: any) => {
    if (!metric.dependOnActiveIntegrations) {
      return true;
    }
    return metric.dependOnActiveIntegrations.some(
      (service: string) => connectedServices?.[service],
    );
  });
});

export const $allPixelColumnsIncludingCustom = $derived((get) => {
  const pixelColumnsForCustomMetrics = get($pixelColumnsForCustomMetrics);
  return get($allMetrics).concat(pixelColumnsForCustomMetrics);
});
