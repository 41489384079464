import { ChatSources, WillyMetric } from './types/willyTypes';
import { ReactComponent as QuestionMark } from 'icons/question-mark.svg';
import { ColumnTooltip } from './WillyWidgetEditor/TableBuilder/ColumnTooltip';
import { useState } from 'react';
import { analyticsEvents, genericEventLogger, metricBuilderActions } from 'utils/dataLayer';

type WillyDescriptionTooltipProps = {
  metric: WillyMetric;
  context?: ChatSources;
};

export const WillyDescriptionTooltip: React.FC<WillyDescriptionTooltipProps> = ({
  metric,
  context,
}) => {
  const tip = metric?.description;
  const [tooltipOpened, setTooltipOpened] = useState(false);

  if (!tip) {
    return null;
  }

  return (
    <div onMouseEnter={() => setTooltipOpened(true)} onMouseLeave={() => setTooltipOpened(false)}>
      <ColumnTooltip
        title={metric.name}
        subTitle={metric.key}
        description={tip}
        tooltipOpened={tooltipOpened}
        offset={0}
        position="bottom"
        tracking={() => {
          genericEventLogger(analyticsEvents.METRIC_BUILDER, {
            action: metricBuilderActions.GO_TO_DATA_DICTIONARY,
            table_name: undefined,
            metric_name: metric.name,
            url: metric.readmeUrl,
            widget_type: 'tile',
            context: context,
          });
        }}
        withinPortal={true}
      >
        <div className="opacity-0 group-hover/tile:opacity-100 transition-opacity">
          <QuestionMark width={16} height={16} className="text-logo flex cursor-pointer" />
        </div>
      </ColumnTooltip>
    </div>
  );
};
