import { $dialect } from '$stores/$user';
import { MetricsFilterExpression } from '@tw/types';
import { ServicesIds } from '@tw/types/module/services';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { AttributionStatsRequest } from 'types/attribution';
import moment from '@tw/moment-cached/module/timezone';
import { shopIntegrations } from 'ducks/shopIntegrations';
import { useStoreValue } from '@tw/snipestate';
import { $activeAccounts, $currency, $shopCurrency } from '../$stores/$shop';

export const useAttributionParams = (providerId: string = 'recharge') => {
  const [params, setParams] = useState<Partial<AttributionStatsRequest>>();
  const mainDatePickerSelectionRange = useSelector(
    (state: RootState) => state.mainDatePickerSelectionRange,
  );
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const activeAccounts = useStoreValue($activeAccounts);
  const shopTimezone = useSelector((state: RootState) => state.shopTimezone);
  const showDirect = useSelector((state: RootState) => state.pixelShowDirect);
  const {
    dateModel,
    attributionModel,
    includeOneDayView,
    attributionWindow,
    useNewModels,
    attributionSubscription,
    ppsLookBackWindow,
    includeCustomAdSpend,
  } = useSelector((state: RootState) => state.attribution);
  const currency = useStoreValue($currency);
  const shopCurrency = useStoreValue($shopCurrency);
  const accountIds: any = useSelector(shopIntegrations);
  const segments = useSelector((state: RootState) => state.adSegmentations.segments);
  const sensoryIntegrations = useSelector((state: RootState) => state.sensory.sensoryIntegrations);
  const includeCustomSpend = useSelector(
    (state: RootState) =>
      state.attribution.selectedColumns.map((col) => col.key).includes('customSpend') ||
      includeCustomAdSpend,
  );
  const { id: integrationId } =
    sensoryIntegrations.filter((integration) => integration.provider_id === providerId)?.[0] ?? '';

  const dialect = useStoreValue($dialect);

  useEffect(() => {
    if (!mainDatePickerSelectionRange) {
      return;
    }

    if (!Object.keys(segments || {}).length) {
      return;
    }

    const { start, end } = mainDatePickerSelectionRange || {};
    const accounts = Object.entries(accountIds)
      .filter(([serviceId, accounts]) => (accounts as any[])?.length)
      .reduce(
        (acc, [service, accounts]) => ({
          ...acc,
          [service]: (accounts as any[])?.map((acc) => acc.id) || [],
        }),
        {},
      );

    const filters: Record<ServicesIds, MetricsFilterExpression[][]> = Object.entries(
      segments,
    ).reduce<any>(
      (acc, [serviceId, docs]) => ({
        ...acc,
        [serviceId]: Object.values(docs || {})
          .filter((seg) => seg.enabled)
          .map((x) => x.expressionList),
      }),
      {},
    );
    const params: Partial<AttributionStatsRequest> = {
      shopDomain: currentShopId,
      additionalShopIds: activeAccounts || undefined,
      model: attributionModel,
      dateModel,
      startDate: moment(start).format(),
      endDate: moment(end).format(),
      currency: currency || 'USD',
      shopCurrency,
      accountIds: accounts,
      timezone: shopTimezone,
      includeOneDayFacebookView: includeOneDayView,
      attributionWindow,
      subscriptionTags: attributionSubscription,
      integrationId,
      ppsViewsLookbackWindow: Number(ppsLookBackWindow) || undefined,
      filters,
      showDirect,
      useNewModels,
      includeCustomAdSpend,
      includeCustomSpend,
    };
    setParams(params);
  }, [
    attributionModel,
    activeAccounts,
    currency,
    currentShopId,
    dateModel,
    shopTimezone,
    accountIds,
    includeOneDayView,
    mainDatePickerSelectionRange,
    segments,
    shopCurrency,
    attributionWindow,
    attributionSubscription,
    ppsLookBackWindow,
    showDirect,
    useNewModels,
    integrationId,
    includeCustomAdSpend,
    includeCustomSpend,
    dialect,
  ]);

  return params;
};
