import Nestable from 'react-nestable';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ActionIcon, Icon, Menu, Text, useDisclosure, Tooltip, Skeleton } from '@tw/ui-components';
import { WillyDashOptionsList } from './WillyDashOptionsList';
import { $combinedDashboardsMap } from '$stores/willy/$combinedDashboards';
import { useNavigate, useParams } from 'react-router-dom';
import { windowSize } from 'utils/classes/WindowSizeObserver';
import { isMobileApp } from 'utils/Device';
import { toggleMobileSidePanel } from 'ducks/willy';
import { useAppDispatch } from 'index';
import { cx } from 'utils/cx';
import {
  $dashSidePanelOpenMainCats,
  $dashSearch,
  toggleSidePanelMainCats,
  $filteredCategories,
} from './stores';
import { isEqual } from 'lodash';
import { NavLink } from 'react-router-dom';
import { useDebouncer } from 'hooks/useDebouncer';
import { useComputedValue, useStoreValue } from '@tw/snipestate';
import { $reportSelectorDrawer } from '$stores/willy/$mobileDrawers';
import type { CategoryListItem } from './types';
import { deleteDashboardCategory, renameDashboardCategory, updateDashboardCategories } from './api';
import { formatChildren } from './utils';
import { isDefined } from 'utils/isDefined';

function getNestedItem(array: CategoryListItem[], indexes: number[]): CategoryListItem | null {
  const [first, ...newIndexes] = indexes;
  if (!isDefined(first)) return null;

  let listItem = array[first];
  return newIndexes.reduce((acc, i) => ((acc = acc.children[i]), acc), listItem);
}

/** Updates everything about dest except for isOpen */
function maintainOpenState(src: CategoryListItem[], dest: CategoryListItem[]) {
  const clonedDest = structuredClone(dest);

  function update(src: CategoryListItem[], dest: CategoryListItem[]) {
    const map = new Map(src.map((c) => [c.id, c]));

    for (const cat of dest) {
      const existing = map.get(cat.id);
      if (!existing) continue;

      const isOpen = existing.metadata?.isOpen;
      if (isDefined(isOpen)) cat.metadata = { ...(cat.metadata || {}), isOpen };
      update(existing.children, cat.children);
    }
  }

  update(src, clonedDest);

  return clonedDest;
}

export function WillyDashSidePanelCategories() {
  const debounce = useDebouncer(2000);
  const debounceFilter = useDebouncer(100);
  const [updatingDB, setUpdatingDB] = useState(false);
  const [nestableRef, setNestableRef] = useState<Nestable>();
  const draggedItem = useRef<CategoryListItem | null>(null);
  const isOpen = useComputedValue($dashSidePanelOpenMainCats, (c) => c.includes('custom'));
  const search = useStoreValue($dashSearch);
  const { filtered: filteredCategories, loading } = useStoreValue($filteredCategories);
  const [items, setItems] = useState<CategoryListItem[]>([]);

  const saveItems = useCallback(
    async (newItems: CategoryListItem[]) => {
      if (search.length) return;

      setUpdatingDB(true);
      setItems(newItems);

      debounce(async () => {
        await updateDashboardCategories(newItems)
          .catch((err) => {
            console.error('Error saving categories update:>>', err);
          })
          .finally(() => {
            setUpdatingDB(false);
          });
      });
    },
    [search, debounce],
  );

  useEffect(() => {
    if (updatingDB) return;
    debounce(() => {
      setItems((prev) => {
        const res = isEqual(filteredCategories, prev)
          ? prev
          : maintainOpenState(prev, filteredCategories);
        return res;
      });
    });
  }, [updatingDB, debounce, filteredCategories]);

  const collapsedIds = useMemo(() => {
    if (search.length) return 'NONE';
    return items.reduce((acc, x) => {
      if (!x.metadata?.isOpen) acc.push(x.id);
      return acc;
    }, [] as string[]);
  }, [search, items]);

  useEffect(() => {
    debounceFilter(() => nestableRef?.collapse(collapsedIds));
  }, [debounceFilter, nestableRef, collapsedIds]);

  const toggleOpenSingleItem = useCallback(
    async (itemId: string) => {
      const newItems = items.map((i) => {
        if (i.id !== itemId) return i;
        return {
          ...i,
          metadata: { ...i.metadata, isOpen: !i.metadata?.isOpen },
        };
      });

      setItems(newItems);
    },
    [items],
  );

  if (loading) {
    return (
      <div className="p-4 flex flex-col gap-4">
        {Array.from({ length: 8 }).map((_, i) => (
          <div key={`loading-${i}`}>
            <Skeleton width="100%" height="30px" />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="m-2 group/custom-cat relative">
      {/* {updatingDB && <LoadingOverlay radius="md" visible={updatingDB} />} */}
      <div
        className="flex items-center p-4 cursor-pointer justify-between"
        onClick={() => toggleSidePanelMainCats('custom')}
      >
        <span className="flex items-center gap-[6px]">
          <span className="opacity-0 group-hover/report-side-panel:opacity-100">
            <span className="flex  render-collapse-icon">
              <div style={{ transform: !isOpen ? 'rotate(-90deg)' : '' }}>
                <Icon name="arrow-down-3" size={9} color="gray.4" />
              </div>
            </span>
          </span>
          <Text fz="xs" fw="500" c="gray.5" tt="uppercase">
            Custom
          </Text>
        </span>
        <span className="opacity-0 group-hover/report-side-panel:opacity-100 flex items-center">
          <Tooltip
            label="Custom Dashboards can be fully modified and can be viewed by all shop members."
            lightTooltip
            position="right"
            multiline
          >
            <Icon name="info" size={14} color="gray.4" />
          </Tooltip>
        </span>
      </div>
      <style>{`
      .nestable {margin-left:13px;}
        .nestable .nestable-list { padding: 0px; }
        .nestable-list .nestable-item .render-item { padding-left: calc(20px * var(--i)); }
      `}</style>
      {isOpen && (
        <Nestable
          ref={(el) => el && setNestableRef(el)}
          maxDepth={3}
          threshold={20}
          items={items}
          renderItem={({ item, collapseIcon }) => (
            <RenderItem
              toggleOpenSingleItem={toggleOpenSingleItem}
              draggedItemRef={draggedItem}
              item={item as CategoryListItem}
              collapseIcon={collapseIcon}
            />
          )}
          disableDrag={({ item }) => {
            if (windowSize.isSmall) return false;

            // custom views are completely undraggable regardless of level
            if (item.metadata?.isCustomView || item.metadata?.isDefaultItem) return false;

            // don't allow drag and drop if filter isn't empty
            if (search.length) return false;

            return true;
          }}
          onDragStart={({ dragItem }) => {
            draggedItem.current = dragItem as CategoryListItem;
          }}
          onDragEnd={() => {
            setTimeout(() => (draggedItem.current = null), 100);
          }}
          confirmChange={({ dragItem: di, destinationParent: dp }) => {
            // categories can change order, reports can move out of categories
            if (!dp) return true;

            // a folder can never become a report
            if (di.type === 'folder') return false;

            // a report can never become a folder
            if (dp.type === 'item') return false;

            return true;
          }}
          onChange={async ({ items: newItems, targetPath }) => {
            const nestedItem = getNestedItem(newItems as CategoryListItem[], targetPath);
            if (!nestedItem) return; // doesn't exist

            // mutate depth of affected item in newItems array
            nestedItem.depth = targetPath.length - 1;

            // format children of parent item each time to deal with empty category case
            const parentItem = getNestedItem(
              newItems as CategoryListItem[],
              targetPath.slice(0, targetPath.length - 1),
            );

            // always update children. if we know parent, we can target parent directly.
            // otherwise, we need to fix the children individually
            if (parentItem) {
              const formattedChildren = formatChildren(parentItem);
              if (formattedChildren) parentItem.children = formattedChildren;
            } else {
              newItems = newItems.map((item) => {
                if (item.type === 'folder' && !item.children.length) {
                  const newChildren = formatChildren(item as CategoryListItem);
                  if (newChildren) item.children = newChildren;
                }
                return item;
              }, [] as CategoryListItem[]);
            }

            saveItems(newItems as CategoryListItem[]);
          }}
          onCollapseChange={async (options) => {
            // since "collapsed" above is set to true, options should contain an array of `openIds`
            if (!('closedIds' in options) || !options.closedIds) return;

            const closedIds = new Set(options.closedIds);

            for (const i of items) {
              i.metadata = { ...i.metadata, isOpen: !closedIds.has(i.id) };
            }

            setItems([...items]);
          }}
          renderCollapseIcon={({ isCollapsed }) => {
            return (
              <span className="inline-block relative pr-3 render-collapse-icon">
                <div
                  className="inline-block"
                  style={{ transform: isCollapsed ? 'rotate(-90deg)' : '' }}
                >
                  <Icon name="arrow-down-3" size={9} color="gray.4" />
                </div>
              </span>
            );
          }}
        />
      )}
    </div>
  );
}

type RenderItemProps = {
  item: CategoryListItem;
  collapseIcon: React.ReactNode;
  draggedItemRef: MutableRefObject<CategoryListItem | null>;
  toggleOpenSingleItem: (itemId: string) => Promise<void>;
};
function RenderItem({ item, collapseIcon, draggedItemRef, toggleOpenSingleItem }: RenderItemProps) {
  const { dashboardId } = useParams<{ dashboardId: string }>();
  const navigate = useNavigate();
  const debounce = useDebouncer(100);
  const dispatch = useAppDispatch();
  const [menuVisible, menuVisibleActions] = useDisclosure(false);
  const [menuOpen, menuOpenActions] = useDisclosure(false);
  const dashMap = useStoreValue($combinedDashboardsMap);
  const dashboard = useMemo(() => dashMap.get(item.id), [dashMap, item.id]);
  const link = useMemo(() => `/dashboards/${item.id}${window.location.search}`, [item.id]);

  const Wrapper = useMemo(
    () =>
      item.type === 'item'
        ? (props) => <NavLink {...props} to={link} />
        : (props) => <div {...props} />,
    [item.type, link],
  );

  const classes = useMemo(
    () =>
      cx(
        'no-underline text-[var(--mantine-color-gray-8)]',
        'render-item flex rounded-md items-center p-1 m-0',
        !item.metadata?.isDefaultItem && 'cursor-pointer hover:bg-[var(--gray-light-mode-300)]',
        dashboardId === item.id && 'bg-[var(--gray-light-mode-300)]',
      ),
    [dashboardId, item.id, item.metadata?.isDefaultItem],
  );

  const handleTouchEvent = (e: MouseEvent) => {
    if (e.target?.['tagName'] === 'use' || draggedItemRef.current) return;

    if (item.metadata?.isDefaultItem) {
      e.preventDefault();
      return;
    }

    if (item.children.length) {
      toggleOpenSingleItem(item.id);
      return;
    }

    if (windowSize.isSmall || isMobileApp) {
      $reportSelectorDrawer.set({ opened: false });
      navigate(link);
      debounce(() => dispatch(toggleMobileSidePanel(false)));
    }
  };

  return (
    <Wrapper
      style={{ ['--i' as any]: item.depth || 0 }}
      disabled={item.id === dashboardId}
      className={classes}
      onMouseOver={menuVisibleActions.open}
      onMouseLeave={menuVisibleActions.close}
      onClick={handleTouchEvent}
    >
      <span className="opacity-0 group-hover/report-side-panel:opacity-100 pl-4 sm:opacity-100">
        {collapseIcon}
      </span>

      {!dashboard ? (
        <Text
          fw={500}
          span
          fz="sm"
          truncate
          c={item.metadata?.isDefaultItem ? 'gray.5' : !item.depth ? 'gray.7' : 'gray.8'}
        >
          {item.value}
        </Text>
      ) : (
        <WillyDashOptionsList dashboard={dashboard} isFavorites={false} />
      )}

      {item.type === 'folder' && (
        <div
          style={{
            opacity: menuVisible ? 1 : 0,
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'flex-end',
          }}
        >
          <Menu opened={menuOpen} onClose={menuOpenActions.close} shadow="md">
            <Menu.Target>
              <span className="inline-flex items-center">
                <ActionIcon
                  icon="menu-vertical"
                  variant="transparent"
                  color="gray.5"
                  iconSize={25}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    menuOpenActions.toggle();
                  }}
                />
              </span>
            </Menu.Target>
            <Menu.Dropdown style={{ opacity: menuOpen ? 1 : 0 }}>
              <Menu.Item
                onClick={(e) => {
                  e.stopPropagation();
                  renameDashboardCategory(item.id);
                }}
                leftSection={<Icon name="edit" size={14} />}
              >
                Rename Folder
              </Menu.Item>
              <Menu.Item
                color="red.6"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteDashboardCategory(item.id);
                }}
                leftSection={<Icon name="delete" color="red.6" size={20} />}
              >
                Delete Folder
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </div>
      )}
    </Wrapper>
  );
}
