import 'firebase/compat/firestore';
import { toast } from 'react-toastify';
import { combineReducers } from 'redux';
import type { Reducer } from 'redux';
import axiosInstance from 'utils/axiosInstance';
import type { AppDispatch } from 'index';
import type { RootState } from 'reducers/RootType';
import { analyticsEvents, apiKeysActions, genericEventLogger } from 'utils/dataLayer';

export const API_KEYS_LOADED = 'API_KEYS_LOADED';
export const API_KEYS_LOADING = 'API_KEYS_LOADING';
export const API_KEYS_LOADING_ERROR = 'API_KEYS_LOADING_ERROR';
export const API_KEY_CREATED = 'API_KEY_CREATED';
export const API_KEYS_CLEAR_NEWLY_CREATED = 'API_KEYS_CLEAR_NEWLY_CREATED';

export const loadApiKeys = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const response = await axiosInstance.get('/v2/users/api-keys/list');
    dispatch({ type: API_KEYS_LOADED, payload: response.data?.apiKeys });
  } catch (error) {
    console.error('Error loading API Keys:', error.response?.data);
    dispatch({ type: API_KEYS_LOADING_ERROR });
    toast.error('Error loading API keys');
  }
};

export function createApiKey({
  scopes,
  description,
}: {
  scopes?: string[];
  description?: string;
} = {}) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const response = await axiosInstance.post('/v2/users/api-keys/create', {
        scopes,
        description,
      });
      dispatch({ type: API_KEY_CREATED, payload: response.data });
      dispatch(loadApiKeys());
      toast.success('API key created');
      genericEventLogger(analyticsEvents.API_KEYS, { action: apiKeysActions.CREATE });
    } catch (error) {
      console.error('Error creating API Key:', error.response?.data);
      toast.error('Error creating API key. Please try again.');
    }
  };
}

export function revokeApiKey(apiKey: string) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const response = await axiosInstance.delete('/v2/users/api-keys/delete/' + apiKey);
      dispatch(loadApiKeys());
      toast.success('API key revoked');
      genericEventLogger(analyticsEvents.API_KEYS, { action: apiKeysActions.REVOKE });
    } catch (error) {
      console.error('Error revoking API Key:', error.response?.data);
      toast.error('Error revoking API key. Please try again.');
    }
  };
}

export type ApiKey = {
  _id: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
  apiKeyPrefix: string;
  scopes: string[];
  description: string;
  apiKey?: string;
};

const userKeys: Reducer<ApiKey[]> = (state = [], action) => {
  switch (action.type) {
    case API_KEYS_LOADED:
      return action.payload;
    default:
      return state;
  }
};

const newlyCreated: Reducer<ApiKey | null> = (state = null, action) => {
  switch (action.type) {
    case API_KEYS_CLEAR_NEWLY_CREATED:
      return null;
    case API_KEY_CREATED:
      return action.payload;
    default:
      return state;
  }
};

const loadingError: Reducer<boolean> = (state = false, action) => {
  switch (action.type) {
    case API_KEYS_LOADING_ERROR:
      return true;
    case API_KEYS_LOADED:
      return false;
    default:
      return state;
  }
};

export const reducers = combineReducers({ userKeys, newlyCreated, loadingError });
