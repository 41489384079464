import { Text } from '@tw/ui-components';

type HeadlineProps = {
  headline: string;
};

export const Headline: React.FC<HeadlineProps> = ({ headline }) => {
  return (
    <div className="flex flex-col h-full gap-4 w-full justify-center items-center p-10 rounded-md border border-gray-200 border-solid">
      <svg
        className="w-8 h-8 text-gray-400 dark:text-gray-600 mb-4"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 18 14"
      >
        <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
      </svg>

      <Text as="h2" size="lg" weight="bold" align="center" fs="italic">
        {headline}
      </Text>
    </div>
  );
};
