import { useState } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'reducers/RootType';
import { Button, TextField, Tooltip } from '@shopify/polaris';
import { EditMinor, SaveMinor } from '@shopify/polaris-icons';
import { capitalize } from 'lodash';
import { getCurrencySymbol } from 'utils/getCurrencySymbol';
import { toast } from 'react-toastify';
import { useDarkMode } from 'dark-mode-control';

type UpdatableColumnProps = {
  update: (newBudget: number, oldBudget: number) => Promise<{ message: string; success: boolean }>;
  tooltipText: string;
  permitted: boolean;
  noValueText?: string;
  value: number;
  canEdit: boolean;
  active: boolean;
  type: string;
  currency: string;
  isReadOnly?: boolean;
};

const UpdatableColumn: React.FC<UpdatableColumnProps> = ({
  update,
  tooltipText,
  permitted,
  noValueText = '-',
  value,
  canEdit,
  active,
  type,
  currency,
  isReadOnly = false,
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [valueStr, setValueStr] = useState('');
  const [loading, setLoading] = useState(false);
  const doDarkMode = useDarkMode();

  const handleEdit = () => {
    setValueStr(`${value}`);
    setIsEditable(true);
  };

  const reset = () => {
    setValueStr(`${value}`);
    setIsEditable(false);
    setLoading(false);
  };

  const handleSave = async () => {
    if (!valueStr) {
      reset();
      return;
    }
    const newValue = +valueStr;
    if (isNaN(newValue) || newValue < 0 || newValue === value) {
      reset();
    } else {
      try {
        setIsEditable(false);
        setLoading(true);
        const { message, success } = await update(newValue, value);
        if (success) {
          setValueStr(`${newValue}`);
        }
        toast[success ? 'success' : 'error'](message);
      } catch (e) {
        toast.error(`Failed to update ${type}`);
      }
      setLoading(false);
    }
  };

  if (!active) {
    return <p className={`text-secondary-text flex items-center gap-2 justify-center`}>-</p>;
  }

  if (!value) {
    return (
      <p
        className={`text-secondary-text justify-center flex items-center gap-2 ${
          noValueText === '-' ? 'justify-center' : 'text-lg'
        }`}
      >
        {noValueText}
      </p>
    );
  }

  return (
    <>
      {!isEditable && (
        <>
          <div className="flex items-center gap-4">
            <span className={`text-inherit text-left`}>
              {canEdit
                ? getCurrencySymbol(currency) +
                  (valueStr && valueStr.length > 0 ? valueStr : `${value}`)
                : '-'}
            </span>
            {canEdit && !isReadOnly && (
              <span className="">
                <Tooltip content={tooltipText}>
                  <span className={doDarkMode ? 'tw-nice-dark' : ''}>
                    <Button
                      size="slim"
                      icon={<EditMinor className="flex items-center w-7 h-7" />}
                      disabled={!permitted}
                      onClick={() => handleEdit()}
                      loading={loading}
                    ></Button>
                  </span>
                </Tooltip>
              </span>
            )}
          </div>
          <p className="text-secondary-text text-lg flex items-center gap-2">
            {canEdit && type ? type : ''}
          </p>
        </>
      )}
      {isEditable && (
        <div id="edit-budget-div" className={`flex items-center ${doDarkMode && ' tw-nice-dark'}`}>
          {' '}
          <TextField
            id="pixel-budget"
            onBlur={reset}
            value={valueStr}
            onChange={(e) => {
              setValueStr(e);
            }}
            label="Budget"
            labelHidden
            autoComplete="off"
            min={0}
            prefix={getCurrencySymbol(currency)}
            selectTextOnFocus={true}
            focused={true}
            connectedRight={
              <span className={doDarkMode ? 'tw-nice-dark' : ''}>
                <Button
                  id="pixel-save-budget"
                  size="slim"
                  icon={<SaveMinor className="flex items-center w-7 h-7" />}
                  onPointerDown={handleSave}
                />
              </span>
            }
          />
        </div>
      )}
    </>
  );
};

export default UpdatableColumn;
