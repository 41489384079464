import { FeatureFlag } from '@tw/feature-flag-system/module/types';
import { ActionIcon, Button, Tooltip } from '@tw/ui-components';
import { WillyMainChatRef } from 'components/Willy/types/willyTypes';
import { useFeatureFlag } from 'feature-flag-system';
import { FC } from 'react';
import { analyticsEvents, dashboardsActions, genericEventLogger } from 'utils/dataLayer';

type ChatWithQueryButtonProps = {
  chatOpen?: boolean;
  setChatOpen?: (val: boolean) => void;
  mainChatRef?: React.RefObject<WillyMainChatRef>;
  onlyIcon?: boolean;
  source?: string;
  tooltip?: string;
};

export const ChatWithQueryButton: FC<ChatWithQueryButtonProps> = ({
  chatOpen,
  setChatOpen,
  mainChatRef,
  onlyIcon = false,
  source,
  tooltip,
}) => {
  const { shouldNotBeSeen: isChatBlocked } = useFeatureFlag(FeatureFlag.CHAT_FF);

  const handleOnClick = () => {
    setChatOpen?.(!chatOpen);
    if (!chatOpen) {
      genericEventLogger(analyticsEvents.DASHBOARDS, {
        action: dashboardsActions.OPEN_CHAT,
        source: source,
      });
    }
    mainChatRef?.current?.focusTextInput();
  };

  return (
    <Tooltip label={isChatBlocked ? 'Upgrade to Access Chat' : tooltip ?? 'Chat with Dashboard'}>
      {!onlyIcon ? (
        <Button
          leftSection="star-plus"
          iconSize={16}
          variant="secondary"
          size="xs"
          onClick={handleOnClick}
          disabled={isChatBlocked}
        >
          Moby
        </Button>
      ) : (
        <ActionIcon
          icon="star-plus"
          iconColor="one.5"
          variant="outline"
          onClick={handleOnClick}
          disabled={isChatBlocked}
        />
      )}
    </Tooltip>
  );
};
