import { $isIntercomVisible, toggleLauncherVisibility } from 'utils/intercom';
import { Flex, FormattedColor, Icon, Tooltip } from '@tw/ui-components';
import { useStoreValue } from '@tw/snipestate';
import { useOnMount } from 'hooks/useOnMount';

export type GetHelpProps = {
  iconSize?: number;
  iconColor?: FormattedColor;
  forcedIntercom?: boolean;
};

export function GetHelp({ iconSize = 20, iconColor, forcedIntercom = false }: GetHelpProps) {
  const isIntercomVisible = useStoreValue($isIntercomVisible);
  useOnMount(() => {
    if (forcedIntercom) {
      toggleLauncherVisibility(true);
    }
  });

  return (
    <div id="intercom-help-icon">
      <Flex align="center" justify="center" w="100%" h="100%">
        <Tooltip
          label={isIntercomVisible ? 'Hide help' : 'Get help'}
          onClick={() => {
            if (!isIntercomVisible) {
              toggleLauncherVisibility(true);
            } else {
              toggleLauncherVisibility(false);
            }
          }}
        >
          <div className="flex cursor-pointer">
            <Icon size={iconSize} name="question-mark" color={iconColor} />
          </div>
        </Tooltip>
      </Flex>
    </div>
  );
}
