import { RadioButton } from '@shopify/polaris';
import { getCurrencySymbol } from '@tw/constants';
import { Button, Checkbox, Flex, Icon, Loader, Text, TextInput, Select } from '@tw/ui-components';
import TWAlert from 'components/library/TWAlert/TWAlert';
import { CURRENCIES } from '@tw/constants';
import {
  clearShippingProfileData,
  saveShippingProfileData,
  shippingProfileOnSave,
} from 'ducks/shipping';
import { useAppDispatch } from 'index';
import CogsHistory from 'pages/cogs/CogsHistory';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import axiosInstance from 'utils/axiosInstance';
import { v4 as uuidV4 } from 'uuid';
import { useStoreValue } from '@tw/snipestate';
import { $shopCurrency } from '../../$stores/$shop';

export const Rates: React.FC<any> = ({ setPage, setIsOpen }) => {
  const profile = useSelector((state: RootState) => state.shippingProfileData);
  const [internalShippingCosts, setInternalShippingCosts] = useState<any>(
    (profile?.shipping_costs?.length &&
      profile?.shipping_costs?.map((r) => {
        return { ...r, cost: r.costs?.[0]?.value ?? '' };
      })) || [{ type: 'fixed', costs: [], cost: '' }],
  );
  const [costType, setCostType] = useState<'fixed' | 'weight_based'>(
    internalShippingCosts?.[0]?.type ?? 'fixed',
  );
  const [weightType, setWeightType] = useState<'gr' | 'lbs'>(profile?.weightType ?? 'gr');
  const currency = useStoreValue($shopCurrency);
  const [isCostHistoryOpen, setIsCostHistoryOpen] = useState(false);
  const [selectedHistoryIndex, setSelectedHistoryIndex] = useState(0);
  const currentShopId = useSelector((state: RootState) => state.currentShopId);
  const dispatch = useAppDispatch();
  const [shippingMethodRates, setShippingMethodRates] = useState<any[]>(
    profile?.shipping_methods ?? [],
  );
  const [shippingMethods, setShippingMethods] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [showFulfillmentMethods, setShowFulfillmentMethods] = useState(false);

  const checkWeightTierOverlap = useMemo(() => {
    // Sort weight tiers by minimum weight
    internalShippingCosts.sort((a, b) => a.minWeight - b.minWeight);
    // Check for overlaps
    for (let i = 0; i < internalShippingCosts.length - 1; i++) {
      if (+internalShippingCosts[i].maxWeight > +internalShippingCosts[i + 1].minWeight) {
        // Overlap found
        return true;
      }
    }
    // No overlaps found
    return false;
  }, [internalShippingCosts]);

  const disabled = useMemo(
    () =>
      internalShippingCosts.some((r) => r.cost === '') ||
      (costType === 'weight_based' &&
        internalShippingCosts.some(
          (r) =>
            r.minWeight === undefined || r.maxWeight === undefined || +r.minWeight >= +r.maxWeight,
        )) ||
      (costType === 'weight_based' && checkWeightTierOverlap),
    [internalShippingCosts, costType, checkWeightTierOverlap],
  );

  const errorMessage = internalShippingCosts.some((r) => r.cost === '')
    ? 'Please enter a cost for all rates'
    : costType === 'weight_based' &&
        internalShippingCosts.some(
          (r) =>
            r.minWeight === undefined || r.maxWeight === undefined || +r.minWeight > +r.maxWeight,
        )
      ? 'Please enter a valid minimum and maximum weight for all rates'
      : costType === 'weight_based' && checkWeightTierOverlap
        ? 'Weight tiers cannot overlap'
        : '';

  const showCostHistory = (index) => {
    setIsCostHistoryOpen(true);
    setSelectedHistoryIndex(index);
  };

  useEffect(() => {
    if (profile?.shipping_costs?.length) {
      setInternalShippingCosts(
        profile?.shipping_costs?.map((r) => {
          return { ...r, cost: r.costs?.[0]?.value ?? '' };
        }) ?? [{ type: 'fixed', costs: [], cost: '' }],
      );
    }
    setCostType(profile?.shipping_costs?.[0]?.type ?? 'fixed');
    setShippingMethodRates(profile?.shipping_methods ?? []);
  }, [profile]);

  const handleChange = useCallback(
    (index, field, value) => {
      const newCosts = [...internalShippingCosts];
      if (['cost', 'minWeight', 'maxWeight'].includes(field)) {
        if (isNaN(+value)) return;
      }
      if (field === 'cost') {
        let costHistory = internalShippingCosts[index]['costs'] || [];
        const today = new Date().toISOString();
        if (costHistory?.[0]?.['date']?.split('T')[0] === today.split('T')[0]) {
          costHistory[0] = {
            value,
            date: today,
          };
        } else {
          costHistory.unshift({
            value,
            date: today,
          });
        }
        newCosts[index]['costs'] = costHistory;
      }
      newCosts[index][field] = value;
      setInternalShippingCosts(newCosts);
    },
    [internalShippingCosts],
  );

  const onSave = useCallback(() => {
    setIsOpen(false);
    let id = profile?.id ?? uuidV4();
    if (weightType === 'lbs' && internalShippingCosts?.[0]?.type === 'weight_based') {
      internalShippingCosts.forEach((sc) => {
        sc.minWeight = Math.round(sc.minWeight / 0.00220462);
        sc.maxWeight = Math.round(sc.maxWeight / 0.00220462);
      });
    }
    dispatch(
      shippingProfileOnSave({
        ...profile,
        id,
        ...(weightType == 'lbs' && { weightType }),
        shipping_costs: internalShippingCosts,
        shipping_methods: shippingMethodRates.map((sm) => {
          return {
            ...sm,
            cost: +sm.cost,
          };
        }),
      }),
    );
    dispatch(clearShippingProfileData());
    setPage('zones');
  }, [
    dispatch,
    internalShippingCosts,
    profile,
    setIsOpen,
    setPage,
    shippingMethodRates,
    weightType,
  ]);

  const fetchShippingMethods = async () => {
    const url = `/v2/shopify/mongo/get-shipping-methods`;
    let body = {
      shopId: currentShopId,
    };
    if (profile?.zones?.length) body['countries'] = profile?.zones.map((z) => z.code);
    return await axiosInstance.post(url, body);
  };

  async function fetchData() {
    setLoading(true);
    const res = await fetchShippingMethods();
    setShippingMethods(res?.data ?? []);
    setLoading(false);
  }
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentShopId, profile?.zones]);

  const onChangeShippingMethodRate = useCallback(
    (code, val) => {
      if (isNaN(+val) || +val < 0) return;
      let tempShippingMethodRates = [...shippingMethodRates];
      let index = tempShippingMethodRates.findIndex((x) => x.code === code);
      tempShippingMethodRates[index].cost = val;
      setShippingMethodRates(tempShippingMethodRates);
    },
    [shippingMethodRates],
  );
  return (
    <>
      <div className="w-full h-[1px] bg-[#E5E7EB] mb-5"></div>
      <Text weight={600}>Fulfillment Rates Settings</Text>
      <div className="flex flex-col gap-5 py-5">
        <RadioButton
          label="Fixed Rate"
          name="rates"
          checked={costType === 'fixed'}
          onChange={() => {
            setCostType('fixed');
            setInternalShippingCosts([{ type: 'fixed', cost: '', costs: [] }]);
          }}
        />
        <RadioButton
          label="Order weight-based tiered rates"
          name="rates"
          checked={costType === 'weight_based'}
          onChange={() => {
            setCostType('weight_based');
            setInternalShippingCosts([
              { type: 'weight_based', minWeight: '', maxWeight: '', cost: '', costs: [] },
            ]);
          }}
        />
      </div>
      {costType === 'weight_based' && (
        <Flex direction="column" gap="sm">
          {internalShippingCosts?.map((sc, index) => {
            return (
              <div className="flex gap-4" key={index}>
                <TextInput
                  label={index > 0 ? '' : 'Shipping Rate'}
                  value={sc?.cost ?? ''}
                  onChange={(val) => handleChange(index, 'cost', val)}
                  leadingIcon={
                    <Text size={CURRENCIES[currency].length > 2 ? 'xs' : 'md'}>
                      {CURRENCIES[currency]}
                    </Text>
                  }
                  trailingIcon={
                    profile?.edit ? (
                      <div
                        className="cost-history"
                        color="grey"
                        title="Show Cost History"
                        onClick={() => showCostHistory(index)}
                      >
                        <Icon name={'calendar-1'} />
                      </div>
                    ) : (
                      <></>
                    )
                  }
                />
                <TextInput
                  label={index > 0 ? '' : 'Min Weight'}
                  value={sc?.minWeight ?? ''}
                  onChange={(val) => handleChange(index, 'minWeight', val)}
                />
                <TextInput
                  label={index > 0 ? '' : 'Max Weight (incl)'}
                  value={sc?.maxWeight ?? ''}
                  onChange={(val) => handleChange(index, 'maxWeight', val)}
                />
                <div className="w-32">
                  <Select
                    label={index > 0 ? '' : ' '}
                    data={['gr', 'lbs']}
                    value={weightType}
                    onChange={(val: any) => setWeightType(val)}
                    disabled={index > 0}
                  />
                </div>
                <div
                  onClick={() =>
                    setInternalShippingCosts(internalShippingCosts.filter((sc, i) => i !== index))
                  }
                  className={index === 0 ? 'pointer-events-none opacity-0' : 'cursor-pointer'}
                >
                  <Icon name={'close'} size={10} />
                </div>
              </div>
            );
          })}
          <div className="flex justify-start">
            <Button
              variant="white"
              onClick={() => {
                setInternalShippingCosts([
                  ...internalShippingCosts,
                  {
                    type: 'weight_based',
                    minWeight: internalShippingCosts[internalShippingCosts.length - 1]?.maxWeight,
                    maxWeight: '',
                    cost: '',
                  },
                ]);
              }}
            >
              Add Another Rate
            </Button>
          </div>
        </Flex>
      )}
      {costType === 'fixed' && (
        <div className="flex flex-col gap-4">
          <div className="flex gap-4">
            <TextInput
              label={'Shipping Rate'}
              value={internalShippingCosts[0]?.cost ?? ''}
              onChange={(val) => handleChange(0, 'cost', val)}
              leadingIcon={
                <Text size={CURRENCIES[currency].length > 2 ? 'xs' : 'md'}>
                  {CURRENCIES[currency]}
                </Text>
              }
              trailingIcon={
                profile?.edit ? (
                  <div
                    className="cost-history"
                    color="grey"
                    title="Show Cost History"
                    onClick={() => showCostHistory(0)}
                  >
                    <Icon name={'calendar-1'} />
                  </div>
                ) : (
                  <></>
                )
              }
            />
          </div>
        </div>
      )}
      <div className="w-full h-[1px] bg-[#E5E7EB] my-5 "></div>
      <div
        className="flex gap-4"
        onClick={() => setShowFulfillmentMethods(!showFulfillmentMethods)}
      >
        <Text weight={600}>Fulfillment Methods</Text>
        <div className="flex align-items-center">
          <Icon name={showFulfillmentMethods ? 'caret-up-bold' : 'caret-down-bold'} />
        </div>
      </div>
      {showFulfillmentMethods && loading && <Loader />}
      {showFulfillmentMethods && !loading && shippingMethods.length > 0 && (
        <>
          <TWAlert
            type={'info'}
            message={`We found ${shippingMethods.length} shipping methods for the selected countries. Check the box and set any additional rates that apply for each method. If you only know the fixed rate per shipping method, then set the 'fixed rate' above to 0.`}
            title={''}
          />
          <div className="flex flex-col gap-5 py-5">
            {shippingMethods.map((sm, i) => {
              return (
                <div className="flex space-between align-items-center" key={sm + i}>
                  <Checkbox
                    label={sm}
                    key={sm}
                    checked={shippingMethodRates.some((s) => s.code === sm)}
                    onChange={(checked) => {
                      if (checked) {
                        setShippingMethodRates([...shippingMethodRates, { code: sm, cost: 0 }]);
                      } else {
                        setShippingMethodRates(shippingMethodRates.filter((s) => s.code !== sm));
                      }
                    }}
                  />
                  <TextInput
                    value={shippingMethodRates?.find((x) => x.code === sm)?.cost ?? 0}
                    onChange={(val) => {
                      onChangeShippingMethodRate(sm, val);
                    }}
                    leadingIcon={
                      <Text size={CURRENCIES[currency].length > 2 ? 'xs' : 'md'}>
                        {CURRENCIES[currency]}
                      </Text>
                    }
                    disabled={!shippingMethodRates.some((s) => s.code === sm)}
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
      {showFulfillmentMethods && !loading && !shippingMethods?.length && (
        <TWAlert
          type={'error'}
          message={`We  did not find any additional shipping methods for the selected countries. `}
          title={''}
        />
      )}

      <div className="w-full h-[1px] bg-[#E5E7EB] my-5 "></div>
      <Flex justify={disabled ? 'space-between' : 'end'}>
        {disabled && <Text color="red.5">{errorMessage}</Text>}
        <div className="flex gap-4">
          <Button
            variant={'white'}
            onClick={() => {
              setPage('zones');
              dispatch(
                saveShippingProfileData({
                  ...profile,
                  shipping_costs: internalShippingCosts,
                  shipping_methods: shippingMethodRates,
                  ...(weightType === 'lbs' && { weightType }),
                }),
              );
            }}
          >
            Previous
          </Button>
          <Button
            onClick={() => {
              onSave();
            }}
            disabled={disabled}
          >
            Save
          </Button>
        </div>
      </Flex>

      <CogsHistory
        selectedRow={internalShippingCosts[selectedHistoryIndex]}
        getCurrencySymbol={getCurrencySymbol}
        defaultCurrency={currency}
        isOpen={isCostHistoryOpen}
        onClose={() => setIsCostHistoryOpen(false)}
        service={'shopify'}
        type={'shipping_profile'}
        costType={'costs'}
        title={`Shipping Rate History`}
        onSave={({ costs }) => {
          let tempCosts = [...internalShippingCosts];
          tempCosts[selectedHistoryIndex].costs = costs.sort((a, b) =>
            Date.parse(a.date) > Date.parse(b.date) ? -1 : 1,
          );
          dispatch(
            saveShippingProfileData({
              ...profile,
              shipping_costs: tempCosts,
            }),
          );
        }}
      />
    </>
  );
};
