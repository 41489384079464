import { useState } from 'react';
import { WillyDataSequence } from './Willy/types/willyTypes';
import { InsightsPopupInner } from './InsightsPopupInner';

type SummaryInsightsProps = {
  workflows: WillyDataSequence[];
  metricId: string;
  showGeneralInsights: boolean;
  generalInsights: string;
  generalInsightsError: string | null;
};

export const SummaryInsights: React.FC<SummaryInsightsProps> = ({
  workflows,
  metricId,
  showGeneralInsights,
  generalInsights,
  generalInsightsError,
}) => {
  const [selectedWorkflow, setSelectedWorkflow] = useState<WillyDataSequence | null>(null);

  return (
    <InsightsPopupInner
      workflows={workflows}
      selectedWorkflow={selectedWorkflow}
      setSelectedWorkflow={setSelectedWorkflow}
      useDatePickers={
        selectedWorkflow?.taggedMetrics?.find((tm) => tm.metric === metricId)?.useDatePickers
      }
      source="summary"
      sourceDetails={metricId}
      showGeneralInsights={showGeneralInsights}
      generalInsights={generalInsights}
      generalInsightsError={generalInsightsError}
    />
  );
};
