import { Popover, Text, Flex, Icon } from '@tw/ui-components';
import { FC } from 'react';

type ColumnTooltipProps = {
  title: string;
  subTitle: string;
  description: string;
  children: React.ReactNode;
  tooltipOpened: boolean;
  readmeUrl?: string | null;
  withinPortal?: boolean;
  offset?: number;
  position?: any;
  tracking?: () => void;
};

export const generateShortDescription = (description?: string) => {
  if (!description) {
    return undefined;
  }
  return description.split('.')[0] + ' ...';
};

export const ColumnTooltip: FC<ColumnTooltipProps> = ({
  title,
  subTitle,
  description,
  children,
  tooltipOpened,
  readmeUrl,
  withinPortal = false,
  offset = 20,
  position = 'right-start',
  tracking,
}) => {
  return (
    <Popover
      opened={tooltipOpened}
      position={position}
      offset={offset}
      shadow="md"
      withinPortal={withinPortal}
    >
      <Popover.Target>
        <div>{children}</div>
      </Popover.Target>
      <Popover.Dropdown onClick={(e) => e.stopPropagation()}>
        <Flex direction="column" gap="xs" maw={300}>
          <Flex direction="column">
            <Text fz="sm" fw={500} c="gray.8">
              {title}
            </Text>
            {subTitle && (
              <Text fz="sm" c="gray.5">
                {subTitle}
              </Text>
            )}
          </Flex>
          <Text fz="sm" c="gray.9">
            <div className="break-words" dangerouslySetInnerHTML={{ __html: description }}></div>
          </Text>
          {readmeUrl && (
            <Flex
              onClick={(e) => {
                e.stopPropagation();
                window.open(`https://triplewhale.readme.io/reference/${readmeUrl}`, '_blank');
                tracking?.();
              }}
              cursor="pointer"
              align="center"
              gap="xs"
            >
              <Text color="one.5" size="sm" weight={500}>
                Go to data dictionary
              </Text>
              <Icon name="external-minor" color="one.5" />
            </Flex>
          )}
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};
