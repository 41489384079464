import moment from '@tw/moment-cached';
import { TwNumberFormatOptions } from '@tw/types';

// convert number of seconds to duration in format 00:00:00
export const formatDuration = (seconds: number): string => {
  if (seconds < 0) {
    return '00:00:00';
  }
  const duration = moment.duration(seconds, 'seconds');
  const hours = duration.hours();
  const minutes = duration.minutes();
  const secs = duration.seconds();
  return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${
    secs < 10 ? '0' : ''
  }${secs}`;
};

export const formatNumber = (value: number, options: TwNumberFormatOptions): string => {
  try {
    // we want moment format and not Intl format, for backward compatibility
    if (options.style === 'date') {
      return moment(value).format(options.dateFormat);
    }
    if (options.style === 'duration') {
      return formatDuration(value);
    }
    // if you explicitly want a string, you get a string
    if (options.style === 'string') {
      return value.toString();
    }
    if (!value || Number.isNaN(+value) || !Number.isFinite(+value)) {
      value = 0;
    }
    if ((options.minimumFractionDigits || 0) > (options.maximumFractionDigits || 0)) {
      options.maximumFractionDigits = options.minimumFractionDigits || 0;
    }
    return value.toLocaleString(undefined, options as Intl.NumberFormatOptions);
  } catch (e) {
    console.error('formatNumber went wrong', options, value, e);
    return value.toString();
  }
};

export const formatToFixed = (value: any, decimals = 2): string => {
  if (typeof value === 'number') {
    return value.toFixed(decimals);
  }
  return value ?? '';
};
