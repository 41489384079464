// libs
import { Suspense, useMemo } from 'react';
import { AppShell, Main } from '@tw/ui-components';
import { Badge, Frame, Toast } from '@shopify/polaris';

// hooks
import { useAppDispatch } from 'index';
import { useAppSelector } from 'reducers/RootType';
import { useLocation } from 'react-router-dom';
import { $activeAppVersion, $activeRoute } from '$stores/nav-config-stores';
import {
  useCustomLoadFromAppNotification,
  useHandleOpenFromNotification,
  useHandleVisitorsFromBadEmailUrl,
  useIsUserAfterSignup,
  useDataLayerTracking,
  useShopDomainSync,
  useUpdateTabTitleOnRouteChange,
} from './hooks';
import { usePathMatch } from 'hooks/usePathMatch';
import { useConnectNavigate$ } from '$stores/$navigate';
import { useConnectLocation$ } from '$stores/$location';
import { useSendIntercomShopData } from 'hooks/useSendIntercomShopData';

// utils
import { cx } from 'utils/cx';
import lazyWithRetries from 'utils/lazyWithRetries';
import { isInIframe } from 'config';
import { IframeComponents } from './utils';

// ducks
import { inactiveCopyToClipboard } from 'ducks/actions';

// components
import { TopBar } from 'components/TopBar/TopBar';
import PopupsAndConfigs from 'globalComponents/popupsAndConfigs';
import { FallbackComponent } from 'components/FallbackComponent';
import GeneralErrorAlert from 'components/GeneralErrorAlert';
import { EducationFooter } from 'components/EducationFooter';
import { Nav } from 'components/Nav/Nav';
import { MobileV3Header, MobileV3FooterMenu, NewTemplateWrapper } from 'NewTemplateWrapper';
import { WindowWidth } from 'constants/windowSizes';
import { $store, useComputedValue, useStoreValue } from '@tw/snipestate';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import {
  FreeTrialBanner,
  useShowFreeTrialBanner,
} from 'components/Willy/UpgradePlan/FreeTrialBanner';
import { usePathForDateRange } from './hooks/usePathForDateRange';
import { useIsFeedItemPage } from './hooks/useIsFeedItemPage';
import { useSignOutOrcaUsersOnRegularApp } from './hooks/useSignOutOrcaUsersOnRegularApp';

const RouterFragment = lazyWithRetries(() => import('../RouterFragment'));
const RouterIframeFragment = lazyWithRetries(() => import('../RouterIframeFragment'));

export const $appTransition = $store(200);

const Router = () => {
  const isSmall = useIsSmall();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const isNoShellView = usePathMatch(['/signup', '/signin', '/payment', '/welcome', '/oauth2']);
  const isUserAfterSignUp = useIsUserAfterSignup();
  const copyToClipboardActive = useAppSelector((state) => state.copyToClipboardActive);
  const isLoading = useAppSelector((s) => s.loading || (s.isLoggedIn && s.loadingShops));

  const appVersion = useStoreValue($activeAppVersion);
  const isWillyPage = useComputedValue($activeRoute, (r) => !!r.activeRoute?.isWillyPage);
  const isHybridPage = useComputedValue($activeRoute, (r) => !!r.activeRoute?.isHybridPage);
  const isShoplessPage = useComputedValue($activeRoute, (r) => !!r.activeRoute?.isShoplessPage);
  const isSettingsPage = useComputedValue($activeRoute, (r) => !!r.activeRoute?.isSettingsPage);
  const isAppsPage = isHybridPage && !isShoplessPage && !isSettingsPage && appVersion === '3.0';
  const appTransition = useStoreValue($appTransition);

  const headerHeight =
    isInIframe || isNoShellView || (isWillyPage && !isHybridPage) ? '0px' : 'var(--top-bar-height)';

  // handle all side effects when route changes
  // STICK ALL SIDE EFFECTS IN A CUSTOM HOOK IN HERE (please 🙏)
  // so we don't keep blowing up this component with random logic
  useRouteChangeSideEffects();

  const scrollableClasses = useMemo(() => {
    if (isWillyPage && isSmall) {
      document.getElementById('app-scrollable-wrapper')?.scrollTo(0, 0);
    }
    return cx(
      'w-full overflow-y-auto overflow-x-hidden relative',
      `${pathname.split('/')[1]}-wrapper`,
    );
  }, [isSmall, isWillyPage, pathname]);

  const showFreeTrialBanner = useShowFreeTrialBanner();

  const isFeedItemPage = useIsFeedItemPage();

  return (
    <Frame>
      <AppShell
        padding={0}
        layout="alt"
        header={{
          height: headerHeight,
          offset: (!isWillyPage || isHybridPage) && !isNoShellView,
        }}
        navbar={{
          width: !isInIframe && !isNoShellView ? 'var(--nav-width)' : 0,
          breakpoint: WindowWidth.SMALL - 1,
        }}
        bg="named.3"
        transitionDuration={appTransition}
      >
        {/* HEADER */}
        {isUserAfterSignUp && (!isWillyPage || isHybridPage) ? <TopBar /> : <></>}
        {isUserAfterSignUp ? <Nav /> : <></>}
        {/* Upgrade Banner */}
        {showFreeTrialBanner && <FreeTrialBanner />}
        <Main>
          <div
            id="app-scrollable-wrapper"
            style={{
              height: !isUserAfterSignUp
                ? '100%'
                : isWillyPage
                  ? '100dvh'
                  : `calc(100vh - ${headerHeight})`,
              overflow: isWillyPage ? 'hidden' : '',
            }}
            className={scrollableClasses}
          >
            <GeneralErrorAlert />
            {!isLoading && !isInIframe && (
              <Suspense fallback={<FallbackComponent />}>
                {isAppsPage && isSmall && (
                  <div className="flex items-center justify-between gap-4">
                    <MobileV3Header />
                  </div>
                )}
                {isWillyPage ? <NewTemplateWrapper /> : <RouterFragment />}
                {(isWillyPage || isAppsPage) && !isFeedItemPage && <MobileV3FooterMenu />}
                <EducationFooter />
              </Suspense>
            )}
            {!isLoading && isInIframe && (
              <Suspense fallback={<FallbackComponent />}>
                <RouterIframeFragment />
              </Suspense>
            )}
            {isLoading && <FallbackComponent />}
            <div className="save-indicator">
              <Badge status="success">Saved</Badge>
            </div>
          </div>
        </Main>
        <IframeComponents />
        {!isInIframe && <PopupsAndConfigs />}
        {copyToClipboardActive && (
          <Toast
            content="Copied to Clipboard"
            duration={2000}
            onDismiss={() => dispatch(inactiveCopyToClipboard())}
          />
        )}
      </AppShell>
    </Frame>
  );
};

export default Router;

/**
 * @description Just an helper function to manage/contain all side effects
 * that need to occur when changes happen to location, etc.
 */
const useRouteChangeSideEffects = () => {
  useConnectNavigate$();
  useConnectLocation$();
  useShopDomainSync();
  useSignOutOrcaUsersOnRegularApp();
  useCustomLoadFromAppNotification();
  useHandleVisitorsFromBadEmailUrl();
  useHandleOpenFromNotification();
  useDataLayerTracking();
  useUpdateTabTitleOnRouteChange();
  useSendIntercomShopData();
  usePathForDateRange();
};
