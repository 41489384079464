import { Button, Loader, Popover, Select, Text } from '@tw/ui-components';
import { DashboardsDropDown } from '../DashboardsDropDown';
import {
  DialectWithBoth,
  WillyDashboardElement,
  WillyParameter,
  WillyWidgetElement,
  WorkflowStepBase,
  WorkflowStepPreloadData,
} from '../types/willyTypes';
import { useEffect, useMemo, useState } from 'react';
import {
  DatePickerTimePeriods,
  getDatePickerOptionsDictionary,
} from 'components/useDatePickerSelectedOptions';
import { DateOptionsList } from 'pages/FreeQuery/DateOptionsList';
import { compareOptions } from 'components/useDatePickerCompareOptions';
import { PreviousPeriodIds } from '@tw/types/module/datePicker/datePicker';
import { useStoreValue } from '@tw/snipestate';
import { $isAdminClaim } from '$stores/$user';
import { $currentShopId } from '$stores/$shop';
import _db, { firestoreRef, toArray } from 'utils/DB';
import { uniqBy } from 'lodash';
import { WillyDynamicFields } from '../WillyDynamicFields';
import { emptyArray } from '../utils/willyUtils';
import { $combinedDashboard } from '$stores/willy/$combinedDashboards';

type FlowLoadDataStepProps = {
  step: WorkflowStepPreloadData & WorkflowStepBase;
  stepChange: (newPrompt: WorkflowStepPreloadData & WorkflowStepBase) => void;
  readOnly: boolean;
  setIsPristine: (isPristine: boolean) => void;
  workflowDialect: DialectWithBoth;
};

export const FlowLoadDataStep: React.FC<FlowLoadDataStepProps> = ({
  step,
  stepChange,
  readOnly,
  setIsPristine,
  workflowDialect,
}) => {
  const isAdmin = useStoreValue($isAdminClaim);
  const dashboards = useStoreValue($combinedDashboard);
  const shopId = useStoreValue($currentShopId);
  const [dateOptionsListOpened, setDateOptionsListOpened] = useState(false);
  const [previousDateOptionsListOpened, setPreviousDateOptionsListOpened] = useState(false);
  const [widgets, setWidgets] = useState<WillyWidgetElement[]>([]);
  const [widgetToEdit, setWidgetToEdit] = useState<WillyWidgetElement | null>(null);
  const [loadingWidgets, setLoadingWidgets] = useState(false);
  const [currentDashboard, setCurrentDashboard] = useState<WillyDashboardElement | undefined>(
    undefined,
  );

  const currentParameters = useMemo(() => {
    const fromStep = step.filters?.[widgetToEdit?.queryId || ''] || [];
    const fromWidget = widgetToEdit?.parameters || emptyArray();
    return fromWidget.map<WillyParameter>((p) => {
      const fromStepValue = fromStep.find((s) => s.column === p.column)?.value;
      const fromStepOperator = fromStep.find((s) => s.column === p.column)?.operator;
      return {
        ...p,
        value: fromStepValue ?? p.value,
        operator: fromStepOperator ?? p.operator,
      };
    });
  }, [widgetToEdit, step.filters]);

  useEffect(() => {
    const fetchWidgets = async (collectionPath: string, global: boolean = false) => {
      const docs = await (global ? firestoreRef() : _db())
        .collection(collectionPath)
        .doc(step.dashboardId)
        .collection('widgets')
        .get();

      return toArray(docs)
        .filter((w) => !!w.queryId && !!w.title)
        .filter((w) => w.dialect === workflowDialect);
    };

    (async () => {
      if (!step.dashboardId || !shopId) {
        setWidgets([]);
        setWidgetToEdit(null);
        return;
      }

      setLoadingWidgets(true);
      const path = currentDashboard?.isGlobal ? 'willy_global_dashboards' : 'willy_dashboards';
      const widgetsFromDb = await fetchWidgets(path, currentDashboard?.isGlobal);

      setWidgets(uniqBy(widgetsFromDb, 'queryId'));
      setLoadingWidgets(false);
    })();
  }, [step.dashboardId, shopId, workflowDialect, currentDashboard?.isGlobal]);

  useEffect(() => {
    const dashboard = dashboards.find((d) => d.id === step.dashboardId);
    setCurrentDashboard(dashboard);
  }, [step.dashboardId, dashboards]);

  return (
    <div className="flex flex-col gap-4">
      <DashboardsDropDown
        disabled={readOnly}
        selected={step.dashboardId}
        onSelect={(dashboardId, dashboard) => {
          setCurrentDashboard(dashboard);
          stepChange({
            ...step,
            dashboardId: dashboardId,
          });
          setIsPristine(false);
        }}
        hideGlobals={!isAdmin}
        showStandardDashboards
        withNone
      />

      <Text fw={500} size="sm">
        The data will be preloaded for the following date range:
      </Text>
      <Popover
        opened={dateOptionsListOpened}
        onClose={() => setDateOptionsListOpened(false)}
        shadow="sm"
        position="bottom-start"
      >
        <Popover.Target>
          <span>
            <Button
              disabled={!step.dashboardId || readOnly}
              variant="activator"
              onClick={() => setDateOptionsListOpened((x) => !x)}
              leftSection="calendar-1"
              rightSection="caret-down"
            >
              {step.date ? getDatePickerOptionsDictionary()[step.date]?.label : `Select Date`}
            </Button>
          </span>
        </Popover.Target>
        <Popover.Dropdown p={0}>
          <DateOptionsList
            selectedOption={step.date || null}
            onOptionSelect={(option) => {
              stepChange({
                ...step,
                date: option as DatePickerTimePeriods,
              });
              setDateOptionsListOpened(false);
              setIsPristine(false);
            }}
          />
        </Popover.Dropdown>
      </Popover>
      <Text fw={500} size="sm">
        And compare it to the following date range: (optional)
      </Text>
      <Popover
        opened={previousDateOptionsListOpened}
        onClose={() => setPreviousDateOptionsListOpened(false)}
        shadow="sm"
        position="bottom-start"
      >
        <Popover.Target>
          <span>
            <Button
              disabled={!step.dashboardId || readOnly}
              variant="activator"
              onClick={() => setPreviousDateOptionsListOpened((x) => !x)}
              leftSection="calendar-1"
              rightSection="caret-down"
            >
              {step.previousDate ? compareOptions[step.previousDate]?.label : `None`}
            </Button>
          </span>
        </Popover.Target>
        <Popover.Dropdown p={0}>
          <DateOptionsList
            previous
            selectedOption={step.previousDate || null}
            onOptionSelect={(option) => {
              stepChange({
                ...step,
                previousDate: option as PreviousPeriodIds,
              });
              setPreviousDateOptionsListOpened(false);
              setIsPristine(false);
            }}
          />
        </Popover.Dropdown>
      </Popover>

      {!currentDashboard?.isStandardDashboard && (
        <div className="flex flex-col gap-6.5">
          <div className="flex items-center gap-2 w-full flex-auto">
            {loadingWidgets && <Loader size="xs" />}
            <div className="flex-auto">
              <Select
                placeholder="Select widget"
                data={widgets
                  .filter((w) => w.dialect === workflowDialect)
                  .map((widget) => ({ label: widget.title, value: widget.queryId }))}
                disabled={readOnly}
                label="Edit filters"
                value={widgetToEdit?.queryId}
                onChange={(value) => {
                  setWidgetToEdit(widgets.find((widget) => widget.queryId === value) || null);
                }}
              />
            </div>
          </div>

          {!!widgetToEdit && (
            <div className="flex flex-col gap-6.5">
              <div className="flex-1">
                <WillyDynamicFields
                  parameters={currentParameters}
                  parametersChanged={async (params) => {
                    stepChange({
                      ...step,
                      filters: { ...step.filters, [widgetToEdit.queryId]: params },
                    });
                    setIsPristine(false);
                  }}
                  visibleParameters={(widgetToEdit.parameters || emptyArray()).map((p) => p.column)}
                  query={widgetToEdit.queries?.[0]?.query}
                  wrapperClassName="!overflow-x-visible flex-wrap !p-0"
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
