import { Layer, Rectangle, ResponsiveContainer, Sankey, Tooltip } from 'recharts';
import { WillyMetric, WillySankeyNode } from './types/willyTypes';

import { RawNlqData } from './types/willyTypes';
// import { convertDataToSankey } from './utils/willyUtils';
import { FC, useEffect, useMemo, useState } from 'react';
import { uniqBy } from 'lodash';
import { convertDataToSankey } from './utils/willyUtils';
import { Flex, Select, Slider, Text } from '@tw/ui-components';
import { formatValue } from './utils/formatters';
import { useStoreValue } from '@tw/snipestate';
import { $currency } from '$stores/$shop';

const getUniqNodes = (data: RawNlqData, metrics: WillyMetric[]): WillySankeyNode[] => {
  const x = metrics.filter((m) => m.isDimension).map((m) => m.key);

  data = data.filter((d) => {
    return d.value.some((v) => v !== null);
  });

  let nodes: WillySankeyNode[] = data
    .filter((d) => x.includes(d.name))
    .flatMap((d) => {
      const metric = metrics.find((m) => m.key === d.name);
      return d.value.map((v) => {
        return { name: v?.toString() || '', color: metric?.color, key: `${v?.toString()}_uniq` };
      });
    })
    .filter((n) => n.name !== '');

  nodes = uniqBy(nodes, 'name');

  return nodes;
};

type WillySankeyProps = {
  data: RawNlqData;
  metrics: WillyMetric[];
};

export const WillySankey: FC<WillySankeyProps> = ({ data, metrics }) => {
  const [firstNode, setFirstNode] = useState<string | undefined>(undefined);
  const [valueMetric, setValueMetric] = useState<string | undefined>(undefined);
  const [productsCount, setProductsCount] = useState<number>(0);
  const [activeLink, setActiveLink] = useState<any>(null);
  const currency = useStoreValue($currency);

  const productsCountOptions = useMemo(
    () => [
      { value: 0, label: '4' },
      { value: 25, label: '6' },
      { value: 50, label: '8' },
      { value: 75, label: '10' },
      { value: 100, label: '12' },
    ],
    [],
  );

  const uniqNodes = useMemo(() => getUniqNodes(data, metrics), [data, metrics]);
  const dataSankey = useMemo(() => {
    const productsRealCount =
      productsCountOptions.find((o) => o.value === productsCount)?.label ?? 4;
    return convertDataToSankey(data, metrics, firstNode, valueMetric, +productsRealCount);
  }, [productsCountOptions, data, metrics, firstNode, valueMetric, productsCount]);

  useEffect(() => {
    setFirstNode(undefined);
    setValueMetric(undefined);
  }, [metrics]);

  useEffect(() => {
    setFirstNode(uniqNodes[0]?.name);
  }, [uniqNodes]);

  const dataNotValidError = useMemo(() => {
    const nan = dataSankey.links.some((l) => isNaN(l.value));
    if (nan) return 'Data is not valid';
  }, [dataSankey.links]);

  useEffect(() => {
    setValueMetric(metrics.find((m) => !m.isDimension)?.key);
  }, [metrics]);

  return (
    <div className="flex flex-col h-full overflow-auto">
      <div className="z-10">
        <Flex pos="absolute" gap="md" align="center" w="100%" p="md" bg="gray.0">
          <Select
            label="Select first Node:"
            data={uniqNodes.map((n) => n.name)}
            value={firstNode}
            onChange={(e) => {
              if (e) setFirstNode(e);
            }}
          />
          <Select
            label="Select metric as value"
            data={metrics
              .filter((m) => !m.isDimension)
              .map((m) => ({ value: m.key, label: m.name }))}
            value={valueMetric}
            onChange={(e) => {
              if (e) setValueMetric(e);
            }}
          />
          <div className="flex flex-col items-start mb-auto">
            <Text size="sm" weight={500}>
              Products count
            </Text>
            <Slider
              label={null}
              value={productsCount}
              step={25}
              marks={productsCountOptions}
              w={200}
              onChange={(e) => setProductsCount(e)}
            />
          </div>
        </Flex>
      </div>
      <div className={`p-10 min-h-[700px] min-w-[800px] z-0`}>
        {!!dataSankey.links.length && !!dataSankey.nodes.length && !dataNotValidError && (
          <ResponsiveContainer width={'100%'} height={'100%'}>
            <Sankey
              width={960}
              height={600}
              data={dataSankey}
              node={<CustomNode metricName={valueMetric} metrics={metrics} currency={currency} />}
              nodePadding={50}
              dataKey={(e) => {
                return e.name;
              }}
              onMouseEnter={(e) => {
                if (e.payload.name) return;
                setActiveLink(e);
              }}
              onMouseLeave={() => {
                setActiveLink(null);
              }}
              margin={{
                left: 100,
                right: 220,
                top: 100,
                bottom: 20,
              }}
              link={<CustomLink activeLink={activeLink} />} //{{ stroke: '#06cdd4' }}
            >
              <Tooltip
                content={
                  <CustomTooltip currency={currency} metrics={metrics} metricName={valueMetric} />
                }
              />
            </Sankey>
          </ResponsiveContainer>
        )}
        {dataNotValidError && (
          <Flex justify="center" align="center" h="100%">
            <Text size="lg" color="red.8">
              {dataNotValidError}
            </Text>
          </Flex>
        )}
      </div>
    </div>
  );
};

const CustomTooltip = (props) => {
  const { payload, currency, metrics, metricName } = props;
  const dataPoint = payload?.[0]?.payload;

  if (!dataPoint) return null;

  let labelFormatter;
  if (dataPoint.payload.name) {
    labelFormatter = dataPoint.payload.name;
  } else {
    const source = dataPoint.payload.source;
    const target = dataPoint.payload.target;
    const value = formatValue(dataPoint.payload.value ?? 0, metricName, metrics, currency);
    labelFormatter = `${source?.name} ➞ ${target?.name}: ${value}`;
  }

  return (
    <Flex p="xs" border={'0.5px solid var(--mantine-color-gray-4)'} bg="white" borderRadius={'4px'}>
      <Text size="xs" color="gray.8">
        {labelFormatter}
      </Text>
    </Flex>
  );
};

const CustomLink = (props) => {
  if (props.payload.isHidden) return null;
  const {
    activeLink,
    sourceY,
    sourceX,
    sourceControlX,
    targetControlX,
    targetY,
    targetX,
    index,
    payload,
    linkWidth,
  } = props;

  return (
    <path
      fillOpacity="0"
      d={`
    M${sourceX},${sourceY}
    C${sourceControlX},${sourceY} ${targetControlX},${targetY} ${targetX},${targetY}
  `}
      stroke={payload.color ?? '#06cdd4'}
      strokeOpacity={activeLink?.index === index ? 1 : 0.6}
      strokeWidth={linkWidth}
    />
  );
};

const CustomNode = (props) => {
  const { x, y, width, height, index, payload, containerWidth, metricName, metrics, currency } =
    props;
  const isOut = x + width + 6 > containerWidth;
  return (
    <Layer key={`CustomNode${index}`}>
      <Rectangle
        x={x}
        y={y}
        width={width}
        height={height}
        fill={payload.color || 'blue'}
        fillOpacity="1"
      />
      <text
        textAnchor={isOut ? 'end' : 'start'}
        x={isOut ? x - 6 : x + width + 6}
        y={y + height / 2}
        fontSize="14"
        strokeWidth={0.5}
        stroke="#374051"
      >
        {payload.name}
      </text>
      <text
        textAnchor={isOut ? 'end' : 'start'}
        x={isOut ? x - 6 : x + width + 6}
        y={y + height / 2 + 13}
        fontSize="12"
        stroke="#333"
        strokeOpacity="0.5"
      >
        {formatValue(payload.value, metricName, metrics, currency)}
      </text>
    </Layer>
  );
};
