import { Button, Card, Icon, Loader, Text } from '@tw/ui-components';
import { ColumnName } from '../types/willyTypes';

import { Column } from '../types/willyTypes';
import { useCanAddTemplate } from '../dashboardManagment/template-management/useCanAddTemplate';
import { LimitedAccessTooltip } from 'components/Nav/components/navs/AlanNav/LimitedAccessTooltip';
import { templateLibraryActions } from 'utils/dataLayer/constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { analyticsEvents } from 'utils/dataLayer/constants';
import { toast } from 'react-toastify';
import { genericEventLogger } from 'utils/dataLayer';
import { copyGlobalColumnToShop } from '../utils/willyUtils';
import _db, { toArray } from 'utils/DB';
import { useStoreValue } from '@tw/snipestate';
import { $shopSequences } from '$stores/willy/$sequences';

type AiColumnsTemplateProps = {
  filteredGlobalAiColumns: Column<ColumnName>[];
  globalAiColumnsLoading: boolean;
};

export const AiColumnsTemplate: React.FC<AiColumnsTemplateProps> = ({
  filteredGlobalAiColumns,
  globalAiColumnsLoading,
}) => {
  return (
    <div className="flex flex-col gap-4">
      {globalAiColumnsLoading ? (
        <div className="flex justify-center py-10">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          {/* grid with 2 columns on mobile and 4 columns on desktop */}
          <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
            {filteredGlobalAiColumns.map((column) => (
              <ColumnTile column={column} key={column.key} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

type ColumnTileProps = {
  column: Column<ColumnName>;
};

const ColumnTile: React.FC<ColumnTileProps> = ({ column }) => {
  const [copyLoading, setCopyLoading] = useState(false);
  const templateAccess = useCanAddTemplate(0);

  const shopSequences = useStoreValue($shopSequences);

  const existInShop = useMemo(() => {
    return shopSequences.some(
      (sequence) =>
        column.sequenceId === sequence.id || column.sequenceId === sequence.globalDashboardId,
    );
  }, [shopSequences, column.sequenceId]);

  const copyToShop = useCallback(async () => {
    setCopyLoading(true);
    await copyGlobalColumnToShop(column);
    setCopyLoading(false);
    genericEventLogger(analyticsEvents.TEMPLATE_LIBRARY, {
      action: templateLibraryActions.ADD_COLUMN_TO_WORKSPACE,
      column_id: column.key,
      column_name: column.label,
    });
    toast.success(`Column added to your shop`);
  }, [column]);

  return (
    <div className="flex flex-col gap-4">
      <div className="border border-solid border-gray-200 rounded bg-white px-6 py-7 flex flex-col justify-between gap-6 cursor-pointer w-full h-full hover:bg-gray-50">
        <div className="flex space-between items-center">
          <Text fz={15} fw={500} truncate="end">
            {column.label}
          </Text>
        </div>
        <Text fz={12} lineClamp={3}>
          {column.description}
        </Text>
        <div className="flex flex-col md:flex-row space-between md:items-center gap-5">
          <div className="flex gap-3 justify-end w-full">
            {!existInShop ? (
              <LimitedAccessTooltip
                accessLimit={templateAccess}
                target={
                  <Button
                    variant="secondary"
                    size="xs"
                    onClick={copyToShop}
                    loading={copyLoading}
                    disabled={templateAccess !== 'can-access'}
                  >
                    Get Column
                  </Button>
                }
              />
            ) : (
              <Button
                size="xs"
                disabled={true}
                variant="secondary"
                leftSection={<Icon name="checkmark" size={14} color="gray.4" />}
              >
                Added
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
