import SECTIONS from 'constants/defaultTileSections';
import { isSectionHide, selectSummarySectionsLayout } from 'ducks/summary';
import { filter, uniqBy } from 'lodash';
import { createSelector } from 'reselect';
import { SummarySection } from 'types/SummarySection';
import { services, ServicesIds, ServicesIdsArray } from '@tw/types/module/services';
import { type RootState } from '../reducers/RootType';
import { safeDivide } from '@tw/stats/module/generalUtils';
import { allActiveOrderSegmentMetrics } from './allActiveOrderSegmentMetrics';
import { metrics } from '../constants/metrics/metrics';
import allServices from 'constants/services';
import { getGeneratedDates } from './generateDates';
import {
  customMetric,
  orderTagsMetric,
  willyMetric,
  shopifySourcesMetric,
  SummaryMetrics,
  BaseSummaryMetric,
} from '@tw/types/module/SummaryMetrics';
import { buildWillyDynamicSectionWidget } from '../components/Willy/utils/willyUtils';
import { MetricsKeys } from '@tw/types';
import { windowSize } from './classes/WindowSizeObserver';
import { getWillyMetric } from '../ducks/willy';
import { hasCredentials, shopProvidersStatus } from 'ducks/shopIntegrations';
import { shopWithSensoryData } from '../ducks/sensoryShop';
import { Providers } from '../types/services';

export const productAnalyticsStats = createSelector(
  [
    (state: RootState) => state.productAnalytics.productsFullDetails,
    (state: RootState) => state.productAnalytics.adStats,
    (state: RootState) => state.mainDatePickerSelectionRange?.start,
    (state: RootState) => state.mainDatePickerSelectionRange?.end,
    (state: RootState) => state.groupStatsBy,
  ],
  (productsFullDetails, adStats, startDate, endDate, granularity) => {
    if (!productsFullDetails.length || !startDate || !endDate) return [];
    const defaultMetrics = {
      clicks: 0,
      conversionRate: 0,
      revenue: 0,
      contributionMargin: 0,
      productCpa: 0,
      cpc: 0,
      cpm: 0,
      ctr: 0,
      grossProfit: 0,
      impressions: 0,
      itemsInInventory: 0,
      itemsSoldTotal: 0,
      pixelVisitors: 0,
      purchases: 0,
      returnRate: 0,
      roas: 0,
      spend: 0,
    };
    const generatedDates = getGeneratedDates(startDate.toString(), endDate.toString(), granularity);
    const generatedBreakdown = generatedDates.map((x) => ({
      date: x,
      metrics: { ...defaultMetrics },
    }));

    const combinedData = productsFullDetails?.map((product) => {
      const adMetrics = adStats?.find(
        (stat) => stat.productId?.toString() === product.id?.toString(),
      );
      const { metrics, ...restProduct } = product;
      const allMetrics = { ...adMetrics, ...metrics };
      const contributionMargin = (allMetrics?.grossProfit || 0) - (allMetrics.spend || 0);

      const metricsBreakdown = generatedBreakdown.map((m) => {
        const currentDateAdMetrics = adMetrics?.metricsBreakdown?.find(
          (x) => x.date === m.date,
        )?.metrics;
        const currentDateProductMetrics = product?.metricsBreakdown?.find(
          (x) => x.date === m.date,
        )?.metrics;
        const currentDateMetrics = {
          ...m.metrics,
          ...currentDateAdMetrics,
          ...currentDateProductMetrics,
        };
        const contributionMarginDate =
          (currentDateMetrics?.grossProfit || 0) - (currentDateMetrics?.spend || 0) || 0;

        return {
          date: m.date,
          metrics: {
            ...currentDateMetrics,
            contributionMargin: contributionMarginDate,
            contributionMarginPerUnit: safeDivide(
              contributionMarginDate,
              currentDateMetrics.itemsSoldTotal || 1,
            ),
            blendedRoas:
              safeDivide(currentDateMetrics?.revenue || 0, currentDateMetrics?.spend || 1) || 0,
            productCpa:
              safeDivide(
                currentDateMetrics?.spend || 0,
                currentDateMetrics?.ordersWithProduct || 1,
              ) || 0,
            blendedNewCustomerRoas:
              safeDivide(currentDateMetrics?.ncRevenue, currentDateMetrics?.spend) || 0,
            blendedNcCpa:
              safeDivide(currentDateMetrics?.spend, currentDateMetrics?.ncOrdersWithProduct) || 0,
          },
        };
      });

      return {
        ...restProduct,
        numberOfAds: allMetrics.numberOfAds,
        metrics: {
          ...allMetrics,
          blendedRoas: safeDivide(allMetrics?.revenue, allMetrics?.spend),
          contributionMargin,
          contributionMarginPerUnit: safeDivide(
            contributionMargin,
            allMetrics?.itemsSoldTotal || 1,
          ),
          productCpa: safeDivide(allMetrics.spend, allMetrics?.ordersWithProduct),
          blendedNewCustomerRoas: safeDivide(allMetrics?.ncRevenue, allMetrics.spend),
          blendedNcCpa: safeDivide(allMetrics.spend, allMetrics?.ncOrdersWithProduct),
        },
        metricsBreakdown,
      };
    });
    try {
      if (window.location.href.indexOf('debug') > -1) console.log(combinedData);
    } catch (error) {}
    return [...combinedData];
  },
);
export const productSkusStats = createSelector(
  [
    (state: RootState) => state.productAnalytics.skusFullDetails,
    (state: RootState) => state.productAnalytics.productSkusAdStats,
    (state: RootState) => state.mainDatePickerSelectionRange?.start,
    (state: RootState) => state.mainDatePickerSelectionRange?.end,
    (state: RootState) => state.groupStatsBy,
  ],
  (skusFullDetails, adStats, startDate, endDate, granularity) => {
    if (!skusFullDetails.length || !startDate || !endDate) return [];
    const defaultMetrics = {
      clicks: 0,
      conversionRate: 0,
      revenue: 0,
      contributionMargin: 0,
      productCpa: 0,
      cpc: 0,
      cpm: 0,
      ctr: 0,
      grossProfit: 0,
      impressions: 0,
      itemsInInventory: 0,
      itemsSoldTotal: 0,
      pixelVisitors: 0,
      purchases: 0,
      returnRate: 0,
      roas: 0,
      spend: 0,
    };
    const generatedDates = getGeneratedDates(startDate.toString(), endDate.toString(), granularity);
    const generatedBreakdown = generatedDates.map((x) => ({
      date: x,
      metrics: { ...defaultMetrics },
    }));

    const combinedData = skusFullDetails?.map((product) => {
      const adMetrics = adStats?.find(
        (stat) => stat.productId?.toString() === product.id?.toString(),
      );
      const { metrics, ...restProduct } = product;
      const contributionMargin = (metrics?.grossProfit || 0) - (adMetrics?.spend || 0);

      const metricsBreakdown = generatedBreakdown.map((m) => {
        const currentDateAdMetrics = adMetrics?.metricsBreakdown?.find(
          (x) => x.date === m.date,
        )?.metrics;
        const currentDateProductMetrics = product?.metricsBreakdown?.find(
          (x) => x.date === m.date,
        )?.metrics;
        const contributionMarginDate =
          (currentDateProductMetrics?.grossProfit || 0) - (currentDateAdMetrics?.spend || 0) || 0;

        return {
          date: m.date,
          metrics: {
            ...m.metrics,
            ...currentDateProductMetrics,
            ...currentDateAdMetrics,
            contributionMargin: contributionMarginDate,
            contributionMarginPerUnit: safeDivide(
              contributionMarginDate,
              m.metrics.itemsSoldTotal || 1,
            ),
            blendedRoas:
              safeDivide(
                currentDateProductMetrics?.revenue || 0,
                currentDateAdMetrics?.spend || 1,
              ) || 0,
            productCpa:
              safeDivide(
                currentDateAdMetrics?.spend || 0,
                currentDateProductMetrics?.ordersWithProduct || 1,
              ) || 0,
            blendedNewCustomerRoas: safeDivide(metrics?.ncRevenue, adMetrics?.spend) || 0,
            blendedNcCpa: safeDivide(adMetrics?.spend, metrics?.ncOrdersWithProduct) || 0,
          },
        };
      });

      return {
        ...restProduct,
        numberOfAds: adMetrics?.numberOfAds,
        metrics: {
          ...metrics,
          ...adMetrics,
          blendedRoas: safeDivide(metrics?.revenue, adMetrics?.spend),
          contributionMargin,
          contributionMarginPerUnit: safeDivide(contributionMargin, metrics?.itemsSoldTotal || 1),
          productCpa: safeDivide(adMetrics?.spend, metrics?.ordersWithProduct),
          blendedNewCustomerRoas: safeDivide(metrics?.ncRevenue, adMetrics?.spend),
          blendedNcCpa: safeDivide(adMetrics?.spend, metrics?.ncOrdersWithProduct),
        },
        metricsBreakdown,
      };
    });
    try {
      if (window.location.href.indexOf('debug') > -1) console.log(combinedData);
    } catch (error) {}
    return [...combinedData];
  },
);
export const comparisonAnalyticsStats = createSelector(
  [
    (state: RootState) => state.productAnalytics.comparisonsFullDetails as any,
    (state: RootState) => state.productAnalytics.comparisonAdStats,
    (state: RootState) => state.mainDatePickerSelectionRange?.start,
    (state: RootState) => state.mainDatePickerSelectionRange?.end,
    (state: RootState) => state.groupStatsBy,
    (state: RootState) => state.datesToCompare,
  ],
  (productsFullDetails, adStats, startDate, endDate, granularity, datesToCompare) => {
    if (!productsFullDetails.length || datesToCompare.id === 'none' || !startDate || !endDate)
      return [];
    const defaultMetrics = {
      clicks: 0,
      conversionRate: 0,
      revenue: 0,
      contributionMargin: 0,
      productCpa: 0,
      cpc: 0,
      cpm: 0,
      ctr: 0,
      grossProfit: 0,
      impressions: 0,
      itemsInInventory: 0,
      itemsSoldTotal: 0,
      pixelVisitors: 0,
      purchases: 0,
      returnRate: 0,
      roas: 0,
      spend: 0,
    };

    const generatedDates = getGeneratedDates(startDate.toString(), endDate.toString(), granularity);
    const generatedBreakdown = generatedDates.map((x) => ({
      date: x,
      metrics: { ...defaultMetrics },
    }));

    const combinedData = productsFullDetails?.map((product) => {
      const adMetrics = adStats?.find(
        (stat) => stat.productId?.toString() === product.id?.toString(),
      );
      const { metrics, ...restProduct } = product;
      const allMetrics = { ...adMetrics, ...metrics };

      const contributionMargin = (allMetrics?.grossProfit || 0) - (allMetrics?.spend || 0);

      const metricsBreakdown = generatedBreakdown.map((m) => {
        const currentDateAdMetrics = adMetrics?.metricsBreakdown?.find(
          (x) => x.date === m.date,
        )?.metrics;
        const currentDateProductMetrics = product?.metricsBreakdown?.find(
          (x) => x.date === m.date,
        )?.metrics;
        const currentDateMetrics = {
          ...m.metrics,
          ...currentDateAdMetrics,
          ...currentDateProductMetrics,
        };
        const contributionMarginDate =
          (currentDateMetrics?.grossProfit || 0) - (currentDateMetrics?.spend || 0) || 0;
        return {
          date: m.date,
          metrics: {
            ...currentDateMetrics,
            contributionMargin: contributionMarginDate,
            contributionMarginPerUnit: safeDivide(
              contributionMarginDate,
              m.metrics.itemsSoldTotal || 1,
            ),
            blendedRoas:
              safeDivide(currentDateMetrics?.revenue || 0, currentDateMetrics?.spend || 1) || 0,
            productCpa:
              safeDivide(
                currentDateMetrics?.spend || 0,
                currentDateMetrics?.ordersWithProduct || 1,
              ) || 0,
            blendedNewCustomerRoas:
              safeDivide(currentDateMetrics?.ncRevenue, currentDateMetrics?.spend) || 0,
            blendedNcCpa:
              safeDivide(currentDateMetrics?.spend, currentDateMetrics?.ncOrdersWithProduct) || 0,
          },
        };
      });

      return {
        ...restProduct,
        metrics: {
          ...allMetrics,
          contributionMargin,
          contributionMarginPerUnit: safeDivide(
            contributionMargin,
            allMetrics?.itemsSoldTotal || 1,
          ),
          roas: safeDivide(allMetrics?.revenue, allMetrics?.spend) || 0,
          productCpa: safeDivide(allMetrics?.spend, allMetrics?.ordersWithProduct) || 0,
          cpc: safeDivide(allMetrics?.spend, allMetrics?.clicks) || 0,
          cpm: safeDivide(allMetrics?.spend, allMetrics?.impressions) * 1000,
          ctr: safeDivide(allMetrics?.clicks, allMetrics?.impressions) || 0,
        },
        metricsBreakdown,
      };
    });
    return [...combinedData];
  },
);

export const selectCustomMetrics = (state: RootState) => state.customMetrics.metrics;
export const selectWillySummaryBox = (state: RootState) => state.willySummaryBox;
export const selectWillySummaryWidget = createSelector(
  [(state: RootState) => state.willySummaryWidget],
  (willySummaryWidget) => buildWillyDynamicSectionWidget(willySummaryWidget),
);
export const selectWillyTempMetrics = (state: RootState) => state.willyTempSummaryBox;

const emptyObj = {};
const emptyArr = [];
const selectFalse = () => false;

export const selectSummaryPinnedSectionTiles = (state: RootState) =>
  state.summarySectionsCustomization?.pinned?.tiles || emptyArr;

export const selectAllMetrics = createSelector<any, BaseSummaryMetric<any>[]>(
  [
    (state: RootState) => state.orderTags,
    (state: RootState) => state.shopifySegmentSources,
    (state: RootState) => state.shopifySourcesDictionaryIdToName,
    selectCustomMetrics,
    (state: RootState) => state.externalApps?.externalMetrics || [],
    selectWillySummaryBox,
    selectWillyTempMetrics,
  ],
  (
    orderTags: any[],
    shopifySources: any[],
    shopifySourcesDictionaryIdToName: any[],
    customMetrics: any[],
    externalMetrics: any[],
    willySummaryBox: any[],
    willyTempSummaryBox: any[],
  ) => {
    const shopifySourcesTranslate = shopifySources.map((source) => ({
      ...source,
      title: shopifySourcesDictionaryIdToName[source.id],
    }));
    const orderTagsMetrics = orderTags.map(orderTagsMetric);
    const shopifySourcesMetrics = shopifySourcesTranslate.map(shopifySourcesMetric);
    const uniqueOrderTags = uniqBy(orderTagsMetrics, 'id');
    const uniqueSources = uniqBy(shopifySourcesMetrics, 'id');
    const customMetricsFormatted = customMetrics.map(customMetric);
    const willyMetrics = willySummaryBox
      .concat(willyTempSummaryBox ?? [])
      .map((m) => ({
        id: m.id,
        title: m.title,
        description: m.title,
        valueToFixed: getWillyMetric(m)?.toFixed,
        format: getWillyMetric(m)?.format,
      }))
      .map(willyMetric);

    return Object.values(SummaryMetrics).concat(
      uniqueOrderTags,
      uniqueSources,
      customMetricsFormatted,
      externalMetrics,
      willyMetrics,
    );
  },
);

export const selectConnectedSensory = createSelector(
  [
    (x: RootState) => x.sensory.sensoryIntegrations,
    (x: RootState) => x.sensory.sensorySupportedProviders,
  ],
  (sensoryIntegrations, sensorySupportedProviders) => {
    return sensorySupportedProviders.filter(
      (providerId) =>
        !!sensoryIntegrations.find(
          (x: any) =>
            x.provider_id === providerId && !['deleted', 'disconnected'].includes(x.status),
        ),
    );
  },
);

export const selectConnectedServices = createSelector(
  [
    shopProvidersStatus,
    (x: RootState) => !!x.isAmazonConnectedNA || !!x.isAmazonConnectedEU || !!x.isAmazonConnectedFE,
    (x: RootState) => !!x.hasPixelInstalled,
    (x: RootState) => !!x?.internalGradualReleaseFeatures?.forecasting,
  ],
  (shopProvidersStatus, AMAZON: boolean, pixel: boolean, FORECASTING: boolean) => ({
    ...ServicesIdsArray.reduce(
      (acc, providerId) => ({
        ...acc,
        [providerId]: hasCredentials(shopProvidersStatus[providerId]),
      }),
      {},
    ),
    SHOPIFY: true,
    shopify: true,
    FACEBOOK: hasCredentials(shopProvidersStatus['facebook-ads']),
    GOOGLE_ADS: hasCredentials(shopProvidersStatus['google-ads']),
    GOOGLE_ANALYTICS: hasCredentials(shopProvidersStatus['google-analytics']),
    TIKTOK: hasCredentials(shopProvidersStatus['tiktok-ads']),
    SNAPCHAT: hasCredentials(shopProvidersStatus['snapchat-ads']),
    KLAVIYO: hasCredentials(shopProvidersStatus['klaviyo']),
    GORGIAS: hasCredentials(shopProvidersStatus['gorgias']),
    PINTEREST: hasCredentials(shopProvidersStatus['pinterest-ads']),
    ENQUIRELABS: hasCredentials(shopProvidersStatus['enquirelabs']),
    KNO: hasCredentials(shopProvidersStatus['kno']),
    TWITTER: hasCredentials(shopProvidersStatus['twitter-ads']),
    SLACK: hasCredentials(shopProvidersStatus['slack']),
    // [ALL_CHANNELS_ID]: true,
    amazon: AMAZON,
    pixel: true,
    forecasting: FORECASTING,
    stripe: true,
    'triple-whale': true,
  }),
);

const mspRelatedSections = (
  allSections,
  msp,
  orderTags,
  shopifySegmentSources,
): SummarySection[] => {
  let sectionsLocal = allSections.filter((sec) => !sec.msp || sec.msp.includes(msp));

  if (msp === 'shopify') {
    const salesBySegmentIndex = sectionsLocal.findIndex((x) => x.id === 'salesBySegment');
    const segmentTiles = orderTags
      .concat(shopifySegmentSources)
      .map((x) => String(x.original || x.id).trim());
    if (salesBySegmentIndex > -1) {
      sectionsLocal[salesBySegmentIndex] = {
        ...sectionsLocal[salesBySegmentIndex],
        tiles: [...new Set(segmentTiles)],
      };
    }
  }
  return sectionsLocal;
};

export const selectMspRelatedSections = createSelector(
  [() => SECTIONS, (x) => x.msp, (x) => x.orderTags, (x) => x.shopifySegmentSources],
  mspRelatedSections,
);

export const formatSections = (
  summarySectionsLayout,
  allSections,
  customMetrics,
  selectWillyMetrics,
  selectWillyWidgets,
  connectedServices,
  externalAppSections: any = [],
  providers: any = [],
): SummarySection[] => {
  let sectionsLocal = allSections
    .concat(externalAppSections)
    .concat(selectWillyWidgets)
    .filter((sec) => !sec.mobileOnly || windowSize.isSmall)
    .filter(
      (sec) =>
        !isSectionHide(summarySectionsLayout, connectedServices, {
          sectionId: sec.id,
          sections: allSections,
        }),
    )
    .filter((sec) => {
      if (sec.isSensoryAssetType) {
        const providerId = sec?.services?.[0];
        const assetType = sec.dataType;
        const providerData = providers.find(
          (p) => p.id === providerId && !!p.asset_types?.find((x) => x.id === assetType),
        );
        return !!providerData?.integrations?.some((int) =>
          int.extra_params?.asset_types?.includes(assetType),
        );
      } else return true;
    });

  const customMetricsIndex = sectionsLocal.findIndex((x) => x.id === 'customMetrics');
  const customMetricsTiles = customMetrics.map((x) => x.id);
  const willyTiles = selectWillyMetrics.map((x) => x.id);
  if (customMetricsIndex > -1) {
    sectionsLocal[customMetricsIndex] = {
      ...sectionsLocal[customMetricsIndex],
      tiles: [...sectionsLocal[customMetricsIndex].tiles, ...customMetricsTiles, ...willyTiles],
    };
  }

  sectionsLocal = sectionsLocal.sort((s1, s2) => {
    let h1 = summarySectionsLayout[s1.id] && summarySectionsLayout[s1.id].index;
    let h2 = summarySectionsLayout[s2.id] && summarySectionsLayout[s2.id].index;
    // sort by default order if there is no previous custom ordering and no "index"
    // pop new sections to top if they have no "index"
    // otherwise sort by index
    return isNaN(h1) && !SECTIONS.find((s) => s.id === s1.id)
      ? -1
      : isNaN(h2) && !SECTIONS.find((s) => s.id === s2.id)
        ? 1
        : h1 - h2;
  });

  return sectionsLocal;
};

const selectUniqueBasicSections = createSelector(selectMspRelatedSections, (sections) =>
  sections.filter((sec) => sec.allowToPickForCustomSection),
);

export const selectAllBasicSections = createSelector(
  [
    selectSummarySectionsLayout,
    selectMspRelatedSections,
    (state: RootState) => state.customSections,
  ],
  (summarySectionsLayout, originalSections, customSections) => {
    const sectionsToReturn: SummarySection[] = originalSections.map((sec) => ({
      ...sec,
      tiles: sec.id === 'pinned' ? summarySectionsLayout['pinned']?.tiles || [] : sec.tiles,
    }));

    return sectionsToReturn.filter((s) => s.types.includes('summary')).concat(customSections);
  },
);

export const selectAllSections = createSelector(
  [
    selectSummarySectionsLayout,
    selectAllBasicSections,
    selectCustomMetrics,
    selectWillySummaryBox,
    selectWillySummaryWidget,
    selectConnectedServices,
    (x: RootState) => Object.values(x.externalApps?.externalSections || {}),
    (x: RootState) => x.sensory.providers,
  ],
  formatSections,
);

const selectReportPixelMetrics = createSelector<any, any>(
  [() => Object.values(metrics)],
  (metrics) => metrics.filter((metric) => metric.type === 'pixel' && metric.showInReport !== false),
);

export const selectReportPixelConnectedServices = createSelector<any, any>(
  [
    selectReportPixelMetrics,
    (state: RootState) => state.hasPixelInstalled,
    selectConnectedServices,
    () => Object.values(allServices).filter((s) => s.type === 'ads' || s.type === 'email'),
  ],
  (pixelMetrics, hasPixel, connectedServices, relevantServices) => {
    if (hasPixel) {
      const pixelSection = SECTIONS.find((s) => s.id === 'pixel');
      return {
        ...pixelSection,
        services: relevantServices
          .filter((service) => connectedServices[service.id])
          .map((service) => ({
            ...service,
            pixelMetrics,
          })),
      };
    } else {
      return [];
    }
  },
);

export const selectUniqueSections = createSelector<any, any>(
  [
    selectFalse,
    selectUniqueBasicSections,
    selectCustomMetrics,
    selectWillySummaryBox,
    selectWillySummaryWidget,
    selectConnectedServices,
    (x: RootState) => Object.values(x.externalApps?.externalSections || {}),
  ],
  formatSections,
);

export const selectUniqueSectionsWithMetrics = createSelector<any, any>(
  [selectUniqueSections, selectAllMetrics],
  (sections, metrics) => {
    return sections.map((section) => ({
      ...section,
      tiles: (section.tiles || [])
        .concat(section.hiddenTiles || [])
        ?.map((tile) => metrics.find((metric) => metric.id === tile))
        .filter((x) => x),
    }));
  },
);

/**
 * tiktok token contains scopes. 4 is general.
 * we need 220 and 6 roles in order to fetch creative data
 */
export const selectIsTiktokHasCreativeRoles = createSelector(
  [shopWithSensoryData],
  (shopWithSensory) => {
    const tiktokAccessToken = services['tiktok-ads'].getAccessToken?.(shopWithSensory);
    if (!tiktokAccessToken) {
      return false;
    }
    try {
      const token = JSON.parse(tiktokAccessToken);
      return token.data.scope.includes(220) && token.data.scope.includes(6);
    } catch (e) {
      console.error(e);
      return false;
    }
  },
);

export const servicesWithEnabledAdSegmentation = createSelector(
  [(state: RootState) => state.adSegmentations.segments],
  (segments) => {
    return Object.entries(segments)
      .filter(([service, segments]) => Object.values(segments).some((s) => s.enabled))
      .map(([a]) => a);
  },
);

//TODO now supports only orderTags and sources need to expend it to support all kind
export const selectAllActiveOrderSegmentMetrics = createSelector(
  [
    (state: RootState) => state.msp,
    (state: RootState) => state.customMetrics,
    (state: RootState) => state.summarySectionsCustomization,
    (state: RootState) => state.orderTags,
    (state: RootState) => state.shopifySegmentSources,
    (state: RootState) => state.shopify.loadingSegments,
    (state: RootState) => state.loadingCustomMetrics,
  ],
  (
    msp,
    customMetrics,
    summarySectionsCustomization,
    orderTags,
    shopifySegmentSources,
    loadingSegments,
    loadingCustomMetrics,
  ) => {
    return allActiveOrderSegmentMetrics(
      msp,
      customMetrics,
      summarySectionsCustomization,
      orderTags,
      shopifySegmentSources,
      loadingSegments,
      loadingCustomMetrics,
    );
  },
);

export const selectHasSomePpsInstalled = createSelector<any, boolean>(
  [selectConnectedServices, (state: RootState) => state.shop],
  (connectedServices, shopDoc) => {
    const hasTwPps = !!shopDoc?.twSurvey?.isInstalled;
    const hasKnoPps = !!connectedServices.kno;
    const hasFairingPps = !!connectedServices.enquirelabs;

    return hasTwPps || hasKnoPps || hasFairingPps;
  },
);

export type ActiveProvidersAndMetricsForNexus = {
  loading: boolean;
  providers?: ServicesIds[];
  metrics?: MetricsKeys[];
};
