import {
  DragEndEvent,
  DragOverEvent,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { useCallback, useState, useEffect, FC } from 'react';
import { WillyDashboardElement, WillyFieldElement, WillyWidgetElement } from '../types/willyTypes';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { getMainElementDbRef } from '../utils/willyUtils';
import { useDashLayout } from './useDashLayout';
// import { useTraceUpdate } from 'utils/useTraceUpdate';

type WillyDashDndProps = {
  dashboard?: WillyDashboardElement;
  widgets: WillyWidgetElement[];
  fields: WillyFieldElement[];
  layout: ReactGridLayout.Layout[];
  setLayout: React.Dispatch<React.SetStateAction<ReactGridLayout.Layout[]>>;
};

export const useWillyDashDnd = ({
  dashboard,
  widgets,
  fields,
  layout,
  setLayout,
}: WillyDashDndProps) => {
  const [items, setItems] = useState<(WillyFieldElement | (WillyWidgetElement & { id: string }))[]>(
    [],
  );
  const [activeId, setActiveId] = useState<string | null>(null);
  const { fieldLayout, widgetLayout } = useDashLayout();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor, { activationConstraint: { delay: 250, tolerance: 5 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      const activeId: any = active?.id;
      const overId: any = over?.id;

      if (!overId || activeId === overId) {
        return;
      }

      const oldIndex = items.findIndex((i) => i.id === activeId);
      const newIndex = items.findIndex((i) => i.id === overId);
      const newArray = arrayMove(items, oldIndex, newIndex);

      setItems(newArray);
      setActiveId(null);

      if (newArray.length <= 0) return;

      const reorderedLayout = newArray.map((item, i) => {
        return (
          (Object.hasOwnProperty.call(item, 'queryId')
            ? widgetLayout(item as WillyWidgetElement, i)['data-grid']
            : fieldLayout(item as WillyFieldElement))['data-grid'] ?? {
            id: item.id,
            i: item.id,
            x: 0,
            y: 17 * i,
            w: 12,
            h: 17,
          }
        );
      });

      if (reorderedLayout.some((l) => l === undefined)) return;

      getMainElementDbRef(dashboard!).set(
        { layout: JSON.stringify(reorderedLayout) },
        { merge: true },
      );
      setLayout(reorderedLayout);
    },
    [dashboard, fieldLayout, items, setLayout, widgetLayout],
  );

  useEffect(() => {
    setItems(
      [
        ...fields,
        ...widgets
          .filter((w) => {
            return !w.isProviderNotConnected && !w.isProviderLocked;
          })
          .map((w) => ({ id: w.queryId, ...w })),
      ].sort((a, b) => {
        const aLayout = layout.find((l) => l.i === a.id);
        const bLayout = layout.find((l) => l.i === b.id);
        if (!aLayout || !bLayout) return 0;
        return aLayout.y - bLayout.y || aLayout.x - bLayout.x;
      }),
    );
  }, [fields, layout, widgets]);
  // useTraceUpdate({
  //   fields,
  //   layout,
  //   widgets,
  // });

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id.toString());
  }, []);

  const handleDragOver = useCallback(
    (event: DragOverEvent) => {
      const { active, over } = event;
      const activeId: any = active?.id;
      const overId: any = over?.id;

      if (!overId || activeId === overId) {
        return;
      }
      const oldIndex = items.findIndex((i) => i.id === activeId);
      const newIndex = items.findIndex((i) => i.id === overId);
      setItems(arrayMove(items, oldIndex, newIndex));
    },
    [items, setItems],
  );

  return {
    items,
    activeId,
    sensors,
    handleDragEnd,
    handleDragStart,
    handleDragOver,
  };
};
