import React, { useEffect, useRef, useState } from 'react';
import { Button, Textarea, Flex, Text, Icon, Popover } from '@tw/ui-components';
import { analyticsEvents, feedbackActions, genericEventLogger } from 'utils/dataLayer';
import { $isIntercomOpen, $isIntercomVisible } from 'utils/intercom';
import { $derived, $store, useStoreValue } from '@tw/snipestate';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useLocation } from 'react-router-dom';

const $_isFeedbackVisible = $store(true);
export const $isFeedbackVisible = $derived((get) => get($_isFeedbackVisible));
export const toggleFeedbackVisibility = (show: boolean) => {
  $_isFeedbackVisible.set(show);
};

const $_isFromNav = $store(false);
export const $isFromNav = $derived((get) => {
  const value = get($_isFromNav);
  return value;
});
export const setFromNav = (isFromNav: boolean) => {
  $_isFromNav.set(isFromNav);
};

export const FeedbackButton = () => {
  const isFeedbackVisible = useStoreValue($isFeedbackVisible);
  const isFromNav = useStoreValue($isFromNav);
  const location = useLocation();
  const [buttonWidth, setButtonWidth] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [needsSupport, setNeedsSupport] = useState(false);
  const pathname = location.pathname;
  const isIntercomVisible = useStoreValue($isIntercomVisible);
  const source = $_isFromNav ? 'Nav Button' : 'Floating Button';
  const isIntercomOpen = useStoreValue($isIntercomOpen);
  const offsetY = isIntercomVisible ? 80 : 80;
  const offsetX = isIntercomVisible
    ? isIntercomOpen
      ? `calc(400px  + ${buttonWidth / 2}px)`
      : `calc(80px + 24px)`
    : '24px';
  const MaxCharacters = 500;
  const characterCount = feedback.length;
  const isSmall = useIsSmall();
  const isNavigatingRef = useRef(false);
  const getCurrentSource = () => ($_isFromNav.get() ? 'Nav Button' : 'Floating Button');

  useEffect(() => {
    if (isFeedbackVisible) {
      if (isFromNav) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    } else {
      setIsOpen(false);
    }
  }, [isFeedbackVisible, isFromNav]);

  useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
      isNavigatingRef.current = true;
      $_isFromNav.set(false);
    }
    // Intentionally omitting `isOpen` to run this effect only on `pathname` change.
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    if (isFeedbackVisible && isFromNav) {
      genericEventLogger(analyticsEvents.FEEDBACK, {
        action: feedbackActions.UNHIDE_FEEDBACK_BUTTON,
        source: source,
        path: pathname,
      });

      genericEventLogger(analyticsEvents.FEEDBACK, {
        action: feedbackActions.OPEN_FEEDBACK_FORM,
        source: source,
        path: pathname,
      });
    }
  }, [isFeedbackVisible, isFromNav, pathname, source]);

  if (!isFeedbackVisible || isSmall) {
    return null;
  }

  const handleHide = () => {
    $_isFromNav.set(false);
    const currentSource = getCurrentSource();
    genericEventLogger(analyticsEvents.FEEDBACK, {
      action: feedbackActions.HIDE_FEEDBACK_BUTTON,
      source: currentSource,
      path: pathname,
    });
    setIsOpen(false);
    setFeedback('');
    setSubmitted(false);
    setNeedsSupport(false);
    toggleFeedbackVisibility(false);
  };

  const handleOpen = () => {
    $_isFromNav.set(false);
    const currentSource = getCurrentSource();
    genericEventLogger(analyticsEvents.FEEDBACK, {
      action: feedbackActions.BUTTON_CLICK,
      source: currentSource,
      path: pathname,
    });
    setFeedback('');
    setSubmitted(false);
    setNeedsSupport(false);
    setIsOpen(true);
    isNavigatingRef.current = false;
  };

  const handleClose = () => {
    $_isFromNav.set(false);
    const currentSource = getCurrentSource();
    genericEventLogger(analyticsEvents.FEEDBACK, {
      action: feedbackActions.CLOSE_FEEDBACK_FORM,
      source: currentSource,
      path: pathname,
    });
    setIsOpen(false);
    setNeedsSupport(false);
  };

  const handleSubmit = () => {
    $_isFromNav.set(false);
    genericEventLogger(analyticsEvents.FEEDBACK, {
      action: feedbackActions.SUBMISSION,
      content: feedback,
      start_a_chat_with_us: needsSupport,
      path: pathname,
    });
    setSubmitted(true);
    setTimeout(() => {
      setIsOpen(false);
      setNeedsSupport(false);
    }, 2000);
  };

  // const handleSupportChange = () => {
  //   const newNeedsSupport = !needsSupport;
  //   setNeedsSupport(newNeedsSupport);

  //   if (newNeedsSupport && window.Intercom) {
  //     const message = feedback.trim()
  //       ? `Hi! I'm reaching out regarding: "${feedback}". Can you assist me with this?`
  //       : 'Hi! I need assistance. Can you help me?';
  //     window.Intercom('showNewMessage', message);
  //     setIsOpen(false);
  //     setNeedsSupport(false);
  //   }
  // };

  return (
    <>
      {/* Feedback Button */}
      <Popover
        opened={isOpen}
        onChange={(open) => {
          if (!open) return;
          setIsOpen(open);
        }}
        position="bottom"
        shadow="md"
      >
        <Popover.Target>
          <div
            className="fixed z-[1000]"
            style={{
              bottom: '24px',
              right: offsetX,
              transition: 'right 0.1s ease-out',
            }}
          >
            <Button
              ref={(el) => {
                if (!el?.offsetWidth) return;
                setButtonWidth(el.offsetWidth);
              }}
              onClick={(e) => {
                const target = e.target as Element;
                if (!target.closest('.close-icon')) {
                  handleOpen();
                }
              }}
              variant="activator"
              size="md"
              radius="round"
              style={{
                border: '2px solid var(--Gray-Light-Mode-200, #E5E7EB)',
                boxShadow: `0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)`,
                color: 'var(--primary-600-Brand, #0C70F2)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '8px 16px',
                gap: '10px',
              }}
            >
              <span className="text-blue-500" style={{ marginRight: '10px' }}>
                Feedback
              </span>
              <span
                className="close-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  handleHide();
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '20px',
                  height: '20px',
                  textAlign: 'center',
                }}
              >
                <Icon name="close" size={10} color="named.6" />
              </span>
            </Button>
          </div>
        </Popover.Target>

        <Popover.Dropdown>
          {isOpen && (
            <div
              className="fixed z-[1000] rounded-xl bg-[var(--mantine-color-named-2)] w-[430px] min-h-[425px] shadow-lg"
              style={{
                border: '1px solid #E5E7EB',
                borderRadius: '12px',
                boxShadow:
                  '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)',
                bottom: `${offsetY}px`,
                right: offsetX,
              }}
            ></div>
          )}
        </Popover.Dropdown>
      </Popover>

      {isOpen && (
        <div
          className="fixed z-[1000] rounded-xl bg-[var(--mantine-color-named-2)] w-[430px] min-h-[425px] shadow-lg"
          style={{
            border: '1px solid #E5E7EB',
            borderRadius: '12px',
            boxShadow:
              '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)',
            bottom: `${offsetY}px`,
            right: offsetX,
          }}
        >
          {' '}
          <Flex justify="space-between" align="center" px="lg" py="md">
            <Text fz="lg" fw={600} c="named.9">
              Share Your Feedback
            </Text>
            <Button
              onClick={handleClose}
              variant="white"
              size="sm"
              c="gray.4"
              style={
                {
                  '--button-bd': 'none',
                  background: 'transparent',
                  outline: 'none',
                  boxShadow: 'none',
                  width: '20px',
                  height: '20px',
                  padding: '0',
                } as React.CSSProperties
              }
            >
              <span
                style={{
                  fontSize: '20px',
                  lineHeight: '20px',
                  display: 'block',
                  textAlign: 'center',
                  width: '20px',
                  height: '20px',
                }}
              >
                <Icon name="close" size={13} color="gray.4" />
              </span>
            </Button>
          </Flex>
          <Flex w="100%" h="1px" bg="gray.2" />
          {!submitted ? (
            <>
              <Flex direction="column" px="lg" pb="md">
                <Text fz="sm" fw={600} lh="sm" mb="sm" mt="lg">
                  Your feedback shapes our product!
                </Text>
                <Textarea
                  placeholder="Write your feedback here"
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  radius="md"
                  minRows={8}
                  autosize
                  autoFocus
                />
                <Flex direction="row" justify="flex-end" align="center" mt="sm">
                  {/* <Checkbox
                    label="Get support now"
                    checked={needsSupport}
                    onChange={handleSupportChange}
                  /> */}
                  <Text fz="sm" c={characterCount > MaxCharacters ? 'red.5' : 'gray.6'}>
                    {characterCount}/{MaxCharacters}
                  </Text>
                </Flex>
              </Flex>

              <Flex w="100%" h="1px" bg="gray.2" mb="md" />

              <Flex px="lg">
                <Button
                  onClick={handleSubmit}
                  disabled={!feedback.trim() || characterCount > MaxCharacters}
                  variant="primary"
                  size="md"
                  w="100%"
                >
                  Submit Feedback
                </Button>
              </Flex>
            </>
          ) : (
            <>
              <div className="py-[85px]">
                <Flex direction="column" align="center">
                  <Icon name="circle-check" size={48} color="green.5" />
                  <Text fz="md" fw={500} c="black" lh="sm" mb="xs" mt="md">
                    Thank you!
                  </Text>
                  <Text fz="sm" fw={400} c="black" lh="sm" mb="md" ta="center">
                    Your feedback has been successfully submitted.
                  </Text>
                </Flex>
              </div>
              <Flex w="100%" h="1px" bg="gray.2" />
            </>
          )}
        </div>
      )}
    </>
  );
};
