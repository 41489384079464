import { useEffect, useMemo, useState, useRef } from 'react';
import { genericEventLogger, analyticsEvents, summaryActions } from 'utils/dataLayer';
import { getSocket } from '../WillySocket';
import { useCurrentPopoverOpenerButtonTitle } from 'components/useGetPopoverOpenerButtonTitle';

type GeneralInsightsProps = {
  title: string;
  data: any;
  messageId: string;
};

export const useGeneralInsights = ({ title, data, messageId }: GeneralInsightsProps) => {
  const willySocket = useMemo(() => getSocket(), []);
  const currentPopoverLabel = useCurrentPopoverOpenerButtonTitle();
  const [generalInsights, setGeneralInsights] = useState<string>('');
  const [generalInsightsError, setGeneralInsightsError] = useState<string | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (!data || data.length <= 0 || generalInsights.length > 0) {
      return;
    }

    setGeneralInsightsError(null);

    timeoutRef.current = setTimeout(() => {
      setGeneralInsightsError('Request timed out. Please try again.');
    }, 10000); // 10 second timeout

    genericEventLogger(analyticsEvents.SUMMARY, {
      action: summaryActions.GENERATE_GENERAL_INSIGHTS,
      title,
      messageId,
    });

    willySocket.emit('explain-general', {
      title,
      data: JSON.stringify(data),
      dateRange: currentPopoverLabel,
      messageId,
    });

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [currentPopoverLabel, data, generalInsights.length, messageId, title, willySocket]);

  useEffect(() => {
    const handleGeneralInsight = (data: any) => {
      if (data.messageId !== messageId) {
        return;
      }

      if (data.type === 'chunk' && data.text) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        setGeneralInsights((prevInsights) => `${prevInsights}${data.text}`);
      } else if (data.type === 'error') {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        setGeneralInsightsError(
          'Something went wrong while generating insights. Please try again.',
        );
      }
    };

    willySocket.on('general-insight', handleGeneralInsight);

    // Handle socket disconnection
    willySocket.on('disconnect', () => {
      setGeneralInsightsError('Lost connection to server. Please try again.');
    });

    return () => {
      willySocket.off('general-insight', handleGeneralInsight);
      willySocket.off('disconnect');
    };
  }, [messageId, willySocket]);

  return {
    generalInsights,
    generalInsightsError,
  };
};
