import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/RootType';
import { ActionIcon, Flex, Icon, Menu, Text, Tooltip } from '@tw/ui-components';
import { TagsOptions } from './AttributionMoreSettings';
import { AttributionSubscriptionFilter } from './AttributionSubscriptionFilter';
import AttributionWindowPicker from './AttributionWindowPicker';
import { useIsSmall } from 'hooks/useDefaultWindowSizes';
import { useAttributionActiveSource } from 'utils/useAttributionActiveSource';
import { useAttributionActivePage } from 'utils/useAttributionActivePage';
import { AllServicesIds } from 'types/attribution';
import AttributionModelPicker from './AttributionModelPicker';
import { SourceTypesWithExtra } from 'types/services';
import AttributionDateModelPicker from './AttributionDateModelPicker';
import { AttributionSelectChannel } from 'components/headers/AttributionHeader/AttributionSelectChannel';
import { ConditionalWrapper } from 'components/library/TwConditionalWrapper';

export const AttributionFiltersDropdown = () => {
  const { attributionSubscription } = useSelector((state: RootState) => state.attribution);
  const tagsToCheck: TagsOptions[] = useMemo<TagsOptions[]>(
    () => ['oneTime', 'subscriptionFirstOrder', 'subscriptionRecurringOrder'],
    [],
  );
  const subscriptionFilterOn = useMemo<boolean>(() => {
    const allTagsPresent = tagsToCheck.every((tag) => attributionSubscription?.includes(tag));
    return !allTagsPresent && !!attributionSubscription.length;
  }, [attributionSubscription, tagsToCheck]);
  const [opened, setOpened] = useState(false);
  const isSmall = useIsSmall();
  const activeSource = useAttributionActiveSource();
  const sourceCategory = useAttributionActivePage();

  return (
    <Menu opened={opened} onClose={() => setOpened(false)} closeOnItemClick={false} shadow="md">
      <Menu.Target>
        <div>
          <ConditionalWrapper
            condition={!!subscriptionFilterOn}
            wrapper={(x) => (
              <Tooltip label={'Table is currently displaying subscription filtered data'}>
                {x}
              </Tooltip>
            )}
          >
            <div>
              <ActionIcon
                icon="filter"
                variant="activator"
                bg={opened ? 'gray.1' : 'transparent'}
                onClick={() => setOpened(!opened)}
              />
              {subscriptionFilterOn && (
                <div className={'turn-on-indication right-indicator z-10 ml-5'} />
              )}
            </div>
          </ConditionalWrapper>
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        {isSmall && (
          <>
            <Menu.Item>
              <AttributionDateModelPicker
                customActivator={(label) => (
                  <Flex align="center" gap="md" justify="space-between">
                    <Text size="sm" weight={500} color="named2.0">
                      {label}
                    </Text>
                    <Icon name="chevron-right-minor" size={12} color={'named.8'} />
                  </Flex>
                )}
              />
            </Menu.Item>
            <Menu.Item>
              <AttributionModelPicker
                type={sourceCategory as SourceTypesWithExtra}
                sourceId={activeSource as AllServicesIds | 'tw_referrer'}
                customActivator={(label) => (
                  <Flex align="center" gap="md" justify="space-between">
                    <Text size="sm" weight={500} color="named2.0">
                      {label}
                    </Text>
                    <Icon name="chevron-right-minor" size={12} color={'named.8'} />
                  </Flex>
                )}
              />
            </Menu.Item>
            <Menu.Item>
              <AttributionSelectChannel
                withinPortal={false}
                dropdownPosition={'right-start'}
                dropDownOffset={16}
              />
            </Menu.Item>
          </>
        )}
        <Menu.Item>
          <AttributionWindowPicker withinPortal={false} onClose={setOpened} />
        </Menu.Item>
        <Menu.Item>
          <AttributionSubscriptionFilter />
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
