import { $shopDashboards } from '$stores/willy/$shopDashboards';
import { toast } from 'react-toastify';
import { WillyDashboardElement } from '../types/willyTypes';
import { copyDashboard } from './willyUtils';
import { analyticsEvents, genericEventLogger, templateLibraryActions } from 'utils/dataLayer';
import { $user } from '$stores/$user';
import { createCategoryListItem } from '../dashboardManagment/category-management/utils';
import { addItemToCategoriesList } from '../dashboardManagment/category-management/api';

export const copyGlobalDashboardToShop = async (globalDashboard: WillyDashboardElement) => {
  const user = $user.get();
  const userEmail = user?.email ?? '';
  const name = copyFromGlobalName(globalDashboard);
  try {
    const overrideDashData: Partial<WillyDashboardElement> = {
      name,
    };
    const newId: string = await copyDashboard(
      globalDashboard.id,
      undefined,
      userEmail,
      user,
      false,
      overrideDashData,
    );

    await addItemToCategoriesList(
      createCategoryListItem({
        id: newId,
        value: name || '',
        type: 'item',
      }),
    );

    toast.success(`Added to your workspace`);
    genericEventLogger(analyticsEvents.TEMPLATE_LIBRARY, {
      action: templateLibraryActions.ADD_TEMPLATE_TO_WORKSPACE,
      dashboard_id: newId,
      dashboard_name: name,
      template_id: globalDashboard.id,
      template_name: globalDashboard.name,
    });
    return newId;
  } catch (e) {
    toast.error(`Error creating report. ${e.message ?? e.data?.message ?? ''}`);
  }
};

const copyFromGlobalName = (globalDashboard: WillyDashboardElement) => {
  const shopDashboards = $shopDashboards.get();
  const numberOfCopies = shopDashboards.filter((d) => d.globalDashboardId === globalDashboard.id);
  const name = globalDashboard.name;
  return numberOfCopies.length > 0 ? `${name} (Copy ${numberOfCopies.length})` : name;
};
