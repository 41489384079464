import { $shopSequences } from '$stores/willy/$sequences';
import { useStoreValue } from '@tw/snipestate';
import { Box, Checkbox, Icon, Menu, Text, TextInput } from '@tw/ui-components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

type WorkflowSelectorProps = {
  activator: React.ReactNode;
  selectedList: string[];
  toggleSelection: (id: string) => void;
  withInPortal?: boolean;
  closeOnSelect?: boolean;
};

export const WorkflowSelector: React.FC<WorkflowSelectorProps> = ({
  activator,
  selectedList,
  toggleSelection,
  withInPortal,
  closeOnSelect,
}) => {
  const navigate = useNavigate();
  const [freeSearch, setFreeSearch] = useState('');

  const shopSequences = useStoreValue($shopSequences);
  const filteredWorkflows = shopSequences?.filter((workflow) => {
    return !!freeSearch?.length
      ? workflow?.name?.toLowerCase()?.includes(freeSearch?.toLowerCase())
      : true;
  });
  return (
    <>
      <Menu
        closeOnItemClick={closeOnSelect}
        onClose={() => {}}
        shadow="md"
        withinPortal={withInPortal ?? false}
        position="bottom-end"
        width="300"
      >
        <Menu.Target>{activator}</Menu.Target>
        <Menu.Dropdown>
          <Menu.Label pos="sticky" p="md">
            <TextInput
              value={freeSearch}
              onChange={setFreeSearch}
              leadingIcon="search-major"
              placeholder="Search Agents"
            />
          </Menu.Label>
          <Box mah={200} w={300} overflow="auto" px="md">
            {filteredWorkflows?.map((item, index) => (
              <Menu.Item key={index} onClick={() => toggleSelection(item.id)}>
                <div className="flex items-center gap-4">
                  <Checkbox
                    checked={selectedList?.includes(item.id)}
                    // onChange={() => toggleSelection(item.id)}
                  />
                  <Text lineClamp={1} fz={14} fw={500}>
                    {item.name}
                  </Text>
                </div>
              </Menu.Item>
            ))}
          </Box>
          <Menu.Divider my="sm" />
          <Box px="md" pb="xs">
            <Menu.Item
              leftSection={<Icon name="union" size={14} color="one.4" />}
              onClick={() => {
                navigate('/workflows/create');
              }}
              color={'one.4'}
            >
              Create a New Agent
            </Menu.Item>
          </Box>
        </Menu.Dropdown>
      </Menu>
    </>
  );
};
