import { isMobileApp, isOrcaApp } from '../utils/Device';

if (window.Intercom && !isMobileApp && !isOrcaApp) {
  window.Intercom('boot', {
    app_id: 'ogniecxz',
    hide_default_launcher: false,
  });
}

export {};
