import { $derived, $mutableDerived, $store } from '@tw/snipestate';
import { User } from 'components/UserProfileManagment/User/constants';
import { $redux } from './$redux';
import { emptyArray } from 'utils/emptyArray';
import { type Dialect } from 'components/Willy/types/willyTypes';
import { userDb } from 'utils/DB';
import { $currentShopId } from '$stores/$shop';
import { $shop } from './$shop';
import { DEFAULT_DIALECT } from '../components/Willy/constants';
import { $globalConfig } from './$globalConfig';

const ssrUser = 'twUser' in window && window.twUser ? window.twUser : {};
export const $user = $store<Partial<User>>(ssrUser);

// TODO: Interesting idea - maybe we can add a subscription to the $user to automatically save to db if there are changes in user.

export const $isAdminClaim = $derived((get) => !!get($user).isAdminClaim);

export const $isTWDevClaim = $derived(
  (get) => !!get($user).isAdminClaim && !!get($user).isTwDevClaim,
);

export const $isMFAExempt = $derived(
  (get) => !!get($user).isAdminClaim && !!get($user).isMFAExempt,
);

export const $isTwGlobalDashboardCreatorClaim = $derived(
  (get) => !!get($user).isAdminClaim && !!get($user).isTwGlobalDashboardCreatorClaim,
);

export const $isTwSuperAdmin = $derived(
  (get) => !!get($user).isAdminClaim && !!get($user).isTwSuperAdminClaim,
);

export const $unblockBlockedByPlan = $store(false);

export const $userId = $derived((get) => get($user).uid);

export const $userRoles = $derived((get) => {
  const shopId = get($currentShopId);
  if (!shopId) return emptyArray<string>();

  const user = get($user);
  const shopRoles = user.shops?.[shopId]?.roles;
  return Array.isArray(shopRoles) ? (shopRoles as readonly string[]) : emptyArray<string>();
});

export const $userEmail = $derived((get) => get($user).email);

export const $userDisplayName = $derived((get) => get($user).firstName);

export const $userShop = $derived((get) => get($user).shops?.[get($currentShopId)!] ?? null);

const $shopsAsArrayFromPods = $derived((get) => get($redux)?.shopAsArrayFromPods);

export const $isShopOwner = $derived((get) => {
  const currentShopId = get($currentShopId);
  const shops = get($shopsAsArrayFromPods);
  const currentShop = shops?.find((s) => s.shopId === currentShopId);
  return !!currentShop?.roles?.includes('owner');
});

export const $isShopAdmin = $derived((get) => {
  const currentShopId = get($currentShopId);
  const shops = get($shopsAsArrayFromPods);
  const currentShop = shops?.find((s) => s.shopId === currentShopId);
  return !!currentShop?.roles?.some((role) => ['owner', 'admin'].includes(role));
});

/** User that's not a shop owner, shop admin, or tw admin */
export const $isSimpleUser = $derived((get) => {
  return !get($isAdminClaim) && !get($isShopAdmin) && !get($isShopOwner);
});

export const $dialect = $mutableDerived((get) => {
  const user = get($user);
  const shop = get($shop);
  const globalConfig = get($globalConfig);

  const forceCHDialect = !!globalConfig.find((x) => x.id === 'forceCHDialect')?.state;

  if (shop.forceBQDialect) return 'bigquery';

  if (forceCHDialect) return 'clickhouse';

  if (user && shop) {
    return shop.dialect || user.dialect || DEFAULT_DIALECT;
  }
  return DEFAULT_DIALECT;
});

export const $changingDialect = $store(false);

export async function changeDialect(dialect: Dialect) {
  $changingDialect.set(true);
  await userDb().set({ dialect }, { merge: true });
  $dialect.set(dialect);
  $changingDialect.set(false);
}
