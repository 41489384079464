import { useState, useMemo } from 'react';
import { Image, Text, Modal } from '@tw/ui-components';
import { WebSources, Message } from './types/willyTypes';

type WillyMessageResourcesProps = {
  resources: WebSources;
  message: Message;
};

const getUrlInitials = (url?: string): string => {
  if (!url) return '??';
  return url
    .replace(/^https?:\/\/(www\.)?/, '')
    .split('.')[0]
    .substring(0, 2)
    .toUpperCase();
};

const LegacyMediaDisplay = (resources) => {
  return (
    <div className="flex flex-col gap-4">
      {!!resources.sources.length && (
        <div className="flex flex-col gap-2">
          <Text size="sm" fw="bold">
            Sources:
          </Text>
          {resources.sources.slice(0, 5).map((source, i) => (
            <div key={`${source.url}_${i}`}>
              <a
                href={source.url}
                target="_blank"
                rel="noreferrer"
                className="text-blue-600 hover:text-blue-800 hover:underline break-words"
              >
                {source.header}
              </a>
            </div>
          ))}
        </div>
      )}

      {!!resources.imgs.length && (
        <div className="flex flex-col gap-2">
          <Text size="sm" fw="bold">
            Images:
          </Text>
          <div className="flex flex-wrap gap-2">
            {resources.imgs.slice(0, 5).map((img, i) => (
              <div key={`${img}_${i}`} className="hover:opacity-80 transition-opacity">
                <a href={img} target="_blank" rel="noreferrer">
                  <Image shadowBorderSize="md" src={img} width="120px" height="120px" />
                </a>
              </div>
            ))}
          </div>
        </div>
      )}

      {!!resources.videos.length && (
        <div className="flex flex-col gap-2">
          <Text size="sm" fw="bold">
            Videos:
          </Text>
          {resources.videos.slice(0, 5).map((video, i) => (
            <div key={`${video}_${i}`}>
              <a
                href={video}
                target="_blank"
                rel="noreferrer"
                className="text-blue-600 hover:text-blue-800 hover:underline break-words"
              >
                {video}
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

function matchSourcesWithMatchingMedia(data) {
  const { sources, imgs, videos } = data;

  return sources.map((source) => {
    const sourceDomain = new URL(source.url).hostname;
    const isTripleWhale = sourceDomain.includes('triplewhale');

    const matchingImage = isTripleWhale
      ? '/whale-icon.svg'
      : imgs.find((imgUrl) => {
          try {
            const imgDomain = new URL(imgUrl).hostname;
            return imgDomain === sourceDomain;
          } catch (e) {
            return false;
          }
        });

    const matchingVideo = videos.find((videoUrl) => {
      try {
        const videoDomain = new URL(videoUrl).hostname;
        return videoDomain === sourceDomain;
      } catch (e) {
        return false;
      }
    });

    return {
      ...source,
      img: matchingImage || null,
      video: matchingVideo || null,
    };
  });
}

const Citations = ({ sources, opened, onClose }) => {
  return (
    <Modal.Root opened={opened} onClose={onClose} centered>
      <Modal.Overlay />
      <Modal.Content className="!flex flex-col">
        <Modal.Header>
          <Modal.Title>
            <Text size="md" fw="bold">
              Sources
            </Text>
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-4">
            {sources.map((source, i) => (
              <div key={`${source.url}_${i}`} className="block p-3 rounded-lg transition-colors">
                <div className="flex items-center gap-3">
                  {source.video ? (
                    <a
                      href={source.video}
                      target="_blank"
                      rel="noreferrer"
                      className="relative w-12 h-12 block hover:opacity-80 transition-opacity"
                    >
                      <div className="absolute inset-0 flex items-center justify-center bg-black/30 rounded">
                        <svg className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M8 5v14l11-7z" />
                        </svg>
                      </div>
                      <img
                        src={source.img || source.video}
                        className="w-12 h-12 rounded object-cover"
                        alt=""
                      />
                    </a>
                  ) : source.img ? (
                    <a
                      href={source.img}
                      target="_blank"
                      rel="noreferrer"
                      className="block hover:opacity-80 transition-opacity"
                    >
                      <img src={source.img} className="w-12 h-12 rounded" alt="" />
                    </a>
                  ) : (
                    <div className="w-12 h-12 rounded bg-gray-700 flex items-center justify-center text-lg text-gray-200">
                      {getUrlInitials(source.url)}
                    </div>
                  )}
                  <a
                    href={source.url}
                    target="_blank"
                    rel="noreferrer"
                    className="flex-1 no-underline hover:underline"
                  >
                    <Text size="sm">{source.header}</Text>
                    <Text size="xs">{source.url}</Text>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

export const WillyMessageResources: React.FC<WillyMessageResourcesProps> = ({ resources }) => {
  const sourcesWithMatchingMedia = useMemo(
    () => matchSourcesWithMatchingMedia(resources),
    [resources],
  );

  const sourcesWithMatchingMediaHideDuplicates = useMemo(
    () =>
      sourcesWithMatchingMedia.filter(
        (source, index, self) => index === self.findIndex((t) => t.img === source.img),
      ),
    [sourcesWithMatchingMedia],
  );

  const [isCitationsOpen, setIsCitationsOpen] = useState(false);

  return sourcesWithMatchingMedia ? (
    <>
      <div className="flex flex-col gap-4">
        <div
          className="flex items-center gap-2 rounded-full px-4 py-2 w-fit border border-solid border-gray-400 sm:hover:bg-gray-100 transition-colors cursor-pointer"
          onClick={() => setIsCitationsOpen(true)}
        >
          <Text size="sm">Sources</Text>
          <div className="flex -space-x-2 items-center">
            {sourcesWithMatchingMediaHideDuplicates.map((source, i) => (
              <div key={`${source.url}_${i}`} onClick={() => setIsCitationsOpen(true)}>
                {source.img ? (
                  <img
                    src={source.img}
                    width="20px"
                    height="20px"
                    className="block rounded-full border shadow bg-white"
                  />
                ) : (
                  <div className="w-[20px] h-[20px] rounded-full border bg-gray-200 flex items-center justify-center text-xs text-gray-600 shadow">
                    {getUrlInitials(source.url)}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <Citations
        sources={sourcesWithMatchingMedia}
        opened={isCitationsOpen}
        onClose={() => setIsCitationsOpen(false)}
      />
    </>
  ) : (
    <LegacyMediaDisplay resources={resources} />
  );
};
