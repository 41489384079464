import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { WillyWidgetEditor } from '../WillyWidgetEditor';
import { useDashContext } from '../../dashContext';
import { useStoreValue } from '@tw/snipestate';
import { $currency, $currentShopId } from '$stores/$shop';
import { $dialect } from '$stores/$user';
import { createNewWidget } from '../../utils/willyUtils';
import {
  GridColumnOptions,
  GridOptions,
  MessageTypes,
  TileModes,
  WidgetChartLabel,
  WillyDashboardElement,
  WillyMetric,
  WillyParameter,
  WillyWidgetElement,
} from '../../types/willyTypes';
import { v4 as uuidV4 } from 'uuid';
import { DEFAULT_AXIS_DOMAIN, DEFAULT_DIALECT, MAX_ITEMS_PER_PAGE } from '../../constants';
import { cohortColor } from 'constants/general';
import { BuilderTable } from '@tw/willy-data-dictionary/module/columns/types';
import { RecommendedTable } from './ChooseTableSection';
import { analyticsEvents, dashboardsActions, genericEventLogger } from 'utils/dataLayer';

type QueryBuilderCreatorProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  dashboard?: WillyDashboardElement;
  widgetId?: string;
};

export const QueryBuilderCreator: FC<QueryBuilderCreatorProps> = ({
  open,
  setOpen,
  dashboard,
  widgetId,
}) => {
  const { $widgets, fetchWidgets } = useDashContext();
  const currentShopId = useStoreValue($currentShopId);
  const dialect = useStoreValue($dialect);
  const currency = useStoreValue($currency);

  const [builder, setBuilder] = useState<BuilderTable>();

  const [type, setType] = useState<MessageTypes>('table');
  const [metrics, setMetrics] = useState<WillyMetric[]>([]);
  const [stacked, setStacked] = useState(false);
  const [incrementedStacked, setIncrementedStacked] = useState(false);
  const [title, setTitle] = useState('');
  const [wrapText, setWrapText] = useState(false);
  const [grid, setGrid] = useState<GridOptions>('flex');
  const [twoColumnMobile, setTwoColumnMobile] = useState(false);
  const [gridColumns, setGridColumns] = useState<GridColumnOptions>(2);
  const [skinny, setSkinny] = useState(false);
  const [allowDataOverflow, setAllowDataOverflow] = useState(false);
  const [yAxisDomain, setYAxisDomain] = useState(DEFAULT_AXIS_DOMAIN);
  const [tileMode, setTileMode] = useState<TileModes>('tile');
  const [hasGlobalConditionalFormatting, setHasGlobalConditionalFormatting] = useState(false);
  const [globalConditionalFormattingColor, setGlobalConditionalFormattingColor] =
    useState(cohortColor);
  const [dimension, setDimension] = useState<string>('');
  const [breakdownMode, setBreakdownMode] = useState(false);
  const queryId = useMemo(() => widgetId || uuidV4(), [widgetId]);

  const saved = useRef(false);
  const setSaved = useCallback((v) => {
    //console.log('Setting saved', saved.current, v);
    saved.current = v;
  }, []);

  const generateWidgetTitle = useCallback(
    (metrics: WillyMetric[], parameters: WillyParameter[]) => {
      const providers = parameters?.find((p) => p.column === 'channel')?.value;
      let providersString = '';
      if (Array.isArray(providers)) {
        providersString = providers.join(', ');
      } else if (providers) {
        providersString = providers;
      }

      if (metrics.length > 0) {
        return `${metrics.map((m) => m.name).join(', ')} ${
          providersString ? `by ${providersString}` : ''
        }`;
      }
      return 'New Widget';
    },
    [],
  );

  const fun = useCallback(
    async (widget: WillyWidgetElement) => {
      //console.log('Creating new widget before');
      if (!dashboard || !currentShopId || !widget.dialect) {
        return;
      }
      //console.log('Creating new widget', widget.title, widget.metrics, widget.builderSetup);
      widget.title = widget.title || generateWidgetTitle(widget.metrics, []);
      const w = await createNewWidget(currentShopId, dashboard, widget);
      const widgets = await fetchWidgets();
      genericEventLogger(analyticsEvents.DASHBOARDS, {
        action: dashboardsActions.PIN_RESULT,
        modifier: 'duplicate',
        widget_type: type,
        widget_id: queryId,
        widget_name: title,
        dashboard_id: dashboard?.id,
        dashboard_name: dashboard?.name,
        template_id: dashboard?.globalDashboardId,
        template_name: dashboard?.globalDashboardId && dashboard?.name,
        source: 'table-builder',
      });
      $widgets.set(widgets);
      setOpen(false);
    },
    [
      $widgets,
      currentShopId,
      dashboard,
      fetchWidgets,
      generateWidgetTitle,
      setOpen,
      queryId,
      type,
      title,
    ],
  );

  const createEmptyBuilder = useCallback(() => {
    const b: BuilderTable = {
      table: RecommendedTable,
      columns: [],
      filters: [],
      orderBy: [],
      page: 1,
      perPage: MAX_ITEMS_PER_PAGE,
      queryId: queryId ?? uuidV4(),
    };
    return b;
  }, [queryId]);

  useEffect(() => {
    setType('table');
    setMetrics([]);
    setStacked(false);
    setIncrementedStacked(false);
    setTitle('');
    setWrapText(false);
    setGrid('flex');
    setTwoColumnMobile(false);
    setGridColumns(2);
    setSkinny(false);
    setAllowDataOverflow(false);
    setYAxisDomain(DEFAULT_AXIS_DOMAIN);
    setTileMode('tile');
    setHasGlobalConditionalFormatting(false);
    setGlobalConditionalFormattingColor(cohortColor);
    setDimension('');
    setBreakdownMode(false);
    setBuilder(createEmptyBuilder());
    //setSaved(false);
  }, [createEmptyBuilder, open]);

  return (
    <WillyWidgetEditor
      open={open}
      setOpen={setOpen}
      type={type}
      typeChanged={setType}
      context="dashboard"
      queryId={queryId}
      metrics={metrics}
      metricsChanged={async (id, v) => await setMetrics(v)}
      stacked={stacked}
      stackedChanged={setStacked}
      incrementedStacked={incrementedStacked}
      incrementedStackedChanged={setIncrementedStacked}
      title={title}
      titleChanged={setTitle}
      wrapText={wrapText}
      setWrapText={setWrapText}
      permission={{ providers: [] }}
      permissionChanged={(v) => {}}
      grid={grid}
      setGrid={setGrid}
      twoColumnMobile={twoColumnMobile}
      setTwoColumnMobile={setTwoColumnMobile}
      gridColumns={gridColumns}
      setGridColumns={setGridColumns}
      skinny={skinny}
      setSkinny={setSkinny}
      dialect={dialect || DEFAULT_DIALECT}
      allowDataOverflow={allowDataOverflow}
      setAllowDataOverflow={setAllowDataOverflow}
      filtersOpen={false}
      setFiltersOpen={(v) => {}}
      yAxisDomain={yAxisDomain}
      setYAxisDomain={setYAxisDomain}
      tileMode={tileMode}
      setTileMode={setTileMode}
      hasGlobalConditionalFormatting={hasGlobalConditionalFormatting}
      setHasGlobalConditionalFormatting={setHasGlobalConditionalFormatting}
      globalConditionalFormattingColor={globalConditionalFormattingColor}
      setGlobalConditionalFormattingColor={setGlobalConditionalFormattingColor}
      currency={currency}
      builderSetup={builder}
      builderSetupChanged={async (builderSetup) => {
        setBuilder(builderSetup);
      }}
      dimension={dimension}
      isSyncCharts={dashboard?.isSyncCharts ?? false}
      setDimension={setDimension}
      breakdownMode={breakdownMode}
      breakdownModeChanged={async (v) => setBreakdownMode(v)}
      queriesChanged={async (v) => {}}
      mode="builder"
      //setSaved={setSaved}
      onSave={fun}
    />
  );
};
