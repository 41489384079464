import { SummarySection } from '../types/SummarySection';
import { type RootState } from 'reducers/RootType';
import moment from '@tw/moment-cached/module/timezone';
import { Dispatch, Reducer } from 'redux';
import { groupStats } from '@tw/stats/module/groupStats';
import axiosInstance from 'utils/axiosInstance';

import {
  CalculatedMetrics,
  Entity,
  Granularity,
  MetricsQueryStringParams,
  MetricsResponseData,
  RawMetrics,
  Shop,
} from '@tw/types';
import { services, ServicesIds } from '@tw/types/module/services';
import { APPS_METRICS_RECEIVED, APPS_SECTIONS_RECEIVED } from './apps';
import { BaseSummaryMetric } from '@tw/types/module/SummaryMetrics';
import { INIT_SHOP } from 'ducks/constants';
import { $currentShopId, $shopCurrency } from '$stores/$shop';
import { mapValues } from 'lodash';
import { MetricsKeys } from 'types/metrics';
import STATS from '@tw/stats';
import { metrics as METRICS } from 'constants/metrics/metrics';
import { GetShopifyOrdersSegmentsStatsRequest } from '../types/shopify';
import { ExtraServicesIds, Providers } from 'types/services';
import { AttributionStatsRequest } from 'types/attribution';
import { simpleChart } from '@tw/stats/module/generalUtils';
import { providers, shopIntegrations } from './shopIntegrations';
import { convertDataToStats } from '@tw/stats/module/convertDataToStats';
import { createSelector } from 'reselect';
import { getStatsLtv } from './actions';
import {
  fetchServiceStats,
  getRelevantIntegrations,
  groupStatsByWillyTableType,
} from './newStatsUtils';
import { $granularity } from '../$stores/willy/$dateRange';
// new architecture - start
export const NEW_STATS_RECEIVED = 'NEW_STATS_RECEIVED';
export const STATS_LOADING_ERROR = 'STATS_LOADING_ERROR';
export const NEW_PREVIOUS_PERIOD_STATS_RECEIVED = 'NEW_PREVIOUS_PERIOD_STATS_RECEIVED';
export const LOADING_NEW_STATS = 'LOADING_NEW_STATS';
export const LOADING_PREVIOUS_PERIOD_NEW_STATS = 'LOADING_PREVIOUS_PERIOD_NEW_STATS';
export const RECEIVE_ALL_NEW_STATS = 'RECEIVE_ALL_NEW_STATS';
export const RESET_STATS = 'RESET_STATS';

export { convertDataToStats } from '@tw/stats/module/convertDataToStats';

export const getSummaryWillyStatsForShop = async (
  serviceIds: ServicesIds[],
  isSilent,
  mainDatePickerSelectionRange,
  currency,
  integrations,
  groupStatsBy,
  setLoading,
  willyTableType,
  shop?,
) => {
  let { start, end } = mainDatePickerSelectionRange;
  const format = 'YYYY-MM-DD';
  start = start.format(format);
  end = end.format(format);
  const isOneDay = moment(start).diff(end, 'days') === 0;
  let granularity: Granularity = 'day';
  if (isOneDay) {
    granularity = 'hour';
  }

  const params = {
    currency,
    start,
    end,
    granularity,
    providerIds: serviceIds,
    accountIds: serviceIds.flatMap((s) => integrations[s]?.map((x) => x.id) ?? []),
    integrations: getRelevantIntegrations(integrations),
    willyTableType,
    shopId: shop,
  };
  if (!isSilent) {
    setLoading();
  }

  const { stats } = await fetchServiceStats(
    params,
    `v2/summary-page/willy-metrics${willyTableType ? `?willyTableType=${willyTableType}` : ''}`,
    'post',
    true,
  );
  return Object.entries(stats).reduce((acc, [serviceId, serviceStats]) => {
    acc[serviceId] = groupStats(serviceStats, groupStatsBy, undefined, undefined, true);
    return acc;
  }, {});
};

export const getShopifySegmentsNewStatsForShop = async (
  isSilent,
  mainDatePickerSelectionRange,
  groupStatsBy,
  setLoading,
  shopTimezone,
  shopId,
  calculatedOrderSegment,
) => {
  let { start, end } = mainDatePickerSelectionRange;
  const format = 'YYYY-MM-DD';
  start = moment(start).tz(shopTimezone).format(format);
  end = moment(end).tz(shopTimezone).format(format);
  const isOneDay = moment(start).diff(end, 'days') === 0;
  let granularity: Granularity = 'day';
  if (isOneDay) {
    granularity = 'hour';
  }

  const body: GetShopifyOrdersSegmentsStatsRequest = {
    shopId,
    start: moment(start).tz(shopTimezone).format('YYYY-MM-DD'),
    end: moment(end).tz(shopTimezone).format('YYYY-MM-DD'),
    granularity,
    metricsBreakdown: true,
    match: calculatedOrderSegment,
  };

  if (!isSilent) {
    setLoading();
  }

  const segments = (await axiosInstance.post('/v2/shopify/mongo/get-orders-breakdown', body)).data;

  const shopifySegmentsConverted = {};
  Object.entries(segments).forEach(([segmentCategory, segment]) => {
    Object.entries(segment as any).forEach(([segmentId, data]) => {
      const stats = convertDataToStats(data, start, end);
      const groupedStats = groupStats(stats, groupStatsBy, undefined, undefined, true);
      if (!shopifySegmentsConverted[segmentCategory]) {
        shopifySegmentsConverted[segmentCategory] = {};
      }
      shopifySegmentsConverted[segmentCategory][segmentId] = groupedStats;
    });
  });
  return shopifySegmentsConverted;
};

export const getInfluencerStatsForShop = async (
  shopId,
  shopTimezone,
  groupStatsBy,
  mainDatePickerSelectionRange,
) => {
  if (!mainDatePickerSelectionRange) {
    return;
  }

  let { start, end } = mainDatePickerSelectionRange;
  const startDate = start.format();
  const endDate = end.format();

  const params = {
    startDate,
    endDate,
    start: startDate,
    end: endDate,
    shopDomain: shopId,
    isSummary: true,
    breakdown: 'campaignId',
    attributionWindow: 'lifetime',
    // same signature as detail view
    page: 0,
    model: 'lastPlatformClick-v2',
    dateModel: 'eventDate',
    timezone: shopTimezone,
  };

  try {
    const { stats } = await fetchServiceStats(
      params,
      `/v2/attribution/get-influencer-attribution`,
      'post',
      false,
    );

    const groupedStats = groupStats(stats, groupStatsBy, undefined, undefined, true);
    return groupedStats;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export function serviceHasOwnMetrics(serviceId: ServicesIds): boolean {
  return ['enquirelabs', 'kno', 'amazon'].includes(serviceId);
}

// used by summary page

export const getSummaryWillyStats = (
  serviceIds: ServicesIds[],
  willyTableType,
  isSilent = false,
) => {
  return async (dispatch, getState: () => RootState) => {
    const { mainDatePickerSelectionRange, groupStatsBy, currentShopId, user } = getState();

    const integrations = shopIntegrations(getState());
    if (!mainDatePickerSelectionRange) {
      return;
    }

    //filter out the serviceIds that don't have integrations
    serviceIds = serviceIds.filter((serviceId) => integrations[serviceId]?.length);
    if (!serviceIds.length) return;

    try {
      const groupedStats = await getSummaryWillyStatsForShop(
        serviceIds,
        isSilent,
        mainDatePickerSelectionRange,
        $shopCurrency.get(),
        integrations,
        groupStatsBy,
        () =>
          serviceIds.forEach((serviceId) => {
            dispatch(
              loadingNewStats({
                [serviceId]: true,
              }),
            );
          }),
        willyTableType,
        currentShopId,
      );
      Object.keys(groupedStats).forEach((serviceId) => {
        dispatch(
          newStatsReceived({
            stats: groupedStats[serviceId],
            serviceId,
          }),
        );
      });
      dispatch({ type: 'REGROUPING_STATS_END' });
    } catch (e) {
      console.error(e);
      serviceIds.forEach((serviceId) => {
        dispatch(
          statsLoadingError({
            serviceId,
          }),
        );
      });
    }
  };
};

export const fetchAdsMetrics =
  (params, statsUrl, method, scope, groupStatsBy) => async (dispatch) => {
    const { stats } = await fetchServiceStats(params, statsUrl, method, false);

    const groupedStats = groupStats(stats, groupStatsBy, undefined, undefined, true);

    dispatch(
      newStatsReceived({
        stats: groupedStats,
        serviceId: scope,
      }),
    );
  };

export const getExternalIntegrationsStats = (clientId: string) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const { mainDatePickerSelectionRange, groupStatsBy, currentShopId, externalApps } = getState();
    if (!mainDatePickerSelectionRange) {
      return;
    }

    dispatch(
      loadingNewStats({
        [clientId]: true,
      }),
    );

    let { start, end } = mainDatePickerSelectionRange;
    const format = 'YYYY-MM-DD';

    const params = {
      start: start.format(format),
      end: end.format(format),
      service_id: clientId,
      account_id: currentShopId,
    };

    let url = `v2/tw-metrics/metrics-data`;

    try {
      const { stats } = await fetchServiceStats(params, url, 'get', false);

      const allMetrics: any[] = stats.flatMap((x) => Object.values(x.hours || []));

      const allTilesIds = allMetrics.flatMap((x) => Object.keys(x));
      const uniqueMetrics = [...new Set(allTilesIds)];

      const { apps } = externalApps;
      const currentApp = apps.find((app) => app.id === clientId);
      dispatch({
        type: APPS_SECTIONS_RECEIVED,
        section: {
          ...currentApp,
          tiles: uniqueMetrics.map((x) => x),
          title: currentApp?.appName,
          types: ['summary'],
          id: clientId as any,
          isExternalApp: true,
          allowToPickForCustomSection: true,
          showPreviousPeriod: false,
          services: [clientId],
          icons: [
            () => {
              return <img alt="icon" src={currentApp?.appLogoUrl} width={20} />;
            },
          ],
          indicationWhileShopIsLoading: true,
          beta: true,
          shouldShowTilesIcons: false,
          emptySectionTitle: `${currentApp?.appName} didn't send us data for this date range`,
        } as SummarySection,
      });

      const metrics: BaseSummaryMetric<any>[] = allMetrics
        .flatMap((x) => Object.values(x))
        .map((metric: any) => ({
          id: metric.metricId,
          metricId: 'externalApps',
          chart: 'externalAppsChart',
          services: [clientId],
          title: metric.metricName,
          type: metric.type,
          valueToFixed: 0,
          color: 'black',
          icon: () => <img alt="app icon" src={currentApp?.appLogoUrl} width={16} />,
          tip: metric.metricDescription,
          statObjectKey: clientId as any,
          specificStat: metric.metricId,
        }));

      dispatch({
        type: APPS_METRICS_RECEIVED,
        metrics,
      });

      const groupedStats = groupStats(stats, groupStatsBy, undefined, undefined, true);

      dispatch(
        newStatsReceived({
          stats: groupedStats,
          serviceId: clientId,
        }),
      );
    } catch (e) {
      console.error(e);
      dispatch(
        statsLoadingError({
          serviceId: clientId,
        }),
      );
    }
  };
};

export const getNewStatsForAllServices = (isSilent = false, filterIds: ServicesIds[] = []) => {
  return async (dispatch, getState: () => RootState) => {
    const { externalApps, msp } = getState();
    const apiInApps = externalApps.apps.filter((app) => app.scopes?.includes('ads-metrics:write'));

    const providersProps = Object.entries(providers(getState()))
      .filter(([id]) => filterIds.length === 0 || filterIds.includes(id as ServicesIds))
      .reduce((acc, [id, providerProps]) => {
        acc[id as ServicesIds] = providerProps;
        return acc;
      }, {} as Providers);

    const groupedProviders = groupStatsByWillyTableType(providersProps);
    for (const [willyTableType, providers] of Object.entries(groupedProviders)) {
      dispatch(getSummaryWillyStats(providers, willyTableType, isSilent));
    }

    if (filterIds.length === 0) {
      dispatch(getInfluencerStats(true));

      if (msp === 'shopify') {
        dispatch(getStatsLtv());
      }

      // external integrations
      for (const { id } of apiInApps) {
        dispatch(getExternalIntegrationsStats(id));
      }
    }
  };
};

export const getInfluencerStats = (isSilent = false) => {
  return async (dispatch: Dispatch, getState) => {
    if (!isSilent) {
      dispatch(
        loadingNewStats({
          influencers: true,
        }),
      );
    }
    const { mainDatePickerSelectionRange, groupStatsBy, shopTimezone } = getState();

    if (!mainDatePickerSelectionRange) {
      return;
    }

    try {
      const groupedStats = await getInfluencerStatsForShop(
        $currentShopId.get(),
        shopTimezone,
        groupStatsBy,
        mainDatePickerSelectionRange,
      );

      dispatch(
        newStatsReceived({
          stats: groupedStats,
          serviceId: 'influencers',
        }),
      );
    } catch (e) {
      console.error(e);
      dispatch(
        statsLoadingError({
          serviceId: 'influencers',
        }),
      );
    }
  };
};

const safeDivide = (numerator: number, denominator: number) => {
  if (
    !numerator ||
    !denominator ||
    !isFinite(numerator / denominator) ||
    !(numerator / denominator)
  ) {
    return 0;
  }
  return numerator / denominator;
};

const mergeAndSumObjects: <T extends Record<any, any>>(objects: T[]) => Record<string, T[]> = (
  objects,
) => {
  const res = {};
  objects.forEach((obj) => {
    Object.entries(obj).forEach(([key, value]) => {
      if (!res[key]) {
        res[key] = 0;
      }
      res[key] += value;
    });
  });
  return res;
};

const groupStatsEntity = (oneDayMetrics: MetricsResponseData['metricsBreakdown']) => {
  if (!oneDayMetrics) {
    return {};
  }
  const rawMetrics: any = oneDayMetrics.reduce(
    (acc, curr) => mergeAndSumObjects([acc, curr.metrics]),
    {},
  );

  const allMetrics: RawMetrics & CalculatedMetrics = {
    ...rawMetrics,
    roas: safeDivide(rawMetrics.conversionValue, rawMetrics.spend),
    cpc: safeDivide(rawMetrics.spend, rawMetrics.clicks),
    cpm: safeDivide(rawMetrics.spend, rawMetrics.impressions / 1000),
    ctr: safeDivide(rawMetrics.clicks, rawMetrics.impressions) * 100,
    cpa: safeDivide(rawMetrics.spend, rawMetrics.purchases),
    aov: safeDivide(rawMetrics.conversionValue, rawMetrics.purchases),
  };
  return allMetrics;
};

// used by pixel pages
export const getKlaviyoData = async (
  params: Partial<MetricsQueryStringParams>,
  entity: Entity,
  shop: Shop,
) => {
  const { service_id } = params;

  const ID_FIELD =
    entity === 'campaign' ? 'campaign_id' : entity === 'adset' ? 'adset_id' : 'ad_id';
  const attributes: MetricsQueryStringParams['attributes'] = params.attributes || [
    'name',
    'status',
    'accountId',
    'campaignId',
    'adsetId',
    'adId',
    'imageUrl',
    'urlParams',
  ];

  const account_ids = services['klaviyo'].getAccounts(shop).map((x) => x.id);

  const { data } = await axiosInstance.post('/v2/metrics-table/get-metrics', {
    ...params,
    account_ids,
    entity,
    shopId: $currentShopId.get(),
    attributes,
    currency: shop.currency || 'USD',
  });

  const campaigns = data.data.map((campaign) => {
    const { metricsBreakdown, ..._campaign } = campaign;
    const grouped: any = groupStatsEntity(campaign.metricsBreakdown);
    return {
      ..._campaign,
      [ID_FIELD]: campaign.id,
      name: campaign.name,
      effective_status: campaign.status,
      image_url: campaign.imageUrl,
      urlParams: campaign.urlParams,

      campaign_id: campaign.campaignId,
      adset_id: campaign.adsetId,
      ad_id: campaign.adId,

      clicks: grouped.clicks,
      conversions: grouped.purchases,
      all_conversions: grouped.allConversions,
      all_conversions_value: grouped.allConversionValue,
      cost_per_outbound_click: grouped.outboundClicks,
      cpc: grouped.cpc,
      cpm: grouped.cpm,
      ctr: grouped.ctr,
      day_view_purchase_roas: safeDivide(grouped.oneDayViewConversionValue, grouped.spend),
      impressions: grouped.impressions,
      purchase_roas: safeDivide(grouped.conversionValue, grouped.spend),
      spend: grouped.spend,
      conversionValue: grouped.conversionValue,
      cv: grouped.conversionValue,
      flowsConversionValue: grouped.flowsConversionValue,
      flowsCount: grouped.flowsCount,
      campaignsConversionValue: grouped.campaignsConversionValue,
    };
  });

  return campaigns;
};

export const dispatchPixelStats = (params: Partial<AttributionStatsRequest>) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const { hasPixelInstalled } = getState();

    if (!hasPixelInstalled) {
      return;
    }

    dispatch(
      loadingNewStats({
        pixel: true,
      }),
    );

    const stats = await getPixelStats(params);

    dispatch(
      newStatsReceived({
        stats,
        serviceId: 'pixel',
      }),
    );
  };
};

export const getPixelStats = async (params: Partial<AttributionStatsRequest>) => {
  const { startDate, endDate } = params;
  const { data } = await axiosInstance.post<any, any, Partial<AttributionStatsRequest>>(
    '/v2/attribution/get-attribution-summary',
    {
      ...params,
      model: 'linearAll',
      dateModel: 'eventDate',
      showDirect: true,
      breakdown: 'source',
    },
  );

  try {
    // unique visitors is a unique metric where the sum of the breakdown is not the correct value for the total
    const uniqueVisitors = data?.pixelStats?.pixelUniqueVisitors || 0;
    const uniqueAtc = data?.pixelStats?.pixelUniqueSessionsAtc || 0;
    const pixelNewVisitors = data?.pixelStats?.pixelNewVisitors || 0;

    if (data?.pixelStats?.metricsBreakdown?.length) {
      data.pixelStats.metricsBreakdown[0].metrics.uniqueVisitorsAcrossDateRange = uniqueVisitors;
      data.pixelStats.metricsBreakdown[0].metrics.uniqueAtcAcrossDateRange = uniqueAtc;
      data.pixelStats.metricsBreakdown[0].metrics.uniqueNewVisitorsAcrossDateRange =
        pixelNewVisitors;
    } else {
      data.pixelStats.metricsBreakdown = [
        {
          metrics: {
            uniqueVisitorsAcrossDateRange: uniqueVisitors,
            uniqueAtcAcrossDateRange: uniqueAtc,
            uniqueNewVisitorsAcrossDateRange: pixelNewVisitors,
          },
        },
      ];
    }
  } catch (e) {
    console.error(e);
  }

  const stats = convertDataToStats({ data: [data?.pixelStats || {}] }, startDate, endDate);
  const groupedStats = groupStats(stats, $granularity.get(), undefined, undefined, true);

  return groupedStats;
};

export const getMetricChartForStats = (
  metric: MetricsKeys,
  serviceId: ServicesIds | ExtraServicesIds,
  state,
) => {
  return STATS[METRICS[metric]?.chart || '']?.(state, serviceId);
};

export const convertPixelMetricBreakdownToChart = (stats, metric) => {
  const chart = simpleChart(stats, metric);
  return chart;
};

export const getMetricValueForStats = (metric: MetricsKeys, serviceId: ServicesIds, state) => {
  return STATS[metric]?.(state, serviceId);
};

export const loadingNewStats = (services: Partial<Record<ServicesIds, boolean>>) => ({
  type: LOADING_NEW_STATS,
  services,
});

export const receiveAllNewStats = () => ({
  type: RECEIVE_ALL_NEW_STATS,
});

export const newStatsReceived = (res) => ({
  type: NEW_STATS_RECEIVED,
  payload: res,
});
export const statsLoadingError = (res) => ({
  type: STATS_LOADING_ERROR,
  payload: res,
});

export const previousPeriodNewStatsReceived = (res) => ({
  type: NEW_PREVIOUS_PERIOD_STATS_RECEIVED,
  payload: res,
});

export const resetStats = () => ({
  type: RESET_STATS,
});

const newStats = (state: Partial<Record<ServicesIds, any>> = {}, action) => {
  switch (action.type) {
    case NEW_STATS_RECEIVED:
      return {
        ...state,
        [action.payload.serviceId]: action.payload.stats,
      };
    case RESET_STATS:
      return {};
    default:
      return state;
  }
};

const mapTypeServiceIdToMainServiceId = (serviceId: ServicesIds) => {
  if (serviceId.includes('smsbump')) {
    return 'smsbump';
  }
  if (serviceId.includes('amazon')) {
    return 'amazon';
  }
  if (serviceId.includes('woocommerce')) {
    return 'woocommerce';
  }
  return serviceId;
};

const isLoadingNewStats: Reducer<Record<ServicesIds, boolean>> = (
  state: Partial<Record<ServicesIds, boolean>> = { fakeOnlyForFirstLoading: true } as any,
  action,
) => {
  switch (action.type) {
    case LOADING_NEW_STATS:
      return { ...state, ...action.services, fakeOnlyForFirstLoading: false };
    case NEW_STATS_RECEIVED:
      return {
        ...state,
        [mapTypeServiceIdToMainServiceId(action.payload.serviceId)]: false,
        fakeOnlyForFirstLoading: false,
      };
    case STATS_LOADING_ERROR:
      return { ...state, [action.payload.serviceId]: false, fakeOnlyForFirstLoading: false };
    case RECEIVE_ALL_NEW_STATS:
      return { fakeOnlyForFirstLoading: false };
    default:
      return state;
  }
};

export const statsIsInInitialLoading = createSelector(
  [(state: RootState) => state.isLoadingNewStats],
  (isLoadingNewStats) => {
    return (isLoadingNewStats as any).fakeOnlyForFirstLoading;
  },
);

const errorLoadingStats: Reducer<Partial<Record<ServicesIds, boolean>>> = (state, action) => {
  switch (action.type) {
    case INIT_SHOP:
      return {};
    case LOADING_NEW_STATS:
      return { ...state, ...mapValues(action.services, () => false) };
    case STATS_LOADING_ERROR:
      return { ...state, [action.payload.serviceId]: true };
    default:
      return state || {};
  }
};

const isLoadingPreviousPeriodNewStats = (
  state: Partial<Record<ServicesIds, boolean>> = {},
  action,
) => {
  switch (action.type) {
    case LOADING_PREVIOUS_PERIOD_NEW_STATS:
      return action.services;
    case NEW_PREVIOUS_PERIOD_STATS_RECEIVED:
      return { ...state, [action.payload.serviceId]: false };
    default:
      return state;
  }
};

export const reducers = {
  newStats,
  isLoadingNewStats,
  isLoadingPreviousPeriodNewStats,
  errorLoadingStats,
};
