import { Button, Icon } from '@tw/ui-components';

type MdToolbarProps = {
  text: string;
  selection: { start: number; end: number } | null;
  textChange: (text: string) => void;
};

export const MdToolbar: React.FC<MdToolbarProps> = ({ text, selection, textChange }) => {
  return (
    <div className="flex items-center gap-1">
      <Button
        variant="activator"
        size="xs"
        onClick={() => {
          if (!selection) return;
          const textBeforeSelection = text.slice(0, selection.start);
          const textAfterSelection = text.slice(selection.end);
          const newText = `${textBeforeSelection}**${text.slice(selection.start, selection.end)}**${textAfterSelection}`;
          textChange(newText);
        }}
      >
        <span className="font-bold">B</span>
      </Button>
      <Button
        variant="activator"
        size="xs"
        onClick={() => {
          if (!selection) return;
          const textBeforeSelection = text.slice(0, selection.start);
          const textAfterSelection = text.slice(selection.end);
          const newText = `${textBeforeSelection}*${text.slice(selection.start, selection.end)}*${textAfterSelection}`;
          textChange(newText);
        }}
      >
        <span className="italic">I</span>
      </Button>
      <Button
        variant="activator"
        size="xs"
        onClick={() => {
          if (!selection) return;
          const textBeforeSelection = text.slice(0, selection.start);
          const textAfterSelection = text.slice(selection.end);
          const newText = `${textBeforeSelection}_${text.slice(selection.start, selection.end)}_${textAfterSelection}`;
          textChange(newText);
        }}
      >
        <span className="underline">U</span>
      </Button>
      <Button
        borderRadius={0}
        variant="activator"
        size="xs"
        onClick={() => {
          if (!selection) return;
          const textBeforeSelection = text.slice(0, selection.start);
          const textAfterSelection = text.slice(selection.end);
          const newText = `${textBeforeSelection}~~${text.slice(selection.start, selection.end)}~~${textAfterSelection}`;
          textChange(newText);
        }}
      >
        <span className="line-through">S</span>
      </Button>
      <Button
        borderRadius={0}
        variant="activator"
        size="xs"
        onClick={() => {
          if (!selection) return;
          const textBeforeSelection = text.slice(0, selection.start);
          const textAfterSelection = text.slice(selection.end);
          const newText = `${textBeforeSelection}\n# ${text.slice(selection.start, selection.end)}\n${textAfterSelection}`;
          textChange(newText);
        }}
      >
        <span className="font-bold">H1</span>
      </Button>
      <Button
        borderRadius={0}
        variant="activator"
        size="xs"
        onClick={() => {
          if (!selection) return;
          const textBeforeSelection = text.slice(0, selection.start);
          const textAfterSelection = text.slice(selection.end);
          const newText = `${textBeforeSelection}\n## ${text.slice(selection.start, selection.end)}\n${textAfterSelection}`;
          textChange(newText);
        }}
      >
        <span className="font-bold">H2</span>
      </Button>
      <Button
        borderRadius={0}
        variant="activator"
        size="xs"
        onClick={() => {
          if (!selection) return;
          const allLinesInSelection = text.slice(selection.start, selection.end).split('\n');
          const textBeforeSelection = text.slice(0, selection.start);
          const textAfterSelection = text.slice(selection.end);
          const newText = `${textBeforeSelection}\n${allLinesInSelection.map((line) => `* ${line}`).join('\n')}\n${textAfterSelection}`;
          textChange(newText);
        }}
      >
        <span>*</span>
      </Button>

      <Button
        borderRadius={0}
        variant="activator"
        size="xs"
        onClick={() => {
          if (!selection) return;
          const allLinesInSelection = text.slice(selection.start, selection.end).split('\n');
          const textBeforeSelection = text.slice(0, selection.start);
          const textAfterSelection = text.slice(selection.end);
          const newText = `${textBeforeSelection}\n${allLinesInSelection.map((line, index) => `${index + 1}. ${line}`).join('\n')}\n${textAfterSelection}`;
          textChange(newText);
        }}
      >
        <span>1.</span>
      </Button>
      <Button
        borderRadius={0}
        variant="activator"
        size="xs"
        onClick={() => {
          if (!selection) return;
          const textBeforeSelection = text.slice(0, selection.start);
          const textAfterSelection = text.slice(selection.end);
          const newText = `${textBeforeSelection}\n> ${text.slice(selection.start, selection.end)}\n${textAfterSelection}`;
          textChange(newText);
        }}
      >
        <Icon name="chat-bubble" />
      </Button>
      <Button
        borderRadius={0}
        variant="activator"
        size="xs"
        onClick={() => {
          if (!selection) return;
          const textBeforeSelection = text.slice(0, selection.start);
          const textAfterSelection = text.slice(selection.end);
          const newText = `${textBeforeSelection}\`${text.slice(selection.start, selection.end)}\`${textAfterSelection}`;
          textChange(newText);
        }}
      >
        <Icon name="code-bracket" />
      </Button>
    </div>
  );
};
