export const SYSTEM_PREFERS_DARK = '(prefers-color-scheme: dark)';

// LS - localStorage key
export const LS_COLOR_SCHEME = 'tw-local-color-scheme';

export const PATHS_WITHOUT_DARKMODE = [
  '/activity-feed',
  '/ai-columns',
  '/alan',
  '/aov',
  '/apps',
  '/api-keys',
  '/attribution/settings',
  '/sonar',
  '/cdp', // Specifically because of the Create Segment modal - i think once that's done, we're good to remove this
  '/cost-settings',
  '/chat',
  '/dashboards',
  '/get-started',
  '/insights/cart-analysis',
  '/insights/cohorts',
  '/ltv/bar-view',
  '/ltv/customer-journey',
  '/ltv/time-between-orders',
  '/optimizations',
  '/orcabase',
  '/orders',
  '/pod-settings',
  '/post-purchase-survey',
  '/product-analytics',
  '/product-journey',
  '/utm-builder',
  '/reports',
  '/shipping',
  '/signup',
  '/store-data',
  '/store-settings',
  '/sql-editor',
  '/builder',
  '/templates',
  '/share',
  '/user-profile',
  '/willy',
  '/welcome',
  'user-actions',
  '/workflows',
  '/global-filters',
] as const;
