import { useEffect } from 'react';
import { useStoreValue } from '@tw/snipestate';
import { $currentShopId } from '$stores/$shop';
import { useMutableQueryParams } from 'hooks/useMutableQueryParams';
import { $user } from '../../$stores/$user';
import { logoutOnPress } from '../../ducks/auth';
import { useDispatch } from 'react-redux';
import { useAppDispatch } from '../../index';

export const useSignOutOrcaUsersOnRegularApp = () => {
  const user = useStoreValue($user);
  if (user.isOrcabaseUserSignup) {
    document.location.replace(`/orcabase/integrations`);
  }
};
