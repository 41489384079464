import { ActionIcon, Button, Flex, Modal, Select, Text } from '@tw/ui-components';
import { BqColumn, FilterOperator } from '@tw/willy-data-dictionary/module/columns/types';
import { WillyParameter } from 'components/Willy/types/willyTypes';
import { WillyDynamicFieldOperator } from 'components/Willy/WillyDynamicFieldOperator';
import { WillyDynamicFieldValue } from 'components/Willy/WillyDynamicFieldValue';
import { FC, useEffect, useState } from 'react';

type FilterFieldModalProps = {
  isOpen: boolean;
  onClose: () => void;
  parameters: WillyParameter[];
  parameter?: WillyParameter;
  column?: BqColumn;
  columns?: BqColumn[];
  parametersChange: (parameters: WillyParameter[]) => void;
};

export const FilterFieldModal: FC<FilterFieldModalProps> = ({
  isOpen,
  onClose,
  parameters,
  parameter,
  column,
  columns,
  parametersChange,
}) => {
  const [newParameters, setNewParameters] = useState<WillyParameter[] | null>(null);
  const [newParameter, setNewParameter] = useState<WillyParameter | null>(null);

  useEffect(() => {
    setNewParameters(parameters);
  }, [parameters]);

  useEffect(() => {
    if (!parameter) {
      return;
    }
    setNewParameter(parameter);
  }, [parameter]);

  if (!newParameter || !newParameters) {
    return null;
  }

  return (
    <Modal opened={isOpen} onClose={onClose} size="lg" withCloseButton={false}>
      <Modal.Header p={0} className="z-0" mih={0}>
        <Text weight={500} size="lg">
          Filter
        </Text>
        <ActionIcon icon="close" onClick={onClose} size="md" iconSize={12} />
      </Modal.Header>

      <Flex align="center" gap="md" pt="lg">
        <Text color="gray.8" size="sm">
          by
        </Text>
        <Select
          value={newParameter.column}
          data={columns?.map((col) => ({ label: col.title, value: col.id }))}
          allowDeselect={false}
          onChange={(value) => {
            if (!value) return;
            setNewParameter({ ...newParameter, column: value });
            const editedParameters = newParameters?.map((p) => {
              if (p.column === newParameter.column) {
                return { ...newParameter, column: value };
              }
              return p;
            });
            setNewParameters(editedParameters);
          }}
        />
        <WillyDynamicFieldOperator
          column={column}
          operator={newParameter?.operator}
          disabled={newParameter?.isQueryParameter}
          allowMultiple={column?.multiSelect}
          hasOptions={!!column?.options?.length}
          restrictedOperators={newParameter?.sourceType === 'forecast' ? ['='] : undefined}
          onChange={(operator) => {
            setNewParameter({ ...newParameter, operator: operator as FilterOperator });
            const editedParameters = newParameters?.map((p) => {
              if (p.column === newParameter.column) {
                return { ...newParameter, operator: operator as FilterOperator };
              }
              return p;
            });
            setNewParameters(editedParameters);
          }}
          withinPortal={true}
        />

        <WillyDynamicFieldValue
          operator={newParameter.operator || '='}
          column={column}
          value={newParameter.value}
          options={column?.options ?? []}
          valueChange={(value) => {
            setNewParameter({ ...newParameter, value });
            const newParameters = parameters.map((p) => {
              if (p.column === newParameter.column) {
                return { ...newParameter, value };
              }
              return p;
            });
            setNewParameters(newParameters);
          }}
          updateOnInput={true}
          popoverWithinPortal={true}
        />
      </Flex>

      <Modal.Footer>
        <Flex align="center" justify="flex-end" gap="md">
          <Button variant="activator" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              parametersChange(newParameters ?? parameters);
              onClose();
            }}
          >
            Done
          </Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};
