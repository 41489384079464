import { useEffect, useRef, useState } from 'react';
import { useDebouncer } from './useDebouncer';

export type UseDelegatedHoverArgs = {
  selector: string;
  onHover: (element: HTMLElement) => void;
  allowPropogation?: boolean;
  delay?: number;
};

/**
 * @description Returns the closest HTMLElement that matches the target selector.
 * Current use case is knowing when an item is hovered over in some component that
 * doesn't expose an `onMouseOver` event or similar.
 */
export function useDelegatedHover({
  selector,
  onHover,
  allowPropogation = false,
  delay = 0,
}: UseDelegatedHoverArgs) {
  const debouncer = useDebouncer(delay);
  const [parentEl, setParentEl] = useState<HTMLElement | null>(null);
  const isTouching = useRef(false);

  useEffect(() => {
    if (!parentEl) return;

    // delegated event listener
    function handleHover(e: MouseEvent): void {
      if (!(e.target instanceof HTMLElement)) return;

      // find closest hovered element that has target selector
      const hoveredElement: HTMLElement | null = e.target.closest(selector);
      if (!hoveredElement || !parentEl?.contains(hoveredElement)) return;

      debouncer(() => onHover(hoveredElement));
    }

    function handleTouchStart(e: TouchEvent): void {
      // if there's some sort of touch event in some element above, we don't want
      // it to ever propogate by default, because it can prevent local behavior from catching
      if (!allowPropogation) e.stopPropagation();
      if (!(e.target instanceof HTMLElement)) return;
      isTouching.current = true;
    }

    function handleTouchEnd(e: TouchEvent): void {
      if (!(e.target instanceof HTMLElement)) return;
      isTouching.current = false;
    }

    function handleTouching(e: TouchEvent): void {
      if (!(e.target instanceof HTMLElement) || !isTouching.current) return;

      // find closest hovered element that has target selector
      const touchedElement: HTMLElement | null = e.target.closest(selector);
      if (!touchedElement || !parentEl?.contains(touchedElement)) return;

      debouncer(() => onHover(touchedElement));
    }

    parentEl.addEventListener('mouseover', handleHover);
    parentEl.addEventListener('touchstart', handleTouchStart);
    parentEl.addEventListener('touchend', handleTouchEnd);
    parentEl.addEventListener('touchmove', handleTouching);
    return () => {
      parentEl.removeEventListener('mouseover', handleHover);
      parentEl.removeEventListener('touchstart', handleTouchStart);
      parentEl.removeEventListener('touchend', handleTouchEnd);
      parentEl.removeEventListener('touchmove', handleTouching);
    };
  }, [parentEl, selector, onHover, allowPropogation, debouncer]);

  return setParentEl;
}
